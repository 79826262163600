import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonHttpHeaders } from '../common-http-headers';
import { API_URLS_REGEXP, CA_URLS_REGEXP, IPREO_URLS_REGEXP } from '../common-http-url-regex';
import { SessionIdProvider } from '../services/session-id.provider';

@Injectable()
export class SessionIdInterceptor implements HttpInterceptor {
  constructor(private sessionIdProvider: SessionIdProvider) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (CA_URLS_REGEXP.test(req.url) || IPREO_URLS_REGEXP.test(req.url) || API_URLS_REGEXP.test(req.url)) {
      req = req.clone({
        setHeaders: { [CommonHttpHeaders.XSessionId]: this.sessionIdProvider.sessionId }
      });
    }

    return next.handle(req);
  }
}
