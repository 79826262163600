import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AutocompleteResponse } from './models/autocomplete-response.model';
import { PlaceDetailsResponse } from './models/place-details.models';
import { PlaceDetailsSearchType } from './models/search-request.models';
import { TimezoneResponse } from './models/timezone.model';

@Injectable({
  providedIn: 'root'
})
export class LocationSearchRepository {
  private readonly locationBaseUrl = '/api/location';

  constructor(private httpClient: HttpClient) {}

  queryAutocomplete(text: string): Observable<AutocompleteResponse> {
    return this.httpClient.get<AutocompleteResponse>(`${this.locationBaseUrl}/query-autocomplete`, {
      params: { text }
    });
  }

  getPlaceDetails(placeId: string, addressFields?: PlaceDetailsSearchType[]): Observable<PlaceDetailsResponse> {
    const params = addressFields ? { addressFields } : undefined;
    return this.httpClient.get<PlaceDetailsResponse>(`${this.locationBaseUrl}/places/${placeId}`, { params });
  }

  getTimezone(placeId: string): Observable<TimezoneResponse> {
    return this.httpClient.get<TimezoneResponse>(`${this.locationBaseUrl}/places/${placeId}/timezone`);
  }
}
