import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { CI_APPLICATION, getEntitlements, getUserLoadStatus } from '@capital-access/common/user';
import { FORBIDDEN_PAGE_ROUTE, LoadStatus } from '@capital-access/common/utils';

@Injectable({ providedIn: 'root' })
export class InsightEntitlementsGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(FORBIDDEN_PAGE_ROUTE) private forbiddenPageRoute: string
  ) {}

  canActivate() {
    return this.store.select(getUserLoadStatus).pipe(
      filter(status => status == LoadStatus.Failed || status == LoadStatus.Loaded),
      withLatestFrom(this.store.select(getEntitlements)),
      map(([status, entitlements]) => {
        //As entitlements and user profile are loading together by one API call, so failed status should be processed by 'UserGuard'
        if (status === LoadStatus.Failed) {
          return true;
        }

        if (entitlements === null || !entitlements.applications.some(f => f.sourceId === CI_APPLICATION)) {
          this.router.navigate([this.forbiddenPageRoute]);
        }
        return true;
      }),
      take(1)
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
