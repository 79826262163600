import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SystemSettingsService } from '@capital-access/common/settings';
import { FireflyDrawerContent } from '@capital-access/firefly/components';

@Component({
  templateUrl: './disclaimer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisclaimerComponent extends FireflyDrawerContent<void> {
  settings = this.settingsService.getSettings().licenseAgreement;

  constructor(private settingsService: SystemSettingsService) {
    super();
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
