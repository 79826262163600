import { createAction, props } from '@ngrx/store';
import { ContactFilter, InstitutionFilter } from './models/management-hub-filter.models';

export const openContactManagementTable = createAction(
  scoped`Open Contact Management Table`,
  props<{ filters: ContactFilter }>()
);

export const openInstitutionManagementTable = createAction(
  scoped`Open Institution Management Table`,
  props<{ filters: InstitutionFilter }>()
);

function scoped(templateString: TemplateStringsArray) {
  return '[Common Management Hub] ' + templateString[0];
}
