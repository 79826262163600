import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeatureToggleRepository } from '../feature-toggle.repository';
import { DefaultFeatureToggleRepository } from './default-feature-toggle.repository';

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: FeatureToggleRepository, useClass: DefaultFeatureToggleRepository }]
})
export class DefaultFeatureToggleProviderModule {}
