import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FireflyTopNavService {
  private searchToggle$ = new BehaviorSubject<boolean>(false);

  searchOpenChange$ = this.searchToggle$.asObservable();

  toggleSearchOpenState(value: boolean) {
    this.searchToggle$.next(value);
  }
}
