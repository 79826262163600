import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { openContactPreview, openInstitutionPreview } from '@capital-access/common/actions';
import { CompositeId } from '@capital-access/profiles/common';
import { Contact, SearchCategory } from '../../../+state/models';
import { SearchItemsListBaseComponent } from '../search-items-list-base-component/search-items-list-base.component';

@Component({
  selector: 'ca-search-contacts-list',
  templateUrl: './search-contacts-list.component.html',
  styleUrls: ['./../search-list.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchContactsListComponent extends SearchItemsListBaseComponent<Contact> {
  constructor(store: Store) {
    super(store, SearchCategory.Contacts);
  }

  openContactProfilePreview(contact: Contact) {
    const { crmId, prospectingId: prId } = contact;
    this.store.dispatch(openContactPreview({ id: { prId, crmId } }));
  }

  openInstitutionProfilePreview(contact: Contact) {
    const { crmCompanyId: crmId, prospectingCompanyId: prId } = contact;
    this.store.dispatch(openInstitutionPreview({ id: { prId, crmId } }));
  }

  trackByMethod(index: number, item: Contact): string {
    const compositeId = new CompositeId(item.prospectingId, item.crmId);
    return compositeId.toString();
  }
}
