<div
  class="form-check form-switch form-check-inline"
  [ngClass]="offsetClasses"
  [caLocalizationScope]="localizationScope"
>
  <input
    class="form-check-input"
    id="setAsDefault"
    type="checkbox"
    [formControl]="control"
    [attr.disabled]="disableSaving || null"
  />
  <label class="form-check-label" for="setAsDefault">{{ toggleLabel | caLocalize }}</label>
</div>
