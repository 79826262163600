import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonAppSwitcherModule, CommonSecuritySelectorModule } from '@capital-access/common/components';
import { CommonFeatureToggleModule } from '@capital-access/common/feature-toggle';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonRouterModule } from '@capital-access/common/router';
import { CommonUserModule } from '@capital-access/common/user';
import {
  FireflyDropdownModule,
  FireflyDropdownWithInitialsModule,
  FireflyListModule,
  FireflyTooltipModule,
  FireflyTopNavModule
} from '@capital-access/firefly/components';
import { LogoModule } from '../logo/logo.module';
import { AddEntityDropdownComponent } from './add-entity-dropdown/add-entity-dropdown.component';
import { ContactSupportModule } from './contact-support/contact-support-module';
import { TopNavSearchDirective } from './directives/top-nav-search/top-nav-search.directive';
import { SurveillanceToggleComponent } from './surveillance-toggle/surveillance-toggle.component';
import { TopNavComponent } from './top-nav.component';

@NgModule({
  imports: [
    CommonModule,
    CommonRouterModule,
    CommonUserModule,
    CommonAppSwitcherModule,
    BrowserAnimationsModule,
    CommonLocalizationModule,
    CommonFeatureToggleModule,
    CommonSecuritySelectorModule,
    FireflyDropdownWithInitialsModule,
    FireflyTooltipModule,
    FireflyDropdownModule,
    FireflyTopNavModule,
    FireflyListModule,
    NgbDropdownModule,
    ContactSupportModule,
    LogoModule
  ],
  declarations: [TopNavComponent, TopNavSearchDirective, SurveillanceToggleComponent, AddEntityDropdownComponent],
  exports: [TopNavComponent, TopNavSearchDirective]
})
export class TopNavModule {}
