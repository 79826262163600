import { Convert, EnumObject } from '@capital-access/common/utils';

export interface PreferenceValueConverter<T> {
  convert(value: unknown): T | null;
  toString(value: T): string;
}

export class JsonPreferenceValueConverter<T> implements PreferenceValueConverter<T> {
  public toString = (value: T) => JSON.stringify(value);
  constructor(public convert: (value: unknown) => T | null) {}
}

export class PreferenceValidValueConverter<T> implements PreferenceValueConverter<T> {
  constructor(private underlyingConverter: PreferenceValueConverter<T>, private validateFn: (value: T) => boolean) {}

  convert(value: unknown): T | null {
    const convertedValue = this.underlyingConverter.convert(value);

    if (convertedValue === null) return null;

    return this.validateFn(convertedValue) ? convertedValue : null;
  }

  toString(value: T): string {
    return this.underlyingConverter.toString(value);
  }
}

function getJsonConverter<T>(convertFn: (value: unknown) => T | null) {
  return new JsonPreferenceValueConverter(convertFn);
}

// common type converters
export const type = {
  number: getJsonConverter(Convert.toNumber),
  string: getJsonConverter(Convert.toString),
  boolean: getJsonConverter(Convert.toBoolean),
  date: getJsonConverter(Convert.toDate),
  enum: <T>(enumObj: EnumObject<T>) => getJsonConverter(Convert.toEnum(enumObj)),
  array: {
    ofNumber: getJsonConverter(Convert.toNumberArray),
    ofString: getJsonConverter(Convert.toStringArray),
    ofBoolean: getJsonConverter(Convert.toBooleanArray),
    ofDate: getJsonConverter(Convert.toDateArray),
    ofEnum: <T>(enumObj: EnumObject<T>) => getJsonConverter(Convert.toEnumArray(enumObj))
  },
  /*
    Subset of some type which includes only valid value.
    Valid values determined by `validate` function
  */
  subsetOf: <T>(config: { type: PreferenceValueConverter<T>; validate: (value: T) => boolean }) =>
    new PreferenceValidValueConverter(config.type, config.validate)
};
