import { Directive, ElementRef, HostBinding, HostListener, Input, Optional } from '@angular/core';
import { FireflyDraggableListItemComponent } from '../draggable/draggable-list-item/draggable-list-item.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'f-list-item'
})
export class FireflyListItemDirective {
  @HostBinding('class.list-group-item') listGroupClass = !this.draggableItem;

  @HostBinding('attr.title') get itemTitle() {
    return this.title;
  }
  @HostBinding('class.list-group-item-hover') get listGroupItemHoverClass() {
    return this.withHover;
  }
  @HostBinding('class.list-item-removable-on-hover') get listGroupItemRemovableOnHoverClass() {
    return this.removableOnHover;
  }
  @HostBinding('class.active') get listGroupItemActiveClass() {
    return this.active;
  }
  @HostBinding('class.list-group-item-danger') get listGroupItemDangerClass() {
    return this.danger;
  }
  @HostBinding('class.disabled') get listGroupItemDisabledClass() {
    return this.disabled;
  }

  @HostBinding('attr.data-automation-id') get dataAutomationId() {
    const dataAutomationId = this.el.nativeElement.dataset.automationId;
    return dataAutomationId ? dataAutomationId : 'f-list-item';
  }

  @Input() title = '';
  @Input() withHover = true;
  @Input() removableOnHover = false;
  @Input() active = false;
  @Input() danger = false;
  @Input() disabled = false;

  constructor(private el: ElementRef, @Optional() private draggableItem: FireflyDraggableListItemComponent) {}

  @HostListener('keyup.enter')
  onKeyUp() {
    this.el.nativeElement.click();
    window.requestAnimationFrame(() => this.el.nativeElement.focus());
  }

  @HostListener('click')
  onClick(): void {
    (document.activeElement as HTMLElement)?.blur();
  }
}
