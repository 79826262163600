import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CALENDAR_SYNC_API_BASE } from '../constants';
import { ManualTransactionRequest, ProgressStats, SyncVendor } from '../models';

@Injectable()
export class CalendarSyncTransactionRepository {
  public submitManualTransaction(request: ManualTransactionRequest) {
    return this.http.post(`${CALENDAR_SYNC_API_BASE}/manual-transaction`, request);
  }

  public cancelTransaction(transactionId: string): Observable<ProgressStats> {
    return this.http.get<ProgressStats>(`${CALENDAR_SYNC_API_BASE}/transactions/cancel`, {
      params: { transactionId }
    });
  }

  public getTransactionProgress(vendorType: SyncVendor, transactionId?: string): Observable<ProgressStats> {
    let params = new HttpParams();
    if (transactionId) {
      params = params.append('transactionId', transactionId);
    }
    params = params.append('vendorType', vendorType);
    return this.http.get<ProgressStats>(`${CALENDAR_SYNC_API_BASE}/transactions/progress`, {
      params
    });
  }

  public enableSyncProgress(eventId: string, vendorType: SyncVendor) {
    return this.http.get<ProgressStats>(`${CALENDAR_SYNC_API_BASE}/transactions/enableSyncProgress`, {
      params: { eventId, vendorType }
    });
  }

  constructor(private http: HttpClient) {}
}
