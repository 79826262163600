import { createAction, props } from '@ngrx/store';
import { saveSettings } from '@capital-access/common/user';
import { CIQ_EVENTS_FILTERS_SECTION } from '../constants';
import { CiqEventsPreferences } from '../models';

export const openCiqEventsPreferencesDrawer = createAction('[Events Common] Open CIQ Events Preferences Drawer');
export const openCiqEventsDetails = createAction(
  '[Events Common] Open CIQ Events Details Drawer',
  props<{ eventId: number; addBackButton?: boolean }>()
);
export const saveCiqEventsPreferences = (data: CiqEventsPreferences) =>
  saveSettings(CiqEventsPreferences, { section: CIQ_EVENTS_FILTERS_SECTION, data });
