export const CRM_CONTACT_FORM_LOCALIZATION_SCOPE = 'crm-contact-form';
export const CRM_INSTITUTION_FORM_LOCALIZATION_SCOPE = 'crm-institution-form';
export const CRM_COMMON_LOCALIZATION_SCOPE = 'crm-common';
export const FORM_STATUS_VALID = 'VALID';

export const CRM_FORM_MAX_LENGTH = {
  email: 200,
  phone: 40,
  institutionName: 256,
  website: 256,
  addressLine2: 70,
  salutation: 20,
  firstName: 100,
  middleName: 64,
  lastName: 100,
  nickName: 64,
  suffix: 15,
  jobRole: 255,
  additionalComments: 2000,
  assistantMiddleName: 64,
  assistantLastName: 100,
  assistantFirstName: 100,
  assistantPhone: 40,
  assistantEmail: 200
};

export const phoneCharactersRegex = /^([–+-\s()./]|[0-9])*(\s?(([E|e]xt[:|.|]?)|x|X)(\s?[0-9+-]+))?$/;

export const numberCountRegex = /(.*\d){4,}/;

export const EMPTY_ADDRESS = {
  inputAddress: '',
  addressLine1: '',
  addressLine2: '',
  place_id: '',
  addressCity: '',
  addressCountry: '',
  state: '',
  stateCode: '',
  zipCode: ''
};
