import { Currency } from '@capital-access/common/standing-data';

export const fallbackLang = 'en';
export const testLang = 'test';

export const fallbackTimezone = 'EST';

export const supportedLanguages = [
  fallbackLang,
  testLang,
  'ja',
  'de',
  'pt',
  'fr',
  'ru',
  'fi',
  'es',
  'zh',
  'da',
  'nl',
  'sv',
  'it',
  'tr',
  'hi',
  'el'
] as const;

export const supportedCultures = [
  'en-US',
  'en-GB',
  'ja',
  'de-DE',
  'pt-BR',
  'fr-FR',
  'ru',
  'en-CA',
  'de-AT',
  'fi',
  'es-ES',
  'es-MX',
  'zh-CN',
  'es-AR',
  'da',
  'nl',
  'sv',
  'fr-BE',
  'de-CH',
  'es-CO',
  'it',
  'tr',
  'hi',
  'en-ZA',
  'el'
] as const;

export type CultureCode = (typeof supportedCultures)[number];

export const fallbackCulture: CultureCode = 'en-US';

export const fallbackCurrency: Currency = { code: 'USD', name: 'United States Dollar' };
