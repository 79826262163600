import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { ScaleBand, ScaleLinear, ScalePoint } from 'd3-scale';
import type { ChartDataSeries } from '../../models/common-chart-models';
import { ChartDataEntry } from '../../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-line-series]',
  templateUrl: './line-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyLineSeriesComponent implements OnInit {
  @Input() xScale!: ScalePoint<string> | ScaleBand<string>;
  @Input() yScale!: ScaleLinear<number, number>;
  @Input() data!: ChartDataSeries[];
  @Input() lineClasses!: string[];
  @Input() areaClasses!: string[];
  @Input() animationDuration = 500;
  @Input() animationDelay = 0;
  @Input() animation = true;
  @Input() curved = false;
  @Input() showArea = false;
  @Input() lineWidth = 2;
  @Input() circleRadius = 4;
  @Input() popoverOpenDelay = 200;
  @Input() popoverPlacement = 'top';
  @Input() popoverTriggers = 'manual';
  @Input() popoverTemplate!: TemplateRef<unknown> | null;
  @Input() showPointerLine = false;
  @Input() areaIsSelected = false;
  /**
   * Inputs used for bar-line chart interactions, like animating circle-pointer movements.
   */
  @Input() activeDataPointId = -1;
  @Input() nearestXPointer!: number | undefined | null;

  lines!: ChartDataEntry[][];

  ngOnInit() {
    const lines: { [name: string]: ChartDataEntry[] } = {};

    this.data.forEach(d => {
      (d.series as ChartDataEntry[]).forEach(entry => {
        if (!lines[entry.name]) lines[entry.name] = [];
        lines[entry.name].push({ name: d.name, value: entry.value, cssClass: entry.cssClass });
      });
    });

    this.lines = Object.values(lines);
  }
}
