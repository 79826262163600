import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LinkNavItem, UserNavItem } from '@capital-access/common/navigation';
import { SideNavItemHighlightService } from '../services/side-nav-item-highlight.service';

@Pipe({
  name: 'caIsActiveRoute'
})
export class IsActiveRoutePipe implements PipeTransform {
  constructor(private highlightService: SideNavItemHighlightService) {}

  transform(value: LinkNavItem | UserNavItem, defaultLink = false): Observable<boolean> {
    const activeLink$ = this.highlightService.activeLink$.pipe(
      map(link => (link === null && defaultLink ? value.link : link))
    );

    if ('subItems' in value && value.subItems && value.subItems.length > 0) {
      return activeLink$.pipe(map(activeLink => !!value.subItems && value.subItems.some(i => i.link === activeLink)));
    }
    return activeLink$.pipe(map(activeLink => !!value.link && value.link === activeLink));
  }
}
