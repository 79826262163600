import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EditorModule } from '@progress/kendo-angular-editor';
import { FireflyKendoIconsModule } from './../kendo-icons/kendo-icons.module';
import { FireflyRichTextEditorComponent } from './rich-text-editor.component';

@NgModule({
  declarations: [FireflyRichTextEditorComponent],
  imports: [CommonModule, EditorModule, FireflyKendoIconsModule.forRoot()],
  exports: [FireflyRichTextEditorComponent]
})
export class FireflyRichTextEditorModule {}
