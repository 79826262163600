import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { ENGAGE_BASE_URL, openCiqEventsDetails, openCiqEventsPreferencesDrawer } from '@capital-access/events/common';
import { CIQ_EVENTS_MODULE_ID, LOAD_CIQ_EVENTS_MODULE } from './ciq-events-id.model';

@Injectable()
export class CiqEventsEffects {
  openPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCiqEventsPreferencesDrawer),
      switchMap(() => this.loadModule()),
      map(moduleRef => moduleRef.instance.openCiqEventsPreferences())
    )
  );

  openDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openCiqEventsDetails),
      switchMap(({ eventId, addBackButton }) =>
        this.loadModule().pipe(map(moduleRef => moduleRef.instance.openCiqEventsDetails(eventId, addBackButton)))
      )
    )
  );

  openInitialPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      withLatestFrom(this.store.select(getFeatureToggle('engage-ciq-events-ISSYN-2053'))),
      filter(([{ payload }, toggleEnabled]) => toggleEnabled && payload.event.url === `/${ENGAGE_BASE_URL}`),
      switchMap(() => this.loadModule()),
      map(moduleRef => moduleRef.instance.openInitialSetupPreferences())
    )
  );

  loadModule() {
    return this.commonLazyLoadingService.loadModule(CIQ_EVENTS_MODULE_ID, LOAD_CIQ_EVENTS_MODULE);
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private commonLazyLoadingService: CommonLazyLoadingService
  ) {}
}
