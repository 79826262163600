import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { of } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { Breakpoint } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../../common-components.const';

@Component({
  selector: 'ca-show-all',
  templateUrl: './show-all.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowAllComponent {
  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  constructor(@Optional() private localizationService: LocalizationService) {}

  showAllTitle$ = this.localizationService?.localize('showAllTitle', {}, this.localizationScope) ?? of('Show all');
  allItems!: string[];
  firstItem!: string;

  @Input() popoverTitle?: string | undefined | null;

  @Input() isNumber?: boolean;

  @Input() set items(value: string | string[]) {
    if (value) {
      this.allItems = Array.isArray(value) ? value : [value];
      this.firstItem = this.allItems[0];
    }
  }

  get mobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }
}
