import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { map, share, shareReplay, switchMap } from 'rxjs/operators';
import { AppsyncWebsocket } from './appsync-websocket-connection';
import { NotificationApiAuthProvider } from './auth-providers';

export interface GraphQLError {
  errorType: string;
  message: string;
}

export interface GraphQLResponse<T> {
  data?: T;
  errors?: GraphQLError[];
}

@Injectable()
export class NotificationsApi {
  private configuration$ = defer(() =>
    this.http.get<{ host: string; realtimeHost: string }>('/api/notifications/.well-known/configuration')
  ).pipe(shareReplay(1));

  private sharedSockets: Record<string, Observable<AppsyncWebsocket>> = {};

  constructor(private http: HttpClient) {}

  public graphQl<TData, TVariables = unknown>(
    query: string,
    variables?: TVariables
  ): Observable<GraphQLResponse<TData>> {
    return this.configuration$.pipe(
      switchMap(config =>
        this.http.post<GraphQLResponse<TData>>(`https://${config.host}/graphql`, {
          query,
          variables
        })
      )
    );
  }

  public subscribe<TData, TVariables = unknown>(
    authProvider: NotificationApiAuthProvider,
    query: string,
    variables?: TVariables
  ): Observable<TData> {
    let connection$;

    if (authProvider.multiplex) {
      if (!this.sharedSockets[authProvider.providerName])
        this.sharedSockets[authProvider.providerName] = this.configuration$.pipe(
          map(config => new AppsyncWebsocket(config.host, config.realtimeHost, authProvider)),
          shareReplay({ bufferSize: 1, refCount: true })
        );

      connection$ = this.sharedSockets[authProvider.providerName];
    } else {
      connection$ = this.configuration$.pipe(
        map(config => new AppsyncWebsocket(config.host, config.realtimeHost, authProvider))
      );
    }

    return connection$.pipe(
      switchMap(socket => socket.subscribe<TData>(query, variables)),
      share()
    );
  }
}
