<ca-search-category-list
  caLocalizationScope="search-nav"
  data-automation-id="ca-events-results-container"
  class="ca-one-search-results-list"
  *ngIf="showItems$ | async"
  [header]="'inEvents' | caLocalize"
  [items]="items$ | async"
  [showMoreHeader]="'showMore' | caLocalize"
  [hasMore]="hasMore$ | async"
  [rowTemplate]="rowEventTemplate"
  [category]="categories.Events"
  [currentCriteria]="$any(currentCriteria$ | async)"
  [isSearchFailed]="isItemsSearchFailed$ | async"
  [trackByFn]="trackByMethod"
  (updateSelection)="updateSelection($event)"
  (showMore)="showMore($event)"
>
</ca-search-category-list>
<ng-template #rowEventTemplate let-event="item" let-criteria="criteria">
  <a
    data-automation-id="ca-search-event-link"
    class="link link-main text-truncate w-auto ca-search-link"
    [routerLink]="event.id | caEventLink"
    [innerHTML]="event.name | fHighlight : criteria : 'fw-bold'"
    [title]="event.name"
  ></a>

  <ng-template #tooltipContent>
    <span
      data-automation-id="onesearch-tooltip"
      [innerHTML]="event.name | fHighlight : criteria : 'body-50 text-base fw-bold'"
    ></span>
  </ng-template>
</ng-template>
