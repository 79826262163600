export function getMaxWidth(element: HTMLElement) {
  const elementStyles = window.getComputedStyle(element);
  const maxWidth = elementStyles.maxWidth;

  if (!maxWidth.endsWith('%') || !element.parentElement) {
    return element.offsetWidth;
  }

  const maxWidthInPixels = (parseInt(maxWidth, 10) * getParentWidth(element.parentElement)) / 100;

  return maxWidthInPixels || element.offsetWidth;
}

export function getParentWidth(element: HTMLElement) {
  const parentStyles = window.getComputedStyle(element);

  return element.clientWidth - parseInt(parentStyles.paddingLeft, 10) - parseInt(parentStyles.paddingRight, 10);
}
