import { DateTime } from 'luxon';
import { DateRange, DateRangeFilter, ProgressStats } from './models';
import { EnableSyncState } from './transaction/+store/transaction/transaction.reducer';

export function getCurrentDateRange(day: number = 7) {
  const dateStart = getStartOfToday();

  return { start: dateStart.minus({ day }).toJSDate(), end: dateStart.plus({ day }).toJSDate() };
}

export function getDiffFromRange(dateRange: DateRange): DateRangeFilter {
  const today = getStartOfToday();
  return {
    start: getDaysDiff(dateRange.start!, today),
    end: getDaysDiff(dateRange.end!, today)
  };
}

export function getRangeFromDiff(range: DateRangeFilter): DateRange {
  const today = getStartOfToday();
  return {
    start: today.plus({ day: range.start }).toJSDate(),
    end: today.plus({ day: range.end }).toJSDate()
  };
}

export function getDaysDiff(date: Date, today: DateTime) {
  return DateTime.fromJSDate(date).diff(today, 'days').days;
}

export function toFormat(date: Date): string {
  return DateTime.fromJSDate(date).toUTC().toISO({ includeOffset: false });
}

export function fromFormat(date: string): Date {
  return DateTime.fromISO(date, { zone: 'UTC' }).toJSDate();
}

export function getStartOfToday() {
  return DateTime.now().startOf('day');
}

export function countProgressBarValue(id: string | null, stats: ProgressStats | null) {
  const value = id && stats ? (100 * stats.progressValue) / stats.progressTotalValue : 0;

  return Math.round(value);
}

export function countEnableProgressBarValue(enableState: EnableSyncState | undefined | null) {
  const value =
    enableState && enableState.progressStats
      ? (100 * enableState.progressStats.progressValue) / enableState.progressStats.progressTotalValue
      : 0;

  return Math.round(value);
}
