import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientsModule } from './http-clients/http-clients.module';
import { CorrelationIdModule } from './interceptors/correlation-id.module';
import { SessionIdModule } from './interceptors/session-id.module';

@NgModule({
  imports: [CommonModule, CorrelationIdModule, SessionIdModule, HttpClientsModule]
})
export class CommonHttpModule {}
