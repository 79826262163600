import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';
import { logout } from '@capital-access/common/actions';
import { getFeatureToggle, getFeatureTogglesLoaded } from '@capital-access/common/feature-toggle';
import { Log } from '@capital-access/common/logging';
import { when } from '@capital-access/common/utils';
import { swLoadSuccess, swNotAllowed } from './pwa.actions';

@Injectable()
export class PwaEffects {
  private pwaEnabled = false;

  constructor(private actions: Actions, private store: Store, private swUpdate: SwUpdate) {}

  loadToggleProfile$ = createEffect(() =>
    this.store.select(getFeatureToggle('cpd-1395-pwa')).pipe(
      when(this.store.select(getFeatureTogglesLoaded)),
      map(isEnabled => {
        this.pwaEnabled = isEnabled;
        if (isEnabled) {
          localStorage.setItem('pwa', 'allowed');
          return swLoadSuccess();
        }
        localStorage.removeItem('pwa');
        return swNotAllowed();
      })
    )
  );

  userLogout$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(logout),
        filter(() => this.pwaEnabled),
        tap(() => {
          try {
            localStorage.removeItem('pwa');

            navigator.serviceWorker.getRegistrations().then(function (registrations) {
              for (const registration of registrations) {
                registration.unregister();
              }
            });

            caches.keys().then(cacheNames => {
              cacheNames.forEach(cacheName => caches.delete(cacheName));
            });

            Log.info(`SW is deactivated`);
          } catch (e) {
            return;
          }
        })
      ),
    { dispatch: false }
  );

  swVersionUpdate$ = createEffect(() =>
    this.actions.pipe(
      ofType(swLoadSuccess),
      switchMap(() => this.swUpdate.versionUpdates),
      tap(update => {
        Log.info(`SW update: ${JSON.stringify(update)}`);
      })
    )
  );

  swUnrecoverable$ = createEffect(() =>
    this.actions.pipe(
      ofType(swLoadSuccess),
      switchMap(() => this.swUpdate.unrecoverable),
      tap(e => {
        Log.error(`SW unrecoverable error: ${e.reason}`);
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => caches.delete(cacheName));
        });
        location.reload();
      })
    )
  );
}
