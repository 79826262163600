import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { assignCustomFields, bulkAssignCustomFields } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CUSTOM_FIELD_ASSIGN_MODULE_ID, LOAD_CUSTOM_FIELD_ASSIGN_MODULE } from '../assign-loader-module-id.model';

@Injectable()
export class AssignEffects {
  bulkAssignCustomFields$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(bulkAssignCustomFields),
        switchMap(({ ids, entityType }) =>
          this.commonLazyLoadingService
            .loadModule(CUSTOM_FIELD_ASSIGN_MODULE_ID, LOAD_CUSTOM_FIELD_ASSIGN_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showBulkCustomFieldsAssignForm(ids, entityType)))
        )
      ),
    { dispatch: false }
  );

  assignCustomFields$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignCustomFields),
        switchMap(({ id, entityType, entityName }) =>
          this.commonLazyLoadingService
            .loadModule(CUSTOM_FIELD_ASSIGN_MODULE_ID, LOAD_CUSTOM_FIELD_ASSIGN_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showCustomFieldsAssignForm(id, entityType, entityName)))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
