import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySvgDonutChartModule } from '../svg-donut-chart/svg-donut-chart.module';
import { FireflyDonutChartComponent } from './donut-chart.component';

@NgModule({
  imports: [CommonModule, FireflySvgDonutChartModule],
  declarations: [FireflyDonutChartComponent],
  exports: [FireflyDonutChartComponent]
})
export class FireflyDonutChartModule {}
