import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, take } from 'rxjs';
import { addContact, addInstitution, logout, openContactSupportDrawer } from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import {
  EntityNavItemConfig,
  NavigationConfigService,
  ToggleNavItemConfig,
  UserNavItemConfig,
  UserProfileDropdownItem
} from '@capital-access/common/navigation';
import {
  getSelectedSecurity,
  getSelectedSecuritySurveillance,
  toggleSurveillance
} from '@capital-access/common/security';
import { getContentPermissions, getUser, User } from '@capital-access/common/user';
import { addNewList } from '@capital-access/lists/common';
import { EntityItem } from '../../models/entity-item';
import { CONTACT_SUPPORT_URL, LOGOUT_URL } from './../navigation.constants';

@Injectable()
export class SharedNavigationItemsService {
  localizationScope = 'navigation';

  entitySettingsIcon = 'f-i-addressbook-add';

  entitySettingsOptionsTop: EntityItem[] = [
    { localizationKey: 'addNewInstitution', action: addInstitution() },
    { localizationKey: 'addNewContact', action: addContact() },
    { localizationKey: 'addNewList', action: addNewList() }
  ];
  entitySettingsOptionsSide: EntityNavItemConfig[] = [
    { ...this.entitySettingsOptionsTop[0], icon: this.entitySettingsIcon },
    ...this.entitySettingsOptionsTop.slice(1)
  ];

  showToggleComponent$ = combineLatest([
    this.store.select(getFeatureToggle('surveillance-across-application-cora-2613')), // TODO: remove toggle after release
    this.store.select(getContentPermissions),
    this.store.select(getSelectedSecurity)
  ]).pipe(
    map(
      ([ftEnabled, permissions, securityId]) =>
        !!(ftEnabled && securityId?.id && permissions?.surveillance.securityIds.includes(securityId.id))
    )
  );

  toggleSettingsOption: ToggleNavItemConfig[] = [
    {
      localizationKey: 'surveillance',
      action: toggleSurveillance(),
      value: this.store.select(getSelectedSecuritySurveillance),
      isShown: this.showToggleComponent$
    }
  ];

  user$: Observable<User | null>;

  userSettingsOptionsTop$: Observable<UserProfileDropdownItem[]>;
  userSettingsOptionsSide$: Observable<UserNavItemConfig[]>;

  constructor(
    private store: Store,
    private localizationService: LocalizationService,
    navigationConfig: NavigationConfigService
  ) {
    this.user$ = this.store.select(getUser);
    const routes = navigationConfig.userSettingsRoutes;

    const userSettingsOptions = [
      { localizationKey: 'myAccount', link: routes?.myAccount || '' },
      { localizationKey: 'contactSupport', action: openContactSupportDrawer(), link: CONTACT_SUPPORT_URL },
      { localizationKey: 'settings', link: routes?.settings || '' },
      { localizationKey: 'logOut', action: logout(), link: LOGOUT_URL }
    ];

    this.userSettingsOptionsTop$ = this.localizationService.getLocalization(this.localizationScope).pipe(
      take(1),
      map(localization => {
        return userSettingsOptions.map(option => {
          return {
            text: localization[option.localizationKey],
            route: option.link
          };
        });
      })
    );

    this.userSettingsOptionsSide$ = this.user$.pipe(
      map(user => {
        return [{ ...userSettingsOptions[0], iconText: this.getInitials(user) }, ...userSettingsOptions.slice(1)];
      })
    );
  }

  private getInitials(user: User | null): string {
    if (!user) return '-';
    const firstInitial = user.firstName && user.firstName?.trim()[0];
    const lastInitial = user.lastName && user.lastName?.trim()[0];
    if (firstInitial && lastInitial) {
      return firstInitial + lastInitial;
    } else if (!firstInitial && !lastInitial) {
      return '-';
    } else {
      return (firstInitial as string) || (lastInitial as string);
    }
  }
}
