export function applyRate(value: number, rate: number) {
  return value * rate;
}

export function transformToAbs(value: number) {
  return Math.abs(value);
}

export function transformToMillions(value: number) {
  return value / 1000000;
}
