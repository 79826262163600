import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonHttpHeaders } from '../common-http-headers';
import { API_URLS_REGEXP, CA_URLS_REGEXP, IPREO_URLS_REGEXP } from '../common-http-url-regex';
import { CorrelationIdProvider } from '../services/correlation-id.provider';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {
  constructor(private correlationIdProvider: CorrelationIdProvider) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (CA_URLS_REGEXP.test(req.url) || IPREO_URLS_REGEXP.test(req.url) || API_URLS_REGEXP.test(req.url)) {
      req = req.clone({
        setHeaders: { [CommonHttpHeaders.XCorrelationId]: this.correlationIdProvider.correlationId }
      });
    }

    return next.handle(req);
  }
}
