import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkeletonLoaderShade } from '../../models/skeleton-loader.enum';

@Component({
  selector: 'f-skeleton-large-card-with-schedule',
  template: `
    <div class="container-fluid heading-50 p-0">
      <div class="row">
        <div class="col-8">
          <div *ngFor="let row of rows" class="row ms-0 f-skeleton-scheduler-row">
            <f-skeleton
              *ngFor="let col of columns"
              [height]="scheduleCellHeight"
              [shade]="shade"
              shape="rectangle"
              class="col f-skeleton-scheduler-cell"
            ></f-skeleton>
          </div>
        </div>
        <div class="col-3 offset-1">
          <div class="row">
            <f-skeleton class="col-12 mb-1" [shade]="shade"></f-skeleton>
            <f-skeleton class="col-8 mb-1" [shade]="shade"></f-skeleton>
            <f-skeleton class="col-8 mb-1" [shade]="shade"></f-skeleton>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySkeletonLargeCardWithScheduleComponent {
  @Input() shade: SkeletonLoaderShade = SkeletonLoaderShade.Light;
  @Input() scheduleCellHeight: number | string = '42px';
  @Input() set scheduleColumnsCount(value: number) {
    this.columns = this.makeArray(value);
  }
  @Input() set scheduleRowsCount(value: number) {
    this.rows = this.makeArray(value);
  }

  columns = this.makeArray(5);
  rows = this.makeArray(3);

  private makeArray(n: number) {
    return new Array(n).fill(null);
  }
}
