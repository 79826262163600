/**
 * Group array by any arbitrary key using match function
 * @param array array with items to group
 * @param selectKey key selector function
 * @param matchKey key matcher function
 * @returns array of groups where first item is a key and second is a grouped items
 */
export function lookup<T, TKey>(
  array: T[],
  selectKey: (item: T) => TKey,
  matchKey: (key1: TKey, key2: TKey) => boolean
): Map<TKey, T[]> {
  const map = new Map<TKey, T[]>();

  array.forEach(item => {
    const selectedKey = selectKey(item);
    let keyMatched = false;
    for (const [key, value] of map) {
      if (matchKey(selectedKey, key)) {
        value.push(item);
        keyMatched = true;
        break;
      }
    }
    if (!keyMatched) {
      map.set(selectedKey, [item]);
    }
  });

  return map;
}
