import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { ChartLegendOptions } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../../common-components.const';
import { ConcentrationChartColorScheme, FactorInfo } from '../models';
import { getConcentrationGradientCssClass } from '../utils';

@Component({
  selector: 'ca-concentration-legend',
  templateUrl: './concentration-legend.component.html',
  styleUrls: ['./concentration-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcentrationLegendComponent implements OnInit {
  @Input() securityName!: string;
  @Input() factorInfo!: FactorInfo;
  @Input() colorScheme = ConcentrationChartColorScheme.Scheme_0;

  calculatedSegment = 'Calculated Segment';
  concentrationTitle = 'Holdings Concentration';
  private localizationService = inject(LocalizationService, { optional: true });

  get makeLegend(): ChartLegendOptions {
    const data = [
      { label: this.securityName, markerCssClass: 'rounded-pill ca-selected-decile-bubble' },
      { label: this.calculatedSegment, markerCssClass: 'legend-line ca-highlighted-decile ms-3' },
      {
        label: this.concentrationTitle,
        markerCssClass: `${getConcentrationGradientCssClass(
          this.colorScheme
        )} legend-decile-concentration-marker me-1 ms-3`
      }
    ];

    return {
      data,
      legendItemsMarginSize: 0,
      legendPosition: 'bottom',
      legendContentAlignment: 'center',
      legendWrappingLinesCount: 3
    };
  }

  ngOnInit() {
    if (!this.localizationService) return;
    this.localizationService
      .localize(
        ['concentrationChartLegendCalculatedSegment', 'concentrationChartLegendConcentration'],
        {},
        COMMON_COMPONENTS_LOCALIZATION_SCOPE
      )
      .pipe(take(1))
      .subscribe(([calculatedSegment, concentrationTitle]) => {
        this.calculatedSegment = calculatedSegment;
        this.concentrationTitle = concentrationTitle;
      });
  }
}
