import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { ChipSize } from '../models/chip-size';
import { ChipType } from '../models/chip-type';

@Component({
  selector: 'f-chip',
  templateUrl: './chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyChipComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() type: ChipType = ChipType.Enabled;
  @Input() size: ChipSize = ChipSize.Xs;
  @Input() rootCssClass!: string;
  @Input() outline = false;

  @Output() chipClick = new EventEmitter<void>();

  ChipType = ChipType;
  isEditableChip = false;

  ngOnInit() {
    this.isEditableChip =
      this.type === ChipType.Editable ||
      this.type === ChipType.EditableSecondary ||
      this.type === ChipType.EditableDanger;
  }

  get chipClasses() {
    const outlineClass = this.outline ? 'chip-outline' : '';
    const classes: string[] = [this.type, this.size, outlineClass];
    if (this.rootCssClass) {
      classes.push(this.rootCssClass!);
    }
    return classes;
  }

  onChipClick(event: Event, checkType = true) {
    if ((checkType && this.isEditableChip) || this.disabled) {
      return;
    }
    event.stopPropagation();
    this.chipClick.emit();
  }
}
