<div
  class="ms-4 ms-sm-6 ms-md-8 me-4 me-sm-6 mb-4"
  [ngClass]="containerCssClass"
  [class.mobile-search-active]="mobileSearchIsActive"
  [class.mobile-search-inactive]="!mobileSearchIsActive"
  [class.search-animation-running]="searchAnimationState === 'on'"
  [class.search-animation-done]="mobileSearchIsActive && searchAnimationState === 'off'"
>
  <div
    *ngIf="!cardRowWithTabs; else cardRowWithTabsTemplate"
    class="row align-items-center justify-content-between flex-nowrap"
    [ngClass]="rowCssClass"
  >
    <div
      class="col-xxxl-2 col-xl-3 col-md-4 col-sm-5 col-xs-auto card-row-search-container"
      #searchContainer
      [ngClass]="firstColClass"
    >
      <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
    </div>

    <div class="col-md-2 col-sm-3 col-xs-4 card-row-results-container" [ngClass]="secondColClass">
      <ng-container *ngTemplateOutlet="resultsTemplate"></ng-container>
    </div>

    <div class="col-xxxl-8 col-xl-7 col-md-6 col-sm-4 col-xs card-row-filters-container" [ngClass]="thirdColClass">
      <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
    </div>
  </div>

  <ng-template #cardRowWithTabsTemplate>
    <div class="row align-items-center justify-content-between flex-nowrap" [ngClass]="rowCssClass">
      <div
        class="col-8 col-md-9 col-lg-8 col-xl-10 col-xxl-8 col-xxxl-6 d-flex align-items-center card-row-wrapper-with-tabs"
      >
        <div class="card-row-tabs-container" [class.flex-shrink-0]="!mobile" #tabContainer [ngClass]="tabsColClass">
          <ng-content select="[fTabsCol]"></ng-content>
        </div>

        <div class="card-row-search-container" #searchContainer [ngClass]="firstColClass">
          <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
        </div>

        <div class="card-row-results-container d-none d-md-inline-block" [ngClass]="secondColClass">
          <ng-container *ngTemplateOutlet="resultsTemplate"></ng-container>
        </div>
      </div>

      <div
        class="col-4 col-md-3 col-lg-4 col-xl-2 col-xxl-4 col-x card-row-filters-container"
        [ngClass]="thirdColClass"
      >
        <ng-container *ngTemplateOutlet="filtersTemplate"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #searchTemplate>
    <ng-content select="[fFirstCol]"></ng-content>
    <span class="link link-primary ms-3 search-close-link" *ngIf="showCloseLink" (click)="closeMobileSearch()">
      {{ closeSearchBtnTitle$ | async }}
    </span>
  </ng-template>

  <ng-template #resultsTemplate>
    <ng-content select="[fSecondCol]"></ng-content>
  </ng-template>

  <ng-template #filtersTemplate>
    <div class="d-flex justify-content-end">
      <ng-content select="[fThirdCol]"></ng-content>
    </div>
  </ng-template>
</div>
