import { NgModule } from '@angular/core';
import { CommonRouterModule } from '@capital-access/common/router';

export const loadModule = () => import('@capital-access/lists/client').then(m => m.ListsClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: 'list-management',
          loadChildren: loadModule
        }
      ]
    })
  ]
})
export class ListsClientLazyModule {}
