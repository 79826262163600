import { Injectable } from '@angular/core';
import { NgbConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TOOLTIP_OPEN_DELAY } from './constants';

@Injectable()
export class FireflyTooltipConfig extends NgbTooltipConfig {
  constructor(ngbConfig: NgbConfig) {
    super();
    this.animation = ngbConfig.animation;
    this.openDelay = TOOLTIP_OPEN_DELAY;
  }
}
