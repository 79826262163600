import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import type { ChartDataEntry } from '../common';
import { FireflyBaseChartComponent } from '../common/base-components/base-chart-component';

@Component({
  selector: 'f-bar-chart',
  templateUrl: './bar-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyBarChartComponent extends FireflyBaseChartComponent {
  @Input() barClasses: string[] = [];
  @Input() data!: ChartDataEntry[];
  @Input() infoBubbleScaleFactor = 1;
  @Input() infoBubbleClasses: string[] = ['f-bg-bubble'];
  @Input() infoBubbleTextClasses: string[] = ['f-text-bubble', 'fw-bold'];

  @Output() barClick: EventEmitter<{ data: ChartDataEntry } | undefined | null> = new EventEmitter();

  protected chartComponent = FireflyBarChartComponent;
}
