import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyBadgeFlagComponent } from './badge-flag.component';

@NgModule({
  declarations: [FireflyBadgeFlagComponent],
  imports: [CommonModule],
  exports: [FireflyBadgeFlagComponent]
})
export class FireflyBadgeFlagModule {}
