import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationService } from '@capital-access/common/localization';
import { ChipSize } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { ActivistFilter, ActivistType } from './models/model';

@Component({
  selector: 'ca-activist-filter',
  templateUrl: 'activist-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivistFilterComponent {
  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;
  size = ChipSize.Sm;

  @Input() excludeActivistTypes!: boolean;
  @Input() selectedItems!: ActivistType[];

  @Output() activistTabChange = new EventEmitter<ActivistType[]>();
  @Output() activistExcludeChange = new EventEmitter<boolean>();

  activists$: Observable<ActivistFilter[]> = this.localizationService.getLocalization(this.localizationScope).pipe(
    map(({ often, occasional }) => [
      { id: ActivistType.Often, text: often },
      { id: ActivistType.Occasional, text: occasional }
    ])
  );

  onClick() {
    this.selectedItems = [];
    this.excludeActivistTypes = !this.excludeActivistTypes;
    this.activistExcludeChange.emit(this.excludeActivistTypes);
  }

  onChipToggle(item: unknown) {
    const value = (item as Record<string, ActivistType>).id;
    const result = this.selectedItems.includes(value)
      ? this.selectedItems.filter(d => d !== value)
      : [...this.selectedItems, value];
    this.activistTabChange.emit(result);
  }
  constructor(private localizationService: LocalizationService) {}
}
