import { Injectable } from '@angular/core';
import { createTitle } from '@capital-access/common/browser';
import { LocalizedString } from '@capital-access/common/localization';
import { SessionExpirePageComponent } from './session-expire-page.component';

@Injectable()
export class SessionExpirePageTitle {
  title = createTitle(SessionExpirePageComponent, () => ({
    value: [new LocalizedString('applicationTitle'), new LocalizedString('errorPageSessionExpireTitle')],
    override: true
  }));
}
