import { UrlSegment } from '@angular/router';
import flatten from 'lodash/flatten';

/**
 * see Angular UrlSegment parameters [doc](https://angular.io/api/router/UrlSegment#parameters)
 */
export type MatrixParams = Record<string, string>;
export type UrlPath = (string | MatrixParams)[];

function toPath(segment: UrlSegment) {
  if (Reflect.ownKeys(segment.parameters).length > 0) {
    return [segment.path, segment.parameters];
  }
  return [segment.path];
}

export class BreadcrumbUrl {
  public readonly urlString: string;
  public readonly urlPath: UrlPath;

  constructor(public readonly segments: UrlSegment[] = []) {
    this.urlString = `/${this.segments.map(s => s.toString()).join('/')}`;
    this.urlPath = (['/'] as UrlPath).concat(flatten(this.segments.map(toPath)));
  }
}
