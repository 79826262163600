<div class="heading-20 mb-3" data-automation-id="multiple-master-suggester-heading">
  {{ heading }}
</div>

<f-multiple-suggester
  [items]="items"
  [idField]="idField"
  [linkPath]="linkPath"
  [valuePath]="valuePath"
  [categoryPath]="categoryPath"
  [placeholder]="placeholder"
  [isOutlineInput]="isOutlineInput"
  [customFilterMode]="customFilterMode"
  [maxResultsCount]="maxResultsCount"
  [maxSelectedItemsCount]="maxSelectedItemsCount"
  [actionsForAllList]="actionsForAllList"
  [actionsForCategories]="actionsForCategories"
  [showContactSupportLink]="showContactSupportLink"
  [clearUnmatchedQueryOnBlur]="clearUnmatchedQueryOnBlur"
  [filterListOnFirstInteraction]="filterListOnFirstInteraction"
  [showPredefinedSet]="showPredefinedSet"
  [selectedItems]="selectedItems || []"
  [dropdownCssClass]="dropdownCssClass"
  [listCssClass]="listCssClass"
  [popoverPlacement]="popoverPlacement"
  [popoverContainer]="popoverContainer"
  [popoverAutoClose]="popoverAutoClose"
  [showViewMore]="showViewMore"
  [debounceTime]="debounceTime"
  [disabledPath]="disabledPath"
  [isRequired]="isRequired"
  [validation]="validation"
  [inputValue]="inputValue"
  [minLength]="minLength"
  [disabled]="disabled"
  [showError]="showError"
  (select)="onSelect($event)"
  (remove)="onRemove($event)"
  (selectMany)="onSelectMany($event)"
  (removeMany)="onRemoveMany($event)"
  (itemClick)="itemClick.emit($event)"
  (contactSupport)="contactSupport.emit()"
  (updateSearch)="updateSearch.emit($event)"
  (viewMore)="viewMore.emit($event)"
  (outclick)="outclick.emit($event)"
  (reset)="reset.emit($event)"
  (focus)="focus.emit($event)"
  data-automation-id="multiple-master-suggester"
  class="ca-multiple-master-suggester"
>
  <ng-template #fSuggesterItemTemplate *ngIf="masterSuggesterItemTemplate" let-inputValue="inputValue" let-item="item">
    <ng-template
      [ngTemplateOutlet]="masterSuggesterItemTemplate"
      [ngTemplateOutletContext]="{
        $implicit: item,
        inputValue,
        firstLineId: 'first-line',
        secondLineId: 'second-line'
      }"
    ></ng-template>
  </ng-template>
</f-multiple-suggester>

<f-list class="p-0" *ngIf="customSelectedItemTemplate; else regularSelectedItemTemplate">
  <ng-template [ngTemplateOutlet]="customSelectedItemTemplate"></ng-template>
</f-list>

<ng-template #regularSelectedItemTemplate>
  <f-list
    *ngIf="hasGroupedSelectedData"
    class="p-0"
    [withDividers]="!selectedItemTemplate"
    [withConcisedItems]="!selectedItemTemplate"
    [ngClass]="selectedItemsListClass"
  >
    <ng-container *ngFor="let group of groupedSelectedData?.groupedItems; let first = first; let last = last">
      <label
        *ngIf="group.name"
        [class.mt-1]="!first"
        class="heading-20 mb-3"
        data-automation-id="selected-items-group-name"
      >
        <ng-template
          *ngIf="selectedItemsGroupHeaderTemplate; else defaultGroupHeader"
          [ngTemplateOutlet]="selectedItemsGroupHeaderTemplate"
          [ngTemplateOutletContext]="{ $implicit: group.name, first, last }"
        ></ng-template>

        <ng-template #defaultGroupHeader>
          {{ group.name }}
        </ng-template>
      </label>

      <ng-container *ngFor="let item of group.value; let first = first; let last = last">
        <ng-container
          [ngTemplateOutlet]="selectedItemTemplate ? selectedItemTemplateRef : defaultSelectedItemTemplateRef"
          [ngTemplateOutletContext]="{ $implicit: item, first, last }"
        >
        </ng-container>
      </ng-container>
    </ng-container>
  </f-list>
</ng-template>

<ng-template #selectedItemTemplateRef let-item let-first="first" let-last="last">
  <div [ngClass]="selectedItemRootClass" data-automation-id="f-list-item" class="d-flex" [class.mb-3]="!last">
    <div class="flex-grow-1">
      <ng-template
        [ngTemplateOutlet]="selectedItemTemplate"
        [ngTemplateOutletContext]="{ $implicit: item, first, last }"
      ></ng-template>
    </div>

    <ng-container
      [ngTemplateOutlet]="
        hasSelectedItemDeleteBtnTemplate ? $any(selectedItemDeleteBtnTemplate) : defaultDeleteBtnTemplate
      "
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #defaultSelectedItemTemplateRef let-item let-last="last">
  <f-list-item-concised [ngClass]="selectedItemRootClass" (removeItem)="onRemove(item)" [class.mb-3]="!last">
    <span>{{ item.name }}</span>
  </f-list-item-concised>
</ng-template>

<ng-template #defaultDeleteBtnTemplate let-item>
  <button
    *ngIf="selectedItemDeleteBtnTemplate !== null"
    class="btn btn-icon btn-secondary ms-2 align-self-end"
    (click)="onRemove(item)"
    type="button"
  >
    <i class="f-i f-i-trash-bin"></i>
  </button>
</ng-template>
