import { Security, SecurityFormattingType } from '../models';

export function getSecurityName(security: Security | null | undefined, formattingType?: SecurityFormattingType) {
  if (!security) {
    return '';
  }

  if (formattingType === SecurityFormattingType.Long && security.ticker && security.name) {
    return `${security.ticker} – ${security.name}`;
  }

  if (security.ticker) {
    return security.ticker;
  }

  if (security.name) {
    return security.name;
  }

  return security.id.toString();
}
