import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FireflyKendoCellInteractiveDirective } from './interactive-kendo-cell.directive';
import { PopupHorizontalScrollModule } from './popup-horizontal-scroll/popup-horizontal-scroll.module';

@NgModule({
  imports: [CommonModule, PopupHorizontalScrollModule],
  declarations: [FireflyKendoCellInteractiveDirective],
  exports: [FireflyKendoCellInteractiveDirective, PopupHorizontalScrollModule],
  providers: [NgbPopover]
})
export class FireflyInteractiveCellModule {}
