import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { COMMON_SELECTED_SECURITY_FEATURE_KEY, reducer } from './+state/reducer';
import { SelectedSecurityEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(COMMON_SELECTED_SECURITY_FEATURE_KEY, reducer),
    EffectsModule.forFeature([SelectedSecurityEffects])
  ]
})
export class CommonSelectedSecurityModule {}
