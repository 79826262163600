import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CommonBrowserTitleModule } from '@capital-access/common/browser';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonRouterModule, provideAuthGuard } from '@capital-access/common/router';
import { LicenseAgreementEffects } from './+store/effects';
import { LicenseAgreementComponent } from './components/license-agreement.component';
import {
  LICENSE_AGREEMENT_LOCALIZATION_SCOPE,
  LICENSE_AGREEMENT_PAGE_ROUTE,
  LICENSE_AGREEMENT_PREFERENCE_SECTION
} from './models/constants';
import { LicenseAgreementGuard } from './services/guard';
import { LicenseAgreementPageTitle } from './services/page-title';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule.forFeature(LICENSE_AGREEMENT_LOCALIZATION_SCOPE),
    CommonRouterModule.forFeature({
      anonymous: [
        { path: LICENSE_AGREEMENT_PAGE_ROUTE, component: LicenseAgreementComponent, data: { navigation: false } }
      ]
    }),
    CommonBrowserTitleModule.forFeature(LicenseAgreementPageTitle),
    EffectsModule.forFeature([LicenseAgreementEffects])
  ],
  declarations: [LicenseAgreementComponent],
  providers: [provideAuthGuard(LicenseAgreementGuard)],
  exports: [LicenseAgreementComponent]
})
export class LicenseAgreementModule {
  static forRoot(config: { preferenceSection: string }): ModuleWithProviders<LicenseAgreementModule> {
    return {
      ngModule: LicenseAgreementModule,
      providers: [{ provide: LICENSE_AGREEMENT_PREFERENCE_SECTION, useValue: config.preferenceSection }]
    };
  }
}
