import { inject } from '@angular/core';
import { defer } from 'rxjs';
import { map, retry, share } from 'rxjs/operators';
import { UserNotification } from '@capital-access/notifications/common';
import { OIDC_NOTIFICATION_API_AUTH_PROVIDER } from '../api/auth-providers';
import { NotificationsApi } from '../api/notifications.api';
import { onNotificationQuery } from '../api/queries.graphql';
import { parseUserNotification } from '../helpers/parser.utils';

export function provideUserNotifications() {
  const api = inject(NotificationsApi);
  const oidcAuthProvider = inject(OIDC_NOTIFICATION_API_AUTH_PROVIDER);

  return defer(() =>
    api.subscribe<{ onNotification: UserNotification<string> }>(oidcAuthProvider, onNotificationQuery)
  ).pipe(
    map(x => parseUserNotification(x.onNotification)),
    retry({
      delay: 3_000 // 3 second delay
    }),
    share()
  );
}
