import { InjectionToken } from '@angular/core';
import { LocalizedString } from '@capital-access/common/localization';

export const BROWSER_TITLE_FEATURE = 'common.browser-title';
export const BROWSER_TITLE_TAG_KEY = 'common:browser:title';

export const BROWSER_TITLE_SEPARATOR = new InjectionToken<string>('Common Browser Title separator string');
export const BROWSER_TITLE_FALLBACK_VALUE = new InjectionToken<TitlePartString>('Common Browser Title fallback value');

/**
 * Title part string in form of plain value, localized string or array of strings or localized strings
 */
export type TitlePartString = string | LocalizedString | (string | LocalizedString)[];

/**
 * Title part object which allows to completely override browser title
 */
export type TitlePartObject = { value: TitlePartString; override: boolean };

/**
 * Title part
 */
export type TitlePart = TitlePartString | TitlePartObject;

export type TitleSource = { titleParts: TitlePart[]; scopes: Record<string, boolean> };

export interface ResolvedTitlePart {
  value: string | LocalizedString;
  override: boolean;
}
