import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChartDataEntry, FireflyStackedBarSeriesComponent } from '..';
import { ChartCircleDto } from '../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-positive-negative-bar-series]',
  templateUrl: 'positive-negative-bar-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyPositiveNegativeBarSeriesComponent extends FireflyStackedBarSeriesComponent {
  @Input() showPointerLine = false;
  @Input() lineClasses!: string[];
  @Input() seriesId = 0;

  update(): void {
    const step = this.xScale.step();
    const bandWidth = Math.round(this.xScale.bandwidth());
    const width = bandWidth > this.maxBarWidth ? this.maxBarWidth : bandWidth;

    this._bars = this.series.map(d => {
      const name = d.name;
      const x = this.xScale(name)! + (bandWidth - width) / 2 || 0;
      const halo = {
        x: x - (step - width) / 2,
        width: step
      };
      let stackHeight = 0;
      let y = this.yScale(0);
      let stackY = this.yScale(0);

      const data = (d.series as ChartDataEntry[]).map((entry, index) => {
        const gap = 0.5;
        const barHeight = this.yScale(0) - this.yScale(entry.value!);
        const barClass = `${this.barClasses[index]} ${entry.cssClass ?? ''}`;

        stackHeight += Math.abs(barHeight) + gap;

        if (barHeight > 0) {
          stackY = y -= barHeight + gap;
          return { height: barHeight, y, barClass, entryData: { ...entry, cssClass: barClass } };
        } else {
          y = this.yScale(0) + gap;
          return { height: Math.abs(barHeight), y, barClass, entryData: { ...entry, cssClass: barClass } };
        }
      });

      return { stack: { height: stackHeight, y: stackY }, x, width, name, data, halo };
    });

    if (!this.showOptionalData) return;

    this.optionalData = this._bars.map((d, index) => {
      const cx = d.x + d.width / 2 || 0;
      const cy = d.stack.y - this.bubbleRadius - 4;
      const cssClass = [...this.barBubbleClasses, this.series[index].optional?.cssClass ?? ''].filter(Boolean);
      return { cx, cy, name: d.name, value: this.series[index].optional?.value, cssClass };
    }) as ChartCircleDto[];
  }

  getModalContext(activeBarIndex: number, sliceStartIndex: number, sliceEndIndex: number) {
    const context = super.getModalContext(activeBarIndex, sliceStartIndex, sliceEndIndex);
    return {
      ...context,
      lineClasses: this.lineClasses,
      showPointerLine: this.showPointerLine
    };
  }
}
