import { ProfileSide } from '../models/information.enums';
import { CompositeId } from './composite-id';
import { ProfileType } from './profile-id.models';

export class ProfileId {
  constructor(
    private _id: CompositeId,
    private _type: ProfileType,
    private _name: string | null = null,
    private _side: ProfileSide | null = null
  ) {}

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get name() {
    return this._name;
  }

  get side() {
    return this._side;
  }

  toString() {
    return ProfileId.toString(this);
  }

  static toString(profileId: ProfileId) {
    return `${CompositeId.toString(profileId.id)}|${profileId.type}`;
  }

  equals(profileId: ProfileId) {
    return ProfileId.equals(this, profileId);
  }

  static equals(firstId: ProfileId | null, secondId: ProfileId | null) {
    if (firstId === secondId) {
      return true;
    }

    if (!firstId || !secondId) {
      return false;
    }

    return firstId.toString() === secondId.toString() && firstId.name === secondId.name;
  }
}
