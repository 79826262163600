import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import ResizeObserver from 'resize-observer-polyfill';
import { getTicks } from '../axis';
import { FireflyBaseChartComponent } from '../base-components/base-chart-component';
import type { ChartDimensions } from '../models/common-chart-models';

@Component({
  selector: 'f-chart',
  templateUrl: './chart-boilerplate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyChartBoilerplateComponent
  extends FireflyBaseChartComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() xScale!: ScaleBand<string>;
  @Input() yScale!: ScaleLinear<number, number>;
  @Input() yLineScale!: ScaleLinear<number, number>;
  @Input() lineAxisLabelText!: string;
  @Input() showLineAxisGridlines = false;
  @Input() lineAxisTickFormatting!: (d: unknown) => string;
  @Input() showBorders = false;
  @Input() legendRootClass = '';
  @Input() condensedAxisOnMobile = true;
  @Input() startLineAxisDomainWithZero = true;

  @ViewChild('legendContainer', { static: true, read: ElementRef }) legendContainer!: ElementRef;

  yAxisWidth = 0;
  xAxisHeight = 0;
  labelYOffset = 0;
  minTicksCount = 6;
  lineYAxisWidth = 0;
  lineYAxisLabelWidth = 0;
  yAxisLabelHeight = 10;
  yAxisLabelWidth = 0;
  chartIsVisible = false;
  dimensions!: ChartDimensions;

  @Output() dimensionsChanged = new EventEmitter();

  private resizeObserver: ResizeObserver = new ResizeObserver(() => this.onContainerResize());

  ngOnInit() {
    setTimeout(() => {
      if (this.fitContainer) {
        this.view = this.getContainerDims();

        this.zone.runOutsideAngular(() => {
          this.resizeObserver.observe(this.containerElement);
        });
      }

      this.dimensions = { ...this.view };
      this.cdr.detectChanges();
    });
  }

  ngOnChanges() {
    if (this.fitContainer) {
      this.view = this.getContainerDims();
    }

    this.update();
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  xAxisHeightChanged({ height }: { height: number }) {
    this.xAxisHeight = height;
    this.update();
  }

  yAxisWidthChanged({ width, label }: { width: number; label: { width: number; height: number } }) {
    if (label.height > this.yAxisLabelHeight) {
      this.yAxisLabelHeight = label.height;
    }
    this.yAxisLabelWidth = label.width;
    this.yAxisWidth = width;
    this.update();
  }

  lineYAxisWidthChanged({ width, label }: { width: number; label: { width: number; height: number } }) {
    if (label.height > this.yAxisLabelHeight) {
      this.yAxisLabelHeight = label.height;
    }
    this.lineYAxisLabelWidth = label.width;
    this.lineYAxisWidth = width;
    this.update();
  }

  update() {
    if ((this.showXAxisTicksLabels && !this.xAxisHeight) || (this.showYAxisTicksLabels && !this.yAxisWidth)) return;

    this.labelYOffset = this.showLabels && (this.leftAxisLabelText || this.lineAxisLabelText) ? this.labelsOffset : 0;

    this.dimensions = {
      width: this.view.width - this.yAxisWidth - (this.lineYAxisWidth || 0),
      height: this.view.height - this.xAxisHeight - this.labelYOffset - this.yAxisLabelHeight - this.legendHeight
    };

    setTimeout(() => {
      this.chartIsVisible = this.yLineScale! ? (this.showYAxisTicksLabels ? !!this.lineYAxisWidth : true) : true;
      this.dimensionsChanged.emit(this.dimensions);
      this.cdr.detectChanges();
    });
  }

  get topPadding(): number {
    const showLabels = this.showLabels && (!!this.leftAxisLabelText || !!this.lineAxisLabelText);
    return showLabels ? this.labelsOffset + this.yAxisLabelHeight : 10;
  }

  get legendStyles() {
    const bottomLegendStyles = { display: 'flex', 'width.%': 100 };
    const topLegendStyles = {
      position: 'absolute',
      transform: `translateX(${this.yAxisLabelWidth}px)`,
      width: `calc(100% - ${this.yAxisLabelWidth + this.lineYAxisLabelWidth}px)`,
      left: 0
    };
    return this.legendOptions?.legendPosition === 'top' ? topLegendStyles : bottomLegendStyles;
  }

  get lineAxisTicks() {
    return getTicks(this.yScale, this.maxYAxisTicksCount).map(tick => {
      return this.yLineScale.invert(this.yScale(tick));
    });
  }

  get legendHeight() {
    if (!this.showLegend) return 0;
    const height = parseInt(this.legendContainer.nativeElement.getBoundingClientRect().height, 10) || 0;
    return height || 0;
  }

  get translate() {
    return `translate(${this.yAxisWidth}, ${this.topPadding})`;
  }

  get chartWidth() {
    return this.dimensions.width + this.yAxisWidth + this.lineYAxisWidth;
  }

  get chartHeight() {
    return this.dimensions.height + this.topPadding + this.xAxisHeight;
  }

  private onContainerResize() {
    if (!this.fitContainer) return;

    this.zone.runOutsideAngular(() => {
      this.view = this.getContainerDims();
      window.requestAnimationFrame(() => {
        this.dimensions = {
          width: this.view.width - (this.yAxisWidth || 0) - (this.lineYAxisWidth || 0),
          height:
            this.view.height - (this.xAxisHeight || 0) - this.labelYOffset - this.yAxisLabelHeight - this.legendHeight
        };
        this.dimensionsChanged.emit(this.dimensions);
        this.cdr.detectChanges();
      });
    });
  }
}
