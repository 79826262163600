import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkeletonLoaderShade } from '../../models/skeleton-loader.enum';

@Component({
  selector: 'f-skeleton-large-card-with-text-two-columns',
  template: `
    <div class="container-fluid heading-50 p-0">
      <div class="row mb-1">
        <f-skeleton class="col-6" [shade]="shade"></f-skeleton>
        <f-skeleton class="col-5 offset-1" [shade]="shade"></f-skeleton>
      </div>
      <div class="row mb-1">
        <f-skeleton class="col-5" [shade]="shade"></f-skeleton>
        <f-skeleton class="col-4 offset-2" [shade]="shade"></f-skeleton>
      </div>
      <div class="row mb-1">
        <f-skeleton class="col-5" [shade]="shade"></f-skeleton>
        <f-skeleton class="col-4 offset-2" [shade]="shade"></f-skeleton>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySkeletonLargeCardWithTextTwoColumnsComponent {
  @Input() shade: SkeletonLoaderShade = SkeletonLoaderShade.Light;
}
