import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-grid';
import { ExportableColumnDirective } from '../exportable/exportable-column.directive';
import { CustomFieldsColumn } from './custom-fields-columns.model';

export function columnsFactory(baseColumnsHost: { columns: ColumnBase[] }) {
  return baseColumnsHost.columns;
}

@Component({
  selector: 'ca-custom-fields-columns',
  templateUrl: './custom-fields-columns.component.html',
  providers: [
    {
      provide: ColumnBase,
      useFactory: columnsFactory,
      deps: [CustomFieldsColumnsComponent]
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldsColumnsComponent implements OnInit {
  @ViewChildren(ExportableColumnDirective) exportableColumns!: QueryList<ExportableColumnDirective>;
  @ViewChildren(ColumnComponent) columnQuery!: QueryList<ColumnComponent>;

  columns: ColumnComponent[] = [];

  @Input() media!: string;
  @Input() customFieldsColumns: CustomFieldsColumn[] = [];

  @Input() sortable = false;
  @Input() valuePath = 'customFields';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.columns = this.columnQuery.toArray();
  }
}
