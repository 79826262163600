import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FireflyBarComponent } from '../bar/bar.component';

@Component({
  selector: 'f-bar-with-arrow-pointer',
  templateUrl: './bar-with-arrow-pointer.component.html',
  animations: [
    trigger('moveArrow', [
      state('*', style({ left: '{{left}}%' }), { params: { left: 0.1 } }),
      transition('* <=> *', animate('{{duration}}ms {{delay}}ms ease-out'), { params: { duration: 0, delay: 0 } })
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyBarWithArrowPointerComponent extends FireflyBarComponent {
  @Input() pointerPopover!: string | TemplateRef<unknown>;
  @Input() pointerValue!: number;
  @Input() mobilePopoverTitle = '';

  get arrowAnimationState() {
    return `change-arrow-position-to${this.pointerValue}`;
  }

  get boundedArrowPosition() {
    if (this.pointerValue < 0) {
      return 0.1;
    } else if (this.pointerValue > 100) {
      return 99.9;
    } else {
      return this.pointerValue;
    }
  }
}
