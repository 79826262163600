import { DateRange } from './date-range.model';
import { SyncStatus } from './sync-status.enum';

export interface Filter {
  range: DateRange;
  calendars: string[];
}

export interface AutoFilter {
  calendars: string[];
  startDate: Date;
}

export enum FilterType {
  Calendars = 'calendars',
  Range = 'range',
  Status = 'status'
}

export interface SyncFilters {
  [FilterType.Calendars]?: string[];
  [FilterType.Range]?: DateRange;
  [FilterType.Status]?: SyncStatus[];
}
