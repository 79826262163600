import { InjectionToken } from '@angular/core';

export interface Settings {
  bdcUrl: string;
  profiles: ProfilesSettings;
}

export interface ProfilesSettings {
  hideCardsInDevelopment: boolean;
}

export const PROFILES_SETTINGS = new InjectionToken<ProfilesSettings>('Profiles settings');
