import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkeletonAnimation } from '@progress/kendo-angular-indicators';
import { SkeletonLoaderShade, SkeletonLoaderSize } from '../models/skeleton-loader.enum';

@Component({
  selector: 'f-skeleton',
  template: `
    <kendo-skeleton
      [shape]="shape"
      [animation]="skeletonAnimation"
      [width]="shape === 'rectangle' ? width : ''"
      [height]="shape === 'rectangle' ? height : ''"
      [ngClass]="[size, shade]"
    ></kendo-skeleton>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySkeletonLoaderComponent {
  @Input() shape: 'text' | 'rectangle' = 'text';
  @Input() width: string | number = '100%';
  @Input() height: string | number = '100%';
  @Input() size: SkeletonLoaderSize = SkeletonLoaderSize.Default;
  @Input() shade: SkeletonLoaderShade = SkeletonLoaderShade.Light;
  skeletonAnimation: SkeletonAnimation = 'wave';
}
