import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import isNull from 'lodash/isNull';
import { FireflyBaseBarSeriesComponent } from '../base-components/base-bar-series.component';
import type { ChartBar, ChartCircleDto, ChartDataEntry } from '../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-bar-series]',
  templateUrl: 'bar-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyBarSeriesComponent extends FireflyBaseBarSeriesComponent implements OnChanges {
  @Input() seriesId = 0;
  @Input() series!: ChartDataEntry[];
  @Input() barClasses: string[] = [];
  @Input() showOptionalData = false;
  @Input() bubblesAnimationDuration = 90;
  @Input() barBubbleClasses: string[] = ['bg-primary-100'];
  @Input() barBubbleTextClasses: string[] = ['color-primary-800'];
  @Input() barBubbleScaleFactor = 1;

  _bars: ChartBar[] = [];
  optionalData: ChartCircleDto[] = [];

  ngOnChanges() {
    this.update();
  }

  update(): void {
    const step = this.xScale.step();
    const bandWidth = this.xScale.bandwidth();
    const width = bandWidth > this.maxBarWidth ? this.maxBarWidth : bandWidth;

    this._bars = this.series.map(d => {
      const name = d.name;
      const value = isNull(d.value) ? 0 : d.value!;
      const x = this.xScale(name)! + (bandWidth - width) / 2 || 0;
      const halo = {
        x: x - (step - width) / 2,
        width: step
      };
      const cssClass = value ? d.cssClass : `${d.cssClass} f-rect-bar-empty`;
      const height = this.dimensions.height - this.yScale(value);
      const barClass = [...this.barClasses, cssClass || ''].filter(Boolean);
      return { x, width, name, height, barClass, halo, empty: !value };
    });

    if (!this.showOptionalData) return;

    this.optionalData = this.series.map(d => {
      const value = isNull(d.value) ? 0 : d.value!;
      const cx = this.xScale(d.name)! + bandWidth / 2 || 0;
      const cy = (this.yScale(value)! || 0) - this.bubbleOffset;
      const cssClass = [...this.barBubbleClasses, d.optional?.cssClass ?? ''].filter(Boolean);
      return { cx, cy, name: d.name, value: d.optional?.value, cssClass };
    }) as ChartCircleDto[];
  }

  popoverContextData(index: number, options?: { start: number; end: number }) {
    if (!options) {
      const cssClass = this._bars[index].barClass;
      const bubbleClass = this.optionalData[index]?.cssClass ?? [];
      return { ...this.series[index], bubbleTextClass: this.barBubbleTextClasses, bubbleClass, cssClass };
    }

    const cssClass = this._bars.slice(options.start, options.end)[index].barClass;
    const bubbleClass = this.optionalData.slice(options.start, options.end)[index]?.cssClass ?? [];
    return {
      ...this.series.slice(options.start, options.end)[index],
      bubbleTextClass: this.barBubbleTextClasses,
      bubbleClass,
      cssClass
    };
  }
}
