import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { openInstitutionPreview } from '@capital-access/common/actions';
import { CompositeId } from '@capital-access/profiles/common';
import { Institution, SearchCategory } from '../../../+state/models';
import { SearchItemsListBaseComponent } from '../search-items-list-base-component/search-items-list-base.component';

@Component({
  selector: 'ca-search-institutions-list',
  templateUrl: './search-institutions-list.component.html',
  styleUrls: ['./../search-list.common.scss']
})
export class SearchInstitutionsListComponent extends SearchItemsListBaseComponent<Institution> {
  constructor(store: Store) {
    super(store, SearchCategory.Institutions);
  }

  openProfilePreview(institution: Institution) {
    const { crmId, prospectingId: prId } = institution;
    this.store.dispatch(openInstitutionPreview({ id: { prId, crmId } }));
  }

  trackByMethod(index: number, item: Institution): string {
    const compositeId = new CompositeId(item.prospectingId, item.crmId);
    return compositeId.toString();
  }
}
