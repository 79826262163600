import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { DateFormatOptions } from '../models/date-formatting.models';
import { DateTimeFormattingService } from '../services/date-time-formatting.service';

@Pipe({
  name: 'caDate',
  pure: false
})
export class DatePipe
  extends BaseAsyncPipe<string, DateTime | string | null, [DateFormatOptions?]>
  implements PipeTransform
{
  constructor(private dateTimeFormattingService: DateTimeFormattingService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  getTransformer(value: DateTime | string | null, options?: DateFormatOptions): Observable<string> {
    return this.dateTimeFormattingService.formatDate(value, options);
  }
}
