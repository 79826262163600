import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { GridComponent } from '@progress/kendo-angular-grid';
import { of, Subject, takeUntil } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';

@Component({
  selector: 'f-mobile-table-row',
  templateUrl: './mobile-table-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTableRowComponent implements OnInit, OnDestroy {
  @Input() id!: number;
  @Input() isExpanded = false;
  @Output() toggledRow = new EventEmitter<number | null>();

  private grid = inject(GridComponent);
  private store = inject(Store, { optional: true });
  private respectStickyRows = true;
  private destroyed$ = new Subject<void>();

  private get gridContent(): HTMLElement {
    return this.grid.wrapper.nativeElement.getElementsByClassName('k-grid-content')[0];
  }

  private get stickyRowCount(): number {
    return this.grid.wrapper.nativeElement.getElementsByClassName('k-grid-row-sticky').length;
  }

  ngOnInit(): void {
    (this.store?.select(getFeatureToggle('mobile-scroll-behavior-CPD-2028')) || of(true))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(enabled => (this.respectStickyRows = !enabled));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleColumnData(): void {
    let targetRowId = this.id;
    this.isExpanded = !this.isExpanded;

    targetRowId = this.checkIfRowSticky(targetRowId);

    if (this.isExpanded) {
      this.toggledRow.emit(this.id);
      this.scrollToRow(this.respectStickyRows ? targetRowId - this.stickyRowCount : this.id);
    } else {
      this.toggledRow.emit(null);
    }
  }

  private checkIfRowSticky(row: number): number {
    if (this.gridContent.getElementsByTagName('tr')[row].classList.contains('k-grid-row-sticky')) {
      return (row += this.stickyRowCount);
    }
    return row;
  }

  private scrollToRow(index: number): void {
    requestAnimationFrame(() =>
      this.gridContent.getElementsByTagName('tr')[index].scrollIntoView({ behavior: 'auto' })
    );
  }
}
