import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import isNull from 'lodash/isNull';
import type { ChartDataEntry, ChartDataSeries } from '../common';
import { stackedBarClasses } from '../common';
import { FireflyBaseChartComponent } from '../common/base-components/base-chart-component';

@Component({
  selector: 'f-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyStackedBarChartComponent extends FireflyBaseChartComponent {
  @Input() data!: ChartDataSeries[];
  @Input() gapBetweenSeries = true;
  @Input() limitedBarMaxWidth = true;
  @Input() infoBubbleScaleFactor = 1;
  @Input() barClasses: string[] = stackedBarClasses;
  @Input() infoBubbleClasses: string[] = ['f-bg-bubble'];
  @Input() infoBubbleTextClasses: string[] = ['f-text-bubble', 'fw-bold'];

  protected legendRootClass = 'f-stacked-bar-chart-legend';

  @Output() barClick: EventEmitter<{ data: ChartDataSeries } | undefined | null> = new EventEmitter();

  chartComponent = FireflyStackedBarChartComponent;

  onStackedBarClick($event: { data: ChartDataSeries } | null) {
    this.barClick.emit($event);
  }

  protected shouldAdjustYScaleDomain(threshold: number) {
    const optionalData = [...this.data].filter(d => !!d.optional);
    if (!optionalData.length) return;
    const accValueData = optionalData.map(item => ({
      value: (item.series as ChartDataEntry[]).reduce((acc, i) => {
        const value = isNull(i.value) ? 0 : i.value!;
        return acc + value;
      }, 0)
    }));
    return accValueData.some(d => (d.value as number) > threshold);
  }
}
