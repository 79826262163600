import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyAxisModule } from '../axis/axis.module';
import { FireflyLegendModule } from '../legend/legend.module';
import { FireflyChartBoilerplateComponent } from './chart-boilerplate.component';

@NgModule({
  imports: [CommonModule, FireflyAxisModule, FireflyLegendModule],
  declarations: [FireflyChartBoilerplateComponent],
  exports: [FireflyChartBoilerplateComponent]
})
export class FireflyChartBoilerplateModule {}
