import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyEmptyStatesModule } from '../empty-states';
import { FireflyInnerErrorComponent } from './inner-error/inner-error.component';
import { FireflyInnerErrorDirective } from './inner-error/inner-error.directive';
import { FireflyMobileInnerErrorComponent } from './mobile-inner-error/mobile-inner-error.component';

@NgModule({
  imports: [CommonModule, FireflyEmptyStatesModule],
  declarations: [FireflyInnerErrorDirective, FireflyInnerErrorComponent, FireflyMobileInnerErrorComponent],
  exports: [FireflyInnerErrorDirective, FireflyInnerErrorComponent, FireflyMobileInnerErrorComponent]
})
export class FireflyInnerErrorModule {}
