import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {
  private defaultSystemSettings = {
    contactSupport: {
      phones: {
        americas: '+1 (800) 305-0819',
        asiaPacific: '+852-3742-1068',
        europe: '+44 20 7665 9822',
        japan: '+81-3-6262-1790'
      },
      email: 'CallMeNowIR@spglobal.com'
    },
    licenseAgreement: {
      privacyPolicyUrl: 'https://www.spglobal.com/en/privacy/privacy-policy-english',
      termsOfUseUrl: 'https://www.spglobal.com/en/terms-of-use',
      gcpTermsOfUseUrl: 'https://www.spglobal.com/en/enterprise/Legal/gcp-products-terms-of-use.html'
    },
    esg: {
      csaMethodologyUrl: 'https://www.spglobal.com/esg/csa/methodology/',
      fundEsgWamUrl: 'https://portal.s1.spglobal.com/survey/documents/SPG_S1_Fund_ESG_WAM_Methodology.pdf',
      sustainabilityServicesUrl:
        'https://pages.marketintelligence.spglobal.com/Sustainability-CIQPro-Request-More-Information-Demo-Request.html',
      csaUrl: 'https://www.spglobal.com/esg/csa/',
      csaPrivateUrl: 'https://www.spglobal.com/esg/csa/getting-an-assessment#private',
      capitalIQHost: 'https://www.capitaliq.spglobal.com'
    }
  };

  getSettings() {
    return this.defaultSystemSettings;
  }
}
