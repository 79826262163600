import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FireflyModalService, ModalRef } from '../../modal';
import { Breakpoint, FireflyLocalizationService } from '../../utils';
import { FireflyMobileDropdownComponent } from './mobile-dropdown.component';

@Component({ template: '' })
export class FireflyBaseDropdownComponent implements OnInit, OnDestroy {
  protected destroyed$: Subject<void> = new Subject();
  protected modalComponent = FireflyMobileDropdownComponent;
  protected modalDialogClass = 'mobile-dropdown-modal';
  protected modal!: ModalRef<unknown> | null;

  private defaultModalTitle = 'Menu';

  @Input() icon: string = '';
  @Input() iconAfterTitle = '';
  @Input() text: string = '';
  @Input() tooltip: string | null = '';
  @Input() btnStyle: string = 'btn-primary';
  @Input() mobileModalClass: string = 'mobile';
  @Input() mobileMenuTitle: string = '';
  @Input() btnMaxWidth?: number;
  @Input() disabled: boolean = false;
  @Input() placement: string = 'bottom-right';
  @Input() autoClose: boolean | 'outside' | 'inside' = true;
  @Input() hideCaret: boolean = false;
  @Input() preventMobile = false;
  @Input() container!: 'body';

  @ViewChild(NgbDropdown, { static: true, read: NgbDropdown }) private dropdown!: NgbDropdown;
  @ViewChild(NgbDropdownMenu, { static: true, read: NgbDropdownMenu }) private dropdownMenu!: NgbDropdownMenu;

  get isOpen() {
    return this.dropdown.isOpen();
  }

  get iconClass() {
    if (this.icon && this.text) return 'btn-with-icon';
    else if (this.icon) return 'btn-icon';
    else return '';
  }

  get openChange() {
    return this.dropdown?.openChange;
  }

  get hostTitle() {
    return this.host.nativeElement.getAttribute('title');
  }

  constructor(
    protected host: ElementRef,
    protected cdr: ChangeDetectorRef,
    private modalService: FireflyModalService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  ngOnInit() {
    if (this.preventMobile) return;

    this.localizationService
      ?.localize('mobileMenuTitle', {})
      .pipe(takeUntil(this.destroyed$))
      .subscribe(title => (this.defaultModalTitle = title));

    this.dropdown?.openChange.pipe(takeUntil(this.destroyed$)).subscribe(isOpen => {
      if (isOpen && window.innerWidth < Breakpoint.Sm && !this.modal) {
        this.modal = this.modalService.open({
          title: this.mobileMenuTitle || this.tooltip || this.text || this.hostTitle || this.defaultModalTitle,
          modalDialogClass: `${this.modalDialogClass} ${this.mobileModalClass}`,
          component: this.modalComponent,
          context: this.dropdownMenu,
          mobile: true
        });

        this.modal.result.finally(() => {
          this.modal = null;
          if (!this.autoClose || this.autoClose === 'inside') {
            this.close();
          }
        });
      }
    });
  }

  open() {
    this.dropdown.open();
  }

  close() {
    this.dropdown.close();
  }

  preventAutoClose() {
    const autoClose = this.autoClose;
    this.autoClose = false;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.autoClose = autoClose;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('keyup', ['$event'])
  onEnter($event: KeyboardEvent) {
    const target = $event.target as HTMLElement;
    const eventFromDropdownToggle = Object.hasOwnProperty.call(target.attributes, 'ngbdropdowntoggle');

    if (eventFromDropdownToggle) return;

    if (['Enter', 'Space'].includes($event.code)) {
      if (this.autoClose === 'inside') {
        this.close();
      }
    }
  }
}
