import { Pipe, PipeTransform } from '@angular/core';
import { Security, SecurityFormattingType } from '../models';
import { getSecurityName } from '../utils/security-name';

@Pipe({
  name: 'caSecurityName'
})
export class SecurityNamePipe implements PipeTransform {
  transform(security: Security | null | undefined, formattingType?: SecurityFormattingType) {
    return getSecurityName(security, formattingType);
  }
}
