import { CultureCode } from '@capital-access/common/globalization';
import { DateFormat } from '../models/date-formatting.models';

const individualDateFormats = {
  weekDay: 'EEE',
  month: 'MMM',
  day: 'd',
  year: 'yyyy'
};

export const datePatternOptions: Record<CultureCode, Record<DateFormat, string>> = {
  ['en-US']: {
    default: 'M/d/yyyy',
    short: 'MMM d',
    medium: 'MMM, yyyy',
    long: 'MMM d, yyyy',
    veryLong: 'EEE, MMM d',
    full: 'EEE, MMM d, yyyy',
    ...individualDateFormats
  },
  ['en-GB']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM, yyyy',
    long: 'd MMM, yyyy',
    veryLong: 'EEE, d MMM',
    full: 'EEE, d MMM yyyy',
    ...individualDateFormats
  },
  ['ja']: {
    default: 'yyyy/MM/dd',
    short: 'M月d日',
    medium: 'yyyy年M月',
    long: 'yyyy年M月d日',
    veryLong: 'M月d日EEEE',
    full: 'yyyy年M月d日EEEE',
    weekDay: 'EEEE',
    month: 'M月',
    day: 'd日',
    year: 'yyyy年'
  },
  ['de-DE']: {
    default: 'dd.MM.yyyy',
    short: 'd. MMM',
    medium: 'MMM yyyy',
    long: 'd. MMM yyyy',
    veryLong: 'EEE, d. MMM',
    full: 'EEE, d. MMM yyyy',
    ...individualDateFormats
  },
  ['pt-BR']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: "d 'de' MMM 'de' yyyy",
    veryLong: "EEE, d 'de' MMM",
    full: "EEE, d 'de' MMM 'de' yyyy",
    ...individualDateFormats
  },
  ['fr-FR']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE d MMM',
    full: 'EEE d MMM yyyy',
    ...individualDateFormats
  },
  ['ru']: {
    default: 'dd.MM.yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy г.',
    long: 'd MMM yyyy г.',
    veryLong: 'EEE, d MMM',
    full: 'EEE, d MMM yyyy г.',
    ...individualDateFormats
  },
  ['en-CA']: {
    default: 'yyyy-MM-dd',
    short: 'MMM d',
    medium: 'MMM, yyyy',
    long: 'MMM d, yyyy',
    veryLong: 'EEE, MMM d',
    full: 'EEE, MMM d, yyyy',
    ...individualDateFormats
  },
  ['de-AT']: {
    default: 'dd.MM.yyyy',
    short: 'd. MMM',
    medium: 'MMM yyyy',
    long: 'd. MMM yyyy',
    veryLong: 'EEE, d. MMM',
    full: 'EEE, d. MMM yyyy',
    ...individualDateFormats
  },
  ['fi']: {
    default: 'd.M.yyyy',
    short: 'd. MMM',
    medium: 'MMM yyyy',
    long: 'd. MMM yyyy',
    veryLong: 'EEE d. MMM',
    full: 'EEE d. MMM yyyy',
    ...individualDateFormats
  },
  ['es-ES']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: "d 'de' MMM 'de' yyyy",
    veryLong: "EEE, d 'de' MMM",
    full: "EEE, d 'de' MMM 'de' yyyy",
    ...individualDateFormats
  },
  ['es-MX']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: "d 'de' MMM 'de' yyyy",
    veryLong: "EEE, d 'de' MMM",
    full: "EEE, d 'de' MMM 'de' yyyy",
    ...individualDateFormats
  },
  ['zh-CN']: {
    default: 'yyyy/MM/dd',
    short: 'M月d日',
    medium: 'yyyy年M月',
    long: 'yyyy年M月d日',
    veryLong: 'M月d日EEE',
    full: 'yyyy年M月d日EEE',
    ...individualDateFormats,
    month: 'M月',
    day: 'd日',
    year: 'yyyy年'
  },
  ['es-AR']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: "d 'de' MMM 'de' yyyy",
    veryLong: "EEE, d 'de' MMM",
    full: "EEE, d 'de' MMM 'de' yyyy",
    ...individualDateFormats
  },
  ['da']: {
    default: 'dd/MM/yyyy',
    short: 'd. MMM',
    medium: 'MMM yyyy',
    long: "'d.' d. MMM yyyy",
    veryLong: "EEE 'd.' d. MMM",
    full: "EEE 'd.' d. MMM yyyy",
    ...individualDateFormats
  },
  ['nl']: {
    default: 'dd-MM-yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE d MMM',
    full: 'EEE d MMM yyyy',
    ...individualDateFormats
  },
  ['sv']: {
    default: 'yyyy-MM-dd',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: "'den' d MMM yyyy",
    veryLong: "EEE 'den' d MMM",
    full: "EEE 'den' d MMM yyyy",
    ...individualDateFormats
  },
  ['fr-BE']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE d MMM',
    full: 'EEE d MMM yyyy',
    ...individualDateFormats
  },
  ['de-CH']: {
    default: 'dd.MM.yyyy',
    short: 'd. MMM',
    medium: 'MMM yyyy',
    long: 'd. MMM yyyy',
    veryLong: 'EEE, d. MMM',
    full: 'EEE, d. MMM yyyy',
    ...individualDateFormats
  },
  ['es-CO']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: "d 'de' MMM 'de' yyyy",
    veryLong: "EEE, d 'de' MMM",
    full: "EEE, d 'de' MMM 'de' yyyy",
    ...individualDateFormats
  },
  ['it']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE d MMM',
    full: 'EEE d MMM yyyy',
    ...individualDateFormats
  },
  ['tr']: {
    default: 'dd.MM.yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'd MMM EEE',
    full: 'd MMM yyyy EEE',
    ...individualDateFormats
  },
  ['hi']: {
    default: 'dd-MM-yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE d MMM',
    full: 'EEE d MMM yyyy',
    ...individualDateFormats
  },
  ['en-ZA']: {
    default: 'yyyy/MM/dd',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE d MMM',
    full: 'EEE d MMM yyyy',
    ...individualDateFormats
  },
  ['el']: {
    default: 'dd/MM/yyyy',
    short: 'd MMM',
    medium: 'MMM yyyy',
    long: 'd MMM yyyy',
    veryLong: 'EEE, d MMM',
    full: 'EEE, d MMM yyyy',
    ...individualDateFormats
  }
};
