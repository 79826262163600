import { Injector, ViewContainerRef } from '@angular/core';

export enum DrawerPosition {
  Right = 'right',
  Bottom = 'bottom'
}

export enum DrawerType {
  Filters = 'filters',
  Form = 'form',
  Info = 'info'
}

export interface DrawerConfig {
  title: string;
  titleIconTooltip?: string;
  titleClass?: string;
  titleIconClass?: string;
  titleBadgeClass?: string;
  titleBadgeText?: string;
  type?: DrawerType;
  rootCssClass?: string;
  position: DrawerPosition;
  container?: ViewContainerRef;
  titleWrapperCssClass?: string;
  closeOnClickOutside?: boolean;
  addBackButton?: boolean;
  injector?: Injector;
  zIndex?: number;
  dataLayoutId?: string;
  modalLocalizedInfo?: Record<string, string>;
  controlButtonIconClass?: string;
  showControlButton?: boolean;
  controlButtonTitle?: string;
}

export type DrawerOutput = Record<string, unknown> | undefined | void;
