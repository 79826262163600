import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Renderer2
} from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { take, takeUntil } from 'rxjs/operators';
import { FireflyModalService } from '../../modal';
import { FireflyLocalizationService } from '../../utils';
import { FireflyBaseDateTimeDirective } from './base-date-time-directive';

@Directive({
  selector: '[fDatePicker]'
})
export class FireflyDatePickerDirective extends FireflyBaseDateTimeDirective implements OnInit {
  @Input() focusedDate!: Date;

  @HostBinding('class') class = 'f-date-picker';

  constructor(
    protected host: ElementRef,
    private datePicker: DatePickerComponent,
    protected renderer: Renderer2,
    protected cdr: ChangeDetectorRef,
    protected modalService: FireflyModalService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {
    super(host, datePicker, renderer, cdr, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.valueChange$.pipe(takeUntil(this.destroyed$)).subscribe(date => {
      if (!date) return;
      this.datePicker.focusedDate = date;
    });
    this.localizationService
      ?.localize('datePickerModalTitle', {})
      .pipe(take(1))
      .subscribe(value => (this.modalDialogTitle = value));
  }

  resetDate = () => {
    this.datePicker.focusedDate = this.focusedDate;
    this.datePicker.value = null as unknown as Date;
    this.datePicker.valueChange.emit(null as unknown as Date);
    this.cdr.markForCheck();
    this.datePicker.toggle(false);
  };

  protected resetState() {
    this.datePicker.handleChange(this.initialValue);
  }

  protected handleStateUpdate() {
    this.initialValue = this.datePicker.value!;
  }
}
