import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account } from '../user/models/account';
import { User } from '../user/models/user';
import { UserProfile } from '../user/models/user-profile';
import { UserRepository } from '../user/user.repository';
import { Observable } from 'rxjs';
import { ContactSupportAdvisor } from '../user/models/contact-support-advisor';

@Injectable()
export class DefaultUserRepository implements UserRepository {
  private readonly apiBase = '/api/identity/v1';

  constructor(private http: HttpClient) {}

  getUserProfile() {
    return this.http.get<UserProfile>(`${this.apiBase}/profile`);
  }

  getUserAccount() {
    return this.http.get<Account>(`${this.apiBase}/accounts/current`);
  }

  getUserContactSupportAdvisor(): Observable<ContactSupportAdvisor> {
    return this.http.get<ContactSupportAdvisor>(`${this.apiBase}/accounts/current/cs-advisor`);
  }

  getUserInfoById(id: number) {
    return this.http.get<User>(`${this.apiBase}/users/${id}`);
  }
}
