import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../popovers';
import { FireflyBubbleSeriesModule } from '../bubble-series/bubble-series.module';
import { FireflyBarChartPopoverModule } from '../mobile-popovers/bar-chart-popover/chart-mobile-popover.module';
import { FireflyBarSeriesComponent } from './bar-series.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule, FireflyBubbleSeriesModule, FireflyBarChartPopoverModule],
  declarations: [FireflyBarSeriesComponent],
  exports: [FireflyBarSeriesComponent]
})
export class FireflyBarSeriesModule {}
