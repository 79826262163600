<ng-container caLocalizationScope="search-nav">
  <div (click)="onClickOutside()">
    <div class="ca-one-search-top"></div>
    <div class="ca-one-search-backdrop-body"></div>
  </div>

  <ng-container *ngIf="mobile; else desktop">
    <div data-automation-id="search-results" class="ca-one-search-dropdown">
      <div class="ca-one-search-results mx-4 mx-sm-2 pt-3">
        <div
          *ngIf="hasAnyResults$ | async"
          class="ca-one-search-chips"
          [ngClass]="(hasSelectedEntities$ | async) ? 'mb-4' : 'mb-5'"
        >
          <f-single-choice-chip-group
            [items]="items$ | async"
            [selectedId]="getFilter$ | async"
            [chipSize]="(increasedMobileSizeFT$ | async) ? ChipSize.Sm : ChipSize.Xs"
            (select)="onSelect($event)"
          ></f-single-choice-chip-group>
        </div>
        <ng-container *ngIf="hasFilteredResults$ | async; else noResults">
          <div class="d-flex w-100" [ngClass]="(hasSelectedEntities$ | async) ? 'mb-2' : 'mb-3'">
            <ca-search-popup-header></ca-search-popup-header>
            <f-dropdown
              *ngIf="hasSelectedEntities$ | async"
              [btnStyle]="'btn-secondary btn-sm'"
              [icon]="'f-i-plus-circle'"
              [mobileMenuTitle]="'actions' | caLocalize"
            >
              <ca-action-panel
                [specialActions]="availableSpecialActions$ | async"
                (startSpecialAction)="onStartSpecialAction($event)"
              ></ca-action-panel>
            </f-dropdown>
          </div>
          <div class="overflow-auto" #mobileResultContainer>
            <ng-container *ngTemplateOutlet="searchLists"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #desktop>
    <div data-automation-id="search-results" class="ca-one-search-dropdown">
      <div class="ca-one-search-results mx-4 mx-sm-2 pt-3">
        <div
          *ngIf="(hasAnyResults$ | async) || (showPanel$ | async)"
          class="mb-2 ps-2 d-flex justify-content-between align-items-center overflow-auto"
        >
          <ca-search-popup-header></ca-search-popup-header>
          <button
            type="button"
            class="btn btn-sm btn-secondary btn-with-icon me-2"
            [class.ca-one-search-filter-btn]="isFiltersButtonActive$ | async"
            (click)="toggleFilterPanel()"
            data-automation-id="search-results-filter-toggle-btn"
          >
            <i class="f-i f-i-xs f-i-filter"></i>
            {{ 'filter' | caLocalize }}
          </button>
        </div>
        <div
          *ngIf="hasFilteredResults$ | async; else noResults"
          data-automation-id="search-results-container"
          class="overflow-auto"
        >
          <ng-container *ngTemplateOutlet="searchLists"></ng-container>
        </div>
      </div>
      <ca-panel-container *ngIf="showPanel$ | async"></ca-panel-container>
    </div>
  </ng-template>

  <ng-template #noResults>
    <ca-search-error *ngIf="isSearchFailed$ | async; else noSearchResults"></ca-search-error>
    <ng-template #noSearchResults>
      <ng-container *ngTemplateOutlet="searchLists"></ng-container>
      <p
        *ngIf="!(isAnyCategoryFailed$ | async)"
        class="text-muted p-0 p-sm-2 mb-3"
        data-automation-id="search-no-results"
      >
        {{ 'noSearchResults' | caLocalize }}
      </p>
    </ng-template>
  </ng-template>
</ng-container>

<ng-template #searchLists>
  <ca-search-institutions-list></ca-search-institutions-list>
  <ca-search-contacts-list></ca-search-contacts-list>
  <ca-search-funds-list></ca-search-funds-list>
  <ca-search-events-list></ca-search-events-list>
</ng-template>
