import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ToggleNavItem } from '@capital-access/common/navigation';
import { NAVIGATION_LOCALIZATION_SCOPE } from '../../../navigation.constants';

@Component({
  selector: 'ca-toggle-nav-item',
  templateUrl: './toggle-nav-item.component.html',
  styleUrls: ['../../side-nav-item.scss', './toggle-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleNavItemComponent {
  @Input() navItem!: ToggleNavItem;
  @Input() index = 0;

  @ViewChild('toggleInput') toggleInput!: HTMLInputElement;

  localizationScope = NAVIGATION_LOCALIZATION_SCOPE;

  constructor(private store: Store) {}

  onClick() {
    if (this.navItem.action) {
      this.store.dispatch(this.navItem.action);
    }
  }

  changeToggleValue() {
    this.toggleInput.checked = !this.toggleInput.checked;
  }
}
