import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { FireflyDrawerService } from '@capital-access/firefly/components';
import { BaseReportDrawer } from '../../../components/report-drawer/base-report-drawer';
import { createProfileReport } from '../../+state/profile-report.actions';
import { CreateProfileReportDto } from '../../models/profile-report.models';

@Component({
  templateUrl: './profile-report-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileReportDrawerComponent extends BaseReportDrawer<CreateProfileReportDto> {
  get settingsKey(): string {
    return this.request!.profileType;
  }

  get createReportAction(): Action {
    return createProfileReport({ request: this.request!, sections: this.getSelectedSection });
  }

  constructor(store: Store, drawerService: FireflyDrawerService) {
    super(store, drawerService);
  }
}
