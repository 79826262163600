import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { TranslocoLoader } from '@ngneat/transloco';
import { iif, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { fallbackLang, testLang } from '@capital-access/common/globalization';
import { AppSettingsService } from '@capital-access/common/settings';
import { I18nSettings } from './i18n-settings';
import { LocalizationApiResponse } from './models/localization-api-response.model';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly apiBase = '/api/localization';

  constructor(
    private http: HttpClient,
    private settingService: AppSettingsService,
    @Optional() @Inject(APP_BASE_HREF) private baseHref: string
  ) {}

  getTranslation(path: string) {
    const { loadFromServer, defaultScope } = this.settingService.getSettings<I18nSettings>().i18n;

    const parts = path.split('/');
    let language = parts.pop() as string;
    const scope = parts.join('/') || defaultScope;

    // if test language is specified
    // we should load default language and add some additional treatment to localization entries afterwards
    const isTestLanguage = language === testLang;
    if (isTestLanguage) {
      language = fallbackLang;
    }

    const loadFunc = (loadFromServer ? this.loadFromApi : this.loadFromAssets).bind(this);

    return loadFunc(language, scope).pipe(
      // if first request failed, we'd try to load the fallback language
      // if we already tried to load it, just skip additional loading process
      catchError(err =>
        iif(
          () => language !== fallbackLang,
          loadFunc(fallbackLang, scope),
          throwError(() => err)
        )
      ),
      // if we fail to load anything, it makes sense not to fail, but show at least something to user
      // not the best, but at least application can work in some way
      catchError(() => of(this.emptyResponse)),
      map((translation: LocalizationApiResponse) => this.mapResponse(translation, isTestLanguage))
    );
  }

  private loadFromApi(language: string, scope: string) {
    return this.http
      .get<LocalizationApiResponse>(`${this.apiBase}/${language}/${scope}`)
      .pipe(catchError(() => this.loadFromAssets(language, scope)));
  }

  private loadFromAssets(language: string, scope: string) {
    const baseUrl = this.baseHref ? this.baseHref : '/';
    return this.http.get<LocalizationApiResponse>(`${baseUrl}assets/i18n/${scope}/${language}.json`);
  }

  private mapResponse(response: LocalizationApiResponse, isTestLanguage: boolean) {
    const items = response.items;

    // add additional mapping for 'test' language
    if (isTestLanguage) {
      Object.entries(items).forEach(([key, value]) => {
        items[key] = `•${value}•`;
      });
    }

    return items;
  }

  private get emptyResponse(): LocalizationApiResponse {
    return {
      items: {},
      language: fallbackLang,
      scope: ''
    };
  }
}
