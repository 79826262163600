<div class="ms-n2 ms-sm-0">
  <div class="d-flex align-items-center ps-2" *ngIf="!isMobile || items?.length">
    <span class="badge badge-xs badge-icon {{ icon?.color }}">
      <i class="f-i {{ icon?.class }}"></i>
    </span>
    <h2 class="heading-20 align-text-top ps-2 mt-2 mb-2">{{ header }}</h2>
    <span
      class="link link-primary link-50 ms-4"
      *ngIf="hasMore"
      data-automation-id="onesearch-show-more"
      (click)="onShowMore()"
    >
      {{ showMoreHeader }}
    </span>
  </div>

  <ng-container *ngIf="!!isSearchFailed; else categoryList">
    <div class="ps-2 ps-sm-4 py-3 py-sm-2" [class.ca-search-error]="isMobile && (getFilter$ | async)">
      <ca-search-error></ca-search-error>
    </div>
  </ng-container>
</div>

<ng-template #categoryList>
  <f-list *ngIf="items?.length" class="pt-0 pb-3">
    <f-list-item
      class="d-inline-flex ca-entity-row"
      [class.active]="item.isSelected"
      *ngFor="let item of items; trackBy: trackByFn"
    >
      <div
        class="form-check"
        [class.invisible]="item.category === SearchCategory.Funds || item.category === SearchCategory.Events"
      >
        <input
          class="form-check-input ca-show-on-hover"
          [checked]="item.isSelected"
          type="checkbox"
          (click)="onChangeSelection(item)"
        />
      </div>
      <div class="row w-100 ps-1 overflow-hidden">
        <ng-container
          *ngTemplateOutlet="rowTemplate; context: { item: item, criteria: currentCriteria }"
        ></ng-container>
      </div>
    </f-list-item>
  </f-list>
</ng-template>
