import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LOCALIZATION_KEY } from '../../constants';

@Component({
  selector: 'ca-set-as-default-toggle',
  templateUrl: './set-as-default-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetAsDefaultToggleComponent {
  localizationScope = LOCALIZATION_KEY;
  @Input() disableSaving = false;
  @Input() toggleLabel!: string;
  @Input() control!: FormControl;
  @Input() offsetClasses: string = 'mt-3';
}
