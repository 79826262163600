import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { getTitleSource } from './title.selectors';
import { TitleService } from './title.service';

@Injectable()
export class TitleEffects {
  updateTitle$ = createEffect(
    () =>
      this.store.pipe(
        select(getTitleSource),
        switchMap(source => from(this.titleService.buildTitle(source))),
        tap({
          next: newTitle => this.title.setTitle(newTitle)
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(private store: Store, private titleService: TitleService, private title: Title) {}
}
