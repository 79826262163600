import { APP_BASE_HREF } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BROWSER_TITLE_FALLBACK_VALUE, TitlePartString } from '@capital-access/common/browser';
import { FORBIDDEN_PAGE_ROUTE, INTERNAL_SERVER_ERROR_PAGE_ROUTE } from '@capital-access/common/utils';
import { SideNavService } from '../side-nav.service';

@Component({
  selector: 'ca-logo',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./logo.component.scss'],
  templateUrl: './logo.component.html'
})
export class LogoComponent {
  @Input() plainView = false;
  @Input() appTitle = '';
  @Input() titleCssClass = '';

  icon$ = this.sideNavService.panelExpanded$.pipe(map(expanded => !expanded));

  constructor(
    private router: Router,
    private sideNavService: SideNavService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(FORBIDDEN_PAGE_ROUTE) private forbiddenPageRoute: string,
    @Inject(INTERNAL_SERVER_ERROR_PAGE_ROUTE) private internalErrorPageRoute: string,
    @Optional() @Inject(BROWSER_TITLE_FALLBACK_VALUE) public title: TitlePartString
  ) {}

  toggleButton() {
    this.sideNavService.togglePanelExpanded();
  }

  navigateHome() {
    // Force navigation due to issue https://teamipreo.atlassian.net/browse/CPD-1746
    const match = [this.forbiddenPageRoute, this.internalErrorPageRoute].some(url => location.pathname.includes(url));
    if (match) {
      location.assign(location.origin + this.baseHref ?? '/');
    } else {
      this.router.navigate(['/']);
    }
  }
}
