import { Type } from '@angular/core';
import { TypeGuard } from './types';

/**
 * check if `arg` is array which contains only elements that satisfy `elementTypeGuard`
 */
export function isArray<T = unknown>(arg: unknown, elementTypeGuard: TypeGuard<T>): arg is T[] {
  return Array.isArray(arg) && arg.every(elementTypeGuard);
}

export function isString(arg: unknown): arg is string {
  return typeof arg === 'string' || arg instanceof String;
}

export function isBoolean(arg: unknown): arg is boolean {
  return typeof arg === 'boolean';
}

export function isType<T = unknown>(arg: unknown): arg is Type<T> {
  return arg && typeof arg === 'function' && arg.prototype && !!arg.prototype.constructor;
}

export function isNumber(arg: unknown): arg is number {
  return typeof arg === 'number';
}

// taken from moment.js implementation https://github.com/moment/moment/blob/master/src/lib/utils/is-date.js
export function isDate(arg: unknown): arg is Date {
  return arg instanceof Date || Object.prototype.toString.call(arg) === '[object Date]';
}
