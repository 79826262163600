<div
  class="row flex-nowrap flex-sm-wrap justify-content-between text-muted body-50 mb-3"
  [ngClass]="{ 'mb-sm-2': marginBottomClass === 'primary', 'mb-sm-1': marginBottomClass === 'secondary' }"
  [attr.data-automation-id]="rowID"
>
  <div
    class="col-auto d-flex flex-shrink-1"
    [ngClass]="{
      'col-sm-8': columnBreakdown === 'primary',
      'col-sm-7': columnBreakdown === 'secondary',
      'justify-content-between': !firstContainerWithBadge
    }"
  >
    <div
      *ngIf="!(mobile && firstContainerWithBadge)"
      [class.f-small-card-first-container]="firstContainerWithBadge"
      [attr.data-automation-id]="firstContainerID"
    >
      {{ firstContainerTitle }}
    </div>
    <div
      *ngIf="!mobile || firstContainerWithBadge"
      class="f-small-card-second-container"
      [attr.data-automation-id]="secondContainerID"
    >
      {{ secondContainerTitle }}
    </div>
  </div>
  <div
    class="col-auto d-flex justify-content-end flex-shrink-1 text-right"
    [ngClass]="{ 'col-sm-4': columnBreakdown === 'primary', 'col-sm-5': columnBreakdown === 'secondary' }"
  >
    <div class="text-wrap text-right" [attr.data-automation-id]="thirdContainerID">
      {{ thirdContainerTitle }}
    </div>
    <div class="text-right ms-3" *ngIf="!mobile && fourthContainerTitle" [attr.data-automation-id]="fourthContainerID">
      {{ fourthContainerTitle }}
    </div>
  </div>
</div>
