export interface Style {
  id: number;
  code: string;
  name: string;
}

export interface CoverageItem {
  id: number;
  name: string;
}

export interface Region {
  id: number;
  name: string;
  code: string;
}

export interface Country {
  name: string;
  code: string;
  regionId: number;
}

export enum IndustryLevel {
  Micro = 'Micro',
  Mid = 'Mid',
  Macro = 'Macro'
}

export interface Industry {
  id: number;
  parentId: number;
  name: string;
  level: IndustryLevel;
}

export interface JobRole {
  id: number;
  name: string;
}

export interface FundType {
  id: number;
  name: string;
  code: string;
}

export interface InvestmentCenter {
  id: number;
  regionId: number;
  name: string;
}

export interface Currency {
  code: string;
  name: string;
}

export interface CurrencyResponse {
  data: Currency[];
  totalCount: number;
}

export interface CurrencyRateResponse {
  rate: number;
  rateDate: string;
}

export interface InvestorType {
  id: number;
  name: string;
}

export interface EsgApproach {
  categories: EsgCategory[];
  name: string;
}

export interface EsgCategory {
  id: number;
  name: string;
  focuses: string[];
}

export type CoverageType = 'region' | 'country' | 'marketCap' | 'industry';
