import { NgModule } from '@angular/core';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';

export const loadModule = () => import('@capital-access/ownership/client').then(m => m.OwnershipClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonNavigationModule.forFeature({
      top: [
        {
          localizationKey: 'ownership',
          icon: 'f-i-suitcase',
          order: 400,
          link: 'ownership'
        }
      ]
    }),
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: 'ownership',
          loadChildren: loadModule
        }
      ]
    })
  ]
})
export class OwnershipClientLazyModule {}
