<ng-container *ngIf="breadcrumbItems$ | async as breadcrumbItems">
  <nav *ngIf="breadcrumbItems && breadcrumbItems.length > 0" aria-label="breadcrumb">
    <ol class="breadcrumb w-100">
      <li
        data-automation-id="breadcrumb-item"
        class="breadcrumb-item breadcrumb-item-truncate"
        [ngClass]="{ active: !(item | caCanNavigate) }"
        *ngFor="let item of breadcrumbItems; trackBy: trackByUrl"
      >
        <ng-container *ngIf="item | caCanNavigate; else noLink">
          <a [routerLink]="item.url!.urlPath" [title]="item | caLabel">{{ item | caLabel }}</a>
        </ng-container>
        <ng-template #noLink>
          <span [title]="item | caLabel">{{ item | caLabel }}</span>
        </ng-template>
      </li>
    </ol>
  </nav>
</ng-container>
