import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils';
import { ChipSize } from '../models/chip-size';
import { ChipType } from '../models/chip-type';

@Component({
  selector: 'f-multi-choice-chip-group',
  templateUrl: './multi-choice-chip-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMultiChoiceChipGroupComponent implements OnChanges {
  @Input() items: Record<string, unknown>[] | null = [];
  @Input() valuePath: string = 'text';
  @Input() idField: string = 'id';
  @Input() selectedIds: unknown[] = [];
  @Input() size: ChipSize = ChipSize.Xs;
  @Input() groupedField: string = '';
  @Input() disabled = false;
  @Input() dropdownPlacement = 'bottom-left';
  @Input() responsive = false;

  ChipType = ChipType;
  chipItems: Record<string, unknown>[] | null = [];

  private localizationService = inject(FireflyLocalizationService, { optional: true });

  get selectedItemsCount$() {
    const count = this.selectedIds.length;
    return this.localizationService
      ? this.localizationService.localize(count === 1 ? 'chipsItemsCountSingular' : 'chipsItemsCountPlural', {
          count: count
        })
      : of(count === 1 ? `${count} item selected` : `${count} items selected`);
  }

  get noItemsSelected$() {
    return this.localizationService
      ? this.localizationService.localize('chipsNoItemsSelected', {})
      : of('No items selected');
  }

  get selectedItems$() {
    return this.localizationService
      ? this.localizationService.localize('chipsSelectedItems', {})
      : of('Selected items');
  }

  @Output() toggle = new EventEmitter<unknown>();

  @HostBinding('class') class = 'd-flex flex-wrap mt-n2';

  ngOnChanges() {
    this.chipItems = this.groupedField ? flatten(Object.values(groupBy(this.items, this.groupedField))) : this.items;
  }

  isSelected(id: unknown) {
    return this.selectedIds.includes(id);
  }

  onToggle(item: unknown) {
    this.toggle.emit(item);
  }

  updateListOnActionButtonClick(isSelected: boolean) {
    if (!this.chipItems) return;

    this.chipItems.forEach(chipItem => {
      if (this.isSelected(chipItem[this.idField]) !== isSelected) this.toggle.emit(chipItem);
    });
  }
}
