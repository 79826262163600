import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyDropdownListFocusDirective } from './dropdown-list-focus.directive';
import { FireflyDropdownWidthDirective } from './dropdown-width.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyDropdownListFocusDirective, FireflyDropdownWidthDirective],
  exports: [FireflyDropdownListFocusDirective, FireflyDropdownWidthDirective]
})
export class FireflyDropdownUtilsModule {}
