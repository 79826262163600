import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { internalServerErrorPageRoute } from '../error-page.models';

@Injectable()
export class RedirectService {
  private refreshKey = 'capital-access___redirect-from-internal-server-error';

  constructor(private location: Location) {}

  scheduleRedirectAfterRefresh() {
    sessionStorage.setItem(this.refreshKey, 'true');
  }

  clearScheduledRedirect(force = false) {
    if (
      sessionStorage.getItem(this.refreshKey) &&
      (force || !this.location.path().includes(internalServerErrorPageRoute))
    ) {
      sessionStorage.removeItem(this.refreshKey);
      return true;
    }

    return false;
  }
}
