import { ChartMobilePopoverContext, FireflyChart } from '@capital-access/firefly/components';

export interface Decile {
  values: number[];
}

export interface FactorInfo {
  decile: Decile;
  ratioDesc: string;
  securityDecileIndex?: number;
  score?: number;
}

export interface ConcentrationChartMobilePopoverContext extends ChartMobilePopoverContext {
  factorInfo: FactorInfo;
  calculatedDecilesRange: number[];
  securityDecileValue: number;
  mostDecilesRange: number[];
}

export interface ConcentrationChart extends FireflyChart {
  factorInfo: FactorInfo;
}

export enum DecileHighlightStrategy {
  Centric = 'Centric',
  LeftSide = 'LeftSide',
  RightSide = 'RightSide'
}

export enum ConcentrationChartColorScheme {
  Scheme_0 = 'scheme-0',
  Scheme_1 = 'scheme-1'
}

export interface DecilesIndexRange {
  startIndex: number;
  endIndex: number;
}
