import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { FireflyLocalizationService } from '@capital-access/firefly/components';
import { LOCALIZATION_SCOPE } from './constants';

@Injectable()
export class FireflyCommonLocalizationService implements FireflyLocalizationService {
  constructor(private localizationService: LocalizationService) {}

  localize<T extends string | string[]>(value: T, params: { [key: string]: unknown }) {
    return this.localizationService.localize(value, params, LOCALIZATION_SCOPE) as Observable<T>;
  }

  getLocalization() {
    return this.localizationService.getLocalization(LOCALIZATION_SCOPE);
  }
}
