import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthorizationHeader, XAnonymousResourceHeader } from '../+store/models/auth.headers';

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.oidcSecurityService.getConfiguration().pipe(
      switchMap(config => {
        const tokenUrl = config.authWellknownEndpoints?.tokenEndpoint;

        if (
          request.headers.has(XAnonymousResourceHeader) ||
          request.headers.has(AuthorizationHeader) ||
          (!!tokenUrl && request.url === tokenUrl)
        ) {
          return next.handle(request);
        }

        return this.enrichWithAuthorization(request).pipe(switchMap(request => next.handle(request)));
      })
    );
  }

  private enrichWithAuthorization(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    return this.oidcSecurityService.getAccessToken().pipe(
      map(token => {
        if (token) {
          return request.clone({
            setHeaders: {
              [AuthorizationHeader]: `Bearer ${token}`
            }
          });
        }

        return request;
      }),
      take(1)
    );
  }
}
