import { createSelector } from '@ngrx/store';
import { createSectionSelector, getSettings } from '@capital-access/common/user';
import { CIQ_EVENTS_FILTERS_SECTION } from '../constants';
import { CiqEventsPreferences } from '../models';

const getCiqEventsFiltersSection = createSectionSelector(getSettings, settings =>
  settings.getSection(CIQ_EVENTS_FILTERS_SECTION)
);

export const getCiqEventsFiltersPreferences = createSelector(getCiqEventsFiltersSection, section =>
  section.toObject(CiqEventsPreferences)
);
