import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FireflyConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { FireflyMobileModalComponent } from './mobile/mobile-modal.component';
import { FireflyModalComponent } from './modal.component';

const components = [FireflyModalComponent, FireflyMobileModalComponent, FireflyConfirmationModalComponent];

@NgModule({
  imports: [CommonModule, NgbModalModule],
  declarations: [...components],
  exports: [...components]
})
export class FireflyModalModule {}
