import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { FireflyColumnChooserPersistenceService } from '@capital-access/firefly/components';
import { ColumnChooserPersistenceService } from './column-chooser-persistence.service';

export const APP_TABLE_SETTINGS_PREFIX = new InjectionToken<string>('Prefix for application table settings section');

@NgModule({
  imports: [CommonModule]
})
export class TableSettingsModule {
  static forRoot(config: { prefix?: string }): ModuleWithProviders<TableSettingsModule> {
    return {
      ngModule: TableSettingsModule,
      providers: [
        {
          provide: FireflyColumnChooserPersistenceService,
          useClass: ColumnChooserPersistenceService
        },
        {
          provide: APP_TABLE_SETTINGS_PREFIX,
          useValue: config?.prefix ?? 'components'
        }
      ]
    };
  }
}
