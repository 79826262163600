import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FireflyDropdownComponent } from '../dropdown/dropdown.component';

@Component({
  selector: 'f-split-dropdown',
  templateUrl: './split-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySplitDropdownComponent extends FireflyDropdownComponent {
  @Input() dropdownButtonTitle?: string;
  @Input() dropdownToggleTitle?: string;
  @Input() loading: boolean = false;
  @Output() buttonClick = new EventEmitter();
}
