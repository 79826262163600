import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToggleResponse } from '../+state/feature-toggle.models';

@Injectable()
export class DefaultFeatureToggleRepository {
  private readonly baseUrl = '/api/features';

  constructor(private http: HttpClient) {}

  getCurrentUserToggles() {
    return this.http.get<ToggleResponse>(`${this.baseUrl}/identities/current/toggles`);
  }
}
