export enum SyncStatus {
  NotSynced = 'NotSynced',
  Synced = 'Synced',
  PartiallySynced = 'PartiallySynced',
  Edited = 'Edited',
  Deleted = 'Deleted',
  Failed = 'Failed',
  Disabled = 'Disabled',
  DoesNotExist = 'DoesNotExist',
  Disconnected = 'Disconnected'
}
