import { Action, createReducer, on } from '@ngrx/store';
import { setSelectedSecurityId, setSelectedSecuritySurveillance, toggleSurveillance } from './actions';

export const COMMON_SELECTED_SECURITY_FEATURE_KEY = 'common.selected-security';

export interface State {
  securityId: number | null;
  surveillance: boolean;
}

export const initialState: State = {
  securityId: null,
  surveillance: true
};

const selectedSecurityReducer = createReducer(
  initialState,

  on(setSelectedSecurityId, (state, action) => ({
    ...state,
    securityId: action.selectedSecurityId
  })),

  on(setSelectedSecuritySurveillance, (state, { enabled }) => ({
    ...state,
    surveillance: enabled
  })),

  on(toggleSurveillance, state => ({
    ...state,
    surveillance: !state.surveillance
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return selectedSecurityReducer(state, action);
}
