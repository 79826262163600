import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserSettingsRepository } from '../settings/user-settings.repository';
import { UserRepository } from '../user/user.repository';
import { DefaultUserRepository } from './default-user.repository';
import { DefaultUserSettingsRepository } from './default-user-settings.repository';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: UserRepository, useClass: DefaultUserRepository },
    { provide: UserSettingsRepository, useClass: DefaultUserSettingsRepository }
  ]
})
export class DefaultUserProviderModule {}
