import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeFormattingService } from '@capital-access/common/formatting';
import { ProfileRepository, ProfileType } from '@capital-access/profiles/common';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { OwnershipSource } from '../../core/models/ownership-source.enum';
import { CreateProfileReportDto } from '../models/profile-report.models';

@Injectable()
export class ProfileReportNamingService {
  constructor(private dateFormatting: DateTimeFormattingService, private profileRepository: ProfileRepository) {}

  public getProfileReportFileName(
    showSrvSource: boolean,
    isSrv: boolean,
    createProfileRequest: CreateProfileReportDto,
    timezone: string
  ): Observable<string> {
    const currentDate = new Date();
    const ownershipSource = isSrv ? OwnershipSource.Surveillance : OwnershipSource.Public;
    const dataSource = showSrvSource ? `- (${ownershipSource}) ` : '';

    return combineLatest([
      this.dateFormatting.formatDate(currentDate.toISOString(), { zone: timezone }, DEFAULT_DATE_FORMAT),
      this.getProfileSpecificName(createProfileRequest)
    ]).pipe(
      map(([formattedDate, profileSpecificName]) => {
        return `${profileSpecificName} ${dataSource}- ${formattedDate}`;
      })
    );
  }

  private getProfileSpecificName(createProfileRequest: CreateProfileReportDto): Observable<string> {
    const isSingleProfile = createProfileRequest.profiles.length === 1;

    if (!isSingleProfile) {
      const name =
        createProfileRequest.profileType === ProfileType.Institution
          ? 'Investor Profile Reports'
          : 'Contact Profile Reports';
      return of(name);
    }
    if (createProfileRequest.profiles[0].name) return of(createProfileRequest.profiles[0].name);

    switch (createProfileRequest.profileType) {
      case ProfileType.Contact: {
        return this.profileRepository.getContactInformation(createProfileRequest.profiles[0].id, ['name']).pipe(
          map(profile => {
            return profile.name!;
          })
        );
      }
      case ProfileType.Institution: {
        return this.profileRepository.getInstitutionInformation(createProfileRequest.profiles[0].id, ['name']).pipe(
          map(profile => {
            return profile.name!;
          })
        );
      }
      default:
        return of('');
    }
  }
}
