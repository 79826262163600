import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SaveFormStateDirective } from './save-form.directive';

@NgModule({
  declarations: [SaveFormStateDirective],
  imports: [CommonModule],
  exports: [SaveFormStateDirective]
})
export class SaveFormModule {}
