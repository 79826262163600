<svg:g *ngFor="let bar of _bars; let i = index; trackBy: trackBy">
  <defs>
    <clipPath id="clip-path-{{ seriesId }}{{ i }}-{{ hash }}" class="f-bar-clipping">
      <rect
        [attr.x]="bar.x - strokeWidth"
        [attr.y]="animationState === 'initial' ? '100%' : dimensions.height - bar.height - strokeWidth"
        [attr.width]="bar.width + strokeWidth * 2"
        [attr.height]="bar.height + strokeWidth * 2"
        [ngStyle]="animationStyles"
      ></rect>
    </clipPath>
  </defs>
  <svg:g
    #container
    class="f-bar-container"
    [class.selected]="selectedBarIndex === i"
    [class.interactive]="interactions.hover"
    [attr.data-automation-id]="'bar-container-' + seriesId + '' + i"
    (click)="onClick(container, bar.x, i)"
    (mouseenter)="onMouseEnter(popover, bar.x, i)"
    (mouseleave)="onMouseLeave(popover, bar.x)"
  >
    <rect
      class="f-bar-halo"
      *ngIf="interactions.hover"
      [attr.x]="bar.halo?.x"
      [attr.width]="bar.halo?.width"
      [attr.height]="dimensions.height + bubbleOffset"
      [attr.y]="0 - bubbleOffset"
    ></rect>
    <rect
      #popover="ngbPopover"
      [container]="'body'"
      [triggers]="'manual'"
      [placement]="popoverPlacement"
      [popoverClass]="popoverClass"
      [ngbPopover]="popoverTemplate && popoverTemplateOutlet"
      [attr.height]="(bar.height || minBarHeight) + getPopoverOffset(optionalData[i].value)"
      [attr.y]="dimensions.height - bar.height - getPopoverOffset(optionalData[i].value)"
      [attr.width]="bar.halo?.width"
      [attr.x]="bar.halo?.x"
      fill="transparent"
    ></rect>
    <svg:g>
      <rect
        class="f-rect-bar"
        [ngClass]="bar.barClass"
        [attr.y]="dimensions.height - bar.height"
        [attr.height]="bar.height || minBarHeight"
        [attr.x]="selectedBarIndex === i ? bar.x + strokeWidth / 2 : bar.x"
        [attr.width]="selectedBarIndex === i ? bar.width - strokeWidth : bar.width"
        [attr.clip-path]="'url(#clip-path-' + seriesId + '' + i + '-' + hash + ')'"
        [attr.data-automation-id]="'bar-' + seriesId + '' + i"
      ></rect>

      <ng-container *ngIf="showOptionalData">
        <svg:g
          f-bubble
          [cx]="optionalData[i].cx"
          [cy]="optionalData[i].cy"
          [bubbleRadius]="bubbleRadius"
          [value]="optionalData[i].value"
          [textClasses]="barBubbleTextClasses"
          [bubbleClasses]="optionalData[i].cssClass"
          [animationDuration]="bubblesAnimationDuration"
          [animationDelay]="animationDuration + 50"
          [animation]="animation"
        ></svg:g>
        <rect
          *ngIf="decileIsHighlighted(i)"
          class="ca-highlighted-decile"
          [attr.x]="optionalData[i].cx - xScale.step() / 2"
          [attr.y]="dimensions.height + 3"
          [attr.width]="xScale.step()"
          [attr.height]="3"
        ></rect>
      </ng-container>
    </svg:g>

    <ng-template #popoverTemplateOutlet>
      <ng-container
        *ngTemplateOutlet="$any(popoverTemplate); context: { $implicit: popoverContextData(i) }"
      ></ng-container>
    </ng-template>
    <ng-content></ng-content>
  </svg:g>
</svg:g>
