import { ConcentrationChartColorScheme, DecileHighlightStrategy, DecilesIndexRange } from './models';

const decileLastIdx = 9;

export function getConcentrationLevelCssClasses(colorScheme: ConcentrationChartColorScheme) {
  return [
    `concentration-${colorScheme}-level-1`,
    `concentration-${colorScheme}-level-2`,
    `concentration-${colorScheme}-level-3`,
    `concentration-${colorScheme}-level-4`,
    `concentration-${colorScheme}-level-5`
  ];
}

export function getConcentrationGradientCssClass(colorScheme: ConcentrationChartColorScheme) {
  return `concentration-${colorScheme}-gradient`;
}
export function getHighlightedDecilesRange(
  securityDecileIndex: number,
  highlightRange: number,
  highlightStrategy: DecileHighlightStrategy
): DecilesIndexRange {
  switch (highlightStrategy) {
    case DecileHighlightStrategy.Centric:
      return getCentricHighlightedDecilesRange(securityDecileIndex, highlightRange);
    case DecileHighlightStrategy.LeftSide:
      return getLeftSideHighlightedDecilesRange(securityDecileIndex, highlightRange);
    case DecileHighlightStrategy.RightSide:
      return getRightSideHighlightedDecilesRange(securityDecileIndex, highlightRange);
    default:
      throw new Error('Unsupported DecileHighlightStrategy');
  }
}

function getCentricHighlightedDecilesRange(securityDecileIndex: number, highlightRange: number): DecilesIndexRange {
  return {
    startIndex: Math.max(0, securityDecileIndex - highlightRange),
    endIndex: Math.min(decileLastIdx, securityDecileIndex + highlightRange)
  };
}

function getLeftSideHighlightedDecilesRange(securityDecileIndex: number, highlightRange: number): DecilesIndexRange {
  return {
    startIndex: Math.max(0, securityDecileIndex - highlightRange),
    endIndex: securityDecileIndex
  };
}

function getRightSideHighlightedDecilesRange(securityDecileIndex: number, highlightRange: number): DecilesIndexRange {
  return {
    startIndex: securityDecileIndex,
    endIndex: Math.min(decileLastIdx, securityDecileIndex + highlightRange)
  };
}
