import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, State } from './auth.reducer';

export const getAuthState = createFeatureSelector<State>(AUTH_FEATURE_KEY);

export const getAuthorizeWithPopUpStatus = createSelector(getAuthState, state => state.authorizeWithPopUpStatus);

export const getUserData = createSelector(getAuthState, state => state.userData);
export const getUserDataLoaded = createSelector(getAuthState, state => !!state.userData);

export const getUserId = createSelector(getUserData, state => state?.id);
export const getUserName = createSelector(getUserData, state => state?.name);
export const getUserFamilyName = createSelector(getUserData, state => state?.familyName);
export const getUserGivenName = createSelector(getUserData, state => state?.givenName);
export const getUserEmail = createSelector(getUserData, state => state?.email);
export const getUserEmailVerified = createSelector(getUserData, state => state?.emailVerified);
export const getUserZoneInfo = createSelector(getUserData, state => state?.zoneInfo);

export const getUserFullName = createSelector(getUserData, state => ({
  firstName: state?.givenName || '',
  lastName: state?.familyName || ''
}));
