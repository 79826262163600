import { Directive, Host, OnDestroy, OnInit } from '@angular/core';
import { ColumnBase, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[fKendoInteractiveGrid]'
})
export class FireflyKendoGridInteractiveDirective implements OnInit, OnDestroy {
  private columnReorderSub$!: Subscription;

  constructor(@Host() private grid: GridComponent) {}

  ngOnInit() {
    this.columnReorderSub$ = this.grid.columnReorder.subscribe($event => {
      const { newIndex } = $event;
      const column = this.getColumnByIndex(newIndex, this.grid);
      if (!column?.reorderable) $event.preventDefault();
    });
  }

  ngOnDestroy() {
    this.columnReorderSub$.unsubscribe();
  }

  private getColumnByIndex(index: number, grid: GridComponent): ColumnBase | undefined {
    return grid.columns.find((_, i) => i === index);
  }
}
