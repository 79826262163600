import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { LOCALIZATION_KEY } from './constants';
import { IsProfileReportingDisabledPipe } from './pipes/is-profile-reporting-disabled.pipe';
import { ReportActionTitlePipe } from './pipes/report-action-title.pipe';
import { ReportGenerationTooltipPipe } from './pipes/report-generation-tooltip.pipe';
import { ReportIsExportingTooltipPipe } from './pipes/report-is-exporting-tooltip.pipe';

const pipes = [
  IsProfileReportingDisabledPipe,
  ReportGenerationTooltipPipe,
  ReportIsExportingTooltipPipe,
  ReportActionTitlePipe
];

@NgModule({
  imports: [CommonModule, CommonLocalizationModule.forFeature(LOCALIZATION_KEY)],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [...pipes]
})
export class ReportingCommonModule {}
