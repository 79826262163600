import { isBoolean, isDate, isNumber, isString } from '../../type-checks';
import { TypeGuard } from '../../types';

class ResolveResult<T> {
  private constructor(public readonly resolved: boolean, public readonly value?: T) {}

  public static success<T>(result: T) {
    return new ResolveResult<T>(true, result);
  }

  public static failure<T>() {
    return new ResolveResult<T>(false);
  }
}

class UnknownResolverBuilder<T> {
  private resolvers: Array<(x: unknown) => ResolveResult<T>> = [];

  public ifString(resolve: (x: string) => T) {
    return this.ifOfType(isString, resolve);
  }

  public ifNumber(resolve: (x: number) => T) {
    return this.ifOfType(isNumber, resolve);
  }

  public ifBoolean(resolve: (x: boolean) => T) {
    return this.ifOfType(isBoolean, resolve);
  }

  public ifDate(resolve: (x: Date) => T) {
    return this.ifOfType(isDate, resolve);
  }

  public ifArray(resolve: (x: unknown[]) => T) {
    return this.ifOfType(Array.isArray, resolve);
  }

  public ifElse(resolve: (x: unknown) => T) {
    this.resolvers.push(unknownValue => ResolveResult.success(resolve(unknownValue)));
  }

  public ifOfType<TIn>(typeGuard: TypeGuard<TIn>, resolve: (x: TIn) => T) {
    this.resolvers.push(unknownValue => {
      if (typeGuard(unknownValue)) {
        return ResolveResult.success<T>(resolve(unknownValue));
      }
      return ResolveResult.failure<T>();
    });
    return this;
  }

  public build() {
    return (unknownValue: unknown): T | null => {
      for (const resolver of this.resolvers) {
        const result = resolver(unknownValue);
        if (result.resolved) {
          return result.value!;
        }
      }
      return null;
    };
  }
}

export function createUnknownResolver<T>(setup: (builder: Omit<UnknownResolverBuilder<T | null>, 'build'>) => void) {
  const builder = new UnknownResolverBuilder<T | null>();
  setup(builder);
  return builder.build();
}
