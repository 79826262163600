import { Injectable } from '@angular/core';
import { NgbConfig, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { POPOVER_AUTO_CLOSE } from './constants';

@Injectable()
export class FireflyPopoverConfig extends NgbPopoverConfig {
  constructor(ngbConfig: NgbConfig) {
    super();
    this.animation = ngbConfig.animation;
    this.autoClose = POPOVER_AUTO_CLOSE;
  }
}
