import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { NullableCellComponent } from './nullable-cell.component';
import { NullableCellDirective } from './nullable-cell.directive';

@NgModule({
  imports: [CommonModule, CommonLocalizationModule],
  declarations: [NullableCellComponent, NullableCellDirective],
  exports: [NullableCellDirective]
})
export class CommonNullableCellModule {}
