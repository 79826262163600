import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { FireflyDropdownModule, FireflyListModule } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { CommonAppSwitcherComponent } from './app-switcher.component';

@NgModule({
  imports: [
    CommonModule,
    FireflyListModule,
    FireflyDropdownModule,
    CommonLocalizationModule.forFeature(COMMON_COMPONENTS_LOCALIZATION_SCOPE)
  ],
  declarations: [CommonAppSwitcherComponent],
  exports: [CommonAppSwitcherComponent]
})
export class CommonAppSwitcherModule {}
