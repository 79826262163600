import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonUserEffects } from './+state/user.effects';
import { userReducer } from './+state/user.reducer';
import { USER_PROFILE_FEATURE_KEY } from './+state/user.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_PROFILE_FEATURE_KEY, userReducer),
    EffectsModule.forFeature([CommonUserEffects])
  ]
})
export class UserModule {}
