import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FireflyDropdownComponent } from '../../../dropdowns';
import { Breakpoint } from '../../../utils';
import { isDisabledItem } from '../../utils/list-items/list-items.utils';
import { FireflySingleSuggesterComponent } from '../single-suggester/single-suggester.component';
import { FireflySuggesterMobileDropdownComponent } from '../suggester-mobile/suggester-mobile-dropdown.component';

@Component({
  selector: 'f-single-suggester-dropdown',
  templateUrl: './single-suggester-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySingleSuggesterDropdownComponent extends FireflySingleSuggesterComponent implements AfterViewInit {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  protected modalComponent: any = FireflySuggesterMobileDropdownComponent;
  protected modalDialogClass = 'mobile-suggester-dropdown';

  @Input() buttonText: string = '';
  @Input() buttonTooltip: string = '';
  @Input() buttonTooltipPlacement = 'bottom-left';
  @Input() btnStyle: string = 'btn-secondary';
  @Input() btnMaxWidth?: number;
  @Input() icon: string = 'f-i-caret-down';
  @Input() hideCaret: boolean = true;
  @Input() activeItemValue!: string;
  @Input() showPredefinedSet = true;
  @Input() loading = false;
  @Input() disabled = false;

  @Output() dropdownOpen = new EventEmitter();
  @Output() dropdownClose = new EventEmitter();

  @ViewChild('parentDropdown', { read: FireflyDropdownComponent })
  parentDropdown!: FireflyDropdownComponent;

  parentDropdownToggleClass = 'parent-dropdown-toggle-btn';

  isActive(value: string) {
    if (this.selectedItem) {
      return super.isActive(value);
    }
    return value === this.activeItemValue;
  }

  ngAfterViewInit() {
    const btn = this.baseHost.nativeElement.querySelector(`.${this.parentDropdownToggleClass}`);
    fromEvent(btn, 'click', { capture: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(e => {
        if (window.innerWidth >= Breakpoint.Sm || this.modal) return;
        const event = e as unknown as Event;
        event.stopImmediatePropagation();
        event.preventDefault();
        this.openMobileModal();
        this.openSuggesterDropdown();
        this.suggesterManager.onOpenChange(true);
      });

    this.parentDropdown.openChange.pipe(takeUntil(this.destroyed$)).subscribe(isOpen => {
      if (!isOpen) return;
      if (window.innerWidth >= Breakpoint.Sm) {
        this.openSuggesterDropdown();
      }
    });
  }

  onOpenChange($event: boolean) {
    if (window.innerWidth >= Breakpoint.Sm) {
      this.suggesterManager.onOpenChange($event);
    }
  }

  openSuggesterDropdown() {
    this.suggesterManager.toggleDropdown(true);
    this.dropdownOpen.emit();
  }

  onElementClick(item: unknown, $event?: Event): void {
    $event?.preventDefault();
    if (!isDisabledItem(item, this.disabledPath, this.nestedValuePipe.transform)) {
      this.parentDropdown.close();
      if (!this.modal) {
        this.dropdownClose.emit();
        this.updateValueForInput('');
        this.suggesterManager.toggleDropdown(false);
        this.select.emit(item);
      } else {
        this.selectedItem = item as Record<string, unknown>;
      }
    }
  }

  onContactSupport() {
    this.suggesterManager.onContactSupport();
    this.parentDropdown.close();
  }

  onDropdownClose() {
    this.suggesterManager.clearUnmatchedQuery();
    this.dropdownClose.emit();
  }

  protected resetState() {
    this.selectedItem = null;
    this.updateValueForInput('');
    this.suggesterManager.filterList();
  }

  protected handleStateUpdate() {
    this.updateValueForInput('');
    if (this.selectedItem) {
      this.initialSelectedItem = { ...this.selectedItem };
      this.select.emit(this.selectedItem);
      this.suggesterManager.filterList();
      this.selectedItem = null;
    }
  }
}
