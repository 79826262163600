import { CultureCode } from '@capital-access/common/globalization';

export function getCultureDisplayName(cultureCode: CultureCode): string {
  switch (cultureCode) {
    case 'en-US':
      return 'English (United States)';
    case 'en-GB':
      return 'English (United Kingdom)';
    case 'ja':
      return 'Japanese (Japan)';
    case 'de-DE':
      return 'German (Germany)';
    case 'pt-BR':
      return 'Portuguese (Brazil)';
    case 'fr-FR':
      return 'French (France)';
    case 'ru':
      return 'Russian (Russia)';
    case 'en-CA':
      return 'English (Canada)';
    case 'de-AT':
      return 'German (Austria)';
    case 'fi':
      return 'Finnish (Finland)';
    case 'es-ES':
      return 'Spanish (Spain)';
    case 'es-MX':
      return 'Spanish (Mexico)';
    case 'zh-CN':
      return 'Chinese (Simplified, PRC)';
    case 'es-AR':
      return 'Spanish (Argentina)';
    case 'da':
      return 'Danish (Denmark)';
    case 'nl':
      return 'Dutch (Netherlands)';
    case 'sv':
      return 'Swedish (Sweden)';
    case 'fr-BE':
      return 'French (Belgium)';
    case 'de-CH':
      return 'German (Switzerland)';
    case 'es-CO':
      return 'Spanish (Colombia)';
    case 'it':
      return 'Italian (Italy)';
    case 'tr':
      return 'Turkish (Turkey)';
    case 'hi':
      return 'Hindi (India)';
    case 'en-ZA':
      return 'English (South Africa)';
    case 'el':
      return 'Greek (Greece)';
    default:
      return cultureCode;
  }
}
