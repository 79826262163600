import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyAlertsModule } from '../alerts';
import { FireflyDrawerModule } from '../drawer';
import { FireflyListModule } from '../lists';
import { FireflySuggesterModule } from '../suggesters';
import { FireflyColumnChooserComponent } from './column-chooser.component';
import { FireflyColumnChooserDrawerComponent } from './column-chooser-drawer.component';

@NgModule({
  imports: [CommonModule, FireflyListModule, FireflyDrawerModule, FireflyAlertsModule, FireflySuggesterModule],
  declarations: [FireflyColumnChooserComponent, FireflyColumnChooserDrawerComponent],
  exports: [FireflyColumnChooserComponent]
})
export class FireflyColumnChooserModule {}
