import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SmallCardRowComponent } from './small-card-row.component';
import { SmallCardRowHeaderComponent } from './small-card-row-header/small-card-row-header.component';

@NgModule({
  declarations: [SmallCardRowComponent, SmallCardRowHeaderComponent],
  imports: [CommonModule],
  exports: [SmallCardRowComponent, SmallCardRowHeaderComponent]
})
export class FireflySmallCardRowModule {}
