import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { FireflyGridModule, FireflyPopoverModule } from '@capital-access/firefly/components';
import { ActivityNotesComponent } from './activity-notes.component';

@NgModule({
  imports: [CommonModule, FireflyGridModule, FireflyPopoverModule, CommonLocalizationModule],
  declarations: [ActivityNotesComponent],
  exports: [ActivityNotesComponent]
})
export class CommonActivityNotesModule {}
