import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'f-draggable-list-item',
  templateUrl: './draggable-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyDraggableListItemComponent {
  @Input() rootClass = 'align-items-center';
  @Input() draggableIconClass?: string;
  @Input() actionBtnClass!: string | undefined;
  @Input() actionBtnIcon!: string | undefined;
  @Input() hoverable = true;
  @Input() disabled = false;
  @Input() disabledIcon: boolean | undefined = false;
  @Input() disabledIconTitle!: string | undefined;

  @Output() actionBtnClick = new EventEmitter();
}
