import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonBrowserTitleModule } from '@capital-access/common/browser';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonRouterModule } from '@capital-access/common/router';
import { SESSION_EXPIRE_PAGE_ROUTE } from '@capital-access/common/utils';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { CommonErrorPageModule } from '../error-page/error-page.module';
import { sessionExpirePageRoute } from '../utils/error-page.models';
import { SessionExpirePageComponent } from './session-expire-page.component';
import { SessionExpirePageTitle } from './session-expire-page.title';
@NgModule({
  imports: [
    CommonModule,
    CommonErrorPageModule,
    CommonBrowserTitleModule.forFeature(SessionExpirePageTitle),
    CommonRouterModule.forFeature({
      anonymous: [{ path: sessionExpirePageRoute, component: SessionExpirePageComponent }]
    }),
    CommonLocalizationModule.forFeature(COMMON_COMPONENTS_LOCALIZATION_SCOPE)
  ],
  providers: [
    SessionExpirePageTitle,
    {
      provide: SESSION_EXPIRE_PAGE_ROUTE,
      useValue: sessionExpirePageRoute
    }
  ],
  declarations: [SessionExpirePageComponent],
  exports: [SessionExpirePageComponent]
})
export class SessionExpirePageModule {}
