import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { addInstitution, editInstitution } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CompositeId } from '@capital-access/profiles/common';
import {
  CRM_INSTITUTION_FORM_MODULE_ID,
  LOAD_CRM_INSTITUTION_FORM_MODULE
} from '../institution-form-loader-module-id.model';

@Injectable()
export class InstitutionFormLoaderEffects {
  addInstitution$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addInstitution),
        switchMap(() =>
          this.commonLazyLoadingService
            .loadModule(CRM_INSTITUTION_FORM_MODULE_ID, LOAD_CRM_INSTITUTION_FORM_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showAddInstitutionForm()))
        )
      ),
    { dispatch: false }
  );

  editInstitution$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editInstitution),
        switchMap(({ id }) =>
          this.commonLazyLoadingService
            .loadModule(CRM_INSTITUTION_FORM_MODULE_ID, LOAD_CRM_INSTITUTION_FORM_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showEditInstitutionForm(new CompositeId(id.prId, id.crmId))))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
