import { SortDirection } from '@capital-access/common/utils';
import { ProfileSourceFilter } from './profile-source-filter.model';

export type ManagedCompany = {
  crmId?: number;
  prId?: number;
  name: string;
  isPrimaryCompany: boolean;
  isPrimaryContact: boolean;
};

export type CustomField = {
  fieldName: string;
  value: number | string | string[];
};

export type CompositeContact = {
  crmId: number;
  prId: number;
  name: string;
  isPrimaryContact: boolean;
  isExcludedFromMail: boolean;
  firstName: string;
  lastName: string;
  nickname: string;
  middleName: string;
  prefix: string;
  suffix: string;
  crmEmail: string;
  prEmail: string;
  crmPhone: string;
  prPhone: string;
  crmJobTitle: string;
  prJobTitle: string;
  createdByUserId: number;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  lastModifiedDate: string;
  lastModifiedUserId: number;
  state: string;
  zipCode: string;
  addressCountry: string;
  primaryCompanyCrmId: number;
  primaryCompanyName: string;
  excludedFromMailDate: string;
  excludedFromMailUserId: number;
  managedCompanies: ManagedCompany[];
  customFields: CustomField[];
  isEsgContact: boolean;
  primaryCompanyPrId: number;
  prPrimaryCompanyPrId: number;
  isEsgPrimaryCompany: boolean;
};

export type SortContactField = 'Name' | 'PrimaryCompanyName' | 'CrmId' | 'LastModifiedDate';

export enum CompanyContactType {
  External = 'External',
  InvestorRelationsActive = 'InvestorRelationsActive',
  ManagementActive = 'ManagementActive',
  OtherActive = 'OtherActive',
  InvestorRelationsInactive = 'InvestorRelationsInactive',
  ManagementInactive = 'ManagementInactive',
  OtherInactive = 'OtherInactive'
}

export type SearchContactsCriteria = {
  order: SortDirection;
  sort: SortContactField;
  source?: ProfileSourceFilter;
  skip?: number;
  take?: number;
  selectionFields?: string[];
  listIds?: number[];
  importId?: number;
  primaryContactOnly?: boolean;
  primaryInstitutionIds?: number[];
  //TODO: remove after deleting FT contact-management-with-institution-filter-SIRS-3313
  withoutPrimaryCompanyOnly?: boolean;
  withPrimaryCompany?: boolean;
  isExcludedFromMail?: boolean;
  types?: CompanyContactType[];
  regionIds?: number[];
  countryCodes?: string[];
  customFieldsFilter?: string[];
  name?: string;
  jobTitle?: string;
};
