import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardRowComponent } from './card-row.component';
import { FirstColDirective } from './directives/first-col.directive';
import { SecondColDirective } from './directives/second-col.directive';
import { ThirdColDirective } from './directives/third-col.directive';

@NgModule({
  declarations: [FirstColDirective, SecondColDirective, ThirdColDirective, CardRowComponent],
  imports: [CommonModule],
  exports: [FirstColDirective, SecondColDirective, ThirdColDirective, CardRowComponent]
})
export class FireflyCardRowModule {}
