import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { LOCALIZATION_KEY, MAX_REPORT_PROFILES_COUNT } from '../constants';

@Pipe({ name: 'caReportGenerationTooltip', pure: false })
export class ReportGenerationTooltipPipe extends BaseAsyncPipe<string, number | null> implements PipeTransform {
  constructor(private localizationService: LocalizationService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  getTransformer(profilesCount: number | null): Observable<string> {
    return this.localizationService
      .localize(
        ['minimumItemsSelectedTooltip', 'maximumItemsSelectedTooltip'],
        { MAX_REPORT_PROFILES_COUNT },
        { scope: LOCALIZATION_KEY }
      )
      .pipe(
        take(1),
        map(([minimumItemsSelectedTooltip, maximumItemsSelectedTooltip]) => {
          return profilesCount! <= 0
            ? minimumItemsSelectedTooltip
            : profilesCount! > MAX_REPORT_PROFILES_COUNT
            ? maximumItemsSelectedTooltip
            : '';
        })
      );
  }
}
