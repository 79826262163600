import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityItem } from '../../../models/entity-item';

@Component({
  selector: 'ca-add-entity-dropdown',
  templateUrl: './add-entity-dropdown.component.html',
  styleUrls: ['./add-entity-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEntityDropdownComponent {
  @Input() icon = '';
  @Input() list: EntityItem[] = [];

  @Output() itemClick = new EventEmitter<number>();
}
