import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { AbsoluteValuePipe, NumberPipe } from '@capital-access/common/formatting';
import { BaseBadgeComponent } from '../base-badge/base-badge.component';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../../common-components.const';
import { LocalizationService } from '@capital-access/common/localization';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ca-change-badge',
  templateUrl: './change-badge.component.html',
  styleUrls: ['./change-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeBadgeComponent extends BaseBadgeComponent {
  @Input() formattingFn: ((v: number) => string | null) | null = null;
  @Input() showTooltip = true;
  notAvailableCaption$: Observable<string>;

  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  constructor(
    private caAbs: AbsoluteValuePipe,
    private caNumber: NumberPipe,
    @Optional() @Self() private localizationService: LocalizationService
  ) {
    super();

    this.notAvailableCaption$ = this.localizationService
      ? this.localizationService.localize('notAvailableCaption', {}, COMMON_COMPONENTS_LOCALIZATION_SCOPE)
      : of('N/A');
  }

  override get badgeClass() {
    const badgeType: string = `badge-${
      !this.isValidValue || !this.valueChange ? 'neutral' : this.valueChange > 0 ? 'success' : 'danger'
    }`;
    return [badgeType, this.size];
  }

  get defaultFormatting() {
    return this.caNumber.transform(this.caAbs.transform(this.valueChange), 0);
  }

  get formattedValue() {
    return this.formattingFn ? this.formattingFn(this.valueChange) : this.defaultFormatting;
  }
}
