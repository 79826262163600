import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { LoadingType } from '../../loading-indicators';

@Component({
  selector: 'f-large-card',
  templateUrl: './large-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyLargeCardComponent {
  @Input() title!: string;
  @Input() rootCssClass = '';
  @Input() bodyCssClass = '';
  @Input() titleCssClass = '';
  @Input() titleStartCssClass = '';
  @Input() actionsCssClass = '';
  @Input() contentCssClass = '';
  @Input() loadingType?: LoadingType | null;
  @Input() loaderTemplate!: TemplateRef<unknown>;
  @Input() layout: 'primary' | 'secondary' = 'primary';

  LoadingType = LoadingType;
}
