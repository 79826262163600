<span
  [class.d-none]="!showLink"
  [class.link-disabled]="disabled"
  [attr.tabindex]="!showLink ? -1 : 0"
  [title]="disabled ? disabledMessage : ''"
  data-automation-id="add-new-item-link"
  class="suggester-add-item-link"
  (keyup.enter)="showInput(inputRef)"
  (click)="showInput(inputRef)"
>
  + {{ linkTitle || (linkText$ | async) }}
</span>

<div class="d-flex justify-content-between pt-2 pb-1 ps-3 pe-0 pe-sm-3" [class.d-none]="showLink">
  <div class="flex-grow-1">
    <input
      #inputRef
      type="text"
      autocomplete="new-password"
      [formControl]="inputValue"
      [ngClass]="validationClass"
      [placeholder]="placeholder"
      [attr.tabindex]="showLink ? -1 : 0"
      data-automation-id="add-new-item-input"
      class="form-control form-control-outline"
      [attr.disabled]="disabled || isLoading ? true : null"
      (keyup)="inputValueChange.emit(inputValue.value ?? '')"
      (select)="$event.stopPropagation()"
      (keyup.enter)="tryToAddNewItem()"
    />
    <div
      *ngIf="validation?.message"
      [ngClass]="validationMessageClass"
      data-automation-id="add-new-item-validation-message"
    >
      {{ validation?.message }}
    </div>
  </div>
  <div class="position-relative align-self-start ms-1">
    <button
      type="button"
      [attr.tabindex]="showLink ? -1 : 0"
      data-automation-id="add-new-item-btn"
      class="btn btn-simple-icon btn-primary"
      [disabled]="inputValue.invalid || disabled || isLoading ? true : null"
      [title]="disabled ? disabledMessage : ''"
      (click)="tryToAddNewItem()"
    >
      <i class="f-i f-i-plus-circle"></i>
    </button>
    <f-spinner *ngIf="isLoading" rootCssClass="spinner-center" [spinnerSize]="SpinnerSize.Sm"></f-spinner>
  </div>
</div>
