<div class="results-total-count" data-automation-id="results-count">
  <ng-container [ngSwitch]="totalCountLoadStatus">
    <ng-container *ngSwitchCase="LoadStatus.Loading">
      <span>{{ totalResultsTitle$ | async }}: </span>
      <div class="d-inline-block align-middle ms-2">
        <f-spinner [spinnerSize]="SpinnerSize.Sm"></f-spinner>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="LoadStatus.Failed">
      <span>{{ filterResultsLoadFailedLabel$ | async }}</span>
      <div>
        <span class="link link-primary" data-automation-id="try-again" (click)="onTryAgainClick()">{{
          tryAgainLabel$ | async
        }}</span>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span>{{ totalResultsTitle$ | async }}: </span>
      <span>{{ totalCount$ | async }}</span>
    </ng-container>
  </ng-container>
</div>
