import { Injectable } from '@angular/core';
import { AppKey, AppUrlService } from '@capital-access/common/settings';

// @dynamic - Marked to enable injection of ambient Document type
@Injectable({ providedIn: 'root' })
export class HostProvider {
  constructor(private appUrlsService: AppUrlService) {}

  get baseUrl() {
    return this.appUrlsService.getAppUrl(AppKey.CAPITAL_ACCESS);
  }
}
