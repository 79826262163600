export enum ProfileError {
  None,
  NotFound,
  Forbidden,
  Unknown
}

export enum ProfileType {
  Contact = 'contact',
  Fund = 'fund',
  Institution = 'institution'
}
