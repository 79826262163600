import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../../popovers/popover.module';
import { FireflyBarModule } from '../bar/bar.module';
import { FireflyBarWithArrowPointerComponent } from './bar-with-arrow-pointer.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule, FireflyBarModule],
  declarations: [FireflyBarWithArrowPointerComponent],
  exports: [FireflyBarWithArrowPointerComponent]
})
export class FireflyBarWithArrowPointerModule {}
