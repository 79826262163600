import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export abstract class FireflyLocalizationService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localize<T extends string | string[]>(value: T, params: { [key: string]: unknown }) {
    return of(value);
  }

  getLocalization(): Observable<Record<string, string>> {
    return of();
  }
}
