export const handleMissingPullToReload = () => {
  const getThreshold = () => {
    const isPortrait = window.innerHeight > window.innerWidth;
    const sideLength = isPortrait ? window.innerHeight : window.innerHeight;
    return sideLength * 0.15; // Takes 15% of the side length.
  };
  let threshold = getThreshold();
  const scrollingContainer = document.querySelector('html')!;
  const spinnerContainer = document.createElement('div');
  const spinner = document.createElement('span');
  spinnerContainer.classList.add('d-flex', 'w-100', 'position-fixed', 'top-0', 'mt-4');
  spinner.classList.add('spinner-border', 'no-animation', 'mx-auto');
  spinner.style.opacity = '0';
  spinnerContainer.append(spinner);
  document.body.append(spinnerContainer);

  /**
   * Fallback to deprecated "orientationchange" event,
   * because older versions of Safari don't support orientation API.
   */
  const hasOrientationSupport = !!screen.orientation;
  hasOrientationSupport
    ? screen.orientation.addEventListener('change', () => (threshold = getThreshold()))
    : window.addEventListener('orientationchange', () => (threshold = getThreshold()));

  scrollingContainer.addEventListener('touchmove', () => {
    if (scrollingContainer.scrollTop < 0) {
      const absScrollTop = Math.abs(scrollingContainer.scrollTop);
      spinner.style.opacity = `${absScrollTop / threshold}`;
      spinner.style.rotate = `${absScrollTop}deg`;
    }
  });

  scrollingContainer.addEventListener('touchend', () => {
    if (scrollingContainer.scrollTop < -threshold) {
      location.reload();
    }
  });
};
