import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CultureCode, getUserSettingsCulture } from '@capital-access/common/globalization';

@Injectable({ providedIn: 'root' })
export class NumberFormattingService {
  // Intl.NumberFormat supports fraction range from 0 to 20
  private readonly maxFractionValue = 20;

  constructor(private store: Store) {}

  formatNumber(value: number | null | undefined, fraction?: number): Observable<string>;
  formatNumber(value: number | null | undefined, fraction?: number, cultureCode?: CultureCode): Observable<string>;
  formatNumber(value: number | null | undefined, fraction: number = 0, cultureCode?: CultureCode): Observable<string> {
    if (value === null || value === undefined || !isFinite(value)) {
      return of('');
    }

    if (fraction < 0 || fraction > this.maxFractionValue) fraction = 0;

    if (cultureCode)
      return of(
        new Intl.NumberFormat(cultureCode, {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction
        }).format(value)
      );

    return this.store.select(getUserSettingsCulture).pipe(
      map(culture =>
        new Intl.NumberFormat(culture, {
          minimumFractionDigits: fraction,
          maximumFractionDigits: fraction
        }).format(value)
      )
    );
  }
}
