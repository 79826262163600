import { Inject, Injectable } from '@angular/core';
import flatten from 'lodash/flatten';
import { firstValueFrom } from 'rxjs';
import { LocalizationService, LocalizedString } from '@capital-access/common/localization';
import { isString } from '@capital-access/common/utils';
import { BROWSER_TITLE_FALLBACK_VALUE, BROWSER_TITLE_SEPARATOR, TitlePartString, TitleSource } from './title.models';
import { fromTitlePart, skipOverridenTitleParts } from './title.resolvers';

@Injectable()
export class TitleService {
  constructor(
    private localizationService: LocalizationService,
    @Inject(BROWSER_TITLE_SEPARATOR) private titleSeparator: string,
    @Inject(BROWSER_TITLE_FALLBACK_VALUE) private titleFallbackValue: TitlePartString
  ) {}

  async buildTitle(source: TitleSource) {
    let title = await this.createTitle(source);
    if (!title) {
      title = await this.createTitle({ titleParts: [this.titleFallbackValue], scopes: source.scopes });
    }

    return title;
  }

  private async createTitle(source: TitleSource) {
    const translateFn = this.createTranslateFn(source.scopes);

    const parts = await Promise.all(
      skipOverridenTitleParts(flatten(source.titleParts.map(part => fromTitlePart(part))))
        .map(x => x.value)
        .filter(x => x)
        .map(x => translateFn(x))
    );

    return parts.filter(x => x).join(this.titleSeparator);
  }

  private createTranslateFn(scopes: Record<string, boolean>) {
    return (source: string | LocalizedString) => {
      if (isString(source)) return Promise.resolve(source);

      const scope = this.getLocalizationScope(source);
      // Override behavior of translate service: by default if localization scope is not loaded yet
      // translate returns localization key and writes a warning to console
      if (!scopes[scope]) {
        return Promise.resolve(null);
      }

      const result = this.localizationService.localize(source);

      return firstValueFrom(result);
    };
  }

  private getLocalizationScope(source: LocalizedString) {
    return source.scope ?? (source.localizationKey.includes('.') ? source.localizationKey.split('.')[0] : '$default');
  }
}
