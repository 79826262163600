import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import { LOCALIZATION_KEY } from '../../constants';
import { SectionSelectorListItem } from '../../core/models/report-sections';

@Component({
  selector: 'ca-sections-selector',
  templateUrl: './sections-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: SectionsSelectorComponent
    }
  ]
})
export class SectionsSelectorComponent implements ControlValueAccessor {
  localizationScope = LOCALIZATION_KEY;
  sections: SectionSelectorListItem[] = [];

  @Output()
  settingsChange = new EventEmitter<SectionSelectorListItem[]>();

  private emitValueChange: (sections: SectionSelectorListItem[]) => void = noop;
  private markTouched: () => void = noop;

  writeValue(sections: SectionSelectorListItem[]) {
    this.sections = sections;
  }

  registerOnChange(fn: (sections: SectionSelectorListItem[]) => void) {
    this.emitValueChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.markTouched = fn;
  }

  drop(value: unknown[]) {
    this.sections = value as SectionSelectorListItem[];
    this.emitValues(this.sections);
  }

  toggle(section: SectionSelectorListItem) {
    this.sections = this.sections.map(s => (s.id === section.id ? { ...s, isSelected: !s.isSelected } : s));
    this.emitValues(this.sections);
  }

  selectAll() {
    this.sections = this.sections.map(s => ({ ...s, isSelected: true }));
    this.emitValues(this.sections);
  }

  clearAll() {
    this.sections = this.sections.map(s => ({ ...s, isSelected: false }));
    this.emitValues(this.sections);
  }

  emitValues(value: SectionSelectorListItem[]) {
    this.emitValueChange(this.sections);
    this.settingsChange.emit(value);
  }
}
