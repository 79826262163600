import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyLoadingDotsModule } from '../../loading-indicators';
import { FireflyAssistantChatMessageComponent } from './assistant-message/assistant-message.component';
import { FireflyMessageLabelComponent } from './message-label/message-label.component';
import { FireflyUserChatMessageComponent } from './user-message/user-message.component';

@NgModule({
  imports: [CommonModule, FireflyLoadingDotsModule],
  declarations: [FireflyUserChatMessageComponent, FireflyMessageLabelComponent, FireflyAssistantChatMessageComponent],
  exports: [FireflyUserChatMessageComponent, FireflyAssistantChatMessageComponent]
})
export class FireflyMessageModule {}
