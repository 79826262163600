import { Type } from '@angular/core';
import flatten from 'lodash/flatten';
import { ActivatedRoutes } from '@capital-access/common/router';
import { addTag } from '@capital-access/common/utils';
import { BreadcrumbLabel } from '../models/breadcrumb-item.model';
import { BreadcrumbUrl } from '../models/breadcrumb-url.model';
import { BreadcrumbConfigProvider } from '../models/config-provider.model';
import { BreadcrumbItemsFn } from '../models/strategy.model';

export const BREADCRUMB_ITEMS_PROVIDER_KEY = 'breadcrumb:items:provider';

/**
 * Creates breadcrumb items for specified component.
 * This items will be used by default strategy to construct full breadcrumbs path
 */
export const createBreadcrumbItems = (config: { component: Type<unknown> }, itemsFn: BreadcrumbItemsFn) => {
  const provider = new BreadcrumbConfigProvider({ items: itemsFn });
  addTag(config.component, {
    key: BREADCRUMB_ITEMS_PROVIDER_KEY,
    descriptor: {
      value: provider.token
    }
  });
  return provider;
};

/**
 * Creates breadcrumb label for specified component. Url will be retrieved from activated routes paths
 * This label will be used by default strategy to construct full breadcrumbs path
 */
export const createBreadcrumbLabel = (
  config: {
    component: Type<unknown>;
  },
  labelFn: (route: ActivatedRoutes) => BreadcrumbLabel
) => {
  const itemsFn = (routes: ActivatedRoutes) => [
    {
      label: labelFn(routes),
      url: new BreadcrumbUrl(flatten(routes.pathFromRoot.map(route => route.url)))
    }
  ];
  return createBreadcrumbItems(config, itemsFn);
};
