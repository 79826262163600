import {
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  MemoizedSelector,
  resultMemoize
} from '@ngrx/store';
import { EmptyConstructor, LoadStatus } from '@capital-access/common/utils';
import { getUserLoadStatus } from '../../user/+state/user.selectors';
import { SettingsConstructor } from '../models/internal/settings-constructor';
import { UserSettingsSection } from '../models/user-settings-section';
import { settingsAdapter } from './settings.reducer';
import { SettingsState } from './settings.state';
import { USER_SETTINGS_FEATURE_KEY } from './settings.state';

const getSettingsState = createFeatureSelector<SettingsState>(USER_SETTINGS_FEATURE_KEY);

const { selectAll, selectEntities } = settingsAdapter.getSelectors();

/** Creates user settings section selector
 * It will match section by settings values instead of reference.
 * This helps to avoid emitting the same values on each and every preference change.
 * So for example changes in globalization section will not trigger changes in selector for application section
 */
export const createSectionSelector = createSelectorFactory<unknown, UserSettingsSection>(projectFn =>
  resultMemoize(projectFn, (x: UserSettingsSection, y: UserSettingsSection) => x.equals(y))
) as <TState, TIn>(
  selector: MemoizedSelector<TState, TIn>,
  project: (s: TIn) => UserSettingsSection
) => MemoizedSelector<TState, UserSettingsSection>;

export const getAllSettings = createSelector(getSettingsState, (state: SettingsState) => selectAll(state));

export const getSettingsEntities = createSelector(getSettingsState, (state: SettingsState) => selectEntities(state));

// we can use simple selector factory for root settings section cause it guarantee to change if all settings selector emits new value
export const getSettings = createSelector(getAllSettings, settings => new UserSettingsSection('', settings));

export const getIsUserSettingsLoaded = createSelector(
  getUserLoadStatus,
  loadStatus => loadStatus === LoadStatus.Loaded
);

export const getSavingSettingKeys = createSelector(getSettingsState, state => state.savingIds);

export const getFailedSettingKeys = createSelector(getSettingsState, state => state.failedIds);

export const getIdsMarkedForReset = createSelector(getSettingsState, state => state.markedForResetIds);

export const createSettingsSelectors = <T>(settingClass: EmptyConstructor<T>, section: string = '') => {
  const settingKeysList = SettingsConstructor.fromCtor(settingClass)
    .getSettingsEntities(section)
    .map(s => s.id);

  const selectSaving = createSelector(getSavingSettingKeys, savingKeys =>
    savingKeys.some(key => settingKeysList.includes(key))
  );
  const selectFailed = createSelector(getFailedSettingKeys, failedKeys =>
    failedKeys.some(key => settingKeysList.includes(key))
  );

  const selectSection = createSectionSelector(getSettings, settings => settings.getSection(section));
  const selectSettings = createSelector(selectSection, section => section.toObject(settingClass));

  return {
    selectSaving,
    selectFailed,
    selectSettings,
    selectSection
  };
};
