import { Directive, Input } from '@angular/core';

/**
 * Provides localization scope for LocalizePipe within the hosted element
 */
@Directive({
  selector: '[caLocalizationScope]'
})
export class LocalizationScopeDirective {
  @Input() caLocalizationScope: string | undefined;
}
