import { createNgModuleRef, Injectable, Injector, NgModuleRef, Type } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CommonLazyLoadingService {
  private moduleRefCache = new Map<string, NgModuleRef<unknown>>();

  constructor(private injector: Injector) {}

  loadModule<T>(moduleId: string, loadModule: () => Promise<Type<T>>): Observable<NgModuleRef<T>> {
    let moduleRef = this.moduleRefCache.get(moduleId) as NgModuleRef<T> | undefined;

    if (moduleRef) {
      return of(moduleRef);
    }

    // Get module declaration
    return from(loadModule()).pipe(
      map(ngModule => {
        // Needed to instantiate module with all its services and connect with root injector
        moduleRef = createNgModuleRef(ngModule, this.injector);
        // Save module instance reference to cache
        this.moduleRefCache.set(moduleId, moduleRef);
        return moduleRef;
      })
    );
  }
}
