<div [ngClass]="rootCssClass">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="shape-rendering: auto"
    [attr.width]="dotNumber === LoadingDotsNumber.One ? '14px' : '26px'"
    height="20px"
    [attr.viewBox]="dotNumber === LoadingDotsNumber.One ? '0 0 14 20' : '0 0 26 20'"
    preserveAspectRatio="xMidYMid"
  >
    <g *ngIf="dotNumber === LoadingDotsNumber.One" transform="translate(7 10)">
      <circle class="loading-message-first-circle" cx="0" cy="0" r="3">
        <animateTransform
          *ngIf="animation"
          attributeName="transform"
          type="scale"
          begin="0s"
          calcMode="spline"
          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
          values="0;1;0"
          keyTimes="0;0.5;1"
          dur="1.2318518518518516s"
          repeatCount="indefinite"
        ></animateTransform>
      </circle>
    </g>

    <ng-container *ngIf="dotNumber === LoadingDotsNumber.Default">
      <g transform="translate(5 10)">
        <circle class="loading-message-first-circle" cx="0" cy="0" r="3">
          <animateTransform
            *ngIf="animation"
            attributeName="transform"
            type="scale"
            begin="-0.41128791943957965s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.2318518518518516s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(14 10)">
        <circle class="loading-message-second-circle" cx="0" cy="0" r="3">
          <animateTransform
            *ngIf="animation"
            attributeName="transform"
            type="scale"
            begin="-0.20564395971978982s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.2318518518518516s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
      <g transform="translate(23 10)">
        <circle class="loading-message-third-circle" cx="0" cy="0" r="3">
          <animateTransform
            *ngIf="animation"
            attributeName="transform"
            type="scale"
            begin="0s"
            calcMode="spline"
            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
            values="0;1;0"
            keyTimes="0;0.5;1"
            dur="1.2318518518518516s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </g>
    </ng-container>
  </svg>
</div>
