import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { HttpParamsObject } from './models/http-params.model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toHttpParamsObject(input: any): HttpParamsObject {
  return getHttpParams({}, input, '');
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getHttpParams(params: HttpParamsObject, data: any, currentPath = '') {
  Object.keys(data).forEach(key => {
    const value = data[key];

    if (!isUndefined(value) && !isNull(value)) {
      if (value instanceof Object && !Array.isArray(value)) {
        params = getHttpParams(params, value, `${currentPath}${key}.`);
      } else {
        params[`${currentPath}${key}`] = value;
      }
    }
  });

  return params;
}
