import { createAction, props } from '@ngrx/store';

export const openProfileMergeDuplicatesPage = createAction(
  scoped`Open Main Page`,
  props<{
    payload: {
      id?: { prId?: number | null; crmId?: number | null };
      type: string;
    };
  }>()
);

function scoped(templateString: TemplateStringsArray) {
  return '[Common Merge Duplicates] ' + templateString[0];
}
