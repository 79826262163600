import { SearchCategory } from '../../+state/models';

export interface CategoryIcon {
  class: string;
  color: string;
}

export const categoryIcon: { [key in SearchCategory]: CategoryIcon } = {
  [SearchCategory.Contacts]: { class: 'f-i-contact', color: 'badge-primary' },
  [SearchCategory.Institutions]: { class: 'f-i-bank', color: 'badge-accent-2' },
  [SearchCategory.Events]: { class: 'f-i-calendar', color: 'badge-success' },
  [SearchCategory.Funds]: { class: 'f-i-building', color: 'badge-warning' }
};
