import { Component, OnInit, SkipSelf } from '@angular/core';
import { NgbActiveModal, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { FireflyMobileModalComponent, ModalContent } from '../../modal';

@Component({
  selector: 'f-mobile-dropdown',
  template: ''
})
export class FireflyMobileDropdownComponent implements OnInit, ModalContent {
  context!: NgbDropdownMenu;
  modalInstance!: NgbActiveModal;

  constructor(@SkipSelf() private modalContainer: FireflyMobileModalComponent) {}

  ngOnInit() {
    this.modalContainer.toggleAcceptBtnVisibility(false);
    this.modalContainer.modalBody.nativeElement.prepend(this.context.nativeElement);
    this.context.dropdown.openChange.pipe(take(1)).subscribe(isOpen => {
      if (!isOpen) {
        this.modalContainer.modalBody.nativeElement.prepend(this.context.nativeElement.cloneNode(true));
        this.modalInstance.close();
      }
    });
  }
}
