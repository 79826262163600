import { createAction, props } from '@ngrx/store';
import { CurrencyResponse } from '@capital-access/common/standing-data';

export const loadSupportedCurrencies = createAction('[Globalization] [Supported Currencies] Load');
export const loadSupportedCurrenciesSuccess = createAction(
  '[Globalization] [Supported Currencies] Load Success',
  props<{ response: CurrencyResponse }>()
);
export const loadSupportedCurrenciesFail = createAction(
  '[Globalization] [Supported Currencies] Load Fail',
  props<{ error: unknown }>()
);
export const loadSupportedCurrenciesFallback = createAction('[Globalization] [Supported Currencies] Fallback');
