<div class="action-wrapper">
  <span
    class="action-item"
    data-automation-id="select-all"
    tabindex="0"
    (click)="onActionButtonClick(true, $event)"
    (keyup.enter)="onActionButtonClick(true, $event)"
    *ngIf="showSelectAll"
    >{{ selectAll$ | async }}</span
  >
  <span
    class="action-item"
    data-automation-id="clear-all"
    tabindex="0"
    (click)="onActionButtonClick(false, $event)"
    (keyup.enter)="onActionButtonClick(false, $event)"
    *ngIf="showClearAll"
    >{{ clearAll$ | async }}</span
  >
</div>
