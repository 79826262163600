import { NgModule } from '@angular/core';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { Breakpoint } from '@capital-access/firefly/components';

export const loadModule = () => import('@capital-access/management-hub/client').then(m => m.ManagementHubClientModule);
const isMobile = () => window.innerWidth < Breakpoint.Sm;

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: 'data_management',
          loadChildren: loadModule
        }
      ]
    }),
    CommonNavigationModule.forFeature({
      top: [
        {
          order: 600,
          enabled: !isMobile(),
          icon: 'f-i-crm',
          localizationKey: 'dataManagement',
          link: 'data_management'
        }
      ]
    })
  ]
})
export class ManagementHubClientLazyModule {}
