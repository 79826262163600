import { NgModule } from '@angular/core';
import { CommonRouterModule } from '@capital-access/common/router';

export const loadInvitationModule = () => import('@capital-access/events/emails/landing').then(m => m.InvitationModule);

export const loadSaveTheDateModule = () =>
  import('@capital-access/events/emails/landing').then(m => m.SaveTheDateModule);

export const loadUnsubscribeModule = () =>
  import('@capital-access/events/emails/landing').then(m => m.UnsubscribeModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      anonymous: [
        {
          path: 'mail',
          data: { navigation: false }, // do not show top-nav for landing pages
          children: [
            {
              path: 'invitation',
              loadChildren: loadInvitationModule
            },
            {
              path: 'save-the-date',
              loadChildren: loadSaveTheDateModule
            },
            {
              path: 'unsubscribe',
              loadChildren: loadUnsubscribeModule
            }
          ]
        }
      ]
    })
  ]
})
export class EventsEmailsLandingLazyModule {}
