import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbPopover, NgbPopoverConfig, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FireflyMobilePopoverComponent } from './components/mobile-popover.component';
import { FireflyMobilePopoverDirective } from './directives/mobile-popover.directive';
import { FireflyPopoverConfig } from './popover-config';

@NgModule({
  imports: [NgbPopoverModule, CommonModule],
  declarations: [FireflyMobilePopoverComponent, FireflyMobilePopoverDirective],
  exports: [NgbPopover, FireflyMobilePopoverDirective]
})
export class FireflyPopoverModule {
  static forRoot(): ModuleWithProviders<FireflyPopoverModule> {
    return {
      ngModule: FireflyPopoverModule,
      providers: [{ provide: NgbPopoverConfig, useClass: FireflyPopoverConfig }]
    };
  }
}
