import { createAction, props } from '@ngrx/store';
import { TitlePart } from './title.models';

export const updateTitle = createAction(
  '[Common Browser] Update Title',
  props<{
    token: string;
    title: TitlePart;
  }>()
);
