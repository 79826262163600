<div
  class="legend justify-content-{{ contentAlignment }}"
  [ngClass]="[alignment, legendRootClass ?? '']"
  data-automation-id="legend"
>
  <ng-container *ngTemplateOutlet="legendItems"></ng-container>
  <span
    #showAllBtn
    class="link link-primary legend-show-all"
    [ngClass]="marginClass"
    [ngStyle]="showAllBtnStyles"
    [ngbPopover]="popoverLegendItems"
    [placement]="showAllPopoverPlacement"
    [popoverClass]="legendPopoverClass"
    [autoClose]="'outside'"
    [triggers]="'click'"
    [container]="'body'"
    >{{ showAllTitle$ | async }}</span
  >
</div>

<ng-template #legendItem let-item let-widthInfoPopover="widthInfoPopover">
  <div class="d-flex align-items-center text-truncate">
    <span class="legend-marker" [ngClass]="item.markerCssClass" data-automation-id="legend-marker"></span>
    <span
      class="legend-title text-truncate"
      [ngClass]="$any(item.labelCssClass)"
      data-automation-id="legend-label"
      title="{{ item.label }}"
    >
      {{ item.label }}
    </span>
    <ng-container
      *ngTemplateOutlet="infoPopover; context: { $implicit: item, widthInfoPopover: widthInfoPopover }"
    ></ng-container>
  </div>
  <span *ngIf="item.value !== undefined" data-automation-id="legend-value" [ngClass]="valueCssClass">
    {{ item.value }}
  </span>
</ng-template>

<ng-template #infoPopover let-item let-widthInfoPopover="widthInfoPopover">
  <i
    fMobilePopover
    *ngIf="item.info && widthInfoPopover; else infoTitle"
    class="f-i f-i-info legend-info"
    data-automation-id="legend-info"
    [class]="item.infoSizeClass"
    [placement]="popoverPlacement"
    [mobilePopoverTitle]="item.label"
    [ngbPopover]="item.info"
    [triggers]="'hover focus'"
    [container]="'body'"
    [openDelay]="200"
  ></i>

  <ng-template #infoTitle>
    <i *ngIf="item.info" class="f-i f-i-info text-muted cursor-pointer" [attr.title]="item.info"></i>
  </ng-template>
</ng-template>

<ng-template #legendItems>
  <ng-container *ngFor="let item of data; let i = index; let last = last">
    <div class="legend-item" [ngClass]="last && !showAll ? '' : marginClass" [ngStyle]="{ order: i }">
      <ng-container *ngTemplateOutlet="legendItem; context: { $implicit: item, widthInfoPopover: true }"></ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #popoverLegendItems>
  <ng-container *ngFor="let item of data; let i = index; let last = last">
    <div class="legend-item" [class.mb-2]="!last">
      <ng-container
        *ngTemplateOutlet="legendItem; context: { $implicit: item, widthInfoPopover: false }"
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
