import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyActionButtonsComponent } from './action-buttons.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyActionButtonsComponent],
  exports: [FireflyActionButtonsComponent]
})
export class FireflyActionButtonsModule {}
