import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyBarSeriesModule } from '../common/bar-series/bar-series.module';
import { FireflyChartBoilerplateModule } from '../common/chart-boilerplate/chart-boilerplate.module';
import { FireflyBarChartComponent } from './bar-chart.component';

@NgModule({
  imports: [CommonModule, FireflyBarSeriesModule, FireflyChartBoilerplateModule],
  declarations: [FireflyBarChartComponent],
  exports: [FireflyBarChartComponent]
})
export class FireflyBarChartModule {}
