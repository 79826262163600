import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CommonRouterModule } from '@capital-access/common/router';
import { FireflyPopoverModule } from '@capital-access/firefly/components';
import { TryLocalizeModule } from '../pipes/try-localize/try-localize.module';
import { SubNavComponent } from './sub-nav.component';

@NgModule({
  imports: [CommonModule, CommonRouterModule, TryLocalizeModule, FireflyPopoverModule],
  declarations: [SubNavComponent],
  exports: [SubNavComponent]
})
export class SubNavModule {}
