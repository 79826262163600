import { type, value } from '@capital-access/common/user';

export type GlobalizationKey = 'currency' | 'language' | 'timezone' | 'culture';
export const GLOBALIZATION_SECTION_KEY = 'globalization';

const globalizationMap: Record<GlobalizationKey, string> = {
  currency: 'currency:code',
  language: 'language',
  timezone: 'timezone',
  culture: 'culture:name'
};

export function getGlobalizationKey(type: GlobalizationKey) {
  const key = globalizationMap[type];

  return `${GLOBALIZATION_SECTION_KEY}:${key}`;
}

export class GlobalizationSettings {
  @value(type.string, { path: globalizationMap['language'] }) language: string | null = null;
  // TODO: read values below from user preferences when more values supported
  /*@value(type.string, { path: globalizationMap['timezone'] })*/ timezone: string | null = null;
  @value(type.string, { path: globalizationMap['culture'] }) culture: string | null = null;
  @value(type.string, { path: globalizationMap['currency'] }) currency: string | null = null;
}
