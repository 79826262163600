<div
  class="list-group-item f-list-item-draggable"
  data-automation-id="f-draggable-list-item"
  [class.list-group-item-hover]="hoverable"
  [ngClass]="rootClass"
>
  <i
    class="f-i f-i-sm f-i-drag-drop me-2 d-none d-sm-inline"
    [class.f-draggable-list-item-drag-icon-disabled]="disabled || disabledIcon"
    [ngClass]="draggableIconClass"
    [title]="disabledIconTitle || ''"
  ></i>
  <div
    class="f-list-item-draggable-content"
    [class.f-list-item-draggable-content-disabled]="disabled"
    [attr.inert]="disabled || null"
  >
    <ng-content></ng-content>
  </div>
  <button
    type="button"
    *ngIf="actionBtnIcon"
    [ngClass]="actionBtnClass"
    [disabled]="disabled"
    [class.pe-none]="disabled"
    class="btn btn-icon btn-secondary ms-2"
    data-automation-id="f-draggable-list-item-action-button"
    (keyup.enter)="actionBtnClick.emit()"
    (click)="actionBtnClick.emit()"
  >
    <i class="f-i" [ngClass]="actionBtnIcon"></i>
  </button>
</div>
