import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  NgbNav,
  NgbNavConfig,
  NgbNavContent,
  NgbNavItem,
  NgbNavLink,
  NgbNavModule,
  NgbNavOutlet
} from '@ng-bootstrap/ng-bootstrap';
import { FireflyNavConfig } from './nav-config';

@NgModule({
  imports: [NgbNavModule],
  exports: [NgbNav, NgbNavItem, NgbNavLink, NgbNavOutlet, NgbNavContent]
})
export class FireflyNavModule {
  static forRoot(): ModuleWithProviders<FireflyNavModule> {
    return {
      ngModule: FireflyNavModule,
      providers: [{ provide: NgbNavConfig, useClass: FireflyNavConfig }]
    };
  }
}
