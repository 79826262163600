import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastMessageComponent } from './toast-message.component';
import { ToastMessageContainerComponent } from './toast-message-container/toast-message-container.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ToastMessageComponent, ToastMessageContainerComponent],
  exports: [ToastMessageComponent, ToastMessageContainerComponent]
})
export class FireflyToastMessageModule {}
