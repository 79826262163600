<div class="d-flex flex-column h-100" [caLocalizationScope]="localizationScope">
  <div class="ca-la-col-container d-flex">
    <div class="w-100 row g-0">
      <div class="ca-la-col-first col-lg-6" data-automation-id="license-agreement">
        <div class="overflow-auto pe-xl-8">
          <div class="px-4 px-sm-7 px-md-12 ps-xl-13 pb-6 pt-8 py-sm-9" data-automation-id="license-agreement-text">
            <h1 class="heading-50 d-flex mb-6 ca-la-header">
              <div class="f-brand-logo ca-la-brand-logo me-3"></div>
              {{ applicationName | caLocalize }}
            </h1>
            <h2 class="heading-30 mb-3" [caLocalizationScope]="localizationScope">
              {{ 'welcome' | caLocalize : { applicationName: applicationName | caLocalize } }}
            </h2>
            <div class="mb-3">
              <span>{{ 'group1' | caLocalize }} </span>
              <a class="link link-primary" [href]="settings.privacyPolicyUrl" target="_blank"
                >{{ 'privacyPolicyLinkTitle' | caLocalize }}.</a
              >
            </div>
            <div class="mb-3">
              <span>{{ 'group2' | caLocalize }} </span>
              <a class="link link-primary" [href]="settings.privacyPolicyUrl" target="_blank"
                >{{ 'privacyPolicyLinkTitle' | caLocalize }}.</a
              >
            </div>
            <div class="mb-3">
              <span>{{ 'group3' | caLocalize }} </span>
              <a class="link link-primary" [href]="settings.gcpTermsOfUseUrl" target="_blank">
                {{ 'termsOfUseLinkTitle' | caLocalize }}</a
              >
              <span> {{ 'group4' | caLocalize }}</span>
            </div>
            <div class="mb-3">
              <span>{{ 'group7' | caLocalize }} </span>
            </div>
            <div>
              <span>{{ 'group5' | caLocalize }} </span>
              <a class="link link-primary" [href]="settings.gcpTermsOfUseUrl" target="_blank">
                {{ 'termsOfUseLinkTitle' | caLocalize }}</a
              ><span> {{ 'group6' | caLocalize }}</span>
            </div>

            <div class="d-none d-lg-block pt-11">
              <ng-template [ngTemplateOutlet]="buttons"></ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="ca-la-col-second col-lg-6" data-automation-id="trial-agreement">
        <div class="ca-la-col-second-color d-flex flex-column h-100 pt-7 pt-sm-9 pt-md-12">
          <h2 class="subtitle-50 fw-bold mb-3 px-4 px-sm-7 px-md-12 px-xl-16">
            S&P Global {{ applicationName | caLocalize }} Trial Agreement
          </h2>
          <div class="ca-la-col-second-wrapper pb-7 pb-sm-9 pb-md-12">
            <div class="px-4 px-sm-7 ps-md-12 ps-xl-16 pe-md-8 pe-xl-12" data-automation-id="trial-agreement-text">
              <p class="fw-bold mb-3">
                This trial agreement (“Agreement”) applies to the Solutions licensed by S&P Global (“S&P”) to You. By
                accessing the Solutions, You acknowledge and irrevocably represent that You have read and understood,
                and agree to be bound by this Agreement. When you accept this Agreement, You represent and warrant that
                You are authorized to do so by the company by which you are employed or retained and for whose benefit
                you are using the Solutions (“Company”). References to “You” shall mean “You and the Company.” This
                Agreement applies to your use of the Solutions in addition to any other terms identified on the web site
                from which You access the Solutions. If You do not agree with these terms and conditions of this
                Agreement, do not access or use the Solutions.
              </p>
              <h4 class="body-70 fw-bold">1. Definitions</h4>
              <p>
                <span class="fw-bold">“Aggregated Data”</span>
                means data resulting from the aggregation, calculation, analysis or compilation of User-Submitted
                Content and/or data from other sources, including other clients or customers of S&amp;P; provided that
                (i) such resulting data does not identify You as the source of any such data, and (ii) the identities of
                You and Your clients are not disclosed by or discernable from the resulting data without an
                extraordinary amount of time or effort. S&P shall own the Aggregated Data, subject to Your rights in the
                User-Submitted Content.
              </p>
              <p>
                <span class="fw-bold">“Business to Business Communications”</span>
                means communications between or directed at corporate organizations (not including individuals, sole
                traders or partnerships) for the purposes of selling or marketing goods and/or services to the
                organization, which are relevant to the business of the individuals to whom such communications are
                sent, and which the individual would expect to receive in their business role.
              </p>
              <p>
                <span class="fw-bold">“Data Protection Legislation”</span>
                means the California Consumer Privacy Act, California Civil Code §1798.100 et seq., any law or
                regulation in force from time to time which implements the European Community’s Directive 95/46/EC,
                Directive 2002/58/EC, the General Data Protection Regulation 2016/679 and/or any equivalent national
                laws and regulations which otherwise regulate the processing of Personal Data and is applicable to the
                Solutions.
              </p>
              <p>
                <span class="fw-bold">“Database”</span>
                means any database (including any Personal Data) provided or made available by S&P through the
                Solutions, subject to the limitations herein.
              </p>
              <p>
                <span class="fw-bold">“Database Contacts”</span>
                means persons or institutions whose business contact details are contained in any Database.
              </p>
              <p>
                <span class="fw-bold">“Data Controller”</span>
                means an organization that determines the means and purposes of processing Personal Data.
              </p>
              <p>
                <span class="fw-bold">“Data Processor”</span>
                means an organization that processes Personal Data on behalf of a Data Controller.
              </p>
              <p>
                <span class="fw-bold">“Data Subject”</span>
                means any natural person whose Personal Data is being collected, held or processed.
              </p>
              <p>
                <span class="fw-bold">“Documentation”</span>
                means, if applicable, the materials, user guides, and manuals made available to You from time to time in
                connection with the Solutions.
              </p>
              <p>
                <span class="fw-bold">“e-Privacy Legislation”</span>
                means any law or regulation in force from time to time which implements the European Community’s
                Directive 2002/58/EC and/or the proposed e-Privacy Regulation 2017/0003 (COD), which is intended to
                replace the Directive 2002/58/EC and/or any equivalent national laws and regulations which otherwise
                regulate direct marketing to individuals pursuant to this Schedule.
              </p>
              <p>
                <span class="fw-bold">“S&P Property"</span>
                means:(a) materials forming part of the Solutions; (b) all information, processes, analytics, materials,
                techniques and technologies of or created or provided by S&P pursuant to this Agreement, including any
                algorithms, analyses, aggregated data, data, trademarks, copyrights, databases, domain names,
                Documentation, formats, forecasts, formulae, information, inventions, know-how, methodologies, models,
                schema, feed formats, tools, software (including all source code and object code), trade secrets,
                valuations, websites, programs; (c) any and all enhancements, updates, or modifications to any of the
                foregoing and any component thereof or of any derivative work which comprises any of the foregoing; and
                (d) all intellectual property and proprietary rights associated with (a) through (c) above.
              </p>
              <p>
                <span class="fw-bold">“Law”</span>
                means all international, national, federal, state, local or foreign statutes, laws, treaties,
                constitutions, ordinances, regulations, rules, judgments, decisions, writs, codes, orders and other
                requirements or rules of law, and all judicial interpretations in respect thereof.
              </p>
              <p>
                <span class="fw-bold">“Personal Data”</span>
                means any information relating to an identified or identifiable natural person.
              </p>
              <p>
                <span class="fw-bold">“Product(s)”</span>
                means all data, information, software, information technology systems, applications, reports and files
                and S&P Property or deliverables provided by S&P and/or its Third Party Providers to You under this
                Agreement.
              </p>
              <p>
                <span class="fw-bold">“Relevant Data Subjects”</span>
                means the Data Subjects whose Relevant Personal Data is being processed.
              </p>
              <p>
                <span class="fw-bold">“User-Submitted Content”</span>
                means content and information that You or other authorized users of the Solutions associated with the
                Company, upload, post, store, view, or transmit in the Solutions.
              </p>
              <p>
                <span class="fw-bold">“Service”</span>
                means services and S&P Property provided by S&P to You under this Agreement.
              </p>
              <p>
                <span class="fw-bold">“Solutions”</span>
                means, where applicable Product(s) and/or the Service, in each case including anything contained therein
                or provided in connection therewith and any portion thereof.
              </p>
              <p>
                <span class="fw-bold">“Third Party Providers”</span>
                means third parties providing data, software, information technology systems, any other deliverables or
                intellectual property to S&P to enable S&P to provide the Solutions.
              </p>
              <p class="mb-3">
                <span class="fw-bold">“Trial Period”</span>
                shall take effect upon the earlier of first delivery of the Solutions or login credentials for the
                Solutions, is free-of charge, and shall automatically expire sixty (60) days thereafter with no
                obligation to subscribe post the Trial Period.
              </p>
              <h4 class="body-70 fw-bold">2. Limited License</h4>
              <p class="mb-3">
                Subject to and in consideration of Your compliance with this Agreement, S&P grants You a personal,
                free-of-charge, non-exclusive, non-transferable, revocable license to use the Solutions during the Trial
                Period and to use any reports or other similar deliverables provided as a part of the Solutions (<span
                  class="fw-bold"
                  >“Reports”</span
                >), including content or materials derived therefrom (<span class="fw-bold">“Derived Content”</span>),
                solely for Your internal business purposes and not for redistribution. Except as expressly permitted
                herein, You shall not (directly or indirectly, knowingly or unknowingly): (i) commercially exploit any
                Reports or Derived Content in any manner; or (ii) alter, remove, obscure or fail to reproduce any rights
                notices contained in any Reports; or (iii) share the Reports or any Derived Content with any competitor
                of S&P or its affiliates. Any log-ins or other access credentials provided are personal and are
                Confidential Information of S&P and may not be shared. You may not assign any of Your rights or delegate
                any of Your obligations hereunder and any attempted assignment shall be null and void. This Agreement
                and any terms of use contained in any Solutions or login page set forth the entire agreement between us
                with regard to Your access to and use of the Solutions and supersede any and all prior proposals,
                agreements or communications, written or oral, of the parties with respect to the subject matter hereof.
                Upon any termination or expiration of the Trial Period, You shall cease using any portion of the
                Solutions and, except as otherwise permitted in this Agreement, purge all data or information contained
                in the Solutions from Your electronic or other systems. Notwithstanding the foregoing, upon expiration
                or termination of the Trial Period, You shall have the right to retain any Reports for internal
                recordkeeping purposes and, provided You are in compliance with the terms of this Agreement, to continue
                to use any Report as described herein and in accordance with the terms hereof.
              </p>
              <h4 class="body-70 fw-bold">3. Database Usage</h4>
              <p class="mb-3">
                Subject to any use restrictions set forth in this Agreement, You may access and use the Database solely:
                (i) to view any of the information in the Database; (ii) subject to the provisions of Section 8 hereof,
                to send information or materials to Database Contacts, provided all such communications comply with
                applicable Law and the Data Privacy Requirements contained herein; (iii) to download limited portions of
                information from the Database for Your use solely for the internal business purposes of the Company; and
                (iv) to print limited portions of the Database for Your use solely for the internal business purposes of
                the Company. You are solely responsible for any information, messages or materials You send to any
                Database Contact, whether through an imbedded mail module, regular mail, or otherwise. After termination
                or expiration of the applicable license granted hereby, You may not use any information obtained from
                any Database for any purpose, including, but not limited to, sending any fax, e-mail or mailing to any
                Database Contact.
              </p>
              <h4 class="body-70 fw-bold">4. Use Restrictions</h4>
              <p class="mb-3">
                Except as expressly permitted herein, You shall not: (i) use any data or information obtained from the
                Solutions to create or update Your own internal database or to modify any database or any product or
                service related to data or that could be competitive with or a substitute for any portion of any of the
                Solutions; (ii) create a “local” copy of any portion of a Solutions on any computer, system or
                otherwise, or take screen shots of or otherwise detail the appearance or functionality of any of the
                Solutions to or for any competitor of S&P or its affiliates, (iii) remove, suppress or modify in any way
                the proprietary markings, including any trademark or copyright notice, used in relation to the
                Solutions; (iv) refer to the Solutions or any trademark or copyright notice used in relation thereto, in
                a way which does or may imply (a) that the Solutions form part of the services or products offered to
                Your clients, or (b) that S&P is responsible for the accuracy or quality of the services or any other
                information or data that You provide to Your clients; (v) copy, distribute, display, publish, republish,
                scan, transfer, sell, license, lease, give, permanently retain, decompile, reverse engineer, modify or
                otherwise reproduce, disclose or make available to others (including Your affiliates), or create
                derivative works from, the Solutions or any portion thereof; (vi) circumvent or disable any security or
                technological measures of the Solutions (or any component thereof); (vii) use the Solutions for any
                illegal or unlawful purpose or in a manner which is competitive with or which would create a functional
                substitute for the Solutions; (viii) violate any applicable Law , including any relating to competition
                or antitrust matters; or (ix) infringe, violate, breach or otherwise contravene any rights of S&P, its
                affiliates or any third party (including any Third Party Provider), including any copyright, database
                right, trademark, patent, right of confidence or any other proprietary or intellectual property right in
                connection with the Solutions.
              </p>
              <h4 class="body-70 fw-bold">5. Your Responsibilities</h4>
              <p class="mb-3">
                Without limiting Your responsibilities set forth elsewhere in this Agreement, You are responsible for
                any information, messages, and materials that you send using the Solutions. Note that any e-mail
                functionality included in the Solutions may or may not include auto-disclosure or unsubscribe features.
                In addition, if You use any appointment or other activities functionality provided by the Solutions, you
                acknowledge that S&P is not able to guarantee that a given activity will be correctly transmitted or
                calendared by the Solutions on your computer or device or on that of any other person. You must
                implement, monitor, and enforce reasonable and appropriate security measures to prevent unauthorized
                access or use of login, passwords, or other credentials or means of access the Solutions. You must
                promptly notify S&P in the event of any actual or reasonably suspected unauthorized access to the
                Solutions or of compromise to the confidentiality of login credentials, and you must provide all
                reasonable cooperation to S&P in order to appropriately address the situation to S&P’s reasonable
                satisfaction.
              </p>
              <h4 class="body-70 fw-bold">6. Ownership</h4>
              <p class="mb-3">
                Title to and ownership of the Solutions and all components and portions thereof and all applicable
                rights in patents, copyrights and trade secrets in the Solutions shall remain exclusively in S&P (or its
                Third Party Providers as provided in this Agreement). All rights not expressly granted under this
                Agreement are reserved. S&P reserves the right to replace, discontinue or make any change to the
                Solutions or any portion thereof at any time.
              </p>
              <h4 class="body-70 fw-bold">7. Confidential Information</h4>
              <p class="mb-3">
                The Solutions provided under this Agreement are confidential and/or proprietary to S&P, and You shall
                safeguard the Solutions and this Agreement accordingly. You agree to hold any information of S&P
                (including without limitation, the Solutions) that is confidential or proprietary to S&P (<span
                  class="fw-bold"
                  >“Confidential Information”</span
                >) in strict confidence and not to copy, reproduce, sell, assign, license, market, transfer or otherwise
                dispose of, give or disclose such Confidential Information to any third party, or to use such
                Confidential Information for any purposes other than the trial covered by this Agreement. It is
                understood and agreed that in the event of a breach of confidentiality, damages may not be an adequate
                remedy and each party shall be entitled to injunctive relief to restrain any such breach, threatened or
                actual.
              </p>
              <h4 class="body-70 fw-bold">8. Data Privacy Requirements</h4>
              <p>
                a) You acknowledge and agree that with respect to any processing undertaken by or on behalf of You
                (including the Company) of any Personal Data contained in the Database (including any Database
                Contacts), to the extent that such personal data is governed by or subject to the Data Protection
                Legislation (the <span class="fw-bold">“Relevant Personal Data”</span>), You (including the Company) are
                the Data Controller, when applicable. S&P acknowledges and agrees that with respect to its collection,
                storage and license of Relevant Personal Data, S&P is also a Data Controller, when applicable. Each of
                S&P and You agree to comply with the Data Protection Legislation (including terms applicable to it as a
                Data Controller) and the e-Privacy Legislation, when applicable, in connection with processing of the
                Relevant Personal Data by it or on its behalf.
              </p>
              <p class="mb-3">
                b) You acknowledge and agree that You shall (i) process the Relevant Personal Data only for Business to
                Business Communications and in compliance with all applicable Laws (including Data Protection
                Legislation and e-Privacy Legislation, as well as other laws applicable to communications with the
                Relevant Data Subject in the jurisdiction where the Relevant Data Subject is located); (ii) if required
                under other laws applicable to communications with the Relevant Data Subject in the jurisdiction where
                the Relevant Data Subject is located, obtain the prior explicit consent of the Relevant Data Subjects to
                its intended use of the Relevant Personal Data; (iii) refrain from using the Relevant Personal Data to
                contact Relevant Data Subjects who have unsubscribed or opted-out from communications for any purpose,
                including direct marketing or Business to Business Communications; (iv) review any suppression lists,
                Withdrawal list, Data Subject preferences and/or notices maintained and provided by S&P or included in
                the Solutions before sending any communications to any Relevant Data Subject; and (v) provide S&P with
                timely and reasonable assistance and information regarding Your processing of Relevant Personal Data as
                may be required for S&P to comply or demonstrate compliance with the Data Protection Legislation.
                Additionally, prior to processing any Relevant Personal Data, You shall (a) implement appropriate
                technical and organizational measures designed to ensure, and to enable You to demonstrate, that Your
                processing of such Relevant Personal Data is performed with adequate safeguards for the protection of
                the Relevant Personal Data, (b) adopt and implement policies, procedures and systems to respond to Your
                legal obligations and the needs of the Relevant Data Subject whose Relevant Personal Data is being
                processed, including the provision for Relevant Data Subjects to unsubscribe/opt-out of the
                communications, and update Your records accordingly, and (c) ensure that any third party to whom You
                disclose Relevant Personal Data implements and maintains reasonable security measures to meet the
                requirements of applicable Law and this Agreement and have agreed to contractual terms that are no less
                stringent that those set out herein. You shall also ensure that each such third party is notified
                without undue delay and instructed to not further process applicable Relevant Personal Data upon (y)
                Your receipt of a valid opt-out request under applicable Laws, and/or (z) notification from S&P or its
                affiliates, including that contained in the Product and/or its Withdrawal List, that the applicable
                Relevant Personal Data is subject to a valid opt-out request. Where required by Law, You shall: (i)
                comply with each Relevant Data Subject’s Opt-Out Request; (ii) forward that Opt-Out Request to any other
                party to/with whom You have disclosed or shared the Relevant Data Subject’s Relevant Personal Data; and
                (iii) not retain, use, or further disclose that Relevant Data Subject’s Relevant Personal Data. You
                shall remain fully liable to S&P for any failure by each third party to fulfill its obligations in
                relation to the processing of any Relevant Personal Data.
              </p>
              <h4 class="body-70 fw-bold">9. User-Submitted Content</h4>
              <p class="mb-3">
                The Solutions may permit the use of User-Submitted Content. S&P shall have no responsibility or
                liability for any User-Submitted Content or access thereto, and S&P shall not be responsible for any
                loss, unavailability, inaccuracy, or corruption of such User-Submitted Content. You agree to provide
                User-Submitted Content only in accordance with applicable Law, and you represent that you have obtained
                all necessary rights and consents for the contemplated publication, use, storage, transfer, and
                dissemination of such User-Submitted Content in accordance with this Agreement.
              </p>
              <p class="mb-3">
                With respect to any User-Submitted Content submitted by You or generated from content or information
                submitted by You, you hereby grant to S&P and its Third Party Providers acting on behalf of S&P the
                right and a license (i) to post, store, and use, and to transmit such User-Submitted Content to You in
                connection with the Solutions; (ii) to modify and create derivative works from the User-Submitted
                Content; (iii) to review such User-Submitted Content to investigate or address any issue or complaint
                concerning the Solutions or any User-Submitted Content; and (iv) to use such User-Submitted Content to
                create Aggregated Data (which shall be owned exclusively by S&P).
              </p>
              <p class="mb-3">
                You represent, warrant, and covenant that You shall not submit any User-Submitted Content which (A) is
                unlawful, threatening, abusive, libelous, obscene, vulgar, or indecent; (B) constitutes or encourages
                conduct that would constitute a criminal offense or give rise to civil liability; (C) violates,
                plagiarizes, infringes, or constitutes a misappropriation of the rights of any third party including,
                without limitation, copyright, trademark, trade secret, patent, or any other proprietary right, or any
                rights of privacy or publicity; (D) violates any obligation of confidentiality; or (E) contains any
                software code or computer programming of any kind.
              </p>
              <p class="mb-3">
                S&P reserves the right to refuse to post or transmit or to remove any User-Submitted Content in whole or
                in part that S&P believes has been uploaded in violation of this Agreement, or that S&P believes
                constitutes a threat to the operation or integrity of the Solutions or to the rights and interests of
                S&P or its customers.
              </p>
              <h4 class="body-70 fw-bold">10. Personal Data and Usage Data</h4>
              <p class="mb-3">
                To the extent User-Submitted Content includes any Personal Data, you acknowledge that (A) S&P is a data
                processor with respect to that Personal Data, and (B) You, including the Company, are the data
                controller of such Personal Data. In addition, you acknowledge and agree that S&P may cause or permit
                such Personal Data to be transferred between or among various S&P affiliates, service providers and
                facilities, including facilities outside of the EU, including in the United Kingdom and the United
                States. It is Your (including the Company’s) obligation to ensure that You have complied with applicable
                Law with respect to the inclusion of such Personal Data in User-Submitted Content and in authorizing
                such transfers.
              </p>
              <p class="mb-3">
                You acknowledge and agree that, when You access or use the Solutions, S&P may collect, store, and use
                Your registration details, preferences, permissions, login data, and related information. This may
                include technical details about your computing device and browser. Additional information regarding
                S&P’s storage and handling of such personal data is set forth in S&P’s Privacy Policy located at
                <a [href]="settings.privacyPolicyUrl" class="link link-primary text-break">
                  {{ settings.privacyPolicyUrl }}</a
                >.
              </p>
              <h4 class="body-70 fw-bold">11. Limits on Downloads</h4>
              <p class="mb-3">
                S&P may place limits on the number of records You may print, download or otherwise export. If you exceed
                this limit, without limiting any other rights S&P may have hereunder, S&P shall have the right to
                terminate this Agreement and your access to the Solutions (or any portion thereof) and/or request an
                explanation or certification from You with respect to such usage.
              </p>
              <h4 class="body-70 fw-bold">12. Disclaimer</h4>
              <p class="mb-3">
                THE SOLUTIONS (AND ALL COMPONENTS THEREOF INCLUDING REPORTS) LICENSED HEREUNDER IS PROVIDED "AS-IS", AND
                NEITHER S&P, ITS AFFILIATES NOR ANY THIRD PARTY PROVIDER SHALL BE LIABLE TO YOU FOR ANY INACCURACIES,
                ERRORS OR OMISSIONS IN THE SOLUTIONS. NEITHER S&P, ITS AFFILIATES NOR ANY THIRD PARTY PROVIDER MAKES ANY
                WARRANTIES OR REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, IN RESPECT OF THIS AGREEMENT, THE
                SOLUTIONS OR DOCUMENTATION PROVIDED HEREUNDER OR AS TO THE RESULTS TO BE ATTAINED BY YOU OR OTHERS FROM
                THE USE OF THE SOLUTIONS. ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, CONTINUITY, ACCURACY, TIMELINESS, COMPLETENESS OR
                NON-INFRINGEMENT ARE EXPRESSLY DISCLAIMED. TO THE EXTENT PERMITTED BY LAW, YOU AGREE THAT NEITHER S&P,
                ITS AFFILIATES NOR ANY THIRD PARTY PROVIDER SHALL BE LIABLE FOR ANY LOSS, CLAIM OR DAMAGE, TO YOU OR ANY
                THIRD PARTY WHETHER DIRECT OR INDIRECT, WHETHER BASED ON CONTRACT, TORT, OR ANY OTHER LEGAL THEORY
                RELATING TO YOUR USE OR POSSESSION OF OR RELIANCE ON THE SOLUTIONS INCLUDING, WITHOUT LIMITATION, ANY
                INCIDENTAL OR CONSEQUENTIAL DAMAGES.
              </p>
              <h4 class="body-70 fw-bold">13. Third Party Providers</h4>
              <p class="mb-3">
                You further acknowledge that certain data and information content made available through the Solutions
                may have been provided to S&P by Third Party Providers and such Third Party Providers have proprietary
                rights with respect to such data. In such case, the use and availability of such data and information is
                subject to arrangements between S&P and such Third Party Providers, and any limitations and restrictions
                that may be displayed or contained on the Solutions. You agree to comply with any restriction or
                condition imposed by Third Party Providers relating to the Solutions as notified by S&P or such Third
                Party Providers. As part of such compliance, You may be required to enter into a separate agreement with
                S&P or a Third Party Provider in order to receive or use such content. Third Party Provider restrictions
                may be provided at
                <a [href]="settings.gcpTermsOfUseUrl" class="link link-primary text-break">
                  {{ settings.gcpTermsOfUseUrl }}
                </a>
                and/or supplied within the Solutions or directly by a Third Party Provider. In addition, You acknowledge
                and agree that, to the extent required under its agreements with Third Party Providers, S&P may provide
                such Third Party Providers with Your identity and your log-in and/or business contact information.
                Notwithstanding anything to the contrary herein, S&P is not responsible for any third-party content
                provided through the Solutions and shall have no liability or obligations with respect to such content.
              </p>
              <h4 class="body-70 fw-bold">14. No Advice</h4>
              <p class="mb-3">
                The Solutions is intended only for professionals and is not and should not be construed as financial,
                investment, legal, tax or other advice of any kind, nor should it be regarded as an offer,
                recommendation, or as a solicitation of an offer to buy, sell or otherwise deal in any investment or
                securities. You may not use the Solutions to transmit, undertake or encourage any unauthorized
                investment advice or financial promotions, or to generate any advice, recommendations, guidance,
                publications or alerts made available to clients or other third parties. Nothing in the Solutions
                constitutes a solicitation by S&P of the purchase or sale of loans, securities, bonds or any investment.
              </p>
              <h4 class="body-70 fw-bold">15. Breach of Agreement</h4>
              <p class="mb-3">
                If You breach any of the obligations under this Agreement, S&P shall have the right, in addition to any
                and all other remedies it may have hereunder, at law or in equity, in its own discretion to claim the
                reasonable value of unauthorized use of the Solutions and/or to terminate your access to and use of the
                Solutions (and all portions thereof). Notwithstanding the foregoing, You shall indemnify S&P and its
                affiliates against any cost, loss and/or damage suffered by S&P and/or its affiliates, including S&P’s
                attorneys’ fees, arising as a result of any use of the Solutions by You or any breach of or
                non-compliance with this Agreement.
              </p>
              <h4 class="body-70 fw-bold">16. SEDOL</h4>
              <p class="mb-3">
                Your license includes access to SEDOL numbers, and the following provisions apply: all SEDOL numbers
                made available to You are obtained from the London Stock Exchange’s (the
                <span class="fw-bold">“Exchange”</span>) SEDOL Masterfile. The SEDOL numbers are for internal matching
                purposes only and may not be reproduced and/or extracted or redistributed without the Exchange’s prior
                written consent. You are responsible for obtaining, directly from the Exchange, the relevant licenses
                for reproduction and/or extraction or redistribution of SEDOL numbers provided to You hereunder.
              </p>
              <h4 class="body-70 fw-bold">17. CUSIP</h4>
              <p class="mb-3">
                Your license includes access to CUSIP numbers, and the following provisions apply: all CUSIP numbers
                provided to You are for internal matching purposes only and are subject to the CUSIP Copyright and
                Disclaimers attached as Exhibit A. S&P shall have the right to terminate Your license immediately upon
                any unauthorized use of CUSIP numbers by You.
              </p>
              <h4 class="body-70 fw-bold">18. Miscellaneous</h4>
              <p class="mb-3">
                You may not use any linking, deep-linking, framing or page-scraping technology, robots, spiders or other
                automatic devices, programs, algorithms or any similar manual processes, to access, acquire, copy, or
                distribute any portion of this website or any Solutions content, or in any way reproduce or circumvent
                the navigational structure of this website, to obtain or attempt to obtain any Solutions content through
                any means not purposely made available to You through this website.
              </p>
              <p class="mb-3">
                S&P may terminate Your access and use of the Solutions and this Agreement at any time during the Trial
                Period in its sole discretion. In addition, S&P may, with immediate effect, suspend or terminate this
                Agreement and cancel or withdraw all or any part of the Solutions provided pursuant to the terms of this
                Agreement at any time that You are in breach of this Agreement or that in S&P’s reasonable opinion (i)
                You fail to cooperate with any reasonable investigation of a suspected breach, (ii) it is necessary to
                do so in order to comply with (x) any change in a material contractual requirement imposed by a Third
                Party Provider or (y) with any applicable Law; (iii) such Solutions has become (or it likely to become)
                subject to a claim that it infringes the rights of any third party; or (iv) the data used to provide
                such Solutions is not commercially satisfactory in terms of legality, quality, volume or significance.
              </p>
              <p class="mb-3">
                The Solutions provided hereunder: (a) was developed at private expense and is in all respects the
                proprietary information of S&amp;P; (b) was not developed with government funds; (c) is a trade secret
                of S&P for all purposes of the Freedom of Information Act and any provincial, state, federal or other
                government access to information legislation; and (d) is a commercial item and thus, duplication or
                disclosure of the Solutions is subject to the restrictions set forth by S&P.
              </p>
              <p class="mb-3">
                You agree to comply with all applicable Law in your use of the Solutions. Export controls and sanctions
                laws and regulations of the United States and other relevant local export controls and sanctions laws
                may apply to the Solutions provided hereunder and to such extent, You agree to comply with all such
                export controls and sanctions laws and regulations, and agree that You will not export, re-export,
                transfer, sell or use the Solutions without the prior express written permission of S&P.
              </p>
              <p class="mb-3">
                Provisions titled Ownership, Confidentiality, Disclaimer, Data Privacy Requirements, User Submitted
                Content, Personal Data and Usage Data, No Advice, Breach of Agreement and Miscellaneous shall survive
                the termination or expiration of this Agreement.
              </p>
              <p class="mb-3">
                This Agreement and the Solutions shall be governed by the law of State of New York (if You are a North
                American user) or England and Wales (if you are a user outside of North America), without regard to
                conflict of laws principles; and You submit to the exclusive jurisdiction of the state and federal
                courts residing in New York, New York (for North American users) and English courts located in London
                (for all other users) for the purposes of determining any dispute arising out of this Agreement or the
                Solutions.
              </p>
              <h3 class="subtitle-50 fw-bold">Exhibit A</h3>
              <h3 class="subtitle-50 fw-bold mb-3">CUSIP Copyright and Disclaimers</h3>
              <p class="mb-3">
                <span class="fw-bold">
                  CUSIP Database provided by CUSIP Global Services (CGS)-CUSIP Service Bureau (CSB):
                </span>
                You agree and acknowledge that the CUSIP Database and the information contained therein is and shall
                remain valuable intellectual property owned by or licensed to, Standard & Poor's CUSIP Service Bureau
                ("CSB") and the American Bankers Association ("ABA"), and that no proprietary rights are being
                transferred to You in such materials or in any of the information contained therein. Any use by You
                outside of the clearing and settlement of transactions requires a license from CSB, along with an
                associated fee based on usage. You agree that misappropriation or misuse of such materials will cause
                serious damage to CSB and ABA, and that in such event money damages may not constitute sufficient
                compensation to CSB and ABA; consequently, You agree that in the event of any misappropriation or
                misuse, CSB and ABA shall have the right to obtain injunctive relief in addition to any other legal or
                financial remedies to which CSB and ABA may be entitled.
              </p>
              <p class="mb-3">
                You agree that You shall not publish or distribute in any medium the CUSIP Database or any information
                contained therein or summaries or subsets thereof to any person or entity except in connection with the
                normal clearing and settlement of security transactions. You further agree that the use of CUSIP numbers
                and descriptions is not intended to create or maintain, and does not serve the purpose of the creation
                or maintenance of, a master file or database of CUSIP descriptions or numbers for itself or any third
                party recipient of such service and is not intended to create and does not serve in any way as a
                substitute for the CUSIP MASTER TAPE, PRINT, DB, INTERNET, ELECTRONIC, CD-ROM Services and/or any other
                future services developed by the CSB.
              </p>
              <p class="mb-3">
                NEITHER CSB, ABA NOR ANY OF THEIR AFFILIATES MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, AS TO THE
                ACCURACY, ADEQUACY OR COMPLETENESS OF ANY OF THE INFORMATION CONTAINED IN THE CUSIP DATABASE. ALL SUCH
                MATERIALS ARE PROVIDED TO SUBSCRIBER ON AN “AS IS" BASIS, WITHOUT ANY WARRANTIES AS TO MERCHANTABILITY
                OR FITNESS FOR A PARTICULAR PURPOSE OR USE NOR WITH RESPECT TO THE RESULTS WHICH MAY BE OBTAINED FROM
                THE USE OF SUCH MATERIALS. NEITHER CSB, ABA NOR THEIR AFFILIATES SHALL HAVE ANY RESPONSIBILITY OR
                LIABILITY FOR ANY ERRORS OR OMISSIONS NOR SHALL THEY BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT OR
                INDIRECT, SPECIAL OR CONSEQUENTIAL, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                IN NO EVENT SHALL THE ·LIABILITY OF CSB, ABA OR ANY OF THEIR AFFILIATES PURSUANT TO ANY CAUSE OF ACTION,
                WHETHER IN CONTRACT, TORT, OR OTHERWISE, EXCEED THE FEE PAID BY CUSTOMER FOR ACCESS TO SUCH MATERIALS IN
                THE MONTH IN WHICH SUCH CAUSE OF ACTION IS ALLEGED TO HAVE ARISEN. FURTHERMORE, CSB AND ABA SHALL HAVE
                NO RESPONSIBILITY OR LIABILITY FOR DELAYS OR FAILURES DUE TO CIRCUMSTANCES BEYOND THEIR CONTROL.
              </p>
              <p>
                You agree that the foregoing terms and conditions shall survive any termination of Your right of access
                to the materials identified above.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ca-la-col-second-color py-3 d-lg-none" data-automation-id="card-footer">
    <div class="px-4 px-sm-7 px-md-12">
      <ng-template [ngTemplateOutlet]="buttons"></ng-template>
    </div>
  </div>
</div>

<ng-template #buttons [caLocalizationScope]="localizationScope">
  <button class="btn btn-lg btn-primary me-3" data-automation-id="accept-button" (click)="accept()">
    {{ 'accept' | caLocalize }}
  </button>
  <button class="btn btn-lg btn-secondary" data-automation-id="decline-button" (click)="decline()">
    {{ 'decline' | caLocalize }}
  </button>
</ng-template>
