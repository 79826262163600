import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AppSettingsService } from './app-settings.service';
import { AppKey } from './models/apps-settings.model';

@Injectable({
  providedIn: 'root'
})
export class AppUrlService {
  private defaultDomain = 'spglobal';
  private env = this.settingsService.getSettings<{ environmentName: string }>().environmentName;
  private currentDomain = this.getCurrentDomain();

  private appUrls = {
    local: {
      [AppKey.ADMIN]: 'http://localhost:4201',
      [AppKey.CAPITAL_ACCESS]: 'http://localhost:4200',
      [AppKey.CAPITAL_INSIGHT]: 'http://localhost:4203',
      [AppKey.COMPASS]: 'http://localhost:4202',
      [AppKey.CAPITAL_IQ_PRO]:
        'https://sam.samexternal.net/sso/saml2/jsp/idpSSOInit.jsp?metaAlias=/Customers/idp&spEntityID=https://www.okta.com/saml2/service-provider/spuddumtolzabxcoyfdc&RelayState=https%3A%2F%2Fwww.capitaliq.spglobal.com%2Fweb%2Flogin'
    },
    dev: {
      [AppKey.ADMIN]: `https://admin.dev.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_ACCESS]: `https://dev.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_INSIGHT]: `https://dev.capitalinsight.${this.defaultDomain}.com`,
      [AppKey.COMPASS]: `https://dev.compass.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_IQ_PRO]:
        'https://sam.samexternal.net/sso/saml2/jsp/idpSSOInit.jsp?metaAlias=/Customers/idp&spEntityID=https://www.okta.com/saml2/service-provider/spuddumtolzabxcoyfdc&RelayState=https%3A%2F%2Fwww.capitaliq.spglobal.com%2Fweb%2Flogin'
    },
    staging: {
      [AppKey.ADMIN]: `https://admin.staging.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_ACCESS]: `https://staging.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_INSIGHT]: `https://staging.capitalinsight.${this.defaultDomain}.com`,
      [AppKey.COMPASS]: `https://staging.compass.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_IQ_PRO]:
        'https://sam.samexternal.net/sso/saml2/jsp/idpSSOInit.jsp?metaAlias=/Customers/idp&spEntityID=https://www.okta.com/saml2/service-provider/spuddumtolzabxcoyfdc&RelayState=https%3A%2F%2Fwww.capitaliq.spglobal.com%2Fweb%2Flogin'
    },
    perf: {
      [AppKey.ADMIN]: `https://admin.perf.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_ACCESS]: `https://perf.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_INSIGHT]: `https://perf.capitalinsight.${this.defaultDomain}.com`,
      [AppKey.COMPASS]: `https://perf.compass.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_IQ_PRO]:
        'https://sam.samexternal.net/sso/saml2/jsp/idpSSOInit.jsp?metaAlias=/Customers/idp&spEntityID=https://www.okta.com/saml2/service-provider/spuddumtolzabxcoyfdc&RelayState=https%3A%2F%2Fwww.capitaliq.spglobal.com%2Fweb%2Flogin'
    },
    prod: {
      [AppKey.ADMIN]: `https://admin.capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_ACCESS]: `https://capitalaccess.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_INSIGHT]: `https://capitalinsight.${this.defaultDomain}.com`,
      [AppKey.COMPASS]: `https://compass.${this.defaultDomain}.com`,
      [AppKey.CAPITAL_IQ_PRO]:
        'https://sam.ihsmarkit.com/sso/saml2/jsp/idpSSOInit.jsp?metaAlias=/Customers/idp&spEntityID=https://www.okta.com/saml2/service-provider/spdllhctujhfkzrphguj&RelayState=https%3A%2F%2Fwww.capitaliq.spglobal.com%2Fweb%2Flogin'
    }
  };

  constructor(
    private settingsService: AppSettingsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {}

  getAppUrl(appName: AppKey) {
    const envConfig = this.appUrls[this.env as keyof AppUrlService['appUrls']];

    if (!envConfig || !envConfig[appName]) {
      const origin = this.document.location.origin;
      return `${origin}${this.baseHref ?? '/'}`;
    }

    let url = envConfig[appName];
    if (appName === AppKey.CAPITAL_IQ_PRO) {
      return url;
    }

    if (this.env === 'dev' && this.baseHref?.includes('pr')) {
      url = url.replace('https://', `https://pr.`);
    }

    const appUrl = `${url}${this.baseHref ?? '/'}`;

    if (this.currentDomain !== this.defaultDomain && appName !== AppKey.CAPITAL_INSIGHT) {
      return appUrl.replace(this.defaultDomain, this.currentDomain);
    }

    return appUrl;
  }

  private getCurrentDomain() {
    const hostEntries = location.hostname.split('.');
    const comIndex = hostEntries.findIndex(i => i === 'com');
    return hostEntries[comIndex - 1] ?? this.defaultDomain;
  }
}
