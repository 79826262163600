import { NgModule } from '@angular/core';
import { USER_SETTINGS_ROUTES, UserSettingsRoutes } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';

export const loadModule = () => import('@capital-access/user/settings').then(m => m.UserSettingsModule);

const rootRoute = 'user';

const routes: UserSettingsRoutes = {
  myAccount: `${rootRoute}/my-account`,
  crmData: `${rootRoute}/crm-data`,
  settings: `${rootRoute}/settings`
};

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: rootRoute,
          loadChildren: loadModule
        }
      ]
    })
  ],
  providers: [
    {
      provide: USER_SETTINGS_ROUTES,
      useValue: routes
    }
  ]
})
export class UserSettingsLazyModule {}
