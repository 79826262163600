import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseSmallCardRowComponent } from '../base-small-card-row/base-small-card-row.component';

@Component({
  selector: 'f-small-card-row-header',
  templateUrl: './small-card-row-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallCardRowHeaderComponent extends BaseSmallCardRowComponent {
  @Input() thirdContainerTitle: string | null = '';
  @Input() firstContainerID = '';
  @Input() thirdContainerID = '';
  @Input() marginBottomClass: 'primary' | 'secondary' = 'primary';
}
