import { createSelector } from '@ngrx/store';
import { createSettingsSelectors, getUserLoadStatus } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';
import { getSupportedCurrenciesCodes, getSupportedCurrenciesLoadStatus } from '../currency/+state/selectors';
import { supportedLanguages, supportedCultures } from '../models/constants';
import { GLOBALIZATION_SECTION_KEY, GlobalizationSettings } from '../models/globalization';
import { resolveCulture } from '../models/types/culture';
import { resolveCurrency } from '../models/types/currency';
import { resolveLanguage } from '../models/types/language';
import { resolveTimezone } from '../models/types/timezone';

export const {
  selectSaving: getGlobalizationSettingsSaving,
  selectFailed: getGlobalizationSettingsFailed,
  selectSettings: getRawGlobalizationSettings
} = createSettingsSelectors(GlobalizationSettings, GLOBALIZATION_SECTION_KEY);

export const getUserSettingsTimezone = createSelector(getRawGlobalizationSettings, globalization =>
  resolveTimezone(globalization.timezone)
);

export const getUserSettingsLanguage = createSelector(getRawGlobalizationSettings, globalization =>
  // TODO: include testLang only for test users
  resolveLanguage(globalization.language, supportedLanguages)
);

export const getUserSettingsCulture = createSelector(getRawGlobalizationSettings, globalization => {
  return resolveCulture(globalization.culture, supportedCultures);
});

export const getIsUserSettingsCurrencyResolved = createSelector(
  getUserLoadStatus,
  getSupportedCurrenciesLoadStatus,
  (settingsStatus, currenciesStatus) =>
    (settingsStatus == LoadStatus.Failed || settingsStatus == LoadStatus.Loaded) &&
    (currenciesStatus == LoadStatus.Failed || currenciesStatus == LoadStatus.Loaded || currenciesStatus == 'Fallback')
);

export const getUserSettingsCurrency = createSelector(
  getRawGlobalizationSettings,
  getSupportedCurrenciesCodes,
  (globalization, supportedCurrencies) => resolveCurrency(globalization.currency, supportedCurrencies)
);

export const getUserSettingsCurrencySymbol = createSelector(getUserSettingsCurrency, currency => currency);
