import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyChartBoilerplateModule } from '../common/chart-boilerplate/chart-boilerplate.module';
import { FireflyGroupedBarSeriesModule } from '../common/grouped-bar-series/grouped-bar-series.module';
import { FireflyGroupedBarChartComponent } from './grouped-bar-chart.component';

@NgModule({
  imports: [CommonModule, FireflyGroupedBarSeriesModule, FireflyChartBoilerplateModule],
  declarations: [FireflyGroupedBarChartComponent],
  exports: [FireflyGroupedBarChartComponent]
})
export class FireflyGroupedBarChartModule {}
