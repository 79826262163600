import { animate, style, transition, trigger } from '@angular/animations';

export const enterTransition = 400;
export const leaveTransition = 500;
export const delay = 600;

export const iconAnimation = trigger('iconAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate(enterTransition + 'ms ease-out', style({ opacity: 1 }))]),
  transition(':leave', [animate(leaveTransition + 'ms ease-out', style({ opacity: 0 }))])
]);
