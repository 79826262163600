export const KEY_DELIMITER = ':';

const trimKey = (key: string) => {
  while (key.startsWith(KEY_DELIMITER)) {
    key = key.substring(1);
  }
  while (key.endsWith(KEY_DELIMITER)) {
    key = key.substring(0, key.length - 1);
  }
  return key;
};

export const fullSettingKey = (section: string, key: string) => {
  return trimKey(`${section}${KEY_DELIMITER}${key}`);
};

export const shortSettingKey = (section: string, key: string) => {
  return trimKey(key.substring(section.length));
};
