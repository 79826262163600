import { Action, createReducer, on } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { ProgressStats, SyncVendor, TransactionStatus, TransactionType } from '../../../models';
import {
  cancelTransaction,
  loadEnableSyncProgressStatus,
  loadEnableSyncProgressStatusFailure,
  loadEnableSyncProgressStatusSuccess,
  loadProgressStatusFailure,
  loadProgressStatusSuccess,
  reset,
  setInitData,
  setProgressStatus,
  skipTransaction,
  syncActivities,
  transactionFailure,
  transactionReset,
  triggerEnableSyncProgress
} from './transaction.actions';

export interface EnableSyncState {
  progressStats: ProgressStats;
  transactionId: string;
  loadStatus: LoadStatus;
  activityId: string;
  eventId: string;
  calendarId: string;
}

export interface State {
  email: string | null;
  vendorType: SyncVendor | null;
  sync: {
    progressStats: ProgressStats | null;
    transactionId: string | null;
    loadStatus: LoadStatus;
  };
  enableSync: EnableSyncState[];
}

export const initialState: State = {
  email: null,
  vendorType: null,
  sync: {
    progressStats: null,
    transactionId: null,
    loadStatus: LoadStatus.Pristine
  },
  enableSync: []
};

export const transactionReducer = createReducer(
  initialState,

  on(setProgressStatus, (state, { progressStats, transactionType }) => {
    const transactionId =
      progressStats.transactionStatus === TransactionStatus.Complete ? null : progressStats.transactionId;
    return {
      ...state,
      sync:
        transactionType !== TransactionType.EnableSync ? { ...state.sync, progressStats, transactionId } : state.sync,
      enableSync:
        transactionType === TransactionType.EnableSync
          ? state.enableSync.map(st =>
              st.transactionId === progressStats.transactionId ? { ...st, progressStats } : st
            )
          : state.enableSync
    };
  }),

  on(syncActivities, state => ({
    ...state,
    sync: { ...state.sync, progressStats: { transactionStatus: TransactionStatus.InProgress } as ProgressStats }
  })),

  on(transactionFailure, state => ({
    ...state,
    sync: { ...state.sync, progressStats: null }
  })),
  on(skipTransaction, state => ({
    ...state,
    sync: { ...state.sync, progressStats: null }
  })),
  on(transactionReset, (state, { transactionId, transactionType }) => {
    return {
      ...state,
      sync:
        transactionType !== TransactionType.EnableSync
          ? { ...state.sync, progressStats: null, transactionId: null }
          : state.sync,
      enableSync:
        transactionType === TransactionType.EnableSync
          ? state.enableSync.filter(st => st.transactionId !== transactionId)
          : state.enableSync
    };
  }),

  on(triggerEnableSyncProgress, (state, { activityId, eventId, calendarId, vendorType, stats }) => ({
    ...state,
    enableSync: state.enableSync.some(es => es.eventId === eventId)
      ? state.enableSync
      : [
          ...state.enableSync,
          {
            activityId,
            loadStatus: LoadStatus.Pristine,
            transactionId: stats.transactionId,
            eventId,
            calendarId: calendarId!,
            vendorType,
            progressStats: stats
          }
        ]
  })),

  on(cancelTransaction, state => ({
    ...state,
    sync: {
      ...state.sync,
      transactionId: null,
      progressStats: null
    }
  })),
  on(reset, state => ({
    ...initialState,
    email: state.email
  })),
  on(setInitData, (state, { email, vendor }) => ({
    ...initialState,
    email,
    vendorType: vendor
  })),
  on(loadEnableSyncProgressStatus, state => ({
    ...state,
    enableSync: {
      ...state.enableSync,
      loadStatus: LoadStatus.Loading
    }
  })),
  on(loadProgressStatusSuccess, state => ({
    ...state,
    sync: {
      ...state.sync,
      loadStatus: LoadStatus.Loaded
    }
  })),
  on(loadEnableSyncProgressStatusSuccess, state => ({
    ...state,
    enableSync: {
      ...state.enableSync,
      loadStatus: LoadStatus.Loaded
    }
  })),
  on(loadProgressStatusFailure, state => ({
    ...state,
    sync: {
      ...state.sync,
      loadStatus: LoadStatus.Failed
    }
  })),
  on(loadEnableSyncProgressStatusFailure, state => ({
    ...state,
    enableSync: {
      ...state.enableSync,
      loadStatus: LoadStatus.Failed
    }
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return transactionReducer(state, action);
}
