import { fallbackCurrency } from '../constants';

export function resolveCurrency(preferenceValue: string | null, supportedCurrencies: string[]) {
  if (preferenceValue) {
    if (supportedCurrencies.includes(preferenceValue)) {
      // Use value from preference
      return preferenceValue;
    } else {
      // Preference value is not supported. Negative scenario.
      return null;
    }
  }

  return fallbackCurrency.code;
}
