import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyInlineAlertComponent } from './inline-alert/inline-alert.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyInlineAlertComponent],
  exports: [FireflyInlineAlertComponent]
})
export class FireflyAlertsModule {}
