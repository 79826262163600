<div class="card card-large h-100" [ngClass]="rootCssClass" data-automation-id="card">
  <f-spinner *ngIf="loadingType === LoadingType.Overlay" rootCssClass="spinner-overlay"></f-spinner>
  <div class="card-body" [ngClass]="[layout, bodyCssClass]">
    <div class="card-title">
      <div class="card-title-start" [ngClass]="titleStartCssClass">
        <span class="card-title-text" data-automation-id="card-title" [title]="title" [ngClass]="titleCssClass">{{
          title
        }}</span>
        <span class="card-title-extensions">
          <ng-content select="[fCardTitleExtensions]"></ng-content>
        </span>
      </div>
      <div class="card-title-actions card-title-end" [ngClass]="actionsCssClass">
        <ng-content select="[fCardActions]"></ng-content>
      </div>
    </div>
    <div class="card-text" data-automation-id="card-content" [ngClass]="contentCssClass">
      <ng-content *ngIf="!loadingType || loadingType === LoadingType.Overlay; else loader"></ng-content>
    </div>
  </div>
  <div class="card-footer">
    <ng-content select="[fCardFooter]"></ng-content>
  </div>
</div>

<ng-template #loader>
  <ng-container [ngTemplateOutlet]="loaderTemplate || defaultLoaderTemplate"></ng-container>
</ng-template>

<ng-template #defaultLoaderTemplate>
  <f-skeleton-large-card-with-text-one-column></f-skeleton-large-card-with-text-one-column>
</ng-template>
