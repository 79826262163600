import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeSize } from '@capital-access/common/utils';
import { ChangeBadgeComponent } from '../change-badge/change-badge.component';

@Component({
  selector: 'ca-simple-change-badge',
  templateUrl: './simple-change-badge.component.html',
  styleUrls: ['./simple-change-badge.component.scss', '../change-badge/change-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleChangeBadgeComponent extends ChangeBadgeComponent {
  @Input() isBold: boolean = false;
  @Input() override size: BadgeSize.Xs | BadgeSize.Xxs = BadgeSize.Xs;

  get iconSizeClass() {
    return this.size == BadgeSize.Xxs ? 'f-i-xs' : 'f-i-sm';
  }

  override get badgeClass() {
    const badgeType: string = `ca-simple-change-badge-${
      this.valueChange > 0 ? 'success' : this.valueChange < 0 ? 'danger' : 'neutral'
    }`;
    return [badgeType, this.getSizeClass()];
  }

  private getSizeClass() {
    return this.size == BadgeSize.Xxs ? 'ca-simple-change-badge-xxs' : 'ca-simple-change-badge-xs';
  }
}
