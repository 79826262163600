import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';
import { delay, enterTransition, iconAnimation, leaveTransition } from './animation';
import { ActionButtonClasses, ActionIconClasses } from './models/action-classes.enum';

@Component({
  selector: 'f-toggle-list-item',
  templateUrl: './toggle-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [iconAnimation]
})
export class FireflyToggleListItemComponent implements OnChanges {
  @Input() buttonClass: ActionButtonClasses = ActionButtonClasses.Add;
  @Input() iconClass: ActionIconClasses = ActionIconClasses.Add;
  @Input() isOptimistic?: boolean = false;
  @Output() addItem: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeItem: EventEmitter<void> = new EventEmitter<void>();

  private readonly hoverItemClass = 'toggle-list-item';

  @HostBinding('class') listGroupClasses = `list-group-item ${this.hoverItemClass} pe-1 p-2`;

  private readonly checkedItemClass = 'toggle-list-item-checked';
  private readonly transitionOnEnter = `background-color ${enterTransition}ms ease-out`;
  private readonly transitionOnLeave = `background-color ${leaveTransition}ms ease-out`;
  showCheckIcon = false;
  showActionIcon = true;
  get iconTitle$() {
    if (this.localizationService) {
      return this.localizationService.localize(
        this.iconClass === ActionIconClasses.Remove ? 'removeFromList' : 'addToList',
        {}
      );
    } else return of(this.iconClass === ActionIconClasses.Remove ? 'Remove From This List' : 'Add To This List');
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['iconClass']?.currentValue === ActionIconClasses.Remove &&
      !this.isOptimistic &&
      !changes['iconClass'].isFirstChange()
    ) {
      this.addCheckedStatus();
    }
  }

  onActionIconClick() {
    if (this.iconClass === ActionIconClasses.Add) {
      this.onAddItem();
    } else if (this.iconClass === ActionIconClasses.Remove) {
      this.onRemoveItem();
    }
  }

  private onRemoveItem() {
    this.removeItem.emit();
    if (this.isOptimistic) {
      this.buttonClass = ActionButtonClasses.Add;
      this.iconClass = ActionIconClasses.Add;
    }
  }

  private onAddItem() {
    this.addItem.emit();
    if (this.isOptimistic) {
      this.addCheckedStatus();
    }
  }

  private addCheckedStatus() {
    this.showCheckIcon = true;
    this.showActionIcon = false;
    this.renderer.removeClass(this.elementRef.nativeElement, this.hoverItemClass);
    this.renderer.setStyle(this.elementRef.nativeElement, 'transition', this.transitionOnEnter);
    this.renderer.addClass(this.elementRef.nativeElement, this.checkedItemClass);
    this.removeCheckedStatus();
  }

  private removeCheckedStatus() {
    setTimeout(() => {
      this.showCheckIcon = false;
      this.renderer.setStyle(this.elementRef.nativeElement, 'transition', this.transitionOnLeave);
      this.renderer.removeClass(this.elementRef.nativeElement, this.checkedItemClass);
      this.renderer.addClass(this.elementRef.nativeElement, this.hoverItemClass);
      this.changeDetectorRef.detectChanges();
    }, delay + enterTransition);
  }

  captureAnimationDoneEvent(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.buttonClass = ActionButtonClasses.Remove;
      this.iconClass = ActionIconClasses.Remove;
      this.showActionIcon = true;
      this.renderer.removeStyle(this.elementRef.nativeElement, 'transition');
      this.changeDetectorRef.detectChanges();
    }
  }
}
