import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../popovers';
import { FireflyBarSeriesModule } from '../bar-series/bar-series.module';
import { FireflyBarChartPopoverModule } from '../mobile-popovers/bar-chart-popover/chart-mobile-popover.module';
import { FireflyGroupedBarSeriesComponent } from './grouped-bar-series.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule, FireflyBarSeriesModule, FireflyBarChartPopoverModule],
  declarations: [FireflyGroupedBarSeriesComponent],
  exports: [FireflyGroupedBarSeriesComponent]
})
export class FireflyGroupedBarSeriesModule {}
