import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getRouter } from '@capital-access/common/router';
import { BROWSER_TITLE_FEATURE, BROWSER_TITLE_TAG_KEY, TitleSource } from './title.models';
import { State } from './title.reducers';

const featureSelector = createFeatureSelector<State>(BROWSER_TITLE_FEATURE);

export const getComponentTags = createSelector(getRouter, router => {
  if (router && router.state && router.state.pathFromRoot) {
    return router.state.pathFromRoot.map(t => t.componentTags[BROWSER_TITLE_TAG_KEY] as string).filter(x => !!x);
  }

  return null;
});

export const getTitleParts = createSelector(featureSelector, getComponentTags, (state, router) =>
  router ? router.map(x => state.titleParts[x] || '') : ['']
);

const getScopes = createSelector(featureSelector, state => state.scopes);

export const getTitleSource = createSelector(
  getTitleParts,
  getScopes,
  (titleParts, l10nScopes) =>
    ({
      titleParts,
      scopes: l10nScopes
    } as TitleSource)
);
