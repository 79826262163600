import { createAction, props } from '@ngrx/store';
import { HttpStatusCode } from '@capital-access/common/http';
import { ProgressStats, SyncEvent, SyncStatus, SyncVendor, TransactionType } from '../../../models';

export const syncActivities = createAction(
  '[Calendar Sync] Sync Activities',
  props<{ activities: SyncEvent[]; vendor: SyncVendor }>()
);

export const loadTransactionProgressStatus = createAction(
  '[Calendar Sync] Load Transaction Progress Status',
  props<{ transactionId?: string }>()
);

export const loadInitialProgressStatus = createAction(
  '[Calendar Sync] Load Initial Progress Status',
  props<{ vendor: SyncVendor }>()
);

export const loadProgressStatusFailure = createAction('[Calendar Sync] Load Progress Status Failure');
export const loadProgressStatusSuccess = createAction('[Calendar Sync] Load Progress Status Success');

export const setProgressStatus = createAction(
  '[Calendar Sync] Set Progress Status',
  props<{ progressStats: ProgressStats; transactionType?: TransactionType }>()
);

export const updateProgressStatus = createAction(
  '[Calendar Sync] Update Progress Status',
  props<{ transactionId: string; transactionType?: TransactionType }>()
);

export const transactionComplete = createAction(
  '[Calendar Sync] Sync Transaction Complete',
  props<{ progressStats: ProgressStats }>()
);

export const enableSyncTransactionComplete = createAction(
  '[Calendar Sync] Enable Sync Transaction Complete',
  props<{ progressStats: ProgressStats; transactionId: string }>()
);

export const transactionReset = createAction(
  '[Calendar Sync] Transaction Reset',
  props<{ transactionId: string; transactionType?: TransactionType }>()
);

export const transactionFailure = createAction(
  '[Calendar Sync] Transaction Failure',
  props<{ count: number; status?: HttpStatusCode }>()
);
export const transactionSuccess = createAction(
  '[Calendar Sync] Transaction Success',
  props<{ stats: ProgressStats }>()
);
export const transactionFinished = createAction('[Calendar Sync] Transaction Finished');

export const reset = createAction('[Calendar Sync] Reset All Transactions');

export const skipTransaction = createAction(
  '[Calendar Sync] Skip Transaction',
  props<{ count: number; syncStatus: SyncStatus }>()
);

export const setInitData = createAction(
  '[Calendar Sync] Set Email and Vendor',
  props<{ email: string; vendor: SyncVendor }>()
);

export const cancelProgress = createAction('[Calendar Sync] Cancel Progress');
export const cancelTransaction = createAction('[Calendar Sync] Cancel Transaction', props<{ transactionId: string }>());
export const cancelProgressSuccess = createAction(
  '[Calendar Sync] Cancel Progress Success',
  props<{ progressStats: ProgressStats }>()
);
export const cancelProgressFailure = createAction('[Calendar Sync] Cancel Progress Failure');

export const loadEnableSyncProgressStatus = createAction(
  '[Calendar Sync] Enable Sync Load Progress Status',
  props<{ transactionId?: string; eventId?: string }>()
);

export const loadEnableSyncProgressStatusFailure = createAction(
  '[Calendar Sync] Load Enable Sync Progress Status Failure'
);
export const loadEnableSyncProgressStatusSuccess = createAction(
  '[Calendar Sync] Load Enable Sync Progress Status Success'
);

export const triggerEnableSyncProgress = createAction(
  '[Calendar Sync] Trigger Enable Sync Progress',
  props<{ activityId: string; eventId: string; calendarId?: string; vendorType: SyncVendor; stats: ProgressStats }>()
);

export const disableSyncActivityFinish = createAction('[Calendar Sync] Disable Sync Activity Finish');

export const enableSyncTransactionFailure = createAction(
  '[Calendar Sync] Enable Sync Transaction Failure',
  props<{ count: number; status?: HttpStatusCode }>()
);
