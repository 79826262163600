import { Type } from '@angular/core';
import { addTag } from '@capital-access/common/utils';
import { BreadcrumbConfigProvider } from '../models/config-provider.model';
import { BreadcrumbStrategy } from '../models/strategy.model';
import { breadcrumbStrategies } from '../services/strategies';

export const BREADCRUMB_STRATEGY_PROVIDER_KEY = 'breadcrumb:strategy:provider';

/**
 * Create Breadcrumb Strategy from specified component.
 * This strategy will replace default for this page.
 * Strategy should return full breadcrumbs path
 */
export const createBreadcrumbStrategy = (config: { component: Type<unknown> }, strategy: BreadcrumbStrategy) => {
  const provider = new BreadcrumbConfigProvider({ strategy });
  addTag(config.component, {
    key: BREADCRUMB_STRATEGY_PROVIDER_KEY,
    descriptor: {
      value: provider.token
    }
  });
  return provider;
};

/**
 * Create strategy which will not provide any items and so Breadcrumbs will not be displayed
 */
export const createHiddenBreadcrumbs = (config: { component: Type<unknown> }) =>
  createBreadcrumbStrategy(config, breadcrumbStrategies.empty());
