<button
  [caLocalizationScope]="L10N"
  *ngIf="isAiChatEnabled$ | async"
  class="ai-chat-button position-fixed btn btn-primary btn-icon btn-rounded"
  [title]="'title' | caLocalize"
  (click)="openDrawer()"
  data-automation-id="ai-chat-button"
>
  <i class="f-i f-i-stars"></i>
</button>
