import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { manageCustomField } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CUSTOM_FIELD_MANAGE_MODULE_ID, LOAD_CUSTOM_FIELD_MANAGE_MODULE } from '../manage-loader-module-id.model';

@Injectable()
export class ManageEffects {
  manageCustomField$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(manageCustomField),
        switchMap(({ entityType, addBackButton }) =>
          this.commonLazyLoadingService
            .loadModule(CUSTOM_FIELD_MANAGE_MODULE_ID, LOAD_CUSTOM_FIELD_MANAGE_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showCustomFieldManageDrawer(entityType, addBackButton || false)))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
