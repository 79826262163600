import { NgModule } from '@angular/core';
import { CommonBrowserTitleModule } from '@capital-access/common/browser';
import { CommonRouterModule } from '@capital-access/common/router';
import { FORBIDDEN_PAGE_ROUTE } from '@capital-access/common/utils';
import { CommonErrorPageModule } from '../error-page/error-page.module';
import { forbiddenPageRoute } from '../utils/error-page.models';
import { ForbiddenPageComponent } from './forbidden-page.component';
import { ForbiddenPageTitle } from './forbidden-page.title';
@NgModule({
  imports: [
    CommonErrorPageModule,
    CommonBrowserTitleModule.forFeature(ForbiddenPageTitle),
    CommonRouterModule.forFeature({
      anonymous: [{ path: forbiddenPageRoute, component: ForbiddenPageComponent }]
    })
  ],
  providers: [
    ForbiddenPageTitle,
    {
      provide: FORBIDDEN_PAGE_ROUTE,
      useValue: forbiddenPageRoute
    }
  ],
  declarations: [ForbiddenPageComponent],
  exports: [ForbiddenPageComponent]
})
export class ForbiddenPageModule {}
