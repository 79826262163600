import { routerNavigatedAction } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';
import { Error, ErrorState, SearchCategory, SearchEntity, SearchResults, SearchStatus } from './models';
import { Panel } from './models/panel.enum';
import * as SearchActions from './search.actions';
import { isEqual, mapSelectionToResults, unselectAllResults } from './utils';

export interface State {
  status: SearchStatus;
  criteria: string;
  results: SearchResults;
  filter: SearchCategory | null;
  buckets: number[];
  selectedEntities: SearchEntity[];
  error: ErrorState;
  panel: Panel;
}

export const initialState: State = {
  // set initial required properties
  status: SearchStatus.None,
  criteria: '',
  results: {
    criteria: '',
    contacts: [],
    institutions: [],
    funds: [],
    events: []
  },
  filter: null,
  buckets: [],
  selectedEntities: [],
  error: {
    type: Error.None,
    failedCategories: []
  },
  panel: Panel.None
};

const searchReducer = createReducer(
  initialState,
  on(routerNavigatedAction, SearchActions.reset, () => ({
    ...initialState
  })),

  on(SearchActions.searchStarted, state => ({
    ...state,
    status: SearchStatus.Started,
    error: {
      type: Error.None,
      failedCategories: []
    }
  })),

  on(SearchActions.close, state => ({
    ...initialState,
    criteria: state.criteria
  })),

  on(SearchActions.criteriaChanged, (state, { criteria }) => ({ ...state, criteria: criteria })),

  on(SearchActions.filterChanged, (state, { filter }) => ({
    ...state,
    filter: filter
  })),

  on(SearchActions.loadSearchResultsFailure, state => ({
    ...state,
    results: {
      criteria: state.criteria,
      contacts: [],
      institutions: [],
      funds: [],
      events: []
    },
    filter: null,
    status: SearchStatus.Failed,
    error: {
      type: Error.Generic,
      failedCategories: []
    },
    panel: state.selectedEntities?.length ? Panel.Actions : Panel.None
  })),

  on(SearchActions.loadSearchResultsSuccess, (state, { results }) => ({
    ...state,
    status: SearchStatus.Success,
    results: mapSelectionToResults(results, state.selectedEntities),
    error: {
      type: results.partialErrors.length > 0 ? Error.Partial : Error.None,
      failedCategories: results.partialErrors
    }
  })),

  on(SearchActions.viewBucketsChanged, (state, { buckets }) => ({
    ...state,
    buckets
  })),

  on(SearchActions.selectEntity, (state, { entity }) => {
    const selectedEntity = { ...entity, isSelected: true };
    const updatedEntities = (state.results[entity.category] as Array<SearchEntity>).map((i: SearchEntity) =>
      isEqual(i, entity) ? selectedEntity : i
    );
    return {
      ...state,
      results: {
        ...state.results,
        [entity.category]: updatedEntities
      },
      // Saving in order - last selected is at the beginning
      selectedEntities: [selectedEntity, ...state.selectedEntities],
      panel: Panel.Actions
    };
  }),

  on(SearchActions.unselectEntity, (state, { entity }) => {
    const unselectedEntity = { ...entity, isSelected: false };
    const updatedEntities = (state.results[entity.category] as Array<SearchEntity>).map((i: SearchEntity) =>
      isEqual(i, entity) ? unselectedEntity : i
    );
    const selectedEntities = [...state.selectedEntities.filter(i => !isEqual(i, entity))];

    return {
      ...state,
      results: {
        ...state.results,
        [entity.category]: updatedEntities
      },
      // Saving order of selection
      selectedEntities: selectedEntities,
      panel: selectedEntities.length > 0 ? Panel.Actions : state.filter ? Panel.Filters : Panel.None
    };
  }),

  on(SearchActions.unselectAll, state => ({
    ...state,
    results: unselectAllResults(state.results),
    selectedEntities: [],
    panel: state.filter ? Panel.Filters : Panel.None
  })),

  on(SearchActions.toggleFilterPanel, state => {
    let panel: Panel;
    if (state.panel === Panel.Filters) {
      panel = state.selectedEntities.length > 0 ? Panel.Actions : Panel.None;
    } else panel = Panel.Filters;

    return {
      ...state,
      panel
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return searchReducer(state, action);
}
