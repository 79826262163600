import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { NumberPipe } from './number.pipe';

@Pipe({
  name: 'caMM',
  pure: false
})
export class MillionsPipe extends BaseAsyncPipe<string, number | null | undefined, [number?]> implements PipeTransform {
  constructor(changeDetectorRef: ChangeDetectorRef, private numberPipe: NumberPipe) {
    super(changeDetectorRef);
  }

  getTransformer(value: number | null | undefined, fraction: number = 0) {
    if (value === null || value === undefined || !isFinite(value)) {
      return of('');
    }

    return this.numberPipe.getTransformer(value / 1000000, fraction);
  }
}
