import { Injectable } from '@angular/core';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class FireflyNavConfig extends NgbNavConfig {
  constructor() {
    super();
    this.animation = true;
  }
}
