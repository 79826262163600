import { Injectable } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class FireflyGridResizingService {
  private stylesMutationObserver?: MutationObserver;
  resizeSubj = new Subject<void>();

  onGridColumnsChanges(grid: GridComponent): Observable<void> {
    this.stylesMutationObserver?.disconnect();
    this.stylesMutationObserver = new MutationObserver(mutations => {
      const hasRelatedChanges = mutations.some(mutation => {
        const childListChanges = mutation.type === 'childList';
        const styleChanges = mutation.type === 'attributes' && mutation.attributeName === 'style';
        return childListChanges || styleChanges;
      });

      if (hasRelatedChanges) this.resizeSubj.next();
    });

    const colGroup = grid.wrapper.nativeElement.querySelector('.k-grid-header colgroup');
    this.stylesMutationObserver.observe(colGroup, { attributes: true, childList: true, subtree: true });

    return this.resizeSubj.asObservable();
  }
}
