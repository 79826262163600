import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { provideAuthGuard } from '@capital-access/common/router';
import { CommonStandingDataModule } from '@capital-access/common/standing-data';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FireflyModalModule } from '@capital-access/firefly/components';
import { CurrencyEffects } from './+state/effects';
import { currencyReducer } from './+state/reducer';
import { COMMON_CURRENCY_FEATURE_KEY } from './+state/state';
import { COMMON_CURRENCY_LOCALIZATION_SCOPE } from './common-currency.const';
import { CommonCurrencyGuard } from './currency-load.guard';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule.forFeature(COMMON_CURRENCY_LOCALIZATION_SCOPE),
    FireflyModalModule,
    StoreModule.forFeature(COMMON_CURRENCY_FEATURE_KEY, currencyReducer),
    EffectsModule.forFeature([CurrencyEffects]),
    CommonStandingDataModule
  ],
  providers: [provideAuthGuard(CommonCurrencyGuard)]
})
export class CommonCurrencyModule {}
