import { Directive, Input, Optional } from '@angular/core';
import { CellOptions } from '@progress/kendo-angular-excel-export';
import { ColumnComponent } from '@progress/kendo-angular-grid';
import isArray from 'lodash/isArray';
import isSet from 'lodash/isSet';
import { ExcelAlign, ExcelColumn, isString, KendoExcelColumn } from '@capital-access/common/utils';

@Directive({
  selector: '[caExportableColumn]',
  standalone: true
})
export class ExportableColumnDirective {
  defaultAlignment = ExcelAlign.Left;

  @Input() exportTitle?: string;
  @Input() exportField?: string;
  @Input() exportFormat?: string;
  @Input() exportSubFormat?: string;
  @Input() exportOrderIndex?: number;
  @Input() exportAlignment?: ExcelAlign;
  @Input() exportHidden?: boolean;
  @Input() exportWidth?: number;
  @Input() bindingColumn?: ColumnComponent;

  constructor(@Optional() private column?: ColumnComponent) {}

  get hidden(): boolean {
    /*
     * In case the column is provided by the DI, try alternative override if it was overtly set to true/false.
     * Otherwise fallback to exportHidden as the default.
     */
    return !!(this.column ? this.exportHidden ?? this.column.hidden : this.exportHidden);
  }

  get title(): string {
    return this.exportTitle || (this.column?.title ?? '');
  }

  get field(): string {
    return this.exportField || (this.column?.field ?? '');
  }

  get orderIndex(): number {
    return this.exportOrderIndex ?? this.column?.orderIndex ?? Number.MAX_VALUE;
  }

  get format(): string | undefined {
    const itemFormat = this.exportFormat || this.column?.format;
    const subFormat = this.exportSubFormat || '';
    return subFormat.length > 0 ? `${itemFormat} ${subFormat}` : itemFormat;
  }

  get align(): ExcelAlign | undefined {
    return this.exportAlignment || this.getColumnAlignment();
  }

  get kendoAlign() {
    switch (this.align) {
      case ExcelAlign.Left:
        return 'left';
      case ExcelAlign.Right:
        return 'right';
      case ExcelAlign.Center:
        return 'center';
      default:
        return 'left';
    }
  }
  get width() {
    return this.exportWidth || this.column?.width || 0;
  }

  updateBindings() {
    if (!this.bindingColumn) return;
    this.exportHidden = this.bindingColumn.hidden;
    this.exportOrderIndex = this.bindingColumn.orderIndex;
  }

  toExcelColumn(): ExcelColumn {
    return {
      title: this.title,
      field: this.field,
      align: this.align,
      format: this.format
    };
  }

  toKendoExcelColumn(): KendoExcelColumn {
    return {
      title: this.title,
      field: this.field,
      width: this.width,
      cellOptions: { format: this.exportFormat, textAlign: this.kendoAlign } as CellOptions
    };
  }

  private getColumnAlignment(): ExcelAlign {
    const cssClass = this.column?.cssClass;
    if (!cssClass) {
      return this.defaultAlignment;
    }
    if (isArray(cssClass)) {
      return this.mapAlignFromClass(cls => cssClass.includes(cls));
    }
    if (isSet(cssClass)) {
      return this.mapAlignFromClass(cls => cssClass.has(cls));
    }
    return this.mapAlignFromClass(isString(cssClass) ? cls => cssClass.includes(cls) : cls => cssClass[cls]);
  }

  private mapAlignFromClass(testFn: (cssClass: string) => boolean): ExcelAlign {
    if (testFn('text-right')) {
      return ExcelAlign.Right;
    }
    if (testFn('text-center')) {
      return ExcelAlign.Center;
    }
    return this.defaultAlignment;
  }
}
