import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonFeatureToggleModule } from '@capital-access/common/feature-toggle';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonRouterModule } from '@capital-access/common/router';
import { EventsCommonModule } from '@capital-access/events/common';
import {
  FireflyChipModule,
  FireflyDrawerModule,
  FireflyDropdownModule,
  FireflyFormattingModule,
  FireflyListModule,
  FireflyPopoverModule,
  FireflySpinnerModule,
  FireflyTooltipModule
} from '@capital-access/firefly/components';
import { SearchEffects } from './+state/search.effects';
import { reducer } from './+state/search.reducer';
import { ActionPanelComponent } from './components/action-panel/action-panel.component';
import { FilterPanelComponent } from './components/filter-panel/filter-panel.component';
import { OneSearchComponent } from './components/one-search/one-search.component';
import { OneSearchInputComponent } from './components/one-search-input/one-search-input.component';
import { OneSearchResultsComponent } from './components/one-search-results/one-search-results.component';
import { PanelContainerComponent } from './components/panel-container/panel-container.component';
import { SearchCategoryListComponent } from './components/search-category-list/search-category-list.component';
import { SearchErrorComponent } from './components/search-error/search-error.component';
import { SearchPopupHeaderComponent } from './components/search-popup-header/search-popup-header.component';
import {
  SearchContactsListComponent,
  SearchEventsListComponent,
  SearchFundsListComponent,
  SearchInstitutionsListComponent
} from './components/search-result-lists';
import { SEARCH_FEATURE_KEY, SEARCH_LOCALIZATION_SCOPE } from './constants';
import { EmptyDrawerContentComponent } from './services/empty-drawer-content';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule.forFeature(SEARCH_LOCALIZATION_SCOPE),
    CommonFeatureToggleModule,
    StoreModule.forFeature(SEARCH_FEATURE_KEY, reducer),
    EffectsModule.forFeature([SearchEffects]),
    CommonRouterModule,
    EventsCommonModule,
    FireflyTooltipModule,
    FireflyPopoverModule,
    FireflyChipModule,
    FireflyFormattingModule,
    FireflyDrawerModule,
    FireflySpinnerModule,
    FireflyListModule,
    FireflyDropdownModule
  ],
  declarations: [
    OneSearchComponent,
    OneSearchResultsComponent,
    OneSearchInputComponent,
    FilterPanelComponent,
    SearchCategoryListComponent,
    SearchErrorComponent,
    ActionPanelComponent,
    PanelContainerComponent,
    SearchContactsListComponent,
    SearchInstitutionsListComponent,
    SearchFundsListComponent,
    SearchEventsListComponent,
    EmptyDrawerContentComponent,
    SearchPopupHeaderComponent
  ],
  exports: [OneSearchComponent]
})
export class SearchModule {}
