<f-dropdown
  [hideCaret]="true"
  [text]="initials | uppercase"
  [btnStyle]="'btn-with-initials btn-secondary'"
  tooltip="{{ user?.firstName || '-' }} {{ user?.lastName || '-' }}"
>
  <f-list>
    <f-list-item *ngFor="let item of items" (click)="itemClick.emit(item)" fDropdownItem>{{ item?.text }}</f-list-item>
  </f-list>
</f-dropdown>
