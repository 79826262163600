<span
  *ngIf="navItem"
  class="link link-main ca-nav-item ca-nav-item-action"
  (click)="onClick($event)"
  (keyup.enter)="onClick($event)"
  ngbTooltip="{{ navItem.localizationKey | caLocalize }}"
  placement="right"
  tooltipClass="text-nowrap"
  [disableTooltip]="!mini"
  tabindex="0"
  [caLocalizationScope]="localizationScope"
>
  <span class="ca-nav-item-container" [attr.data-automation-id]="navItem.localizationKey">
    <ca-nav-item-icon [navItem]="navItem"></ca-nav-item-icon>
    <span *ngIf="!mini" class="flex-shrink-0 ps-2 user-select-none" @fadeIn>
      {{ navItem.localizationKey | caLocalize }}
    </span>
  </span>
</span>
