import { ChangeDetectionStrategy, Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInfo, FileSelectComponent, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { FireflyBaseUploaderComponent } from '../base-uploader/base-uploader.component';

@Component({
  selector: 'f-file-select',
  templateUrl: './file-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => FireflyFileSelectComponent)
    }
  ]
})
export class FireflyFileSelectComponent extends FireflyBaseUploaderComponent {
  @ViewChild(FileSelectComponent) fileselect!: FileSelectComponent;

  private totalSize = 0;

  uploaderComponent(): FileSelectComponent {
    return this.fileselect;
  }

  select(event: SelectEvent): void {
    super.select(event);

    for (let i = event.files.length - 1; i >= 0; i--) {
      const file = event.files[i];
      if (this.totalFileSizeTooLarge(file)) {
        this.notifyTotalSizeTooLarge();
        event.files.splice(i, 1);
      }
    }

    requestAnimationFrame(() => this.handleSingleFileUpload());
  }

  remove(event: RemoveEvent): void {
    super.remove(event);
    event.files.forEach(file => {
      if (file.size) this.totalSize -= file.size;
    });
  }

  private totalFileSizeTooLarge(file: FileInfo): boolean {
    let accumulatedFileSize = 0;
    if (file.size && this.maxTotalSize && this.validationChecks(file)) {
      this.totalSize += file.size;
      accumulatedFileSize += this.totalSize;
    }
    if (file.size && this.totalSize > this.maxTotalSize) this.totalSize -= file.size;
    return accumulatedFileSize > this.maxTotalSize;
  }
}
