import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectService } from './redirect.service';

@Directive({
  selector: '[caScheduleRedirect]'
})
export class ScheduleRedirectDirective {
  @HostListener('window:beforeunload') scheduleRedirectAfterRefresh() {
    this.redirectService.scheduleRedirectAfterRefresh();
  }

  constructor(private router: Router, private redirectService: RedirectService) {
    if (this.redirectService.clearScheduledRedirect(true)) {
      this.router.navigate(['/']);
    }
  }
}
