import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SerializedSettingsEntity } from '../settings/models/settings-entity';
import { UserSettingsRepository } from '../settings/user-settings.repository';

@Injectable()
export class DefaultUserSettingsRepository implements UserSettingsRepository {
  private readonly apiBase = '/api/identity/v1';

  constructor(private http: HttpClient) {}

  saveSettings(settings: SerializedSettingsEntity[]): Observable<SerializedSettingsEntity[]> {
    // In case of an empty array, forkJoin will return EMPTY observable which is undesirable
    if (settings.length === 0) return of([]);

    const requests = settings.map(setting => {
      const url = `${this.apiBase}/users/current/preferences/${setting.id}`;
      if (setting.value != null) {
        return this.http.put(url, { value: setting.value });
      }
      return this.http.delete(url);
    });

    return forkJoin(requests).pipe(map(() => settings));
  }
}
