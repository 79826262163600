import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { logout, openContactSupportDrawer } from '@capital-access/common/actions';
import { getUserDataLoaded, getUserFullName } from '@capital-access/common/auth';
import { LocalizationService } from '@capital-access/common/localization';
import { UserProfileDropdownItem } from '@capital-access/common/navigation';
import { getActivatedRoutesFromRoot } from '@capital-access/common/router';
import { CONTACT_SUPPORT_URL, LOGOUT_URL } from '../navigation.constants';

@Component({
  selector: 'ca-navigation-anonymous',
  templateUrl: './navigation-anonymous.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationAnonymousComponent {
  userLoaded$ = this.store.select(getUserDataLoaded);
  userFullName$ = this.store.select(getUserFullName);
  userSettingsOptions$: Observable<UserProfileDropdownItem[]>;
  showTopNav$ = this.store.select(getActivatedRoutesFromRoot).pipe(
    map(routes => {
      // iterate from child to parent routes to check if 'navigation' flag enabled
      for (let i = routes.length - 1; i >= 0; i--) {
        const showNav = routes[i]?.data?.navigation;

        if (!isUndefined(showNav) && !isNull(showNav)) {
          return showNav;
        }
      }

      return true;
    })
  );

  constructor(private store: Store, localizationService: LocalizationService) {
    this.userSettingsOptions$ = localizationService.getLocalization('navigation').pipe(
      map(localization => {
        return [
          { text: localization['contactSupport'], route: CONTACT_SUPPORT_URL },
          { text: localization['logOut'], route: LOGOUT_URL }
        ];
      })
    );
  }

  onClick($event: unknown): void {
    const item = $event as UserProfileDropdownItem;

    if (item.route === CONTACT_SUPPORT_URL) {
      this.store.dispatch(openContactSupportDrawer());
    } else if (item.route === LOGOUT_URL) {
      this.store.dispatch(logout());
    }
  }

  logout() {
    this.store.dispatch(logout());
  }
}
