export enum EventStatus {
  Active = 'Active',
  Postponed = 'Postponed',
  Cancelled = 'Cancelled',
  Completed = 'Completed'
}

export enum EventType {
  AnalystDay = 'AnalystDay',
  Conference = 'Conference',
  DealRoadshow = 'DealRoadshow',
  EarningsCall = 'EarningsCall',
  FieldTrip = 'FieldTrip',
  InvestorDay = 'InvestorDay',
  NonDealRoadshow = 'NonDealRoadshow',
  PostEarningsCall = 'PostEarningsCall'
}

export const EVENT_TYPES = [
  EventType.AnalystDay,
  EventType.Conference,
  EventType.DealRoadshow,
  EventType.EarningsCall,
  EventType.FieldTrip,
  EventType.NonDealRoadshow,
  EventType.InvestorDay,
  EventType.PostEarningsCall
];
