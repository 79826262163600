import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { createItineraryReport, createItineraryReportFinished } from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import {
  getUserSettingsCulture,
  getUserSettingsCurrency,
  getUserSettingsTimezone
} from '@capital-access/common/globalization';
import { getSelectedSecurity, getSelectedSecuritySurveillancePermission } from '@capital-access/common/security';
import { generateReport } from '../../core/+state/report.actions';
import { OwnershipSource } from '../../core/models/ownership-source.enum';
import { FileNameService } from '../../core/services/file-name.service';
import { CreateItineraryReportRequest } from '../models/itinerary-report.models';
import { LocalizationKeys } from '../models/localization.model';

@Injectable()
export class ItineraryReportEffects {
  createItineraryReportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createItineraryReport),
      withLatestFrom(
        this.store.select(getSelectedSecurity),
        this.store.select(getUserSettingsTimezone),
        this.store.select(getSelectedSecuritySurveillancePermission),
        this.store.select(getFeatureToggle('surveillance-across-application-cora-2613')),
        this.store.select(getUserSettingsCurrency),
        this.store.select(getFeatureToggle('skyl-2231-culture-specific-formatting')),
        this.store.select(getUserSettingsCulture)
      ),
      mergeMap(
        ([request, security, timeZone, isSrvPermissioned, isSrvFtEnabled, currency, cultureFtEnabled, culture]) => {
          const isSrv = security?.surveillance ?? false;
          return this.fileNameService
            .getDefaultFileName(isSrvPermissioned && isSrvFtEnabled, isSrv, request.eventName, timeZone)
            .pipe(
              map(fileName => {
                const createItineraryReportRequest: CreateItineraryReportRequest = {
                  eventId: request.eventId,
                  timeZone,
                  securityId: security?.id,
                  fileName,
                  currency,
                  culture: cultureFtEnabled ? culture : 'en-US',
                  dataSource: isSrv ? OwnershipSource.Surveillance : OwnershipSource.Public
                };

                return generateReport({
                  request: createItineraryReportRequest,
                  url: 'itinerary-reports/process/launch',
                  successMessage: LocalizationKeys.ItineraryReportCreated,
                  failureMessage: LocalizationKeys.ItineraryReportFailed,
                  finishAction: createItineraryReportFinished({ eventId: request.eventId })
                });
              })
            );
        }
      )
    )
  );

  constructor(private actions$: Actions, private store: Store, private fileNameService: FileNameService) {}
}
