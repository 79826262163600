import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { provideNavConfig } from '@capital-access/common/navigation';
import { FireflyDrawerModule } from '@capital-access/firefly/components';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { NavigationComponent } from './navigation.component';
import { NavigationAnonymousModule } from './navigation-anonymous/navigation-anonymous.module';
import { SharedNavConfigProvider } from './services/ca-shared-nav-config.provider';
import { SharedNavigationItemsService } from './services/ca-shared-navigation-items.service';
import { SideNavService } from './side-nav.service';
import { SideNavModule } from './side-nav/side-nav.module';
import { TopNavSearchDirective } from './top-nav/directives/top-nav-search/top-nav-search.directive';
import { TopNavModule } from './top-nav/top-nav.module';

@NgModule({
  imports: [
    CommonModule,
    CommonNavigationModule,
    BreadcrumbsModule,
    TopNavModule,
    SideNavModule,
    NavigationAnonymousModule,
    CommonLocalizationModule.forFeature('navigation'),
    FireflyDrawerModule
  ],
  declarations: [NavigationComponent],
  providers: [SideNavService, SharedNavigationItemsService, provideNavConfig(SharedNavConfigProvider)],
  exports: [NavigationComponent, TopNavSearchDirective, NavigationAnonymousModule]
})
export class NavigationModule {}
