import { Injectable } from '@angular/core';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class SessionIdProvider {
  private readonly sessionIdKey = 'ca_session_id';
  private readonly _sessionId = this.generateSessionId();

  get sessionId() {
    return this._sessionId;
  }

  private generateSessionId(): string {
    const sessionId = sessionStorage[this.sessionIdKey] || v4();
    sessionStorage[this.sessionIdKey] = sessionId;

    return sessionId;
  }
}
