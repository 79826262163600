import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ActivityFormLazyModule } from '@capital-access/activity/form/lazy';
import { AiPanelLazyModule } from '@capital-access/ai-panel/lazy';
import { AskResearchRequestFormLoaderModule } from '@capital-access/ask-research/request-form/lazy';
import { CommonAuthModule } from '@capital-access/common/auth';
import { BreadcrumbUrl, CommonBreadcrumbsModule } from '@capital-access/common/breadcrumbs';
import { CommonBrowserTitleModule } from '@capital-access/common/browser';
import { CommonGuardsModule } from '@capital-access/common/components';
import {
  ForbiddenPageModule,
  InternalServerErrorPageModule,
  SessionExpirePageModule
} from '@capital-access/common/components';
import { CommonCurrencyModule } from '@capital-access/common/currency';
import { CommonFeatureToggleModule, DefaultFeatureToggleProviderModule } from '@capital-access/common/feature-toggle';
import { CommonFormattingModule } from '@capital-access/common/formatting';
import { CommonGlobalizationModule } from '@capital-access/common/globalization';
import { CommonHttpModule } from '@capital-access/common/http';
import { CommonLazyLoadingModule } from '@capital-access/common/lazy-loading';
import { CommonLocalizationModule, LocalizedString } from '@capital-access/common/localization';
import { CommonLoggingModule } from '@capital-access/common/logging';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonPwaModule } from '@capital-access/common/pwa';
import { CommonRouterModule, provideAuthGuard } from '@capital-access/common/router';
import { CommonSecurityModule, CommonSelectedSecurityModule } from '@capital-access/common/security';
import { CommonUserModule, DefaultUserProviderModule } from '@capital-access/common/user';
import { ContactFormLoaderModule } from '@capital-access/crm/contact-form/lazy';
import { InstitutionFormLoaderModule } from '@capital-access/crm/institution-form/lazy';
import { CustomFieldsClientModule } from '@capital-access/custom-fields/client';
import { EmailsClientLazyModule } from '@capital-access/emails/client/lazy';
import { CalendarSyncSettingsLazyModule } from '@capital-access/events/calendar-sync/settings/lazy';
import { CiqEventsLazyModule } from '@capital-access/events/ciq-events/lazy';
import { EventsClientLazyModule } from '@capital-access/events/client/lazy';
import {
  FireflyAccordionModule,
  FireflyFormsModule,
  FireflyKendoIconsModule,
  FireflyPopoverModule,
  FireflySpinnerModule,
  FireflyTooltipModule
} from '@capital-access/firefly/components';
import { FireflyGlobalizationModule } from '@capital-access/firefly-globalization';
import { FireflyStylesLoaderModule } from '@capital-access/firefly-styles';
import { InsightClientLazyModule } from '@capital-access/insight/client/lazy';
import { KendoGlobalizationLazyModule } from '@capital-access/kendo-globalization/lazy';
import { LandingPageLazyModule } from '@capital-access/landing-page/lazy';
import { LicenseAgreementModule } from '@capital-access/license-agreement';
import { ListsActionsLazyModule } from '@capital-access/lists/actions/lazy';
import { ListsClientLazyModule } from '@capital-access/lists/client/lazy';
import { openListManagement } from '@capital-access/lists/common';
import { ManagementHubClientLazyModule } from '@capital-access/management-hub/client/lazy';
import { MeasureClientLazyModule } from '@capital-access/measure/client/lazy';
import { NavigationModule } from '@capital-access/navigation';
import { NotificationsClientModule } from '@capital-access/notifications/client';
import { OwnershipClientLazyModule } from '@capital-access/ownership/client/lazy';
import { PendoModule } from '@capital-access/pendo';
import { ProfilesLazyModule } from '@capital-access/profiles/lazy';
import { CreateReportModule } from '@capital-access/reporting/create';
import { SearchModule } from '@capital-access/search/nav';
import { TableSettingsModule } from '@capital-access/table-settings';
import { TargetingClientLazyModule } from '@capital-access/targeting/client/lazy';
import { ThemingModule } from '@capital-access/theming';
import { CapitalAccessEntitlementsGuard } from '@capital-access/user/common';
import { UserSettingsLazyModule } from '@capital-access/user/settings/lazy';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routes';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonAuthModule,
    CommonHttpModule,
    CommonLoggingModule.forRoot({ sendLogs: environment.production, appName: 'capital-access-ui' }),
    BrowserModule,
    CommonRouterModule.forRoot({ authorized: routes }),
    NavigationModule,
    CommonUserModule.forRoot(),
    DefaultUserProviderModule,
    DefaultFeatureToggleProviderModule,
    CommonSecurityModule.forRoot(),
    CommonSelectedSecurityModule,
    CommonBrowserTitleModule.forRoot({
      separator: ' | ',
      fallbackValue: 'Capital Access'
    }),
    CommonNavigationModule.forFeature({
      bottom: [
        {
          localizationKey: 'listManagement',
          icon: 'f-i-management',
          action: openListManagement()
        },
        {
          // No action needed because of using pendo functionality for implementing 'Help' logic
          localizationKey: 'help',
          icon: 'f-i-help'
        }
      ]
    }),
    CommonLazyLoadingModule,
    CommonLocalizationModule.forRoot({ enableProdMode: environment.production }),
    CommonGlobalizationModule,
    CommonFeatureToggleModule.forRoot(),
    CommonFormattingModule,
    CommonCurrencyModule,
    CommonGuardsModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true
        }
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ProfilesLazyModule.forRoot(),
    LandingPageLazyModule,
    OwnershipClientLazyModule,
    EventsClientLazyModule,
    UserSettingsLazyModule,
    ManagementHubClientLazyModule,
    CustomFieldsClientModule,
    ContactFormLoaderModule,
    InstitutionFormLoaderModule,
    AskResearchRequestFormLoaderModule,
    TargetingClientLazyModule,
    ListsClientLazyModule,
    ListsActionsLazyModule,
    SearchModule,
    MeasureClientLazyModule,
    InternalServerErrorPageModule,
    ForbiddenPageModule,
    SessionExpirePageModule,
    ActivityFormLazyModule,
    FireflyAccordionModule.forRoot(),
    FireflyTooltipModule.forRoot(),
    FireflyPopoverModule.forRoot(),
    FireflyGlobalizationModule.forRoot(),
    FireflyFormsModule.forRoot(),
    FireflySpinnerModule,
    FireflyStylesLoaderModule,
    LicenseAgreementModule.forRoot({ preferenceSection: 'license-agreement' }),
    PendoModule,
    CommonBreadcrumbsModule.forRoot({
      homePageItem: {
        label: new LocalizedString('homeLabel'),
        url: new BreadcrumbUrl()
      }
    }),
    KendoGlobalizationLazyModule,
    CalendarSyncSettingsLazyModule,
    CreateReportModule,
    ThemingModule,
    CommonPwaModule.forRoot(environment.production),
    NotificationsClientModule.forRoot(),
    TableSettingsModule.forRoot({ prefix: 'capital-access' }),
    AiPanelLazyModule,
    InsightClientLazyModule,
    FireflyKendoIconsModule.forRoot(),
    CiqEventsLazyModule,
    EmailsClientLazyModule
  ],
  exports: [AppComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    },
    provideAuthGuard(CapitalAccessEntitlementsGuard)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
