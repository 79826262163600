import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActivityContainerDirective } from './directives/activity-container.directive';
import { EntityContainerDirective } from './directives/entity-container.directive';
import { LogoContainerDirective } from './directives/logo-container.directive';
import { SearchContainerDirective } from './directives/search-container.directive';
import { SelectorContainerDirective } from './directives/selector-container.directive';
import { UserMenuContainerDirective } from './directives/user-menu-container.directive';
import { TopNavComponent } from './top-nav.component';

@NgModule({
  declarations: [
    TopNavComponent,
    LogoContainerDirective,
    SelectorContainerDirective,
    SearchContainerDirective,
    EntityContainerDirective,
    ActivityContainerDirective,
    UserMenuContainerDirective
  ],
  imports: [CommonModule],
  exports: [
    TopNavComponent,
    LogoContainerDirective,
    SelectorContainerDirective,
    SearchContainerDirective,
    EntityContainerDirective,
    ActivityContainerDirective,
    UserMenuContainerDirective
  ]
})
export class FireflyTopNavModule {}
