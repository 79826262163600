import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { TransactionStatus } from '../../../models';
import { countEnableProgressBarValue, countProgressBarValue } from '../../../utils';
import { State } from './transaction.reducer';

export const getTransactionState = createFeatureSelector<State>('calendar-sync-transaction');
export const getEnableTransactionState = createSelector(getTransactionState, state => state.enableSync);
export const getShowProgressBar = createSelector(
  getTransactionState,
  state =>
    state.sync.loadStatus !== LoadStatus.Failed &&
    state.sync.progressStats?.transactionStatus === TransactionStatus.InProgress
);

export const getTransactionId = createSelector(getTransactionState, state => state.sync.transactionId);
export const getProgressStats = createSelector(getTransactionState, state => state.sync.progressStats);
export const getProgressLoadStatus = createSelector(getTransactionState, state => state.sync.loadStatus);
export const getEmail = createSelector(getTransactionState, state => state.email);
export const getVendorType = createSelector(getTransactionState, state => state.vendorType);
export const getProgressBarValue = createSelector(
  getTransactionId,
  getProgressStats,
  (transactionId, progressStats) => {
    return countProgressBarValue(transactionId, progressStats);
  }
);

export const getEnablingStateForActivity = (activityId?: string) =>
  createSelector(getEnableTransactionState, enableState => {
    return activityId ? enableState?.find(progress => progress.activityId === activityId) : undefined;
  });

export const getEnablingStateForTransaction = (transactionId?: string) =>
  createSelector(getEnableTransactionState, enableState => {
    return transactionId ? enableState?.find(progress => progress.transactionId === transactionId) : undefined;
  });

export const getEnableSyncProgress = createSelector(getEnableTransactionState, enableState => enableState || []);

export const getEnableSyncActivityId = (transactionId?: string) =>
  createSelector(getEnablingStateForTransaction(transactionId), state => state?.activityId);

export const getEnableSyncInfo = (transactionId?: string) =>
  createSelector(getEnablingStateForTransaction(transactionId), state => state);

export const getEnableSyncEventIds = createSelector(getEnableTransactionState, state => {
  return state?.filter(s => s.eventId).map(s => s.eventId!) || [];
});

export const getEnableSyncProgressBarValues = createSelector(getEnableTransactionState, enableState => {
  return enableState.map(state => ({
    activityId: state.activityId,
    bar: countEnableProgressBarValue(state)
  }));
});

export const getTransactionIdForSync = createSelector(getTransactionState, state => state.sync.transactionId);
