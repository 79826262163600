import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { loadUserProfileSuccess } from '../../user/+state/user.actions';
import { SerializedSettingsEntity } from '../models/settings-entity';
import {
  clearIdsMarkedForReset,
  markIdsForReset,
  saveSerializedSettings,
  saveSetting,
  saveSettingsFailure,
  saveSettingsSuccess
} from './settings.actions';
import { SettingsState } from './settings.state';

export const settingsAdapter: EntityAdapter<SerializedSettingsEntity> = createEntityAdapter<SerializedSettingsEntity>();

export const initialState = settingsAdapter.getInitialState<
  Omit<SettingsState, keyof EntityState<SerializedSettingsEntity>>
>({
  savingIds: [],
  failedIds: [],
  markedForResetIds: []
});

const reducer = createReducer(
  initialState,

  on(loadUserProfileSuccess, (state, { userProfile: { accountPreferences, userPreferences } }) => {
    const preferences = [...accountPreferences, ...userPreferences];
    const settings = preferences.map<SerializedSettingsEntity>(({ key, value }) => ({
      id: key,
      value: value
    }));

    return settingsAdapter.setAll(settings, { ...state });
  }),

  on(saveSetting, (state, { key }) => ({
    ...state,
    savingIds: uniq([key].concat(state.savingIds)),
    failedIds: difference(state.failedIds, [key])
  })),

  on(saveSerializedSettings, (state, { data }) => {
    const ids = data.map(x => x.id);
    return {
      ...state,
      savingIds: uniq(ids.concat(state.savingIds)),
      failedIds: difference(state.failedIds, ids)
    };
  }),

  on(saveSettingsSuccess, (state, { settings }) => {
    const ids = settings.map(s => s.id);
    return settingsAdapter.upsertMany(settings, {
      ...state,
      savingIds: difference(state.savingIds, ids)
    });
  }),

  on(saveSettingsFailure, (state, { failedIds }) => ({
    ...state,
    savingIds: difference(state.savingIds, failedIds),
    failedIds: uniq(failedIds.concat(state.failedIds))
  })),

  on(markIdsForReset, (state, { markedForResetIds }) => ({
    ...state,
    markedForResetIds: markedForResetIds.concat(state.markedForResetIds)
  })),

  on(clearIdsMarkedForReset, state => ({
    ...state,
    markedForResetIds: []
  }))
);

export function settingsReducer(state: SettingsState | undefined, action: Action) {
  return reducer(state, action);
}
