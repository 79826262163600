import { Pipe, PipeTransform } from '@angular/core';
import { NestedValuePipe } from '../../utils/formatting/pipes/nested-value.pipe';
import { isDisabledItem } from '../utils/list-items/list-items.utils';

@Pipe({ name: 'fIsDisabledItem' })
export class FireflyIsDisabledItemPipe implements PipeTransform {
  constructor(private nestedValuePipe: NestedValuePipe) {}

  transform(item: unknown, disabledPath: string): boolean {
    return isDisabledItem(item, disabledPath, this.nestedValuePipe.transform);
  }
}
