import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { FireflyModalComponent } from '../modal.component';

@Component({
  selector: 'f-mobile-modal',
  templateUrl: './mobile-modal.component.html',
  styleUrls: ['./mobile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMobileModalComponent extends FireflyModalComponent {
  backBtnVisibilityChange$ = new Subject<boolean>();
  title = '';

  @ViewChild('modalTitle', { static: true }) private modalTitleRef!: ElementRef;

  private _showAcceptBtn = true;
  private _showBackBtn = false;

  get showBackBtn() {
    return this._showBackBtn;
  }

  get showAcceptBtn() {
    return this._showAcceptBtn;
  }

  toggleBackBtnVisibility(show: boolean) {
    window.requestAnimationFrame(() => {
      this._showBackBtn = show;
      this.cdr.markForCheck();
    });
  }

  toggleAcceptBtnVisibility(show: boolean) {
    window.requestAnimationFrame(() => {
      this._showAcceptBtn = show;
      this.cdr.markForCheck();
    });
  }

  onBackBtnClick() {
    this._showBackBtn = false;
    this.backBtnVisibilityChange$.next(false);
  }

  setNewTitle(value: string) {
    this.modalTitleRef.nativeElement.textContent = value;
  }

  resetTitle() {
    this.setNewTitle('');
  }
}
