import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileSortingModalComponent } from './mobile-sorting-modal.component';
import { FireflyListModule } from '../../lists/list.module';

@NgModule({
  imports: [CommonModule, FireflyListModule],
  declarations: [MobileSortingModalComponent],
  exports: [MobileSortingModalComponent]
})
export class FireflyMobileSortingModalModule {}
