import { ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoadingType } from '../../loading-indicators';
import { FireflyDrawerComponent } from '../drawer.component';
import { DrawerConfig, DrawerType } from '../models/drawer-config.model';
import { FireflyDrawerContent } from '../models/drawer-content.model';
import { DrawerRef } from '../models/drawer-ref.model';

@Injectable({ providedIn: 'root' })
export class FireflyDrawerService {
  private prevDrawerComponentRefsArr: ComponentRef<FireflyDrawerComponent>[] = [];
  private drawerComponentRef!: ComponentRef<FireflyDrawerComponent>;
  private defaultContainer!: ViewContainerRef;
  private currentDrawerRef!: DrawerRef;
  private customZIndex!: number | null;
  private redirectUrl: string | null = null;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart && this.drawerInstance?.opened),
        switchMap(event => {
          if (!this.drawerInstance?.formDrawerHasChanges) {
            this.drawerInstance?.close();
            return EMPTY;
          }

          if (this.drawerInstance?.hasConfirmationModal) {
            if (this.redirectUrl === null) {
              this.redirectUrl = this.router.url;
              return this.redirectObs;
            }

            return this.redirectObs;
          }

          // redirect to the same url to prevent redirecting
          this.redirectUrl = this.router.url;
          this.router.navigateByUrl(this.redirectUrl);

          return this.drawerInstance?.openLocalizedConfirmationModal().pipe(
            switchMap(() =>
              this.drawerInstance?.confirmationModalClose.pipe(
                map(() => {
                  this.drawerInstance?.close();
                  this.router.navigateByUrl((event as NavigationStart).url);
                }),
                catchError(() => {
                  setTimeout(() => (this.redirectUrl = null));
                  return EMPTY;
                })
              )
            )
          );
        })
      )
      .subscribe();
  }

  setDefaultContainer(container: ViewContainerRef) {
    this.defaultContainer = container;
  }

  openDrawer<TComponent extends FireflyDrawerContent<TInputs>, TInputs>(
    config: DrawerConfig,
    component: Type<TComponent>,
    inputs?: TInputs
  ): DrawerRef {
    if (this.drawerInstance?.formDrawerHasChanges && this.drawerInstance?.opened) {
      return this.initDrawerAfterFormChanges(config, component, inputs);
    } else {
      return this.drawerInit(config, component, inputs);
    }
  }

  private get redirectObs() {
    return this.drawerInstance?.confirmationModalClose.pipe(
      map(() => this.drawerInstance?.close()),
      catchError(() => {
        if (this.redirectUrl !== null) {
          this.router.navigateByUrl(this.redirectUrl!);
          this.redirectUrl = null;
        }
        return EMPTY;
      })
    );
  }

  private initDrawerAfterFormChanges<TComponent extends FireflyDrawerContent<TInputs>, TInputs>(
    config: DrawerConfig,
    component: Type<TComponent>,
    inputs?: TInputs
  ): DrawerRef {
    this.drawerInstance
      ?.openLocalizedConfirmationModal()
      ?.pipe(take(1))
      .subscribe(createDrawer => {
        if (createDrawer) this.drawerInit(config, component, inputs);
      });
    return this.currentDrawerRef;
  }

  private drawerInit<TComponent extends FireflyDrawerContent<TInputs>, TInputs>(
    config: DrawerConfig,
    component: Type<TComponent>,
    inputs?: TInputs
  ): DrawerRef {
    this.setupDrawerComponent(config);
    return this.createDrawerContent(component, inputs, config.injector);
  }

  private setupDrawerComponent(config: DrawerConfig) {
    const drawerConfig = this.customZIndex ? { ...config, zIndex: this.customZIndex } : config;
    this.defaultContainer = drawerConfig.container || this.defaultContainer;
    if (!this.defaultContainer) {
      throw new Error(
        'Firefly Drawer Error:  Missing Container. ' +
          'Either defaultContainer must be set or container should be passed into DrawerConfig.'
      );
    }

    const componentRef = this.defaultContainer.createComponent(FireflyDrawerComponent, { index: 0 });
    componentRef.instance.setConfig(drawerConfig);
    this.onDrawerClosed(componentRef);
    this.handlePreviousDrawers(drawerConfig, componentRef);
    this.drawerComponentRef = componentRef;
  }

  createDrawerContent<TComponent extends FireflyDrawerContent<TInputs>, TInputs>(
    component: Type<TComponent>,
    inputs?: TInputs,
    injector?: Injector
  ): DrawerRef {
    const drawerContentRef = this.drawerInstance.createDrawerContent(component, injector);
    if (inputs) drawerContentRef.instance.onInjectInputs(inputs);
    this.currentDrawerRef = new DrawerRef(this.drawerComponentRef, drawerContentRef);
    return this.currentDrawerRef;
  }

  private handlePreviousDrawers(config: DrawerConfig, componentRef: ComponentRef<FireflyDrawerComponent>) {
    if (this.drawerComponentRef) {
      if (config.addBackButton) {
        this.drawerInstance.toggleHiddenState(true);
        this.prevDrawerComponentRefsArr.push(this.drawerComponentRef);
        this.onBackButtonClick(componentRef);
      } else {
        this.drawerInstance?.close();
      }
    }
  }

  private onDrawerClosed(componentRef: ComponentRef<FireflyDrawerComponent>) {
    componentRef.instance.openedChange$.pipe(take(1)).subscribe(() => {
      componentRef.destroy();
      this.clearDrawersHistory();
    });
  }

  closeDrawersWithoutConfirmation() {
    this.drawerInstance?.close();
    const ids: number[] = [];

    this.prevDrawerComponentRefsArr.forEach((ref, index) => {
      if (ref.instance.isFormDrawer && ref.instance.formHasChanges) {
        this.drawerComponentRef = ref;
        this.drawerInstance?.setConfirmationModalTrigger(false);
        this.drawerInstance?.toggleHiddenState(false);
        this.drawerInstance?.confirmAndClose();
        return;
      }
      ids.push(index);
      ref.destroy();
    });

    if (!ids.length) {
      this.prevDrawerComponentRefsArr = [];
      return;
    }

    ids.forEach(index => this.prevDrawerComponentRefsArr.splice(index, 1));
  }

  private clearDrawersHistory() {
    const ids: number[] = [];

    this.prevDrawerComponentRefsArr.forEach((ref, index) => {
      if (ref.instance.isFormDrawer && ref.instance.formHasChanges) {
        this.drawerComponentRef = ref;
        this.drawerInstance?.setConfirmationModalTrigger(true);
        this.drawerInstance?.toggleHiddenState(false);
        this.drawerInstance?.confirmAndClose();
        return;
      }
      ids.push(index);
      ref.destroy();
    });

    if (!ids.length) {
      this.prevDrawerComponentRefsArr = [];
      return;
    }

    ids.forEach(index => this.prevDrawerComponentRefsArr.splice(index, 1));
  }

  private onBackButtonClick(componentRef: ComponentRef<FireflyDrawerComponent>) {
    componentRef.instance.openPreviousDrawer$.pipe(takeUntil(componentRef.instance.destroyed$)).subscribe(() => {
      componentRef.destroy();
      this.drawerComponentRef = this.prevDrawerComponentRefsArr[this.prevDrawerComponentRefsArr.length - 1];
      this.drawerInstance?.toggleHiddenState(false);
      this.prevDrawerComponentRefsArr.pop();
    });
  }

  private get drawerInstance() {
    return this.drawerComponentRef?.instance;
  }

  setForm(form: FormGroup) {
    this.drawerInstance.form = form;
    this.drawerInstance.createFormReference(form);
  }

  setDrawerLoadingState(type?: LoadingType) {
    this.drawerInstance?.setDrawerLoadingState(type);
  }

  setZIndex(zIndex: number) {
    this.customZIndex = zIndex;
  }

  resetZIndex() {
    this.customZIndex = null;
    this.drawerInstance?.resetZIndex();
  }

  // TODO: Get rid of this after "CRM Institution" form-drawer is refactored
  changeDrawerFooterVisibility(shouldHideFooter: boolean) {
    this.drawerInstance.shouldHideFooter = shouldHideFooter;
  }

  // TODO: Get rid of this after "CRM Institution" form-drawer is refactored
  setDrawerType(type: DrawerType) {
    this.drawerInstance?.setDrawerType(type);
  }
}
