import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../utils';

@Component({
  selector: 'f-action-buttons',
  templateUrl: './action-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyActionButtonsComponent implements OnInit {
  @Input() showSelectAll!: boolean;
  @Input() showClearAll!: boolean;
  @Output() updateList = new EventEmitter<boolean>();

  selectAll$ = of('Select all');
  clearAll$ = of('Clear all');

  onActionButtonClick(isSelected: boolean, $event: Event) {
    this.updateList.emit(isSelected);
    $event.stopPropagation();
  }

  constructor(@Optional() private localizationService: FireflyLocalizationService) {}

  ngOnInit() {
    if (!this.localizationService) return;
    this.selectAll$ = this.localizationService.localize('suggesterSelectAll', {});
    this.clearAll$ = this.localizationService.localize('suggesterClearAll', {});
  }
}
