import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomFieldNumberPipe } from './custom-field-number.pipe';

@NgModule({
  declarations: [CustomFieldNumberPipe],
  imports: [CommonModule],
  exports: [CustomFieldNumberPipe]
})
export class CustomFieldNumberModule {}
