import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalOptions } from './confirmation-modal-options';

@Component({
  selector: 'f-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyConfirmationModalComponent {
  context!: ConfirmationModalOptions;
  @HostBinding('attr.data-automation-id') get dataAutomationId() {
    return this.context?.dataAutomationId;
  }

  get cssBtnColorClass() {
    switch (this.context.style) {
      case 'error':
        return 'btn-danger';
      case 'warning':
      case 'warning-merge':
        return 'btn-warning';
      case 'confirmation':
        return 'btn-primary';
    }
  }

  constructor(public modal: NgbActiveModal) {}

  confirmClick() {
    this.modal.close();
  }
}
