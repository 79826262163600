import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { getUserLoaded } from '@capital-access/common/user';
import { getLicenseAgreementSettings } from '../+store/selectors';
import { LICENSE_AGREEMENT_PAGE_ROUTE, LICENSE_AGREEMENT_PREFERENCE_SECTION } from '../models/constants';

@Injectable({ providedIn: 'root' })
export class LicenseAgreementGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(LICENSE_AGREEMENT_PREFERENCE_SECTION) private preferenceSection: string
  ) {}

  canActivate() {
    return this.store.select(getUserLoaded).pipe(
      filter(userLoaded => userLoaded),
      withLatestFrom(this.store.select(getLicenseAgreementSettings(this.preferenceSection))),
      map(([_, licenseAgreement]) => {
        if (!licenseAgreement.trialAccepted) {
          // for now we have only trial users
          // terms of use will be different for trial and regular users
          this.router.navigate([LICENSE_AGREEMENT_PAGE_ROUTE]);
        }

        return licenseAgreement.trialAccepted;
      }),
      take(1)
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
