import { LoadStatus } from '@capital-access/common/utils';
import { Account } from '../models/account';
import { User } from '../models/user';
import { ContentPermissions, Entitlements } from '../models/user-profile';

export const USER_PROFILE_FEATURE_KEY = 'common.user.profile';

export interface UserState {
  user: User | null;
  account: Account | null;
  contentPermissions: ContentPermissions | null;
  userEntitlements: Entitlements | null;
  loadStatus: LoadStatus;
  error?: unknown;
}
