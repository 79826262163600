<ng-container [caLocalizationScope]="localizationScope">
  <ca-modify-report-drawer-body [reportDescriptionTitle]="request!.profileType + 'ReportDescription'">
    <form [formGroup]="form">
      <ca-sections-selector formControlName="sections" (settingsChange)="settingsChange($event)"></ca-sections-selector>
      <ca-set-as-default-toggle
        [control]="setAsDefaultControl"
        [disableSaving]="disableSaving"
        [toggleLabel]="request!.profileType + 'ToggleLabel'"
      ></ca-set-as-default-toggle>
    </form>
  </ca-modify-report-drawer-body>
  <ng-template fDrawerFooterTemplate>
    <ca-modify-report-drawer-footer
      [disableSaving]="disableSaving"
      [createReportTitle]="request!.profileType + 'CreateReport'"
      (closeDrawer)="close()"
      (createReport)="createReport()"
    >
    </ca-modify-report-drawer-footer>
  </ng-template>
</ng-container>
