import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  NgbAccordionDirective,
  NgbAccordionBody,
  NgbAccordionConfig,
  NgbAccordionModule,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionToggle,
  NgbAccordionItem
} from '@ng-bootstrap/ng-bootstrap';
import { FireflyAccordionConfig } from './accordion-config';

@NgModule({
  imports: [NgbAccordionModule],
  exports: [
    NgbAccordionDirective,
    NgbAccordionBody,
    NgbAccordionCollapse,
    NgbAccordionButton,
    NgbAccordionToggle,
    NgbAccordionItem
  ]
})
export class FireflyAccordionModule {
  static forRoot(): ModuleWithProviders<FireflyAccordionModule> {
    return {
      ngModule: FireflyAccordionModule,
      providers: [{ provide: NgbAccordionConfig, useClass: FireflyAccordionConfig }]
    };
  }
}
