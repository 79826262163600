<ng-container *ngIf="navItem" [caLocalizationScope]="localizationScope">
  <a
    class="link link-main ca-nav-item"
    [ngClass]="linkHighlightClass"
    (click)="onClick($event, tooltip)"
    [routerLink]="navItem.link && [navItem.link]"
    ngbTooltip="{{ navItem.localizationKey | caLocalize }}"
    placement="right"
    tooltipClass="text-nowrap"
    [disableTooltip]="!mini"
    #tooltip="ngbTooltip"
  >
    <span class="ca-nav-item-container" [attr.data-automation-id]="navItem.localizationKey">
      <ca-nav-item-icon [navItem]="navItem"></ca-nav-item-icon>
      <span *ngIf="!mini" class="ps-2" @fadeIn>{{ navItem.localizationKey | caLocalize }}</span>
    </span>
    <ng-container *ngIf="expandable">
      <i class="f-i f-i-xxs f-i-chevron-right" [ngClass]="chevronTransitionClass"></i>
    </ng-container>
  </a>
  <nav class="ca-link-nav" *ngIf="expanded && hasSubItems" @fade @fadeIn>
    <a
      *ngFor="let subItem of $any(navItem).subItems"
      class="ca-link-nav-subitem"
      [ngClass]="rla.isActive ? activeSecondNavClass : selectableClass"
      [routerLink]="[subItem.link]"
      routerLinkActive
      #rla="routerLinkActive"
      >{{ subItem.localizationKey | caLocalize }}</a
    >
  </nav>
</ng-container>
