import { Component, HostBinding, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FireflyMobileModalComponent, ModalContent } from '../../modal';
import { FireflyDateRangeDirective } from '../directives/date-range.directive';

@Component({
  selector: 'f-mobile-date-range',
  template: ``
})
export class FireflyMobileDateRangeComponent implements ModalContent, OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  context!: FireflyDateRangeDirective;
  modalInstance!: NgbActiveModal;

  @HostBinding('class') class = 'd-none';

  constructor(@SkipSelf() private modalContainer: FireflyMobileModalComponent) {}

  getTitle() {
    const startDateTitle = this.context.dateInputs.first.value
      ? this.context.dateInputs.first.inputElement.value
      : 'Start date';
    const endDateTitle = this.context.dateInputs.last.value
      ? this.context.dateInputs.last.inputElement.value
      : 'End date';
    return `${startDateTitle} - ${endDateTitle}`;
  }

  ngOnInit() {
    this.modalContainer.setNewTitle(this.getTitle());
    this.context.openPopupFromModal(this.modalContainer.dynamicContent);
    this.context.rangeChange$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.modalContainer.setNewTitle(this.getTitle());
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
