import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySpinnerModule } from '../loading-indicators/spinner/spinner.module';
import { FireflyResultsTotalCountComponent } from './results-total-count.component';

const components = [FireflyResultsTotalCountComponent];

@NgModule({
  imports: [CommonModule, FireflySpinnerModule],
  declarations: [...components],
  exports: [...components]
})
export class FireflyResultsTotalCountModule {}
