import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyEmptyStatesDirective } from './directives/empty-states.directive';
import { VirtualGridFixedHeightDirective } from './directives/virtual-grid-fixed-height.directive';
import { FireflyNoResultsEmptyStateComponent } from './no-results-empty-state/no-results-empty-state.component';
import { FireflyOnboardingEmptyStateComponent } from './onboarding-empty-state/onboarding-empty-state.component';
import { FireflySimpleActionableEmptyStateComponent } from './simple-actionable-empty-state/simple-actionable-empty-state.component';
import { FireflySimpleEmptyStateComponent } from './simple-empty-state/simple-empty-state.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FireflyEmptyStatesDirective,
    VirtualGridFixedHeightDirective,
    FireflyOnboardingEmptyStateComponent,
    FireflySimpleActionableEmptyStateComponent,
    FireflySimpleEmptyStateComponent,
    FireflyNoResultsEmptyStateComponent
  ],
  exports: [
    FireflyEmptyStatesDirective,
    VirtualGridFixedHeightDirective,
    FireflyOnboardingEmptyStateComponent,
    FireflySimpleActionableEmptyStateComponent,
    FireflySimpleEmptyStateComponent,
    FireflyNoResultsEmptyStateComponent
  ]
})
export class FireflyEmptyStatesModule {}
