import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { openContactPreview, openFundPreview, openInstitutionPreview } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CompositeId } from '@capital-access/profiles/common';
import { LOAD_PROFILE_PREVIEW_MODULE, PROFILE_PREVIEW_MODULE_ID } from '../profile-preview-module-id';

@Injectable()
export class ProfilePreviewLoaderEffects {
  openInstitutionPreview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openInstitutionPreview),
        switchMap(action =>
          this.commonLazyLoadingService
            .loadModule(PROFILE_PREVIEW_MODULE_ID, LOAD_PROFILE_PREVIEW_MODULE)
            .pipe(
              tap(moduleRef =>
                moduleRef.instance.openInstitutionPreview(
                  new CompositeId(action.id.prId, action.id.crmId),
                  action.initiatorDrawer,
                  action.backButtonPreviewDrawer,
                  action.name
                )
              )
            )
        )
      ),
    { dispatch: false }
  );

  openContactPreview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openContactPreview),
        switchMap(action =>
          this.commonLazyLoadingService
            .loadModule(PROFILE_PREVIEW_MODULE_ID, LOAD_PROFILE_PREVIEW_MODULE)
            .pipe(
              tap(moduleRef =>
                moduleRef.instance.openContactPreview(
                  new CompositeId(action.id.prId, action.id.crmId),
                  action.initiatorDrawer,
                  action.backButtonPreviewDrawer,
                  action.name
                )
              )
            )
        )
      ),
    { dispatch: false }
  );

  openFundPreview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openFundPreview),
        switchMap(action =>
          this.commonLazyLoadingService
            .loadModule(PROFILE_PREVIEW_MODULE_ID, LOAD_PROFILE_PREVIEW_MODULE)
            .pipe(
              tap(moduleRef =>
                moduleRef.instance.openFundPreview(new CompositeId(action.id.prId, action.id.crmId), action.name)
              )
            )
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
