import { Action, createReducer, on } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { loadUserProfileFail, loadUserProfileSuccess } from './user.actions';
import { UserState } from './user.state';

export const getInitialState = (): UserState => ({
  user: null,
  account: null,
  contentPermissions: null,
  userEntitlements: null,
  loadStatus: LoadStatus.Pristine,
  error: null
});

export const reducer = createReducer(
  getInitialState(),
  on(
    loadUserProfileSuccess,
    (_, { userProfile: { user, account, contentPermissions, userEntitlements } }): UserState => ({
      user,
      account,
      contentPermissions,
      userEntitlements,
      loadStatus: LoadStatus.Loaded
    })
  ),
  on(loadUserProfileFail, (state, { error }): UserState => ({ ...state, error, loadStatus: LoadStatus.Failed }))
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
