import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbTooltip, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TOOLTIP_OPEN_DELAY } from './constants';
import { FireflyTooltipConfig } from './tooltip-config';

@NgModule({
  imports: [NgbTooltipModule],
  exports: [NgbTooltip]
})
export class FireflyTooltipModule {
  static forRoot(): ModuleWithProviders<FireflyTooltipModule> {
    // Fix tooltip issue BDC-8894
    fromEvent(document, 'visibilitychange')
      .pipe(debounceTime(TOOLTIP_OPEN_DELAY * 2))
      .subscribe(() => {
        if (!document.hidden) {
          const tooltip = document.querySelector('ngb-tooltip-window');
          const activeElement = document.activeElement as HTMLElement;

          if (tooltip) {
            tooltip.remove();
            activeElement?.blur();
          }
        }
      });

    return {
      ngModule: FireflyTooltipModule,
      providers: [{ provide: NgbTooltipConfig, useClass: FireflyTooltipConfig }]
    };
  }
}
