import { SelectedSecuritySettings } from './models';

export function toSettings(selectedSecurityId: number | null): SelectedSecuritySettings {
  return {
    id: selectedSecurityId
  };
}

export function toSelectedSecurityId(settings: SelectedSecuritySettings): number | null {
  if (!settings.id) {
    return null;
  }

  return settings.id;
}
