import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { INTERNAL_SERVER_ERROR_PAGE_ROUTE } from '@capital-access/common/utils';
import { getFeatureTogglesLoaded, getFeatureTogglesLoadFailed } from './+state/feature-toggle.selectors';

@Injectable({ providedIn: 'root' })
export class FeatureToggleGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(INTERNAL_SERVER_ERROR_PAGE_ROUTE) private internalServerErrorPageRoute: string
  ) {}

  canActivate() {
    return combineLatest([
      this.store.select(getFeatureTogglesLoaded),
      this.store.select(getFeatureTogglesLoadFailed)
    ]).pipe(
      filter(([isLoaded, hasErrors]) => isLoaded || hasErrors),
      map(([isLoaded, hasErrors]) => {
        if (hasErrors) {
          this.router.navigate([this.internalServerErrorPageRoute]);
        }
        return isLoaded;
      }),
      take(1)
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
