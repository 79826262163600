import { Component, OnInit, SkipSelf, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FireflyMobileModalComponent, ModalContent } from '../../modal';

@Component({
  selector: 'f-mobile-popover',
  template: `<div>
    <ng-container *ngIf="templateRef && templateRef.createEmbeddedView; else stringContent">
      <ng-container *ngTemplateOutlet="templateRef; context: context"></ng-container>
    </ng-container>
    <ng-template #stringContent>
      {{ context.content }}
    </ng-template>
  </div>`
})
export class FireflyMobilePopoverComponent implements ModalContent, OnInit {
  context!: { content: TemplateRef<unknown> | string; closeOnClickInside: boolean };
  modalInstance!: NgbActiveModal;
  templateRef!: TemplateRef<unknown>;

  constructor(@SkipSelf() private modalContainer: FireflyMobileModalComponent) {}

  ngOnInit() {
    if (this.context.content instanceof TemplateRef) {
      this.templateRef = this.context.content;
    }

    this.modalContainer.toggleAcceptBtnVisibility(false);

    this.modalContainer.modalBody.nativeElement.addEventListener('click', (e: PointerEvent) => {
      const target = e.target as HTMLElement;
      if (target.classList.contains('link') || this.context.closeOnClickInside) {
        this.modalInstance.close();
      }
    });
  }
}
