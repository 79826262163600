/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettingsService } from '@capital-access/common/settings';
import {
  Audit,
  BatchResponse,
  Event,
  EventParticipant,
  EventReservation,
  EventReservationTemplate,
  EventsAuditSearchCriteria,
  EventSchedule,
  EventSearchCriteria,
  SearchResult
} from './models';
import { Settings } from './settings/settings.model';

@Injectable()
export class EventRepository {
  private eventHubBaseUrl: string;

  constructor(private httpClient: HttpClient, settingsService: AppSettingsService) {
    const settings = settingsService.getSettings<Settings>();
    this.eventHubBaseUrl = settings.eventHubUrl;
  }

  // using generic type here because search might return not all fields of event and subentities
  // depending on provided fields in EventSearchCriteria
  // it is highly recommended to get only that fields that are used by client to increase performance
  searchEvents<T>(searchCriteria: EventSearchCriteria): Observable<SearchResult<T>> {
    return this.httpClient.post<SearchResult<T>>(`${this.eventHubBaseUrl}/api/search/events`, searchCriteria);
  }

  getAudit(searchCriteria: EventsAuditSearchCriteria): Observable<SearchResult<Audit>> {
    return this.httpClient.post<SearchResult<Audit>>(
      `${this.eventHubBaseUrl}/api/event/audits/last-modified`,
      searchCriteria
    );
  }

  deleteEvent(eventId: string) {
    return this.httpClient.delete(`${this.eventHubBaseUrl}/api/event/${eventId}`);
  }

  getEvent(id: string): Observable<Event> {
    return this.httpClient.get(`${this.eventHubBaseUrl}/api/event/${id}`).pipe(map(this._toEvent));
  }

  getEventSchedules(eventId: string): Observable<EventSchedule[]> {
    return this.httpClient
      .get(`${this.eventHubBaseUrl}/api/event/${eventId}/schedules`)
      .pipe(map(this._toEventSchedules));
  }

  getEventParticipants(eventId: string): Observable<EventParticipant[]> {
    return this.httpClient.get<EventParticipant[]>(`${this.eventHubBaseUrl}/api/event/${eventId}/participants`);
  }

  updateEvent(id: string, event: Event): Observable<Event> {
    return this.httpClient
      .put(`${this.eventHubBaseUrl}/api/event/${id}`, {
        ...event,
        startDate: this._toISOStringWithoutTimeZone(event.startDate),
        endDate: this._toISOStringWithoutTimeZone(event.endDate)
      })
      .pipe(map(this._toEvent));
  }

  addEventSchedule(eventId: string, eventSchedule: EventSchedule): Observable<EventSchedule> {
    return this.httpClient
      .post(`${this.eventHubBaseUrl}/api/event/${eventId}/schedules`, {
        ...eventSchedule,
        startDate: this._toISOStringWithoutTimeZone(eventSchedule.startDate)
      })
      .pipe(map(this._toEventSchedule));
  }

  updateEventSchedule(eventId: string, scheduleId: string, eventSchedule: EventSchedule): Observable<EventSchedule> {
    return this.httpClient
      .put(`${this.eventHubBaseUrl}/api/event/${eventId}/schedules/${scheduleId}`, {
        ...eventSchedule,
        startDate: this._toISOStringWithoutTimeZone(eventSchedule.startDate)
      })
      .pipe(map(this._toEventSchedule));
  }

  deleteEventSchedule(eventId: string, scheduleId: string) {
    return this.httpClient.delete(`${this.eventHubBaseUrl}/api/event/${eventId}/schedules/${scheduleId}`);
  }

  createAutoGeneratedReservations(
    eventId: string,
    reservationTemplate: EventReservationTemplate
  ): Observable<EventReservation[]> {
    return this.httpClient.post<EventReservation[]>(
      `${this.eventHubBaseUrl}/api/event/${eventId}/reservations/auto-generate`,
      reservationTemplate
    );
  }

  deleteScheduleReservations(eventId: string, scheduleId: string) {
    return this.httpClient.delete(`${this.eventHubBaseUrl}/api/event/${eventId}/schedule/${scheduleId}/reservations`);
  }

  addEventParticipant(eventId: string, participant: EventParticipant): Observable<EventParticipant> {
    return this.httpClient.post<EventParticipant>(
      `${this.eventHubBaseUrl}/api/event/${eventId}/participants`,
      participant
    );
  }

  updateEventParticipant(eventId: string, participant: EventParticipant) {
    return this.httpClient.put<EventParticipant>(
      `${this.eventHubBaseUrl}/api/event/${eventId}/participants/${participant.eventParticipantId}`,
      participant
    );
  }

  addEventParticipants(eventId: string, participants: EventParticipant[]): Observable<BatchResponse<EventParticipant>> {
    return this.httpClient.post<BatchResponse<EventParticipant>>(
      `${this.eventHubBaseUrl}/api/v2/event/${eventId}/participants`,
      participants
    );
  }

  deleteEventParticipant(eventId: string, participantId: number) {
    return this.httpClient.delete(`${this.eventHubBaseUrl}/api/event/${eventId}/participants/${participantId}`);
  }

  private _toISOStringWithoutTimeZone(date: Date | null): string | null {
    return date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString() : null;
  }

  private _toEvent(r: any): Event {
    return {
      ...r,
      startDate: r.startDate ? new Date(r.startDate) : null,
      endDate: r.endDate ? new Date(r.endDate) : null
    } as Event;
  }

  private _toEventSchedule(r: any): EventSchedule {
    return {
      ...r,
      startDate: r.startDate ? new Date(r.startDate) : null
    } as EventSchedule;
  }

  private _toEventSchedules(r: Object): EventSchedule[] {
    return (<any[]>r).map(
      r =>
        <EventSchedule>{
          ...r,
          startDate: r.startDate ? new Date(r.startDate) : null
        }
    );
  }
}
