import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyProgressComponent } from './progress.component';
import { FireflyProgressActionDirective } from './progress-action.directive';
import { FireflyProgressErrorActionDirective } from './progress-error-action.directive';

@NgModule({
  declarations: [FireflyProgressComponent, FireflyProgressActionDirective, FireflyProgressErrorActionDirective],
  imports: [CommonModule],
  exports: [FireflyProgressComponent]
})
export class FireflyProgressModule {}
