<div class="d-flex w-100">
  <div class="overflow-hidden me-auto">
    <ng-content></ng-content>
  </div>
  <div class="ms-auto ps-4 toggle-list-item-button">
    <button
      class="btn btn-simple-icon btn-rounded"
      [ngClass]="buttonClass"
      (click)="onActionIconClick()"
      *ngIf="showActionIcon"
    >
      <i class="f-i f-i-md ms-0" [ngClass]="iconClass" [title]="iconTitle$ | async"></i>
    </button>
    <div *ngIf="showCheckIcon" class="toggle-list-item-icon-check">
      <i class="f-i f-i-xs f-i-check" [@iconAnimation] (@iconAnimation.done)="captureAnimationDoneEvent($event)"></i>
    </div>
  </div>
</div>
