<ca-concentration-bar-chart
  [factorInfo]="factorInfo"
  [showLegend]="false"
  [decileConcentrationClasses]="concentrationCssClasses"
  [highlightedIndexRange]="calculatedDecilesIndexRange"
  [xAxisTickLabelClasses]="'body-50'"
  [infoBubbleClasses]="['ca-selected-decile-bubble']"
  [popoverTemplate]="showPopover ? popoverTemplate : null"
  [interactions]="{ click: false, hover: showPopover }"
></ca-concentration-bar-chart>
<ca-concentration-legend
  *ngIf="showLegend"
  [securityName]="securityName"
  [factorInfo]="factorInfo"
  [colorScheme]="colorScheme"
></ca-concentration-legend>

<ng-template #popoverTemplate let-context>
  <h6 class="fw-bold mb-4">{{ factorInfo.ratioDesc }}</h6>
  <div class="row mb-2">
    <div class="col-8">{{ valueTitle | async }}</div>
    <div class="col text-right fw-bold">{{ context.originalValue ? (context.originalValue | caNumber : 2) : '-' }}</div>
  </div>
  <hr class="my-2" style="transform: scaleY(0.3)" />
  <div *ngIf="securityDecileIndex >= 0" class="row mb-2">
    <div class="col-8 d-flex align-items-baseline">
      <div class="legend-marker rounded-pill ca-selected-decile-bubble"></div>
      <span>{{ securityDecile | async }}</span>
    </div>
    <div class="col text-right">{{ securityDecileIndex + 1 | caNumber : 0 }}</div>
  </div>
  <div *ngIf="calculatedDecilesValueRange" class="row mb-2">
    <div class="col-7 d-flex align-items-baseline">
      <div class="legend-marker legend-line ca-highlighted-decile"></div>
      <span>{{ calculatedDecileRange | async }}</span>
    </div>
    <div class="col text-right">
      {{ calculatedDecilesValueRange[0] | caNumber : 2 }} -
      {{ calculatedDecilesValueRange[1] | caNumber : 2 }}
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-7 d-flex align-items-baseline">
      <div class="legend-marker" [ngClass]="concentrationCssClasses[4]"></div>
      <span>{{ topFactorValues | async }}</span>
    </div>
    <div class="col text-right text-prewrap">
      <span *ngFor="let decile of mostDecilesRange; let isLast = last"
        >{{ decile | caNumber : 2 }}{{ isLast ? '' : ', ' }}</span
      >
    </div>
  </div>
</ng-template>
