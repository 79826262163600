import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';
import type { NotificationOnClose, NotificationType } from './notification/notification-models';

@Component({
  selector: 'f-toast-message',
  templateUrl: './toast-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastMessageComponent implements OnInit {
  @Input() message!: string | TemplateRef<unknown>;
  @Input() headerText!: string | null;
  @Input() type!: NotificationType;
  @Input() onClose!: NotificationOnClose;

  close$: Observable<unknown> = of('Close');

  get messageTemplate() {
    return this.message instanceof TemplateRef ? (this.message as TemplateRef<unknown>) : null;
  }

  constructor(@Optional() private localizationService: FireflyLocalizationService) {}

  ngOnInit() {
    if (this.localizationService) {
      this.close$ = this.localizationService.localize('close', {});
    }
  }

  closeClick() {
    this.onClose();
  }
}
