import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CultureCode, getUserSettingsCulture, getUserSettingsTimezone } from '@capital-access/common/globalization';
import { datePatternOptions } from '../formatting-options/date-pattern.options';
import { timePatternOptions } from '../formatting-options/time-pattern.options';
import { DateFormatOptions, TimeFormatOptions } from '../models/date-formatting.models';

@Injectable()
export class DateTimeFormattingService {
  constructor(private store: Store) {}

  formatDate(date: DateTime | string | null, options?: DateFormatOptions, customPattern?: string): Observable<string>;
  formatDate(
    date: DateTime | string | null,
    options?: DateFormatOptions,
    customPattern?: string,
    cultureCode?: CultureCode
  ): Observable<string>;
  formatDate(
    date: DateTime | string | null,
    options?: DateFormatOptions,
    customPattern?: string,
    cultureCode?: CultureCode
  ): Observable<string> {
    if (!date) return of('');

    return combineLatest([this.store.select(getUserSettingsCulture), this.store.select(getUserSettingsTimezone)]).pipe(
      take(1),
      map(([cultureFromSettings, timezone]) => {
        const culture = cultureCode ?? cultureFromSettings;
        const luxDate = date instanceof DateTime ? date : this.createDate(date, options, culture, timezone);
        return luxDate.toFormat(this.getDateTimePattern(customPattern, options, culture));
      })
    );
  }

  private createDate(date: string, options: DateFormatOptions | undefined, cultureCode: string, timezone: string) {
    return DateTime.fromISO(date, {
      setZone: options?.ignoreLocalTimezone,
      locale: cultureCode,
      zone: options?.zone ?? timezone
    });
  }

  private getDateTimePattern(
    customPattern: string | undefined,
    options: DateFormatOptions | undefined,
    cultureCode: CultureCode
  ) {
    const optionsDatePattern =
      customPattern || (options?.format ? datePatternOptions[cultureCode][options.format] : '');

    return optionsDatePattern || datePatternOptions[cultureCode].default;
  }

  formatTime(date: string | null, options?: TimeFormatOptions, customPattern?: string): Observable<string>;
  formatTime(
    date: string | null,
    options?: TimeFormatOptions,
    customPattern?: string,
    cultureCode?: CultureCode
  ): Observable<string>;
  formatTime(
    date: string | null,
    options?: TimeFormatOptions,
    customPattern?: string,
    cultureCode?: CultureCode
  ): Observable<string> {
    if (!date) return of('');

    return combineLatest([this.store.select(getUserSettingsCulture), this.store.select(getUserSettingsTimezone)]).pipe(
      take(1),
      map(([cultureFromSettings, timezone]) => {
        const culture = cultureCode ?? cultureFromSettings;
        return this.createTime(customPattern, options, culture, timezone, date);
      })
    );
  }

  private createTime(
    customPattern: string | undefined,
    options: TimeFormatOptions | undefined,
    culture: CultureCode,
    timezone: string,
    date: string
  ) {
    const optionsTimePattern = customPattern || (options?.format ? timePatternOptions[culture][options.format] : '');

    const timePattern = optionsTimePattern || timePatternOptions[culture].default;

    const dateTime = DateTime.fromISO(date, {
      setZone: options?.ignoreLocalTimezone,
      locale: culture,
      zone: options?.zone ?? timezone
    });

    const formattedTime = dateTime.toFormat(timePattern);

    const timeZoneLabel = dateTime.zoneName;
    return options?.ignoreTimeZoneLabel ? formattedTime : `${formattedTime} ${timeZoneLabel}`.trim();
  }
}
