import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Optional,
  TemplateRef,
  Type
} from '@angular/core';
import type { Notification, NotificationComponentContainer, NotificationOptions } from './notification-models';
import {
  NOTIFICATION_COMPONENT_CONTAINER_TOKEN,
  NOTIFICATION_DATA,
  NOTIFICATION_DEFAULT_OPTIONS
} from './notification-token';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private container!: NotificationComponentContainer;

  constructor(
    private appRef: ApplicationRef,
    private injector: Injector,
    // todo: unable to replace ComponentFactoryResolver with ViewContainerRef as this is service
    // need to find a way to do so
    // https://github.com/angular/angular/issues/44947
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    @Inject(NOTIFICATION_DEFAULT_OPTIONS) private defaultOptions: NotificationOptions,
    @Optional()
    @Inject(NOTIFICATION_COMPONENT_CONTAINER_TOKEN)
    private componentContainerType: Type<NotificationComponentContainer>
  ) {
    const containerRef = this.componentFactoryResolver
      .resolveComponentFactory(this.componentContainerType)
      .create(this.injector);

    this.container = containerRef.instance;

    this.appRef.attachView(containerRef.hostView);
    const domElem = (containerRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    document.body.insertBefore(domElem, document.body.firstChild);
  }

  notify(message?: string | TemplateRef<unknown>, options?: NotificationOptions) {
    const notification = {
      message
    } as Notification;

    this._notify(notification, options);
  }

  notifyUsingComponent<T>(component: Type<T>, options?: NotificationOptions) {
    const notification = {
      template: component
    } as Notification;

    this._notify(notification, options);
  }

  private _notify(notification: Notification, options?: NotificationOptions) {
    const customOptions = {
      ...this.defaultOptions,
      ...options
    };

    const customNotification = {
      ...this.getNotification(customOptions),
      ...notification
    };

    this.container.add(customNotification);

    let timeout: number;

    if (!customOptions.manualClose) {
      timeout = setTimeout(
        (() => {
          this.container.remove(customNotification);
        }) as TimerHandler,
        customOptions.delay
      );
    }

    customNotification.onClose = () => {
      this.container.remove(customNotification);
      clearTimeout(timeout);
    };
  }

  private getNotification(options: NotificationOptions) {
    return {
      type: options.type,
      headerText: options.headerText,
      manualClose: options.manualClose,
      templateInjector: this.getInjector(options.templateContext)
    } as Notification;
  }

  private getInjector(data: unknown) {
    return Injector.create({
      providers: [{ provide: NOTIFICATION_DATA, useValue: data }],
      parent: this.injector
    });
  }
}
