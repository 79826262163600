import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'f-bar',
  templateUrl: './bar.component.html',
  animations: [
    trigger('expandBar', [
      state('*', style({ width: '{{width}}%' }), { params: { width: 0 } }),
      transition('* <=> *', animate('{{duration}}ms {{delay}}ms ease-out'), { params: { duration: 0, delay: 0 } })
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyBarComponent {
  @Input() value!: string;
  @Input() name!: string;
  @Input() thickness = 5;
  @Input() popover!: string | TemplateRef<unknown>;
  @Input() popoverPlacement = 'top';
  @Input() popoverTriggers = 'hover focus';
  @Input() animationDuration = 500;
  @Input() animationDelay = 0;
  @Input() animation = false;
  @Input() barClass!: string | string[];
  @Input() iconClass!: string | string[];
  @Input() nameClass!: string | string[];
  @Input() valueClass!: string | string[];
  @Input() containerClass!: string | string[];
  @Input() percentage!: number;

  @HostBinding('class') cssCass = 'd-block position-relative';

  get animationState() {
    return `change-bar-width-to-${this.percentage}`;
  }
}
