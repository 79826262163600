import { NgModule } from '@angular/core';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';

export const loadModule = () => import('@capital-access/targeting/client').then(m => m.TargetingClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: 'targeting',
          loadChildren: loadModule
        }
      ]
    }),
    CommonNavigationModule.forFeature({
      top: [
        {
          localizationKey: 'target',
          icon: 'f-i-target',
          order: 100,
          link: 'targeting'
        }
      ]
    })
  ]
})
export class TargetingClientLazyModule {}
