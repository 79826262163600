<div class="modal-header">
  <h4 class="modal-title">{{ context.title }}</h4>
  <button class="btn btn-sm btn-simple-icon btn-rounded btn-primary" (click)="modal.dismiss()">
    <i class="f-i f-i-close"></i>
  </button>
</div>
<div #body [ngClass]="contentClass">
  <ng-template #dynamicContent></ng-template>
</div>
<div class="modal-footer" *ngIf="!context.hideDefaultFooter">
  <button type="button" data-automation-id="confirm-btn" class="btn btn-primary me-3" (click)="confirmClick()">
    {{ context.actionText }}
  </button>
  <button type="button" data-automation-id="dismiss-btn" class="btn btn-secondary" (click)="modal.dismiss()">
    {{ context.dismissText }}
  </button>
</div>
