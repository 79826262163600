import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { openContactSupportDrawer } from '@capital-access/common/actions';
import { LocalizationService } from '@capital-access/common/localization';
import { saveFile } from '@capital-access/common/utils';
import { NotificationService } from '@capital-access/firefly/components';
import { ProfileRepository } from '@capital-access/profiles/common';
import { ContactSupportDrawer } from '../contact-support-drawer';
import {
  exportWithdrawList,
  exportWithdrawListFail,
  exportWithdrawListSuccess
} from './contact-support-drawer.actions';

@Injectable()
export class ContactSupportDrawerEffects {
  private withdrawFileName = 'Withdraw List.xlsx';
  private withdrawTitle = 'Withdraw List';
  private withdrawExportColumns = [
    { field: 'optOutDate' },
    { field: 'firstName' },
    { field: 'lastName' },
    { field: 'email' },
    { field: 'orgName' },
    { field: 'countryName' },
    { field: 'state' }
  ];

  openDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openContactSupportDrawer),
        map(() => this.supportDrawer.open())
      ),
    {
      dispatch: false
    }
  );

  $exportWithdrawList = createEffect(() =>
    this.actions$.pipe(
      ofType(exportWithdrawList),
      switchMap(() =>
        this.localizationService.localize('withdrawListGenerationStarted', {}, 'navigation').pipe(
          take(1),
          tap(message =>
            this.notificationService.notify(message, {
              type: 'info',
              delay: 3000
            })
          ),
          switchMap(_ =>
            this.profileRepository
              .getWithdrawalList(this.withdrawFileName, this.withdrawTitle, this.withdrawExportColumns)
              .pipe(
                map(fileBlob => exportWithdrawListSuccess({ fileBlob })),
                catchError(_ => of(exportWithdrawListFail()))
              )
          )
        )
      )
    )
  );

  $exportWithdrawListSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportWithdrawListSuccess),
        tap(action => saveFile(this.withdrawFileName, action.fileBlob))
      ),
    { dispatch: false }
  );

  $exportWithdrawListFail = createEffect(
    () =>
      this.actions$.pipe(
        ofType(exportWithdrawListFail),
        switchMap(() =>
          this.localizationService.localize('somethingWentWrong', {}, 'navigation').pipe(
            take(1),
            tap(message =>
              this.notificationService.notify(message, {
                type: 'danger'
              })
            )
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private supportDrawer: ContactSupportDrawer,
    private profileRepository: ProfileRepository,
    private notificationService: NotificationService,
    private localizationService: LocalizationService
  ) {}
}
