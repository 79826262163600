import { Inject, Injectable } from '@angular/core';
import { ActivatedRoutes, ActivatedRouteSnapshotData } from '@capital-access/common/router';
import { BreadcrumbItem } from '../models/breadcrumb-item.model';
import { BREADCRUMB_HOME_ITEM } from '../models/breadcrumb-strategies.token';
import { BreadcrumbConfigProvider } from '../models/config-provider.model';
import { BreadcrumbComponentTagsResolver, BreadcrumbItemsFn, BreadcrumbStrategy } from '../models/strategy.model';
import { BREADCRUMB_ITEMS_PROVIDER_KEY } from './config-items-provider.factory';
import { BREADCRUMB_STRATEGY_PROVIDER_KEY } from './config-strategy-provider.factory';
import { breadcrumbStrategies } from './strategies';

@Injectable({ providedIn: 'root' })
export class BreadcrumbResolver {
  private providers: Record<string, BreadcrumbItemsFn | BreadcrumbStrategy> = {};
  private tagsResolver: BreadcrumbComponentTagsResolver = {
    getItems: tags =>
      (this.providers[tags[BREADCRUMB_ITEMS_PROVIDER_KEY] as string] as BreadcrumbItemsFn) || (() => []),
    getStrategy: tags =>
      (this.providers[tags[BREADCRUMB_STRATEGY_PROVIDER_KEY] as string] as BreadcrumbStrategy) ||
      breadcrumbStrategies.default(this.homePageItem)
  };

  constructor(@Inject(BREADCRUMB_HOME_ITEM) private homePageItem: BreadcrumbItem) {}

  setProviders(providers: Record<string, unknown>[]) {
    for (const provider of providers) {
      for (const key in provider) {
        const item = provider[key];
        if (item instanceof BreadcrumbConfigProvider) {
          this.providers[item.token] = item.config;
        }
      }
    }
  }

  getComponentStrategy(route: ActivatedRouteSnapshotData) {
    const strategy = this.tagsResolver.getStrategy(route.componentTags);
    return (routes: ActivatedRouteSnapshotData[]) => strategy(new ActivatedRoutes(routes), this.tagsResolver);
  }
}
