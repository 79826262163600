import { OidcUserData } from './oidc-user-data.model';
import { UserAuthData } from './user-data.model';

export function mapUserData(data: OidcUserData): UserAuthData {
  return {
    id: data.sub,
    name: data.name,
    familyName: data.family_name,
    givenName: data.given_name,
    email: data.email,
    emailVerified: data.email_verified === '1',
    zoneInfo: data.zoneinfo
  };
}
