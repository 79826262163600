import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import sortBy from 'lodash/sortBy';
import {
  ComplexListData,
  FireflyBaseSuggesterInputs,
  groupBy,
  GroupedData,
  NestedValuePipe
} from '@capital-access/firefly/components';

@Component({
  selector: 'ca-multiple-master-suggester',
  templateUrl: './multiple-master-suggester.component.html',
  styleUrls: ['./multiple-master-suggester.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultipleMasterSuggesterComponent extends FireflyBaseSuggesterInputs implements OnChanges {
  @Input() override items: ComplexListData[] = [];
  @Input() selectedItems: ComplexListData[] = [];
  @Input() selectedItemsCategoryPath = '';
  @Input() masterSuggesterItemTemplate!: TemplateRef<unknown>;
  @Input() selectedItemDeleteBtnTemplate!: TemplateRef<unknown> | null;
  @Input() selectedItemTemplate!: TemplateRef<unknown>;
  @Input() selectedItemsGroupHeaderTemplate!: TemplateRef<unknown>;
  @Input() customSelectedItemTemplate!: TemplateRef<unknown> | null;
  @Input() selectedItemsListClass: string | string[] = 'mt-3';
  @Input() selectedItemRootClass!: string | string[];
  @Input() actionsForCategories: boolean = true;
  @Input() actionsForAllList: boolean = true;
  @Input() maxSelectedItemsCount!: number | null;
  @Input() popoverPlacement = 'bottom-left top-left';
  @Input() popoverAutoClose: boolean | string = 'outside';
  @Input() popoverContainer = '';
  @Input() heading!: string;

  @Output() remove = new EventEmitter();
  @Output() removeMany = new EventEmitter();
  @Output() selectMany = new EventEmitter();
  @Output() itemClick = new EventEmitter<ComplexListData>();

  groupedSelectedData!: GroupedData;

  get hasSelectedItemDeleteBtnTemplate() {
    return this.selectedItemDeleteBtnTemplate instanceof TemplateRef;
  }

  get hasGroupedSelectedData() {
    return (
      this.groupedSelectedData &&
      this.groupedSelectedData.groupedItems[0] &&
      this.groupedSelectedData.groupedItems[0].value.length
    );
  }

  constructor(private nestedValuePipe: NestedValuePipe) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItems?.currentValue || changes.selectedItemsCategoryPath?.currentValue) {
      this.groupSelectedItems();
    }
  }

  onSelect(event: unknown) {
    const item = event as ComplexListData;
    this.selectedItems.push(item);
    this.select.emit(item);
    this.groupSelectedItems();
  }

  onRemove(event: unknown) {
    const item = event as ComplexListData;
    this.selectedItems = this.selectedItems.filter(i => i[this.idField] != item[this.idField]);
    this.remove.emit(item);
    this.groupSelectedItems();
  }

  onSelectMany(event: unknown[]) {
    const items = event as ComplexListData[];
    this.selectedItems = [...this.selectedItems, ...items];
    this.selectMany.emit(items);
    this.groupSelectedItems();
  }

  onRemoveMany(event: unknown) {
    const items = event as ComplexListData[];
    this.selectedItems = this.selectedItems.filter(i => {
      return items.every(item => i[this.idField] !== item[this.idField]);
    });
    this.removeMany.emit(event);
    this.groupSelectedItems();
  }

  private groupSelectedItems() {
    this.groupedSelectedData = groupBy(
      this.selectedItems,
      this.selectedItemsCategoryPath,
      this.nestedValuePipe.transform
    );
    this.groupedSelectedData.groupedItems = sortBy(this.groupedSelectedData.groupedItems, 'name');
  }
}
