import { EMPTY, Observable, OperatorFunction } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

/**
 * When control observable emits truthy value switch to source observable, otherwise emit nothing.
 */
export function when<T>(control: Observable<boolean>): OperatorFunction<T, T> {
  return source =>
    control.pipe(
      switchMap(allowed => (allowed ? source : EMPTY)),
      distinctUntilChanged<T>()
    );
}
