import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { LOCALIZATION_SCOPE } from '@capital-access/ai-panel';
import { CommonLazyLoadingModule } from '@capital-access/common/lazy-loading';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { AiChatButtonComponent } from './ai-chat-button/ai-chat-button.component';
import { AiPanelEffects } from './ai-panel.effects';

// @dynamic
@NgModule({
  imports: [
    CommonModule,
    CommonLazyLoadingModule,
    CommonLocalizationModule.forFeature(LOCALIZATION_SCOPE),
    EffectsModule.forFeature([AiPanelEffects])
  ],
  declarations: [AiChatButtonComponent],
  exports: [AiChatButtonComponent]
})
export class AiPanelLazyModule {}
