import { Component, HostBinding, Input } from '@angular/core';
import { BadgeSize } from '@capital-access/common/utils';

@Component({
  selector: 'ca-base-badge',
  template: ''
})
export class BaseBadgeComponent {
  @Input() size: BadgeSize = BadgeSize.Xs;
  @Input() valueChange!: number;

  @HostBinding('class') class = 'd-inline-block';

  get badgeClass() {
    const badgeType: string = `badge-${this.valueChange > 0 ? 'success' : this.valueChange < 0 ? 'danger' : 'neutral'}`;
    return [badgeType, this.size];
  }

  get isValidValue() {
    return Number.isFinite(this.valueChange);
  }

  get iconClass() {
    if (this.valueChange > 0) {
      return 'f-i-arrow-up ca-up-icon';
    }
    if (this.valueChange < 0) {
      return 'f-i-arrow-down ca-down-icon';
    }
    return 'f-i-line ca-neutral-icon';
  }
}
