import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonRouterModule } from '@capital-access/common/router';
import { AppSettingsService } from '@capital-access/common/settings';
import { PROFILES_SETTINGS, Settings } from '@capital-access/profiles/common';
import { ProfilePreviewLazyLoaderModule } from '@capital-access/profiles/preview/lazy';

export const loadModule = () => import('@capital-access/profiles/client').then(m => m.ProfilesModule);

export function getProfilesSettings(settings: AppSettingsService) {
  return settings.getSettings<Settings>().profiles;
}

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: 'profiles',
          loadChildren: loadModule
        }
      ]
    }),
    ProfilePreviewLazyLoaderModule
  ]
})
export class ProfilesLazyModule {
  static forRoot(): ModuleWithProviders<ProfilesLazyModule> {
    return {
      ngModule: ProfilesLazyModule,
      providers: [
        {
          provide: PROFILES_SETTINGS,
          useFactory: getProfilesSettings,
          deps: [AppSettingsService]
        }
      ]
    };
  }
}
