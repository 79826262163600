import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SimpleActionableEmptyStatesOptions } from './../models/empty-state-options.enum';

@Component({
  selector: 'f-simple-actionable-empty-state',
  templateUrl: './simple-actionable-empty-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySimpleActionableEmptyStateComponent {
  @Input() caption!: string;
  @Input() info!: string;
  @Input() note!: string;
  @Input() simpleActionableOption!: SimpleActionableEmptyStatesOptions;
}
