<div class="ca-one-search-input-wrapper input-group-side-icons">
  <input
    data-automation-id="one-search-input"
    placeholder="{{ 'search-nav.searchInputPlaceholder' | caLocalize }}"
    class="form-control form-control-rounded"
    (input)="onInputChange($event)"
    (focus)="onInputFocus()"
    [value]="searchText$ | async"
  />
  <i class="f-i f-i-md f-i-search"></i>

  <ng-container *ngIf="searchStarted$ | async; then loadingSpinner; else close"></ng-container>
</div>
<ng-template #loadingSpinner>
  <f-spinner data-automation-id="ca-one-search-field-spinner" rootCssClass="spinner-end"></f-spinner>
</ng-template>
<ng-template #close>
  <i
    data-automation-id="one-search-input-close"
    class="f-i f-i-xs f-i-close"
    [class.d-none]="(searchText$ | async) === ''"
    (click)="onCloseClick()"
  ></i>
</ng-template>
