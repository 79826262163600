import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'caKendoTodayClass' })
export class KendoTodayClassPipe implements PipeTransform {
  transform(currentDate: string): string {
    return this.isToday(currentDate) ? 'k-scheduler-today' : '';
  }

  private isToday(currentDate: string): boolean {
    const dateTime = DateTime.fromISO(currentDate);
    return dateTime.hasSame(DateTime.local(), 'day');
  }
}
