<div [caLocalizationScope]="localizationScope">
  <div>
    <span
      class="body-70 link link-primary me-3"
      data-automation-id="select-all"
      (click)="selectAll()"
      (keyup.enter)="selectAll()"
    >
      {{ 'selectAll' | caLocalize }}
    </span>
    <span
      class="body-70 link link-primary"
      data-automation-id="clear-all"
      (click)="clearAll()"
      (keyup.enter)="clearAll()"
    >
      {{ 'clearAll' | caLocalize }}
    </span>
  </div>

  <f-draggable-list [items]="sections" [listItemTemplate]="listItemTemplate" (drop)="drop($event)"></f-draggable-list>
  <ng-template #listItemTemplate let-item>
    <f-list-item-checkbox [checked]="item.isSelected" (toggle)="toggle(item)">{{
      item.localizationKey | caLocalize
    }}</f-list-item-checkbox>
  </ng-template>
</div>
