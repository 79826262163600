import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyLineSeriesModule } from '../common';
import { FireflyChartBoilerplateModule } from '../common/chart-boilerplate/chart-boilerplate.module';
import { FireflyLineModule } from '../common/lines/line/line.module';
import { FireflyPositiveNegativeBarSeriesModule } from '../common/positive-negative-bar-series';
import { FireflyPositiveNegativeBarLineChartComponent } from './positive-negative-bar-line-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FireflyLineModule,
    FireflyPositiveNegativeBarSeriesModule,
    FireflyChartBoilerplateModule,
    FireflyLineSeriesModule
  ],
  declarations: [FireflyPositiveNegativeBarLineChartComponent],
  exports: [FireflyPositiveNegativeBarLineChartComponent]
})
export class FireflyPositiveNegativeBarLineChartModule {}
