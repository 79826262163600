import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FireflyLocalizationService } from '@capital-access/firefly/components';
import { FireflyNumberFormattingService } from '@capital-access/firefly/components';
import { FireflyCommonLocalizationService } from './firefly-common-localization.service';
import { FireflyCommonNumberFormattingService } from './firefly-common-number-formatting.service';

@NgModule({
  imports: [CommonModule]
})
export class FireflyGlobalizationModule {
  static forRoot(): ModuleWithProviders<FireflyGlobalizationModule> {
    return {
      ngModule: FireflyGlobalizationModule,
      providers: [
        {
          provide: FireflyLocalizationService,
          useClass: FireflyCommonLocalizationService
        },
        {
          provide: FireflyNumberFormattingService,
          useClass: FireflyCommonNumberFormattingService
        }
      ]
    };
  }
}
