import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CALENDAR_SYNC_COMMON_KEY } from './constants';
import { CalendarSyncRepository } from './services/calendar-sync.repository';
import { CalendarSyncTransactionRepository } from './services/calendar-sync-transaction.repository';

@NgModule({
  imports: [CommonModule, CommonLocalizationModule.forFeature(CALENDAR_SYNC_COMMON_KEY)],
  providers: [CalendarSyncRepository, CalendarSyncTransactionRepository]
})
export class EventsCalendarSyncCommonModule {}
