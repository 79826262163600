import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { BaseBadgeComponent } from '../base-badge/base-badge.component';

@Component({
  selector: 'ca-trend-badge',
  templateUrl: './trend-badge.component.html',
  styleUrls: ['./trend-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrendBadgeComponent extends BaseBadgeComponent {
  @HostBinding('class.lh-1') lineHeightClass = true;
}
