import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { KendoGlobalizationEffects } from './kendo-globalization.effects';

// @dynamic
@NgModule({
  imports: [EffectsModule.forFeature([KendoGlobalizationEffects])],
  providers: [
    CldrIntlService,
    {
      provide: IntlService,
      useExisting: CldrIntlService
    }
  ]
})
export class KendoGlobalizationLazyModule {}
