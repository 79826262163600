import { InjectionToken } from '@angular/core';

export enum LogLevel {
  Trace = 'Trace',
  Debug = 'Debug',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
  Disable = 'Disable'
}

export type LogObject = string | Record<string, unknown> | unknown;

export interface Logger {
  error(error: LogObject): void;
  warn(warning: LogObject): void;
  info(message: LogObject): void;
  debug(message: LogObject): void;
  trace(message: LogObject): void;
}

export const LOG_LEVEL = new InjectionToken<LogLevel>('Log Level');
export const LOGGER = new InjectionToken<Logger>('Logger');
export const APP_NAME = new InjectionToken<string>('AppName');
