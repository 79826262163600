import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toHttpParamsObject } from '@capital-access/common/utils';
import { PricingRequestModel, PricingResponseItem, TradingInfoOverview, TradingInfoOverviewRequest } from '../models';

@Injectable()
export class SecurityPricingRepository {
  private readonly apiBase = '/api/pricing';

  constructor(private http: HttpClient) {}

  getPricingData(securityId: number, request: PricingRequestModel) {
    return this.http.get<PricingResponseItem[]>(`${this.apiBase}/shares/${securityId}`, {
      params: toHttpParamsObject(request)
    });
  }

  getTradingInfoOverview(securityId: number, request: TradingInfoOverviewRequest) {
    return this.http.get<TradingInfoOverview>(`${this.apiBase}/shares/${securityId}/overview`, {
      params: toHttpParamsObject(request)
    });
  }
}
