import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { LocalizationService } from '@capital-access/common/localization';
import { DrawerPosition, DrawerType, FireflyDrawerService } from '@capital-access/firefly/components';
import { ContactSupportComponent } from './contact-support.component';

@Injectable()
export class ContactSupportDrawer {
  constructor(private drawerService: FireflyDrawerService, private localizationService: LocalizationService) {}

  open() {
    return this.localizationService
      .getLocalization('navigation')
      .pipe(
        map(({ contactSupport }) => {
          return this.drawerService.openDrawer(
            {
              title: contactSupport,
              type: DrawerType.Form,
              position: DrawerPosition.Right
            },
            ContactSupportComponent
          );
        }),
        take(1)
      )
      .subscribe();
  }
}
