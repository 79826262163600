<div [formGroup]="address" [caLocalizationScope]="localizationScope">
  <div class="mb-1" data-automation-id="address">
    <label class="form-label-top">{{ addressTitle }}</label>
    <f-single-suggester
      placeholder="{{ 'addressPlaceholder' | caLocalize }}"
      [items]="(searchAddressItems$ | async)!"
      [inputValue]="inputAddress"
      [valuePath]="'description'"
      [customFilterMode]="true"
      [disabled]="!!disabled"
      [clearUnmatchedQueryOnBlur]="false"
      (updateSearch)="filterSearchAddressItems($event)"
      (select)="onSelectionAddressChange($event)"
      (reset)="resetAddress()"
      (outclick)="onOutClickAddress()"
    >
      <ng-template #fSuggesterItemTemplate let-item="item">
        <span data-automation-id="address-drop-down-item" innerHTML="{{ item.description }}"></span>
      </ng-template>
      <ng-template #fFooterTemplate>
        <div class="powered-by-google-logo"></div>
      </ng-template>
    </f-single-suggester>
  </div>

  <ng-content></ng-content>

  <div data-automation-id="additional-address-information" *ngIf="isAddressDetailsShowed">
    <input
      [ngClass]="{ 'is-invalid': addressLine2?.touched && addressLine2?.invalid }"
      [placeholder]="'addressDetailsPlaceholder' | caLocalize"
      type="text"
      class="form-control form-control-outline"
      formControlName="addressLine2"
      [attr.disabled]="disabled || null"
      autocomplete="new-password"
    />
    <div
      *ngIf="addressLine2?.hasError('maxlength')"
      class="invalid-feedback"
      data-automation-id="addressLine2-limit-validation"
    >
      {{ 'addressDetailsLengthLimit' | caLocalize : { maxLength: maxLength } }}
    </div>
  </div>

  <span
    *ngIf="!isAddressDetailsShowed"
    class="link link-primary d-inline-block mt-2"
    data-automation-id="show-additional-address-information"
    (click)="showAddressDetails()"
  >
    +&nbsp;{{ 'addAddressDetailsTitle' | caLocalize }}
  </span>
</div>
