import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../../common-components.const';
import { PredictionProbabilityBadgeComponent } from './prediction-probability-badge.component';

@NgModule({
  imports: [CommonModule, CommonLocalizationModule.forFeature(COMMON_COMPONENTS_LOCALIZATION_SCOPE)],
  declarations: [PredictionProbabilityBadgeComponent],
  exports: [PredictionProbabilityBadgeComponent]
})
export class PredictionProbabilityBadgeModule {}
