import { createSelector } from '@ngrx/store';
import {
  dictionary,
  getIdsMarkedForReset,
  getSettings,
  JsonPreferenceValueConverter
} from '@capital-access/common/user';
import { ColumnChooserTableSettings } from '@capital-access/firefly/components';

const jsonParseFn = (x: unknown) => (x ? (JSON.parse(x as string) as ColumnChooserTableSettings) : null);

export class TableSettings {
  @dictionary(new JsonPreferenceValueConverter(jsonParseFn), { path: '' })
  settings!: Record<string, ColumnChooserTableSettings>;
}

export const getTableSettings = (path: string) =>
  createSelector(getSettings, settings => settings.getSection(path).toObject(TableSettings));

export const getSettingMarkedForReset = () =>
  createSelector(getIdsMarkedForReset, keys => keys.map(key => ({ id: key, value: null })));
