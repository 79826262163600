<svg:g
  *ngIf="isVisible"
  class="f-bubble-wrapper overflow-visible"
  [attr.width]="bubbleRadius * 2"
  [attr.height]="bubbleRadius * 2"
  [@.disabled]="!animation"
  [@bubbleAnimation]="{
    value: 'active',
    params: { duration: animationDuration, delay: animationDelay }
  }"
>
  <circle class="f-bubble" [attr.r]="radius" [attr.cx]="cx" [attr.cy]="cy" [ngClass]="bubbleClasses"></circle>

  <text
    [attr.x]="cx"
    [attr.y]="cy"
    [ngClass]="textClasses"
    alignment-baseline="middle"
    class="f-bubble-text"
    text-anchor="middle"
  >
    {{ value }}
  </text>
</svg:g>
