import { Type } from '@angular/core';
import { Xor } from '../types';

export const TAGS_KEY = 'ca:tags';

export type TagTarget<T = unknown> = (Record<string, unknown> | Type<T>) & {
  [TAGS_KEY]?: Record<string, unknown>;
};

export interface Tag<T = unknown> {
  key: string;
  descriptor: TagDescriptor<T>;
}

export type TagDescriptor<T> = Xor<{ get: () => T }, { value: T }>;

export class TagAlreadyExists extends Error {
  public override readonly name = 'TagAlreadyExists';
  constructor(target: TagTarget, tagKey: string) {
    super(`${target} already contains ${tagKey}`);
  }
}

/**
 * Add new tag to target
 * @param target
 * @param tag
 * @throws `TagAlreadyExists`
 */
export function addTag(target: TagTarget, tag: Tag) {
  let tags = target[TAGS_KEY];
  if (!tags) {
    tags = {};
    Reflect.defineProperty(target, TAGS_KEY, {
      value: tags,
      writable: false
    });
  } else {
    if (Reflect.has(tags, tag.key)) {
      throw new TagAlreadyExists(target, tag.key);
    }
  }

  const propDescriptor = Reflect.has(tag.descriptor, 'value')
    ? {
        value: tag.descriptor.value,
        writeable: false
      }
    : { get: tag.descriptor.get };

  Reflect.defineProperty(tags, tag.key, propDescriptor);
}

/**
 * Get all tags assigned to target
 * @param target tag target
 * @returns tags dictionary
 */
export function getTags<T = unknown>(target: TagTarget<T> | null): Record<string, unknown> {
  return (target && Reflect.get(target, TAGS_KEY)) || {};
}

/**
 * Try to get specific tag from target
 * returns null if tag is not present
 * @param target tag target
 * @param tagKey tag key
 * @returns tag value or null
 */
export function tryGetTag<T = unknown>(target: TagTarget | null, tagKey: string): T | null {
  const tags = getTags(target);
  if (tags) {
    return (Reflect.get(tags, tagKey) as T) || null;
  }
  return null;
}
