import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActionNavItem, EntityNavItem, UserNavItem } from '@capital-access/common/navigation';
import { NAVIGATION_LOCALIZATION_SCOPE } from '../../../navigation.constants';
import { NAV_TRANSITION_DURATION_FUNC } from '../../constants';

@Component({
  selector: 'ca-action-nav-item',
  templateUrl: './action-nav-item.component.html',
  styleUrls: ['../../side-nav-item.scss', '../../side-nav-item.theme.scss', './action-nav-item.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC)])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionNavItemComponent {
  @Input() navItem!: ActionNavItem | EntityNavItem | UserNavItem;
  @Input() mini!: boolean;
  @Input() set sharedNavItem(isSharedNavItem: boolean) {
    this.localizationScope = isSharedNavItem ? NAVIGATION_LOCALIZATION_SCOPE : '';
  }

  localizationScope = '';

  constructor(private store: Store) {}

  onClick(event: Event): void {
    if (this.mini) {
      event.stopPropagation();
    }

    if (this.navItem.action) {
      this.store.dispatch(this.navItem.action);
    }
  }
}
