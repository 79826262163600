import isUndefined from 'lodash/isUndefined';
import { isNumber, isString } from '../type-checks';
import { EnumObject } from '../types';
import { createUnknownResolver } from './internal/resolve-unknown';

function parseJson<T>(input: string, handle: { onFail: () => T; onSuccess: (result: unknown) => T }): T {
  try {
    return handle.onSuccess(JSON.parse(input));
  } catch {
    return handle.onFail();
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Convert {
  export const toNumber = (unknownValue: unknown) => {
    if (unknownValue === null || isUndefined(unknownValue)) {
      return null;
    }
    const numberResult = new Number(unknownValue).valueOf();
    if (isNaN(numberResult)) {
      return null;
    }
    return numberResult;
  };

  export const toString = createUnknownResolver<string>(builder => {
    builder
      .ifString(stringValue => {
        return parseJson(stringValue, {
          onSuccess: parsed => {
            if (isString(parsed)) {
              return parsed;
            } else {
              return stringValue;
            }
          },
          onFail: () => stringValue
        });
      })
      .ifElse(unknownValue => {
        if (isUndefined(unknownValue) || unknownValue === null) {
          return null;
        }
        const mayBeToString = unknownValue as { toString?: () => string };
        return (mayBeToString.toString && mayBeToString.toString()) || null;
      });
  });

  export const toBoolean = createUnknownResolver<boolean>(builder => {
    builder
      .ifBoolean(boolValue => boolValue)
      .ifString(stringValue => {
        const lowerCased = stringValue.toLowerCase();
        if (lowerCased === 'true') return true;
        if (lowerCased === 'false') return false;
        return null;
      });
  });

  export const toDate = createUnknownResolver<Date>(builder => {
    const fromDate = (x: Date) => (isNaN(x.getTime()) ? null : x);
    builder
      .ifDate(fromDate)
      .ifString(stringValue => {
        const parsedString = parseJson(stringValue, {
          onSuccess: parsed => {
            if (isString(parsed) || isNumber(parsed)) {
              return parsed;
            } else {
              return stringValue;
            }
          },
          onFail: () => stringValue
        });
        return fromDate(new Date(parsedString));
      })
      .ifNumber(numberValue => fromDate(new Date(numberValue)));
  });

  export const toEnum = <T>(enumObj: EnumObject<T>) =>
    createUnknownResolver<T>(builder => {
      const handleNumberValue = (x: number) => {
        // if input number is among enum values then it's an valid enum of T
        if (Object.values<number>(enumObj as unknown as Record<string, number>).includes(x)) {
          return x as unknown as T;
        }
        return null;
      };

      const handleStringValue = (x: string) => {
        const enumValue = (enumObj as Record<string, unknown>)[x];
        if (isNumber(enumValue)) {
          /* if we got number that means that input string is a name of the specific enum value
            and we need to return number value */
          return enumValue as unknown as T;
        }
        /**
         * Otherwise we need to check if input string is one of the enum values.
         */
        if (Object.values(enumObj).includes(x)) {
          return x as unknown as T;
        }
        return null;
      };
      builder
        .ifNumber(numberValue => handleNumberValue(numberValue))
        .ifString(stringValue =>
          parseJson(stringValue, {
            onSuccess: parsed => {
              if (isString(parsed)) {
                return handleStringValue(parsed);
              }
              if (isNumber(parsed)) {
                return handleNumberValue(parsed);
              }
              return null;
            },
            onFail: () => handleStringValue(stringValue)
          })
        );
    });

  export const toArrayOf = <T>(convertElement: (x: unknown) => T | null) =>
    createUnknownResolver<T[]>(builder => {
      const convertElements = (array: unknown[]) => {
        const convertedElements = array.map(x => convertElement(x));
        if (convertedElements.some(x => x === null)) {
          return null;
        }
        return convertedElements as T[];
      };

      builder
        .ifArray(arrayValue => convertElements(arrayValue))
        .ifString(stringValue => {
          const parsedArray = parseJson<unknown[] | null>(stringValue, {
            onSuccess: parsed => {
              if (Array.isArray(parsed)) {
                return parsed;
              } else {
                return null;
              }
            },
            onFail: () => null
          });

          if (parsedArray === null) {
            return null;
          }

          return convertElements(parsedArray);
        });
    });

  export const toNumberArray = Convert.toArrayOf(Convert.toNumber);
  export const toStringArray = Convert.toArrayOf(Convert.toString);
  export const toBooleanArray = Convert.toArrayOf(Convert.toBoolean);
  export const toDateArray = Convert.toArrayOf(Convert.toDate);
  export const toEnumArray = <T>(enumObj: EnumObject<T>) => Convert.toArrayOf(Convert.toEnum(enumObj));
}
