import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { isIphone } from '../utils';

const turnOffZooming = () => {
  const viewportMeta = document.head.querySelector('[name="viewport"]');
  const viewportMetaContent = viewportMeta?.getAttribute('content');
  viewportMeta?.setAttribute('content', `${viewportMetaContent}, maximum-scale=1`);
};

@NgModule({
  imports: [CommonModule]
})
export class FireflyFormsModule {
  static forRoot(): ModuleWithProviders<FireflyFormsModule> {
    // Temporary fix for zooming issues on iOS devices (CPD-1266)
    if (isIphone()) turnOffZooming();

    return { ngModule: FireflyFormsModule };
  }
}
