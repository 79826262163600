import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {
  MaybeArray,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_SCOPE,
  translocoConfig,
  TranslocoModule,
  TranslocoPipe,
  TranslocoScope
} from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { fallbackLang } from '@capital-access/common/globalization';
import { provideChildRoutesGuard } from '@capital-access/common/router';
import { CommonLocalizationEffects } from './+store/localization.effects';
import { LocalizationScopeDirective } from './directive/localization-scope.directive';
import { LocalizePipe } from './pipes/localize.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { LocalizationLoadGuard } from './services/localization-load.guard';
import { LocalizationScopesLoadQueue } from './services/localization-scopes-load.queue';
import { TranslocoHttpLoader } from './transloco.loader';

export interface LocalizationConfig {
  enableProdMode: boolean;
}

@NgModule({
  imports: [TranslocoModule, EffectsModule.forFeature([CommonLocalizationEffects])]
})
export class CommonLocalizationRootModule {}

@NgModule({
  imports: [CommonModule],
  declarations: [LocalizationScopeDirective, LocalizePipe, PluralizePipe],
  providers: [TranslocoPipe],
  exports: [LocalizationScopeDirective, LocalizePipe, PluralizePipe]
})
export class CommonLocalizationModule {
  public static forRoot({ enableProdMode }: LocalizationConfig): ModuleWithProviders<CommonLocalizationRootModule> {
    return {
      ngModule: CommonLocalizationRootModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            defaultLang: fallbackLang, // explicitly specify default value instead of relying on default transloco config
            availableLangs: [fallbackLang], // Default lang should be available immediately in case if localization pipe will execute before actual language loaded
            reRenderOnLangChange: true,
            prodMode: enableProdMode,
            failedRetries: 0
          })
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        provideChildRoutesGuard(LocalizationLoadGuard)
      ]
    };
  }

  public static forFeature(scope: string): ModuleWithProviders<CommonLocalizationModule> {
    return {
      ngModule: CommonLocalizationModule,
      providers: [
        {
          provide: TRANSLOCO_SCOPE,
          /**
           * use alias to provide scope in templates the same way it is provided in feature.
           * If not provided, by default Transloco would convert it to camelCase.
           */
          useValue: { scope: scope, alias: scope },
          multi: true
        }
      ]
    };
  }

  constructor(
    scopesLoadQueue: LocalizationScopesLoadQueue,
    @Inject(TRANSLOCO_SCOPE) scopes: MaybeArray<TranslocoScope>
  ) {
    // queue module localization scopes for loading with respect to lazy modules
    scopesLoadQueue.queue(scopes);
  }
}
