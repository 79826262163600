import { Component, Inject, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { logout } from '@capital-access/common/actions';
import { LocalizedString } from '@capital-access/common/localization';
import { SystemSettingsService } from '@capital-access/common/settings';
import { saveLicenseAgreementPreferences } from '../+store/actions';
import { LICENSE_AGREEMENT_LOCALIZATION_SCOPE, LICENSE_AGREEMENT_PREFERENCE_SECTION } from '../models/constants';

@Component({
  selector: 'ca-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss']
})
export class LicenseAgreementComponent {
  localizationScope = LICENSE_AGREEMENT_LOCALIZATION_SCOPE;
  applicationName = new LocalizedString('applicationTitle');
  settings = this.settingsService.getSettings().licenseAgreement;

  constructor(
    @Optional() private store: Store,
    private settingsService: SystemSettingsService,
    @Optional() @Inject(LICENSE_AGREEMENT_PREFERENCE_SECTION) private preferenceSection: string
  ) {}

  accept() {
    if (!this.preferenceSection) return;
    this.store?.dispatch(saveLicenseAgreementPreferences(this.preferenceSection, { trialAccepted: true }));
  }

  decline() {
    if (this.preferenceSection) {
      this.store?.dispatch(saveLicenseAgreementPreferences(this.preferenceSection, { trialAccepted: false }));
    }
    this.store?.dispatch(logout());
  }
}
