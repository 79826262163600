import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../../popovers/popover.module';
import { FireflyStackedBarComponent } from './stacked-bar.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule],
  declarations: [FireflyStackedBarComponent],
  exports: [FireflyStackedBarComponent]
})
export class FireflyStackedBarModule {}
