import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EMPTY, ReplaySubject } from 'rxjs';
import { bufferTime, catchError, filter, mergeMap, retry } from 'rxjs/operators';
import { APP_NAME, Logger, LogLevel, LogObject } from '../logger.model';

const MAX_BUFFER_COUNT = 10;
const BUFFER_TIME_SPAN = 1000;
const RETRY_TIME_DELAY = 1000;
const RETRY_COUNT = 2;

interface LogEvent {
  logLevel: LogLevel;
  message: string;
  timestamp: Date;
  applicationName: string;
}

@Injectable()
export class HttpLogger implements Logger {
  private readonly serverLoggingUrl = '/api/logging';
  private logs = new ReplaySubject<LogEvent>();

  constructor(private http: HttpClient, @Optional() @Inject(APP_NAME) private appName: string) {
    this.logs
      .asObservable()
      .pipe(
        bufferTime(BUFFER_TIME_SPAN, null, MAX_BUFFER_COUNT),
        filter(logs => logs.length > 0),
        mergeMap(logs =>
          this.http.post(`${this.serverLoggingUrl}`, logs).pipe(
            retry({ delay: RETRY_TIME_DELAY, count: RETRY_COUNT }),
            catchError(() => EMPTY)
          )
        )
      )
      .subscribe();
  }

  error(error: LogObject) {
    this.logs.next({
      logLevel: LogLevel.Error,
      message: this.toString(error),
      applicationName: this.appName,
      timestamp: new Date()
    });
  }

  warn(warning: LogObject) {
    this.logs.next({
      logLevel: LogLevel.Warning,
      message: this.toString(warning),
      applicationName: this.appName,
      timestamp: new Date()
    });
  }

  info(message: LogObject) {
    this.logs.next({
      logLevel: LogLevel.Information,
      message: this.toString(message),
      applicationName: this.appName,
      timestamp: new Date()
    });
  }

  debug(message: LogObject) {
    this.logs.next({
      logLevel: LogLevel.Debug,
      message: this.toString(message),
      applicationName: this.appName,
      timestamp: new Date()
    });
  }

  trace(message: LogObject) {
    this.logs.next({
      logLevel: LogLevel.Trace,
      message: this.toString(message),
      applicationName: this.appName,
      timestamp: new Date()
    });
  }

  private toString(error: unknown): string {
    if (this.isString(error)) {
      return `Url: ${window.location.href}; Message: ${error}`;
    }

    if (error instanceof Error) {
      const { message, name, stack } = error;
      return `Url: ${window.location.href}; Message: ${message}; Name: ${name}; Stacktrace: ${stack}`;
    }

    return `Url: ${window.location.href}; Message: ${JSON.stringify(error)}`;
  }

  private isString(arg: unknown): arg is string {
    return typeof arg === 'string' || arg instanceof String;
  }
}
