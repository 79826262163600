import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySpinnerComponent } from './spinner.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflySpinnerComponent],
  exports: [FireflySpinnerComponent]
})
export class FireflySpinnerModule {}
