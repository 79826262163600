<div
  [ngbPopover]="popover"
  [container]="'body'"
  [placement]="popoverPlacement"
  [triggers]="popoverTriggers"
  [ngClass]="containerClass"
  [ngStyle]="{ 'height.px': thickness }"
  class="f-bar-container position-relative"
  data-automation-id="chart-container"
>
  <div
    [@.disabled]="!animation"
    [@expandBar]="{
      value: animationState,
      params: { width: percentage, duration: animationDuration, delay: animationDelay }
    }"
    class="f-bar position-absolute"
    data-automation-id="chart-item"
    [ngClass]="barClass"
  ></div>

  <div class="f-bar-data-container position-relative">
    <span
      *ngIf="name"
      data-automation-id="asset-title-list-item"
      class="f-bar-name"
      [ngClass]="nameClass"
      [title]="name"
      >{{ name }}</span
    >
    <span *ngIf="value" data-automation-id="asset-value-list-item" class="f-bar-value ms-auto" [ngClass]="valueClass">{{
      value
    }}</span>
    <i [ngClass]="iconClass" data-automation-id="chart-icon-list-item"></i>
  </div>
</div>
