export const onNotificationQuery = /* GraphQL */ `
  subscription {
    onNotification {
      id
      level
      payload
      publishTime
      type
    }
  }
`;

export const submitJobQuery = /* GraphQL */ `
  subscription ($jobRequest: SubmitJobRequest!) {
    submitJob(request: $jobRequest) {
      id
      type
      status
      publishTime
      data
    }
  }
`;

export const getNotificationsQuery = /* GraphQL */ `
  query ($request: NotificationsRequest) {
    notifications(request: $request) {
      id
      level
      payload
      publishTime
      type
    }
  }
`;
