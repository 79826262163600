import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ICON_SETTINGS, IconSettingsService } from '@progress/kendo-angular-icons';
import { KendoIconsService } from './kendo-icons.service';

@NgModule({
  imports: [CommonModule]
})
export class FireflyKendoIconsModule {
  static forRoot(): ModuleWithProviders<FireflyKendoIconsModule> {
    return {
      ngModule: FireflyKendoIconsModule,
      providers: [
        { provide: ICON_SETTINGS, useValue: { type: 'svg' } },
        { provide: IconSettingsService, useClass: KendoIconsService }
      ]
    };
  }
}
