import { Pipe, PipeTransform } from '@angular/core';
import { MILLIONS_SYMBOL } from '../common-formatting.constants';

@Pipe({
  name: 'caMmSymbol'
})
export class MillionsSymbolPipe implements PipeTransform {
  transform(value: string | null | undefined) {
    if (value === null || value === undefined) {
      return '';
    }
    return `${value}, ${MILLIONS_SYMBOL}`;
  }
}
