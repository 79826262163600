import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { MAX_REPORT_PROFILES_COUNT } from '../constants';

@Pipe({ name: 'caIsProfileReportingDisabled' })
export class IsProfileReportingDisabledPipe extends BaseAsyncPipe<boolean, number | null> implements PipeTransform {
  constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
  getTransformer(count: number | null): Observable<boolean> {
    if (count === null || (count > 0 && count <= MAX_REPORT_PROFILES_COUNT)) return of(false);
    return of(true);
  }
}
