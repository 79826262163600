import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import type { ScaleLinear } from 'd3-scale';
import { getTicks } from './axis-ticks.helper';
import { truncateLabel } from './trim-label.helper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-charts-y-axis-ticks]',
  template: `
    <svg:g #ticksElement *ngIf="showTicksLabels">
      <g
        *ngFor="let tick of tickLabels"
        class="f-tick"
        data-automation-id="y-axis-tick"
        [attr.transform]="transform(tick)"
      >
        <title>{{ tickFormat(tick) }}</title>
        <text [attr.dy]="dy" [attr.x]="x1" [attr.y]="y1" [attr.text-anchor]="textAnchor">
          {{ tickTrim(tickFormat(tick)) }}
        </text>
      </g>
    </svg:g>

    <svg:g *ngIf="showGridLines">
      <ng-container *ngFor="let tick of tickLabels">
        <line
          [attr.transform]="transform(tick)"
          [attr.x2]="position === 'right' ? -gridLineWidth : gridLineWidth"
          class="f-grid-line f-grid-line-horizontal"
          [class.f-axis-zero-line]="$any(tick == 0)"
          x1="0"
        />
      </ng-container>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyYAxisTicksComponent implements OnChanges, AfterViewInit {
  @Input() ticks!: number[];
  @Input() integerLabels = false;
  @Input() scale!: ScaleLinear<number, number>;
  @Input() position!: 'left' | 'right';
  @Input() tickFormatting!: (d: unknown) => string;
  @Input() truncateTicks!: boolean;
  @Input() maxTickLength!: number;
  @Input() maxTicksCount!: number;
  @Input() showGridLines = false;
  @Input() showTicksLabels = true;
  @Input() gridLineWidth!: number;
  @Input() height!: number;
  @Input() condensedAxisOnMobile = true;
  @Input() condensedOnDesktop = false;

  @Output() dimensionsChanged = new EventEmitter();

  transform!: (d: number) => string;
  tickFormat!: (d: number) => string;
  innerTickSize = 6;
  tickPadding = 3;
  tickSpacing!: number;
  textAnchor = 'middle';
  tickLabels!: number[];
  width = 0;
  dy!: string;
  x1!: number;
  x2!: number;
  y1!: number;
  y2!: number;

  @ViewChild('ticksElement') ticksElement!: ElementRef;

  ngOnChanges() {
    this.update();
  }

  ngAfterViewInit() {
    window.requestAnimationFrame(() => this.updateDims());
  }

  updateDims() {
    if (!this.showTicksLabels) {
      this.dimensionsChanged.emit({ width: this.width });
      return;
    }

    const width = parseInt(this.ticksElement.nativeElement.getBoundingClientRect().width, 10);

    if (width !== this.width) {
      this.width = width;
      this.dimensionsChanged.emit({ width: this.width + this.tickSpacing });
    }
  }

  update() {
    const sign = this.position === 'left' ? -1 : 1;
    this.tickSpacing = Math.max(this.innerTickSize, 0) + this.tickPadding;
    this.tickLabels = this.ticks?.length ? this.ticks : getTicks(this.scale, this.maxTicksCount, this.integerLabels);

    if (this.tickFormatting) {
      this.tickFormat = this.tickFormatting;
    } else if (this.scale.tickFormat) {
      this.tickFormat = this.integerLabels
        ? (v: number) => `${v.toFixed(0)}`
        : this.scale.tickFormat(this.maxTicksCount);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.tickFormat = function (d: any) {
        if (d.constructor.name === 'Date') {
          return d.toLocaleDateString();
        }
        return d.toLocaleString();
      };
    }

    this.transform = (tick: number) => {
      return 'translate(0,' + this.scale(tick) + ')';
    };

    if (this.position === 'left') {
      this.textAnchor = 'end';
      this.x2 = this.innerTickSize * sign;
      this.x1 = this.tickSpacing * sign;
      this.dy = '.32em';
    } else {
      this.textAnchor = 'start';
      this.x2 = this.innerTickSize * sign;
      this.x1 = this.tickSpacing * sign;
      this.dy = '.32em';
    }

    window.requestAnimationFrame(() => this.updateDims());
  }

  tickTrim(label: string): string {
    return this.truncateTicks ? truncateLabel(label, this.maxTickLength) : label;
  }
}
