import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SimpleEmptyStatesOptions } from './../models/empty-state-options.enum';

@Component({
  selector: 'f-simple-empty-state',
  templateUrl: './simple-empty-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySimpleEmptyStateComponent {
  @Input() caption!: string;
  @Input() simpleOption!: SimpleEmptyStatesOptions;
  @Input() isHorizontal: boolean = false;
  @Input() isFlexiblePlacement: boolean = false;
  @Input() rootCssClass: string = '';

  get alignmentClass() {
    return this.isHorizontal ? 'horizontal' : '';
  }

  get flexiblePlacementClass() {
    return this.isFlexiblePlacement ? 'flexible' : '';
  }
}
