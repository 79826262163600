import { getBrowserCulture } from '@capital-access/common/utils';
import { CultureCode, fallbackCulture } from '../constants';

export function resolveCulture(preferenceValue: string | null, supportedCultures: readonly CultureCode[]): CultureCode {
  const preferenceCultureCode = preferenceValue as CultureCode;

  if (preferenceCultureCode && supportedCultures.includes(preferenceCultureCode)) {
    return preferenceCultureCode;
  }

  const browserCulture = getBrowserCulture() as CultureCode;

  if (browserCulture && supportedCultures.includes(browserCulture)) {
    return browserCulture;
  }

  return fallbackCulture;
}
