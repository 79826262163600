import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CalendarComponent, CalendarView } from '@progress/kendo-angular-dateinputs';
import { SchedulerView } from '@progress/kendo-angular-scheduler';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'f-mobile-calendar',
  templateUrl: './mobile-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMobileCalendarComponent implements AfterViewInit, OnDestroy, OnChanges {
  private destroyed$ = new Subject<void>();
  private swipeStartX = 0;
  private swipeEndX = 0;

  @Input() isHidden = false;
  @Input() isCollapsed = true;
  @Input() selectedDate!: Date;
  @Input() schedulerViewName?: SchedulerView['name'];
  @Input() disabledDates = (date: Date): boolean => {
    const day = date.getDay();
    return this.isMonthView ? false : day === 6 || day === 0;
  };

  @Output() selectedDateChange = new EventEmitter();

  @ViewChild(CalendarComponent) calendar!: CalendarComponent;
  @ViewChild('mobileCalendarContainer', { static: true }) mobileCalendarContainer!: ElementRef;

  get activeView(): CalendarView {
    return this.isMonthView ? 'year' : 'month';
  }

  private get isMonthView(): boolean {
    return this.schedulerViewName === 'month';
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.handleMobileCalendarSwipe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate?.currentValue && !this.isHidden && this.isCollapsed) {
      requestAnimationFrame(() => this.alignSelectedCalendarRow());
    }
  }

  private getCalendarScrollable(): HTMLElement | null {
    const scrollableSelector = '.f-mobile-calendar .k-calendar-view';
    return this.mobileCalendarContainer.nativeElement.querySelector(scrollableSelector);
  }

  toggleMobileCalendar() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.alignSelectedCalendarRow();
    }
  }

  collapseMobileCalendar() {
    this.isCollapsed = true;
    this.cdr.detectChanges();
    this.alignSelectedCalendarRow();
  }

  alignSelectedCalendarRow() {
    const scrollable = this.getCalendarScrollable();
    if (!scrollable) return;

    const selectedCell = scrollable.querySelector('.k-selected');
    if (!selectedCell) return;

    if (this.isMonthView) {
      const cells = Array.from(scrollable.querySelectorAll('.k-calendar-td'));
      const middleIndex = cells.length / 2;
      const [cellsToShow, cellsToHide] =
        this.selectedDate.getMonth() < 6
          ? [cells.slice(0, middleIndex), cells.slice(middleIndex)]
          : [cells.slice(middleIndex), cells.slice(0, middleIndex)];

      cellsToHide.forEach(cell => cell.classList.add('k-hidden-cell'));
      cellsToShow.forEach(cell => cell.classList.remove('k-hidden-cell'));
    } else {
      const selectedCellRow = selectedCell.closest('.k-calendar-tr');
      Array.from(scrollable.querySelectorAll('.k-selected-row'))
        .filter(row => row !== selectedCellRow)
        .forEach(row => row.classList.remove('k-selected-row'));

      selectedCellRow?.classList.add('k-selected-row');
    }
  }

  private handleTouchStart(event: TouchEvent) {
    this.swipeStartX = event.touches[0].clientX;
  }

  private handleTouchMove(event: TouchEvent) {
    this.swipeEndX = event.touches[0].clientX;
  }

  private handleTouchEnd() {
    if (!this.swipeEndX) return;
    const query = this.swipeEndX > this.swipeStartX ? '.k-calendar-nav-prev' : '.k-calendar-nav-next';
    const btn = this.mobileCalendarContainer.nativeElement.querySelector(query);
    this.swipeStartX = 0;
    this.swipeEndX = 0;
    btn?.click();
  }

  private handleMobileCalendarSwipe() {
    const scrollable = this.getCalendarScrollable();

    if (!scrollable) return;

    fromEvent(scrollable, 'touchstart')
      .pipe(
        filter(() => !this.isCollapsed),
        takeUntil(this.destroyed$)
      )
      .subscribe(e => this.handleTouchStart(e as TouchEvent));

    fromEvent(scrollable, 'touchmove')
      .pipe(
        filter(() => !this.isCollapsed),
        takeUntil(this.destroyed$)
      )
      .subscribe(e => this.handleTouchMove(e as TouchEvent));

    fromEvent(scrollable, 'touchend')
      .pipe(
        filter(() => !this.isCollapsed),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.handleTouchEnd());
  }
}
