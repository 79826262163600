import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubNavItem } from './sub-nav-item.model';

const subNavWidth = 240;

@Component({
  selector: 'ca-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss', './sub-nav.theme.snp.scss', './sub-nav.theme.bny.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [
        style({ width: 0, opacity: 0 }),
        animate('0.2s ease-out', style({ width: subNavWidth, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ width: subNavWidth, opacity: 1 }),
        animate('0.2s ease-in', style({ width: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class SubNavComponent {
  @Input() items: SubNavItem[] | undefined;
  @Input() collapsible: boolean = false;

  showSubNav = true;

  toggleSubNav() {
    this.showSubNav = !this.showSubNav;
  }

  trackByFn(_index: number, item: SubNavItem) {
    return item.name;
  }
}
