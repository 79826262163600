export interface LayoutChangeEventData {
  oldIndex: number;
  newIndex: number;
  positions: [];
}

export interface LayoutItemPosition {
  id: string;
  position: number;
}

export class LayoutOptions {
  overlapPercent = 40; // item has overlap another item by this percent to switch places
  overlapRenderDelay = 20; // delay before overlap calculation. This is used to make movement smoother and prevent accidential flickering. Adjust this if you need faster switch time
  transitionDuration = 400; // animation duration
  easing = 'easeSinIn'; //d3 easing function name
  scroll = true; // flag to enable\disable auto scrolling
  scrollDistance = 10; // defines number of pixels to scroll by each time scroll event called
  scrollOverlapDelay = 500; // when scroll is stuck (we are at the top or at the bottom of the screen) we wait this amount of time in ms before launching switch
  scrollAreaPercent = 5; // percent of screen area in which you can scroll. For example it's 5. Then if you're in lower 5 percent of screen it will start to scroll down automatically
  scrollParent: HTMLElement | Window | null = window;
  dragSelector = '.ca-drag-header'; // selector for drag element. If not defined entire dom element is drag element
  dragPreventSelector = 'a, a > *, input, select, select > option, button, button > *'; // selector for elements that shouldn't be dragged
  onPositionChange: ((data: LayoutChangeEventData) => void) | null = null; //position change callback
}
