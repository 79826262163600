import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { getFeatureTogglesLoaded } from '@capital-access/common/feature-toggle';
import { getAvailableSecurityIds, getIsUserSettingsLoaded, saveSettings } from '@capital-access/common/user';
import { when } from '@capital-access/common/utils';
import { APPLICATION_SURVEILLANCE_KEY } from '../+state/preferences/preferences.keys';
import { getSecuritySettings, getSurveillanceSettings } from '../+state/preferences/preferences.selectors';
import { saveApplicationSelectedSecurityPreference } from '../default-selected-security/+state/actions';
import { getRawApplicationSelectedSecuritySettings } from '../default-selected-security/+state/selectors';
import { resolveApplicationSelectedSecurityId } from '../default-selected-security/selected-security.resolver';
import { toSelectedSecurityId } from '../default-selected-security/utils';
import { SurveillanceSettings } from '../models/surveillance-settings';
import { setSelectedSecurityId, setSelectedSecuritySurveillance, toggleSurveillance } from './+state/actions';
import { getSelectedSecurityId, getSelectedSecuritySurveillance } from './+state/selectors';

@Injectable()
export class SelectedSecurityEffects {
  initSelectedSecurity$ = createEffect(() =>
    combineLatest([
      this.store.select(getRawApplicationSelectedSecuritySettings),
      this.store.select(getSecuritySettings),
      this.store.select(getAvailableSecurityIds)
    ]).pipe(
      map(([selectedSecuritySettings, securitySettings, availableSecurityIds]) =>
        resolveApplicationSelectedSecurityId(selectedSecuritySettings, securitySettings, availableSecurityIds)
      ),
      withLatestFrom(this.store.select(getSelectedSecurityId)),
      filter(([resolved, selectedSecurityId]) => !!resolved && resolved !== selectedSecurityId),
      map(([resolved]) => setSelectedSecurityId({ selectedSecurityId: resolved! })),
      when(
        combineLatest([this.store.select(getIsUserSettingsLoaded), this.store.select(getFeatureTogglesLoaded)]).pipe(
          map(([userSettingsLoaded, togglesLoaded]) => userSettingsLoaded && togglesLoaded)
        )
      )
    )
  );

  setSelectedSecurity$ = createEffect(() =>
    this.actions.pipe(
      ofType(setSelectedSecurityId),
      withLatestFrom(this.store.select(getRawApplicationSelectedSecuritySettings)),
      filter(([{ selectedSecurityId }, settingsValue]) => selectedSecurityId !== toSelectedSecurityId(settingsValue)),
      map(([{ selectedSecurityId }]) => saveApplicationSelectedSecurityPreference({ id: selectedSecurityId }))
    )
  );

  initSelectedSecuritySurveillance$ = createEffect(() =>
    this.store.pipe(
      select(getSurveillanceSettings),
      when(this.store.select(getIsUserSettingsLoaded)),
      map(({ enabled }) => setSelectedSecuritySurveillance({ enabled: enabled }))
    )
  );

  setSelectedSecuritySurveillance$ = createEffect(() =>
    this.actions.pipe(
      ofType(toggleSurveillance),
      withLatestFrom(this.store.select(getSelectedSecuritySurveillance)),
      map(([, enabled]) =>
        saveSettings(SurveillanceSettings, {
          section: APPLICATION_SURVEILLANCE_KEY,
          data: { enabled }
        })
      )
    )
  );

  constructor(private actions: Actions, private store: Store) {}
}
