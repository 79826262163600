import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RedirectService } from './redirect.service';
import { ScheduleRedirectDirective } from './schedule-redirect.directive';

@NgModule({
  declarations: [ScheduleRedirectDirective],
  imports: [CommonModule],
  providers: [RedirectService],
  exports: [ScheduleRedirectDirective]
})
export class ScheduleRedirectModule {}
