import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SearchCategory, SearchEntity } from '../../../+state/models';
import { filterChanged, selectEntity, unselectEntity } from '../../../+state/search.actions';
import * as searchSelectors from '../../../+state/search.selectors';

export abstract class SearchItemsListBaseComponent<T extends SearchEntity> {
  constructor(protected store: Store, category: SearchCategory) {
    const itemsSelector = searchSelectors.getSubsetSelector<T>(category);
    this.items$ = this.store.select(itemsSelector);
    const showItemsSelector = searchSelectors.getShowSubsetSelector(category);
    this.showItems$ = this.store.select(showItemsSelector);
    const isSubsetSearchFailedSelector = searchSelectors.getIsSubsetSearchFailedSelector(category);
    this.isItemsSearchFailed$ = this.store.select(isSubsetSearchFailedSelector);

    this.hasMore$ = this.store.select(searchSelectors.getHasMoreSelector(category));
  }

  currentCriteria$ = this.store.select(searchSelectors.getCurrentCriteria);

  items$: Observable<Array<T>>;
  showItems$: Observable<boolean>;
  isItemsSearchFailed$: Observable<boolean>;
  hasMore$: Observable<boolean>;

  public categories = SearchCategory;

  abstract trackByMethod(index: number, item: T): string;

  updateSelection(entity: SearchEntity) {
    if (entity.isSelected) {
      this.store.dispatch(unselectEntity({ entity }));
    } else {
      this.store.dispatch(selectEntity({ entity }));
    }
  }

  showMore(category: SearchCategory) {
    this.store.dispatch(filterChanged({ filter: category }));
  }
}
