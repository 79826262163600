<div class="d-flex mt-2" [caLocalizationScope]="localizationScope">
  <i class="f-i f-i-sm {{ icon }} me-3 text-muted" data-automation-id="icon" [title]="iconTitle"></i>
  <div *ngIf="address && address.address; else noAddress">
    <span data-automation-id="main-value">{{ address.address }}</span>
    <div *ngIf="address.addressCountry || address.showEditedIcon" class="d-inline-block">
      <f-badge-flag
        *ngIf="address.addressCountry | caCountryFlagCode as countryCode"
        data-automation-id="country-flag"
        class="ms-1"
        [countryCode]="countryCode"
      ></f-badge-flag>

      <i
        *ngIf="address.showEditedIcon"
        class="f-i f-i-sm f-i-crm ms-1 text-muted align-bottom"
        data-automation-id="crm-icon"
        title="{{ 'editedIconTooltipTitle' | caLocalize }}"
      ></i>
    </div>
  </div>

  <ng-template #noAddress>
    <div class="d-flex text-muted" data-automation-id="no-value">
      {{ noDataTitle }}
    </div>
  </ng-template>
</div>
