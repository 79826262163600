import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './reducer';
import { GLOBALIZATION_CURRENCY_FEATURE_KEY } from './state';

const getCurrencyState = createFeatureSelector<State>(GLOBALIZATION_CURRENCY_FEATURE_KEY);
const getSupportedCurrenciesState = createSelector(getCurrencyState, x => x.availableCurrencies);

export const getSupportedCurrencies = createSelector(getSupportedCurrenciesState, x => x?.data || []);
export const getSupportedCurrenciesCodes = createSelector(getSupportedCurrencies, data => data.map(x => x.code));
export const getSupportedCurrenciesLoadStatus = createSelector(getCurrencyState, x => x.loadStatus);
