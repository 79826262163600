import { createAction, props } from '@ngrx/store';

export const setSelectedSecurityId = createAction(
  '[Common][Security] Set Selected Security Id',
  props<{ selectedSecurityId: number }>()
);

export const setSelectedSecuritySurveillance = createAction(
  '[Common][Security] Set Selected Security Surveillance',
  props<{ enabled: boolean }>()
);

export const toggleSurveillance = createAction('[Common][Security] Toggle Selected Security Surveillance');
