import { Directive, Renderer2, ViewContainerRef } from '@angular/core';
import { FireflyDrawerService } from '../services/drawer.service';

@Directive({
  selector: '[fDrawerDefaultContainer]'
})
export class FireflyDrawerDefaultContainerDirective {
  constructor(viewContainer: ViewContainerRef, drawerService: FireflyDrawerService, renderer: Renderer2) {
    const parent = viewContainer.element.nativeElement.parentElement;
    renderer.setStyle(parent, 'position', 'relative');
    drawerService.setDefaultContainer(viewContainer);
  }
}
