import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyLineModule } from '../line/line.module';
import { FireflyLineSeriesComponent } from './line-series.component';

@NgModule({
  imports: [CommonModule, FireflyLineModule],
  declarations: [FireflyLineSeriesComponent],
  exports: [FireflyLineSeriesComponent]
})
export class FireflyLineSeriesModule {}
