import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { requestAskResearch } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { LOAD_REQUEST_FORM_MODULE, REQUEST_FORM_MODULE_ID } from '../loader-module-id.model';

@Injectable()
export class RequestFormLoaderEffects {
  requestAskResearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(requestAskResearch),
        switchMap(() =>
          this.commonLazyLoadingService
            .loadModule(REQUEST_FORM_MODULE_ID, LOAD_REQUEST_FORM_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showRequestForm()))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
