import { createAction, props } from '@ngrx/store';

export const openInstitutionPreview = createAction(
  scoped`Open Institution`,
  props<{
    id: { prId?: number | null; crmId?: number | null };
    backButtonPreviewDrawer?: boolean;
    initiatorDrawer?: 'Add-Institution' | 'Edit-Institution';
    name?: string;
  }>()
);

export const openContactPreview = createAction(
  scoped`Open Contact`,
  props<{
    id: { prId?: number | null; crmId?: number | null };
    backButtonPreviewDrawer?: boolean;
    initiatorDrawer?: 'Add-Contact' | 'Edit-Contact';
    name?: string;
  }>()
);

export const openFundPreview = createAction(
  scoped`Open Fund`,
  props<{
    id: { prId?: number | null; crmId?: number | null };
    name?: string;
  }>()
);

function scoped(templateString: TemplateStringsArray) {
  return '[Common Profile Preview] ' + templateString[0];
}
