export enum SkeletonLoaderSize {
  Default = '',
  Body30 = 'body-30',
  Body40 = 'body-40',
  Body50 = 'body-50',
  Body70 = 'body-70',
  Subtitle50 = 'subtitle-50',
  Heading20 = 'heading-20',
  Heading30 = 'heading-30',
  Heading40 = 'heading-40',
  Heading50 = 'heading-50',
  Heading70 = 'heading-70'
}

export enum SkeletonLoaderShade {
  Light = 'k-skeleton-light',
  Dark = 'k-skeleton-dark'
}
