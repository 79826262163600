import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { LOG_LEVEL, LOGGER, Logger, LogLevel, LogObject } from '../lib/logger.model';
import { Log } from './log';

const logByLevel = {
  [LogLevel.Error]: (logger: Logger, message: LogObject) => logger.error(message),
  [LogLevel.Warning]: (logger: Logger, message: LogObject) => logger.warn(message),
  [LogLevel.Information]: (logger: Logger, message: LogObject) => logger.info(message),
  [LogLevel.Debug]: (logger: Logger, message: LogObject) => logger.debug(message),
  [LogLevel.Trace]: (logger: Logger, message: LogObject) => logger.trace(message)
};

export function logServiceInitFactory(logService: LogService) {
  return () => logService.init();
}

@Injectable({ providedIn: 'root' })
export class LogService implements OnDestroy {
  private readonly logLevels = Object.values(LogLevel);
  private subscription: Subscription | null = null;

  constructor(@Inject(LOG_LEVEL) private logLevel: LogLevel, @Optional() @Inject(LOGGER) private loggers: Logger[]) {
    if (!this.loggers) loggers = [];
  }

  init() {
    this.subscription = Log.logs$.subscribe(({ level, message }) => this.log(level, message));
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private log(logLevel: Exclude<LogLevel, LogLevel.Disable>, message: LogObject) {
    if (this.logLevels.indexOf(logLevel) >= this.logLevels.indexOf(this.logLevel)) {
      this.loggers.forEach(logger => logByLevel[logLevel](logger, message));
    }
  }
}
