import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  inject,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ColumnComponent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FireflyColumnChooserService } from '../../column-chooser/column-chooser.service';
import { Breakpoint, isSafari, tableMedia } from '../../utils';

@Component({
  template: '',
  standalone: true
})
export abstract class FireflySharedGridComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild(GridComponent, { static: true }) grid!: GridComponent;

  // workaround for IOS v17: show border for the first table row after data input changes
  // (GridComponent does not emit event on data input change)
  @HostBinding('class.k-table-safari') iosWholeTable = false;

  mediaMaxWidth = tableMedia.mediaMaxWidthForMobile;
  mediaMinWidth = tableMedia.mediaMinWidthForTabletAndDesktop;
  showAll: Record<number, boolean> = [];
  expandedRow: number | null = null;
  expandedDetailKeys: number[] = [];

  protected destroyed$ = new Subject<void>();
  protected cdr = inject(ChangeDetectorRef);
  protected columnChooserService = inject(FireflyColumnChooserService);
  private isSafari = isSafari();
  private isGridDataArray: boolean | undefined;
  private previousGridDataArray: Array<undefined> | undefined;

  get mobileBreakpoint(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  get gridDataArray(): Array<undefined> {
    if (!this.grid?.data) return [];
    if (this.isGridDataArray) return this.grid.data as Array<undefined>;
    return (this.grid.data as GridDataResult)?.data;
  }

  ngOnChanges(_changes?: SimpleChanges) {
    requestAnimationFrame(() => this.handleGridDataInputChange());
  }

  ngAfterViewInit() {
    this.columnChooserService.applyChangesObs.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      requestAnimationFrame(() => this.cdr.markForCheck());
    });

    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroyed$),
        map(() => this.mobileBreakpoint),
        distinctUntilChanged(),
        filter(Boolean)
      )
      .subscribe(() => {
        /**
         * Collapses previously expanded desktop details row.
         * Triggered once when breakpoint changes from desktop to mobile.
         */
        this.expandedDetailKeys = [];
      });
  }

  gridColumnIsHidden(name: string): boolean {
    const col = (this.grid?.columns as QueryList<ColumnComponent>)?.find(col => col.field === name);
    return !!col?.hidden;
  }

  private handleGridDataInputChange() {
    if (!this.isSafari) return;

    if (this.isGridDataArray === undefined && this.grid?.data) {
      this.isGridDataArray = Array.isArray(this.grid.data);
    }

    if (this.previousGridDataArray?.length === this.gridDataArray?.length) return;

    const previousIosWholeTable = this.iosWholeTable;
    this.iosWholeTable = this.previousGridDataArray?.length === 1 && this.gridDataArray.length > 1;

    if (previousIosWholeTable !== this.iosWholeTable) {
      this.cdr.markForCheck();
    }

    this.previousGridDataArray = this.gridDataArray;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
