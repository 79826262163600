import { NgModule } from '@angular/core';
import { provideNavConfig } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { InsightEntitlementsGuard } from '@capital-access/insight/user/common';
import { InsightNavConfigProvider } from './nav-config.provider';

export const loadModule = () => import('@capital-access/insight/client').then(m => m.InsightClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: 'insight',
          loadChildren: loadModule,
          canActivate: [InsightEntitlementsGuard],
          canActivateChild: [InsightEntitlementsGuard]
        }
      ]
    })
  ],
  providers: [provideNavConfig(InsightNavConfigProvider)]
})
export class InsightClientLazyModule {}
