export interface SwitcherConfigItem {
  name: string;
  icon: string;
  url?: string;
}

export enum EntitlementType {
  Function = 'Function',
  Application = 'Application'
}

export interface AppSwitcherItemEntitlement {
  type: EntitlementType;
  sourceId: string;
}

export interface AppSwitcherItem {
  name: string;
  icon: string;
  order: number;
  entitlement: AppSwitcherItemEntitlement;
}
