import { NgModule } from '@angular/core';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';

export const loadModule = () => import('@capital-access/landing-page').then(m => m.LandingPageModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: '',
          pathMatch: 'full',
          loadChildren: loadModule
        }
      ]
    }),
    CommonNavigationModule.forFeature({
      top: [
        {
          order: 0,
          localizationKey: 'homeLabel',
          link: '/',
          icon: 'f-i-home'
        }
      ]
    })
  ]
})
export class LandingPageLazyModule {}
