<span
  *ngIf="navItem.isShown | async"
  class="ca-nav-item ca-toggle-nav-item form-check form-switch"
  [caLocalizationScope]="localizationScope"
  (click)="changeToggleValue()"
>
  <label class="form-check-label w-100" for="toggle-{{ index }}">
    {{ navItem.localizationKey | caLocalize }}
  </label>
  <input
    #toggleInput
    class="form-check-input input-lg ms-3 me-1"
    id="toggle-{{ index }}"
    type="checkbox"
    [checked]="navItem.value | async"
    (click)="onClick()"
  />
</span>
