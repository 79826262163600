import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  Renderer2,
  TemplateRef
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { Breakpoint, FireflyLocalizationService } from '../../utils';
import { CardSize } from './models/card-size.enum';

@Component({
  selector: 'f-small-card',
  templateUrl: './small-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySmallCardComponent {
  @Input() title!: string;
  @Input() size: CardSize = CardSize.Sm;
  @Input() loading: boolean | null = false;
  @Input() rootCssClass: string = '';
  @Input() bodyCssClass: string = '';
  @Input() contentCssClass: string = '';
  @Input() titleStartCssClass = '';
  @Input() loaderTemplate!: TemplateRef<unknown>;

  @Input() set scrollableContent(content: ElementRef<HTMLElement> | null) {
    this.setScrollableContent(content);
  }

  hasScrollableContent = false;
  isScrollable = false;
  isContentExpanded = false;
  scrollHeight?: number;

  private cdr = inject(ChangeDetectorRef);
  private localizationService = inject(FireflyLocalizationService, { optional: true });
  private renderer = inject(Renderer2);
  private host = inject(ElementRef);

  get showLinkTitle(): Observable<string> {
    const showMore = this.localizationService ? this.localizationService.localize('showMore', {}) : of('Show more');
    const showLess = this.localizationService ? this.localizationService.localize('showLess', {}) : of('Show less');
    return this.isContentExpanded ? showLess : showMore;
  }

  get mobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  setScrollableContent(content: ElementRef<HTMLElement> | null): void {
    const contentEl = content?.nativeElement;

    if (contentEl && this.scrollHeight && this.isContentExpanded) {
      this.isScrollable = contentEl.scrollHeight > this.scrollHeight;
      if (!this.isScrollable) this.isContentExpanded = false;
      this.cdr.markForCheck();
      return;
    }

    this.isContentExpanded = false;
    this.isScrollable = false;

    if (!content || !this.mobile) return;

    this.hasScrollableContent = true;
    this.cdr.detectChanges();

    requestAnimationFrame(() => {
      this.renderer.addClass(contentEl, 'overflow-hidden');
      this.isScrollable = contentEl!.scrollHeight > contentEl!.clientHeight;
      if (this.isScrollable) this.scrollHeight = contentEl!.clientHeight;
      this.cdr.markForCheck();
    });
  }

  onShowLinkClick(): void {
    this.isContentExpanded = !this.isContentExpanded;
    if (!this.isContentExpanded)
      requestAnimationFrame(() =>
        this.host.nativeElement.firstChild.scrollIntoView({ behavior: 'instant', block: 'end' })
      );
  }
}
