<f-list class="draggable" [class.active]="wasMovement" [ngClass]="rootCssClass">
  <div
    kendoDropTarget
    class="drop-target"
    [class.cursor-not-allowed]="item.disabled || disabled"
    [title]="disabled ? disabledItemTooltip : ''"
    (onDrop)="handleDrop($event, index)"
    (onDragLeave)="handleDragLeave($event)"
    (onDragEnter)="handleDragEnter($event, index)"
    *ngFor="let item of items; let index = index"
  >
    <div
      *ngIf="desktopView; else notDraggableList"
      kendoDragTarget
      (onDragStart)="handleDragStart($event, index)"
      (onDragEnd)="handleDragEnd($event)"
      (onPress)="handlePress($event)"
      (onDrag)="onDrag($event)"
      class="drag-target"
      [class.pe-none]="disabled"
      [restrictByAxis]="'vertical'"
    >
      <div kendoDragHandle>
        <ng-template *ngTemplateOutlet="draggableListItem; context: { $implicit: item, index: index }"></ng-template>
      </div>
      <ng-template *ngTemplateOutlet="draggableNestedList; context: { $implicit: item }"></ng-template>
    </div>
    <ng-template #notDraggableList>
      <ng-template *ngTemplateOutlet="draggableListItem; context: { $implicit: item, index: index }"></ng-template>
      <ng-template *ngTemplateOutlet="draggableNestedList; context: { $implicit: item }"></ng-template>
    </ng-template>
  </div>
</f-list>

<ng-template #draggableListItem let-item let-index="index">
  <f-draggable-list-item
    [hoverable]="hoverable"
    [actionBtnIcon]="item.icon"
    [rootClass]="listItemRootClass"
    [draggableIconClass]="draggableIconClass"
    [disabled]="disabled"
    [actionBtnClass]="item.btnClass"
    [disabledIcon]="item.disabled"
    [disabledIconTitle]="item.iconTitle"
    (actionBtnClick)="actionBtnClick.emit({ item, index })"
  >
    <ng-template *ngTemplateOutlet="listItemTemplate; context: { $implicit: item, index: index }"></ng-template>
  </f-draggable-list-item>
</ng-template>

<ng-template #draggableNestedList let-item>
  <f-draggable-nested-list
    *ngIf="item.nestedItems"
    [items]="item.nestedItems"
    [hoverable]="hoverable"
    [listItemRootClass]="listItemRootClass"
    [isParentDragging]="!!draggingElement"
    [disabled]="disabled"
    [actionBtnIcon]="item.icon"
    [listItemTemplate]="listItemTemplate"
  >
  </f-draggable-nested-list>
</ng-template>
