import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CRM_COMMON_LOCALIZATION_SCOPE } from '../../model/constants';
import { ExistingProfileAddressItem } from '../../model/existing-profile.models';

@Component({
  selector: 'ca-crm-form-existing-address',
  templateUrl: './crm-form-existing-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrmFormExistingAddressComponent {
  localizationScope = CRM_COMMON_LOCALIZATION_SCOPE;

  @Input() address?: ExistingProfileAddressItem;
  @Input() icon!: string;
  @Input() iconTitle!: string;
  @Input() noDataTitle!: string;
}
