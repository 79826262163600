import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Event, SearchCategory } from '../../../+state/models';
import { SearchItemsListBaseComponent } from '../search-items-list-base-component/search-items-list-base.component';

@Component({
  selector: 'ca-search-events-list',
  templateUrl: './search-events-list.component.html',
  styleUrls: ['./../search-list.common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchEventsListComponent extends SearchItemsListBaseComponent<Event> {
  constructor(store: Store) {
    super(store, SearchCategory.Events);
  }

  trackByMethod(index: number, item: Event): string {
    return item.id;
  }
}
