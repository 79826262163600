import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CommonFeatureToggleModule } from '@capital-access/common/feature-toggle';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import {
  FireflyDrawerModule,
  FireflyInnerErrorModule,
  FireflySkeletonDrawerLoaderModule
} from '@capital-access/firefly/components';
import { ContactSupportDrawerEffects } from './+state/contact-support-drawer.effects';
import { ContactSupportComponent } from './contact-support.component';
import { ContactSupportDrawer } from './contact-support-drawer';
import { DisclaimerComponent } from './disclaimer.component';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule,
    FireflyDrawerModule,
    EffectsModule.forFeature([ContactSupportDrawerEffects]),
    FireflySkeletonDrawerLoaderModule,
    CommonFeatureToggleModule,
    FireflyInnerErrorModule
  ],
  declarations: [ContactSupportComponent, DisclaimerComponent],
  exports: [ContactSupportComponent],
  providers: [ContactSupportDrawer]
})
export class ContactSupportModule {}
