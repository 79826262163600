import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { USER_PROFILE_FEATURE_KEY, UserState } from './user.state';

const getUserProfileState = createFeatureSelector<UserState>(USER_PROFILE_FEATURE_KEY);
export const getUserState = createSelector(getUserProfileState, state => state);

export const getUserLoadStatus = createSelector(getUserState, state => state.loadStatus);

export const getUserLoaded = createSelector(getUserState, state => state.loadStatus === LoadStatus.Loaded);
export const getUserLoadError = createSelector(getUserState, state => state.error);

export const getUser = createSelector(getUserState, state => state.user);
export const getAccount = createSelector(getUserState, state => state.account);
export const getContentPermissions = createSelector(getUserState, state => state.contentPermissions);
export const getAvailableSecurityIds = createSelector(
  getContentPermissions,
  state => state?.targeting?.securityIds ?? []
);

export const getEntitlements = createSelector(getUserState, state => state.userEntitlements);
