import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { Breakpoint } from '../../../utils';

@Component({ template: '' })
export class BaseSmallCardRowComponent implements AfterViewInit, OnDestroy {
  @Input() firstContainerTitle: string | null = '';
  @Input() secondContainerTitle: string | null = '';
  @Input() fourthContainerTitle: string | null = '';
  @Input() columnBreakdown: 'primary' | 'secondary' = 'primary';
  @Input() firstContainerWithBadge = false;
  @Input() rowID = '';
  @Input() secondContainerID = '';
  @Input() fourthContainerID = '';

  mobile = this.isMobile;

  get isMobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  private resizeObserver = new ResizeObserver(() => {
    this.updateIsMobile();
  });

  protected cdr = inject(ChangeDetectorRef);

  ngAfterViewInit() {
    this.resizeObserver.observe(document.documentElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  private updateIsMobile() {
    if (this.mobile !== this.isMobile) {
      this.mobile = this.isMobile;
      this.cdr.detectChanges();
    }
  }
}
