import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { FireflySkeletonLoaderComponent } from './skeleton-loader.component';

@NgModule({
  imports: [CommonModule, IndicatorsModule],
  declarations: [FireflySkeletonLoaderComponent],
  exports: [FireflySkeletonLoaderComponent]
})
export class FireflySkeletonLoaderModule {}
