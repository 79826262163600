import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FireflyTopNavService, SpinnerSize } from '@capital-access/firefly/components';
import { State } from '../../+state/search.reducer';
import { isSearchStarted } from '../../+state/search.selectors';
import { OneSearchInputService } from './one-search-input.service';

@Component({
  selector: 'ca-one-search-input',
  templateUrl: './one-search-input.component.html',
  styleUrls: ['./one-search-input.component.scss'],
  providers: [OneSearchInputService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneSearchInputComponent {
  SpinnerSize = SpinnerSize;

  searchStarted$ = this.store.select(isSearchStarted);
  searchText$ = this.inputStore.searchText$;

  constructor(
    private store: Store<State>,
    private inputStore: OneSearchInputService,
    private topNavService: FireflyTopNavService
  ) {}

  onInputChange($event: Event) {
    const text = ($event.target as HTMLInputElement).value.trim();
    this.inputStore.setSearchText(text);
  }

  onCloseClick(): void {
    this.inputStore.setSearchText('');
  }

  onInputFocus() {
    this.topNavService.toggleSearchOpenState(true);
  }
}
