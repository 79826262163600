import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SkeletonLoaderShade } from '../../models/skeleton-loader.enum';

@Component({
  selector: 'f-skeleton-small-card-with-text',
  template: `
    <div class="container-fluid heading-50 p-0">
      <div class="row mb-1">
        <f-skeleton class="col-12" [shade]="shade"></f-skeleton>
      </div>
      <div class="row mb-1">
        <f-skeleton class="col-10" [shade]="shade"></f-skeleton>
      </div>
      <div *ngIf="!shortTemplate" class="row mb-1">
        <f-skeleton class="col-10" [shade]="shade"></f-skeleton>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySkeletonSmallCardWithTextComponent {
  @Input() shade: SkeletonLoaderShade = SkeletonLoaderShade.Light;
  @Input() shortTemplate = false;
}
