import { CellOptions } from '@progress/kendo-angular-excel-export';

export interface ExcelColumn {
  field?: string;
  title?: string;
  format?: string;
  align?: ExcelAlign;
}

export interface KendoExcelColumn {
  field: string;
  title: string;
  width: number;
  cellOptions: CellOptions;
}

export enum ExcelAlign {
  General = 'General',
  Left = 'Left',
  Center = 'Center',
  CenterContinuous = 'CenterContinuous',
  Right = 'Right',
  Fill = 'Fill',
  Distributed = 'Distributed',
  Justify = 'Justify'
}
