// eslint-disable-next-line no-restricted-imports
import { HashMap } from '@ngneat/transloco';

export class LocalizedString {
  public readonly scope?: string;
  public readonly params?: HashMap;
  public readonly fullKey: string;

  constructor(public localizationKey: string, options?: { scope?: string; params?: HashMap }) {
    this.scope = options?.scope;
    this.params = options?.params;

    if (options?.scope) {
      this.fullKey = `${this.scope}.${this.localizationKey}`;
    } else {
      this.fullKey = this.localizationKey;
    }
  }
}
