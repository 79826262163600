import { createSelector } from '@ngrx/store';
import { createSectionSelector, getSettings } from '@capital-access/common/user';
import { LicenseAgreementPreferences } from '../models/license-agreement-preferences';

const getLicenseAgreementSection = (preferenceSection: string) =>
  createSectionSelector(getSettings, settings => settings.getSection(preferenceSection));

export const getLicenseAgreementSettings = (preferenceSection: string) =>
  createSelector(getLicenseAgreementSection(preferenceSection), section =>
    section.toObject(LicenseAgreementPreferences)
  );
