import { SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { Breakpoint } from '../../utils';
import { FireflyBaseSuggesterComponent } from '../components/base-suggester/base-suggester.component';
import { FireflyMultipleSuggesterComponent } from '../components/multiple-suggester/multiple-suggester.component';
import { GroupedData, GroupedMultipleData } from '../models/grouped-data.model';
import { SuggesterType } from '../models/suggester-type.enum';
import { updateActionButtonsOnListChanges } from '../utils/action-buttons/action-buttons.utils';
import { getFilteredItems, groupBy } from '../utils/list-items/list-items.utils';

export class SuggesterManager {
  // suggester manager is used to store all common logic for both - single and multiple suggester
  constructor(private suggester: FireflyBaseSuggesterComponent, private suggesterType: SuggesterType) {}

  closedOnInsideClick!: boolean;
  showLoader = false;
  listData: GroupedData = {
    totalLength: 0,
    groupedItems: []
  };
  private viewMoreInProgress = false;
  initialGroupedData: GroupedData = {
    totalLength: 0,
    groupedItems: []
  };

  get showList(): boolean {
    return !this.suggester.showError || this.viewMoreInProgress;
  }

  get showNoData(): boolean {
    return !this.listData.totalLength && !this.inputControlValue.length;
  }

  get showNoResults(): boolean {
    return !this.listData.totalLength && !!this.inputControlValue.length;
  }

  get inputLengthValid(): boolean {
    return this.inputControlValue.length > this.suggester.minLength - 1;
  }

  get inputControlValue(): string {
    return this.suggester.inputValue.value?.trim() as string;
  }

  toggleLoader(value: boolean) {
    this.showLoader = value;
    if (!value && this.viewMoreInProgress) this.toggleViewMoreProgress(false);
  }

  toggleDropdown(state: boolean): void {
    if (state) {
      if (window.innerWidth < Breakpoint.Sm && !this.suggester.modalIsOpen) return;
      this.suggester.dropdown?.open();
      this.setDropdownMenuMaxHeight();
      this.scrollListIntoView();
    } else {
      this.closedOnInsideClick = true;
      this.suggester.dropdown?.close();
    }
  }

  groupData(items: unknown[], categoryPath: string) {
    const groupedInitialData = groupBy(items, categoryPath, this.suggester.nestedValuePipe.transform);
    if (this.suggester.customFilterMode) {
      this.listData = groupedInitialData;
    } else {
      this.initialGroupedData = groupedInitialData;
      // TODO: need better solution for multi suggester to update list of checked items as we filter
      this.getFilterOptions();
    }
    this.applyUpdatesOnListChanges();
  }

  clearInput() {
    this.resetValue(true);
    this.suggester.inputValue.markAsTouched();
    this.applyDefaultValidation();
  }

  resetValue(emitEvent = false): void {
    this.suggester.inputValue.reset('', { emitEvent });
    if (!this.suggester.modalIsOpen || (this.suggester.showPredefinedSet && this.suggester.customFilterMode))
      this.suggester.reset.emit();
    this.handleInvalidInputLength();
  }

  onViewMore(): void {
    this.toggleViewMoreProgress(true);
    this.toggleLoader(true);
    this.suggester.viewMore.emit();
  }

  onContactSupport(): void {
    this.toggleDropdown(false);
    this.suggester.contactSupport.emit();
  }

  onPropertiesChanges(changes: SimpleChanges) {
    if (changes['items']?.currentValue && !this.suggester.predefinedSetIsLoading) {
      this.toggleLoader(false);
    }
    if (changes['showError']?.currentValue && this.suggester.suggesterIsInFocus) {
      this.toggleLoader(false);
      this.toggleDropdown(true);
    }
    if (changes['disabled']) {
      this.toggleDisabledState(changes['disabled'].currentValue);
    }
    if (changes['items']?.previousValue && changes['items']?.currentValue) {
      if (this.suggester.showPredefinedSet) this.setDropdownMenuMaxHeight();
      this.scrollListOnViewMore(changes.items.currentValue.length - changes.items.previousValue.length);
    }
  }

  private toggleViewMoreProgress(state: boolean): void {
    this.viewMoreInProgress = state;
    this.toggleDisabledState(state);
  }

  private toggleDisabledState(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.suggester.inputValue[action]({ emitEvent: false });
  }

  subscribeToInputChanges() {
    this.suggester.inputValue.valueChanges
      .pipe(
        takeUntil(this.suggester.destroyed$),
        debounce(() => timer(this.inputDebounceTime))
      )
      .subscribe(() => {
        this.checkToFilterListOnInput();
        if (this.suggester.showPredefinedSet) this.setDropdownMenuMaxHeight();
        this.suggester.changeDetectorRef.markForCheck();
      });
  }

  private get inputDebounceTime() {
    return this.inputLengthValid && !this.suggester.showPredefinedSet ? this.suggester.debounceTime : 0;
  }

  private checkToFilterListOnInput() {
    if (this.inputLengthValid) {
      this.filterList();
    } else {
      this.handleInvalidInputLength();
    }
  }

  private handleInvalidInputLength() {
    if (!this.suggester.showPredefinedSet) {
      this.toggleDropdown(false);
      return;
    }
    if (!this.suggester.customFilterMode) {
      this.setListDataEqualToInitial();
      this.applyUpdatesOnListChanges();
    }
  }

  private setListDataEqualToInitial() {
    this.listData = {
      groupedItems: this.initialGroupedData.groupedItems.slice(),
      totalLength: this.initialGroupedData.totalLength
    };
  }

  filterList(): void {
    if (this.suggester.showError) {
      this.suggester.updateSearch.emit(this.inputControlValue);
      this.toggleDropdown(true);
    }

    if (this.suggester.customFilterMode) {
      if (!this.suggester.showPredefinedSet) this.toggleLoader(true);
      this.suggester.updateSearch.emit(this.inputControlValue);
    } else {
      this.getFilterOptions();
      this.applyUpdatesOnListChanges();
    }
  }

  private getFilterOptions(): void {
    if (
      !this.suggester.filterListOnFirstInteraction &&
      this.suggester.inputValue.pristine &&
      this.suggester.showPredefinedSet
    ) {
      this.listData = {
        groupedItems: this.initialGroupedData.groupedItems,
        totalLength: this.initialGroupedData.totalLength
      };
    } else {
      const searchString = this.inputControlValue.toLowerCase();
      const filteredItemsData = getFilteredItems(
        this.initialGroupedData.groupedItems,
        searchString,
        this.suggester.valuePath,
        this.suggester.nestedValuePipe.transform
      );
      this.listData = { groupedItems: filteredItemsData.groupedItems, totalLength: filteredItemsData.totalLength };
    }
  }

  onOpenChange(isOpen: boolean) {
    if (!isOpen && !this.closedOnInsideClick) {
      this.suggester.outclick.emit();
      this.clearUnmatchedQuery();
      this.applyDefaultValidation();
    }
    this.closedOnInsideClick = false;
  }

  onBlur() {
    this.toggleLoader(false);
    if (!this.suggester.dropdown?.isOpen()) {
      this.suggester.outclick.emit();
      this.clearUnmatchedQuery();
      this.applyDefaultValidation();
    }
  }

  applyDefaultValidation() {
    if (this.suggester.isRequired && !this.inputControlValue.length && !this.suggester.selectedChips.length) {
      window.requestAnimationFrame(() => {
        this.suggester.inputValue.setErrors({ required: true });
        this.suggester.changeDetectorRef.markForCheck();
      });
    }
  }

  clearUnmatchedQuery() {
    const isInputValueEmpty = !this.suggester.inputValue.value?.trim();

    if (
      (this.suggester.clearUnmatchedQueryOnBlur && this.suggester.inputValue.dirty && this.inputControlValue.length) ||
      isInputValueEmpty
    ) {
      this.suggester.inputValue.patchValue('', { emitEvent: true });
    }
  }

  private setDropdownMenuMaxHeight() {
    if (!this.suggester.resultsList) return;
    window.requestAnimationFrame(() => {
      const listItems = Array.from(this.suggester.resultsList.nativeElement.children).filter(
        child => child !== this.suggester.actionButtons?.nativeElement
      ) as HTMLElement[];
      const maxListItems = listItems.slice(0, this.suggester.maxResultsCount);
      const resultsHeight = maxListItems
        .map(item => {
          const height = item.getBoundingClientRect().height;
          const computedParentStyles = window.getComputedStyle(item);
          const mt = computedParentStyles?.marginTop ?? '0';
          const mb = computedParentStyles?.marginBottom ?? '0';
          return height + parseInt(mt) + parseInt(mb);
        })
        .reduce((acc, value) => acc + value, 0);

      const minListHeight = this.suggester.getResultsBoxMinHeight(listItems.length);
      this.suggester.dropdownMaxHeight = minListHeight + resultsHeight;
      this.suggester.changeDetectorRef.markForCheck();
    });
  }

  private scrollListIntoView() {
    if (this.suggester.items?.length) {
      requestAnimationFrame(() => {
        this.suggester.resultsBox?.nativeElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      });
    }
  }

  private scrollListOnViewMore(diff: number) {
    const listItem = this.suggester.fSuggesterList.nativeElement.querySelector('.dropdown-item');
    const listItemHeight = listItem?.getBoundingClientRect().height ?? 32;

    requestAnimationFrame(() => {
      const listHeight = this.suggester.fSuggesterList.nativeElement.scrollTop;
      this.suggester.fSuggesterList.nativeElement.scrollTop = listHeight - listItemHeight * diff;
    });
  }

  private applyUpdatesOnListChanges() {
    // add here specific updates for different suggester types after general list data changes if required
    if (this.suggesterType === SuggesterType.Multiple) {
      updateActionButtonsOnListChanges(
        (this.suggester as FireflyMultipleSuggesterComponent).actionsForAllList,
        this.listData as GroupedMultipleData,
        this.suggester.disabledPath,
        this.suggester.nestedValuePipe.transform
      );
    }

    if (this.inputLengthValid && this.suggester.inputValue.dirty && !this.suggester.showError) {
      this.toggleDropdown(true);
    }
  }
}
