import { NgModule } from '@angular/core';
import { AppSettingsService } from '@capital-access/common/settings';

@NgModule()
export class FireflyStylesLoaderModule {
  constructor(private settingsService: AppSettingsService) {
    const commonStylesName = 'firefly-common';
    const settings = this.settingsService.getSettings<{ stylesHashes: { [key: string]: string } }>();
    const stylesHashes = settings.stylesHashes ?? {};
    const hash = stylesHashes[commonStylesName];

    const style = document.createElement('link');
    style.id = commonStylesName;
    style.rel = 'stylesheet';
    style.href = hash ? `${commonStylesName}.${hash}.css` : `${commonStylesName}.css`;

    document.head.appendChild(style);
  }
}
