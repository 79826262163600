import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { DatePickerComponent, TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { Observable, Subject } from 'rxjs';
import { noop } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { FireflyModalService } from '../../modal/modal.service';
import { ModalRef } from '../../modal/models/base-modal-options';
import { Breakpoint } from '../../utils';
import { FireflyMobileDateTimeComponent } from '../components/mobile-date-time-picker.component';

@Directive()
export abstract class FireflyBaseDateTimeDirective implements OnInit, OnDestroy, OnChanges {
  protected modalDialogClass = 'mobile-datepicker';
  protected modalDialogTitle = 'Date';
  protected modal!: ModalRef<unknown> | null;
  protected valueChange$!: Observable<Date>;
  protected initialValue!: Date;

  private resetIconContainer: HTMLElement = this.renderer.createElement('div');
  private pickerClosePrevented = false;
  protected destroyed$ = new Subject<void>();

  private get calendarBtn(): HTMLElement | null {
    return this.host.nativeElement.querySelector('.k-input-button');
  }

  private get calendarInput(): HTMLElement | null {
    return this.host.nativeElement.querySelector('.k-input-inner');
  }

  get popupElement(): HTMLElement | null {
    return this.picker.popupRef?.popupElement;
  }

  @Input() showResetBtn = false;

  constructor(
    protected host: ElementRef,
    private picker: DatePickerComponent | TimePickerComponent,
    protected renderer: Renderer2,
    protected cdr: ChangeDetectorRef,
    protected modalService: FireflyModalService
  ) {}

  ngOnInit() {
    this.initialValue = this.picker.value!;

    this.valueChange$ = this.picker.valueChange.pipe(share());

    this.valueChange$.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (!this.modal) this.initialValue = value;
      this.checkResetButton();
    });

    window.requestAnimationFrame(() => this.checkResetButton());

    document.addEventListener('click', this.onDocumentClick, true);

    document.addEventListener('visibilitychange', this.onVisibilityChange);

    this.picker.close.pipe(takeUntil(this.destroyed$)).subscribe(e => {
      if (window.innerWidth < Breakpoint.Sm || this.modal) e.preventDefault();
    });

    this.picker.onFocus.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.open();
    });

    this.host.nativeElement.addEventListener('keydown', this.onKeydown, true);

    this.host.nativeElement.addEventListener('click', (e: PointerEvent) => {
      this.pickerClosePrevented = false;
      if (e.target !== this.host.nativeElement) return;
      this.open();
    });

    this.calendarInput?.addEventListener('click', () => {
      this.open();
    });

    this.picker.close.pipe(takeUntil(this.destroyed$)).subscribe(e => {
      if (this.pickerClosePrevented) e.preventDefault();
    });
  }

  ngOnChanges() {
    this.checkResetButton();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private checkResetButton(): void {
    if (this.showResetBtn && this.picker.value) {
      if (!this.host.nativeElement.querySelector('.f-i-close')) {
        this.resetIconContainer.classList.add('k-reset-button');
        this.resetIconContainer.innerHTML =
          '<i class="f-i f-i-close f-i-xs cursor-pointer" tabindex="0" title="Clear"></i>';
        this.resetIconContainer.addEventListener('click', this.resetDate);
        this.renderer.appendChild(this.host.nativeElement, this.resetIconContainer);
      }
    } else {
      this.renderer.removeChild(this.host.nativeElement, this.resetIconContainer);
    }
  }

  openPopupFromModal(ref: ViewContainerRef) {
    this.picker.popupSettings.animate = false;
    this.picker.popupSettings.appendTo = ref;
    this.picker.toggle(true);
  }

  open() {
    if (this.picker.isOpen) return;

    if (window.innerWidth < Breakpoint.Sm && !this.modal) {
      this.picker.dateInput.blur();

      this.modal = this.modalService.open({
        component: FireflyMobileDateTimeComponent,
        modalDialogClass: this.modalDialogClass,
        title: this.modalDialogTitle,
        context: this,
        mobile: true
      });

      this.modal.result
        .then(
          () => this.handleStateUpdate(),
          () => this.resetState()
        )
        .finally(() => {
          setTimeout(() => {
            this.modal = null;
            this.close();
          }, 100);
        });
    } else {
      this.calendarBtn?.click();
      this.picker.popupRef?.popupElement.focus();
    }
  }

  close() {
    try {
      this.picker.toggle(false);
      this.picker.dateInput.blur();
      this.picker.blur();
      // eslint-disable-next-line no-empty
    } catch {}
  }

  protected resetDate = () => {
    this.picker.value = null as unknown as Date;
    this.picker.valueChange.emit(null as unknown as Date);
    this.cdr.markForCheck();
    this.picker.toggle(false);
  };

  protected handleStateUpdate() {
    noop();
  }

  protected resetState() {
    noop();
  }

  private onKeydown = (event: KeyboardEvent) => {
    this.pickerClosePrevented = true;
    const target = event.target as HTMLElement;
    if (event.code === 'Enter' && target.classList.contains('f-i-close')) {
      this.resetDate();
    }
    if (event.code === 'Enter') {
      this.close();
    }
  };

  private onDocumentClick = (e: PointerEvent | MouseEvent) => {
    if (!this.picker.isOpen || this.modal) return;
    this.pickerClosePrevented = false;
    const target = e.target as HTMLElement;
    const contains = this.host.nativeElement.contains(target) || this.picker.popupRef?.popupElement.contains(target);
    if (!contains) this.close();
  };

  private onVisibilityChange = () => {
    if (document.hidden || this.modal) return;
    setTimeout(() => this.close(), 10);
  };
}
