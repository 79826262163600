import { animate, style, transition, trigger } from '@angular/animations';

const toastMobilePositionBottom = '28px';

export const hideOnRemovalAnimation = trigger('hideOnRemoval', [
  transition(':enter', [
    style({ translate: `0 calc(100% + ${toastMobilePositionBottom})`, opacity: 0 }),
    animate('0.3s ease-out', style({ translate: '0', opacity: 1 }))
  ]),
  transition(':leave', [style({ opacity: 1 }), animate('0.25s ease-in', style({ opacity: 0 }))])
]);
