import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BreadcrumbItem, BreadcrumbResolver } from '@capital-access/common/breadcrumbs';
import { getActivatedRoute, getActivatedRoutesFromRoot } from '@capital-access/common/router';

@Component({
  selector: 'ca-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  breadcrumbItems$: Observable<BreadcrumbItem[]>;

  constructor(private store: Store, private resolver: BreadcrumbResolver) {
    this.breadcrumbItems$ = this.store.pipe(
      select(getActivatedRoute),
      filter(route => !!route),
      switchMap(route => {
        const strategy = this.resolver.getComponentStrategy(route!);
        return this.store.pipe(select(getActivatedRoutesFromRoot), map(strategy));
      })
    );
  }

  trackByUrl(i: number, item: BreadcrumbItem) {
    return item.url?.urlString;
  }
}
