import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OnboardingEmptyStatesOptions } from './../models/empty-state-options.enum';

@Component({
  selector: 'f-onboarding-empty-state',
  templateUrl: './onboarding-empty-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyOnboardingEmptyStateComponent {
  @Input() caption!: string;
  @Input() info!: string;
  @Input() note!: string;
  @Input() onboardingOption!: OnboardingEmptyStatesOptions;
}
