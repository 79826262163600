import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { openContactPreview, openInstitutionPreview } from '@capital-access/common/actions';
import { ChipsItem, Fund, SearchCategory, SearchEntity } from '../../+state/models';
import { PopupHeaderState } from '../../+state/models/popup-header-state.enum';
import { filterChanged, unselectAll, unselectEntity } from '../../+state/search.actions';
import * as searchSelectors from '../../+state/search.selectors';

@Component({
  selector: 'ca-search-popup-header',
  templateUrl: './search-popup-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPopupHeaderComponent {
  @HostBinding('class') class = 'w-100';

  private store = inject(Store);
  popupHeaderState$ = this.store.select(searchSelectors.getPopupHeaderState);
  popupHeaderState = PopupHeaderState;
  chipsItems$ = this.store.select(searchSelectors.getChipItems);
  hasSelectedEntities$ = this.store.select(searchSelectors.hasSelectedEntities);

  onFilterChanged(filter: SearchCategory | null) {
    this.store.dispatch(filterChanged({ filter }));
  }

  onUnselect(item: unknown) {
    this.store.dispatch(unselectEntity({ entity: (item as ChipsItem).entity }));
  }

  onUnselectAll() {
    this.store.dispatch(unselectAll());
  }

  onChipClick(item: unknown) {
    const { crmId, prospectingId: prId, category } = (item as ChipsItem).entity as Exclude<SearchEntity, Fund | Event>;
    switch (category) {
      case SearchCategory.Contacts:
        this.store.dispatch(openContactPreview({ id: { prId, crmId } }));
        break;
      case SearchCategory.Institutions:
        this.store.dispatch(openInstitutionPreview({ id: { prId, crmId } }));
    }
  }
}
