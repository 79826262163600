import isUndefined from 'lodash/isUndefined';
import { Log } from '@capital-access/common/logging';
import { EmptyConstructor, ObjectWithProperty } from '@capital-access/common/utils';
import { GetSettingsEntitiesFn, GetValueFn, SettingsConstructor } from '../internal/settings-constructor';
import { PreferenceValueConverter } from '../preference-value-converters';
import { SettingsConfiguration } from './decorator-config';

function getSettingValueFactory<T>(
  settingsPath: string,
  converter: PreferenceValueConverter<T>,
  settingName: string
): GetValueFn<T | null> {
  return (section, _, defaultVal) => {
    const stringValue = section.getValue(settingsPath);
    const parsedValue = stringValue !== null ? converter.convert(stringValue) : null;

    if (parsedValue !== null) {
      return parsedValue;
    }

    if (!isUndefined(defaultVal)) {
      Log.debug(`${settingName} value is missing in user preferences. Using default value instead`); //TODO: show only on dev
      return defaultVal;
    }

    return null;
  };
}

function getSettingsEntityFactory<TKey extends string, TVal, TObj extends ObjectWithProperty<TKey, TVal | null>>(
  key: TKey,
  settingsPath: string,
  converter: PreferenceValueConverter<TVal>
): GetSettingsEntitiesFn<TObj> {
  return settingsObject => {
    let serializedValue: string | null = null;

    if (settingsObject && !isUndefined(settingsObject[key])) {
      const value = settingsObject[key];
      serializedValue = value !== null ? converter.toString(value as TVal) : null;
    }

    return [{ id: settingsPath, value: serializedValue }];
  };
}

/**
 * Initialize property from user setting.
 * Setting value will be converted by valueConverter and assigned to target property.
 * If value is missing in settings and initial value is not set, null will be assigned.
 * @param valueConverter converter that defines rules to convert to target type and to string.
 * @param config additional configuration. See SettingsConfiguration for more info
 */
export function value<TVal>(
  valueConverter: PreferenceValueConverter<TVal>,
  config: SettingsConfiguration = SettingsConfiguration.default
) {
  return <TKey extends string, TClass extends ObjectWithProperty<TKey, TVal | null>>(cls: TClass, key: TKey) => {
    const settings = SettingsConstructor.fromCtor<TClass>(cls.constructor as EmptyConstructor<TClass>);
    const settingsKeyPath = SettingsConfiguration.getPath(config, key);

    settings.registerSettingsEntities(getSettingsEntityFactory(key, settingsKeyPath, valueConverter));

    settings.addSettingCast(key, getSettingValueFactory(settingsKeyPath, valueConverter, settingsKeyPath));
  };
}
