import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadAiPanel, LOCALIZATION_SCOPE } from '@capital-access/ai-panel';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';

@Component({
  selector: 'ca-ai-chat-button',
  templateUrl: './ai-chat-button.component.html',
  styleUrls: ['./ai-chat-button.component.scss']
})
export class AiChatButtonComponent {
  isAiChatEnabled$ = this.store.select(getFeatureToggle('ai-assistant-AIE-9'));
  L10N = LOCALIZATION_SCOPE;

  openDrawer() {
    this.store.dispatch(loadAiPanel());
  }

  constructor(private store: Store) {}
}
