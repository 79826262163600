import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule, Optional, Type } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { BreadcrumbItem } from './models/breadcrumb-item.model';
import { BREADCRUMB_PROVIDERS } from './models/breadcrumb-providers.token';
import { BREADCRUMB_HOME_ITEM } from './models/breadcrumb-strategies.token';
import { BreadcrumbResolver } from './services/breadcrumb.resolver';

@NgModule({
  imports: [CommonModule, StoreModule]
})
export class CommonBreadcrumbsModule {
  public static forRoot(options: { homePageItem: BreadcrumbItem }): ModuleWithProviders<CommonBreadcrumbsModule> {
    return {
      ngModule: CommonBreadcrumbsModule,
      providers: [
        {
          provide: BREADCRUMB_HOME_ITEM,
          useValue: options.homePageItem
        }
      ]
    };
  }

  public static forFeature(provider: Type<unknown>): ModuleWithProviders<CommonBreadcrumbsModule> {
    return {
      ngModule: CommonBreadcrumbsModule,
      providers: [
        {
          provide: BREADCRUMB_PROVIDERS,
          useClass: provider,
          multi: true
        }
      ]
    };
  }

  constructor(
    @Optional() @Inject(BREADCRUMB_PROVIDERS) providers: Record<string, unknown>[] | null,
    resolver: BreadcrumbResolver
  ) {
    resolver.setProviders(providers || []);
  }
}
