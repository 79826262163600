import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import type { ScaleBand } from 'd3-scale';
import { scaleBand } from 'd3-scale';
import { Breakpoint } from '../../../utils';
import { FireflyBaseBarSeriesComponent } from '../base-components/base-bar-series.component';
import type { ChartBarDto, ChartDataEntry, ChartDataSeries } from '../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-grouped-bar-series]',
  templateUrl: 'grouped-bar-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyGroupedBarSeriesComponent extends FireflyBaseBarSeriesComponent implements OnInit, OnChanges {
  @Input() series!: ChartDataSeries[];
  @Input() groupPadding = 0.2;

  mobileInnerPadding = 0;
  mobileOuterPadding = 0.5;
  x1Scale!: ScaleBand<string>;
  barGroups: ChartDataSeries[] = [];

  get bars() {
    return this.barGroups as unknown as ChartBarDto[];
  }

  ngOnChanges() {
    this.x1Scale = this.getX1Scale();
    this.barGroups = this.getGroups();
  }

  getX1Scale() {
    const groups = this.series.reduce((acc, curr) => [...acc, ...curr.series.map(d => d.name)], [] as string[]);
    const scale = scaleBand().range([0, this.xScale.bandwidth()]).domain(groups).padding(this.groupPadding);
    const adjustedOuterPadding = window.innerWidth < Breakpoint.Sm ? this.mobileOuterPadding : this.groupPadding;
    const adjustedInnerPadding = window.innerWidth < Breakpoint.Sm ? this.mobileInnerPadding : this.groupPadding;
    return scale.paddingOuter(adjustedOuterPadding).paddingInner(adjustedInnerPadding);
  }

  getGroups(): ChartDataSeries[] {
    const step = this.xScale.step();
    const width = this.xScale.bandwidth();

    this.padding = (step - width) / 2;

    return this.series.map(group => {
      const x = this.xScale(group.name)! || 0;
      return {
        ...group,
        series: group.series.map((item, i) => {
          return { ...item, cssClass: `${this.barClasses[i]} ${item.cssClass ?? ''}` };
        }) as ChartDataEntry[],
        halo: { width: step, x: x - this.padding },
        transform: `translate(${x}, 0)`,
        x
      };
    });
  }

  handleMobileModal(activeBarIndex: number) {
    if (!this.chartBoilerplate) return;

    if (!this.interactions.preventMobile && window.innerWidth < Breakpoint.Sm && !this.modal) {
      const sliceStartIndex = activeBarIndex;
      const sliceEndIndex = activeBarIndex + 1;
      // Group-bar chart always has 1 group inside mobile popover, meaning it's index is 0;
      activeBarIndex = 0;

      this.modal = this.modalService.open({
        component: this.mobileModalComponent,
        modalDialogClass: 'f-chart-mobile-popover',
        context: this.getModalContext(activeBarIndex, sliceStartIndex, sliceEndIndex),
        title: this.modalTitle,
        mobile: true
      });

      this.modal.result.finally(() => (this.modal = null));
    }
  }

  popoverContextData(index: number, options?: { start: number; end: number }) {
    if (!options) {
      const series = this.barGroups[index].series;
      return { ...this.series[index], series };
    }
    const series = this.barGroups.slice(options.start, options.end)[index].series;
    return { ...this.series.slice(options.start, options.end)[index], series };
  }
}
