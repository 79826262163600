import { InjectionToken, Type } from '@angular/core';
import { ToastMessageContainerComponent } from '../toast-message-container/toast-message-container.component';
import { NotificationComponentContainer, NotificationOptions } from './notification-models';

export const NOTIFICATION_COMPONENT_CONTAINER_TOKEN = new InjectionToken<Type<NotificationComponentContainer>>(
  'Notification Component Container',
  {
    providedIn: 'root',
    factory: () => ToastMessageContainerComponent
  }
);

export const NOTIFICATION_DEFAULT_OPTIONS = new InjectionToken<NotificationOptions>('Notification Options', {
  providedIn: 'root',
  factory: notificationDefaultOptionsFactory
});

export const NOTIFICATION_DATA = new InjectionToken<unknown>('Notification Data');

export function notificationDefaultOptionsFactory(): NotificationOptions {
  return {
    headerText: null,
    type: 'default',
    manualClose: false,
    delay: 5000,
    templateContext: null
  };
}
