import { Directive, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';

@Directive({
  selector: '[caTextOverflow]'
})
export class TextOverflowDirective implements OnInit, OnChanges, OnDestroy {
  private resizeObserver = new ResizeObserver(() => this.ngOnChanges());

  @Input('caTextOverflow') text: string | null | undefined;
  private readonly element: HTMLElement;

  constructor(elementRef: ElementRef, private zone: NgZone) {
    this.element = elementRef.nativeElement;
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => this.resizeObserver.observe(document.documentElement));
  }

  ngOnChanges() {
    this.zone.runOutsideAngular(() => {
      const text = this.text ?? '';
      this.setText(text);
      if (this.isOverflow) {
        this.truncate(text);
      }
    });
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  private get isOverflow() {
    // need this check, since if the element has no associated CSS layout box or if the CSS layout box is inline,
    // clientHeight returns zero and directive trims out text completely
    if (!this.element.clientHeight) {
      return false;
    }
    return this.element.clientHeight < this.element.scrollHeight;
  }

  private setText(text: string) {
    this.element.textContent = text;
  }

  private trimRight(text: string) {
    return text.replace(/\s*$/, '');
  }

  private truncate(original: string) {
    const ellipsis = '…';

    let maxChunk = '';
    let chunk: string;

    let low = 0;
    let mid: number;
    let high = original.length;

    // Binary Search
    while (low <= high) {
      mid = low + ((high - low) >> 1); // Integer division

      chunk = this.trimRight(original.substring(0, mid + 1)) + ellipsis;
      this.setText(chunk);

      if (this.isOverflow) {
        // too big, reduce the chunk
        high = mid - 1;
      } else {
        // chunk valid, try to get a bigger chunk
        low = mid + 1;
        maxChunk = maxChunk.length > chunk.length ? maxChunk : chunk;
      }
    }

    this.setText(maxChunk);
  }
}
