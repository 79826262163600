import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FireflyCardRowService {
  private searchToggle$ = new BehaviorSubject<boolean>(false);

  searchOpenChange$ = this.searchToggle$.asObservable();

  toggleSearchOpenState(value: boolean) {
    if (this.searchToggle$.value !== value) {
      this.searchToggle$.next(value);
    }
  }
}
