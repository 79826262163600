import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import isNull from 'lodash/isNull';
import type { ChartDataEntry, ChartDataSeries, StackedChartBar } from '..';
import { FireflyBaseBarSeriesComponent } from '../base-components/base-bar-series.component';
import { ChartCircleDto } from '../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-stacked-bar-series]',
  templateUrl: 'stacked-bar-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyStackedBarSeriesComponent extends FireflyBaseBarSeriesComponent implements OnInit, OnChanges {
  @Input() series!: ChartDataSeries[];
  @Input() gapBetweenSeries = true;
  @Input() limitedBarMaxWidth = true;
  @Input() showOptionalData = false;
  @Input() bubblesAnimationDuration = 90;
  @Input() barBubbleClasses: string[] = ['bg-primary-100'];
  @Input() barBubbleTextClasses: string[] = ['color-primary-800'];

  _bars!: StackedChartBar[];
  optionalData: ChartCircleDto[] = [];

  ngOnChanges() {
    this.update();
  }

  update(): void {
    const step = this.xScale.step();
    const bandWidth = Math.round(this.xScale.bandwidth());
    const width = this.limitedBarMaxWidth && bandWidth > this.maxBarWidth ? this.maxBarWidth : bandWidth;

    this._bars = this.series.map(d => {
      const name = d.name;
      const x = this.xScale(name)! + (bandWidth - width) / 2 || 0;
      const halo = {
        x: x - (step - width) / 2,
        width: step
      };
      let y = this.dimensions.height;
      let stackHeight = 0;

      const data = (d.series as ChartDataEntry[]).map((entry, index) => {
        const gap = index && this.gapBetweenSeries ? 1 : 0;
        const value = isNull(entry.value) ? 0 : entry.value;
        const barHeight = this.dimensions.height - this.yScale(value) - gap;
        const barClass = `${this.barClasses[index]} ${entry.cssClass ?? ''}`;
        if (barHeight > 0) {
          stackHeight += barHeight + gap;
          y -= barHeight + gap;
          return { height: barHeight, y, barClass, entryData: { ...entry, cssClass: barClass } };
        } else {
          return { height: 0, y, barClass, entryData: { ...entry, cssClass: barClass } };
        }
      });

      return { stack: { height: stackHeight, y }, x, width, name, data, halo };
    });

    if (!this.showOptionalData) return;

    this.optionalData = this._bars.map((d, index) => {
      const cx = d.x + d.width / 2 || 0;
      const cy = this.dimensions.height - d.stack.height - this.bubbleOffset;
      const cssClass = [...this.barBubbleClasses, this.series[index].optional?.cssClass ?? ''].filter(Boolean);
      return { cx, cy, name: d.name, value: this.series[index].optional?.value, cssClass };
    }) as ChartCircleDto[];
  }

  popoverContextData(index: number, options?: { start: number; end: number }) {
    if (!options) {
      const series = this._bars[index].data.map(d => d.entryData);
      const bubbleClass = this.optionalData[index]?.cssClass ?? [];
      return {
        ...this.series[index],
        bubbleTextClass: this.barBubbleTextClasses,
        bubbleClass,
        series
      };
    }

    const series = this._bars.slice(options.start, options.end)[index].data.map(d => d.entryData);
    const bubbleClass = this.optionalData.slice(options.start, options.end)[index]?.cssClass ?? [];
    return {
      ...this.series.slice(options.start, options.end)[index],
      bubbleTextClass: this.barBubbleTextClasses,
      bubbleClass,
      series
    };
  }
}
