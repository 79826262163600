import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NAVIGATION_LOCALIZATION_SCOPE } from '../../navigation.constants';

@Component({
  selector: 'ca-surveillance-toggle',
  templateUrl: './surveillance-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveillanceToggleComponent {
  localizationScope = NAVIGATION_LOCALIZATION_SCOPE;

  @Input() label = 'surveillance';
  @Input() checked = false;
  @Output() toggle: EventEmitter<void> = new EventEmitter();

  onToggle() {
    this.toggle.emit();
  }
}
