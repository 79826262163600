import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonFormattingModule } from '@capital-access/common/formatting';
import { FireflyGridModule } from '@capital-access/firefly/components';
import { FireflyPopoverModule } from '@capital-access/firefly/components';
import { ExportableColumnDirective } from '../exportable/exportable-column.directive';
import { CustomFieldNumberModule } from '../pipes/custom-field-number/custom-field-number.module';
import { CustomFieldsColumnsComponent } from './custom-fields-columns.component';
import { CustomFieldColumnDirective } from './custom-fields-columns.directive';
import { ShowAllComponent } from './showAll/show-all.component';

@NgModule({
  imports: [
    CommonModule,
    FireflyGridModule,
    FireflyPopoverModule,
    ExportableColumnDirective,
    CommonFormattingModule,
    CustomFieldNumberModule
  ],
  declarations: [CustomFieldsColumnsComponent, CustomFieldColumnDirective, ShowAllComponent],
  exports: [CustomFieldsColumnsComponent, ShowAllComponent]
})
export class CustomFieldsColumnsModule {}
