import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GlobalizationCurrencyModule } from './currency/module';
import { CountryFlagCodePipe } from './pipes/country-flag-code.pipe';

@NgModule({
  imports: [CommonModule, GlobalizationCurrencyModule],
  exports: [CountryFlagCodePipe],
  declarations: [CountryFlagCodePipe]
})
export class CommonGlobalizationModule {}
