import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { logout } from '@capital-access/common/actions';
import { getUserLoadStatus } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';

@Component({
  selector: 'ca-forbidden-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './forbidden-page.component.html'
})
export class ForbiddenPageComponent implements OnInit {
  /**
   * We don't want to localize text for Forbidden page, as at this stage
   * we didn't get userProfile info, thus we don't know their language of choice
   * and it is always will be English.
   */
  title = 'Access denied';
  description = "You don't have permission to view this page. Please reach out to support to ask about the permission.";

  constructor(private store: Store, private router: Router) {}

  onClick() {
    this.store.dispatch(logout());
  }

  ngOnInit() {
    this.store
      .select(getUserLoadStatus)
      .pipe(
        tap(loadStatus => {
          /**
           * When user is on Forbidden page and refreshes it
           * we should check if user hasn't been authorized by that time.
           */
          if (loadStatus === LoadStatus.Pristine) {
            this.router.navigate(['/']);
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
