<div class="d-flex justify-content-between p-3">
  <button
    class="btn btn-simple-icon btn-primary"
    data-automation-id="back-btn"
    [class.d-none]="!showBackBtn"
    (click)="onBackBtnClick()"
  >
    <i class="f-i f-i-chevron-left"></i>
  </button>
  <button
    class="btn btn-simple-icon btn-primary"
    data-automation-id="close-btn"
    [class.d-none]="showBackBtn"
    (click)="modal.dismiss()"
  >
    <i class="f-i f-i-close"></i>
  </button>
  <h4 #modalTitle class="mobile-modal-title">{{ title || context.title }}</h4>
  <button
    [class.invisible]="!showAcceptBtn"
    class="btn btn-simple-icon text-primary-600"
    data-automation-id="accept-btn"
    (click)="modal.close()"
  >
    <i class="f-i f-i-check"></i>
  </button>
</div>
<div #body class="custom-modal-body">
  <ng-template #dynamicContent></ng-template>
</div>
