import { Observable } from 'rxjs';
import { JobStatus } from '../model/jobStatus';

export abstract class JobService {
  /**
   * Submit Job over default websocket connection with OIDC Auth
   * @param type job type
   * @param payload job payload
   * @param throwOnFailure throw error on failed status
   */
  abstract submitJob<TData>(args: {
    type: string;
    payload?: unknown;
    throwOnFailure?: boolean;
  }): Observable<JobStatus<TData>>;

  /**
   * Submit Job over transient websocket connection with Temp Auth token
   * @param type job type
   * @param payload job payload
   * @param throwOnFailure throw error on failed status
   * @param tempAuthToken Temp auth token for anonymous calls
   */
  abstract submitJob<TData>(
    args: {
      type: string;
      payload?: unknown;
      throwOnFailure?: boolean;
    },
    tempAuthToken: string
  ): Observable<JobStatus<TData>>;
}
