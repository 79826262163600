import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { addEvent } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CREATE_EVENT_MODULE_ID, LOAD_CREATE_EVENT_MODULE } from './create-event-module-id.model';

@Injectable()
export class CreateEventEffects {
  addEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addEvent),
        switchMap(({ addBackButton }) =>
          this.commonLazyLoadingService
            .loadModule(CREATE_EVENT_MODULE_ID, LOAD_CREATE_EVENT_MODULE)
            .pipe(switchMap(moduleRef => moduleRef.instance.openCreateEventForm(addBackButton)))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
