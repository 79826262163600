import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caToMM'
})
export class ToMillionsPipe implements PipeTransform {
  transform(value: number | null | undefined) {
    if (value === null || value === undefined) {
      return 0;
    }
    return value / 1000000;
  }
}
