import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Breakpoint } from '@capital-access/firefly/components';
import { SearchCategory, SearchEntity } from '../../+state/models';
import { getFilter } from '../../+state/search.selectors';
import { CategoryIcon, categoryIcon } from './category-icon';

@Component({
  selector: 'ca-search-category-list',
  templateUrl: './search-category-list.component.html',
  styleUrls: ['./search-category-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchCategoryListComponent<T extends SearchEntity> implements OnInit {
  @Input()
  category!: SearchCategory;
  @Input()
  items?: Array<T> | null;
  @Input()
  rowTemplate!: TemplateRef<unknown>;
  @Input()
  header!: string;
  @Input()
  showMoreHeader!: string;
  @Input()
  hasMore!: boolean | null;
  @Input()
  currentCriteria!: string;
  @Input()
  isSearchFailed!: boolean | null;
  @Input()
  trackByFn!: (index: number, item: T) => string;

  @Output()
  updateSelection = new EventEmitter<T>();

  @Output()
  showMore = new EventEmitter<SearchCategory>();

  icon?: CategoryIcon;
  SearchCategory = SearchCategory;
  getFilter$ = this.store.select(getFilter);

  get isMobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  constructor(private store: Store) {}

  ngOnInit() {
    this.icon = categoryIcon[this.category];
  }

  onChangeSelection(item: T) {
    this.updateSelection.emit(item);
  }

  onShowMore() {
    this.showMore.emit(this.category);
  }
}
