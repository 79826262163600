import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../popovers/popover.module';
import { FireflyBubbleSeriesModule } from '../bubble-series';
import { FireflyBarChartPopoverModule } from '../mobile-popovers/bar-chart-popover/chart-mobile-popover.module';
import { FireflyStackedBarSeriesComponent } from './stacked-bar-series.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule, FireflyBubbleSeriesModule, FireflyBarChartPopoverModule],
  declarations: [FireflyStackedBarSeriesComponent],
  exports: [FireflyStackedBarSeriesComponent]
})
export class FireflyStackedBarSeriesModule {}
