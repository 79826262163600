import { ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Directive({
  selector: '[caSaveFormState]',
  exportAs: 'caSaveFormState'
})
export class SaveFormStateDirective<T> implements OnInit, OnDestroy {
  @Input('caSaveFormState') form!: AbstractControl;
  @Input() value$!: Observable<T>;
  @Input() saving$!: Observable<boolean>;
  @Input() failed$!: Observable<boolean>;

  @Output() updated = new EventEmitter();

  private subscription = new Subscription();
  private defaultValue!: T;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscription.add(
      this.failed$.subscribe(failed => {
        this.failed = failed;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.saving$.subscribe(saving => {
        this.saving = saving;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.value$
        .pipe(
          distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
          map((value, index) => ({ value, initial: index === 0 }))
        )
        .subscribe(({ value, initial }) => {
          this.failed = false;
          this.defaultValue = value;
          this.form.patchValue(value);
          this.form.markAsPristine();

          if (!initial) {
            this.updated.emit();
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // exportAs API
  saving = false;
  failed = false;

  get saveDisabled() {
    return this.form.pristine || this.form.invalid || this.saving;
  }

  get discardChangesDisabled() {
    return this.form.pristine || this.saving;
  }

  errorMsg<T>(controlPath: string, errorsMapping: T & { savingError?: string }) {
    const control = this.form.get(controlPath);
    if (control && (control.dirty || control.touched)) {
      const result = Object.entries(errorsMapping)
        .map(([key, value]) => ({ key, value }))
        .find(({ key }) => control.hasError(key))?.value;

      return result ?? ((this.failed && errorsMapping.savingError) || null);
    }
    return null;
  }

  discardChanges() {
    this.form.reset(this.defaultValue);
    this.failed = false;
    this.cdr.markForCheck();
  }
}
