import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getAvailableSecurityIds, getContentPermissions } from '@capital-access/common/user';
import { getSecurities } from '../../+state/selectors';
import { SelectedSecurity } from '../models/selected-security';
import { State } from './reducer';
import { COMMON_SELECTED_SECURITY_FEATURE_KEY } from './reducer';

const getSecuritiesState = createFeatureSelector<State>(COMMON_SELECTED_SECURITY_FEATURE_KEY);

export const getSelectedSecurityId = createSelector(getSecuritiesState, state => state.securityId);
export const getSelectedSecuritySurveillancePermission = createSelector(
  getContentPermissions,
  getSecuritiesState,
  (permissions, { securityId }) => !!securityId && !!permissions?.surveillance.securityIds.includes(securityId)
);
export const getSelectedSecuritySurveillance = createSelector(
  getSelectedSecuritySurveillancePermission,
  getSecuritiesState,
  (permission, { surveillance }) => permission && surveillance
);

export const getSelectedSecurity = createSelector(
  getSelectedSecurityId,
  getSelectedSecuritySurveillance,

  (selectedSecurityId, surveillance): SelectedSecurity | null =>
    selectedSecurityId
      ? {
          id: selectedSecurityId!,
          surveillance: surveillance
        }
      : null
);

export const getSelectedSecurityReportType = createSelector(
  getSecurities,
  getSelectedSecurity,

  (securities, selectedSecurity) => securities.find(({ id }) => id === selectedSecurity?.id)?.reportType
);

export const getSelectedSecurityCompanyName = createSelector(
  getSecurities,
  getSelectedSecurity,

  (securities, selectedSecurity) => securities.find(({ id }) => id === selectedSecurity?.id)?.name
);

export const getSelectedRelatedSecurities = createSelector(
  getSecurities,
  getAvailableSecurityIds,
  getSelectedSecurityId,

  (securities, availableSecurityIds, selectedSecurityId) => {
    const selectedSecurity = securities.find(({ id }) => id === selectedSecurityId);
    const issuerSecurities = securities
      .filter(({ issuerId }) => issuerId === selectedSecurity?.issuerId)
      .filter(({ id }) => availableSecurityIds.includes(id));

    return issuerSecurities;
  }
);
