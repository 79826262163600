import { Inject, Injectable, Optional } from '@angular/core';
import { combineLatest, map, Observable, of } from 'rxjs';
import {
  ActionNavItemConfig,
  EntityNavItemConfig,
  LinkNavItemConfig,
  NavConfig,
  Orderable,
  ToggleNavItemConfig,
  UserNavItemConfig
} from '../models/navigation-item-config';
import { UserSettingsRoutes } from '../models/user-settings-routes';
import { NavigationConfigResolver } from '../navigation-config.resolver';
import { NAV_ITEMS_CONFIGS } from '../tokens/nav-item-configs.token';
import { USER_SETTINGS_ROUTES } from '../tokens/user-settings-routes.token';

@Injectable()
export class NavigationConfigService {
  private sideMobileToggleConfig$: Observable<ToggleNavItemConfig[]>;
  private sideTopConfig$: Observable<LinkNavItemConfig[]>;
  private sideMobileEntityConfig$: Observable<EntityNavItemConfig[]>;
  private sideMobileUserConfig$: Observable<UserNavItemConfig[]>;
  private sideBottomConfig$: Observable<ActionNavItemConfig[]>;

  constructor(
    configResolver: NavigationConfigResolver,
    @Optional() @Inject(NAV_ITEMS_CONFIGS) staticNavConfigs: NavConfig[],
    @Optional() @Inject(USER_SETTINGS_ROUTES) private _userSettingsRoutes: UserSettingsRoutes | null
  ) {
    const configs$ = this.concatConfigs(configResolver.getConfigs(), of(staticNavConfigs ?? []));

    const mobileToggleConfigsArray$ = configs$.pipe(map(x => x.map(config => config.mobileToggle ?? [])));
    const topConfigsArray$ = configs$.pipe(map(x => x.map(config => config.top ?? [])));
    const mobileEntityConfigsArray$ = configs$.pipe(map(x => x.map(config => config.mobileEntity ?? [])));
    const mobileUserConfigsArray$ = configs$.pipe(map(x => x.map(config => config.mobileUser ?? [])));
    const bottomConfigsArray$ = configs$.pipe(map(x => x.map(config => config.bottom ?? [])));

    this.sideMobileToggleConfig$ = this.mergeConfig(mobileToggleConfigsArray$);
    this.sideTopConfig$ = this.getSortedItems(this.mergeConfig(topConfigsArray$));
    this.sideMobileEntityConfig$ = this.getSortedItems(this.mergeConfig(mobileEntityConfigsArray$));
    this.sideMobileUserConfig$ = this.getSortedItems(this.mergeConfig(mobileUserConfigsArray$));
    this.sideBottomConfig$ = this.getSortedItems(this.mergeConfig(bottomConfigsArray$));
  }

  public get sideMobileToggleNavigationConfig() {
    return this.sideMobileToggleConfig$;
  }

  public get sideTopNavigationConfig() {
    return this.sideTopConfig$;
  }

  public get sideMobileEntityNavigationConfig() {
    return this.sideMobileEntityConfig$;
  }

  public get sideMobileUserNavigationConfig() {
    return this.sideMobileUserConfig$;
  }

  public get sideBottomNavigationConfig() {
    return this.sideBottomConfig$;
  }

  public get userSettingsRoutes() {
    return this._userSettingsRoutes;
  }

  private concatConfigs(...configs: Observable<NavConfig[]>[]): Observable<NavConfig[]> {
    return combineLatest(configs).pipe(map(cfg => cfg.reduce<NavConfig[]>((result, item) => result.concat(item), [])));
  }

  private mergeConfig<TConfig extends { localizationKey: string; subItems?: unknown[] }>(
    items: Observable<Array<TConfig>[]>
  ): Observable<TConfig[]> {
    // groupBy item.localizationKey
    return items.pipe(
      map(x => {
        return x.reduce((dict, items) => {
          items.forEach(item => {
            const currSubItems = dict[item.localizationKey] && dict[item.localizationKey].subItems;
            dict[item.localizationKey] = {
              ...dict[item.localizationKey],
              ...item,
              subItems: currSubItems ? currSubItems.concat(item.subItems || []) : item.subItems
            };
          });
          return dict;
        }, <{ [TKey: string]: TConfig }>{});
      }),
      map(dict => Object.values(dict))
    );
  }

  private getSortedItems<TConfig extends Orderable & { subItems?: Orderable[] }>(items: Observable<TConfig[]>) {
    return items.pipe(
      map(item => {
        // sort top level items
        const sortedItems = item.slice().sort(this.sortByOrder);
        // sort subItems
        sortedItems.forEach(i => i.subItems && i.subItems.sort(this.sortByOrder));
        return sortedItems;
      })
    );
  }

  private sortByOrder(a: Orderable, b: Orderable) {
    return (a.order || 0) - (b.order || 0);
  }
}
