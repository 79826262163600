import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingDotsNumber } from '../models/loading-dots-number.enum';

@Component({
  selector: 'f-loading-dots',
  templateUrl: './loading-dots.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyLoadingDotsComponent {
  @Input() dotNumber: LoadingDotsNumber = LoadingDotsNumber.Default;
  @Input() rootCssClass: string = '';
  @Input() animation = true;

  LoadingDotsNumber = LoadingDotsNumber;
}
