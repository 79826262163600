import flatten from 'lodash/flatten';
import { addTag, DeepNullable, EmptyConstructor, tryGetTag } from '@capital-access/common/utils';
import { SerializedSettingsEntity } from '../settings-entity';
import { UserSettingsSection } from '../user-settings-section';
import { fullSettingKey } from './user-settings.utils';

/**
 * Function that return property value based on
 * settings section, known settings key that was registered and initial property value
 * @param T property type
 */
export interface GetValueFn<T = unknown> {
  (settings: UserSettingsSection, knownSettingKeys: string[], initialValue?: T): T;
}

/**
 * Function that return list of settings entities.
 * If object is provided then the values for the entities should be retrieved from there
 * @param T settings type
 */
export interface GetSettingsEntitiesFn<T> {
  (object?: DeepNullable<T>): SerializedSettingsEntity[];
}

/**
 * Settings Cast config.
 * Include property keys and value factory
 */
interface SettingsCast {
  key: string;
  getValue: GetValueFn;
}

/**
 * Settings Object constructor which create instances of a class InstanceType<TCtor>
 * and populates it's fields from settings entities.
 * @param T Settings Object
 */
export class SettingsConstructor<T> {
  public static tagKey = 'user-settings:ctor';

  private readonly settingsEntitiesFactories: GetSettingsEntitiesFn<T>[] = [];
  private readonly settingsCasts: SettingsCast[] = [];

  constructor(private readonly objectCtor: EmptyConstructor<T>) {}

  /**
   * register factory for settings entities list
   * @param factory factory for settings entities list
   */
  public registerSettingsEntities(factory: GetSettingsEntitiesFn<T>) {
    this.settingsEntitiesFactories.push(factory);
  }

  /**
   * register settings property cast config
   * @param key property key
   * @param getValue value provider function
   */
  public addSettingCast<TVal>(key: string, getValue: GetValueFn<TVal>) {
    this.settingsCasts.push({ key, getValue: getValue as GetValueFn });
  }

  /**
   * Create instance of an Settings Object from settings section
   * @param settings settings section
   * @param args settings object class parameter
   */
  public create(settings: UserSettingsSection): T {
    const instance = new this.objectCtor() as Record<string, unknown>;

    // getting list of all register settings key paths
    // here we providing only default properties (created by original constructor) to get only well known properties
    const knownSettingKeys = this.getSettingsEntities('', instance as T).map(e => e.id);

    // populating values from settings section for each properties that have cast settings registered
    this.settingsCasts.forEach(s => {
      instance[s.key] = s.getValue(settings, knownSettingKeys, instance[s.key]);
    });

    return instance as T;
  }

  /**
   * Returns list of all registered settings entities with values populated from settings object if provided
   */
  public getSettingsEntities(section: string = '', settingsObject?: DeepNullable<T>): SerializedSettingsEntity[] {
    return flatten(
      this.settingsEntitiesFactories.map(fn =>
        fn(settingsObject).map(se => ({ id: fullSettingKey(section, se.id), value: se.value }))
      )
    );
  }

  /**
   * Get SettingsConstructor for Settings Object of Type T.
   * @param ctor settings object class constructor
   */
  public static fromCtor<T>(ctor: EmptyConstructor<T>) {
    let settings = tryGetTag<SettingsConstructor<T>>(ctor, SettingsConstructor.tagKey);
    if (!settings) {
      settings = new SettingsConstructor(ctor);
      addTag(ctor, { key: SettingsConstructor.tagKey, descriptor: { value: settings } });
    }
    return settings;
  }
}
