<div
  class="f-chart-boilerplate d-flex flex-column align-items-center"
  [class.flex-column-reverse]="legendOptions?.legendPosition === 'bottom'"
  [class.visible]="chartIsVisible"
  [class.condensed]="condensedOnDesktop"
>
  <div #legendContainer [ngStyle]="legendStyles">
    <f-legend
      *ngIf="showLegend && legendOptions"
      [alignment]="'horizontal'"
      [data]="legendOptions.data"
      [legendRootClass]="legendRootClass"
      [valueCssClass]="legendOptions.valueCssClass || ''"
      [itemMarginSize]="legendOptions.legendItemsMarginSize"
      [contentAlignment]="legendOptions.legendContentAlignment || 'start'"
      [wrappingLinesCount]="legendOptions.legendPosition === 'top' ? 1 : legendOptions.legendWrappingLinesCount || 2"
      [class.mt-2]="legendOptions?.legendPosition === 'bottom'"
      class="w-100 body-50"
    ></f-legend>
  </div>

  <svg *ngIf="dimensions" [attr.width]="chartWidth" [attr.height]="chartHeight" class="overflow-visible">
    <rect
      *ngIf="showBorders"
      [attr.x]="0"
      [attr.y]="0"
      [attr.height]="dimensions.height"
      [attr.width]="dimensions.width"
      [attr.transform]="translate"
      class="f-chart-rect-border"
      fill="transparent"
    ></rect>
    <g [attr.transform]="translate">
      <g
        f-charts-x-axis
        *ngIf="xScale"
        [xScale]="xScale"
        [dimensions]="dimensions"
        [truncateTicks]="truncateXAxisTicks"
        [rotateTicks]="rotateXAxisTicks"
        [maxTickLength]="maxXAxisTickLength"
        [tickFormatting]="xAxisTickFormatting"
        [showGridLines]="showXAxisGridlines"
        [showTicksLabels]="showXAxisTicksLabels"
        [tickLabelClasses]="xAxisTickLabelClasses"
        [tickLabelOffset]="xAxisTickLabelOffset"
        [showDomain]="showXAxisDomain"
        [condensedOnDesktop]="condensedOnDesktop"
        [condensedAxisOnMobile]="condensedAxisOnMobile"
        (dimensionsChanged)="xAxisHeightChanged($event)"
        class="f-chart-x-axis"
      />
      <g
        f-charts-y-axis
        *ngIf="yScale"
        [yScale]="yScale"
        [dimensions]="dimensions"
        [axisPosition]="'left'"
        [showLabel]="showLabels"
        [labelYOffset]="labelsOffset"
        [labelText]="leftAxisLabelText"
        [integerTickLabels]="integerYAxis"
        [maxTicksCount]="maxYAxisTicksCount"
        [maxTickLength]="maxYAxisTickLength"
        [showTicksLabels]="showYAxisTicksLabels"
        [truncateTicks]="truncateYAxisTicks"
        [tickFormatting]="yAxisTickFormatting"
        [showGridLines]="showYAxisGridlines"
        [showDomain]="showYAxisDomain"
        [condensedOnDesktop]="condensedOnDesktop"
        [condensedAxisOnMobile]="condensedAxisOnMobile"
        (dimensionsChanged)="yAxisWidthChanged($event)"
        class="f-chart-y-axis"
      />
      <g
        f-charts-y-axis
        *ngIf="yLineScale"
        [yScale]="yLineScale"
        [ticks]="lineAxisTicks"
        [dimensions]="dimensions"
        [axisPosition]="'right'"
        [showLabel]="showLabels"
        [labelYOffset]="labelsOffset"
        [labelText]="lineAxisLabelText"
        [maxTicksCount]="maxTicksCount"
        [maxTickLength]="maxYAxisTickLength"
        [showTicksLabels]="showLineAxisTicksLabels"
        [truncateTicks]="truncateYAxisTicks"
        [tickFormatting]="lineAxisTickFormatting"
        [showGridLines]="showLineAxisGridlines"
        [showDomain]="showYAxisDomain"
        [condensedOnDesktop]="condensedOnDesktop"
        [condensedAxisOnMobile]="condensedAxisOnMobile"
        (dimensionsChanged)="lineYAxisWidthChanged($event)"
        class="f-chart-line-axis"
      />
      <svg [attr.width]="dimensions.width" [attr.height]="dimensions.height" class="overflow-visible">
        <ng-container *ngTemplateOutlet="chartPatternsTemplate"></ng-container>
        <ng-content></ng-content>
      </svg>
    </g>
  </svg>
</div>
