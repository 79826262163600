import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  SkipSelf,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FireflyMobileModalComponent, ModalContent } from '../../../../modal';
import type { ChartDataEntry, FireflyChart } from '../../../common/models/common-chart-models';
import { FireflyChartBoilerplateComponent } from '../../chart-boilerplate';

export interface ChartMobilePopoverContext {
  popoverData: unknown;
  template: TemplateRef<unknown>;
  chartData: ChartDataEntry[];
  chartLineData: ChartDataEntry[];
  chartBoilerplate: FireflyChartBoilerplateComponent;
  setBarSelection: (n: number) => void;
  resetBarSelection: () => void;
  component: Type<FireflyChart>;
  activeBarIndex: number;
  barClasses: string[];
  infoBubbleShowZero: boolean;
  infoBubbleClasses: string[];
  infoBubbleTextClasses: string[];
  infoBubbleScaleFactor: number;
  lineClasses: string[];
  showPointerLine: boolean;
}

@Component({
  selector: 'f-chart-mobile-popover',
  template: `
    <div style="height: 300px">
      <ng-template #chartContainer></ng-template>
    </div>
    <hr />
    <div class="overflow-auto">
      <ng-container
        *ngTemplateOutlet="$any(context.template); context: { $implicit: context.popoverData }"
      ></ng-container>
    </div>
  `
})
export class FireflyMobileChartPopoverComponent implements OnInit, OnDestroy, ModalContent {
  chart!: ComponentRef<FireflyChart>;
  context!: ChartMobilePopoverContext;
  modalInstance!: NgbActiveModal;
  popoverData!: unknown;

  @ViewChild('chartContainer', { static: true, read: ViewContainerRef })
  private chartContainer!: ViewContainerRef;
  @ViewChild('chartLegendContainer', { static: true, read: ViewContainerRef })
  private chartLegendContainer!: ViewContainerRef;
  private destroyed$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef, @SkipSelf() private modalContainer: FireflyMobileModalComponent) {}

  ngOnInit() {
    this.modalContainer.toggleAcceptBtnVisibility(false);

    if (!this.context.component) return;

    this.popoverData = this.context.popoverData;

    this.setUpChartInstance();

    this.chart.instance.barClick.pipe(takeUntil(this.destroyed$)).subscribe(event => {
      this.handleBarClicks(event);
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected setUpChartInstance() {
    this.chart = this.chartContainer.createComponent(this.context.component);

    this.chart.instance.fitContainer = true;
    this.chart.instance.data = this.context.chartData;
    this.chart.instance.view = { width: window.innerWidth, height: 300 };
    this.chart.instance.showBorders = this.context.chartBoilerplate.showBorders;
    this.chart.instance.showYAxisDomain = this.context.chartBoilerplate.showYAxisDomain;
    this.chart.instance.showXAxisDomain = this.context.chartBoilerplate.showXAxisDomain;
    this.chart.instance.interactions = { click: true, hover: true, preventMobile: true };
    this.chart.instance.leftAxisLabelText = this.context.chartBoilerplate.leftAxisLabelText;
    this.chart.instance.showBarsOptionalData = this.context.chartBoilerplate.showBarsOptionalData;
    this.chart.instance.xAxisTickFormatting = this.context.chartBoilerplate.xAxisTickFormatting;
    this.chart.instance.yAxisTickFormatting = this.context.chartBoilerplate.yAxisTickFormatting;
    this.chart.instance.integerYAxis = this.context.chartBoilerplate.integerYAxis;
    this.chart.instance.infoBubbleShowZero = this.context.infoBubbleShowZero;
    this.chart.instance.infoBubbleScaleFactor = this.context.infoBubbleScaleFactor;
    this.chart.instance.infoBubbleClasses = this.context.infoBubbleClasses;
    this.chart.instance.infoBubbleTextClasses = this.context.infoBubbleTextClasses;
    this.chart.instance.activeBarIndex = this.context.activeBarIndex;
    this.chart.instance.barClasses = this.context.barClasses;
    this.chart.instance.condensedAxisOnMobile = false;
    this.chart.instance.animation = false;

    if (this.context.chartLineData) {
      this.chart.instance.lineData = this.context.chartLineData;
      this.chart.instance.lineClasses = this.context.lineClasses;
      this.chart.instance.lineAxisTickFormatting = this.context.chartBoilerplate.lineAxisTickFormatting;
      this.chart.instance.startLineAxisDomainWithZero = this.context.chartBoilerplate.startLineAxisDomainWithZero;
    }

    if (this.context.chartBoilerplate.lineAxisLabelText) {
      this.chart.instance.lineAxisLabelText = this.context.chartBoilerplate.lineAxisLabelText;
    }

    if (this.context.chartBoilerplate.showLegend && this.context.chartBoilerplate.legendOptions) {
      this.chart.instance.legendOptions = { ...this.context.chartBoilerplate.legendOptions };
      this.chart.instance.legendOptions.legendContentAlignment = 'center';
      this.chart.instance.legendOptions.legendWrappingLinesCount = 10;
      this.chart.instance.legendOptions.legendPosition = 'bottom';
    }
  }

  protected handleBarClicks(event: { data: unknown; barIndex: number }) {
    if (!event) return;
    if (!event.data) {
      this.context.resetBarSelection();
      this.context.activeBarIndex = event.barIndex;
    } else {
      const diff = event.barIndex - this.context.activeBarIndex;
      this.context.setBarSelection(diff);
      this.context.popoverData = event.data;
      this.context.activeBarIndex += diff;
    }
  }
}
