import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { CheckboxColumnComponent, GridComponent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { GridDataResult } from '@progress/kendo-angular-grid/data/data.collection';
import { SortDescriptor } from '@progress/kendo-data-query';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of } from 'rxjs';
import { FireflyModalService, ModalRef } from '../../../modal';
import { MobileSortingModalComponent } from '../../../table';
import { FireflyLocalizationService } from '../../../utils';

@Component({
  selector: 'f-mobile-table-header',
  templateUrl: './mobile-table-header.component.html'
})
export class MobileTableHeaderComponent implements AfterViewInit {
  @Input() title = '';
  @Input() disabledSorting = false;
  @Output() selectAll = new EventEmitter<SelectAllCheckboxState>();
  @Output() expandedRow = new EventEmitter<number | null>();
  @Output() resetSelectedKeys = new EventEmitter<void>();

  private modal: ModalRef<unknown> | null = null;
  private currentSortParameters: SortDescriptor[] = [];
  private cdr = inject(ChangeDetectorRef);
  private modalService = inject(FireflyModalService);
  private localizationService = inject(FireflyLocalizationService, { optional: true });
  private grid = inject(GridComponent);

  showCheckboxColumn = false;
  select$ = this.localizationService ? this.localizationService.localize('mobileColumnHeaderSelect', {}) : of('Select');
  cancel$ = this.localizationService ? this.localizationService.localize('mobileColumnHeaderCancel', {}) : of('Cancel');
  sortBy$ = this.localizationService
    ? this.localizationService.localize('mobileColumnHeaderSortBy', {})
    : of('Sort by');

  get selectText(): Observable<string> {
    return this.showCheckboxColumn ? this.cancel$ : this.select$;
  }

  get sort(): SortDescriptor[] {
    return this.grid.sort!;
  }

  get isLoading(): boolean {
    return this.grid.loading;
  }

  get hasNoData(): boolean {
    const dataResultTotal = (this.grid.data as GridDataResult | null)?.total;
    return !(dataResultTotal || (this.grid.data as Array<unknown>).length);
  }

  get checkboxColumn(): CheckboxColumnComponent | undefined {
    return (this.grid.columns as QueryList<CheckboxColumnComponent>).find(col => col.isCheckboxColumn);
  }

  ngAfterViewInit(): void {
    this.showCheckboxColumn = !this.checkboxColumn?.hidden;

    this.grid.columns
      .filter(col => !col.hidden && col.matchesMedia)
      .map(col => {
        col.reorderable = false;
        col.includeInChooser = false;
      });

    this.cdr.detectChanges();
  }

  openSortingModal(title: string): void {
    this.currentSortParameters = cloneDeep(this.sort);

    this.modal = this.modalService.open({
      component: MobileSortingModalComponent,
      title: title,
      context: { grid: this.grid, sort: this.currentSortParameters, disabled: this.disabledSorting },
      mobile: true
    });

    this.modal.result
      .then(() => {
        this.expandedRow.emit(null);
        this.grid.wrapper.nativeElement.querySelector('.k-grid-content').scrollTop = 0;
        this.grid.sortChange.emit(this.currentSortParameters);
      })
      .finally(() => (this.modal = null));
  }

  onSelect(): void {
    if (this.isLoading || this.hasNoData || !this.checkboxColumn) return;

    this.checkboxColumn.hidden = !this.checkboxColumn.hidden;
    if (this.checkboxColumn.hidden) {
      this.resetSelectedKeys.emit();
      this.selectAll.emit('unchecked');
    }
  }
}
