import { Injectable, isDevMode } from '@angular/core';
import { AppSettingsService, EnvironmentSettings } from '@capital-access/common/settings';
import { Account, User } from '@capital-access/common/user';
import { PendoObject } from './models/pendo-object';

//this variable will be populated when pendo.js loaded
declare const pendo: PendoObject;

@Injectable()
export class PendoService {
  private prodEnvironmentName = 'prod';
  constructor(private settingsService: AppSettingsService) {}

  initialize(user: User, userAccount: Account): void {
    if (typeof pendo !== 'undefined') {
      const environmentName = this.settingsService.getSettings<EnvironmentSettings>().environmentName;
      pendo.initialize({
        visitor: {
          id: this.appendEnvName(user.id, environmentName),
          accountId: user.accountId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          ipreoAccountId: user.ipreoAccountId,
          samId: user.samId,
          env: environmentName
        },
        account: {
          id: this.appendEnvName(userAccount.id, environmentName),
          address: userAccount.address,
          name: userAccount.name,
          samId: userAccount.samId,
          warehouseId: userAccount.warehouseId
        }
      });
    }
  }

  insertPendoScript(): () => Promise<void> {
    return async () => {
      if (isDevMode()) {
        return;
      }

      await new Promise<void>(resolve => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = '/vendor/pendo/pendo.js';
        document.head.appendChild(script);
        script.onerror = async () => {
          // The script may have been blocked by an ad blocker
          resolve();
        };
        script.onload = async () => {
          resolve();
        };
      });
    };
  }

  private appendEnvName(value: number, environmentName: string) {
    const envNameLower = environmentName.toLowerCase();
    return envNameLower === this.prodEnvironmentName ? value.toString() : `${value}_${envNameLower}`;
  }
}
