import { dictionary, type, value } from '@capital-access/common/user';

export class CiqEventsPreferencesItem {
  @value(type.array.ofNumber) macroIndustries: number[] | null = null;
  @value(type.array.ofNumber) midIndustries: number[] | null = null;
  @value(type.array.ofNumber) microIndustries: number[] | null = null;
  @value(type.array.ofNumber) listIds: number[] | null = null;
}

export class CiqEventsPreferences {
  @dictionary(CiqEventsPreferencesItem, { path: '' }) preferences: Record<string, CiqEventsPreferencesItem> = {};
}
