import { EntityState } from '@ngrx/entity';
import { SerializedSettingsEntity } from '../models/settings-entity';

export const USER_SETTINGS_FEATURE_KEY = 'common.user.settings';

export interface SettingsState extends EntityState<SerializedSettingsEntity> {
  savingIds: string[];
  failedIds: string[];
  markedForResetIds: string[];
}
