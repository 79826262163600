<ng-template
  *ngIf="suggesterItemTemplate; else defaultItemTemplate"
  [ngTemplateOutlet]="suggesterItemTemplate"
  [ngTemplateOutletContext]="{
    item: item,
    inputValue: inputValue,
    firstLineId: 'first-line',
    secondLineId: 'second-line',
    isSingleListItem: isSingleListItem
  }"
></ng-template>

<ng-template #defaultItemTemplate>
  <span
    *ngIf="!!linkPath ? !(item | fNestedValue : linkPath) : true"
    innerHTML="{{ item | fNestedValue : valuePath | fHighlight : inputValue }}"
    title="{{ item | fNestedValue : valuePath }}"
  ></span>

  <a
    *ngIf="!!linkPath ? (item | fNestedValue : linkPath) : false"
    class="link link-main"
    [href]="item | fNestedValue : linkPath"
    target="_blank"
    innerHTML="{{ item | fNestedValue : valuePath | fHighlight : inputValue }}"
    title="{{ item | fNestedValue : valuePath }}"
  ></a>
</ng-template>
