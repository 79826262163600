import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavConfig, NavConfigFn, NavigationConfigProvider } from '@capital-access/common/navigation';
import { CI_APPLICATION, Entitlements, getEntitlements } from '@capital-access/common/user';

@Injectable()
export class InsightNavConfigProvider implements NavigationConfigProvider {
  featureToggle$ = this.store.select(getFeatureToggle('ci-module-ISSYN-1393'));

  constructor(private store: Store) {}

  resolveNavConfig: NavConfigFn = () => {
    return combineLatest([this.store.select(getEntitlements), this.featureToggle$]).pipe(
      map(([userEntitlements, isFeatureToggleEnabled]) => {
        return <NavConfig>{
          top: [
            {
              localizationKey: 'capitalInsightModule',
              icon: 'f-i-insight',
              order: 700,
              link: 'insight',
              enabled: this.hasRole(userEntitlements) && isFeatureToggleEnabled
            }
          ]
        };
      })
    );
  };

  private hasRole(userEntitlements: Entitlements | null) {
    return userEntitlements?.applications.some(f => f.sourceId === CI_APPLICATION);
  }
}
