import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../../utils';
import { LegendDataItem } from './legend-chart.model';

@Component({
  selector: 'f-legend',
  templateUrl: './legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyLegendComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  private resizeObserver = new ResizeObserver(() => this.resizeLegend());
  private _valueCssClass = 'ms-1 body-70 fw-bold';
  private _wrappingLinesCount = 2;

  @Input() set valueCssClass(val: string) {
    if (val) this._valueCssClass = val;
  }
  get valueCssClass() {
    return this._valueCssClass;
  }
  @Input() alignment: 'horizontal' | 'vertical' = 'horizontal';
  @Input() contentAlignment: 'start' | 'center' | 'end' = 'center';
  @Input() popoverPlacement = 'top bottom';
  @Input() data!: LegendDataItem[];
  @Input() itemMarginSize!: number | undefined | null;
  @Input() legendRootClass: string | undefined = '';
  @Input() set wrappingLinesCount(count: number) {
    if (count > 0) this._wrappingLinesCount = count;
  }

  @ViewChild('showAllBtn') showAllBtn!: ElementRef;
  @HostBinding('class') class = `d-block ${this.alignment === 'horizontal' ? 'overflow-hidden' : ''}`;

  raf!: number;
  marginClass = '';
  showAll = false;
  showAllTitle$ = of('Show all');

  get legendPopoverClass() {
    return `f-legend-popover ${this.legendRootClass}`;
  }

  get showAllPopoverPlacement() {
    return this.alignment === 'horizontal' ? 'top bottom' : 'end start';
  }

  get showAllBtnStyles() {
    const order = this.data.length;
    return { order, display: this.showAll ? 'block' : 'none' };
  }

  constructor(
    private host: ElementRef,
    private cdr: ChangeDetectorRef,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  ngOnInit() {
    if (this.localizationService) {
      this.showAllTitle$ = this.localizationService.localize('showAllTitle', {});
    }
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.host.nativeElement.parentElement);
    this.resizeLegend();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.alignment || changes.itemMarginSize) {
      this.setMarginClass();
    }
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  resizeLegend() {
    cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(() => {
      if (this.alignment === 'horizontal') this.resizeHorizontalLegend();
      if (this.alignment === 'vertical') this.resizeVerticalLegend();
    }) as number;
  }

  private resizeHorizontalLegend() {
    const legendItem = this.host.nativeElement.querySelector('.legend-item');

    if (!legendItem) return;

    const itemRect = legendItem?.getBoundingClientRect();
    const legend = this.host.nativeElement.querySelector('.legend');
    const legendRect = legend.getBoundingClientRect();
    const maxHeight = itemRect.height * this._wrappingLinesCount;

    this.host.nativeElement.style.maxHeight = `${maxHeight}px`;
    this.showAll = legendRect.height > maxHeight;

    this.toggleItemsHiddenState();
    this.cdr.detectChanges();
  }

  private resizeVerticalLegend() {
    const hostParent = this.host.nativeElement.parentElement;
    const showAllBtnRect = this.showAllBtn.nativeElement.getBoundingClientRect();
    const legendItems = this.host.nativeElement.querySelectorAll('.legend-item');
    const legend = this.host.nativeElement.querySelector('.legend');
    const legendRect = legend.getBoundingClientRect();
    const minHeight = this.getHeightWithMargins(legendItems[0]) * 2;
    const fullHeight = this.getHeightWithMargins(legendItems[0]) * legendItems.length;
    const maxHeight = fullHeight < hostParent.offsetHeight ? fullHeight : hostParent.offsetHeight;

    this.host.nativeElement.style.minHeight = `${minHeight}px`;
    this.host.nativeElement.style.maxHeight = `${maxHeight}px`;

    const hostRect = this.host.nativeElement.getBoundingClientRect();
    this.showAll = legendRect.height - showAllBtnRect.height > hostRect.height;

    this.toggleItemsHiddenState();
    this.cdr.detectChanges();
  }

  private toggleItemsHiddenState() {
    const legendItems = this.host.nativeElement.querySelectorAll('.legend-item');
    const hostRect = this.host.nativeElement.parentElement.getBoundingClientRect();
    let firstHiddenItemIndex: number;

    if (!this.showAll) {
      document.querySelector(`.${this.legendPopoverClass}`)?.remove();
      this.showAllBtn.nativeElement.style.order = this.data.length;
    }

    [...legendItems].forEach((item: HTMLElement, index: number) => {
      const itemRect = item.getBoundingClientRect();
      if (this.showAll && itemRect.bottom > hostRect.bottom) {
        if (!firstHiddenItemIndex) firstHiddenItemIndex = index - 1;
        item.classList.add('legend-item-hidden');
      } else {
        item.classList.remove('legend-item-hidden');
      }
    });

    const firstHiddenItem = legendItems[firstHiddenItemIndex!];

    if (firstHiddenItem) {
      this.showAllBtn.nativeElement.style.order = `${firstHiddenItemIndex!}`;
      const showAllBtnRect = this.showAllBtn.nativeElement.getBoundingClientRect();

      const showAllBtnOverflow =
        this.alignment === 'horizontal'
          ? showAllBtnRect.y >= hostRect.y + hostRect.height
          : showAllBtnRect.bottom >= hostRect.bottom;

      if (showAllBtnOverflow) {
        firstHiddenItem.classList.add('legend-item-hidden');
        this.showAllBtn.nativeElement.style.order = `${firstHiddenItemIndex! - 1}`;
      }
    }
  }

  private setMarginClass(): void {
    if (this.itemMarginSize === null || this.itemMarginSize === undefined) {
      this.marginClass = this.alignment === 'vertical' ? 'mb-2' : 'me-3';
    } else {
      this.marginClass = this.alignment === 'vertical' ? `mb-${this.itemMarginSize}` : `me-${this.itemMarginSize}`;
    }
  }

  private getHeightWithMargins(el: HTMLElement) {
    const mt = window.getComputedStyle(el).marginTop ?? '0';
    const mb = window.getComputedStyle(el).marginBottom ?? '0';
    const margins = parseInt(mt) + parseInt(mb);
    return el.offsetHeight + margins;
  }
}
