<div
  class="card"
  [class.is-loading]="loading"
  [class.mh-100]="isContentExpanded"
  [class.scrollable]="hasScrollableContent"
  [ngClass]="[size, rootCssClass]"
  data-automation-id="card"
>
  <div class="card-body" [ngClass]="bodyCssClass">
    <div class="card-title">
      <div class="card-title-start" [ngClass]="titleStartCssClass">
        <span class="card-title-text" data-automation-id="card-title">{{ title }}</span>
        <span class="card-title-extensions" data-automation-id="card-title-extensions">
          <ng-content select="[fCardTitleExtensions]"></ng-content>
        </span>
      </div>
      <div class="card-title-actions card-title-end">
        <ng-content select="[fCardActions]"></ng-content>
      </div>
    </div>
    <div class="card-text" [ngClass]="contentCssClass" data-automation-id="card-content">
      <ng-content *ngIf="!loading; else loader"></ng-content>
    </div>
    <span *ngIf="isScrollable" class="mt-5" [class.card-link-show-more]="!isContentExpanded">
      <a class="link link-primary" (click)="onShowLinkClick()">
        {{ showLinkTitle | async }}
      </a>
    </span>
  </div>
</div>

<ng-template #loader>
  <ng-container [ngTemplateOutlet]="loaderTemplate || defaultLoaderTemplate"></ng-container>
</ng-template>

<ng-template #defaultLoaderTemplate>
  <f-skeleton-small-card-with-text-and-badges class="heading-50"> </f-skeleton-small-card-with-text-and-badges>
</ng-template>
