import { CultureCode } from '@capital-access/common/globalization';
import { TimeFormat } from '../models/date-formatting.models';

export const timePatternOptions: Record<CultureCode, Record<TimeFormat, string>> = {
  ['en-US']: {
    default: 'h:mm a',
    short: 'h a'
  },
  ['en-GB']: {
    default: 'h:mm a',
    short: 'h a'
  },
  ['ja']: {
    default: 'H:mm',
    short: 'H'
  },
  ['de-DE']: {
    default: 'H:mm',
    short: 'H'
  },
  ['pt-BR']: {
    default: 'H:mm',
    short: 'H'
  },
  ['fr-FR']: {
    default: 'H:mm',
    short: 'H'
  },
  ['ru']: {
    default: 'H:mm',
    short: 'H'
  },
  ['en-CA']: {
    default: 'h:mm a',
    short: 'h a'
  },
  ['de-AT']: {
    default: 'H:mm',
    short: 'H'
  },
  ['fi']: {
    default: 'H:mm',
    short: 'H'
  },
  ['es-ES']: {
    default: 'H:mm',
    short: 'H'
  },
  ['es-MX']: {
    default: 'H:mm',
    short: 'H'
  },
  ['zh-CN']: {
    default: 'H:mm',
    short: 'H'
  },
  ['es-AR']: {
    default: 'H:mm',
    short: 'H'
  },
  ['da']: {
    default: 'H:mm',
    short: 'H'
  },
  ['nl']: {
    default: 'H:mm',
    short: 'H'
  },
  ['sv']: {
    default: 'H:mm',
    short: 'H'
  },
  ['fr-BE']: {
    default: 'H:mm',
    short: 'H'
  },
  ['de-CH']: {
    default: 'H:mm',
    short: 'H'
  },
  ['es-CO']: {
    default: 'H:mm',
    short: 'H'
  },
  ['it']: {
    default: 'H:mm',
    short: 'H'
  },
  ['tr']: {
    default: 'H:mm',
    short: 'H'
  },
  ['hi']: {
    default: 'H:mm',
    short: 'H'
  },
  ['en-ZA']: {
    default: 'h:mm a',
    short: 'h a'
  },
  ['el']: {
    default: 'h:mm a',
    short: 'h a'
  }
};
