<ng-container [caLocalizationScope]="localizationScope">
  <span
    class="heading-20 d-block mb-3 mt-6"
    data-automation-id="activist-investors-title"
    [caLocalizationScope]="localizationScope"
  >
    {{ 'activistInvestorsTitle' | caLocalize }}
  </span>
  <div class="form-check form-switch d-flex mt-2">
    <input
      class="form-check-input"
      type="checkbox"
      id="activistToggle"
      data-automation-id="activist-toggle"
      (click)="onClick()"
      [checked]="excludeActivistTypes"
    />
    <label class="form-check-label body-70" data-automation-id="activist-toggle-title" for="activistToggle">
      {{ 'excludeActivistTitle' | caLocalize }}
    </label>
  </div>
  <div *ngIf="!excludeActivistTypes" class="mt-2">
    <f-multi-choice-chip-group
      data-automation-id="activist-filter-selector"
      [size]="size"
      [items]="activists$ | async"
      [selectedIds]="selectedItems"
      (toggle)="onChipToggle($event)"
    >
    </f-multi-choice-chip-group>
  </div>
</ng-container>
