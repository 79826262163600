import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[fSuggesterList]'
})
export class FireflySuggesterListDirective {
  @Input() dropdownMaxHeight!: number;

  @HostBinding('class.overflow-auto') overflowAuto = true;
  @HostBinding('style.max-height.px') get maxHeight() {
    return this.dropdownMaxHeight;
  }
}
