<div class="h-100 ps-2 pe-3 pt-4 ca-one-search-panel">
  <ca-filter-panel
    data-automation-id="filter-panel"
    *ngIf="showFilters$ | async"
    [filters]="filters$ | async"
    [selectedFilter]="filter$ | async"
    (filterChanged)="onFilterChanged($event)"
  ></ca-filter-panel>

  <ca-action-panel
    data-automation-id="action-panel"
    *ngIf="showActions$ | async"
    [specialActions]="availableSpecialActions$ | async"
    (startSpecialAction)="onStartSpecialAction($event)"
  ></ca-action-panel>
</div>
