import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonTransactionEffects } from './+store/transaction/transaction.effects';
import { reducer } from './+store/transaction/transaction.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('calendar-sync-transaction', reducer),
    EffectsModule.forFeature([CommonTransactionEffects])
  ]
})
export class EventsCalendarSyncTransactionModule {}
