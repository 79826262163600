import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySkeletonLoaderModule } from '../skeleton-loader.module';
import { FireflySkeletonLargeCardWithScheduleComponent } from './skeleton-large-card-with-schedule.component';
import { FireflySkeletonLargeCardWithTextOneColumnComponent } from './skeleton-large-card-with-text-one-column.component';
import { FireflySkeletonLargeCardWithTextTwoColumnsComponent } from './skeleton-large-card-with-text-two-columns.component';

const components = [
  FireflySkeletonLargeCardWithTextTwoColumnsComponent,
  FireflySkeletonLargeCardWithTextOneColumnComponent,
  FireflySkeletonLargeCardWithScheduleComponent
];

@NgModule({
  imports: [CommonModule, FireflySkeletonLoaderModule],
  declarations: [...components],
  exports: [...components]
})
export class FireflySkeletonLargeCardLoaderModule {}
