import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isString, LoadStatus } from '@capital-access/common/utils';
import { FEATURE_TOGGLE_FEATURE } from './feature-toggle.models';
import { State } from './feature-toggle.reducers';
import { getFeatureToggleValue, isFeatureToggleEnabled } from './feature-toggle.utils';

export const getState = createFeatureSelector<State>(FEATURE_TOGGLE_FEATURE);
export const getFeatureTogglesState = createSelector(getState, state => state.entities);

const getFeatureTogglesLoadStatus = createSelector(getState, state => state.loadStatus);

export const getFeatureTogglesLoaded = createSelector(
  getFeatureTogglesLoadStatus,
  loadStatus => loadStatus == LoadStatus.Loaded
);

export const getFeatureTogglesLoadFailed = createSelector(
  getFeatureTogglesLoadStatus,
  loadStatus => loadStatus == LoadStatus.Failed
);

export const getFeatureToggle = (featureToggle: string | string[]) =>
  createSelector(getFeatureTogglesState, (state: string[]) =>
    isString(featureToggle)
      ? isFeatureToggleEnabled(state, getFeatureToggleValue(featureToggle))
      : featureToggle &&
        featureToggle.length !== 0 &&
        featureToggle.reduce(
          (previous, current) => previous && isFeatureToggleEnabled(state, getFeatureToggleValue(current)),
          true
        )
  );
