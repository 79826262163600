import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActionNavItem, EntityNavItem, LinkNavItem, UserNavItem } from '@capital-access/common/navigation';

@Component({
  selector: 'ca-nav-item-icon',
  templateUrl: './nav-item-icon.component.html',
  styleUrls: ['./nav-item-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemIconComponent implements OnInit {
  @Input() navItem!: LinkNavItem | EntityNavItem | UserNavItem | ActionNavItem;

  icon = '';
  iconText = '';

  ngOnInit() {
    if ('icon' in this.navItem && this.navItem.icon) {
      this.icon = this.navItem.icon;
    } else if ('iconText' in this.navItem && this.navItem.iconText) {
      this.iconText = this.navItem.iconText;
    }
  }
}
