import { Pipe, PipeTransform } from '@angular/core';

const httpOrHttpsAtTheBeginning = /^https?:\/\//;
const slashAtTheEnd = /\/$/;

@Pipe({ name: 'caUrlHostname' })
export class UrlHostnamePipe implements PipeTransform {
  transform(url?: string) {
    return url?.replace(httpOrHttpsAtTheBeginning, '').replace(slashAtTheEnd, '');
  }
}
