<ng-container [caLocalizationScope]="localizationScope">
  <f-dropdown
    *ngIf="apps.length > 1"
    [hideCaret]="true"
    [icon]="'f-i-bento-menu'"
    [btnStyle]="'btn-simple-icon btn-primary'"
    [tooltip]="'appSwitcherListTitle' | caLocalize"
    [tooltipPlacement]="'bottom-left'"
    [placement]="'bottom-left'"
    [ngClass]="rootClass"
    class="me-3 ms-n1"
  >
    <f-list class="pt-0">
      <f-list-item
        [withHover]="false"
        data-automation-id="f-list-title"
        class="heading-20 border-bottom d-none d-sm-block"
      >
        <span>{{ 'appSwitcherListTitle' | caLocalize }}</span>
      </f-list-item>

      <ng-container *ngFor="let app of apps">
        <f-list-item
          *ngIf="title !== app.name"
          class="d-flex align-items-center text-nowrap ps-2"
          (click)="navigateTo(app.url)"
        >
          <i class="f-i f-i-sm me-2" [ngClass]="app.icon"></i>
          {{ app.name }}
        </f-list-item>
      </ng-container>
    </f-list>
  </f-dropdown>
</ng-container>
