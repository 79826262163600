<span
  tabindex="0"
  class="chip"
  [ngClass]="chipClasses"
  [class.disabled]="disabled"
  (keyup.enter)="onChipClick($event, false)"
  (click)="onChipClick($event)"
>
  <i class="f-i f-i-check" *ngIf="type === ChipType.MultiChoice"></i>
  <span class="chip-content"><ng-content></ng-content></span>
  <i class="f-i f-i-close" [class.disabled]="disabled" *ngIf="isEditableChip" (click)="onChipClick($event, false)"></i>
</span>
