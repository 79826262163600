import { createAction, props } from '@ngrx/store';
import { CustomFieldsEntityType } from './models/custom-fields.model';

export const createCustomField = createAction(
  scoped`Create Custom Field`,
  props<{
    entityType: CustomFieldsEntityType;
    addBackButton?: boolean;
  }>()
);

export const editCustomField = createAction(
  scoped`Edit Custom Field`,
  props<{
    id: number;
    entityType: CustomFieldsEntityType;
    addBackButton?: boolean;
  }>()
);

export const manageCustomField = createAction(
  scoped`Manage Custom Field`,
  props<{
    entityType: CustomFieldsEntityType;
    addBackButton?: boolean;
  }>()
);

export const createCustomFieldSuccess = createAction(
  scoped`Create Custom Field Success`,
  props<{ customFieldId: number; entityType: CustomFieldsEntityType }>()
);

export const editCustomFieldSuccess = createAction(
  scoped`Edit Custom Field Success`,
  props<{ entityType: CustomFieldsEntityType }>()
);

export const removeCustomFieldSuccess = createAction(
  scoped`Remove Custom Field Success`,
  props<{ entityType: CustomFieldsEntityType }>()
);

export const saveNewCustomFieldsOrderSuccess = createAction(
  scoped`Save New Custom Fields Order Success`,
  props<{ entityType: CustomFieldsEntityType | null }>()
);

export const bulkAssignCustomFields = createAction(
  scoped`Bulk Assign Custom Fields`,
  props<{ ids: string[]; entityType: CustomFieldsEntityType }>()
);

export const saveBulkCustomFieldsAssignmentSuccess = createAction(scoped`Save Bulk Custom Fields Assignment Success`);

export const assignCustomFields = createAction(
  scoped`Assign Custom Fields`,
  props<{ id: string; entityType: CustomFieldsEntityType; entityName: string }>()
);

export const saveCustomFieldsAssignmentSuccess = createAction(
  scoped`Save Custom Fields Assignment Success`,
  props<{ entityName: string }>()
);

function scoped(templateString: TemplateStringsArray) {
  return '[Common Custom Fields] ' + templateString[0];
}
