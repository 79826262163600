import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyListModule } from '../../lists';
import { FireflyDropdownModule } from '../dropdown.module';
import { FireflyDropdownWithInitialsComponent } from './dropdown-with-initials.component';

@NgModule({
  imports: [CommonModule, FireflyDropdownModule, FireflyListModule],
  declarations: [FireflyDropdownWithInitialsComponent],
  exports: [FireflyDropdownWithInitialsComponent]
})
export class FireflyDropdownWithInitialsModule {}
