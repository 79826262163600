import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { AppSettingsService } from '@capital-access/common/settings';
import { HttpErrorLogInterceptor } from './interceptors/http-error-log.interceptor';
import { HttpLoggerInterceptor } from './interceptors/http-logger.interceptor';
import { LogService, logServiceInitFactory } from './log.service';
import { LogErrorHandler } from './log-error-handler';
import { APP_NAME, LOG_LEVEL } from './logger.model';
import { provideLoggers, provideLogLevel } from './logging-resolver';

@NgModule({
  providers: [
    {
      provide: LOG_LEVEL,
      useFactory: provideLogLevel,
      deps: [AppSettingsService]
    }
  ]
})
export class CommonLoggingModule {
  public static forRoot({
    sendLogs,
    appName
  }: {
    sendLogs: boolean;
    appName: string;
  }): ModuleWithProviders<CommonLoggingModule> {
    return {
      ngModule: CommonLoggingModule,
      providers: [
        ...provideLoggers({ sendLogs }),
        {
          provide: APP_NAME,
          useValue: appName
        },
        {
          provide: APP_INITIALIZER,
          useFactory: logServiceInitFactory,
          multi: true,
          deps: [LogService]
        },
        {
          provide: ErrorHandler,
          useClass: LogErrorHandler
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorLogInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpLoggerInterceptor,
          multi: true
        }
      ]
    };
  }
}
