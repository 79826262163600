import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MultilineTextTruncateComponent } from './multiline-text-truncate.component';

@NgModule({
  declarations: [MultilineTextTruncateComponent],
  imports: [CommonModule],
  exports: [MultilineTextTruncateComponent]
})
export class FireflyMultilineTextTruncateModule {}
