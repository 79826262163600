import { ScaleLinear } from 'd3-scale';

const getBoundedTicks = (scale: ScaleLinear<number, number>, maxCount: number): number[] => {
  const ticks = scale.ticks(maxCount);
  if (ticks.length > maxCount) {
    return getBoundedTicks(scale, maxCount - 1);
  } else {
    return ticks;
  }
};

export const getTicks = (scale: ScaleLinear<number, number>, maxCount: number, integersOnly = false): number[] => {
  const ticks = scale.ticks(maxCount);
  if (integersOnly && ticks.some(tick => !Number.isInteger(tick))) {
    return ticks.filter(tick => Number.isInteger(tick));
  } else {
    return getBoundedTicks(scale, maxCount);
  }
};
