<div class="modal-header">
  <h4 class="modal-title" data-automation-id="modal-title">{{ context.title }}</h4>
</div>
<div class="modal-body" data-automation-id="modal-body">
  {{ context.body }}
</div>
<div class="modal-footer">
  <button
    type="button"
    data-automation-id="confirm-btn"
    class="btn"
    [ngClass]="cssBtnColorClass"
    (click)="confirmClick()"
  >
    {{ context.actionText }}
  </button>
  <button type="button" data-automation-id="dismiss-btn" class="btn btn-secondary" (click)="modal.dismiss()">
    {{ context.dismissText }}
  </button>
</div>
