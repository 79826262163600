import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationConfigService } from '@capital-access/common/navigation';
import { getActivatedRoute } from '@capital-access/common/router';

@Injectable()
export class SideNavItemHighlightService {
  public activeLink$: Observable<string | null>;

  constructor(navConfigService: NavigationConfigService, router: Router, private store: Store) {
    // flatten links from items and subItems
    const itemLinks$ = combineLatest([
      navConfigService.sideTopNavigationConfig,
      navConfigService.sideMobileUserNavigationConfig
    ]).pipe(
      map(config =>
        config.flat().reduce<string[]>((result, item) => {
          if (item.link) {
            result.push(item.link);
          } else if ('subItems' in item) {
            result.push(...(item.subItems ? item.subItems.map(i => i.link) : []));
          }
          return result;
        }, [])
      )
    );

    this.activeLink$ = combineLatest([itemLinks$, this.store.select(getActivatedRoute)]).pipe(
      map(([items]) => {
        const forwardSlashRegex = /^\//;
        const currentUrl = router.url.replace(forwardSlashRegex, '');

        const activeLinks = items.filter(link => {
          link = link.replace(forwardSlashRegex, '');
          return currentUrl === link || (link && currentUrl.startsWith(link));
        });
        if (activeLinks.length > 0) {
          return activeLinks[0];
        }
        return null;
      })
    );
  }
}
