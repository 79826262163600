import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { CommonContactSupportModule } from '../contact-support/contact-support.module';
import { ErrorPageComponent } from './error-page.component';

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [
    CommonModule,
    CommonContactSupportModule,
    CommonLocalizationModule.forFeature(COMMON_COMPONENTS_LOCALIZATION_SCOPE)
  ],
  exports: [ErrorPageComponent]
})
export class CommonErrorPageModule {}
