import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonUserSettingsEffects } from './+state/settings.effects';
import { settingsReducer } from './+state/settings.reducer';
import { USER_SETTINGS_FEATURE_KEY } from './+state/settings.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_SETTINGS_FEATURE_KEY, settingsReducer),
    EffectsModule.forFeature([CommonUserSettingsEffects])
  ]
})
export class UserSettingsModule {}
