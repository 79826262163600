import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { MaybeArray, TranslocoScope, TranslocoService } from '@ngneat/transloco';
import { isString } from '@capital-access/common/utils';

interface TranslocoServiceInternals extends TranslocoService {
  _setScopeAlias(scope: string, alias: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class LocalizationScopesLoadQueue {
  private scopes: string[] = ['']; // empty string as initial value to load root `capital-access` scope
  private loadedScopes: string[] = [];

  constructor(private transloco: TranslocoService) {}

  /**
   * Queues scopes for loading if it's not loaded yet
   * @param providerScope localization scope to load
   */
  public queue(providerScope: MaybeArray<TranslocoScope>) {
    if (!Array.isArray(providerScope)) {
      providerScope = [providerScope];
    }
    this.scopes.push(
      ...(providerScope.map(s => this.resolveScope(s)).filter(s => s && !this.loadedScopes.includes(s)) as string[])
    );
  }

  public get empty() {
    return this.scopes.length === 0;
  }

  /**
   * Get all queued scopes and clear the queue
   */
  public getAll() {
    const notLoadedScopes = [...this.scopes];
    this.scopes = [];
    this.loadedScopes.push(...notLoadedScopes);
    return notLoadedScopes;
  }

  private resolveScope(scope: TranslocoScope): string | null {
    if (!scope) {
      return null;
    }
    if (isString(scope)) {
      return scope;
    }
    if (scope.alias) {
      /**
       * Register scope alias to make sure values will be cached correctly.
       * Otherwise default camelCase alias will be used and so localize pipe will display incorrect values :(.
       * For example, "user-settings" scoped will be stored as "userSettings" and
       * "'user-settings.applicationSettings' | caLocalize" will return 'user-settings.applicationSettings' instead of actual value
       */
      (this.transloco as TranslocoServiceInternals)._setScopeAlias(scope.scope, scope.alias);
    }
    return scope.scope;
  }
}
