import { GroupedMultipleData } from '../../models/grouped-data.model';
import { GroupedMultipleItem } from '../../models/grouped-item.model';
import { ValueFunction } from '../../models/value-function.type';
import { isDisabledItem } from '../list-items/list-items.utils';

export function updateActionButtonsOnListChanges(
  forAllList: boolean,
  listData: GroupedMultipleData,
  disabledPath: string,
  valueFunction: ValueFunction<boolean>
) {
  forAllList
    ? updateActionButtonsForList(listData, disabledPath, valueFunction)
    : updateActionButtonsForGroups(listData, disabledPath, valueFunction);
}

function updateActionButtonsForList(
  listData: GroupedMultipleData,
  disabledPath: string,
  valueFunction: ValueFunction<boolean>
) {
  updateClearAllButtonForList(listData, false);
  updateSelectAllButtonForList(listData, false);

  outer: for (const group of listData.groupedItems) {
    for (const item of group.value) {
      if (!isClearAllButtonPresentInList(listData) && item.isSelected) {
        updateClearAllButtonForList(listData, true);
      }
      if (
        !isSelectAllButtonPresentInList(listData) &&
        !item.isSelected &&
        !isDisabledItem(item, disabledPath, valueFunction)
      ) {
        updateSelectAllButtonForList(listData, true);
      }
      if (isSelectAllButtonPresentInList(listData) && isClearAllButtonPresentInList(listData)) {
        break outer;
      }
    }
  }
}

function updateActionButtonsForGroups(
  listData: GroupedMultipleData,
  disabledPath: string,
  valueFunction: ValueFunction<boolean>
) {
  listData.groupedItems.forEach(group => {
    updateClearAllButtonForGroup(group, false);
    updateSelectAllButtonForGroup(group, false);

    for (const item of group.value) {
      if (!isClearAllButtonPresentInGroup(group) && item.isSelected) {
        updateClearAllButtonForGroup(group, true);
      }
      if (
        !isSelectAllButtonPresentInGroup(group) &&
        !item.isSelected &&
        !isDisabledItem(item, disabledPath, valueFunction)
      ) {
        updateSelectAllButtonForGroup(group, true);
      }
      if (isClearAllButtonPresentInGroup(group) && isSelectAllButtonPresentInGroup(group)) {
        break;
      }
    }
  });
}

export function updateClearAllButtonForList(listData: GroupedMultipleData, showClearAll: boolean) {
  listData.showClearAll = showClearAll;
}

export function updateSelectAllButtonForList(listData: GroupedMultipleData, showSelectAll: boolean) {
  listData.showSelectAll = showSelectAll;
}

export function updateClearAllButtonForGroup(group: GroupedMultipleItem, showClearAll: boolean) {
  group.showClearAll = showClearAll;
}

export function updateSelectAllButtonForGroup(group: GroupedMultipleItem, showSelectAll: boolean) {
  group.showSelectAll = showSelectAll;
}

function isSelectAllButtonPresentInList(listData: GroupedMultipleData): boolean {
  return listData.showSelectAll as boolean;
}

function isClearAllButtonPresentInList(listData: GroupedMultipleData): boolean {
  return listData.showClearAll as boolean;
}

function isSelectAllButtonPresentInGroup(group: GroupedMultipleItem): boolean {
  return group.showSelectAll as boolean;
}

function isClearAllButtonPresentInGroup(group: GroupedMultipleItem): boolean {
  return group.showClearAll as boolean;
}
