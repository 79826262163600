import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyGridResizingService } from './grid-resizing.service';
import { FireflyKendoDetailsGridInteractiveDirective } from './interactive-kendo-details-grid.directive';
import { FireflyKendoGridInteractiveDirective } from './interactive-kendo-grid.directive';

const directives = [FireflyKendoGridInteractiveDirective, FireflyKendoDetailsGridInteractiveDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [...directives],
  exports: [...directives],
  providers: [FireflyGridResizingService]
})
export class FireflyInteractiveGridModule {}
