import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getFeatureToggle } from './+state/feature-toggle.selectors';

@Directive({
  selector: '[caFeatureToggle]'
})
export class FeatureToggleDirective implements OnDestroy {
  private subscription = Subscription.EMPTY;
  private hasView = false;

  private elseTemplateView: TemplateRef<unknown> | undefined;
  private featureToggleEnabled: boolean = false;

  @Input('caFeatureToggle') set featureToggle(value: string | string[]) {
    this.subscription.unsubscribe();

    this.subscription = this.store.select(getFeatureToggle(value)).subscribe(featureToggle => {
      this.featureToggleEnabled = featureToggle;

      this.updateView();
    });
  }

  @Input() set caFeatureToggleElse(templateRef: TemplateRef<unknown> | undefined) {
    this.elseTemplateView = templateRef;

    this.updateView();
  }

  constructor(
    private store: Store,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private updateView() {
    if (!this.hasView && this.featureToggleEnabled) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!this.hasView && !this.featureToggleEnabled) {
      if (this.elseTemplateView) {
        this.hasView = true;
        this.viewContainer.createEmbeddedView(this.elseTemplateView);
      }
    } else if (this.hasView && !this.featureToggleEnabled) {
      this.hasView = false;
      this.viewContainer.clear();
    }

    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
