import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import type { ScaleBand } from 'd3-scale';
import type { ChartDimensions } from '..';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-charts-x-axis]',
  template: `
    <svg:g class="f-axis" [attr.transform]="transform">
      <line *ngIf="showDomain" class="f-axis-domain" [attr.x1]="dimensions.width" x2="0" />
      <g
        f-charts-x-axis-ticks
        *ngIf="xScale"
        [scale]="xScale"
        [width]="dimensions.width"
        [truncateTicks]="truncateTicks"
        [rotateTicks]="rotateTicks"
        [maxTickLength]="maxTickLength"
        [tickFormatting]="tickFormatting"
        [showGridLines]="showGridLines"
        [showTicksLabels]="showTicksLabels"
        [gridLineHeight]="dimensions.height"
        [tickLabelClasses]="tickLabelClasses"
        [tickLabelOffset]="tickLabelOffset"
        [condensedOnDesktop]="condensedOnDesktop"
        [condensedAxisOnMobile]="condensedAxisOnMobile"
        (dimensionsChanged)="emitTicksHeight($event)"
      />
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyXAxisComponent implements OnChanges {
  @Input() xScale!: ScaleBand<string>;
  @Input() truncateTicks = true;
  @Input() maxTickLength = 16;
  @Input() tickLabelOffset = 12;
  @Input() showTicksLabels = true;
  @Input() showDomain = true;
  @Input() rotateTicks = true;
  @Input() showGridLines = false;
  @Input() dimensions!: ChartDimensions;
  @Input() tickFormatting!: (d: unknown) => string;
  @Input() tickLabelClasses: string | string[] = [];
  @Input() condensedAxisOnMobile = true;
  @Input() condensedOnDesktop = false;

  @Output() dimensionsChanged = new EventEmitter();

  transform = '';

  ngOnChanges() {
    this.update();
  }

  update() {
    this.transform = `translate(0,${this.dimensions.height})`;
  }

  emitTicksHeight({ height }: { height: number }) {
    this.dimensionsChanged.emit({ height });
  }
}
