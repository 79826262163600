import { Directive, OnInit } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';

@Directive({
  selector: '[caCustomFieldColumn]'
})
export class CustomFieldColumnDirective implements OnInit {
  constructor(private column: ColumnBase) {}
  ngOnInit() {
    this.column.hidden = true;
    this.column.includeInChooser = false;
    (this.column as unknown as { optional: boolean }).optional = true;
  }
}
