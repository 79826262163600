import { Component, ComponentRef } from '@angular/core';
import { FireflyMobileChartPopoverComponent } from '@capital-access/firefly/components';
import { ConcentrationChart, ConcentrationChartMobilePopoverContext } from '../models/concentration-chart.model';

@Component({
  selector: 'ca-concentration-mobile-popover',
  template: `
    <div style="height: 300px">
      <ng-template #chartContainer></ng-template>
    </div>
    <hr />
    <div class="overflow-auto">
      <ng-container
        *ngTemplateOutlet="$any(context.template); context: { $implicit: context.popoverData }"
      ></ng-container>
    </div>
  `
})
export class ConcentrationChartMobilePopoverComponent extends FireflyMobileChartPopoverComponent {
  override context!: ConcentrationChartMobilePopoverContext;
  override chart!: ComponentRef<ConcentrationChart>;

  override setUpChartInstance() {
    super.setUpChartInstance();
    this.chart.instance.factorInfo = this.context.factorInfo;
  }
}
