import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';
import { Alert } from './models/alert.enum';

@Component({
  selector: 'f-inline-alert',
  templateUrl: './inline-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyInlineAlertComponent implements OnInit {
  @Input() type!: Alert;
  @Input() showCancelButton = true;
  @Input() showActionButton = false;
  @Input() rootCssClass: string = '';
  @Input() actionButtonText: string = '';
  @Output() hideClick = new EventEmitter<void>();
  @Output() action = new EventEmitter<void>();

  public iconClasses = {
    [Alert.Warning]: 'f-i-warning',
    [Alert.Info]: 'f-i-info',
    [Alert.Success]: 'f-i-success',
    [Alert.Danger]: 'f-i-danger'
  };

  close$: Observable<unknown> = of('Close');

  constructor(private elementRef: ElementRef, @Optional() private localizationService: FireflyLocalizationService) {}

  ngOnInit() {
    if (this.localizationService) {
      this.close$ = this.localizationService.localize('close', {});
    }
  }

  public onHideClick(): void {
    this.hideClick.emit();
    this.elementRef.nativeElement.remove();
  }

  public onAction(): void {
    this.action.emit();
  }
}
