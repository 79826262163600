<ng-container [caLocalizationScope]="localizationScope">
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center flex-sm-grow-1 mb-6 mb-sm-0">
      <div
        class="d-flex flex-column justify-content-center ca-error-page-content py-6"
        data-automation-id="error-page-content"
      >
        <h1 class="ca-error-page-title" data-automation-id="error-title">
          {{ title ? title : (defaultTitle! | caLocalize) }}
        </h1>
        <div class="subtitle-50 mb-6 ca-error-page-subtitle" data-automation-id="error-description">
          {{ description ? description : (defaultDescription! | caLocalize) }}
        </div>
        <div *ngIf="showActions" [ngSwitch]="true" data-automation-id="error-actions">
          <div class="d-flex" *ngSwitchCase="type === 'notFound' || type === 'unknown'">
            <a (click)="navigateHome()" data-automation-id="error-navigate-home" class="btn btn-primary">
              {{ actionTitle ? actionTitle : ('goToHomePage' | caLocalize) }}
            </a>
          </div>
        </div>
        <div #ref><ng-content></ng-content></div>
      </div>
    </div>
    <ca-contact-support *ngIf="isExternal"></ca-contact-support>
  </div>
</ng-container>
