import { Inject, Injectable } from '@angular/core';
import { of, pipe } from 'rxjs';
import { catchError, filter, map, pairwise, startWith } from 'rxjs/operators';
import { JobService, JobStatus } from '@capital-access/notifications/common';
import {
  createNotificationApiTempAuthProvider,
  NotificationApiAuthProvider,
  OIDC_NOTIFICATION_API_AUTH_PROVIDER
} from '../api/auth-providers';
import { NotificationsApi } from '../api/notifications.api';
import { submitJobQuery } from '../api/queries.graphql';
import { parseJobStatus } from '../helpers/parser.utils';

export class JobFailedError<TData> extends Error {
  constructor(public job: JobStatus<TData>) {
    super(`job ${job.id} of type ${job.type} failed with data: ${JSON.stringify(job.data)}`);
    this.name = 'JobFailedError';
  }
}

@Injectable()
export class ApiJobService implements JobService {
  constructor(
    private api: NotificationsApi,
    @Inject(OIDC_NOTIFICATION_API_AUTH_PROVIDER) private oidcAuthProvider: NotificationApiAuthProvider
  ) {}

  public submitJob<TData>(args: { type: string; payload?: unknown; throwOnFailure?: boolean }, tempAuthToken?: string) {
    const authProvider = tempAuthToken ? createNotificationApiTempAuthProvider(tempAuthToken) : this.oidcAuthProvider;

    return this.api
      .subscribe<{ submitJob: JobStatus<string> }>(authProvider, submitJobQuery, {
        jobRequest: { type: args.type, payload: JSON.stringify(args.payload) }
      })
      .pipe(
        map(x => x.submitJob),
        this.handleJobStatus<TData>(args.type, args.throwOnFailure)
      );
  }

  private handleJobStatus<T>(jobType: string, throwOnFailure?: boolean) {
    return pipe(
      startWith<JobStatus<string>>(null),
      pairwise(),
      // handle events order - filter out older events
      filter(([previous, current]) => !previous || current!.publishTime > previous.publishTime),
      map(([_, current]) => parseJobStatus(current!)),
      catchError(error =>
        of({
          id: '',
          type: jobType,
          publishTime: new Date(),
          status: 'Failed',
          data: { error }
        } as JobStatus<T>)
      ),
      map(e => {
        // default is throw, so check for not null (for undefined will return true)
        if (throwOnFailure !== false && e.status === 'Failed') throw new JobFailedError(e);
        return e;
      })
    );
  }
}
