import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Log } from '@capital-access/common/logging';
import { getCurrentRouteAuthorized } from '@capital-access/common/router';
import { AppSettingsService } from '@capital-access/common/settings';

@Component({
  selector: 'ca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  flow$?: Observable<'authorized' | 'anonymous'>;

  constructor(private store: Store, private appSettings: AppSettingsService) {
    this.logAppInfo();
  }

  ngAfterViewInit() {
    this.flow$ = this.store.select(getCurrentRouteAuthorized).pipe(
      filter(x => x !== null),
      map(authorized => (authorized ? 'authorized' : 'anonymous'))
    );
  }

  private logAppInfo() {
    const { version } = this.appSettings.getSettings<{ version: string }>();

    Log.info(`Application info: ${version}`);
  }
}
