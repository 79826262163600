<ng-container caLocalizationScope="search-nav">
  <span *ngIf="!mobileBreakpoint" class="heading-20 ps-3 text-muted align-middle">{{ 'actions' | caLocalize }}</span>

  <div *ngIf="!!specialActions?.length" data-automation-id="special-actions">
    <f-list>
      <f-list-item
        fDropdownItem
        class="cursor-pointer action-panel-item"
        [withHover]="false"
        *ngFor="let action of specialActions"
        [attr.data-automation-id]="'action-' + action.localizationKey"
        [title]="action.disabled! ? action.disabledLabel! && (action.disabledLabel | caLocalize) : ''"
        (click)="onStartSpecialAction(action, action.disabled)"
        [disabled]="!!action.disabled"
      >
        <span class="link link-main" [class.link-disabled]="action.disabled">
          {{ action.localizationKey | caLocalize }}</span
        >
      </f-list-item>
    </f-list>
  </div>
</ng-container>
