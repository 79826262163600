import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileStepperComponent } from './mobile-stepper/mobile-stepper.component';
import { StepComponent } from './step.component';

@NgModule({
  imports: [CommonModule],
  declarations: [StepComponent, MobileStepperComponent],
  exports: [StepComponent, MobileStepperComponent]
})
export class FireflyStepModule {}
