import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { Security } from '../models/security';
import { loadSecurities, loadSecuritiesFail, loadSecuritiesSuccess } from './actions';
import { SecuritiesState } from './state';

export const adapter: EntityAdapter<Security> = createEntityAdapter<Security>();

type AdditionalState = Omit<SecuritiesState, keyof EntityState<Security>>;

const reducer = createReducer(
  adapter.getInitialState<AdditionalState>({ loadingIds: [], failedIds: [] }),
  on(loadSecurities, (state, { ids }) => ({
    ...state,
    loadingIds: uniq([...state.loadingIds, ...ids]),
    failedIds: difference(state.failedIds, ids)
  })),
  on(loadSecuritiesSuccess, (state, { securities }) =>
    adapter.upsertMany(securities, {
      ...state,
      loadingIds: difference(
        state.loadingIds,
        securities.map(s => s.id)
      )
    })
  ),
  on(loadSecuritiesFail, (state, { failedIds }) => ({
    ...state,
    failedIds: uniq([...failedIds, ...state.failedIds]),
    loadingIds: difference(state.loadingIds, failedIds)
  }))
);

export function securitiesReducer(state: SecuritiesState | undefined, action: Action) {
  return reducer(state, action);
}

export const securitiesSelectors = adapter.getSelectors();
