import { ProfileSide } from './information.enums';
import { Address, BaseCrmEntityResponse, CompanyInfoResponse } from './information.models';
import { CompanyContactType } from './search-contact.model';

export interface ContactIdInformationResponse {
  id: string;
}

export interface ContactInformationResponse extends Record<string, unknown> {
  lastName: string;
  firstName: string;
  middleName: string;
  prefix: string;
  suffix: string;
  nickname: string;
  name: string;
  jobTitle: string;
  contacts: ContactContacts;
  address: Address;
  mailingAddress: Address;
  crmEntity: ContactCrmEntityResponse;
  prEntity: ContactPrEntityResponse;
  isEsg: boolean;
}

export interface ContactCrmEntityResponse extends BaseCrmEntityResponse {
  companyName: string;
  lastName: string;
  firstName: string;
  middleName: string;
  prefix: string;
  suffix: string;
  nickname: string;
  jobTitle: string;
  name: string;
  notes: string;
  isMailingAddressSameAsPhysical: boolean;
  internalContactInvestorRelations: boolean;
  internalInvestorRelationsActive: boolean;
  internalContactManagement: boolean;
  internalManagementActive: boolean;
  type: CompanyContactType;
  isExcludedFromMail: boolean;
  excludedFromMailLastModified: string;
  excludedFromMailUserId: number;
  emailExclusionSourceType: string;
  isPrimaryContact: boolean;
  assistantContact: AssistantContactResponse;
  contacts: ContactContacts;
  mailingAddress: Address;
  companyContacts: CompanyContactInfo[];
  primaryCompanyInfo: CompanyInfoResponse;
}

export interface ContactPrEntityResponse extends Record<string, unknown> {
  id: number;
  lastName: string;
  firstName: string;
  middleName: string;
  prefix: string;
  suffix: string;
  nickname: string;
  biography: string;
  education: string;
  scoutingReport: string;
  isFixedIncome: boolean;
  isEquity: boolean;
  isDerivative: boolean;
  side: ProfileSide;
  name: string;
  primaryRole: string;
  jobTitle: string;
  contacts: ContactContacts;
  address: Address;
  mailingAddress: Address;
  primaryCompanyInfo: CompanyInfoResponse;
}

export interface ContactContacts extends Record<string, string> {
  email1: string;
  email2: string;
  phone1: string;
  phone2: string;
}

export interface AssistantContactResponse {
  fullName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface CompanyContactInfo {
  companyName: string;
  primaryContactType: PrimaryContactType;
  companyId: string;
  isPrimaryContact: boolean;
  isPrimaryCompany: boolean;
}

export enum PrimaryContactType {
  None = 'None',
  Government = 'Government',
  Legal = 'Legal',
  Proxy = 'Proxy'
}
