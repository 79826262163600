import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CRM_COMMON_LOCALIZATION_SCOPE } from '../../model/constants';
import { CrmModifiedToastOptionsModel } from './crm-modified-toast-options.model';
import { CrmModifiedToastStateService } from './crm-modified-toast-state.service';

@Component({
  selector: 'ca-crm-modified-toast',
  templateUrl: 'crm-modified-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrmModifiedToastComponent {
  options!: CrmModifiedToastOptionsModel;
  localizationScope = CRM_COMMON_LOCALIZATION_SCOPE;

  constructor(state: CrmModifiedToastStateService) {
    this.options = state.options;
  }
}
