import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';
import { getContentPermissions } from '@capital-access/common/user';
import { orderByNullLast } from '@capital-access/common/utils';
import { Security, SortSettings } from '../models';
import { getSelectedSecurity } from '../selected-security/+state/selectors';
import { fetchSecurities, fetchSecurity } from './fetch-securities.operators';

@Injectable()
export class SecuritySelectorProvider {
  constructor(private store: Store) {}

  getSecurities() {
    return this.store.select(getContentPermissions).pipe(
      switchMap(permissions =>
        fetchSecurities(this.store, permissions!.targeting.securityIds, {
          onSuccess: securities => orderByNullLast(securities, SortSettings),
          onFail: (securities, failedIds) =>
            of(failedIds.map(id => ({ id, ticker: id.toString() } as unknown as Security)).concat(securities))
        })
      )
    );
  }

  getSelectedSecurity() {
    return this.store.pipe(
      select(getSelectedSecurity),
      switchMap(security =>
        security
          ? fetchSecurity<Security>(this.store, security.id, {
              onSuccess: security => security,
              onFail: () =>
                of({
                  id: security.id,
                  ticker: security.id!.toString(),
                  stockExchange: '',
                  name: ''
                })
            })
          : of(null)
      )
    );
  }
}
