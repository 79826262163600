import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ResultCategoryMetadata, SearchCategory } from '../../+state/models';

@Component({
  selector: 'ca-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterPanelComponent {
  @Input() selectedFilter!: SearchCategory | null;
  @Input() filters!: ResultCategoryMetadata[] | null;

  @Output() filterChanged = new EventEmitter<SearchCategory | null>();

  onFilterSelect(value: ResultCategoryMetadata) {
    if (value.hasItems) {
      const filter = value.name !== this.selectedFilter ? value.name : null;
      this.filterChanged.emit(filter);
    }
  }
}
