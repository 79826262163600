import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SideNavService {
  private expanded = false;
  private subject = new BehaviorSubject<boolean>(this.expanded);

  panelExpanded$ = this.subject.asObservable();

  togglePanelExpanded() {
    this.expanded = !this.expanded;
    this.subject.next(this.expanded);
  }
}
