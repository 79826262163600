import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, repeat } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { POLLING_INTERVAL, REPORTING_API_BASE_URL, ReportStatus } from '@capital-access/reporting/common';
import { BaseReportRequest, ReportState } from '../models/report-request.models';

@Injectable()
export class CreateReportService {
  constructor(private http: HttpClient) {}

  private _locationHeaderName: string = 'location';

  public createReport(request: BaseReportRequest, url: string): Observable<ReportState> {
    return this.triggerReportCreation(url, request).pipe(
      switchMap(response => this.poolForReport(response.headers.get(this._locationHeaderName)!))
    );
  }

  private poolForReport(url: string): Observable<ReportState> {
    return this.getReportStatus(url).pipe(
      repeat({ delay: POLLING_INTERVAL }),
      filter(response => response.status !== ReportStatus.InProgress),
      take(1),
      map(response => this.handleReportGenerationCompleted(response))
    );
  }

  private triggerReportCreation(url: string, request: BaseReportRequest): Observable<HttpResponse<null>> {
    return this.http.post<null>(`${REPORTING_API_BASE_URL}${url}`, request, {
      observe: 'response'
    });
  }

  private getReportStatus(url: string): Observable<ReportState> {
    return this.http.get<ReportState>(url);
  }

  private handleReportGenerationCompleted(response: ReportState): ReportState {
    if (response.status === ReportStatus.Failed) {
      throw new Error('Failed to generate a report.');
    }

    return response;
  }
}
