import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import type { ScaleBand } from 'd3-scale';
import { Breakpoint } from '../../../utils';
import { truncateLabel } from './trim-label.helper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-charts-x-axis-ticks]',
  template: `
    <svg:g
      #ticksElement
      class="f-tick-container"
      [ngStyle]="{ opacity: showTicksLabels ? 1 : 0, pointerEvents: showTicksLabels ? 'auto' : 'none' }"
    >
      <g
        *ngFor="let tick of ticks; let first = first; let last = last"
        class="f-tick"
        [ngClass]="tickLabelClasses"
        data-automation-id="x-axis-tick"
        [attr.transform]="tickTransform(tick, first, last)"
      >
        <title>{{ tickFormat(tick) }}</title>
        <text [attr.text-anchor]="textAnchor" [attr.transform]="textTransform">
          {{ tickTrim(tickFormat(tick)) }}
        </text>
      </g>
    </svg:g>

    <svg:g class="f-tick-lines-container">
      <svg:g *ngFor="let tick of ticks" class="f-grid-vertical" [attr.transform]="gridLineTransform(tick)">
        <line *ngIf="showTicksLabels" y1="0" [attr.y2]="tickLineHeight" class="f-tick-line" />
        <line *ngIf="showGridLines" [attr.y1]="-gridLineHeight" y2="0" class="f-grid-line" />
      </svg:g>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyXAxisTicksComponent implements OnChanges, AfterViewInit {
  @Input() scale!: ScaleBand<string>;
  @Input() tickValues!: string[];
  @Input() truncateTicks!: boolean;
  @Input() maxTickLength!: number;
  @Input() showGridLines!: boolean;
  @Input() showTicksLabels = true;
  @Input() gridLineHeight!: number;
  @Input() rotateTicks!: boolean;
  @Input() width!: number;
  @Input() tickLabelOffset = 15;
  @Input() condensedOnDesktop = false;
  @Input() condensedAxisOnMobile = true;
  @Input() tickFormatting!: (d: unknown) => string;
  @Input() tickLabelClasses: string | string[] = [];

  @Output() dimensionsChanged = new EventEmitter();

  textAnchor = 'middle';
  tickFormat!: (d: string) => string;
  maxTicksLength = 0;
  maxAllowedLength = 16;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adjustedScale!: any;
  textTransform!: string;
  ticks!: string[];
  approxHeight = 10;
  tickLineHeight = 3;
  height = 0;

  @ViewChild('ticksElement') ticksElement!: ElementRef;

  @HostBinding('class.condensed') get condensedStyles() {
    return this.condensedAxis;
  }

  get condensedAxis() {
    return (
      (this.condensedAxisOnMobile && window.innerWidth < Breakpoint.Sm) ||
      (this.condensedOnDesktop && window.innerWidth >= Breakpoint.Sm)
    );
  }

  ngOnChanges() {
    this.update();
  }

  ngAfterViewInit() {
    window.requestAnimationFrame(() => this.updateDims());
  }

  updateDims() {
    if (!this.showTicksLabels) {
      this.dimensionsChanged.emit({ height: this.height });
      return;
    }

    const height = parseInt(this.ticksElement.nativeElement.getBoundingClientRect().height, 10);

    if (height !== this.height) {
      this.height = height;
      this.dimensionsChanged.emit({ height: height + this.tickLabelOffset / 2 });
    }
  }

  update(): void {
    this.ticks = this.getTicks();

    if (this.tickFormatting) {
      this.tickFormat = this.tickFormatting;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.tickFormat = (d: any) => {
        if (d.constructor.name === 'Date') {
          return d.toLocaleDateString();
        }
        return d.toLocaleString();
      };
    }

    this.adjustedScale =
      'bandwidth' in this.scale
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (d: any) => {
            const scale = this.scale as ScaleBand<string>;
            return (this.scale(d) || 0) + scale.bandwidth() * 0.5;
          }
        : this.scale;

    const angle = this.rotateTicks ? this.getRotationAngle(this.ticks) : null;

    this.textTransform = '';

    if (angle && angle !== 0 && !this.condensedAxis) {
      this.textTransform = `translate(5, -2) rotate(${angle})`;
      this.textAnchor = 'end';
    } else {
      this.textAnchor = 'middle';
    }

    window.requestAnimationFrame(() => {
      this.updateDims();
    });
  }

  getRotationAngle(ticks: string[]): number {
    if (this.condensedAxis) return 0;

    let angle = 0;
    this.maxTicksLength = 0;
    for (let i = 0; i < ticks.length; i++) {
      const tick = this.tickFormat(ticks[i]).toString();
      let tickLength = tick.length;
      if (this.truncateTicks) {
        tickLength = this.tickTrim(tick).length;
      }

      if (tickLength > this.maxTicksLength) {
        this.maxTicksLength = tickLength;
      }
    }

    const len = Math.min(this.maxTicksLength, this.maxAllowedLength);
    const charWidth = 7;
    const wordWidth = len * charWidth;

    let baseWidth = wordWidth;
    const maxBaseWidth = Math.floor(this.width / ticks.length);

    while (baseWidth > maxBaseWidth && angle > -30) {
      angle -= 10;
      baseWidth = Math.cos(angle * (Math.PI / 180)) * wordWidth;
    }

    this.approxHeight = Math.max(Math.abs(Math.sin(angle * (Math.PI / 180)) * wordWidth), 10);

    return angle;
  }

  getTicks(): string[] {
    let ticks;

    if (this.tickValues) {
      ticks = this.tickValues;
    } else {
      ticks = this.scale.domain();
    }

    return ticks as string[];
  }

  tickTransform(tick: string, isFirst: boolean, isLast: boolean): string {
    const divider = this.scale.padding() === 0 ? 2 : 1;
    const firstTickOffset = this.condensedAxis && isFirst ? -this.adjustedScale(tick) : 0;
    const lastTickOffset = this.condensedAxis && isLast ? this.scale.bandwidth() / divider : 0;
    const adjustedOffset = firstTickOffset + lastTickOffset;
    return `translate(${this.adjustedScale(tick) + adjustedOffset}, ${this.tickLabelOffset})`;
  }

  gridLineTransform(tick: string): string {
    return `translate(${this.adjustedScale(tick)}, 0)`;
  }

  tickTrim(label: string): string {
    return this.truncateTicks ? truncateLabel(label, this.maxTickLength) : label;
  }
}
