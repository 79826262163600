import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Log } from '@capital-access/common/logging';
import { ProfileLinkProvider } from '@capital-access/profiles/common';
import { SearchCategory, SearchResults, WithErrors } from '../+state/models';
import { contactsQuery, eventsQuery, fundsQuery, institutionsQuery } from './graphql/queries';
import { GraphQLSearchError } from './graphql/search-result.model';
import { QueryVariables } from './graphql/variables.model';
import { SearchRepository } from './search.repository';

@Injectable({ providedIn: 'root' })
export class SearchService {
  constructor(private repository: SearchRepository, private linkBuilder: ProfileLinkProvider) {}

  public search(criteria: string, searchQuantity: number): Observable<SearchResults & WithErrors> {
    const query = `
      query($filter: String!, $skip: Int!, $take: Int!) {
        ${contactsQuery}
        ${institutionsQuery}
        ${fundsQuery}
        ${eventsQuery}
      }`;

    const variables: QueryVariables = {
      filter: criteria,
      skip: 0,
      take: searchQuantity
    };

    return this.repository.search(query, variables).pipe(
      map(r => ({
        criteria: criteria,
        contacts: r.contacts.map(c => {
          c.profileLink = this.linkBuilder.getContactProfileLink(c.prospectingId, c.crmId);
          c.category = SearchCategory.Contacts;
          return c;
        }),
        institutions: r.institutions.map(i => {
          i.profileLink = this.linkBuilder.getInstitutionProfileLink(i.prospectingId, i.crmId);
          i.category = SearchCategory.Institutions;
          return i;
        }),
        funds: r.funds.map(f => {
          f.profileLink = this.linkBuilder.getFundProfileLink(f.prospectingId, f.crmId);
          f.category = SearchCategory.Funds;
          return f;
        }),
        events: r.events.map(e => {
          e.category = SearchCategory.Events;
          return e;
        }),
        partialErrors: this.mapPartialErrors(r.errors)
      }))
    );
  }

  private mapPartialErrors(errors: GraphQLSearchError[]): SearchCategory[] {
    const searchCategories = Object.values(SearchCategory);
    const resultErrors = errors.map(error => {
      if (!error.path) {
        throw error.message;
      }
      const errorCategory = searchCategories.find(c => c === error.path[0]);
      if (!errorCategory) {
        // unknown partial error
        Log.error({ message: error.message, path: error.path.join('/') });
      }
      return errorCategory;
    });

    return resultErrors.filter(Boolean) as SearchCategory[];
  }
}
