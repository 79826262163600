import {
  AfterContentInit,
  ContentChild,
  Directive,
  ElementRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
  SkipSelf
} from '@angular/core';
import { NgbDropdown, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FireflyDropdownComponent } from '../dropdown/dropdown.component';

@Directive({
  selector: '[fNestedDropdown]',
  exportAs: 'fNestedDropdown'
})
export class FireflyNestedDropdownDirective implements AfterContentInit, OnChanges, OnDestroy {
  @Input() placement: 'top-left' | 'top-right' = 'top-left';
  @HostBinding('class.nested-dropdown') nestedDropdown = true;
  @HostBinding('class.position-static') positionStatic = true;
  @ContentChild(NgbDropdownMenu) nestedDropdownMenu!: NgbDropdownMenu;

  private openChangeSub$!: Subscription;

  get isOpen() {
    return this.dropdown.isOpen();
  }

  constructor(
    private el: ElementRef,
    @Host() private dropdown: NgbDropdown,
    @Optional() @SkipSelf() private parentDropdown: FireflyDropdownComponent
  ) {
    this.dropdown.autoClose = 'outside';
    this.dropdown.display = 'static';
  }

  private setPlacement() {
    this.nestedDropdownMenu?.nativeElement.classList.add(`${this.placement}-placement`);
    this.el.nativeElement.setAttribute('placement', this.placement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['placement'].currentValue && !changes['placement'].firstChange) {
      const previousPlacement = changes['placement'].previousValue;
      this.nestedDropdownMenu?.nativeElement.classList.remove(`${previousPlacement}-placement`);
      this.setPlacement();
    }
  }

  ngAfterContentInit() {
    this.setPlacement();
    this.openChangeSub$ = this.parentDropdown?.openChange
      .pipe(filter(isOpen => !isOpen && this.dropdown.isOpen()))
      .subscribe(() => this.dropdown.close());
  }

  ngOnDestroy() {
    this.openChangeSub$.unsubscribe();
  }

  @HostListener('keyup', ['$event'])
  onEnter($event: KeyboardEvent) {
    if (['Enter', 'Space'].includes($event.code)) {
      if (!this.dropdown.isOpen()) this.dropdown.open();
    }
    if ($event.code === 'ArrowLeft') {
      this.placement === 'top-right' ? setTimeout(() => this.dropdown.close()) : this.dropdown.open();
    }
    if ($event.code === 'ArrowRight') {
      this.placement === 'top-left' ? setTimeout(() => this.dropdown.close()) : this.dropdown.open();
    }
  }
}
