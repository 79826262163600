import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Address, ContactInformationDto, InstitutionInformationDto } from '@capital-access/profiles/common';
import {
  ExistingProfileAddressItem,
  existingProfileAddressModelKey,
  existingProfileContactModelKey,
  ExistingProfileItem
} from '../model/existing-profile.models';

export const emptyExistingProfileItem = {
  value: '',
  showEditedIcon: false
} as ExistingProfileItem;

export function getExistingProfileContactItem(
  info: ContactInformationDto | InstitutionInformationDto | null,
  modelKey: existingProfileContactModelKey
): ExistingProfileItem {
  if (!info) {
    return emptyExistingProfileItem;
  }

  const value = info.contacts?.[modelKey] || '';

  const showEditedIcon =
    !!info.crmEntity?.contacts?.[modelKey] &&
    info.crmEntity.contacts?.[modelKey] != info.prEntity?.contacts?.[modelKey];

  return {
    value,
    showEditedIcon
  };
}

export function getExistingProfileAddressItem(
  info: ContactInformationDto | InstitutionInformationDto | null,
  addressModelKey: existingProfileAddressModelKey,
  isProspecting: boolean
): ExistingProfileAddressItem {
  if (!info) {
    return {
      address: '',
      addressCountry: '',
      showEditedIcon: false
    };
  }

  const prAddress = info?.prEntity?.[addressModelKey] as Address;
  const crmAddress = info?.crmEntity?.[addressModelKey] as Address;

  const addressCountry = (isProspecting ? prAddress?.addressCountry : crmAddress?.addressCountry) || '';
  const address = (isProspecting ? prAddress?.address : crmAddress?.address) || '';
  const showEditedIcon = !isProspecting;

  return {
    address,
    addressCountry,
    showEditedIcon
  };
}

export function markControlsWithErrorsTouched(form: FormGroup) {
  Object.keys(form.controls).forEach((controlName: string) => {
    const control = form.get(controlName);

    if (control instanceof FormGroup) {
      markControlsWithErrorsTouched(control);
    } else if (control instanceof FormArray) {
      const controlsArray = control.controls;
      controlsArray.forEach(control => updateControl(control));
    } else {
      updateControl(control);
    }
  });

  function updateControl(control: AbstractControl | null) {
    if (control?.invalid) {
      control?.markAsTouched();
    }
  }
}
