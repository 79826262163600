import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AbstractSecurityStorage, AuthModule as OidcAuthModule, StsConfigLoader } from 'angular-auth-oidc-client';
import { provideAuthGuard } from '@capital-access/common/router';
import { AppSettingsService } from '@capital-access/common/settings';
import { AuthEffects } from './+store/auth.effects';
import * as fromAuth from './+store/auth.reducer';
import { AuthenticationGuard } from './common-auth.guard';
import { AuthorizationTokenInterceptor } from './interceptors/authorization-token.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { configLoaderFactory } from './oidc-configuration';
import { SecurityLocalStorage } from './security-local-storage';

@NgModule({
  imports: [
    CommonModule,
    OidcAuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: configLoaderFactory,
        deps: [AppSettingsService]
      }
    }),
    StoreModule.forFeature(fromAuth.AUTH_FEATURE_KEY, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [
    { provide: AbstractSecurityStorage, useClass: SecurityLocalStorage },
    provideAuthGuard(AuthenticationGuard),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    }
  ]
})
export class CommonAuthModule {}
