import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'f-list'
})
export class FireflyListDirective {
  @Input() withDividers = false;
  @Input() withConcisedItems = false;
  @Input() withAfterIcon = false;
  @Input() withBackground = false;
  @Input() selectable = false;
  @Input() compact = false;

  @HostBinding('class.list-group') listGroupClass = true;
  @HostBinding('class.list-group-flush') get listGroupFlushClass() {
    return this.withDividers;
  }
  @HostBinding('class.list-group-borderless') get listGroupBorderlessClass() {
    return !this.withDividers && !this.selectable;
  }
  @HostBinding('class') get listGroupConcisedClass() {
    return this.withConcisedItems ? 'list-group-icon-after list-group-removable-concised' : '';
  }
  @HostBinding('class.list-group-icon-after') get listAfterIconClass() {
    return this.withAfterIcon;
  }
  @HostBinding('class.list-group-with-background') get listBackgroundClass() {
    return this.withBackground;
  }
  @HostBinding('class.list-group-selectable') get listSelectableClass() {
    return this.selectable;
  }
  @HostBinding('class.list-group-compact') get listCompactClass() {
    return this.compact;
  }

  @HostBinding('attr.data-automation-id') get dataAutomationId() {
    const dataAutomationId = this.host.nativeElement.dataset.automationId;
    return dataAutomationId ? dataAutomationId : 'f-list';
  }

  constructor(private host: ElementRef) {}
}
