import { Injectable } from '@angular/core';
import { createTitle } from '@capital-access/common/browser';
import { LocalizedString } from '@capital-access/common/localization';
import { InternalServerErrorPageComponent } from './internal-server-error-page.component';

@Injectable()
export class InternalServerErrorPageTitle {
  title = createTitle(InternalServerErrorPageComponent, () => ({
    value: [new LocalizedString('applicationTitle'), new LocalizedString('errorPageUnknownTitle')],
    override: true
  }));
}
