import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { COMMON_CURRENCY_LOCALIZATION_SCOPE, getCurrencyRate } from '@capital-access/common/currency';
import { LocalizationService } from '@capital-access/common/localization';
import { CurrencyFormatOptions, CurrencyValueOptions } from '../models/currency-formatting.models';
import { applyRate, transformToAbs, transformToMillions } from '../utils/format-number';
import { NumberFormattingService } from './number-formatting.service';

@Injectable()
export class CurrencyFormattingService {
  private defaultValueOptions: CurrencyValueOptions = {
    toAbsolute: true,
    toMillions: true
  };

  private defaultFormatOptions: CurrencyFormatOptions = {
    fraction: 2
  };

  constructor(
    private store: Store,
    private numberFormattingService: NumberFormattingService,
    private localizationService: LocalizationService
  ) {}

  formatCurrency(
    value: number | null | undefined,
    formatOptions?: Partial<CurrencyFormatOptions>,
    valueOptions?: Partial<CurrencyValueOptions>
  ): Observable<string> {
    if (value === null || value === undefined || !isFinite(value)) {
      return of('');
    }
    return combineLatest([
      this.store.select(getCurrencyRate),
      this.localizationService.getLocalization(COMMON_CURRENCY_LOCALIZATION_SCOPE)
    ]).pipe(
      mergeMap(([rate, localization]) => {
        if (rate == null) {
          return of(localization['notApplicableTitle']);
        }

        const customValueOptions = { ...this.defaultValueOptions, ...valueOptions };
        const { fraction } = { ...this.defaultFormatOptions, ...formatOptions };

        const customValue = this.transformValue(applyRate(value, rate), customValueOptions);

        return this.numberFormattingService.formatNumber(customValue, fraction);
      })
    );
  }

  private transformValue(value: number, options: CurrencyValueOptions) {
    let transformedValue = value;
    if (options.toAbsolute) {
      transformedValue = transformToAbs(transformedValue);
    }
    if (options.toMillions) {
      transformedValue = transformToMillions(transformedValue);
    }
    return transformedValue;
  }
}
