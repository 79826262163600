import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { login } from '@capital-access/common/actions';
import { getUserLoadStatus } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
@Component({
  selector: 'ca-session-expire-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './session-expire-page.component.html'
})
export class SessionExpirePageComponent implements OnInit {
  constructor(private store: Store, private router: Router) {}
  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  onClick() {
    this.store.dispatch(login());
  }

  ngOnInit() {
    this.store
      .select(getUserLoadStatus)
      .pipe(
        tap(loadStatus => {
          if (loadStatus === LoadStatus.Pristine) {
            this.router.navigate(['/']);
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
