import { JobStatus, UserNotification } from '@capital-access/notifications/common';

export function parseUserNotification(notification: UserNotification<string>): UserNotification {
  return {
    id: notification.id,
    level: notification.level,
    payload: notification.payload && JSON.parse(notification.payload),
    publishTime: new Date(notification.publishTime),
    type: notification.type
  };
}

export function parseJobStatus<TData>(jobStatus: JobStatus<string>): JobStatus<TData> {
  return {
    id: jobStatus.id,
    type: jobStatus.type,
    status: jobStatus.status,
    publishTime: new Date(jobStatus.publishTime),
    data: jobStatus.data && JSON.parse(jobStatus.data)
  };
}
