import { SearchCategory } from './search-category.enum';
import { SpecialActions } from './special-actions.enum';

export interface ActionConfig {
  priority: number;
  action: SpecialActions;
  localizationKey: string;
  hidden?: boolean;
  disabled?: boolean;
  disabledLabel?: string;
}

export interface BelongToCategories {
  categories: SearchCategory[];
}

export const actionsConfig: Array<ActionConfig & BelongToCategories> = [
  {
    priority: 1,
    action: SpecialActions.AddToNewActivity,
    localizationKey: 'addToNewActivity',
    categories: [SearchCategory.Contacts, SearchCategory.Institutions]
  },
  {
    priority: 2,
    action: SpecialActions.AddToEvent,
    localizationKey: 'addToEvent',
    categories: [SearchCategory.Contacts, SearchCategory.Institutions],
    hidden: true
  },
  {
    priority: 3,
    action: SpecialActions.AddToList,
    localizationKey: 'addToList',
    categories: [SearchCategory.Contacts, SearchCategory.Institutions],
    disabledLabel: 'addToListDisabled'
  },
  {
    priority: 4,
    action: SpecialActions.AddToTargetView,
    localizationKey: 'addToTargetQueue',
    categories: [SearchCategory.Contacts, SearchCategory.Institutions, SearchCategory.Funds],
    hidden: true
  },
  {
    priority: 5,
    action: SpecialActions.AddActivity,
    localizationKey: 'addActivity',
    categories: [SearchCategory.Events]
  }
];
