import { HistoricalOwnershipReportFilters } from '@capital-access/reporting/common';
import { OwnershipSource } from '../../core/models/ownership-source.enum';
import { BaseReportRequest } from '../../core/models/report-request.models';
import { ReportSection } from '../../core/models/report-sections';

export interface CreateHistoricalOwnershipRequest extends BaseReportRequest {
  sections: ReportSection[];
  reportedQuarters: string[];
  filters?: HistoricalOwnershipReportFilters;
  includeTables: boolean;
}

export class CreateHistoricalOwnershipDto {
  reportedQuarters: string[];
  filters?: HistoricalOwnershipReportFilters;

  constructor(reportedQuarters: string[], filters?: HistoricalOwnershipReportFilters) {
    this.reportedQuarters = reportedQuarters;
    this.filters = filters;
  }

  static create(reportedQuarters: string[], filters?: HistoricalOwnershipReportFilters): CreateHistoricalOwnershipDto {
    return new CreateHistoricalOwnershipDto(reportedQuarters, filters);
  }
}
