import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { drawerOutClickExceptionClass } from '../drawer/constants';
import { FireflyConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ConfirmationModalOptions } from './confirmation-modal/confirmation-modal-options';
import { FireflyMobileModalComponent } from './mobile/mobile-modal.component';
import { FireflyModalComponent } from './modal.component';
import { ModalRef } from './models/base-modal-options';
import { ModalOptions } from './models/modal-options';

@Injectable({ providedIn: 'root' })
export class FireflyModalService {
  constructor(private ngModalService: NgbModal) {}

  open(options: ModalOptions) {
    const component = options.mobile ? FireflyMobileModalComponent : FireflyModalComponent;
    const modal: ModalRef<ModalOptions> = this.ngModalService.open(component, {
      modalDialogClass: options.modalDialogClass ?? '',
      windowClass: drawerOutClickExceptionClass,
      size: options.modalSize ?? 'md',
      container: options.container,
      backdrop: 'static',
      scrollable: true
    });

    modal.componentInstance.context = options;

    return modal;
  }

  openConfirmation(options: ConfirmationModalOptions) {
    const modal: ModalRef<ConfirmationModalOptions> = this.ngModalService.open(FireflyConfirmationModalComponent, {
      windowClass: `${options.style} ${options.windowClass ?? ''}`,
      container: options.container,
      backdrop: 'static',
      keyboard: false,
      centered: true
    });

    modal.componentInstance.context = options;

    // Prevent focusing of the primary button before pressing ESC key
    window.requestAnimationFrame(() => {
      (document.activeElement as HTMLElement)?.blur();
    });

    return modal;
  }
}
