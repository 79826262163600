<div
  class="list-group-item form-check"
  [attr.data-automation-id]="'f-list-item'"
  [class.active]="checked"
  [class.disabled]="disabled"
  [class.list-group-item-hover]="hoverable"
  [class.list-group-item-selected]="hasSelectedStyles"
  [class]="itemCheckboxClass"
  (click)="flipCheckboxValue($event)"
>
  <input
    type="checkbox"
    class="form-check-input"
    [attr.tabindex]="tabable ? 0 : -1"
    [title]="checkboxTitle"
    [disabled]="disabled || checkboxDisabled"
    [checked]="checked"
    [class.indeterminate]="indeterminate"
  />
  <ng-content></ng-content>
</div>
