import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AssignEffects } from './+state/assign.effects';
import { CreationEffects } from './+state/creation.effects';
import { EditEffects } from './+state/edit.effects';
import { ManageEffects } from './+state/manage.effects';

@NgModule({
  imports: [EffectsModule.forFeature([CreationEffects, ManageEffects, EditEffects, AssignEffects])]
})
export class CustomFieldsClientModule {}
