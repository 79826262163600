import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyStickyColumnsDirective } from './sticky-columns.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyStickyColumnsDirective],
  exports: [FireflyStickyColumnsDirective]
})
export class FireflyStickyColumnsModule {}
