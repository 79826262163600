export function truncateLabel(s: unknown, max = 16): string {
  if (typeof s !== 'string') {
    if (typeof s === 'number') {
      return s + '';
    } else {
      return '';
    }
  }

  const res = s.trim();
  if (res.length <= max) {
    return res;
  } else {
    return `${res.slice(0, max)}...`;
  }
}
