import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Host, Input, OnInit, QueryList } from '@angular/core';
import { CheckboxColumnComponent, GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'f-skeleton-table-loader',
  template: `
    <kendo-grid *ngIf="columns && tableData" [data]="tableData" [scrollable]="'none'">
      <ng-container *ngFor="let column of columns">
        <kendo-grid-column
          class="{{ column?.isCheckboxColumn ? 'k-checkbox-column' : '' }}"
          [class]="column.cssClass"
          [style]="column.style"
          [width]="column.width"
        >
          <ng-template kendoGridCellTemplate>
            <f-skeleton
              *ngIf="!column?.isCheckboxColumn || !hideCheckboxes"
              [class.k-checkbox]="column?.isCheckboxColumn"
              [shape]="column?.isCheckboxColumn ? 'rectangle' : 'text'"
            ></f-skeleton>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
    </kendo-grid>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySkeletonTableLoaderComponent implements OnInit {
  private _rowsCount = 30;

  tableData = this.getTableData(this._rowsCount);
  columns!: {
    width: number;
    style: { [key: string]: string };
    cssClass: string | string[] | Set<string> | { [key: string]: string };
    isCheckboxColumn: boolean;
  }[];

  constructor(@Host() private grid: GridComponent, private cdr: ChangeDetectorRef) {}

  @Input() hideCheckboxes: boolean = false;

  @Input() set rowsCount(value: number) {
    this._rowsCount = value > 0 && value < 30 ? value : this._rowsCount;
    this.tableData = this.getTableData(this._rowsCount);
  }
  get rowsCount() {
    return this._rowsCount;
  }

  private getTableData(n: number): null[] {
    return new Array(n).fill(null);
  }

  ngOnInit() {
    if (this.columns?.length !== this.grid.columns.length) {
      window.requestAnimationFrame(() => {
        this.columns = (this.grid.columns as QueryList<CheckboxColumnComponent>)
          .filter(c => !c.hidden && c.matchesMedia)
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map(({ width, style, cssClass, isCheckboxColumn }) => ({ width, style, cssClass, isCheckboxColumn }));

        this.cdr.markForCheck();
      });
    }
  }
}
