import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  getGlobalizationKey,
  getIsUserSettingsCurrencyResolved,
  getUserSettingsCurrency
} from '@capital-access/common/globalization';
import { LocalizationService } from '@capital-access/common/localization';
import { StandingDataRepository } from '@capital-access/common/standing-data';
import { saveSetting, saveSettingsFailure, saveSettingsSuccess } from '@capital-access/common/user';
import { when } from '@capital-access/common/utils';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FireflyModalService, NotificationService } from '@capital-access/firefly/components';
import { COMMON_CURRENCY_LOCALIZATION_SCOPE, FALLBACK_CURRENCY } from '../common-currency.const';
import { loadCurrencyRate, loadCurrencyRateFail, loadCurrencyRateSuccess } from './actions';

@Injectable()
export class CurrencyEffects {
  private trackSettings$ = new BehaviorSubject<boolean>(false);
  private currencyPreference = getGlobalizationKey('currency');

  onCurrencySetting$ = createEffect(() =>
    this.store.select(getUserSettingsCurrency).pipe(
      map(code => {
        if (code == null) {
          return loadCurrencyRateFail({ error: 'The currency is not selected. Unable to load rate.' });
        }
        if (code == FALLBACK_CURRENCY) {
          // currency rate never changes for USD
          return loadCurrencyRateSuccess({ rate: 1.0 });
        }
        return loadCurrencyRate({ code });
      }),
      when(this.store.select(getIsUserSettingsCurrencyResolved))
    )
  );

  loadCurrencyRate$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadCurrencyRate),
      switchMap(({ code }) =>
        this.repository.getCurrencyRate(code).pipe(
          map(({ rate }) => loadCurrencyRateSuccess({ rate })),
          catchError(error => of(loadCurrencyRateFail({ error })))
        )
      )
    )
  );

  loadCurrencyRateFailed$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadCurrencyRateFail),
      switchMap(() => this.localizationService.getLocalization(COMMON_CURRENCY_LOCALIZATION_SCOPE).pipe(take(1))),
      switchMap(localization => {
        const {
          fallbackModalTitle,
          fallbackModalBody,
          actionButtonTitle,
          dismissButtonTitle,
          switchCurrencyInProgressAlertTitle
        } = localization;
        return from(
          this.modalService.openConfirmation({
            title: fallbackModalTitle,
            style: 'error',
            body: fallbackModalBody,
            actionText: actionButtonTitle,
            dismissText: dismissButtonTitle
          }).result
        ).pipe(
          map(() => saveSetting({ key: this.currencyPreference, value: FALLBACK_CURRENCY })),
          // Confirmation that given effect was the initiator of the action
          tap(() => this.trackSettings$.next(true)),
          tap(() =>
            this.notificationService.notify(switchCurrencyInProgressAlertTitle, {
              type: 'success'
            })
          )
        );
      })
    )
  );

  switchCurrencySettingSuccess$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(saveSettingsSuccess),
        withLatestFrom(this.trackSettings$),
        filter(
          ([{ settings }, tracked]) =>
            tracked && settings.find(x => x.id == this.currencyPreference)?.value == FALLBACK_CURRENCY
        ),
        switchMap(() =>
          this.localizationService
            .localize(`${COMMON_CURRENCY_LOCALIZATION_SCOPE}.switchCurrencySuccessAlertTitle`)
            .pipe(take(1))
        ),
        tap(switchCurrencySuccessAlertTitle => {
          this.completeTracking();
          this.notificationService.notify(switchCurrencySuccessAlertTitle, {
            type: 'success'
          });
        })
      ),
    { dispatch: false }
  );

  switchCurrencySettingFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(saveSettingsFailure),
        withLatestFrom(this.trackSettings$),
        filter(([{ failedIds }, tracked]) => tracked && failedIds.includes(this.currencyPreference)),
        switchMap(() =>
          this.localizationService
            .localize(`${COMMON_CURRENCY_LOCALIZATION_SCOPE}.switchCurrencyFailedAlertTitle`)
            .pipe(take(1))
        ),
        tap(switchCurrencyFailedAlertTitle => {
          this.completeTracking();
          this.notificationService.notify(switchCurrencyFailedAlertTitle, {
            type: 'danger'
          });
        })
      ),
    { dispatch: false }
  );

  private completeTracking() {
    this.trackSettings$.next(false);
    this.trackSettings$.complete();
  }

  constructor(
    private actions: Actions,
    private store: Store,
    private repository: StandingDataRepository,
    private modalService: FireflyModalService,
    private localizationService: LocalizationService,
    private notificationService: NotificationService
  ) {}
}
