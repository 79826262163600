import { createFeatureSelector, createSelector } from '@ngrx/store';
import memoize from 'lodash/memoize';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { Breakpoint } from '@capital-access/firefly/components';
import { SEARCH_FEATURE_KEY } from '../constants';
import { Error, Panel, SearchCategory, SearchEntity, SearchStatus } from './models';
import { PopupHeaderState } from './models/popup-header-state.enum';
import { State } from './search.reducer';
import {
  getAvailableCategories,
  getSpecialActions,
  getSubset,
  hasMore,
  isCategorySearchFailed,
  mapToChipsItems,
  showCategoryResult
} from './utils';

// Lookup the 'Search' feature state managed by NgRx
export const getSearchState = createFeatureSelector<State>(SEARCH_FEATURE_KEY);

export const getSearchStatus = createSelector(getSearchState, state => state.status);
export const isSearchStarted = createSelector(getSearchStatus, status => status === SearchStatus.Started);

export const getFilter = createSelector(getSearchState, s => s.filter);
export const getSearchResults = createSelector(getSearchState, state => state.results);
export const getAvailableResultCategories = createSelector(getSearchResults, results =>
  getAvailableCategories(results)
);
export const hasAnyResults = createSelector(getSearchResults, results =>
  Object.values(SearchCategory).some(category => !!results[category]?.length)
);
export const hasFilteredResults = createSelector(getSearchState, state =>
  state.filter
    ? !!state.results[state.filter].length
    : Object.values(SearchCategory).some(category => !!state.results[category]?.length)
);
export const getCurrentCriteria = createSelector(getSearchResults, r => r.criteria);

export const showResults = createSelector(
  getSearchStatus,
  getCurrentCriteria,
  (status, criteria) => status !== SearchStatus.None && !!criteria
);

export const getSearchCriteria = createSelector(getSearchState, state => state.criteria);

export const getErrorState = createSelector(getSearchState, state => state.error);

export const isSearchFailed = createSelector(getErrorState, error => {
  return (
    error.type === Error.Generic ||
    Object.values(SearchCategory).every(category => isCategorySearchFailed(error, category))
  );
});

export const isAnyCategoryFailed = createSelector(getErrorState, error => {
  return Object.values(SearchCategory).some(category => isCategorySearchFailed(error, category));
});

export const showFilters = createSelector(getSearchState, state => state.panel === Panel.Filters);
export const showActions = createSelector(getSearchState, state => state.panel === Panel.Actions);
export const showPanel = createSelector(getSearchState, state => state.panel !== Panel.None);

export const getSelectedEntities = createSelector(getSearchState, state => state.selectedEntities);
export const getChipItems = createSelector(getSelectedEntities, selectedEntities => mapToChipsItems(selectedEntities));
export const hasSelectedEntities = createSelector(getSelectedEntities, selectedEntities => !!selectedEntities?.length);

export const getPopupHeaderState = createSelector(getFilter, hasSelectedEntities, (filter, hasSelected) => {
  if (filter && window.innerWidth > Breakpoint.Sm) return PopupHeaderState.Filter;
  if (hasSelected) return PopupHeaderState.EntitiesSelected;
  return PopupHeaderState.Default;
});

export const getAvailableSpecialActions = createSelector(getSelectedEntities, selected => getSpecialActions(selected));

export const getSubsetSelector = memoize(<T extends SearchEntity>(searchCategory: SearchCategory) => {
  return createSelector(getSearchState, ({ results, buckets }) => {
    return getSubset<T>(results, buckets, searchCategory);
  });
});

export const getIsSubsetSearchFailedSelector = memoize((searchCategory: SearchCategory) => {
  return createSelector(getErrorState, error => {
    return isCategorySearchFailed(error, searchCategory);
  });
});

export const getShowSubsetSelector = memoize((searchCategory: SearchCategory) => {
  const itemsSelector = getSubsetSelector<SearchEntity>(searchCategory);
  const isItemsSearchFailedSelector = getIsSubsetSearchFailedSelector(searchCategory);
  return createSelector(itemsSelector, isItemsSearchFailedSelector, getFilter, (items, isFailed, filter) => {
    return showCategoryResult(items, isFailed, searchCategory, filter);
  });
});

export const getHasMoreSelector = memoize((searchCategory: SearchCategory) => {
  return createSelector(getSearchState, ({ results, buckets }) => hasMore(results, buckets, searchCategory));
});
