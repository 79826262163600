import { CompositeId, ProfileId, ProfileType } from '@capital-access/profiles/common';
import { BaseReportRequest } from '../../core/models/report-request.models';
import { ReportSection } from '../../core/models/report-sections';

export interface CreateProfileReportRequest extends BaseReportRequest {
  profileIds: string[];
  profileType: string;
  sections: ReportSection[];
  defaultPeerListId?: number;
}

export class CreateProfileReportDto {
  profiles!: ProfileId[];
  profileType!: ProfileType;

  static create(
    profiles: { prId?: number | null; crmId?: number | null; name?: string | null }[],
    profileType: 'contact' | 'fund' | 'institution'
  ): CreateProfileReportDto {
    return {
      profileType: profileType as ProfileType,
      profiles: profiles.map(p => new ProfileId(new CompositeId(p.prId, p.crmId), profileType as ProfileType, p.name))
    };
  }
}
