import { createAction, props } from '@ngrx/store';
import { DeepNullable, EmptyConstructor } from '@capital-access/common/utils';
import { SettingsConstructor } from '../models/internal/settings-constructor';
import { SerializedSettingsEntity } from '../models/settings-entity';

export const saveSetting = createAction('[Settings] Save User Setting', props<{ key: string; value: string | null }>());

export const saveSerializedSettings = createAction(
  '[Settings] Save User Settings',
  props<{ data: SerializedSettingsEntity[]; silently?: boolean }>()
);

export const saveSerializedSettingsSilentlySuccess = createAction(
  '[Settings] Save User Settings Silently Success',
  props<{ settings: SerializedSettingsEntity[] }>()
);

export function saveSettings<T>(settingsClass: EmptyConstructor<T>, props: { section: string; data: DeepNullable<T> }) {
  const serializedSettingEntities = SettingsConstructor.fromCtor(settingsClass).getSettingsEntities(
    props.section,
    props.data
  );

  return saveSerializedSettings({ data: serializedSettingEntities });
}

export const saveSettingsFailure = createAction(
  '[Settings] Save User Settings Failed',
  props<{ error: unknown; failedIds: string[] }>()
);

export const saveSettingsSuccess = createAction(
  '[Settings] Save User Settings Success',
  props<{ settings: SerializedSettingsEntity[] }>()
);

export const markIdsForReset = createAction('[Settings] Mark Ids for Reset', props<{ markedForResetIds: string[] }>());

export const clearIdsMarkedForReset = createAction('[Settings] Clear Ids Marked for Reset');
