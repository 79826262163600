import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'f-message-label',
  templateUrl: './message-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMessageLabelComponent {
  @Input() name!: string;
  @Input() timestamp!: string;
}
