import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { NavConfig, NavConfigFn, NavigationConfigProvider } from '@capital-access/common/navigation';
import { SharedNavigationItemsService } from './ca-shared-navigation-items.service';

@Injectable()
export class SharedNavConfigProvider implements NavigationConfigProvider {
  constructor(private sharedNavigationItemsService: SharedNavigationItemsService) {}

  resolveNavConfig: NavConfigFn = () => {
    return this.sharedNavigationItemsService.userSettingsOptionsSide$.pipe(
      map(mobileUser => {
        return <NavConfig>{
          mobileToggle: this.sharedNavigationItemsService.toggleSettingsOption,
          mobileEntity: this.sharedNavigationItemsService.entitySettingsOptionsSide,
          mobileUser
        };
      })
    );
  };
}
