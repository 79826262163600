export const drawerOutClickExceptionClass = 'f-drawer-outclick-exception-element';
export const drawerZIndex = 960;
export const defaultModalLocalization: Record<string, string> = {
  modalLeaveFormTitle: 'Leave the Form?',
  modalConfirmationMessage: 'If you leave, your changes will be lost',
  modalConfirmTitle: 'Leave Anyway',
  modalCancelTitle: 'Keep Editing',
  modalDiscardChangesTitle: 'Discard Changes?',
  modalCancelColumnChooserTitle: 'Keep Selecting'
};
export const drawerWithoutFooterClasses = ['pb-5', 'pb-sm-7'];
export const drawerWithFooterClasses = ['pb-0', 'h-100'];
