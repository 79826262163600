export function isFeatureToggleEnabled(userToggleProfile: string[], featureToggle: string): boolean {
  if (!featureToggle || !userToggleProfile) {
    return false;
  }

  return !!userToggleProfile.find(toggle => toggle === featureToggle);
}

export function getFeatureToggleValue(featureToggle: string): string {
  return featureToggle.toLowerCase();
}
