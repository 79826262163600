import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, withLatestFrom } from 'rxjs';
import { COMMON_CURRENCY_LOCALIZATION_SCOPE, getCurrencyRate } from '@capital-access/common/currency';
import { LocalizationService } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';

@Pipe({ name: 'caServerSideCurrency', pure: false })
export class ServerSideCurrencyPipe extends BaseAsyncPipe<string, string | null | undefined> implements PipeTransform {
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    private localizationService: LocalizationService
  ) {
    super(changeDetectorRef);
  }

  getTransformer(value: string | null): Observable<string> {
    return this.store.select(getCurrencyRate).pipe(
      withLatestFrom(this.localizationService.getLocalization(COMMON_CURRENCY_LOCALIZATION_SCOPE)),
      map(([rate, { notApplicableTitle }]) => {
        if (rate === null) {
          return notApplicableTitle;
        }

        return value;
      })
    );
  }
}
