<ca-search-category-list
  caLocalizationScope="search-nav"
  data-automation-id="ca-funds-results-container"
  class="ca-one-search-results-list"
  *ngIf="showItems$ | async"
  [header]="'inFunds' | caLocalize"
  [items]="items$ | async"
  [showMoreHeader]="'showMore' | caLocalize"
  [hasMore]="hasMore$ | async"
  [rowTemplate]="rowFundTemplate"
  [category]="categories.Funds"
  [currentCriteria]="$any(currentCriteria$ | async)"
  [isSearchFailed]="isItemsSearchFailed$ | async"
  (updateSelection)="updateSelection($event)"
  [trackByFn]="trackByMethod"
  (showMore)="showMore($event)"
>
</ca-search-category-list>

<ng-template #rowFundTemplate let-fund="item" let-criteria="criteria">
  <span
    data-automation-id="ca-search-fund-link"
    class="link link-main w-auto ca-search-link"
    [innerHTML]="fund.name | fHighlight : criteria : 'fw-bold text-reset'"
    [title]="fund.name"
    (click)="openProfilePreview(fund)"
  ></span>

  <ng-template #fundTooltip>
    <span
      data-automation-id="onesearch-tooltip"
      [innerHTML]="fund.name | fHighlight : criteria : 'body-50 text-base fw-bold'"
    ></span>
  </ng-template>
</ng-template>
