import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { fallbackCulture, getUserSettingsCulture } from '@capital-access/common/globalization';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { LOAD_MODULE, MODULE_ID } from './kendo-globalization-module-id.model';

@Injectable()
export class KendoGlobalizationEffects {
  load$ = createEffect(
    () =>
      this.store.select(getUserSettingsCulture).pipe(
        filter(
          culture => culture !== fallbackCulture || (<CldrIntlService>this.intlService).localeId !== fallbackCulture
        ),
        distinctUntilChanged(),
        switchMap(culture => this.setLocale(culture))
      ),
    { dispatch: false }
  );

  private setLocale(value: string) {
    return this.commonLazyLoadingService.loadModule(MODULE_ID, LOAD_MODULE).pipe(
      tap(moduleRef => {
        moduleRef.instance.setLocale(value);
      })
    );
  }

  constructor(
    private store: Store,
    private commonLazyLoadingService: CommonLazyLoadingService,
    private intlService: IntlService
  ) {}
}
