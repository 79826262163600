import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideAuthGuard } from '@capital-access/common/router';
import { UserSettingsModule } from './settings/user-settings.module';
import { UserGuard } from './user/user.guard';
import { UserModule } from './user/user.module';

@NgModule({
  imports: [CommonModule, UserModule, UserSettingsModule]
})
export class CommonUserModule {
  static forRoot(): ModuleWithProviders<CommonUserModule> {
    return {
      ngModule: CommonUserModule,
      providers: [provideAuthGuard(UserGuard)]
    };
  }
}
