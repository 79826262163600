import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySkeletonSmallCardLoaderModule } from '../small-card-templates/skeleton-small-card-loader.module';
import { FireflySkeletonDrawerComponent } from './skeleton-drawer-loader.component';

@NgModule({
  imports: [CommonModule, FireflySkeletonSmallCardLoaderModule],
  declarations: [FireflySkeletonDrawerComponent],
  exports: [FireflySkeletonDrawerComponent]
})
export class FireflySkeletonDrawerLoaderModule {}
