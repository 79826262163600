import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { PwaEffects } from './+state/pwa.effects';
import { handleMissingPullToReload } from './utils/utils';

const pwaEnabled = localStorage.getItem('pwa') === 'allowed';

@NgModule({
  imports: [
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: pwaEnabled,
      registrationStrategy: 'registerWhenStable:5000'
    }),
    EffectsModule.forFeature([PwaEffects])
  ]
})
export class CommonPwaRootModule {}

@NgModule({
  imports: [CommonModule]
})
export class CommonPwaModule {
  static forRoot(enableProdMode: boolean): ModuleWithProviders<CommonPwaRootModule> {
    /**
     * Fix missing pull-to-reload functionality for standalone iOS PWA (CPD-1763).
     */
    const isTouchScreen = window.matchMedia(`(pointer: coarse)`).matches;
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const pwaShell = /PWA/gi.test(navigator.userAgent); // iOS PWAShell has pull-to-reload enabled.

    if (isStandalone && isTouchScreen && !pwaShell) {
      handleMissingPullToReload();
    }

    return { ngModule: enableProdMode ? CommonPwaRootModule : CommonPwaModule };
  }
}
