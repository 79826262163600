import { Directive, HostListener, Input, OnDestroy, Optional, QueryList, SkipSelf } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[fDropdownListFocus]'
})
export class FireflyDropdownListFocusDirective implements OnDestroy {
  private focusSub!: Subscription;
  private keyDownSub!: Subscription;
  private openChangeSub!: Subscription;
  private _focusableElements: QueryList<HTMLElement> = new QueryList();
  private nestedDropdownIsOpen = false;
  private focusableNestedItemsContainer!: HTMLElement;

  @Input() focusableItemsContainer!: HTMLElement;
  @Input() tabbable = true;

  constructor(@Optional() @SkipSelf() private dropdown: NgbDropdown) {
    this.openChangeSub = this.dropdown.openChange.subscribe(() => {
      this.nestedDropdownIsOpen = false;
    });
  }

  get focusableElements(): QueryList<HTMLElement> {
    const query = this.nestedDropdownIsOpen ? '[tabIndex]' : '[tabIndex]:not([fNestedDropdown] [tabindex])';
    const container = this.nestedDropdownIsOpen ? this.focusableNestedItemsContainer : this.focusableItemsContainer;
    const elements = Array.from(container.querySelectorAll(query)) as Array<HTMLElement>;
    this._focusableElements.reset(elements.filter(el => parseInt(el.getAttribute('tabindex') || '0', 10) >= 0));
    return this._focusableElements;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if (!this.tabbable) return;

    if (!this.focusableElements.length) return;

    if (!this.focusSub) {
      this.focusSub = fromEvent(this.focusableItemsContainer, 'focusin').subscribe(() => {
        this.keyBoardNavigate();
      });
    }

    if ($event.code === 'ArrowDown') {
      this.focusNextElement($event);
    }
  }

  ngOnDestroy() {
    this.focusSub?.unsubscribe();
    this.keyDownSub?.unsubscribe();
    this.openChangeSub.unsubscribe();
    this.nestedDropdownIsOpen = false;
  }

  private keyBoardNavigate() {
    if (this.keyDownSub) return;

    this.keyDownSub = fromEvent(this.focusableItemsContainer, 'keydown').subscribe(event => {
      const $event = event as KeyboardEvent;
      const { isTab, isArrowUp, isArrowDown, isArrowLeft, isArrowRight } = this.getKeysPressed($event);

      if (!(isTab || isArrowDown || isArrowUp || isArrowLeft || isArrowRight)) return;

      this.focusNextElement($event);
    });
  }

  private focusNextElement($event: KeyboardEvent) {
    const focusedElement = document.activeElement;
    const { isTab, isArrowUp, isShiftKeyPressed, isArrowLeft, isArrowRight } = this.getKeysPressed($event);

    const isActiveTextInput =
      focusedElement?.classList.contains('form-control') && focusedElement?.getAttribute('type') === 'text';

    if (isActiveTextInput && (isArrowLeft || isArrowRight)) {
      return;
    }

    $event.preventDefault();

    if (isArrowRight || isArrowLeft) {
      const nestedDropdownIsOpen =
        this.focusableNestedItemsContainer?.classList.contains('show') || focusedElement?.classList.contains('show');
      const nestedDropdownPlacement =
        this.focusableNestedItemsContainer?.getAttribute('placement') || focusedElement?.getAttribute('placement');

      if (!nestedDropdownIsOpen) return;

      if (isArrowRight && nestedDropdownPlacement?.includes('left')) {
        this.focusableNestedItemsContainer?.focus();
        this.nestedDropdownIsOpen = false;
        return;
      }

      if (isArrowLeft && nestedDropdownPlacement?.includes('right')) {
        this.focusableNestedItemsContainer?.focus();
        this.nestedDropdownIsOpen = false;
        return;
      }

      this.focusableNestedItemsContainer = focusedElement as HTMLElement;
      this.nestedDropdownIsOpen = true;
    }

    const sign = isArrowUp || (isTab && isShiftKeyPressed) ? -1 : 1;
    const focusedElementIndex = this.focusableElements.toArray().findIndex(el => el === focusedElement);
    let item = this.focusableElements.get(focusedElementIndex + sign);

    if (!item) item = sign === 1 ? this.focusableElements.first : this.focusableElements.last;

    item?.focus();
  }

  private getKeysPressed($event: KeyboardEvent) {
    const isTab = $event.code === 'Tab';
    const isShiftKeyPressed = $event.shiftKey;
    const isArrowUp = $event.code === 'ArrowUp';
    const isArrowDown = $event.code === 'ArrowDown';
    const isArrowLeft = $event.code === 'ArrowLeft';
    const isArrowRight = $event.code === 'ArrowRight';
    return { isTab, isShiftKeyPressed, isArrowUp, isArrowDown, isArrowLeft, isArrowRight };
  }
}
