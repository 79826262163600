import { Pipe, PipeTransform } from '@angular/core';
import { EventType } from '../models/event.enums';

export const EventTypeLocalizationKeys = {
  [EventType.AnalystDay]: 'analystDay',
  [EventType.Conference]: 'conference',
  [EventType.DealRoadshow]: 'dealRoadshow',
  [EventType.EarningsCall]: 'earningsCall',
  [EventType.FieldTrip]: 'fieldTrip',
  [EventType.InvestorDay]: 'investorDay',
  [EventType.NonDealRoadshow]: 'nonDealRoadshow',
  [EventType.PostEarningsCall]: 'postEarningsCall'
};

@Pipe({
  name: 'caEventTypeLocalizationKey'
})
export class EventTypeLocalizationKeyPipe implements PipeTransform {
  transform(type: EventType | string): string {
    return EventTypeLocalizationKeys[type as EventType];
  }
}
