import { Observable } from 'rxjs';
import { UserNotification } from '../model/userNotification';

export abstract class UserNotificationsRepository {
  public abstract getNotifications(request: {
    limit?: number;
    beforeTimestamp?: string;
    lastRetrievedId?: string;
  }): Observable<UserNotification[]>;
}
