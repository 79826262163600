import {
  AfterViewInit,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  ViewContainerRef
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
  DateRangePopupComponent,
  DateRangeService,
  MultiViewCalendarComponent
} from '@progress/kendo-angular-dateinputs';
import { DateInputComponent, DateRangeComponent } from '@progress/kendo-angular-dateinputs';
import { SelectionRange } from '@progress/kendo-angular-dateinputs/calendar/models/selection-range.interface';
import { forkJoin, fromEvent, merge, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { drawerOutClickExceptionClass } from '../../drawer/constants';
import { FireflyModalService } from '../../modal';
import { ModalRef } from '../../modal/models/base-modal-options';
import { Breakpoint, FireflyLocalizationService } from '../../utils';
import { FireflyMobileDateRangeComponent } from '../components/mobile-date-range.component';

@Directive({
  selector: '[fDateRangePicker]'
})
export class FireflyDateRangeDirective implements OnChanges, AfterViewInit, OnDestroy {
  protected modalDialogClass = drawerOutClickExceptionClass + ' mobile-datepicker';
  protected modalDialogTitle = 'Date';
  protected modal!: ModalRef<unknown> | null;
  protected initialRange!: SelectionRange;

  private destroyed$ = new Subject<void>();
  private activeDateInput!: DateInputComponent | null;
  private firstInputResetBtn = document.createElement('div');
  private lastInputResetBtn = document.createElement('div');
  private confirmationBtn = document.createElement('button');
  private popoverContainerRef!: 'root' | 'component' | ViewContainerRef;
  private confirmationBtnDisabledTitle = 'Please select range first';
  private confirmationBtnTitle = 'Set Dates';
  private calendarValueChangeSub$ = Subscription.EMPTY;
  private outClickSub$: Subscription | null = null;
  private changesConfirmed = false;
  private dateRangeFocus = false;
  private dateRangeOutlined = false;

  @Input() showDateRangePopup = true;
  @Input() disabledDates: ((date: Date) => boolean) | null = null;
  @Input() @HostBinding('class.k-collapsed-mobile-and-tablet') collapsableMobileAndTablet = false;
  @Input() @HostBinding('class.k-collapsed-mobile') collapsableMobile = false;
  @Input() @HostBinding('class.with-reset-btn') showResetBtn = true;
  @Input() @HostBinding('class.k-daterangepicker-mobile-sm') mobileSm = false;
  @Input() @HostBinding('class.with-confirmation-btn') showConfirmationBtn = false;
  @Input() confirmationBtnDisabledFn = (range: SelectionRange) =>
    !(range.start && range.end && range.start <= range.end);

  @Output() confirmSelectionRange = new EventEmitter<SelectionRange>();

  @ContentChild(MultiViewCalendarComponent, { descendants: true }) calendar!: MultiViewCalendarComponent;
  @ContentChild(DateRangePopupComponent, { descendants: true }) dateRangePopup!: DateRangePopupComponent;
  @ContentChildren(DateInputComponent, { descendants: true }) dateInputs!: QueryList<DateInputComponent>;

  @HostBinding('class.is-warning') get isDateRangeWarning() {
    return this.checkDateRangeClass('is-warning');
  }
  @HostBinding('class.is-invalid') get isDateRangeInvalid() {
    return this.checkDateRangeClass('is-invalid');
  }
  @HostBinding('class.k-focus') get isDateRangeFocus() {
    return this.dateRangeFocus;
  }
  @HostBinding('class.f-is-outlined') get isDateRangeOutlined() {
    return this.dateRangeOutlined;
  }
  @HostBinding('class.f-is-collapsed') get isDateRangeCollapsed() {
    return this.dateRangeCollapsed;
  }

  initialRangeValues!: { start: string; end: string };
  currentRangeValues!: { start: string; end: string };
  rangeChange$ = new Subject<{ start: string; end: string }>();

  get dateRangeCollapsed(): boolean {
    return (
      (this.collapsableMobileAndTablet && window.innerWidth < Breakpoint.Md) ||
      (this.collapsableMobile && window.innerWidth < Breakpoint.Sm)
    );
  }

  get firstDateInputWrapper() {
    return this.dateInputs.first?.wrapper.nativeElement as HTMLElement;
  }

  get lastDateInputWrapper() {
    return this.dateInputs.last?.wrapper.nativeElement as HTMLElement;
  }

  get dateRangePopupElement() {
    return this.dateRangePopup.popupRef?.popupElement;
  }

  get selectionRange(): SelectionRange {
    return {
      start: this.dateInputs.first.value,
      end: this.dateInputs.last.value
    };
  }

  get selectionRangeValues(): { start: string; end: string } {
    return {
      start: this.dateInputs.first.inputElement.value,
      end: this.dateInputs.last.inputElement.value
    };
  }

  constructor(
    protected modalService: FireflyModalService,
    protected dateRangeService: DateRangeService,
    @Host() protected dateRangePicker: DateRangeComponent,
    @Optional() protected localizationService: FireflyLocalizationService,
    @Optional() protected dropdown: NgbDropdown
  ) {}

  ngOnChanges() {
    setTimeout(() => {
      this.checkResetButtons();
      this.checkConfirmationButton();
    });
  }

  ngAfterViewInit() {
    if (!this.dateInputs.length) return;

    this.popoverContainerRef = this.dateRangePopup?.appendTo ?? 'component';

    this.confirmationBtn.tabIndex = 0;
    this.confirmationBtn.title = this.confirmationBtnTitle;
    this.confirmationBtn.textContent = this.confirmationBtnTitle;
    this.confirmationBtn.dataset.automationId = 'confirmation-btn';

    window.requestAnimationFrame(() => {
      this.addCalendarIcon(this.dateInputs.first);
      this.addCalendarIcon(this.dateInputs.last);
      this.setDateRangeValidationClass();
      this.setDateRangeOutlineClass();
    });

    if (this.localizationService) {
      forkJoin([
        this.localizationService.localize('confirmationBtnTitle', {}),
        this.localizationService.localize('confirmationBtnDisabledTitle', {})
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([title, disabledTitle]) => {
          this.confirmationBtn.title = title;
          this.confirmationBtn.textContent = title;
          this.confirmationBtnDisabledTitle = disabledTitle;
          this.confirmationBtnTitle = title;
        });
    }

    if (this.showResetBtn || this.showConfirmationBtn) {
      this.dateInputs.first.valueChange.pipe(takeUntil(this.destroyed$)).subscribe(value => {
        window.requestAnimationFrame(() => {
          this.updateConfirmationBtnDisabledState();
          if (!this.showResetBtn) return;
          if (value) {
            this.checkResetButtons();
          } else {
            this.firstInputResetBtn?.remove();
            if (!this.dateInputs.last.inputElement.value) {
              this.lastInputResetBtn?.remove();
            }
          }
        });
      });

      this.dateInputs.last.valueChange.pipe(takeUntil(this.destroyed$)).subscribe(value => {
        window.requestAnimationFrame(() => {
          this.updateConfirmationBtnDisabledState();
          if (!this.showResetBtn) return;
          if (value) {
            this.checkResetButtons();
          } else {
            this.lastInputResetBtn?.remove();
            if (!this.dateInputs.first.inputElement.value) {
              this.firstInputResetBtn?.remove();
            }
          }
        });
      });
    }

    if (window.innerWidth < Breakpoint.Md || this.showConfirmationBtn || this.showResetBtn) {
      this.checkResetButtons();
      this.updateState();
    }

    if (this.showConfirmationBtn) {
      this.handleConfirmKeyboardEvent();

      this.confirmationBtn.classList.add('btn', 'btn-primary', 'w-fit', 'me-auto', 'ms-6', 'mb-4');

      this.confirmationBtn.addEventListener('click', () => {
        this.confirmSelectionRange.emit(this.selectionRange);
        if (!this.dropdown) this.updateState();
        this.changesConfirmed = true;
        this.unsubscribeFromOutClickSub();
        this.focusOut();
        this.close();
      });

      this.checkConfirmationButton();

      this.dropdown?.openChange.pipe(takeUntil(this.destroyed$)).subscribe(isOpen => {
        if (!isOpen) {
          if (!this.changesConfirmed) {
            this.resetState();
          } else {
            setTimeout(() => this.updateState());
          }
        } else {
          this.updateConfirmationBtnDisabledState();
        }
        this.changesConfirmed = false;
      });
    }

    this.setActiveDateInputOnClick(this.firstDateInputWrapper, this.dateInputs.first);
    this.setActiveDateInputOnClick(this.lastDateInputWrapper, this.dateInputs.last, this.firstDateInputWrapper);

    this.dateRangePopup?.open.pipe(takeUntil(this.destroyed$)).subscribe(e => {
      if (!this.showDateRangePopup || this.outClickSub$) {
        e.preventDefault();
        this.focusOut();
        return;
      }

      window.requestAnimationFrame(() => this.setDateRangeFocusClass());

      if (window.innerWidth < Breakpoint.Sm) {
        e.preventDefault();
        this.openModal();
      } else {
        this.handleOutClick();
        setTimeout(() => {
          this.checkConfirmationButton();
          this.dateRangePopup.calendar?.valueChange.pipe(takeUntil(this.dateRangePopup.close)).subscribe(() => {
            this.checkResetButtons();
            if (this.showConfirmationBtn) {
              this.updateConfirmationBtnDisabledState();
              this.currentRangeValues = this.selectionRangeValues;
            }
          });
        });
      }
    });

    this.dateRangePopup?.close.pipe(takeUntil(this.destroyed$)).subscribe(e => {
      if (this.outClickSub$) this.unsubscribeFromOutClickSub();

      window.requestAnimationFrame(() => {
        this.setDateRangeValidationClass();
        this.setDateRangeFocusClass();
      });

      if (!this.showDateRangePopup || window.innerWidth < Breakpoint.Sm) {
        e.preventDefault();
        return;
      } else if (this.showConfirmationBtn) {
        setTimeout(() => {
          this.resetState();
          this.setDateRangeFocusClass();
        });
      }
    });

    if (!this.showDateRangePopup) {
      // prevents unpredicted behavior for calendar with embedded inputs:
      // workaround with preventing events for closing dropdown didn't give expected result for selected dates confirmation
      this.calendar.tabIndex = -1;

      this.handleInputBlur(this.dateInputs.first);
      this.handleInputBlur(this.dateInputs.last);

      this.bindCalendarWithInputOnFocus();
    }

    this.preventDropdownCloseEvent();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openPopupFromModal(ref: ViewContainerRef) {
    this.dateRangePopup.animate = false;
    this.dateRangePopup.appendTo = ref;
    this.dateRangePopup.toggle(true);
    this.dateRangePopup.activate();

    setTimeout(() => {
      this.dateRangePopup.calendar.views = 1;
      if (this.disabledDates) {
        this.dateRangePopup.calendar.disabledDates = this.disabledDates;
      }

      // a workaround for the loss of focus in date inputs, caused by setting calendar views to 1.
      requestAnimationFrame(() => {
        if (this.activeDateInput === this.dateInputs.first) {
          this.dateInputs.first.inputElement.focus();
        }
        if (this.activeDateInput === this.dateInputs.last) {
          this.dateInputs.last.inputElement.focus();
        }
      });
      this.dateRangePopup.calendar.focusedDate = this.dateInputs.last.value!;
      this.calendarValueChangeSub$ = this.dateRangePopup.calendar.valueChange
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.currentRangeValues = this.selectionRangeValues;
          this.rangeChange$.next(this.currentRangeValues);
          this.checkResetButtons();
        });
    });
  }

  openModal() {
    if (this.modal) return;

    this.modal = this.modalService.open({
      component: FireflyMobileDateRangeComponent,
      modalDialogClass: this.modalDialogClass,
      title: this.modalDialogTitle,
      context: this,
      mobile: true
    });

    this.modal.result
      .then(
        () => {
          this.updateState();
          this.confirmSelectionRange.emit(this.selectionRange);
        },
        () => this.resetState()
      )
      .finally(() => {
        this.focusOut();
        setTimeout(() => this.checkResetButtons());
        setTimeout(() => {
          if (this.dateRangePopup) this.dateRangePopup.appendTo = this.popoverContainerRef;
          this.calendarValueChangeSub$.unsubscribe();
          this.modal = null;
          this.close();
        }, 100);
      });
  }

  private handleOutClick() {
    this.outClickSub$ = fromEvent(document, 'click').subscribe(e => {
      if (
        this.dateRangePopupElement.contains(e.target as Node) ||
        this.firstDateInputWrapper.contains(e.target as Node) ||
        this.lastDateInputWrapper.contains(e.target as Node) ||
        e.target === this.confirmationBtn
      ) {
        return;
      }

      this.dateRangePopup.cancelPopup();
      this.unsubscribeFromOutClickSub();

      window.requestAnimationFrame(() => {
        this.setDateRangeValidationClass();
        this.focusOut();
      });
    });
  }

  private unsubscribeFromOutClickSub() {
    this.outClickSub$?.unsubscribe();
    this.outClickSub$ = null;
  }

  private setRange(range: SelectionRange) {
    window.requestAnimationFrame(() => {
      this.dateRangeService.setRange(range);
    });
  }

  protected updateState() {
    this.initialRange = this.selectionRange;
    this.initialRangeValues = this.selectionRangeValues;
  }

  protected resetState() {
    if (this.showConfirmationBtn) this.updateConfirmationBtnDisabledState();
    if (this.calendar && this.initialRange.end) this.calendar.focusedDate = this.initialRange.end;
    this.setRange(this.initialRange);
  }

  private checkConfirmationButton(): void {
    this.confirmationBtn.remove();

    if (!this.calendar || !this.showConfirmationBtn || window.innerWidth < Breakpoint.Sm) return;

    this.currentRangeValues = this.selectionRangeValues;

    this.updateConfirmationBtnDisabledState();

    this.calendar.element.nativeElement.append(this.confirmationBtn);
  }

  private checkResetButtons(): void {
    this.firstInputResetBtn.remove();
    this.lastInputResetBtn.remove();

    if (!this.showResetBtn) return;

    this.firstDateInputWrapper.classList.add('empty');
    this.lastDateInputWrapper.classList.add('empty');

    if (this.dateInputs.first.value) {
      this.addResetBtn(this.firstInputResetBtn, this.dateInputs.first, 'start');
    }
    if (this.dateInputs.last.value) {
      this.addResetBtn(this.lastInputResetBtn, this.dateInputs.last, 'end');
    }
  }

  private addResetBtn(resetBtn: HTMLElement, dateInput: DateInputComponent, rangeSelectionKey: keyof SelectionRange) {
    resetBtn.classList.add('k-reset-button');
    resetBtn.innerHTML = '<i class="f-i f-i-close f-i-xs cursor-pointer" tabindex="0" title="Clear"></i>';

    const resetBtnListener = (e: Event) => {
      this.onResetBtnClick(rangeSelectionKey, e);
      dateInput.wrapper.nativeElement.classList.add('empty');
      resetBtn.remove();
    };

    resetBtn.addEventListener('click', resetBtnListener, true);
    resetBtn.addEventListener(
      'keydown',
      e => {
        if (e.key === 'Enter') resetBtnListener(e);
      },
      true
    );

    dateInput.wrapper.nativeElement.classList.remove('empty');
    dateInput.wrapper.nativeElement.appendChild(resetBtn);
  }

  private addCalendarIcon(dateInput: DateInputComponent): void {
    const calendarIcon = document.createElement('i');
    calendarIcon.classList.add('f-i', 'f-i-calendar');
    dateInput.wrapper.nativeElement.appendChild(calendarIcon);
  }

  private onResetBtnClick(rangeSelectionKey: keyof SelectionRange, e: Event) {
    if (this.dateRangeCollapsed) {
      this.initialRangeValues = { start: '', end: '' };
      this.currentRangeValues = { start: '', end: '' };
      this.initialRange = { start: null, end: null };
      this.setRange(this.initialRange);
      setTimeout(() => {
        this.firstDateInputWrapper.classList.add('empty');
        this.lastDateInputWrapper.classList.add('empty');
      });
      this.firstInputResetBtn.remove();
      this.lastInputResetBtn.remove();
    } else if (!this.dropdown && this.showConfirmationBtn) {
      this.initialRangeValues[rangeSelectionKey] = '';
      this.initialRange[rangeSelectionKey] = null;
      this.setRange(this.initialRange);
    } else {
      const range = this.dateRangeService.selectionRange;
      range[rangeSelectionKey] = null;
      this.setRange(range);
    }
    e.stopImmediatePropagation();
    this.unsubscribeFromOutClickSub();
  }

  private updateConfirmationBtnDisabledState() {
    const disabled = this.confirmationBtnDisabledFn(this.selectionRange);
    this.confirmationBtn.disabled = disabled;
    this.confirmationBtn.tabIndex = disabled ? -1 : 0;
    this.confirmationBtn.title = disabled ? this.confirmationBtnDisabledTitle : this.confirmationBtnTitle;
  }

  private handleInputBlur(dateInput: DateInputComponent) {
    dateInput.inputElement.addEventListener('click', () => {
      dateInput.inputElement.addEventListener('blur', (e: InputEvent) => this.preventInputBlur(e), {
        capture: true,
        once: true
      });
      window.requestAnimationFrame(() => {
        // Safari fix
        dateInput.inputElement.focus();
        dateInput.wrapper.nativeElement.classList.add('k-focus');
      });
    });
  }

  private preventInputBlur(e: InputEvent) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  private close() {
    try {
      this.dropdown?.close();
      this.dateRangePopup?.toggle(false);
      // eslint-disable-next-line no-empty
    } catch {}
  }

  private focusOut() {
    // Safari fix
    this.dateInputs.first.inputElement.blur();
    this.dateInputs.last.inputElement.blur();
    this.firstDateInputWrapper.classList.remove('k-focus');
    this.lastDateInputWrapper.classList.remove('k-focus');
    this.dateRangeFocus = false;
  }

  private setDateRangeValidationClass() {
    this.checkDateRangeClass('is-warning');
    this.checkDateRangeClass('is-invalid');
  }

  private setDateRangeOutlineClass() {
    this.dateRangeOutlined = this.checkDateRangeClass('k-input-outline');
  }

  private setDateRangeFocusClass() {
    this.dateRangeFocus = this.checkDateRangeClass('k-focus');
  }

  private checkDateRangeClass(className: string): boolean {
    return (
      this.firstDateInputWrapper?.classList.contains(className) ||
      this.lastDateInputWrapper?.classList.contains(className)
    );
  }

  private setActiveDateInputOnClick(
    wrapper: HTMLElement,
    dateInput: DateInputComponent,
    initiatedWrapper?: HTMLElement
  ) {
    fromEvent(wrapper, 'click')
      .pipe(
        takeUntil(this.destroyed$),
        filter(e => e.target === wrapper)
      )
      .subscribe(() => {
        if (this.dateRangeCollapsed && initiatedWrapper) {
          initiatedWrapper.click();
        } else {
          this.activeDateInput = dateInput;
        }
      });
  }

  private bindCalendarWithInputOnFocus() {
    fromEvent(this.dateInputs.first.inputElement, 'focus')
      .pipe(takeUntil(this.destroyed$), take(1))
      .subscribe(() => this.dateInputs.first.inputElement.click());

    fromEvent(this.dateInputs.last.inputElement, 'focus')
      .pipe(takeUntil(this.destroyed$), take(1))
      .subscribe(() => this.dateInputs.last.inputElement.click());
  }

  private preventDropdownCloseEvent() {
    merge(
      fromEvent([this.firstDateInputWrapper, this.lastDateInputWrapper], 'keydown'),
      fromEvent([this.firstDateInputWrapper, this.lastDateInputWrapper], 'keyup')
    )
      .pipe(
        takeUntil(this.destroyed$),
        map(e => e as KeyboardEvent),
        filter(e => e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowDown' || e.key === 'ArrowUp')
      )
      .subscribe(e => e.stopImmediatePropagation());
  }

  private handleConfirmKeyboardEvent() {
    fromEvent([this.firstDateInputWrapper, this.lastDateInputWrapper], 'keyup')
      .pipe(
        takeUntil(this.destroyed$),
        map(e => e as KeyboardEvent),
        filter(e => e.key === 'Enter')
      )
      .subscribe(e => {
        !this.confirmationBtn.disabled ? this.confirmationBtn.click() : e.stopImmediatePropagation();
      });
  }
}
