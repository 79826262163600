import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { criteriaChanged } from '../../+state/search.actions';
import { getSearchCriteria } from '../../+state/search.selectors';
import { SEARCH_DEBOUNCE_TIME_MS } from '../../constants';
import { initialInputState, OneSearchInputState } from './one-search-input.state';

@Injectable()
export class OneSearchInputService extends ComponentStore<OneSearchInputState> implements OnDestroy {
  readonly searchText$: Observable<string> = this.select(state => state.searchText);
  private subscription$ = new Subject<void>();

  constructor(private store: Store) {
    super(initialInputState);
    this.searchText$
      .pipe(takeUntil(this.subscription$), debounceTime(SEARCH_DEBOUNCE_TIME_MS), distinctUntilChanged())
      .subscribe(text => {
        this.store.dispatch(criteriaChanged({ criteria: text }));
      });

    this.store
      .select(getSearchCriteria)
      .pipe(takeUntil(this.subscription$))
      .subscribe(text => {
        this.setSearchText(text);
      });
  }

  setSearchText(searchText: string) {
    this.setState({ searchText });
  }

  override ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }
}
