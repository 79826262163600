import flatten from 'lodash/flatten';
import { ActivatedRoutes } from '@capital-access/common/router';
import { BreadcrumbItem } from '../models/breadcrumb-item.model';
import { BreadcrumbComponentTagsResolver, BreadcrumbItemsFn, BreadcrumbStrategy } from '../models/strategy.model';

/**
 * Default breadcrumb strategy.
 * Combines all breadcrumbs items from activated routes into one list sorted from the roots
 * Also always has root home page as a first item
 */
const defaultStrategy =
  (homePageItem: BreadcrumbItem) =>
  (routes: ActivatedRoutes, { getItems }: BreadcrumbComponentTagsResolver) =>
    [homePageItem].concat(
      flatten(
        routes.pathFromRoot.map((route, index, all) =>
          getItems(route.componentTags)(new ActivatedRoutes(all.slice(0, index + 1)))
        )
      )
    );

const emptyStrategy = () => [];

/**
 * Extend parent strategy.
 * Uses parent component strategy to get all items and adds additional items in the end
 */
const extendParentStrategy =
  (itemsFn: BreadcrumbItemsFn) => (routes: ActivatedRoutes, tagsResolver: BreadcrumbComponentTagsResolver) => {
    const parentStrategy = tagsResolver.getStrategy(routes.getParent().componentTags);
    return parentStrategy(routes, tagsResolver).concat(itemsFn(routes));
  };

export const breadcrumbStrategies = {
  default: (homePageItem: BreadcrumbItem) => defaultStrategy(homePageItem) as BreadcrumbStrategy,
  empty: () => emptyStrategy as BreadcrumbStrategy,
  extendParent: (itemsFn: BreadcrumbItemsFn) => extendParentStrategy(itemsFn) as BreadcrumbStrategy
};
