import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyBarSeriesModule } from '../common/bar-series/bar-series.module';
import { FireflyChartBoilerplateModule } from '../common/chart-boilerplate/chart-boilerplate.module';
import { FireflyLineModule } from '../common/lines/line/line.module';
import { FireflyAreaChartComponent } from './area-chart.component';

@NgModule({
  imports: [CommonModule, FireflyLineModule, FireflyBarSeriesModule, FireflyChartBoilerplateModule],
  declarations: [FireflyAreaChartComponent],
  exports: [FireflyAreaChartComponent]
})
export class FireflyAreaChartModule {}
