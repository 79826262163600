import isEmpty from 'lodash/isEmpty';
import { SecuritySettings } from '../models/security-settings';
import { SelectedSecuritySettings } from './models';

export function resolveApplicationSelectedSecurityId(
  selectedSecuritySettings: SelectedSecuritySettings,
  securitySettings: SecuritySettings,
  availableSecurityIds: number[]
): number | null {
  const selectedSecurityPreferenceValue = selectedSecuritySettings.id;
  const defaultSecurityPreferenceValue = securitySettings.primary;

  if (selectedSecurityPreferenceValue && availableSecurityIds.includes(selectedSecurityPreferenceValue)) {
    return selectedSecurityPreferenceValue;
  }

  if (defaultSecurityPreferenceValue && availableSecurityIds.includes(defaultSecurityPreferenceValue)) {
    return defaultSecurityPreferenceValue;
  }

  const sortedAvailableSecurityIds = availableSecurityIds.slice().sort((a, b) => a - b);

  return !isEmpty(sortedAvailableSecurityIds) ? sortedAvailableSecurityIds[0] : null;
}
