import { Injectable } from '@angular/core';
import { DrawerConfig, DrawerPosition, DrawerType, FireflyDrawerService } from '@capital-access/firefly/components';
import { EmptyDrawerContentComponent } from './empty-drawer-content';

@Injectable({ providedIn: 'root' })
export class SearchDrawerService {
  constructor(private drawerService: FireflyDrawerService) {}

  public showDrawer(title: string) {
    this.drawerService.openDrawer(
      {
        title,
        type: DrawerType.Form,
        position: DrawerPosition.Right,
        rootCssClass: 'ca-one-search-drawer'
      } as DrawerConfig,
      EmptyDrawerContentComponent
    );
  }
}
