export const institutionsQuery = `
  institutions(filter: $filter, skip: $skip, take: $take) {
    crmId
    prospectingId
    name
  }`;

export const contactsQuery = `
  contacts(filter: $filter, skip: $skip, take: $take) {
    crmId
    prospectingId
    firstName
    lastName
    companyName
    email
    crmCompanyId
    prospectingCompanyId
  }`;

export const contactsByEmailQuery = `
  contacts(filter: $filter, skip: $skip, take: $take, searchByEmail: $searchByEmail, exactEmailMatch: $exactEmailMatch) {
    crmId
    prospectingId
    firstName
    lastName
    companyName
    email
    crmCompanyId
    prospectingCompanyId
}`;
