import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideAuthGuard } from '@capital-access/common/router';
import { FeatureToggleEffects } from './+state/feature-toggle.effects';
import { FEATURE_TOGGLE_FEATURE } from './+state/feature-toggle.models';
import { reducer } from './+state/feature-toggle.reducers';
import { FeatureToggleDirective } from './feature-toggle.directive';
import { FeatureToggleGuard } from './feature-toggle.guard';

@NgModule({
  imports: [StoreModule.forFeature(FEATURE_TOGGLE_FEATURE, reducer), EffectsModule.forFeature([FeatureToggleEffects])]
})
export class CommonFeatureToggleRootModule {}

@NgModule({
  imports: [CommonModule],
  declarations: [FeatureToggleDirective],
  exports: [FeatureToggleDirective]
})
export class CommonFeatureToggleModule {
  static forRoot(): ModuleWithProviders<CommonFeatureToggleRootModule> {
    return {
      ngModule: CommonFeatureToggleRootModule,
      providers: [provideAuthGuard(FeatureToggleGuard)]
    };
  }
}
