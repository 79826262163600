import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../popovers';
import { FireflyLegendComponent } from './legend.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule],
  declarations: [FireflyLegendComponent],
  exports: [FireflyLegendComponent]
})
export class FireflyLegendModule {}
