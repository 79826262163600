import { Provider } from '@angular/core';
import { AppSettingsService } from '@capital-access/common/settings';
import { LOGGER, LogLevel } from './logger.model';
import { ConsoleLogger } from './loggers/console-logger';
import { HttpLogger } from './loggers/http-logger';

export function provideLogLevel(service: AppSettingsService) {
  const settings = service.getSettings<{ logLevel: LogLevel }>();

  return settings.logLevel;
}

export function provideLoggers({ sendLogs }: { sendLogs: boolean }): Provider[] {
  const loggingProviders: Provider[] = [
    {
      provide: LOGGER,
      useClass: ConsoleLogger,
      multi: true
    }
  ];

  if (sendLogs) {
    loggingProviders.push({
      provide: LOGGER,
      useClass: HttpLogger,
      multi: true
    });
  }

  return loggingProviders;
}
