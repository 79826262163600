<div class="d-flex flex-column h-100">
  <f-top-nav *ngIf="showTopNav$ | async">
    <ng-container fLogoContainer>
      <ca-logo></ca-logo>
    </ng-container>

    <ng-container fUserMenuContainer>
      <f-dropdown-with-initials
        *ngIf="userLoaded$ | async"
        [user]="userFullName$ | async"
        [items]="userSettingsOptions$ | async"
        (itemClick)="onClick($event)"
      ></f-dropdown-with-initials>
    </ng-container>
  </f-top-nav>

  <div class="h-100 overflow-hidden" scroll-root>
    <ng-container fDrawerDefaultContainer> </ng-container>
    <!-- scroll-root attribute should be present on the root scrollable element -->
    <div class="h-100 overflow-auto" scroll-root>
      <ng-content></ng-content>
    </div>
  </div>
</div>
