import { Pipe, PipeTransform } from '@angular/core';
import camelCase from 'lodash/camelCase';

@Pipe({
  name: 'caCamelCase'
})
export class CamelCasePipe implements PipeTransform {
  transform(value: unknown) {
    return value == null ? null : camelCase(`${value}`);
  }
}
