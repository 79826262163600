export enum ProfileAction {
  AddToTargetQueue = 'addToTargetQueue',
  AddToList = 'addToList',
  AddToActivity = 'addToActivity',
  AddToEvent = 'addToEvent',
  AddContact = 'addContact',
  AddInstitution = 'addInstitution',
  Edit = 'edit',
  Merge = 'merge',
  Delete = 'delete',
  UnmarkAsPrimary = 'unmarkAsPrimary',
  MarkAsPrimary = 'markAsPrimary',
  IncludeToMailing = 'includeToMailing',
  ExcludeFromMailing = 'excludeFromMailing'
}
