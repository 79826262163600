import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import ResizeObserver from 'resize-observer-polyfill';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[fDropdownWidth]'
})
export class FireflyDropdownWidthDirective implements OnInit, OnDestroy {
  private resizeObserver = new ResizeObserver(() => this.setWidth());
  private dropDownOpenChangeSub = Subscription.EMPTY;

  @Input() fDropdownWidth!: HTMLElement;
  @Input() dropdown!: NgbDropdown;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private zone: NgZone) {}

  private get dropdownAnchor() {
    return this.fDropdownWidth;
  }

  private get anchorWidthDidChange() {
    return this.dropdown.isOpen() && this.elementRef.nativeElement?.offsetWidth !== this.dropdownAnchor?.offsetWidth;
  }

  ngOnInit() {
    this.zone.runOutsideAngular(() => this.resizeObserver.observe(this.dropdownAnchor));
    this.dropDownOpenChangeSub = this.dropdown.openChange.pipe(filter(Boolean)).subscribe(() => this.setWidth());
  }

  setWidth() {
    if (this.anchorWidthDidChange) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.dropdownAnchor.offsetWidth}px`);
    }
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
    this.dropDownOpenChangeSub.unsubscribe();
  }
}
