<div class="btn-group" data-automation-id="f-dropdown-btn-group">
  <button
    data-automation-id="split-button"
    class="btn"
    [disabled]="disabled || loading"
    [ngClass]="[btnStyle, iconClass]"
    [attr.title]="dropdownButtonTitle"
    (click)="buttonClick.emit()"
  >
    <i *ngIf="icon" class="f-i" [ngClass]="icon"></i>
    {{ text }}
    <f-spinner *ngIf="loading" rootCssClass="spinner-center"></f-spinner>
  </button>
  <div
    class="btn-group"
    ngbDropdown
    [container]="container"
    display="dynamic"
    [placement]="placement"
    data-automation-id="f-dropdown-btn-container"
  >
    <button
      class="btn"
      [disabled]="disabled || loading"
      [ngClass]="[btnStyle, iconClass]"
      ngbDropdownToggle
      fDropdownListFocus
      [focusableItemsContainer]="dropdownMenu"
      [attr.title]="dropdownToggleTitle"
    >
      <i class="f-i f-i-xs f-i-caret-down form-caret"></i>
    </button>
    <div #dropdownMenu ngbDropdownMenu data-automation-id="f-dropdown-menu">
      <ng-content></ng-content>
    </div>
  </div>
</div>
