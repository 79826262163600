import { APP_BASE_HREF } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewChild
} from '@angular/core';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { errorPageDescription, ErrorPageScope, errorPageTitle, ErrorPageType } from './error-page.models';

/**
 * `CommonErrorPageModule` has to be imported in order to use `ca-error-page` component.
 *
 * Component supports 4 predefined types which are: `notFound`, `forbidden`, `unknown` and `custom`
 *
 * Also component supports content projection - meaning default action can be replaced with custom markup
 */
@Component({
  selector: 'ca-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPageComponent implements AfterViewInit {
  private _type: ErrorPageType = 'notFound';
  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  defaultDescription!: string | null;
  defaultTitle!: string | null;
  showActions = false;

  /**
   * Optional error title, if not passed then the default one is used depending on the **type**
   */
  @Input() title?: string;
  /**
   * Optional error description, if not passed then the default one is used depending on the **type**
   */
  @Input() description?: string;
  /**
   * Optional title for action button, if not passed then the default one is used depending on the **type**
   */
  @Input() actionTitle?: string;
  /**
   * One of the error-page supported types (**ErrorPageType**), if not passed then the **notFound** is used
   */
  @Input() get type() {
    return this._type;
  }
  set type(value: ErrorPageType) {
    this._type = value;
    this.updateDefaults();
  }

  @Input() scope: ErrorPageScope = 'external';
  get isExternal() {
    return this.scope == 'external';
  }

  @ViewChild('ref') projectedContent?: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    this.updateDefaults();
  }

  ngAfterViewInit() {
    const hasProjectedContent =
      this.projectedContent &&
      (this.projectedContent.nativeElement.children.length !== 0 || this.projectedContent.nativeElement.textContent);
    this.showActions = !hasProjectedContent;
    this.changeDetectorRef.detectChanges();
  }

  navigateHome() {
    // Force navigation due to issue https://teamipreo.atlassian.net/browse/CPD-1746
    location.assign(location.origin + this.baseHref ?? '/');
  }

  private updateDefaults() {
    this.defaultDescription = errorPageDescription[this.type] ?? null;
    this.defaultTitle = errorPageTitle[this.type] ?? null;
  }
}
