<ng-container caLocalizationScope="search-nav">
  <span class="heading-20 align-middle ps-3">{{ 'filter' | caLocalize }}</span>

  <f-list>
    <f-list-item
      [withHover]="false"
      class="d-flex align-content-center filter-item"
      *ngFor="let filter of filters"
      [class.disabled]="!filter.hasItems"
      (click)="onFilterSelect(filter)"
      [class.ca-filter-text]="selectedFilter === filter.name"
      [attr.data-automation-id]="'filter-by-' + filter.name"
    >
      <span class="pe-2 user-select-none"> {{ filter.localizationKey | caLocalize }}</span>
      <i class="f-i f-i-check f-i-xs ca-filter-text" *ngIf="selectedFilter === filter.name"></i>
    </f-list-item>
  </f-list>
</ng-container>
