import { Action, createReducer, on } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { loadCurrencyRate, loadCurrencyRateFail, loadCurrencyRateSuccess } from './actions';

export interface State {
  rate: number | null;
  loadStatus: LoadStatus;
  error: unknown;
}

export const initialState: State = {
  rate: null,
  loadStatus: LoadStatus.Pristine,
  error: null
};

const reducer = createReducer(
  initialState,
  on(loadCurrencyRate, state => ({
    ...state,
    loadStatus: LoadStatus.Loading
  })),
  on(loadCurrencyRateSuccess, (state, action) => ({
    ...state,
    rate: action.rate,
    loadStatus: LoadStatus.Loaded
  })),
  on(loadCurrencyRateFail, (_, action) => ({
    ...initialState,
    loadStatus: LoadStatus.Failed,
    error: action.error
  }))
);

export function currencyReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
