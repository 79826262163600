export enum ProfileSide {
  None = 'None',
  Sell = 'Sell',
  Buy = 'Buy',
  Both = 'Both'
}

export enum ProfileActivist {
  Often = 'Often',
  Occasional = 'Occasional'
}
