import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils/localization/firefly-localization.service';

@Component({
  selector: 'f-inner-error',
  templateUrl: './inner-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyInnerErrorComponent {
  @Input() caption!: string;
  @Input() info!: string;
  @Input() isHorizontal: boolean = false;
  @Input() isFlexiblePlacement: boolean = false;
  @Input() rootCssClass: string = '';

  constructor(@Optional() private localizationService: FireflyLocalizationService) {}

  innerErrorTitle$ = this.localizationService
    ? this.localizationService?.localize('innerErrorTitle', {})
    : of('Something went wrong');

  get alignmentClass() {
    return this.isHorizontal ? 'horizontal' : '';
  }

  get flexiblePlacementClass() {
    return this.isFlexiblePlacement ? 'flexible' : '';
  }
}
