<div class="footer-with-buttons" [caLocalizationScope]="localizationScope">
  <button
    [title]="disableSaving ? ('createReportDisabled' | caLocalize) : ''"
    class="btn btn-primary"
    data-automation-id="submit-button"
    title="{{ createReportTitle | caLocalize }}"
    [disabled]="disableSaving"
    (click)="onCreateReport()"
  >
    {{ createReportTitle | caLocalize }}
  </button>
  <button
    class="btn btn-secondary"
    data-automation-id="cancel-button"
    title="{{ 'cancel' | caLocalize }}"
    (click)="cancel()"
  >
    {{ 'cancel' | caLocalize }}
  </button>
</div>
