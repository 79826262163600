import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyXAxisComponent } from './x-axis.component';
import { FireflyXAxisTicksComponent } from './x-axis-ticks.component';
import { FireflyYAxisComponent } from './y-axis.component';
import { FireflyYAxisTicksComponent } from './y-axis-ticks.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyXAxisComponent, FireflyXAxisTicksComponent, FireflyYAxisComponent, FireflyYAxisTicksComponent],
  exports: [FireflyXAxisComponent, FireflyYAxisComponent]
})
export class FireflyAxisModule {}
