<span
  *ngIf="isValidValue; else nonValidBadge"
  class="badge badge-right-icon ca-change-badge"
  [ngClass]="badgeClass"
  [title]="showTooltip ? formattedValue : ''"
>
  <span [ngClass]="{ 'text-truncate': valueChange !== 0 }">{{ formattedValue }}</span>
  <i [ngClass]="iconClass" class="f-i"></i>
</span>

<ng-template #nonValidBadge>
  <span
    class="badge ca-change-badge"
    [ngClass]="badgeClass"
    [title]="showTooltip ? (notAvailableCaption$ | async) : ''"
  >
    <span class="text-truncate">{{ notAvailableCaption$ | async }}</span>
  </span>
</ng-template>
