import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadUserProfileSuccess } from '@capital-access/common/user';
import { FeatureToggleRepository } from '../feature-toggle.repository';
import { loadFeatureToggleFail, loadFeatureToggleSuccess } from './feature-toggle.actions';

@Injectable()
export class FeatureToggleEffects {
  loadToggleProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfileSuccess),
      switchMap(() =>
        this.repository.getCurrentUserToggles().pipe(
          map(toggleProfile => loadFeatureToggleSuccess({ toggleProfile })),
          catchError(error => of(loadFeatureToggleFail({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private repository: FeatureToggleRepository) {}
}
