import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { EffectsFeatureModule, EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TitleEffects } from './title.effects';
import {
  BROWSER_TITLE_FALLBACK_VALUE,
  BROWSER_TITLE_FEATURE,
  BROWSER_TITLE_SEPARATOR,
  TitlePartString
} from './title.models';
import { reducer } from './title.reducers';
import { TitleService } from './title.service';

@NgModule({
  imports: [StoreModule.forFeature(BROWSER_TITLE_FEATURE, reducer), EffectsModule.forFeature([TitleEffects])]
})
export class CommonBrowserTitleRootModule {}

@NgModule({})
export class CommonBrowserTitleModule {
  static forRoot(config: {
    separator?: string;
    fallbackValue?: TitlePartString;
  }): ModuleWithProviders<CommonBrowserTitleRootModule> {
    return {
      ngModule: CommonBrowserTitleRootModule,
      providers: [
        TitleService,
        { provide: BROWSER_TITLE_SEPARATOR, useValue: config.separator ?? ' | ' },
        {
          provide: BROWSER_TITLE_FALLBACK_VALUE,
          useValue: config.fallbackValue ?? 'Capital Access'
        }
      ]
    };
  }

  static forFeature(type: Type<unknown>): ModuleWithProviders<EffectsFeatureModule> {
    return EffectsModule.forFeature([type]);
  }
}
