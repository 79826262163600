import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../popovers/popover.module';
import { CollapsedViewFirstLineDirective } from './directives/collapsed-view-first-line.directive';
import { CollapsedViewSecondLineDirective } from './directives/collapsed-view-second-line.directive';
import { ExpandedViewDirective } from './directives/expanded-view.directive';
import { MobileExpandedRowComponent } from './mobile-expanded-row/mobile-expanded-row.component';
import { MobileTableColumnComponent } from './mobile-table-column/mobile-table-column.component';
import { MobileTableHeaderComponent } from './mobile-table-header/mobile-table-header.component';
import { MobileTableRowComponent } from './mobile-table-row/mobile-table-row.component';

@NgModule({
  declarations: [
    CollapsedViewFirstLineDirective,
    CollapsedViewSecondLineDirective,
    ExpandedViewDirective,
    MobileTableRowComponent,
    MobileTableHeaderComponent,
    MobileTableColumnComponent,
    MobileExpandedRowComponent
  ],
  imports: [CommonModule, FireflyPopoverModule],
  exports: [
    CollapsedViewFirstLineDirective,
    CollapsedViewSecondLineDirective,
    ExpandedViewDirective,
    MobileTableRowComponent,
    MobileTableHeaderComponent,
    MobileTableColumnComponent,
    MobileExpandedRowComponent
  ]
})
export class FireflyMobileColumnModule {}
