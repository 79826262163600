import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { displaySessionExpirePage } from '@capital-access/common/actions';
import { HttpStatusCode } from '@capital-access/common/http';
import { initAuthorizeWithPopUp } from '../+store/auth-popup.actions';
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService, private store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle case when token expired or user logout via another tab or silent renew failed
        if (error.status === HttpStatusCode.UNAUTHORIZED) {
          return this.oidcSecurityService.getAccessToken().pipe(
            tap(token => {
              if (!token) {
                this.store.dispatch(displaySessionExpirePage());
                this.store.dispatch(initAuthorizeWithPopUp());
              }
            }),
            switchMap(() => throwError(() => error))
          );
        }

        return throwError(() => error);
      })
    );
  }
}
