import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getUserSettingsCurrencySymbol } from '@capital-access/common/globalization';
import { BaseAsyncPipe } from '@capital-access/common/utils';

@Pipe({
  name: 'caCurrencySymbol',
  pure: false
})
export class CurrencySymbolPipe
  extends BaseAsyncPipe<string, string | number | null | undefined>
  implements PipeTransform
{
  constructor(changeDetectorRef: ChangeDetectorRef, private store: Store) {
    super(changeDetectorRef);
  }

  getTransformer(value: string | number | null | undefined): Observable<string> {
    if (value === null || value === undefined) {
      return of('');
    }

    return this.store
      .select(getUserSettingsCurrencySymbol)
      .pipe(map(symbol => (symbol ? `${value} ${symbol}` : `${value}`)));
  }
}
