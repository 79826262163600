import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FireflyModalService, ModalRef } from '../../modal';
import { Breakpoint, isIphone } from '../../utils';
import { FireflyMobilePopoverComponent } from '../components/mobile-popover.component';

@Directive({
  selector: '[fMobilePopover]'
})
export class FireflyMobilePopoverDirective implements OnInit, OnDestroy {
  @Input() mobilePopoverTitle = '';
  @Input() closeOnClickInside = false;

  private modalDialogClass = 'mobile-popover';
  private modal!: ModalRef<unknown> | null;
  private destroyed$ = new Subject<void>();
  private isIphone = isIphone();

  constructor(protected host: ElementRef, private popover: NgbPopover, protected modalService: FireflyModalService) {}

  @HostListener('click')
  onClick() {
    if (!this.isIphone) return;
    requestAnimationFrame(() => this.openMobilePopover());
  }

  ngOnInit() {
    this.popover.popoverClass = `${this.popover.popoverClass ?? ''} ${this.modalDialogClass}`.trim();

    this.popover.shown.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (window.innerWidth > Breakpoint.Sm || this.isIphone) return;
      this.openMobilePopover();
    });
  }

  openMobilePopover() {
    if (this.modal) return;

    this.modal = this.modalService.open({
      context: { content: this.popover.ngbPopover, closeOnClickInside: this.closeOnClickInside },
      component: FireflyMobilePopoverComponent,
      modalDialogClass: this.modalDialogClass,
      title: this.mobilePopoverTitle,
      mobile: true
    });

    this.modal.result.finally(() => {
      this.modal = null;
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
