import { Injectable, OnDestroy } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { HashMap, TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable } from 'rxjs';
import { fallbackLang } from '@capital-access/common/globalization';
import { LocalizedString } from '../models/localized-string.model';

@Injectable({ providedIn: 'root' })
export class LocalizationService implements OnDestroy {
  private readonly availableLangs: string[] = [];

  constructor(private transloco: TranslocoService) {
    this.availableLangs = transloco.getAvailableLangs() as string[];
  }

  /**
   * Gets the translated value of a key as observable
   *
   * @example
   *
   * localize('hello').subscribe(value => ...)
   * localize('hello', {}, 'es').subscribe(value => ...)
   * localize('hello', {}, 'todos').subscribe(value => ...)
   * localize('hello', {}, { scope: 'todos' }).subscribe(value => ...)
   */
  localize<T extends string | string[] | LocalizedString>(
    key: T,
    params?: { [key: string]: unknown },
    lang?: string | { scope: string } | undefined
  ): Observable<T extends LocalizedString ? string : T>;
  localize(
    key: string | string[] | LocalizedString,
    params?: { [key: string]: unknown },
    lang?: string | { scope: string } | undefined
  ) {
    if (key instanceof LocalizedString) {
      return this.transloco.selectTranslate(key.localizationKey, key.params, key.scope);
    }
    return this.transloco.selectTranslate(key, params, lang);
  }

  /**
   * Gets an object of translations for a given language
   *
   * @example
   *
   * getLocalization().subscribe() - will return the current lang translation
   * getLocalization('es').subscribe()
   * getLocalization('admin-page').subscribe() - will return the current lang scope translation
   * getLocalization('admin-page/es').subscribe()
   */
  getLocalization(lang?: string): Observable<HashMap> {
    return this.transloco.selectTranslation(lang);
  }

  setActiveLang(lang: string) {
    if (!lang) {
      lang = fallbackLang;
    }
    if (!this.availableLangs.includes(lang)) {
      this.availableLangs.push(lang);
    }

    if (lang !== this.transloco.getActiveLang()) {
      this.transloco.setActiveLang(lang);
    }
  }

  load(scopes: string[], lang = this.transloco.getActiveLang()) {
    return combineLatest(scopes.map(s => this.transloco.load(s === '' ? lang : `${s}/${lang}`)));
  }

  ngOnDestroy() {
    this.transloco.ngOnDestroy();
  }
}
