/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import { Logger, LogObject } from '../logger.model';

@Injectable()
export class ConsoleLogger implements Logger {
  error(error: LogObject) {
    console.error(error);
  }

  warn(warning: LogObject) {
    console.warn(warning);
  }

  info(message: LogObject) {
    console.log(message);
  }

  debug(message: LogObject) {
    console.debug(message);
  }

  trace(message: LogObject) {
    console.trace(message);
  }
}
