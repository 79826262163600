import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { JobService, USER_NOTIFICATIONS, UserNotificationsRepository } from '@capital-access/notifications/common';
import { NotificationsApi } from './api/notifications.api';
import { ApiJobService } from './services/api-job.service';
import { provideUserNotifications } from './services/user-notifications.observable';
import { UserNotificationsApiRepository } from './services/user-notifications.repository';

@NgModule({
  imports: [CommonModule]
})
export class NotificationsClientModule {
  public static forRoot(): ModuleWithProviders<NotificationsClientModule> {
    return {
      ngModule: NotificationsClientModule,
      providers: [
        NotificationsApi,
        {
          provide: JobService,
          useClass: ApiJobService
        },
        {
          provide: UserNotificationsRepository,
          useClass: UserNotificationsApiRepository
        },
        {
          provide: USER_NOTIFICATIONS,
          useFactory: provideUserNotifications
        }
      ]
    };
  }
}
