<ca-search-category-list
  caLocalizationScope="search-nav"
  data-automation-id="ca-contacts-results-container"
  class="ca-one-search-results-list"
  *ngIf="showItems$ | async"
  [header]="'inContacts' | caLocalize"
  [items]="items$ | async"
  [showMoreHeader]="'showMore' | caLocalize"
  [hasMore]="hasMore$ | async"
  [rowTemplate]="rowContactTemplate"
  [category]="categories.Contacts"
  [currentCriteria]="$any(currentCriteria$ | async)"
  [isSearchFailed]="isItemsSearchFailed$ | async"
  [trackByFn]="trackByMethod"
  (updateSelection)="updateSelection($event)"
  (showMore)="showMore($event)"
>
</ca-search-category-list>
<ng-template #rowContactTemplate let-contact="item" let-criteria="criteria">
  <span
    data-automation-id="ca-search-contact-link"
    class="link link-main ca-search-link col-sm-3"
    [innerHTML]="contact.firstName + ' ' + contact.lastName | fHighlight : criteria : 'fw-bold text-reset'"
    [title]="contact.firstName + ' ' + contact.lastName"
    (click)="openContactProfilePreview(contact)"
  ></span>

  <span
    data-automation-id="ca-search-contact-institution-link"
    class="link link-secondary ca-search-link col-sm-6"
    [innerHTML]="contact.companyName | fHighlight : criteria : 'fw-bold'"
    [title]="contact.companyName || ''"
    (click)="openInstitutionProfilePreview(contact)"
  ></span>

  <ng-template #contactTooltip>
    <span
      data-automation-id="onesearch-tooltip"
      [innerHTML]="contact.firstName + ' ' + contact.lastName | fHighlight : criteria : 'body-50 text-base fw-bold'"
    ></span>
  </ng-template>

  <ng-template #companyTooltip>
    <span
      data-automation-id="onesearch-tooltip"
      [innerHTML]="contact.companyName | fHighlight : criteria : 'body-50 text-base fw-bold'"
    ></span>
  </ng-template>
</ng-template>
