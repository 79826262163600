import { ChangeDetectorRef, Host, Inject, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {
  HashMap,
  MaybeArray,
  TRANSLOCO_LANG,
  TRANSLOCO_SCOPE,
  TranslocoPipe,
  TranslocoScope,
  TranslocoService
} from '@ngneat/transloco';
import { LocalizationScopeDirective } from '../directive/localization-scope.directive';
import { LocalizedString } from '../models/localized-string.model';

/**
 * Can take the localization scope from LocalizationScopeDirective hosted on the parent element within one view.
 * Will ignore localization scope if key already contains scope.
 */
@Pipe({
  name: 'caLocalize',
  pure: false
})
export class LocalizePipe implements PipeTransform, OnDestroy {
  private translocoPipe: TranslocoPipe;

  constructor(
    private translocoService: TranslocoService,
    @Host() @Optional() private scope: LocalizationScopeDirective,
    @Optional() @Inject(TRANSLOCO_SCOPE) private providerScope: MaybeArray<TranslocoScope>,
    @Optional() @Inject(TRANSLOCO_LANG) private providerLang: string,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.translocoPipe = new TranslocoPipe(
      this.translocoService,
      this.providerScope,
      this.providerLang,
      this.changeDetectorRef
    );
  }

  transform(key: LocalizedString | string | null, params?: HashMap | undefined): string {
    if (key instanceof LocalizedString) {
      return this.translocoPipe.transform(key.fullKey, key.params);
    }
    return this.translocoPipe.transform(this.resolveKey(key), params);
  }

  ngOnDestroy() {
    this.translocoPipe.ngOnDestroy();
  }

  private resolveKey(key: string | null) {
    return !this.withScope(key) && this.scope && this.scope.caLocalizationScope
      ? `${this.scope.caLocalizationScope}.${key}`
      : key;
  }

  private withScope(key: string | null): boolean {
    if (!key) {
      return false;
    }

    // Scope and localization key are separated by '.', i.e., 'user-settings.accountInfo'
    const { length } = key.split('.');

    // if length is more that 1, it means scope is present in the key.
    return length > 1;
  }
}
