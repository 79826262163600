import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { HttpStatusCode } from '@capital-access/common/http';
import { SystemSettingsService } from '@capital-access/common/settings';
import { ContactSupportAdvisor, UserRepository } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';

@Component({
  selector: 'ca-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportComponent implements OnInit {
  settings = this.systemSettings.getSettings().contactSupport;
  loadCsAdvisorStatus$ = new BehaviorSubject(LoadStatus.Pristine);
  csAdvisorData$!: Observable<ContactSupportAdvisor | null>;
  loadStatus = LoadStatus;

  constructor(
    private store: Store,
    private userRepository: UserRepository,
    private systemSettings: SystemSettingsService
  ) {}

  ngOnInit() {
    this.csAdvisorData$ = this.store.select(getFeatureToggle('cs-info-CPD-1797')).pipe(
      switchMap(allowed => {
        if (!allowed) return of(null);
        requestAnimationFrame(() => this.loadCsAdvisorStatus$.next(LoadStatus.Loading));
        return this.userRepository.getUserContactSupportAdvisor().pipe(
          tap(() => this.loadCsAdvisorStatus$.next(LoadStatus.Loaded)),
          catchError(error => {
            //no advisor data found for this user
            if (error && error instanceof HttpErrorResponse && error.status === HttpStatusCode.NOT_FOUND) {
              this.loadCsAdvisorStatus$.next(LoadStatus.Loaded);
            } else {
              this.loadCsAdvisorStatus$.next(LoadStatus.Failed);
            }
            return of(null);
          })
        );
      })
    );
  }

  reload() {
    location.reload();
  }
}
