import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { openContactSupportDrawer } from '@capital-access/common/actions';
import { CRM_COMMON_LOCALIZATION_SCOPE } from '../../model/constants';

@Component({
  selector: 'ca-crm-drawer-error',
  templateUrl: './crm-drawer-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrmDrawerErrorComponent {
  localizationScope = CRM_COMMON_LOCALIZATION_SCOPE;

  @Input() rootCssClass = '';

  constructor(private store: Store) {}

  onContactSupportClick() {
    this.store.dispatch(openContactSupportDrawer());
  }
}
