import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CurrencyMillionsSymbolDirective } from './directives/currency-millions-symbol.directive';
import { CurrencySymbolDirective } from './directives/currency-symbol.directive';
import { MillionsSymbolDirective } from './directives/millions-symbol.directive';
import { TextOverflowDirective } from './directives/text-overflow.directive';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { CurrencyPipe } from './pipes/currency.pipe';
import { CurrencyMillionsSymbolPipe } from './pipes/currency-millions-symbol.pipe';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { DatePipe } from './pipes/date.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ExcelNumberFormatPipe } from './pipes/excel-number-format.pipe';
import { MillionsPipe } from './pipes/millions.pipe';
import { MillionsSymbolPipe } from './pipes/millions-symbol.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ServerSideCurrencyPipe } from './pipes/server-side-currency.pipe';
import { TimePipe } from './pipes/time.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ToMillionsPipe } from './pipes/to-millions.pipe';
import { UrlHostnamePipe } from './pipes/url-hostname.pipe';
import { CurrencyFormattingService } from './services/currency-formatting.service';
import { DateTimeFormattingService } from './services/date-time-formatting.service';

const pipes = [
  AbsoluteValuePipe,
  CurrencyPipe,
  CurrencySymbolPipe,
  ServerSideCurrencyPipe,
  CurrencyMillionsSymbolPipe,
  CamelCasePipe,
  MillionsPipe,
  MillionsSymbolPipe,
  ToMillionsPipe,
  NumberPipe,
  DatePipe,
  SafeHtmlPipe,
  TimePipe,
  UrlHostnamePipe,
  ExcelNumberFormatPipe,
  DateFormatPipe,
  TimeFormatPipe
];

const directives = [
  MillionsSymbolDirective,
  CurrencySymbolDirective,
  TextOverflowDirective,
  CurrencyMillionsSymbolDirective
];

@NgModule({
  imports: [CommonModule],
  declarations: [...pipes, ...directives],
  exports: [...pipes, ...directives],
  providers: [...pipes, DateTimeFormattingService, CurrencyFormattingService]
})
export class CommonFormattingModule {}
