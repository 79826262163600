import { Directive, Host, Input, OnDestroy, OnInit } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { SchedulerComponent } from '@progress/kendo-angular-scheduler';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, throttleTime } from 'rxjs/operators';
import { Breakpoint } from '../../utils';

@Directive({
  selector: '[fSchedulerDateFormatting]'
})
export class FireflySchedulerDateFormattingDirective implements OnInit, OnDestroy {
  @Input() monthViewIndex = 3;

  private firstDayIndex = this.intlService.firstDay();
  private destroyed$ = new Subject<void>();
  private shortDayNames!: string[];
  private longDayNames!: string[];

  constructor(@Host() private scheduler: SchedulerComponent, private intlService: IntlService) {}

  ngOnInit() {
    this.shortDayNames = this.getAdjustedDayNames('short');
    this.longDayNames = this.getAdjustedDayNames('wide');

    if (this.scheduler.selectedViewIndex === this.monthViewIndex) {
      this.transformWeekDays();
    }

    this.scheduler.selectedViewIndexChange
      .pipe(
        takeUntil(this.destroyed$),
        filter(index => index === this.monthViewIndex)
      )
      .subscribe(() => this.transformWeekDays());

    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroyed$),
        throttleTime(100),
        filter(() => this.scheduler.selectedViewIndex === this.monthViewIndex),
        map(() => window.innerWidth < Breakpoint.Sm),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.transformWeekDays();
      });
  }

  private transformWeekDays() {
    window.requestAnimationFrame(() => {
      const selector = '.k-scheduler-header table tr th';
      const dayElements = this.scheduler.wrapper.nativeElement.querySelectorAll(selector);
      const dayNames = window.innerWidth < Breakpoint.Sm ? this.shortDayNames : this.longDayNames;

      [...dayElements].forEach((el, index) => {
        el.textContent = dayNames[index];
      });
    });
  }

  private getAdjustedDayNames(format: 'short' | 'wide') {
    const weekDays = this.intlService.dateFormatNames({ type: 'days', nameType: format });
    const adjustedWeekdays = [];
    let index = this.firstDayIndex;

    for (let i = 0; i < weekDays.length; i++) {
      adjustedWeekdays.push(weekDays[index]);
      index = (index + 1) % 7;
    }

    return adjustedWeekdays;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
