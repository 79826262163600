import { GroupedData } from '../../models/grouped-data.model';
import { GroupedItem } from '../../models/grouped-item.model';
import { ValueFunction } from '../../models/value-function.type';

export function groupBy(items: unknown[], categoryPath: string, valueFunction: ValueFunction<string>): GroupedData {
  let groupedItems: GroupedItem[];
  const groupedObj: Record<string, unknown[]> = {};
  if (categoryPath) {
    for (const item of items) {
      const categoryName = valueFunction(item, categoryPath);
      (groupedObj[categoryName] = groupedObj[categoryName] || []).push(item);
    }
    groupedItems = Object.keys(groupedObj).map(name => {
      return { name, value: groupedObj[name] };
    });
  } else {
    groupedItems = [{ value: items }];
  }
  return { groupedItems, totalLength: items.length };
}

export function getFilteredItems(
  items: GroupedItem[],
  searchString: string,
  valuePath: string,
  valueFunction: ValueFunction<string>
): GroupedData {
  let totalLength: number = 0;
  const groupedItems: GroupedItem[] = [];
  for (const group of items) {
    const value = group.value.filter(item => valueFunction(item, valuePath).toLowerCase().includes(searchString));
    if (value.length > 0) {
      totalLength += value.length;
      groupedItems.push({ ...group, value });
    }
  }
  return { groupedItems, totalLength };
}

export function isDisabledItem(item: unknown, disabledPath: string, valueFunction: ValueFunction<boolean>): boolean {
  return disabledPath ? valueFunction(item, disabledPath, false) : false;
}
