import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  TemplateRef,
  Type,
  ViewChildren
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import { sha1 as hash } from 'object-hash';
import { take } from 'rxjs/operators';
import { FireflyModalService } from '../../../modal';
import { ModalRef } from '../../../modal/models/base-modal-options';
import { Breakpoint, FireflyLocalizationService } from '../../../utils';
import { barClasses } from '../../common/bars/constants';
import { FireflyChartBoilerplateComponent } from '../chart-boilerplate';
import { FireflyMobileChartPopoverComponent } from '../mobile-popovers/bar-chart-popover/chart-mobile-popover.component';
import type { ChartBarDto, ChartDataEntry, ChartDimensions, FireflyChart } from '../models/common-chart-models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-base-series]',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ''
})
export class FireflyBaseBarSeriesComponent implements OnInit {
  @Input() interactions: { hover: boolean; click: boolean; preventMobile?: boolean } = {
    hover: true,
    click: false,
    preventMobile: false
  };
  @Input() parentChartComponent!: Type<FireflyChart>;
  @Input() dimensions!: ChartDimensions;
  @Input() series!: unknown[];
  @Input() lineData?: ChartDataEntry[];
  @Input() xScale!: ScaleBand<string>;
  @Input() yScale!: ScaleLinear<number, number>;
  @Input() popoverTemplate!: TemplateRef<unknown> | null;
  @Input() popoverPlacement = 'top';
  @Input() popoverOpenDelay = 200;
  @Input() popoverCssClass?: string;
  @Input() animationDuration = 500;
  @Input() animationDelay = 0;
  @Input() animation = false;
  @Input() condensedOnDesktop = false;
  @Input() barBubbleShowZero = false;
  @Input() barBubbleScaleFactor = 1;
  @Input() barBubbleClasses: string[] = [];
  @Input() barBubbleTextClasses: string[] = [];
  @Input() barClasses: string[] = barClasses;
  @Input() set activeBarIndex(value: number | undefined) {
    this.selectedBarIndex = value;
    if (Number.isFinite(value)) {
      this.host.nativeElement.classList.add('has-selected-bar');
      const index = value as number;
      setTimeout(() => {
        this.seriesHover.emit({ x: Math.round(this.bars[index]?.x ?? 0), index });
        this.interactionContainer = this.barContainers.get(value as number)?.nativeElement;
      }, this.animationDuration);
    } else {
      this.host.nativeElement.classList.remove('has-selected-bar');
    }
  }

  @ViewChildren('container') barContainers!: QueryList<ElementRef>;

  @Output() seriesHover = new EventEmitter();
  @Output() seriesClick = new EventEmitter();

  strokeWidth = 1;
  maxBarWidth = 80;
  minBarHeight = 0.1;
  minBubbleRadius = 10;
  maxBubbleRadius = 14;
  animationState = 'initial';
  hash = hash(new Date()).slice(0, 10);
  popoverClass = 'f-chart-popover';
  activePopover!: NgbPopover;
  popoverTimer!: number;
  padding!: number;

  get bars() {
    return this._bars;
  }

  get bubbleRadius() {
    const bandWidth = this.xScale.bandwidth();
    const height = this.dimensions.height;
    const width = height < bandWidth ? height : bandWidth;
    const halfWidth = width / 2;
    const quarter = (halfWidth / 100) * 25;
    let radius = halfWidth - quarter;

    if (radius > this.maxBubbleRadius) {
      radius = this.maxBubbleRadius;
    } else if (radius < this.minBubbleRadius) {
      radius = this.minBubbleRadius;
    }

    return radius * this.barBubbleScaleFactor;
  }

  get bubbleOffsetScaleFactor() {
    return this.barBubbleClasses.length === 1 && this.barBubbleClasses[0] === 'bg-transparent' ? 1 : 1.5;
  }

  get bubbleOffset() {
    return this.bubbleRadius * this.bubbleOffsetScaleFactor;
  }

  get animationStyles() {
    return this.animation && this.animationState === 'running'
      ? {
          'transition-property': 'y',
          'transition-timing-function': 'ease-out',
          'transition-duration.ms': this.animationDuration,
          'transition-delay.ms': this.animationDelay
        }
      : {};
  }

  protected _bars!: ChartBarDto[];
  protected selectedBarIndex!: number | undefined;
  protected interactionContainer!: HTMLElement | undefined | null;
  protected modal!: ModalRef<unknown> | null;
  protected modalTitle = 'Chart Details';
  protected mobileModalComponent = FireflyMobileChartPopoverComponent;

  constructor(
    protected host: ElementRef,
    protected cdr: ChangeDetectorRef,
    protected modalService: FireflyModalService,
    @Optional() @SkipSelf() protected chartBoilerplate: FireflyChartBoilerplateComponent,
    @Optional() protected localizationService: FireflyLocalizationService
  ) {}

  ngOnInit() {
    this.localizationService
      ?.localize('chartDetailTitle', {})
      .pipe(take(1))
      .subscribe((title: string) => {
        this.modalTitle = title;
      });

    window.requestAnimationFrame(() => {
      this.animationState = 'running';
      this.cdr.detectChanges();
      setTimeout(() => {
        this.animationState = 'done';
        this.cdr.detectChanges();
      }, this.animationDuration + this.animationDelay);
    });

    if (this.popoverCssClass) {
      this.popoverClass = `${this.popoverClass} ${this.popoverCssClass}`;
    }
  }

  trackBy(index: number, data: { name: string }): string {
    return data.name;
  }

  popoverContextData(index: number, options?: { start: number; end: number }) {
    if (!options) return this.series[index];
    return this.series.slice(options.start, options.end)[index];
  }

  onMouseEnter(popover: NgbPopover, x: number | undefined, index: number) {
    if (this.popoverTemplate) this.openPopover(popover);
    this.seriesHover.emit({ x: Math.round(x ?? 0), index });
  }

  onMouseLeave(popover: NgbPopover, x: number) {
    if (this.popoverTemplate) this.closePopover(popover);
    this.seriesHover.emit({ x: Math.round(x), index: null });
  }

  openPopover(popover: NgbPopover) {
    if (window.innerWidth < Breakpoint.Sm) return;
    if (this.popoverTimer) clearTimeout(this.popoverTimer);
    this.activePopover = popover;
    this.popoverTimer = setTimeout(() => {
      popover.open();
      this.cdr.detectChanges();
    }, this.popoverOpenDelay) as unknown as number;
  }

  closePopover(popover: NgbPopover) {
    if (this.popoverTimer) clearTimeout(this.popoverTimer);
    popover.close();
  }

  onClick(element: HTMLElement, x: number | undefined, i: number) {
    if (this.interactionContainer !== element) this.handleMobileModal(i);

    if (!this.interactions.click) return;

    this.selectedBarIndex = undefined;
    this.interactionContainer?.classList.remove('selected');
    this.host.nativeElement.classList.remove('has-selected-bar');

    if (this.interactionContainer === element) {
      this.interactionContainer = null;
      this.seriesClick.emit({ data: null, barIndex: i });
      return;
    }

    this.selectedBarIndex = i;
    this.interactionContainer = element;
    this.interactionContainer.classList.add('selected');
    this.host.nativeElement.classList.add('has-selected-bar');
    this.seriesClick.emit({ data: this.popoverContextData(i), barIndex: i });
    this.seriesHover.emit({ x: Math.round(x ?? 0), index: i });
  }

  resetBarSelection() {
    this.interactionContainer?.classList.remove('selected');
    this.host.nativeElement.classList.remove('has-selected-bar');
    this.interactionContainer = null;
    this.seriesClick.emit(null);
  }

  setBarSelection(diff: number) {
    if (!this.interactions.click) return;

    this.interactionContainer?.classList.remove('selected');
    this.host.nativeElement.classList.remove('has-selected-bar');

    const index = (this.selectedBarIndex ?? 0) + diff;

    this.selectedBarIndex = index;
    this.interactionContainer = this.barContainers.get(index)?.nativeElement;
    this.interactionContainer?.classList.add('selected');
    this.host.nativeElement.classList.add('has-selected-bar');

    this.seriesClick.emit({ data: this.popoverContextData(index), barIndex: index });
    this.seriesHover.emit({ x: Math.round(this.bars[index]?.x ?? 0), index });
  }

  handleMobileModal(activeBarIndex: number) {
    if (!this.chartBoilerplate) return;

    if (!this.interactions.preventMobile && window.innerWidth < Breakpoint.Sm && !this.modal) {
      let sliceStartIndex = activeBarIndex - 1,
        sliceEndIndex = activeBarIndex + 2;

      if (sliceStartIndex < 1) {
        sliceStartIndex = 0;
        sliceEndIndex = 3;
      } else if (sliceEndIndex > this.series.length - 1) {
        activeBarIndex = 3 - (this.series.length - activeBarIndex);
        sliceStartIndex = this.series.length - 3;
        sliceEndIndex = this.series.length;
      } else {
        activeBarIndex = 1;
      }

      this.modal = this.modalService.open({
        component: this.mobileModalComponent,
        modalDialogClass: 'f-chart-mobile-popover',
        context: this.getModalContext(activeBarIndex, sliceStartIndex, sliceEndIndex),
        title: this.modalTitle,
        mobile: true
      });

      this.modal.result.finally(() => (this.modal = null));

      this.cdr.detectChanges();
    }
  }

  protected getModalContext(activeBarIndex: number, startIndex: number, endIndex: number) {
    return {
      activeBarIndex,
      barClasses: this.barClasses,
      template: this.popoverTemplate,
      component: this.parentChartComponent,
      chartBoilerplate: this.chartBoilerplate,
      infoBubbleShowZero: this.barBubbleShowZero,
      infoBubbleClasses: this.barBubbleClasses,
      infoBubbleTextClasses: this.barBubbleTextClasses,
      infoBubbleScaleFactor: this.barBubbleScaleFactor,
      chartData: this.series.slice(startIndex, endIndex),
      chartLineData: this.lineData?.slice(startIndex, endIndex),
      popoverData: this.popoverContextData(activeBarIndex, { start: startIndex, end: endIndex }),
      setBarSelection: (n: number) => this.setBarSelection(n),
      resetBarSelection: () => this.resetBarSelection()
    };
  }
}
