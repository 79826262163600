import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { getUserSettingsLanguage } from '@capital-access/common/globalization';
import { LocalizationService } from './localization.service';
import { LocalizationScopesLoadQueue } from './localization-scopes-load.queue';

@Injectable({
  providedIn: 'root'
})
export class LocalizationLoadGuard implements CanActivateChild {
  constructor(
    private localizationService: LocalizationService,
    private scopesLoadQueue: LocalizationScopesLoadQueue,
    private store: Store
  ) {}

  canActivateChild(routeSnapshot: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    /**
     * We are about to activate a component that might use localization.
     * So let's make sure it's loaded first :)
     */
    if (routeSnapshot.component) {
      if (!this.scopesLoadQueue.empty) {
        const scopes = this.scopesLoadQueue.getAll();
        return this.store.pipe(
          select(getUserSettingsLanguage),
          switchMap(lang => this.localizationService.load(scopes, lang)),
          take(1),
          // navigation should not be cancelled even if load failed
          map(() => true),
          catchError(_ => of(true))
        );
      }
    }

    return true;
  }
}
