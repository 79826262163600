import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeFormattingService } from '@capital-access/common/formatting';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { OwnershipSource } from '../../core/models/ownership-source.enum';

@Injectable()
export class FileNameService {
  constructor(private dateFormatting: DateTimeFormattingService) {}

  public getDefaultFileName(
    showSrvSource: boolean,
    isSrv: boolean,
    name: string,
    timezone: string
  ): Observable<string> {
    const currentDate = new Date();
    const ownershipSource = isSrv ? OwnershipSource.Surveillance : OwnershipSource.Public;
    const dataSource = showSrvSource ? `- (${ownershipSource}) ` : '';
    return this.dateFormatting
      .formatDate(currentDate.toISOString(), { zone: timezone }, DEFAULT_DATE_FORMAT)
      .pipe(map(formattedDate => `${name} ${dataSource}- ${formattedDate}`));
  }
}
