import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CRM_COMMON_LOCALIZATION_SCOPE,
  CRM_FORM_MAX_LENGTH,
  numberCountRegex,
  phoneCharactersRegex
} from '../../model/constants';

@Component({
  selector: 'ca-crm-form-phone-input',
  templateUrl: 'crm-form-phone-input.component.html'
})
export class CrmFormPhoneInputComponent {
  @Input() formGroup!: FormGroup;

  localizationScope = CRM_COMMON_LOCALIZATION_SCOPE;
  maxLength = CRM_FORM_MAX_LENGTH.phone;
  formArrayName = 'values';

  phoneValidation = [
    Validators.pattern(phoneCharactersRegex),
    Validators.pattern(numberCountRegex),
    Validators.maxLength(this.maxLength)
  ];

  get value() {
    return this.formGroup.get(this.formArrayName) as FormArray;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  addInputField() {
    this.value.push(new FormControl('', this.phoneValidation));
  }

  removeInputField(index: number) {
    this.value.removeAt(index);
  }

  isAddButtonDisabled() {
    return this.value.length > 1;
  }

  onInputFocus(control: AbstractControl) {
    control.markAsUntouched();
  }

  injectInputs(firstValue: string, secondValue: string) {
    this.value.at(0).patchValue(firstValue);

    if (secondValue) {
      this.value.push(new FormControl(secondValue, this.phoneValidation));
      this.changeDetectorRef.markForCheck();
    }
  }
}
