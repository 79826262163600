import uniq from 'lodash/uniq';
import {
  DeepNullable,
  EmptyConstructor,
  isType,
  ObjectWithProperty,
  PropertyDecorator
} from '@capital-access/common/utils';
import { SettingsConstructor } from '../internal/settings-constructor';
import { PreferenceValueConverter } from '../preference-value-converters';
import { SerializedSettingsEntity } from '../settings-entity';
import { UserSettingsSection } from '../user-settings-section';
import { SettingsConfiguration } from './decorator-config';

function mapDictionaryToSettings<T>(
  obj: DeepNullable<Record<string, T>> | undefined,
  mapFn: (key: string, value: DeepNullable<T>) => SerializedSettingsEntity[]
) {
  const result: SerializedSettingsEntity[] = [];
  Object.entries(obj || {}).forEach(([key, value]) => {
    const entities = mapFn(key, value);
    result.push(...entities);
  });
  return result;
}

function decorateSimpleDictionary<TVal>(valueConverter: PreferenceValueConverter<TVal>, config: SettingsConfiguration) {
  return <TKey extends string, TClass extends ObjectWithProperty<TKey, Record<string, TVal>>>(
    cls: TClass,
    key: TKey
  ) => {
    const settings = SettingsConstructor.fromCtor<TClass>(cls.constructor as EmptyConstructor<TClass>);
    const keyPrefix = SettingsConfiguration.getKeyPrefix(config, key);

    settings.registerSettingsEntities(obj =>
      mapDictionaryToSettings<TVal>(obj && obj[key], (dictKey, dictValue) => [
        {
          id: keyPrefix + dictKey,
          value: dictValue !== null ? valueConverter.toString(dictValue as TVal) : null
        }
      ])
    );

    settings.addSettingCast<Record<string, TVal>>(key, (section, knownKeys, initialValue) => {
      const dict = initialValue || {};

      section.settingsKeys
        .filter(settingKey => settingKey.startsWith(keyPrefix) && !knownKeys.includes(settingKey))
        .forEach(settingKey => {
          const recordValue = valueConverter.convert(section.getValue(settingKey));
          if (recordValue !== null) {
            dict[settingKey.slice(keyPrefix.length)] = recordValue;
          }
        });

      return dict;
    });
  };
}

function decorateComplexDictionary<TVal>(settingsCtor: EmptyConstructor<TVal>, config: SettingsConfiguration) {
  return <TKey extends string, TClass extends ObjectWithProperty<TKey, Record<string, TVal>>>(
    cls: TClass,
    key: TKey
  ) => {
    const settings = SettingsConstructor.fromCtor<TClass>(cls.constructor as EmptyConstructor<TClass>);
    const nestedSettings = SettingsConstructor.fromCtor(settingsCtor);
    const keyPrefix = SettingsConfiguration.getKeyPrefix(config, key);

    settings.registerSettingsEntities(obj =>
      mapDictionaryToSettings<TVal>(obj && obj[key], (dictKey, dictValue) =>
        nestedSettings.getSettingsEntities(`${keyPrefix}${dictKey}`, dictValue)
      )
    );

    settings.addSettingCast<Record<string, TVal>>(key, (section, knownKeys, initialValue) => {
      const dict = initialValue || {};

      uniq(
        section.settingsKeys
          .filter(settingKey => settingKey.startsWith(keyPrefix) && !knownKeys.includes(settingKey))
          .map(settingKey => settingKey.slice(keyPrefix.length).split(UserSettingsSection.keyDelimiter)[0])
      ).forEach(settingKey => {
        dict[settingKey] = nestedSettings.create(section.getSection(keyPrefix + settingKey));
      });

      return dict;
    });
  };
}

/**
 * This property will be set from subSection with prefix defined in path (by default it matches property name).
 *
 * The result is a dictionary where
 * - keys is all keys present is settings sub section excluding already defined keys
 * - and values retrieved from the valueConverter
 * @param valueConverter converter that defines rules to convert to target type and to string.
 * @param config additional configuration. See SettingsConfiguration for more info
 */
export function dictionary<TVal>(
  valueConverter: PreferenceValueConverter<TVal>,
  config?: SettingsConfiguration
): PropertyDecorator<Record<string, TVal>>;
/**
 * This property will be set from subSection with prefix defined in path (by default it matches property name).
 *
 * The result is a dictionary where
 * - keys is all keys present is settings sub section excluding already defined keys
 * - and values set by the rules defined in TVal class
 * @param settingsCtor Nested Settings Class
 * @param config additional configuration. See SettingsConfiguration for more info
 */
export function dictionary<TVal>(
  settingsCtor: EmptyConstructor<TVal>,
  config?: SettingsConfiguration
): PropertyDecorator<Record<string, TVal>>;

export function dictionary<TVal>(
  arg: EmptyConstructor<TVal> | PreferenceValueConverter<TVal>,
  config: SettingsConfiguration = SettingsConfiguration.default
) {
  if (isType(arg)) {
    return decorateComplexDictionary(arg, config);
  } else {
    return decorateSimpleDictionary(arg, config);
  }
}
