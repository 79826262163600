import { Component, HostBinding, OnInit, SkipSelf } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FireflyMobileModalComponent, ModalContent } from '../../modal';
import { FireflyBaseDateTimeDirective } from '../directives/base-date-time-directive';

@Component({
  selector: 'f-mobile-date-picker',
  template: ''
})
export class FireflyMobileDateTimeComponent implements OnInit, ModalContent {
  context!: FireflyBaseDateTimeDirective;
  modalInstance!: NgbActiveModal;

  @HostBinding('class') class = 'd-none';

  constructor(@SkipSelf() private modalContainer: FireflyMobileModalComponent) {}

  ngOnInit() {
    this.context.openPopupFromModal(this.modalContainer.dynamicContent);
  }
}
