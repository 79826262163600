import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { from, iif, isObservable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LocalizationService } from '@capital-access/common/localization';
import { ConfirmationModalOptions, FireflyModalService } from '@capital-access/firefly/components';
import { OnUnsavedChanges } from './on-unsaved-changes.interface';

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<OnUnsavedChanges> {
  constructor(private modalService: FireflyModalService, private localizationService: LocalizationService) {}

  canDeactivate(component: OnUnsavedChanges) {
    const dirty$ = isObservable(component?.dirty) ? component?.dirty : of(component?.dirty);
    const customLocalization$ = isObservable(component?.unsavedChangesModalLocalization)
      ? component?.unsavedChangesModalLocalization
      : of(component?.unsavedChangesModalLocalization);
    return dirty$.pipe(
      take(1),
      withLatestFrom(customLocalization$),
      switchMap(([isDirty, customLocalization]) =>
        iif(
          () => isDirty,
          this.localizationService.getLocalization('common-components').pipe(
            switchMap(localization => {
              const { unsavedChanges, unsavedChangesConfirmation, leaveAnyway, keepEditing } = localization;
              return from(
                this.modalService.openConfirmation({
                  style: 'warning',
                  title: customLocalization?.title || unsavedChanges,
                  body: customLocalization?.body || unsavedChangesConfirmation,
                  actionText: customLocalization?.actionText || leaveAnyway,
                  dismissText: customLocalization?.dismissText || keepEditing
                } as ConfirmationModalOptions).result
              ).pipe(
                // leave
                tap(() => {
                  if (component.onConfirmLeave) {
                    component.onConfirmLeave();
                  }
                }),
                map(() => true),
                // keep editing
                catchError(() => of(false))
              );
            })
          ),
          of(true)
        )
      )
    );
  }
}
