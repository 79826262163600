<div class="d-flex" [class.mobile-table-row-expanded]="isExpanded">
  <div class="w-100" [class.overflow-hidden]="!isExpanded">
    <div class="mt-1" [ngClass]="{ 'd-flex align-items-center mb-2': !isExpanded }" [class.text-wrap]="isExpanded">
      <ng-content select="[fCollapsedViewFirstLine]"></ng-content>
    </div>
    <div *ngIf="!isExpanded" class="d-flex align-items-center">
      <ng-content select="[fCollapsedViewSecondLine]"></ng-content>
    </div>
  </div>
  <div class="k-mobile-expand-button-wrapper">
    <button class="btn btn-simple-icon btn-primary k-mobile-expand-button" (click)="toggleColumnData()">
      <i class="f-i" [ngClass]="isExpanded ? 'f-i-caret-down' : 'f-i-caret-right'"></i>
    </button>
  </div>
</div>
<ng-container *ngIf="isExpanded">
  <ng-content select="[fExpandedView]"></ng-content>
</ng-container>
