import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyLoadingDotsComponent } from './loading-dots.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyLoadingDotsComponent],
  exports: [FireflyLoadingDotsComponent]
})
export class FireflyLoadingDotsModule {}
