import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FireflyDropdownModule } from '../../lib/dropdowns/dropdown.module';
import { FireflyActionButtonsModule } from '../action-buttons/action-buttons.module';
import { FireflyChipModule } from '../chips/chip.module';
import { FireflyDropdownUtilsModule } from '../dropdowns/directives/dropdown.utils.module';
import { FireflyListModule } from '../lists/list.module';
import { FireflySpinnerModule } from '../loading-indicators/spinner/spinner.module';
import { FireflyFormattingModule } from '../utils/formatting/formatting.module';
import { FireflyAddNewSuggesterItemComponent } from './components/add-new-suggester-item/add-new-suggester-item.component';
import { FireflyListStatusItemsComponent } from './components/list-status-items/list-status-items.component';
import { FireflyMultipleSuggesterComponent } from './components/multiple-suggester/multiple-suggester.component';
import { FireflyMultipleSuggesterDropdownComponent } from './components/multiple-suggester-dropdown/multiple-suggester-dropdown.component';
import { FireflySingleSuggesterComponent } from './components/single-suggester/single-suggester.component';
import { FireflySingleSuggesterDropdownComponent } from './components/single-suggester-dropdown/single-suggester-dropdown.component';
import { FireflySuggesterListItemComponent } from './components/suggester-list-item/suggester-list-item.component';
import { FireflySuggesterMobileComponent } from './components/suggester-mobile/suggester-mobile.component';
import { FireflySuggesterMobileDropdownComponent } from './components/suggester-mobile/suggester-mobile-dropdown.component';
import { FIREFLY_SUGGESTER } from './constants';
import { FireflySuggesterListDirective } from './directives/suggester-list.directive';
import { FireflyIsDisabledItemPipe } from './pipes/is-disabled-item.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    FireflyChipModule,
    FireflyListModule,
    FireflySpinnerModule,
    FireflyDropdownModule,
    FireflyFormattingModule,
    FireflyDropdownUtilsModule,
    FireflyActionButtonsModule
  ],
  declarations: [
    FireflySingleSuggesterComponent,
    FireflySingleSuggesterDropdownComponent,
    FireflySuggesterMobileDropdownComponent,
    FireflySuggesterMobileComponent,
    FireflyMultipleSuggesterComponent,
    FireflyMultipleSuggesterDropdownComponent,
    FireflyListStatusItemsComponent,
    FireflySuggesterListItemComponent,
    FireflySuggesterListDirective,
    FireflyIsDisabledItemPipe,
    FireflyAddNewSuggesterItemComponent
  ],
  exports: [
    FireflySingleSuggesterComponent,
    FireflySingleSuggesterDropdownComponent,
    FireflyMultipleSuggesterComponent,
    FireflyMultipleSuggesterDropdownComponent,
    FireflySuggesterMobileComponent,
    FireflySuggesterListDirective,
    FireflyAddNewSuggesterItemComponent
  ],
  providers: [
    {
      provide: FIREFLY_SUGGESTER,
      useClass: FireflyMultipleSuggesterComponent
    }
  ]
})
export class FireflySuggesterModule {}
