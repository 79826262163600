import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Log } from '../log';

@Injectable()
export class HttpErrorLogInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const logMessage = this.getHttpLogMessage(request, error);

        // do not log error when failed to post logs to exclude cyclic requests
        if (!error.message.includes('api/logging')) {
          Log.error(logMessage);
        }

        return throwError(() => error);
      })
    );
  }

  private getHttpLogMessage(request: HttpRequest<unknown>, response: HttpErrorResponse) {
    return {
      message: response.message,
      method: request.method,
      status: response.status
    };
  }
}
