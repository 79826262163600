import { Component } from '@angular/core';
import { Breakpoint } from '../../../utils';
import { hideOnRemovalAnimation } from '../notification/animations';
import { Notification, NotificationComponentContainer } from '../notification/notification-models';

export type Bucket = {
  items: Notification[];
};

@Component({
  selector: 'f-toast-message-container',
  templateUrl: './toast-message-container.component.html',
  animations: [hideOnRemovalAnimation]
})
export class ToastMessageContainerComponent implements NotificationComponentContainer {
  buckets: Bucket[] = [{ items: [] }];

  add(notification: Notification) {
    const bucket = this.getBucket();

    if (window.innerWidth < Breakpoint.Sm) {
      bucket.items.push(notification);
    } else bucket.items.unshift(notification);
  }

  remove(notification: Notification) {
    const bucket = this.getBucket();
    bucket.items = bucket.items.filter(item => item !== notification);
  }

  private getBucket() {
    const bucket = this.buckets.find(x => x);

    if (bucket) {
      return bucket;
    }

    throw new Error("Couldn't find suitable bucket for notification.");
  }
}
