import { NgModule } from '@angular/core';
import { CommonNavigationModule } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { ENGAGE_BASE_URL } from '@capital-access/events/common';
import { EventsCreateEventLazyModule } from '@capital-access/events/create-event/lazy';
import { EventsEmailsLandingLazyModule } from '@capital-access/events/emails/landing/lazy';

export const loadModule = () => import('@capital-access/events/client').then(m => m.EventsClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: ENGAGE_BASE_URL,
          loadChildren: loadModule
        }
      ]
    }),
    CommonNavigationModule.forFeature({
      top: [
        {
          localizationKey: 'engage',
          icon: 'f-i-calendar',
          order: 200,
          link: ENGAGE_BASE_URL
        }
      ]
    }),
    EventsCreateEventLazyModule,
    EventsEmailsLandingLazyModule
  ]
})
export class EventsClientLazyModule {}
