import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class DraggableLayoutService {
  private refreshSubject = new ReplaySubject<void>();

  refresh = this.refreshSubject.pipe(debounceTime(1));

  scheduleRefresh() {
    this.refreshSubject.next();
  }
}
