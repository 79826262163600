import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ContactGraphQLSearchResult, InstitutionGraphQLSearchResult } from '../services/graphql/search-result.model';
import { EmailQueryVariables, QueryVariables } from '../services/graphql/variables.model';
import { UrlBuilderService } from '../services/url-builder.service';

@Injectable({ providedIn: 'root' })
export class SearchProfileRepository {
  constructor(private http: HttpClient, private urlBuilder: UrlBuilderService) {}
  private readonly searchUrl = this.urlBuilder.getUrl('graphql');

  public searchInstitutions(query: string, variables: QueryVariables) {
    return this.http
      .post<InstitutionGraphQLSearchResult>(this.searchUrl, {
        query,
        variables
      })
      .pipe(
        map(result => ({
          institutions: result?.data?.institutions ?? []
        }))
      );
  }

  public searchContacts(query: string, variables: QueryVariables | EmailQueryVariables) {
    return this.http
      .post<ContactGraphQLSearchResult>(this.searchUrl, {
        query,
        variables
      })
      .pipe(
        map(result => ({
          contacts: result?.data?.contacts ?? []
        }))
      );
  }
}
