import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalizationService, LocalizedString } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';
@Pipe({
  name: 'caTryLocalize',
  pure: false
})
export class TryLocalizePipe
  extends BaseAsyncPipe<string, LocalizedString | string, Array<string | LocalizedString>>
  implements PipeTransform
{
  constructor(changeDetectorRef: ChangeDetectorRef, private localizationService: LocalizationService) {
    super(changeDetectorRef);
  }

  getTransformer(value: LocalizedString | string): Observable<string> {
    if (value instanceof LocalizedString) {
      return this.localizationService.localize(value);
    }
    return of(value);
  }
}
