export class CompositeId {
  prId?: number | null;
  crmId?: number | null;

  constructor(prId?: number | null, crmId?: number | null) {
    this.prId = prId;
    this.crmId = crmId;
  }

  static parse(key: string): CompositeId {
    const [prId, crmId] = key.split('|').map(id => {
      if (id) {
        const parsed = parseInt(id);
        if (isFinite(parsed)) {
          return parsed;
        }
      }
      return undefined;
    });

    return new CompositeId(prId, crmId);
  }

  toString() {
    return CompositeId.toString(this);
  }

  static toString(compositeId?: CompositeId) {
    return `${compositeId?.prId || ''}|${compositeId?.crmId || ''}`;
  }

  get isFullKey() {
    return this.hasPrId && this.hasCrmId;
  }

  get hasPrId() {
    return !!this.prId;
  }

  get hasCrmId() {
    return !!this.crmId;
  }
}
