import { Component, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FireflyMobileModalComponent, ModalContent } from '../../../modal';
import { FireflyBaseSuggesterComponent } from '../base-suggester/base-suggester.component';

@Component({
  selector: 'f-mobile-suggester-dropdown',
  template: ''
})
export class FireflySuggesterMobileDropdownComponent implements OnInit, OnDestroy, ModalContent {
  context!: FireflyBaseSuggesterComponent;
  referenceSibling!: HTMLElement | null;
  suggesterContainer!: HTMLElement;
  modalInstance!: NgbActiveModal;
  suggester!: HTMLElement;

  constructor(@SkipSelf() private modalContainer: FireflyMobileModalComponent) {}

  ngOnInit() {
    this.suggester = this.context.baseHost.nativeElement.querySelector('.dropdown-menu-with-suggester');
    this.suggesterContainer = this.suggester.parentElement!;
    this.referenceSibling = this.suggester.nextSibling as HTMLElement;
    this.modalContainer.modalBody.nativeElement.prepend(this.suggester);
  }

  ngOnDestroy() {
    if (this.referenceSibling) {
      this.suggesterContainer.insertBefore(this.suggester, this.referenceSibling);
    } else {
      this.suggesterContainer.append(this.suggester);
    }
  }
}
