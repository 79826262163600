import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UrlBuilder } from '../client/api/url-builder';
import { GraphQLSearchResult } from './graphql/search-result.model';
import { QueryVariables } from './graphql/variables.model';

@Injectable({ providedIn: 'root' })
export class SearchRepository {
  constructor(private http: HttpClient, private urlBuilder: UrlBuilder) {}
  private readonly searchPath = 'graphql';

  public search(query: string, variables: QueryVariables) {
    const searchUrl = this.urlBuilder.getUrl(this.searchPath);

    return this.http
      .post<GraphQLSearchResult>(searchUrl, {
        query,
        variables
      })
      .pipe(
        map(result => {
          return {
            contacts: result?.data?.contacts ?? [],
            institutions: result?.data?.institutions ?? [],
            funds: result?.data?.funds ?? [],
            events: result?.data?.events ?? [],

            errors: result?.errors ?? []
          };
        })
      );
  }
}
