<div
  class="alert alert-dismissible alert-{{ type }} fade show"
  role="alert"
  [ngClass]="[rootCssClass, showActionButton ? 'py-2' : 'align-items-center']"
>
  <i class="f-i f-i-md" [ngClass]="iconClasses[type]"></i>
  <div class="alert-content" data-automation-id="alert-content">
    <div [class.alert-message]="showActionButton" data-automation-id="alert-message">
      <ng-content></ng-content>
    </div>
    <button class="btn btn-primary btn-sm" *ngIf="showActionButton" (click)="onAction()">
      {{ actionButtonText }}
    </button>
  </div>
  <button *ngIf="showCancelButton" class="ms-auto btn-close" data-dismiss="alert" (click)="onHideClick()">
    <i class="f-i f-i-xs f-i-close" [title]="close$ | async"></i>
  </button>
</div>
