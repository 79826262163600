import isUndefined from 'lodash/isUndefined';
import { UserSettingsSection } from '../user-settings-section';

export class SettingsConfiguration {
  /**
   * Path to the subsection of user setting or to specific setting delimited by `:`
   *
   * If not specified property key will be used
   */
  path?: string;

  public static getPath(config: SettingsConfiguration, propertyKey: string) {
    if (!isUndefined(config.path)) {
      return config.path;
    } else {
      return propertyKey;
    }
  }

  public static getKeyPrefix(config: SettingsConfiguration, propertyKey: string) {
    const path = SettingsConfiguration.getPath(config, propertyKey);
    if (path.length > 0) {
      return path + UserSettingsSection.keyDelimiter;
    }
    return path;
  }

  public static readonly default: SettingsConfiguration = {};
}
