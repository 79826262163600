import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../utils';

@Component({
  selector: 'f-mobile-stepper',
  templateUrl: './mobile-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileStepperComponent {
  @Input() currentNumber!: number;
  @Input() title = '';
  @Input() totalSteps!: number;

  private localizationService = inject(FireflyLocalizationService, { optional: true });

  get mobileStepperLabel$() {
    return this.localizationService
      ? this.localizationService.localize('mobileStepperLabel', {
          currentStepNumber: this.currentNumber,
          totalSteps: this.totalSteps
        })
      : of(`Step ${this.currentNumber} of ${this.totalSteps}: `);
  }
}
