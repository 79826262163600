import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NumberFormattingService } from '@capital-access/common/formatting';
import { FireflyNumberFormattingService } from '@capital-access/firefly/components';

@Injectable()
export class FireflyCommonNumberFormattingService implements FireflyNumberFormattingService {
  constructor(private numberFormattingService: NumberFormattingService) {}

  formatNumber(n: number): Observable<string> {
    return this.numberFormattingService.formatNumber(n);
  }
}
