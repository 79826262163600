import { v4 as guid } from 'uuid';
import { Xor } from '@capital-access/common/utils';
import { BreadcrumbItemsFn, BreadcrumbStrategy } from './strategy.model';

export type BreadcrumbTagProvider = Xor<{ strategy: BreadcrumbStrategy }, { items: BreadcrumbItemsFn }>;

export class BreadcrumbConfigProvider {
  public readonly token: string;
  public readonly config: BreadcrumbItemsFn | BreadcrumbStrategy;

  constructor(provideTag: BreadcrumbTagProvider) {
    const tagType = Reflect.ownKeys(provideTag)[0].toString();
    this.token = `breadcrumb:${tagType}:${guid()}`;
    this.config = provideTag.strategy || provideTag.items;
  }
}
