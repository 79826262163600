import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SpinnerSize } from '../../../loading-indicators';
import { FireflyLocalizationService } from '../../../utils';
import { SuggesterValidation } from '../../models/suggester-validation.model';
import { ValidationType } from '../../models/validation-type.enum';
import { FireflyBaseSuggesterComponent } from '../base-suggester/base-suggester.component';

@Component({
  selector: 'f-add-new-suggester-item',
  templateUrl: 'add-new-suggester-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyAddNewSuggesterItemComponent implements OnInit, OnDestroy {
  @Input() suggester!: FireflyBaseSuggesterComponent;
  @Input() inputValue!: FormControl;
  @Input() validation: SuggesterValidation | null = null;
  @Input() placeholder = 'Add option';
  @Input() linkTitle = '';
  @Input() disabled = false;
  @Input() disabledMessage = '';
  @Input() addItem = new Subject();

  @Output() addNewItem = new EventEmitter<string>();
  @Output() inputValueChange = new EventEmitter<string>();

  showLink = true;
  isLoading = false;
  SpinnerSize = SpinnerSize;
  destroyed$ = new Subject<void>();
  linkText$ = this.localizationService?.localize('addOptionMessage', {}) ?? of('Add option');

  get validationClass() {
    if (this.validation?.type === ValidationType.Valid) return 'is-valid';
    if (this.validation?.type === ValidationType.Warning) return 'is-warning';
    if (this.validation?.type === ValidationType.Invalid) return 'is-invalid';
    else return '';
  }

  get validationMessageClass() {
    let suffix = '-feedback';
    if (this.validation?.tooltip) suffix += '-tooltip';

    if (this.validation?.type === ValidationType.Valid) return 'valid' + suffix;
    if (this.validation?.type === ValidationType.Warning) return 'warning' + suffix;
    if (this.validation?.type === ValidationType.Invalid) return 'invalid' + suffix;
    else return '';
  }

  constructor(private cdr: ChangeDetectorRef, @Optional() private localizationService: FireflyLocalizationService) {}

  ngOnInit() {
    requestAnimationFrame(() => {
      this.suggester.dropdown.openChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.resetState();
      });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  showInput(input: HTMLInputElement) {
    if (this.disabled) return;
    this.showLink = false;
    requestAnimationFrame(() => input.focus());
  }

  tryToAddNewItem() {
    const itemName = this.inputValue.value ?? '';

    this.addNewItem.emit(itemName);

    setTimeout(() => {
      if (this.inputValue.valid && (!this.validation || this.validation.type === ValidationType.Valid)) {
        this.isLoading = true;
        this.cdr.detectChanges();

        this.inputValue.valueChanges.pipe(take(1)).subscribe(() => {
          this.suggester.scrollItemIntoView(itemName.trim());
          this.isLoading = false;
          this.resetState();
        });
      }
    });
  }

  resetState() {
    this.showLink = true;
    this.validation = null;
    this.inputValue.reset();
    this.inputValue.markAsPristine();
    this.cdr.detectChanges();
  }
}
