<div class="overflow-hidden" [ngClass]="contentRootClass">
  <ng-content></ng-content>
</div>
<i
  class="f-i f-i-close f-i-xs"
  data-automation-id="remove-list-group-item"
  [title]="!disabled ? 'Remove' : ''"
  [ngClass]="disabled ? 'disabled' : 'remove'"
  *ngIf="!loading || disabled; else spinner"
  [tabindex]="!disabled ? 0 : -1"
  (click)="onRemoveItem()"
  (keyup.enter)="onRemoveItem()"
></i>
<ng-template #spinner>
  <f-spinner [spinnerSize]="spinnerSize.Sm" class="ms-4"></f-spinner>
</ng-template>
