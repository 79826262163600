<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center" (click)="openSortingModal(modal.title)">
    {{ title }}
    <i
      #modal
      class="f-i f-i-sm"
      [title]="sortBy$ | async"
      [ngClass]="sort[0].dir === 'asc' ? 'f-i-caret-up' : 'f-i-caret-down'"
    ></i>
  </div>
  <span
    *ngIf="checkboxColumn"
    class="link link-primary body-70 fw-normal"
    [class.link-disabled]="isLoading || hasNoData"
    (click)="onSelect()"
  >
    {{ selectText | async }}
  </span>
</div>
