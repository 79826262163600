import { EmptyConstructor, ObjectWithProperty } from '@capital-access/common/utils';
import { SettingsConstructor } from '../internal/settings-constructor';
import { SettingsConfiguration } from './decorator-config';

/**
 * Initialize property with sub settings object
 * This object will be initialized from subSection with prefix that matches property key.
 * @param settingsCtor Nested Settings Class
 * @param config additional configuration. See SettingsConfiguration for more info
 */
export function nested<TVal>(
  settingsCtor: EmptyConstructor<TVal>,
  config: SettingsConfiguration = SettingsConfiguration.default
) {
  return <TKey extends string, TClass extends ObjectWithProperty<TKey, TVal>>(cls: TClass, key: TKey) => {
    const settings = SettingsConstructor.fromCtor<TClass>(cls.constructor as EmptyConstructor<TClass>);
    const sectionPath = SettingsConfiguration.getPath(config, key);
    const nestedSettings = SettingsConstructor.fromCtor(settingsCtor);

    settings.registerSettingsEntities(x => nestedSettings.getSettingsEntities(sectionPath, x && x[key]));
    settings.addSettingCast(key, section => nestedSettings.create(section.getSection(sectionPath)));
  };
}
