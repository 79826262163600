import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PopupHorizontalScrollDirective } from './popup-horizontal-scroll.directive';

@NgModule({
  declarations: [PopupHorizontalScrollDirective],
  exports: [PopupHorizontalScrollDirective],
  imports: [CommonModule]
})
export class PopupHorizontalScrollModule {}
