import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import { of } from 'rxjs';
import { FireflyLocalizationService } from '../../../utils';

@Component({
  selector: 'f-mobile-table-column',
  templateUrl: './mobile-table-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTableColumnComponent implements AfterViewInit {
  @Input() title = '';
  @Input() hasAlwaysContent = false;
  @Input() rootCssClass = '';
  @ViewChild('valueContent') valueContent!: ElementRef;

  private cdr = inject(ChangeDetectorRef);
  private localizationService = inject(FireflyLocalizationService, { optional: true });

  showContent = true;
  noDataMessage$ = this.localizationService
    ? this.localizationService.localize('noDataMessage', {})
    : of('No data to display');

  ngAfterViewInit() {
    if (!this.hasAlwaysContent) {
      this.updateValueElement();
    }
  }

  private updateValueElement(): void {
    const hasContent = !!(
      this.valueContent.nativeElement.innerText?.length || this.valueContent.nativeElement.querySelector('.f-i')
    );

    if (!hasContent) {
      this.showContent = false;
      this.cdr.detectChanges();
    }
  }
}
