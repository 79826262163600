import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { PendoEffects } from './pendo.effects';
import { PendoService } from './pendo.service';

export function pendoInitializer(pendoService: PendoService) {
  return pendoService.insertPendoScript();
}

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([PendoEffects])],
  providers: [
    PendoService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: pendoInitializer,
      deps: [PendoService]
    }
  ]
})
export class PendoModule {}
