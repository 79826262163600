import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { addContact, editContact } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CompositeId } from '@capital-access/profiles/common';
import { CRM_CONTACT_FORM_MODULE_ID, LOAD_CRM_CONTACT_FORM_MODULE } from '../contact-form-loader-module-id.model';

@Injectable()
export class ContactFormLoaderEffects {
  addContact$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addContact),
        switchMap(({ email, institution }) =>
          this.commonLazyLoadingService
            .loadModule(CRM_CONTACT_FORM_MODULE_ID, LOAD_CRM_CONTACT_FORM_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showAddContactForm(email, institution)))
        )
      ),
    { dispatch: false }
  );

  editContact$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editContact),
        switchMap(({ id }) =>
          this.commonLazyLoadingService
            .loadModule(CRM_CONTACT_FORM_MODULE_ID, LOAD_CRM_CONTACT_FORM_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showEditContactForm(new CompositeId(id.prId, id.crmId))))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
