import { Type } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { isObservable, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 as guid } from 'uuid';
import { addTag } from '@capital-access/common/utils';
import { updateTitle } from './title.actions';
import { BROWSER_TITLE_TAG_KEY, TitlePart } from './title.models';

/**
 * Register a browser title part for given component
 * @param component Type of the component for which title is registered
 * @param source Function that returns title part in form of object or observable
 */
export function createTitle(component: Type<unknown>, source: () => Observable<TitlePart> | TitlePart) {
  const uniqueKey = addTitleTag(component);
  const resolvedSource = source();
  const resolvedSource$ = isObservable(resolvedSource) ? resolvedSource : toObservable(resolvedSource);

  const effectResult$ = resolvedSource$.pipe(
    map(title =>
      updateTitle({
        title,
        token: uniqueKey
      })
    )
  );

  return createEffect(() => effectResult$);
}

/**
 * @summary Convert `source` to `Observable`
 * @description This is done by using `ReplaySubject(1)` on purpose.
 * Using `of` operator creates completed `Observable`
 * which will be automatically resubscribed to by an consuming `Effect`
 */
function toObservable(source: TitlePart) {
  const subject = new ReplaySubject<TitlePart>(1);
  subject.next(source);
  return subject;
}

function addTitleTag(component: Type<unknown>) {
  const uniqueKey = `${component.name}:${guid()}`;

  addTag(component, {
    key: BROWSER_TITLE_TAG_KEY,
    descriptor: {
      value: uniqueKey
    }
  });

  return uniqueKey;
}
