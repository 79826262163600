import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { catchError, delay, filter } from 'rxjs/operators';
import { v4 as guid } from 'uuid';
import { HttpStatusCode } from '@capital-access/common/http';
import { LocalizationService } from '@capital-access/common/localization';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FireflyModalService, NotificationService } from '@capital-access/firefly/components';
import { CALENDAR_SYNC_COMMON_KEY } from '../../../constants';
import { ManualTransactionRequest, SyncStatus, TransactionStatus, TransactionType } from '../../../models';
import { CalendarSyncTransactionRepository } from '../../../services/calendar-sync-transaction.repository';
import {
  cancelProgress,
  cancelProgressFailure,
  cancelProgressSuccess,
  cancelTransaction,
  enableSyncTransactionComplete,
  enableSyncTransactionFailure,
  loadEnableSyncProgressStatus,
  loadInitialProgressStatus,
  loadProgressStatusFailure,
  loadProgressStatusSuccess,
  loadTransactionProgressStatus,
  reset,
  setProgressStatus,
  skipTransaction,
  syncActivities,
  transactionComplete,
  transactionFailure,
  transactionFinished,
  transactionReset,
  transactionSuccess,
  triggerEnableSyncProgress,
  updateProgressStatus
} from './transaction.actions';
import { getEmail, getTransactionId, getTransactionIdForSync, getVendorType } from './transaction.selectors';

@Injectable()
export class CommonTransactionEffects {
  cancellationModal: unknown | null = null;

  bulkSyncActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncActivities),
      switchMap(({ activities, vendor }) => {
        if (activities.every(a => a.syncStatus === SyncStatus.Synced || a.syncStatus === SyncStatus.Disabled)) {
          return [skipTransaction({ count: activities.length, syncStatus: activities[0].syncStatus })];
        }

        const request = {
          transactionId: guid(),
          vendorType: vendor,
          manualTransactionItems: activities.map(a => ({
            activityId: a.activityId,
            eventId: a.eventId,
            eventName: a.eventName,
            calendar: a.calendar,
            status: a.syncStatus
          }))
        } as ManualTransactionRequest;
        return this.transactionRepository.submitManualTransaction(request).pipe(
          map(() => loadTransactionProgressStatus({ transactionId: request.transactionId })),
          catchError(({ status }) => of(transactionFailure({ count: activities.length, status })))
        );
      })
    )
  );

  loadProgressStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTransactionProgressStatus),
      withLatestFrom(this.store.select(getVendorType)),
      switchMap(([{ transactionId }, vendor]) =>
        this.transactionRepository.getTransactionProgress(vendor!, transactionId).pipe(
          switchMap(progressStats => {
            const actions =
              !!transactionId && progressStats.transactionStatus === TransactionStatus.Complete
                ? [transactionComplete({ progressStats }), setProgressStatus({ progressStats })]
                : [setProgressStatus({ progressStats })];
            return [loadProgressStatusSuccess(), ...actions];
          }),
          catchError(() => of(loadProgressStatusFailure()))
        )
      )
    )
  );

  loadInitialProgressStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitialProgressStatus),
      switchMap(({ vendor }) =>
        this.transactionRepository.getTransactionProgress(vendor!).pipe(
          switchMap(progressStats => {
            return [loadProgressStatusSuccess(), setProgressStatus({ progressStats })];
          }),
          catchError(() => of(loadProgressStatusFailure()))
        )
      )
    )
  );

  loadEnableSyncProgressStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEnableSyncProgressStatus),
      withLatestFrom(this.store.select(getVendorType)),
      switchMap(([{ eventId }, vendor]) =>
        this.transactionRepository.enableSyncProgress(eventId!, vendor!).pipe(
          map(progressStats => {
            return setProgressStatus({ progressStats, transactionType: TransactionType.EnableSync });
          }),
          catchError(() => of(loadProgressStatusFailure()))
        )
      )
    )
  );

  triggerUpdateProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProgressStatus),
      filter(
        ({ progressStats }) =>
          !!progressStats.transactionId && progressStats.transactionStatus === TransactionStatus.InProgress
      ),
      map(({ progressStats, transactionType }) =>
        updateProgressStatus({ transactionId: progressStats.transactionId, transactionType })
      )
    )
  );

  updateProgressStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgressStatus),
      filter(({ transactionType }) => transactionType !== TransactionType.EnableSync),
      withLatestFrom(this.store.select(getVendorType)),
      delay(2000),
      switchMap(([{ transactionId, transactionType }, vendor]) =>
        this.transactionRepository.getTransactionProgress(vendor!, transactionId!).pipe(
          withLatestFrom(this.store.select(getTransactionIdForSync)),
          switchMap(([progressStats, transactionId]) => {
            return !transactionId
              ? [reset()]
              : progressStats.transactionStatus === TransactionStatus.Complete
              ? [
                  transactionComplete({ progressStats }),
                  transactionReset({ transactionId: progressStats.transactionId, transactionType })
                ]
              : [setProgressStatus({ progressStats, transactionType })];
          }),
          catchError(() => of(loadProgressStatusFailure()))
        )
      )
    )
  );

  updateEnableProgressStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgressStatus),
      filter(({ transactionType }) => transactionType === TransactionType.EnableSync),
      withLatestFrom(this.store.select(getVendorType)),
      delay(2000),
      switchMap(([{ transactionId }, vendor]) =>
        this.transactionRepository.getTransactionProgress(vendor!, transactionId!).pipe(
          switchMap(progressStats => {
            return progressStats.transactionStatus === TransactionStatus.Complete
              ? [
                  enableSyncTransactionComplete({ progressStats, transactionId }),
                  transactionReset({
                    transactionId: progressStats.transactionId,
                    transactionType: TransactionType.EnableSync
                  })
                ]
              : [setProgressStatus({ progressStats, transactionType: TransactionType.EnableSync })];
          }),
          catchError(() => of(loadProgressStatusFailure()))
        )
      )
    )
  );

  cancelProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelProgress),
      withLatestFrom(this.store.select(getTransactionId)),
      filter(([, transactionId]) => !!transactionId),
      switchMap(([, transactionId]) =>
        this.localizationService.getLocalization(CALENDAR_SYNC_COMMON_KEY).pipe(
          switchMap(({ cancelWarningTitle, cancelWarningBody, cancelWarningConfirm, cancelWarningCancel }) => {
            const modal = this.modalService.openConfirmation({
              style: 'warning',
              title: cancelWarningTitle,
              actionText: cancelWarningConfirm,
              dismissText: cancelWarningCancel,
              body: cancelWarningBody
            });
            this.cancellationModal = modal;
            return from(modal.result).pipe(
              map(() => cancelTransaction({ transactionId: transactionId! })),
              catchError(() => EMPTY)
            );
          })
        )
      )
    )
  );

  cancelTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelTransaction),
      switchMap(({ transactionId }) =>
        this.transactionRepository.cancelTransaction(transactionId!).pipe(
          map(progressStats => cancelProgressSuccess({ progressStats })),
          catchError(() => [cancelProgressFailure(), loadTransactionProgressStatus({ transactionId })])
        )
      )
    )
  );

  transactionComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionComplete, enableSyncTransactionComplete),
        tap(() => {
          if (this.cancellationModal) {
            (this.cancellationModal as NgbModalRef)?.dismiss();
          }
        })
      ),
    { dispatch: false }
  );

  cancelSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelProgressSuccess),
        withLatestFrom(this.store.select(getEmail)),
        switchMap(([{ progressStats }, email]) => {
          return this.localizationService
            .localize(['someActivitiesSync', 'activitiesSyncCancelled'], { email }, CALENDAR_SYNC_COMMON_KEY)
            .pipe(
              map(([someActivitiesSync, activitiesSyncCancelled]) => {
                this.notificationService.notify(activitiesSyncCancelled, { type: 'info' });
                if (progressStats.progressValue > 0) {
                  this.notificationService.notify(someActivitiesSync, { type: 'success' });
                }
              })
            );
        })
      ),
    { dispatch: false }
  );

  transactionCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionComplete, enableSyncTransactionComplete),
      switchMap(({ progressStats }) => {
        const failedCount = progressStats.failedCount + progressStats.partiallySynced;
        const actions = [];
        if (!failedCount && !progressStats.completedCount) {
          actions.push(transactionFinished());
        }
        if (failedCount) {
          actions.push(transactionFailure({ count: failedCount }));
        }
        if (progressStats.completedCount) {
          actions.push(transactionSuccess({ stats: progressStats }));
        }
        return actions;
      })
    )
  );

  syncSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionSuccess),
        withLatestFrom(this.store.select(getEmail)),
        switchMap(([{ stats }, email]) => {
          const messageKey = stats.completedCount > 1 ? 'syncActivitiesSuccessMessage' : 'syncActivitySuccessMessage';
          return this.localizationService
            .localize(messageKey, { email, count: stats.completedCount }, CALENDAR_SYNC_COMMON_KEY)
            .pipe(map(message => this.notificationService.notify(message, { type: 'success' })));
        })
      ),
    { dispatch: false }
  );

  syncFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionFailure, enableSyncTransactionFailure),
        withLatestFrom(this.store.select(getEmail)),
        filter(([{ status }]) => status !== HttpStatusCode.CONFLICT),
        switchMap(([{ count }, email]) => {
          const messageKey = count > 1 ? 'syncActivitiesFailureMessage' : 'syncActivityFailureMessage';
          return this.localizationService.localize(messageKey, { email, count }, CALENDAR_SYNC_COMMON_KEY).pipe(
            take(1),
            map(message => this.notificationService.notify(message, { type: 'info' }))
          );
        })
      ),
    { dispatch: false }
  );

  transactionFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionFinished),
        withLatestFrom(this.store.select(getEmail)),
        switchMap(([, email]) => {
          return this.localizationService.localize('syncActivityUpToDate', { email }, CALENDAR_SYNC_COMMON_KEY).pipe(
            take(1),
            map(message => this.notificationService.notify(message, { type: 'info' }))
          );
        })
      ),
    { dispatch: false }
  );

  skipTransaction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skipTransaction),
        switchMap(({ count, syncStatus }) => {
          const key =
            count > 1
              ? (syncStatus === SyncStatus.Disabled && 'skipSyncDisabledActivities') || 'activitiesUpToDate'
              : (syncStatus === SyncStatus.Disabled && 'skipSyncDisabledActivity') || 'activityUpToDate';

          return this.localizationService.localize(key, {}, CALENDAR_SYNC_COMMON_KEY).pipe(
            map(message =>
              this.notificationService.notify(message, {
                type: syncStatus === SyncStatus.Disabled ? 'danger' : 'info'
              })
            )
          );
        })
      ),
    { dispatch: false }
  );

  loadProgressFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadProgressStatusFailure),
        switchMap(() => {
          return this.localizationService
            .localize('progressError', {}, CALENDAR_SYNC_COMMON_KEY)
            .pipe(map(message => this.notificationService.notify(message, { type: 'danger' })));
        })
      ),
    { dispatch: false }
  );

  triggerEnableSyncProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(triggerEnableSyncProgress),
      map(({ stats }) => setProgressStatus({ progressStats: stats, transactionType: TransactionType.EnableSync }))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private localizationService: LocalizationService,
    private notificationService: NotificationService,
    private transactionRepository: CalendarSyncTransactionRepository,
    private modalService: FireflyModalService
  ) {}
}
