import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { LOCALIZATION_KEY, MAX_SMALL_REPORT_ITEMS_COUNT } from '../constants';

@Pipe({ name: 'caReportIsExportingTooltip', pure: false })
export class ReportIsExportingTooltipPipe extends BaseAsyncPipe<string, number, [string?]> implements PipeTransform {
  constructor(private localizationService: LocalizationService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  getTransformer(profilesCount: number, fallback: string = ''): Observable<string> {
    return this.localizationService.getLocalization(LOCALIZATION_KEY).pipe(
      take(1),
      map(localization =>
        profilesCount > MAX_SMALL_REPORT_ITEMS_COUNT ? localization['waitingTimeTooltip'] : fallback
      )
    );
  }
}
