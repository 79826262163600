<svg:g>
  <ng-container *ngFor="let bar of _bars; let i = index; trackBy: trackBy">
    <ng-template #popoverTemplateOutlet>
      <ng-container
        *ngTemplateOutlet="$any(popoverTemplate); context: { $implicit: popoverContextData(i) }"
      ></ng-container>
    </ng-template>
    <svg:g
      #container
      class="f-bar-group"
      [class.selected]="selectedBarIndex === i"
      [class.interactive]="interactions.hover"
      [attr.data-automation-id]="'bar-container-' + seriesId + '' + i"
      (mouseenter)="onMouseEnter(popover, bar.x, i)"
      (mouseleave)="onMouseLeave(popover, bar.x)"
      (click)="onClick(container, bar.x, i)"
    >
      <rect
        class="f-bar-halo"
        *ngIf="interactions.hover"
        [attr.height]="dimensions.height"
        [attr.width]="bar.halo?.width"
        [attr.x]="bar.halo?.x"
        [attr.y]="0"
      ></rect>
      <defs>
        <clipPath id="clip-path-{{ seriesId }}{{ i }}-{{ hash }}" class="f-bar-clipping">
          <rect
            [attr.x]="bar.x - strokeWidth"
            [attr.y]="animationState === 'initial' ? '100%' : bar.stack.y - strokeWidth"
            [attr.height]="bar.stack.height + strokeWidth * 2"
            [attr.width]="bar.width + strokeWidth * 2"
            [ngStyle]="animationStyles"
          ></rect>
        </clipPath>
      </defs>
      <svg:g
        #popover="ngbPopover"
        [ngbPopover]="popoverTemplate && popoverTemplateOutlet"
        [popoverClass]="popoverClass"
        [placement]="popoverPlacement"
        [triggers]="'manual'"
        [container]="'body'"
      >
        <ng-container *ngFor="let data of $any(bar.data)">
          <rect
            class="f-rect-bar"
            [attr.y]="data.y"
            [attr.height]="data.height || minBarHeight"
            [attr.x]="selectedBarIndex === i ? bar.x + strokeWidth / 2 : bar.x"
            [attr.width]="selectedBarIndex === i ? bar.width - strokeWidth : bar.width"
            [attr.clip-path]="'url(#clip-path-' + seriesId + '' + i + '-' + hash + ')'"
            [attr.data-automation-id]="'bar-' + seriesId + '' + i"
            [ngClass]="data.barClass"
          ></rect>
        </ng-container>
        <svg:g
          f-bubble
          *ngIf="showOptionalData"
          [cx]="optionalData[i].cx"
          [cy]="optionalData[i].cy"
          [bubbleRadius]="bubbleRadius"
          [value]="optionalData[i].value"
          [bubbleShowZero]="barBubbleShowZero"
          [textClasses]="barBubbleTextClasses"
          [bubbleClasses]="optionalData[i].cssClass"
          [animationDuration]="bubblesAnimationDuration"
          [animationDelay]="animationDuration + 50"
          [animation]="animation"
        ></svg:g>
      </svg:g>
      <rect
        [attr.y]="bar.stack.y"
        [attr.x]="selectedBarIndex === i ? bar.x + strokeWidth / 2 : bar.x"
        [attr.width]="selectedBarIndex === i ? bar.width - strokeWidth : bar.width"
        [attr.height]="bar.stack.height"
        class="f-stacked-bar-wrapper"
      ></rect>
    </svg:g>
  </ng-container>
</svg:g>
