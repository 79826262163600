import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyMobileChartPopoverComponent } from './chart-mobile-popover.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyMobileChartPopoverComponent],
  exports: [FireflyMobileChartPopoverComponent]
})
export class FireflyBarChartPopoverModule {}
