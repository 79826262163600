import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { FireflyChipModule } from '@capital-access/firefly/components';
import { ActivistFilterComponent } from './activist-filter.component';

@NgModule({
  imports: [CommonModule, CommonLocalizationModule, FireflyChipModule],
  declarations: [ActivistFilterComponent],
  exports: [ActivistFilterComponent]
})
export class ActivistFilterModule {}
