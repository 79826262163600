<ng-container [caLocalizationScope]="localizationScope">
  <ca-modify-report-drawer-body
    [reportDescriptionTitle]="'historicalOwnershipReportDescription'"
    *ngrxLet="isReportFilterToggleEnabled$ as isReportFilterToggleEnabled"
  >
    <form [formGroup]="form">
      <ca-sections-selector formControlName="sections" (settingsChange)="settingsChange($event)"></ca-sections-selector>
      <div class="form-check form-switch form-check-inline mt-1" *ngIf="isReportFilterToggleEnabled">
        <input
          class="form-check-input"
          id="includeTables"
          type="checkbox"
          [formControlName]="ReportSection.HistoricalOwnershipTableDetails"
          (change)="switchOffSetAsDefault()"
          [attr.disabled]="disableSaving || null"
        />
        <label class="form-check-label" for="includeTables">{{ 'includeTables' | caLocalize }}</label>
      </div>
      <ca-set-as-default-toggle
        [offsetClasses]="isReportFilterToggleEnabled ? 'mt-6' : 'mt-3'"
        [control]="setAsDefaultControl"
        [disableSaving]="disableSaving"
        [toggleLabel]="'historicalOwnershipToggleLabel'"
      ></ca-set-as-default-toggle>
    </form>
  </ca-modify-report-drawer-body>
  <ng-template fDrawerFooterTemplate>
    <ca-modify-report-drawer-footer
      [disableSaving]="disableSaving"
      [createReportTitle]="'historicalOwnershipCreateReport'"
      (closeDrawer)="close()"
      (createReport)="createReport()"
    >
    </ca-modify-report-drawer-footer>
  </ng-template>
</ng-container>
