<div class="toast toast-{{ type }} show d-flex align-items-start f-drawer-outclick-exception-element">
  <i class="f-i f-i-md f-i-{{ type }} toast-state-icon"></i>
  <div class="toast-container">
    <div *ngIf="headerText" class="toast-header">{{ headerText }}</div>
    <div class="toast-body">
      <ng-container *ngIf="messageTemplate as template; else text">
        <ng-template [ngTemplateOutlet]="template"></ng-template>
      </ng-container>

      <ng-template #text>{{ message }}</ng-template>
    </div>
  </div>
  <i class="f-i f-i-xs f-i-close close" [title]="close$ | async" (click)="closeClick()"></i>
</div>
