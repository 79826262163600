import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DraggableLayoutComponent } from './draggable-layout.component';
import { DraggableLayoutItemHeaderDirective } from './draggable-layout-item-header.directive';
import { DraggableLayoutItemNameDirective } from './draggable-layout-item-name.directive';
import { DraggableLayoutItemPositionDirective } from './draggable-layout-item-position.directive';

@NgModule({
  declarations: [
    DraggableLayoutComponent,
    DraggableLayoutItemHeaderDirective,
    DraggableLayoutItemNameDirective,
    DraggableLayoutItemPositionDirective
  ],
  imports: [CommonModule],
  exports: [
    DraggableLayoutComponent,
    DraggableLayoutItemHeaderDirective,
    DraggableLayoutItemNameDirective,
    DraggableLayoutItemPositionDirective
  ]
})
export class DraggableLayoutModule {}
