<div class="empty-state-wrapper">
  <div class="empty-state-body">
    <div
      class="onboarding-empty-state-img"
      [ngClass]="onboardingOption"
      data-automation-id="onboarding-empty-state-img"
    ></div>
    <div class="onboarding-empty-state-caption" data-automation-id="onboarding-empty-state-caption">{{ caption }}</div>
    <div class="onboarding-empty-state-info" data-automation-id="onboarding-empty-state-info">{{ info }}</div>
    <ng-content select="[fEmptyStatesActions]"></ng-content>
  </div>
  <div class="empty-state-note" data-automation-id="empty-state-note">
    {{ note }}
  </div>
</div>
