<div
  class="f-small-card-row-container"
  [ngClass]="{
    'border-bottom pb-2 mb-2': !last && mobile,
    'mb-sm-3': reducedRowSpacing && !(last && excludeLastFromSpacing),
    'mb-sm-4': !reducedRowSpacing && !(last && excludeLastFromSpacing)
  }"
  [attr.data-automation-id]="rowID"
>
  <div class="f-small-card-row" [ngClass]="columnBreakdown">
    <div class="f-small-card-left-col" [class.justify-content-start]="firstContainerWithBadge">
      <div
        [ngClass]="{
          'f-small-card-first-container': firstContainerWithBadge,
          'd-block': firstContainerTwoLines,
          'mb-1': mobileBottomRow && showSecondContainerContent
        }"
        [attr.data-automation-id]="firstContainerID"
      >
        <ng-container *ngIf="mobile && firstContainerWithBadge; else defaultMainContent">
          <ng-container *ngTemplateOutlet="secondContainerContent"></ng-container>
        </ng-container>
        <ng-template #defaultMainContent>
          <ng-container *ngTemplateOutlet="firstContainerContent"></ng-container>
        </ng-template>
      </div>

      <div *ngIf="!mobile" class="f-small-card-second-container" [class.flex-shrink-1]="firstContainerWithBadge">
        <div *ngIf="!firstContainerWithBadge; else simpleSecondContainerContent" class="d-flex align-items-center">
          <ng-container *ngTemplateOutlet="secondContainerContent"></ng-container>
        </div>
        <ng-template #simpleSecondContainerContent>
          <ng-container *ngTemplateOutlet="secondContainerContent"></ng-container>
        </ng-template>
      </div>
    </div>

    <div class="f-small-card-right-col">
      <ng-container *ngTemplateOutlet="thirdContainerContent"></ng-container>
      <div *ngIf="!mobile && fourthContainerTitle" class="ms-3">
        <ng-container *ngTemplateOutlet="fourthContainerContent"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="mobile" class="f-small-card-bottom-container" [class.flex-shrink-1]="firstContainerWithBadge">
    <div *ngIf="bottomRowFirstOrSecondTitle" class="d-flex align-items-center body-50 me-3">
      <span class="text-muted me-1" *ngIf="showSecondContainerContent" [attr.data-automation-id]="secondContainerID">
        {{ firstContainerWithBadge ? firstContainerTitle : secondContainerTitle }}:
      </span>
      <span #valueSecondContainerContent>
        <ng-container
          *ngTemplateOutlet="firstContainerWithBadge ? firstContainerContent : secondContainerContent"
        ></ng-container>
      </span>
    </div>
    <div class="d-flex align-items-center" *ngIf="fourthContainerTitle && showFourthContainerContent">
      <span class="body-50 text-muted me-1" [attr.data-automation-id]="fourthContainerID"
        >{{ fourthContainerTitle }}:</span
      >
      <span class="d-inline-flex" #valueFourthContainerContent>
        <ng-container *ngTemplateOutlet="fourthContainerContent"></ng-container>
      </span>
    </div>
  </div>
</div>
