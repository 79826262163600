import { ConditionalReportSections, ReportSection, SectionSelectorListItem } from '../../core/models/report-sections';

export function getConditionalReportSections(isHistoricalOwnershipTableEnabled: boolean): ConditionalReportSections {
  return {
    [ReportSection.HistoricalOwnershipTableDetails]: isHistoricalOwnershipTableEnabled
  };
}

export const DEFAULT_HISTORICAL_OWNERSHIP_SECTIONS: SectionSelectorListItem[] = [
  {
    id: ReportSection.Style,
    isSelected: true,
    localizationKey: 'styleSection'
  },
  {
    id: ReportSection.HolderRegion,
    isSelected: true,
    localizationKey: 'holderRegionSection'
  },
  {
    id: ReportSection.HolderCountry,
    isSelected: true,
    localizationKey: 'holderCountrySection'
  },
  {
    id: ReportSection.HolderInvestmentCenter,
    isSelected: true,
    localizationKey: 'holderInvestmentCenterSection'
  },
  {
    id: ReportSection.HistoricalOwnershipTableDetails,
    isSelected: true,
    isCommonForAllSections: true
  }
];
