import { NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { GridModule } from '@progress/kendo-angular-grid';
import { FireflyScrollableGridDirective } from './shared-grid.directive';

@NgModule({
  declarations: [FireflyScrollableGridDirective],
  exports: [GridModule, FireflyScrollableGridDirective]
})
export class FireflyGridModule {}
