export enum FailedContactStatus {
  NotSaved = 'NotSaved',
  InProgress = 'InProgress',
  Saved = 'Saved'
}

export interface FailedContact {
  status: FailedContactStatus;
  email: string;
  name?: string;
}
