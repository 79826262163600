import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FireflySkeletonLargeCardLoaderModule,
  FireflySkeletonSmallCardLoaderModule,
  FireflySpinnerModule
} from '../loading-indicators';
import { FireflyCardActionsDirective } from './directives/card-actions.directive';
import { FireflyCardContentSubCardDirective } from './directives/card-content.directive';
import { FireflyCardFooterDirective } from './directives/card-footer.directive';
import { FireflyCardTitleExtensionsDirective } from './directives/card-title-extensions.directive';
import { FireflyLargeCardComponent } from './large-card/large-card.component';
import { FireflySmallCardComponent } from './small-card/small-card.component';
import { FireflySubCardsComponent } from './sub-cards/sub-cards.component';

@NgModule({
  imports: [
    CommonModule,
    FireflySpinnerModule,
    FireflySkeletonSmallCardLoaderModule,
    FireflySkeletonLargeCardLoaderModule
  ],
  declarations: [
    FireflyCardActionsDirective,
    FireflyCardFooterDirective,
    FireflyCardTitleExtensionsDirective,
    FireflyCardContentSubCardDirective,
    FireflySmallCardComponent,
    FireflyLargeCardComponent,
    FireflySubCardsComponent
  ],
  exports: [
    FireflyCardActionsDirective,
    FireflyCardFooterDirective,
    FireflyCardTitleExtensionsDirective,
    FireflyCardContentSubCardDirective,
    FireflyLargeCardComponent,
    FireflySmallCardComponent,
    FireflySubCardsComponent
  ]
})
export class FireflyCardsModule {}
