import { Action, createReducer, on } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { loadFeatureToggleFail, loadFeatureToggleSuccess } from './feature-toggle.actions';

export interface State {
  loadStatus: LoadStatus;
  entities: string[];
}

export const initialState: State = {
  loadStatus: LoadStatus.Pristine,
  entities: []
};

const featureToggleReducer = createReducer(
  initialState,
  on(loadFeatureToggleSuccess, (state, action) => {
    return {
      ...state,
      loadStatus: LoadStatus.Loaded,
      entities: action.toggleProfile.toggleIds
    };
  }),
  on(loadFeatureToggleFail, state => {
    return {
      ...state,
      loadStatus: LoadStatus.Failed
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return featureToggleReducer(state, action);
}
