import { Action, createReducer, on } from '@ngrx/store';
import { localizationLoadSuccess } from '@capital-access/common/localization';
import { updateTitle } from './title.actions';
import { TitlePart } from './title.models';

export interface State {
  titleParts: Record<string, TitlePart>;
  scopes: Record<string, boolean>;
}

export const initialState: State = {
  titleParts: {},
  scopes: {}
};

const titleReducer = createReducer(
  initialState,
  on(updateTitle, (state, action) => {
    const titleParts = { ...state.titleParts, [action.token]: action.title };
    return { ...state, titleParts };
  }),
  on(localizationLoadSuccess, (state, action) => {
    const scopes = { ...state.scopes, [action.payload.scope || '$default']: true };
    return { ...state, scopes };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return titleReducer(state, action);
}
