import { drag as d3Drag } from 'd3-drag';
import * as d3Ease from 'd3-ease';
import {
  BaseType,
  ContainerElement,
  matcher as d3Matcher,
  pointer as d3Pointer,
  select as d3Select,
  Selection
} from 'd3-selection';
import { Transition, transition as d3Transition } from 'd3-transition';
import debounce from 'lodash/debounce';
import { LayoutChangeEventData, LayoutItemPosition, LayoutOptions } from './draggable-layout.model';

/* eslint-disable @typescript-eslint/no-explicit-any */

export class D3DraggableLayout {
  private container: Selection<Element, unknown, null, undefined>;
  private items!: { id: string; idx: number; isDragged: boolean; element: Element; offset?: number }[];
  private options: LayoutOptions;
  private renderDragOverlap: any;
  private itemElements!: Selection<Element, any, Element, unknown>;
  private containerBounds?: {
    left: number;
    top: number;
    right: number;
    bottom: number;
    width: number;
    height: number;
  };
  private placeholder?: Selection<HTMLDivElement, any, null, undefined>;
  private positions?: number[];
  private scrollContext?: {
    overlapTimerId: any;
    scrollTimerId: any;
  };
  private dragOffset?: [number, number];

  constructor(element: any, options: Partial<LayoutOptions>) {
    this.container = d3Select(element).classed('ca-layout-grid', true);

    this.options = Object.assign(new LayoutOptions(), options);

    this.createItems();
  }

  refresh() {
    this.createItems();
  }

  private configureDrag() {
    this.renderDragOverlap = debounce((item: any) => this.renderOverlap(item), this.options.overlapRenderDelay);

    const drag = d3Drag()
      .filter((event: any) => {
        // false = ignore event
        // prevent drag if mouse button is not left button or we have match on selector
        return !event.button && !d3Matcher(this.options.dragPreventSelector).call(event.target);
      })
      .on('start', (event, item) => {
        this.renderDragStart(item, event);
      })
      .on('drag', (event, item) => {
        this.renderDrag(item, event);
      })
      .on('end', (event, item) => {
        this.renderDragEnd(item);
      });

    let dragElements = this.itemElements;
    if (this.options.dragSelector) {
      dragElements = dragElements?.select(this.options.dragSelector);
    }

    dragElements?.call(drag);
  }

  private createItems() {
    const items: any[] = (this.items = []);

    const indexes: number[] = [];

    this.itemElements = this.container
      .selectAll(function () {
        return this.children;
      })
      .classed('ca-layout-grid-item', true)

      .each(function (d, i, n) {
        d3Select(this).classed('ca-layout-grid-with-four-items', n.length > 3);
        d3Select(this).classed('ca-layout-grid-with-three-items', n.length === 3);
        d3Select(this).classed('ca-layout-grid-with-two-items', n.length < 3);
      })
      // get initial indexes and place accordingly
      .each((d, i, n) => {
        const t = n[i] as { getAttribute: (attr: string) => string };
        const index = +t?.getAttribute('data-layout-position');
        indexes.push(index);
      })
      .data(indexes)
      .sort((a, b) => a - b)
      // create data model
      .each((d, i, n) => {
        const t = n[i] as { getAttribute: (attr: string) => string };

        const item = {
          id: t.getAttribute('data-layout-id'),
          idx: i,
          isDragged: false,
          element: n[i]
        };
        items.push(item);
      })
      .data(items);

    this.configureDrag();
  }

  private getBounds(element: any) {
    const container = this.container.node();
    if (!container) throw new Error('Container should not be empty');

    const containerBounds = container.getBoundingClientRect();

    let left, top, bounds;

    if (element === container) {
      left = containerBounds.left;
      top = containerBounds.top + window.scrollY;
      bounds = containerBounds;
    } else {
      const elementBounds = element.getBoundingClientRect();
      left = elementBounds.left - containerBounds.left;
      top = elementBounds.top - containerBounds.top;
      bounds = elementBounds;
    }

    return {
      top,
      left,
      right: left + bounds.width,
      bottom: top + bounds.height,
      width: bounds.width,
      height: bounds.height
    };
  }

  private renderDragStart(item: any, event: any) {
    // prepare data model
    item.isDragged = true;
    const positions: number[] = (this.positions = []);
    this.scrollContext = {
      overlapTimerId: null,
      scrollTimerId: null
    };

    this.containerBounds = this.getBounds(this.container.node());
    this.itemElements.each((d, idx, n) => {
      d.initialIdx = idx;
      d.idx = idx;
      d.offset = this.getBounds(n[idx]);

      positions.push(d.offset);
    });

    //offset to current element top left point
    this.dragOffset = d3Pointer(event, item.element);

    // prepare dom
    this.container.classed('ca-layout-grid-active', true).style('height', this.containerBounds.height + 'px');

    d3Select(item.element).classed('ca-layout-grid-item-dragged', true);

    this.itemElements.style('position', 'absolute').call(this.setPosition);

    this.placeholder = this.container
      .append('div')
      .datum(item)
      .classed('ca-layout-grid-placeholder', true)
      .call(this.setPosition)
      .style('width', function (d) {
        return d.offset.width + 'px';
      })
      .style('height', function (d) {
        return d.offset.height + 'px';
      });

    this.placeholder.append('div').classed('ca-layout-grid-placeholder-content', true);
  }

  private renderDrag(item: any, event: any) {
    const container = this.container.node() as ContainerElement;
    if (!container) throw new Error('Container element should not be empty');

    this.renderMove(item, d3Pointer(event, container));
    this.renderDragOverlap(item);

    if (this.options.scroll) {
      this.checkScroll(item, event);
    }
  }

  private renderDragEnd(item: any) {
    this.clearScrollContext();
    this.scrollContext = undefined;

    this.renderDragOverlap.cancel();
    this.placeholder?.remove();

    let isPositionChanged = false;

    // specify order for elements
    this.items.forEach(function (currentItem, idx) {
      isPositionChanged = isPositionChanged || currentItem.idx !== idx;
      currentItem.idx = idx;
    });

    // sort dom elements in specified order
    this.itemElements = this.itemElements.sort(function (a, b) {
      return a.idx - b.idx;
    });

    // set desired position with animation and clear drag specific styles on transition end
    d3Select(item.element)
      .transition(this.createTransition())
      .call(this.setPosition)
      .on('end', (d, i, n) => {
        item.isDragged = false;

        d3Select(n[i]).classed('ca-layout-grid-item-dragged', false);

        this.itemElements
          .style('position', null)
          .style('left', null)
          .style('top', null)
          .attr('data-layout-position', function (d) {
            return d.idx;
          });

        this.container.classed('ca-layout-grid-active', false).style('height', null);

        if (isPositionChanged) {
          this.triggerPositionChangeEvent(item);
        }
      });
  }

  private renderMove(item: any, containerOffset: any) {
    const element = d3Select(item.element);

    if (!this.dragOffset) throw new Error('Drag offset is not set');

    let left = containerOffset[0] - this.dragOffset[0];
    let top = containerOffset[1] - this.dragOffset[1];

    // fit in container bounds
    const maxLeft = (this.containerBounds?.width ?? 0) - item.offset.width;

    if (left < 0) {
      left = 0;
    } else if (left > maxLeft) {
      left = maxLeft;
    }

    const maxTop = (this.containerBounds?.height ?? 0) - item.offset.height;

    if (top < 0) {
      top = 0;
    } else if (top > maxTop) {
      top = maxTop;
    }

    element.style('left', left + 'px').style('top', top + 'px');
  }

  private checkScroll(item: any, event: any) {
    //clear all previous scrolls
    this.clearScrollContext();

    //calculate upper and lower bounds that trigger scroll
    const coefficient = this.options.scrollAreaPercent / 100;
    const scrollParentHeight = this.getScrollParentHeight();
    const upperLimit = scrollParentHeight * coefficient;
    const lowerLimit = scrollParentHeight * (1 - coefficient);

    //save initial coordinates to calculate delta
    const container = this.container.node() as ContainerElement;
    if (!container) throw new Error('Container element should not be empty');

    const containerCoordinates = d3Pointer(event, container);
    const initialScrollTop = this.getScrollOffset();

    const y = event.sourceEvent.clientY - this.getScrollParentTop();

    // check is mouse is within bounds
    const isScrollDown = y > lowerLimit;
    const isScrollUp = !isScrollDown && y < upperLimit;

    if (isScrollDown || isScrollUp) {
      const scrollDistance = (isScrollDown ? 1 : -1) * this.options.scrollDistance;

      const triggerDragOverlap = () => {
        this.renderDragOverlap(item);
      };

      let previousScrollTop = initialScrollTop;
      let currentScrollTop;

      // eslint-disable-next-line no-var
      var triggerScroll = () => {
        if (!this.scrollContext) throw new Error('Scroll context is not set');

        this.options.scrollParent?.scrollBy(0, scrollDistance);

        currentScrollTop = this.getScrollOffset();
        const scrolledDelta = currentScrollTop - previousScrollTop;

        // calculate new top coordinate based on scroll
        const newContainerOffset = [
          containerCoordinates[0],
          containerCoordinates[1] + currentScrollTop - initialScrollTop
        ];

        // if we actually scrolled cancel overlap action
        if (scrolledDelta !== 0) {
          this.renderDragOverlap.cancel();

          this.renderMove(item, newContainerOffset);

          this.scrollContext.scrollTimerId = requestAnimationFrame(triggerScroll);
        }
        // if we didn't scrolled (we are on window top or bottom), cancel scrolling and trigger overlap action with delay
        else {
          this.clearScrollContext();
          this.scrollContext.overlapTimerId = setTimeout(triggerDragOverlap, this.options.scrollOverlapDelay);
        }

        previousScrollTop = currentScrollTop;
      };

      if (!this.scrollContext) throw new Error('Scroll context is not set');
      this.scrollContext.scrollTimerId = requestAnimationFrame(triggerScroll);
    }
  }

  private clearScrollContext() {
    if (!this.scrollContext) throw new Error('Scroll context is not set');

    cancelAnimationFrame(this.scrollContext.scrollTimerId);
    clearTimeout(this.scrollContext.overlapTimerId);
    this.scrollContext.scrollTimerId = null;
    this.scrollContext.overlapTimerId = null;
  }

  private setPosition(selection: any) {
    selection
      .filter((d: { offset: unknown }) => d.offset)
      .style('left', function (d: any) {
        return d.offset.left + 'px';
      })
      .style('top', function (d: any) {
        return d.offset.top + 'px';
      });
  }

  private switchItems(items: any, dragItem: any, overlapItemIndex: any) {
    // pull dragged item from array
    const currentIdx = items.indexOf(dragItem);
    items.splice(currentIdx, 1);

    // insert into correct position
    items.splice(overlapItemIndex, 0, dragItem);
  }

  private renderSwitch(dragItem: any, overlapItemIndex: any) {
    this.switchItems(this.items, dragItem, overlapItemIndex);

    this.items.forEach((currentItem, idx) => {
      currentItem.offset = this.positions?.[idx];
    });

    this.itemElements
      .filter(function (d) {
        return !d.isDragged;
      })
      .transition(this.createTransition())
      .call(this.setPosition);

    this.placeholder?.call(this.setPosition);
  }

  private renderOverlap(item: any) {
    const currentItemPosition = this.getBounds(item.element);

    const draggedItemArea = item.offset.height * item.offset.width;
    const draggedItemCenterX = (currentItemPosition.left + currentItemPosition.right) / 2;
    const draggedItemCenterY = (currentItemPosition.top + currentItemPosition.bottom) / 2;

    const isWithin = (data: any) => {
      if (data === item || !data.offset) {
        return false;
      }

      const isCenterPointWithinBounds =
        draggedItemCenterX >= data.offset.left &&
        draggedItemCenterX < data.offset.right &&
        draggedItemCenterY >= data.offset.top &&
        draggedItemCenterY < data.offset.bottom;

      if (!isCenterPointWithinBounds) {
        return false;
      }

      const overlappedWidth =
        Math.min(data.offset.right, currentItemPosition.right) - Math.max(data.offset.left, currentItemPosition.left);
      const overlappedHeight =
        Math.min(data.offset.bottom, currentItemPosition.bottom) - Math.max(data.offset.top, currentItemPosition.top);

      if (overlappedWidth <= 0 || overlappedHeight <= 0) {
        return false;
      }

      const overlappedArea = overlappedHeight * overlappedWidth;
      return overlappedArea >= (draggedItemArea * this.options.overlapPercent) / 100;
    };

    const overlapIndex = this.items.findIndex(isWithin);
    if (overlapIndex !== -1) {
      this.renderSwitch(item, overlapIndex);
    }
  }

  private createTransition(): Transition<BaseType, any, any, any> {
    return d3Transition()
      .duration(this.options.transitionDuration)
      .ease((d3Ease as any)[this.options.easing]) as any as Transition<BaseType, any, any, any>;
  }

  private triggerPositionChangeEvent(draggedItem: any) {
    const eventHandler = this.options.onPositionChange;
    if (eventHandler) {
      const positionData = this.items.map(function (item) {
        return { id: item.id, position: item.idx } as LayoutItemPosition;
      });
      const eventData = {
        oldIndex: draggedItem.initialIdx,
        newIndex: draggedItem.idx,
        positions: positionData
      } as LayoutChangeEventData;
      eventHandler.call(this, eventData);
    }
  }

  private getScrollParentTop() {
    return this.options.scrollParent instanceof Window ? 0 : this.options.scrollParent?.getBoundingClientRect().y ?? 0;
  }

  private getScrollOffset() {
    if (this.options.scrollParent instanceof Window) {
      return this.options.scrollParent.scrollY;
    } else {
      return this.options.scrollParent?.scrollTop ?? 0;
    }
  }

  private getScrollParentHeight() {
    return this.options.scrollParent instanceof Window
      ? this.options.scrollParent.innerHeight
      : this.options.scrollParent?.clientHeight ?? 0;
  }
}
