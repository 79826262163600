import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonBreadcrumbsModule } from '@capital-access/common/breadcrumbs';
import { CommonBrowserTitleModule } from '@capital-access/common/browser';
import { CommonRouterModule } from '@capital-access/common/router';
import { INTERNAL_SERVER_ERROR_PAGE_ROUTE } from '@capital-access/common/utils';
import { CommonErrorPageModule } from '../error-page/error-page.module';
import { internalServerErrorPageRoute } from '../utils/error-page.models';
import { RedirectService } from '../utils/schedule-redirect/redirect.service';
import { ScheduleRedirectModule } from '../utils/schedule-redirect/schedule-redirect.module';
import { InternalServerErrorPageBreadcrumbs } from './internal-server-error-page.breadcrumbs';
import { InternalServerErrorPageComponent } from './internal-server-error-page.component';
import { InternalServerErrorPageTitle } from './internal-server-error-page.title';

@NgModule({
  declarations: [InternalServerErrorPageComponent],
  imports: [
    CommonModule,
    CommonErrorPageModule,
    CommonBreadcrumbsModule.forFeature(InternalServerErrorPageBreadcrumbs),
    CommonBrowserTitleModule.forFeature(InternalServerErrorPageTitle),
    CommonRouterModule.forFeature({
      anonymous: [{ path: internalServerErrorPageRoute, component: InternalServerErrorPageComponent }]
    }),
    ScheduleRedirectModule
  ],
  exports: [InternalServerErrorPageComponent],
  providers: [
    {
      provide: INTERNAL_SERVER_ERROR_PAGE_ROUTE,
      useValue: internalServerErrorPageRoute
    }
  ]
})
export class InternalServerErrorPageModule {
  constructor(redirectService: RedirectService) {
    redirectService.clearScheduledRedirect();
  }
}
