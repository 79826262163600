import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { saveSettingsSuccess, UserSettingsSection } from '@capital-access/common/user';
import { LICENSE_AGREEMENT_PREFERENCE_SECTION } from '../models/constants';
import { LicenseAgreementPreferences } from '../models/license-agreement-preferences';

@Injectable()
export class LicenseAgreementEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    @Inject(LICENSE_AGREEMENT_PREFERENCE_SECTION) private preferenceSection: string
  ) {}

  redirectToHomePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSettingsSuccess),
        // check if saveSettingsSuccess was triggered for License Agreement
        map(action =>
          new UserSettingsSection('', action.settings)
            .getSection(this.preferenceSection)
            .toObject(LicenseAgreementPreferences)
        ),
        distinctUntilChanged(),
        tap(settings => {
          if (settings.trialAccepted) this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );
}
