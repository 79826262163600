import { Injectable } from '@angular/core';
import { NotificationService } from '@capital-access/firefly/components';
import { CrmModifiedToastOptionsModel } from './crm-modified-toast-options.model';
import { CrmModifiedToastStateService } from './crm-modified-toast-state.service';

@Injectable()
export class CrmModifiedToastService {
  constructor(private notificationService: NotificationService, private state: CrmModifiedToastStateService) {}

  open(options: CrmModifiedToastOptionsModel) {
    this.state.options = options;
    this.notificationService.notify(this.state.template, { type: this.state.options.toastType });
  }
}
