<f-chart
  [view]="view"
  [xScale]="xScale"
  [yScale]="yScale"
  [yLineScale]="lineYScale"
  [animation]="animation"
  [integerYAxis]="integerYAxis"
  [fitContainer]="fitContainer"
  [lineAxisLabelText]="lineAxisLabelText"
  [truncateXAxisTicks]="truncateXAxisTicks"
  [truncateYAxisTicks]="truncateYAxisTicks"
  [rotateXAxisTicks]="rotateXAxisTicks"
  [maxXAxisTickLength]="maxXAxisTickLength"
  [maxYAxisTickLength]="maxYAxisTickLength"
  [maxYAxisTicksCount]="maxTicksCount"
  [xAxisTickFormatting]="xAxisTickFormatting"
  [yAxisTickFormatting]="yAxisTickFormatting"
  [lineAxisTickFormatting]="lineAxisTickFormatting"
  [startLineAxisDomainWithZero]="startLineAxisDomainWithZero"
  [showXAxisGridlines]="showXAxisGridlines"
  [showYAxisGridlines]="showYAxisGridlines"
  [showXAxisTicksLabels]="showXAxisTicksLabels"
  [showYAxisTicksLabels]="showYAxisTicksLabels"
  [showLineAxisTicksLabels]="showLineAxisTicksLabels"
  [xAxisTickLabelClasses]="xAxisTickLabelClasses"
  [xAxisTickLabelOffset]="xAxisTickLabelOffset"
  [showLineAxisGridlines]="showLineAxisGridlines"
  [showBarsOptionalData]="showBarsOptionalData"
  [showXAxisDomain]="showXAxisDomain"
  [showYAxisDomain]="showYAxisDomain"
  [popoverTemplate]="popoverTemplate"
  [popoverPlacement]="popoverPlacement"
  [legendRootClass]="legendRootClass"
  [legendOptions]="legendOptions"
  [showLegend]="showLegend"
  [leftAxisLabelText]="leftAxisLabelText"
  [labelsOffset]="labelsOffset"
  [showBorders]="showBorders"
  [showLabels]="showLabels"
  [condensedOnDesktop]="condensedOnDesktop"
  [condensedAxisOnMobile]="condensedAxisOnMobile"
  [chartPatternsTemplate]="chartPatternsTemplate"
  (dimensionsChanged)="onDimensionsChanged($event)"
>
  <ng-container *ngIf="dimensions">
    <svg:g
      f-stacked-bar-series
      [lineData]="$any(lineData)"
      [series]="data"
      [gapBetweenSeries]="gapBetweenSeries"
      [limitedBarMaxWidth]="limitedBarMaxWidth"
      [xScale]="xScale"
      [yScale]="yScale"
      [animation]="animation"
      [condensedOnDesktop]="condensedOnDesktop"
      [dimensions]="dimensions"
      [barClasses]="barClasses"
      [interactions]="interactions"
      [popoverTemplate]="popoverTemplate"
      [popoverPlacement]="popoverPlacement"
      [popoverOpenDelay]="popoverOpenDelay"
      [popoverCssClass]="popoverCssClass"
      [animationDuration]="animationDuration"
      [parentChartComponent]="chartComponent"
      [showOptionalData]="showBarsOptionalData"
      [barBubbleShowZero]="infoBubbleShowZero"
      [barBubbleTextClasses]="infoBubbleTextClasses"
      [barBubbleClasses]="infoBubbleClasses"
      [activeBarIndex]="activeBarIndex"
      (seriesClick)="onStackedBarClick($event)"
      (seriesHover)="onBarHover($event)"
    />

    <svg:g
      f-line
      [data]="$any(lineData)"
      [xScale]="$any(lineXScale)"
      [yScale]="lineYScale"
      [curved]="lineCurved"
      [lineWidth]="lineWidth"
      [lineClasses]="lineClasses"
      [circleRadius]="lineCircleRadius"
      [activeDataPointId]="activeDataPointId"
      [nearestXPointer]="pointerXCoordinate"
      [animationDuration]="animationDuration"
      [animationDelay]="animationDelay"
      [animation]="animation"
    />
  </ng-container>
</f-chart>
