import { Injectable } from '@angular/core';
import { createTitle } from '@capital-access/common/browser';
import { ForbiddenPageComponent } from './forbidden-page.component';

@Injectable()
export class ForbiddenPageTitle {
  title = createTitle(ForbiddenPageComponent, () => ({
    value: ['Capital Access', 'Forbidden'],
    override: true
  }));
}
