<div
  #drawer
  class="drawer"
  data-automation-id="drawer-container"
  [ngClass]="drawerClasses"
  [ngStyle]="{ 'z-index': zIndex }"
  [attr.data-layout-id]="dataLayoutId"
>
  <div class="drawer-title-wrapper" [ngClass]="titleWrapperCssClass">
    <div class="drawer-back-button-wrapper" *ngIf="addBackButton">
      <button
        class="btn btn-secondary btn-icon btn-sm"
        data-automation-id="back-drawer-button"
        (click)="confirmAndClose(true)"
      >
        <i class="f-i f-i-chevron-left"></i>
      </button>
    </div>

    <div class="drawer-action-button-wrapper" [class.d-none]="!actionButtonTemplate">
      <ng-template #actionButton></ng-template>
    </div>

    <f-multiline-text-truncate
      [dataAutomationId]="'non-collapsed-drawer-title'"
      [title]="title"
      [class]="['drawer-title', titleClass]"
    >
      <ng-template #titleElement let-headerText>
        <span [title]="title">{{ headerText }}</span>
      </ng-template>
      <i
        #titleObject
        *ngIf="titleIconClass"
        data-automation-id="drawer-title-icon"
        class="f-i f-i-md align-text-bottom"
        [ngClass]="titleIconClass"
        [ngbTooltip]="titleIconTooltip"
        placement="bottom"
      ></i>
      <span
        #titleObject
        [ngClass]="titleBadgeClass"
        *ngIf="titleBadgeClass"
        class="badge ms-2 align-text-bottom"
        data-automation-id="drawer-title-badge"
      >
        {{ titleBadgeText }}
      </span>
    </f-multiline-text-truncate>

    <div [ngClass]="{ 'd-inline-flex align-items-start': controlButtonsTemplate || showControlButton }">
      <div
        class="me-2"
        data-automation-id="drawer-dropdown-toggle"
        [class.d-none]="!controlButtonsTemplate || showControlButton"
      >
        <f-dropdown
          [hideCaret]="true"
          [icon]="'f-i-dots-y'"
          [btnStyle]="'btn-secondary btn-sm'"
          [title]="options$ | async"
        >
          <ng-template #controlButtons></ng-template>
        </f-dropdown>
      </div>
      <div class="me-2" data-automation-id="control-button" *ngIf="showControlButton">
        <button
          class="btn btn-secondary btn-icon btn-sm"
          [disabled]="controlButtonDisabled"
          [title]="controlButtonTitle"
          (click)="controlButtonAction($event)"
        >
          <i class="f-i" [ngClass]="controlButtonIconClass"></i>
        </button>
      </div>
      <button
        class="btn btn-secondary btn-icon btn-sm"
        data-automation-id="close-drawer-button"
        (click)="confirmAndClose()"
        [title]="close$ | async"
      >
        <i class="f-i f-i-close"></i>
      </button>
    </div>
  </div>

  <div
    fDrawerScrollable
    [class.pb-0]="hasNoFooter"
    class="drawer-content-wrapper"
    data-automation-id="drawer-content-wrapper"
  >
    <div [ngClass]="hasNoFooter ? drawerWithFooterClasses : drawerWithoutFooterClasses">
      <ng-template #content></ng-template>
    </div>
  </div>

  <div class="drawer-footer" [class.d-none]="hasNoFooter">
    <ng-template #footer></ng-template>
  </div>

  <f-spinner *ngIf="loadingType === LoadingType.Overlay" rootCssClass="spinner-overlay"></f-spinner>
</div>
