import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySvgDonutChartComponent } from './svg-donut-chart.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflySvgDonutChartComponent],
  exports: [FireflySvgDonutChartComponent]
})
export class FireflySvgDonutChartModule {}
