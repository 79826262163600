import { getBrowserLanguage } from '@capital-access/common/utils';
import { fallbackLang } from '../constants';

export function getDefaultLanguage(supportedLanguages: readonly string[]) {
  const browserLanguage = getBrowserLanguage();
  return browserLanguage && supportedLanguages.includes(browserLanguage) ? browserLanguage : fallbackLang;
}

export function resolveLanguage(preferenceValue: string | null, supportedLanguages: readonly string[]) {
  if (preferenceValue && supportedLanguages.includes(preferenceValue)) {
    // Use Value From Preference
    return preferenceValue;
  }
  return getDefaultLanguage(supportedLanguages);
}
