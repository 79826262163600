import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyChartBoilerplateModule } from '../common/chart-boilerplate/chart-boilerplate.module';
import { FireflyStackedBarSeriesModule } from '../common/stacked-bar-series/stacked-bar-series.module';
import { FireflyStackedBarChartComponent } from './stacked-bar-chart.component';

@NgModule({
  imports: [CommonModule, FireflyStackedBarSeriesModule, FireflyChartBoilerplateModule],
  declarations: [FireflyStackedBarChartComponent],
  exports: [FireflyStackedBarChartComponent]
})
export class FireflyStackedBarChartModule {}
