import { ListItem, ListType, ProfileId } from './models';
import { List } from './models/list.model';

export function getUpdatedLists(lists: List[], editedList: List): List[] {
  return lists.map(list =>
    list.id === editedList.id
      ? {
          ...getLastUpdatedListInformation(list, editedList, editedList.itemsCount),
          name: editedList.name,
          description: editedList.description
        }
      : list
  );
}

export function removeItemFromArray<T>(array: Array<T>, index: number) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function getFilteredItems(
  items: ListItem[],
  removedItems: ProfileId[] | number[],
  listType: ListType
): ListItem[] {
  return listType === ListType.Security
    ? getFilteredSecurityItems(items, removedItems as number[])
    : getFilteredProfileItems(items, removedItems as ProfileId[]);
}

export function getItemsIds(items: ListItem[], listType: ListType): ProfileId[] | number[] {
  return listType === ListType.Security
    ? (items.map(item => item.securityId) as number[])
    : (items.map(item => ({ prId: item.prId, crmId: item.crmId })) as ProfileId[]);
}

function getFilteredProfileItems(items: ListItem[], removedItems: ProfileId[]): ListItem[] {
  return items.filter(
    item => !removedItems.find(removedItem => removedItem.crmId == item.crmId && removedItem.prId == item.prId)
  );
}
function getFilteredSecurityItems(items: ListItem[], removedItems: number[]): ListItem[] {
  return items.filter(item => !removedItems.find(removedItem => removedItem == item.securityId));
}
export function getLastUpdatedListInformation(
  list: List,
  listData: Required<{
    lastModifiedUserId: number;
    lastModifiedDateTimeUtc: string;
  }>,
  itemsCount: number
): List {
  return {
    ...list,
    itemsCount: itemsCount,
    lastModifiedUserId: listData.lastModifiedUserId,
    lastModifiedDateTimeUtc: listData.lastModifiedDateTimeUtc
  };
}
