import { Directive, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { DraggableLayoutService } from './draggable-layout-service';

@Directive({
  selector: '[caDraggableLayoutItemPosition]'
})
export class DraggableLayoutItemPositionDirective implements OnChanges, OnDestroy {
  @HostBinding('attr.data-layout-position')
  @Input('caDraggableLayoutItemPosition')
  position!: number;

  constructor(private draggableLayoutService: DraggableLayoutService) {}

  ngOnChanges() {
    this.draggableLayoutService.scheduleRefresh();
  }

  ngOnDestroy() {
    this.draggableLayoutService.scheduleRefresh();
  }
}
