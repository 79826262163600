import { LogLevel, StsConfigHttpLoader } from 'angular-auth-oidc-client';
import { of } from 'rxjs';
import { AppSettingsService } from '@capital-access/common/settings';
import { Settings } from './oidc-settings.model';

const getUrl = (route: string) => `${location.origin}${route}`;

export function configLoaderFactory(settingsService: AppSettingsService) {
  const settings = settingsService.getSettings<Settings>().oidc;

  if (!settings) {
    throw new Error(`Can not configure authentication. Oidc settings are not initialized.`);
  }

  const config = {
    authority: settings.stsServer,
    redirectUrl: getUrl(settings.redirectRoute),
    postLoginRoute: settings.postLoginRoute,
    postLogoutRedirectUri: getUrl(settings.postLogoutRedirectRoute),
    clientId: settings.clientId,
    scope: settings.scope,
    responseType: settings.responseType,
    silentRenew: settings.silentRenew,
    silentRenewUrl: getUrl(settings.silentRenewRoute),
    renewTimeBeforeTokenExpiresInSeconds: settings.renewBeforeExpirationInSec,
    disableIatOffsetValidation: settings.disableIatOffsetValidation,
    unauthorizedRoute: settings.unauthorizedRoute,
    logLevel: LogLevel.Error,
    // To avoid redirection after login via pop-up
    triggerAuthorizationResultEvent: true
  };

  return new StsConfigHttpLoader(of(config));
}
