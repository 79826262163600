<ca-search-category-list
  caLocalizationScope="search-nav"
  data-automation-id="ca-institutions-results-container"
  class="ca-one-search-results-list"
  *ngIf="showItems$ | async"
  [header]="'inInstitutions' | caLocalize"
  [items]="items$ | async"
  [showMoreHeader]="'showMore' | caLocalize"
  [hasMore]="hasMore$ | async"
  [rowTemplate]="rowInstitutionTemplate"
  [category]="categories.Institutions"
  [currentCriteria]="$any(currentCriteria$ | async)"
  [isSearchFailed]="isItemsSearchFailed$ | async"
  [trackByFn]="trackByMethod"
  (updateSelection)="updateSelection($event)"
  (showMore)="showMore($event)"
></ca-search-category-list>
<ng-template #rowInstitutionTemplate let-institution="item" let-criteria="criteria">
  <span
    class="link link-main text-truncate w-auto ca-search-link"
    data-automation-id="ca-search-institution-link"
    [innerHTML]="institution.name | fHighlight : criteria : 'fw-bold text-reset'"
    [title]="institution.name"
    (click)="openProfilePreview(institution)"
  ></span>

  <ng-template #tooltipContent>
    <span
      data-automation-id="onesearch-tooltip"
      [innerHTML]="institution.name | fHighlight : criteria : 'body-50 text-base fw-bold'"
    ></span>
  </ng-template>
</ng-template>
