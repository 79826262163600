import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardDashboardComponent } from './card-dashboard.component';
import { CardDashboardBodyDirective } from './directives/dashboard-body';
import { CardDashboardHeaderDirective } from './directives/dashboard-header';

@NgModule({
  declarations: [CardDashboardComponent, CardDashboardHeaderDirective, CardDashboardBodyDirective],
  imports: [CommonModule],
  exports: [CardDashboardComponent, CardDashboardHeaderDirective, CardDashboardBodyDirective]
})
export class FireflyCardDashboardModule {}
