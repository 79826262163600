import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyDisabledHeaderDirective } from './disabled-header-grid-loader.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyDisabledHeaderDirective],
  exports: [FireflyDisabledHeaderDirective]
})
export class FireflyGridDisabledHeaderModule {}
