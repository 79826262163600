import { ChangeDetectorRef, Injectable, OnDestroy, PipeTransform } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Log } from '@capital-access/common/logging';

/**
 * Base implementation of custom impure async pipe.
 */
@Injectable() // https://github.com/angular/angular/issues/37769#issuecomment-650572372
export abstract class BaseAsyncPipe<TOutput, TInput = unknown, TParams extends unknown[] = never[]>
  implements PipeTransform, OnDestroy
{
  private subscription = Subscription.EMPTY;
  private lastValue: TOutput | null = null;
  private lastKey: unknown = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  transform(key: TInput, ...params: TParams): TOutput | null {
    const keyName = this.buildKeyName(key, ...params);

    if (this.lastKey === keyName) {
      return this.lastValue;
    }

    this.subscription.unsubscribe();
    this.lastKey = keyName;

    this.subscription = this.getTransformer(key, ...params).subscribe({
      next: output => {
        this.updateValue(output);
      },
      error: error => {
        Log.error(`Couldn't transform value due to an error ${error}`);
        this.updateValue(null);
      }
    });

    return this.lastValue;
  }

  /**
   * Builds key name from key and params. Default implementation assumes params to be primitives.
   * @param key Current key passed to the pipe
   * @param params Additional parameters of the pipe
   */
  protected buildKeyName(key: TInput, ...params: TParams): unknown {
    return `${key}${params.join()}`;
  }

  abstract getTransformer(key: TInput, ...params: TParams): Observable<TOutput>;

  protected updateValue(value: TOutput | null) {
    this.lastValue = value;
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
