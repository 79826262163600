import { NgModule } from '@angular/core';
import { HighlightPipe } from './pipes/highlight.pipe';
import { HighlightEmailPipe } from './pipes/highlight-email.pipe';
import { HighlightPhonePipe } from './pipes/highlight-phone.pipe';
import { NestedValuePipe } from './pipes/nested-value.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [HighlightPipe, HighlightPhonePipe, HighlightEmailPipe, NestedValuePipe],
  exports: [HighlightPipe, HighlightPhonePipe, HighlightEmailPipe, NestedValuePipe],
  providers: [NestedValuePipe]
})
export class FireflyFormattingModule {}
