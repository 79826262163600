import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingDotsNumber } from '../../../loading-indicators';
import { MessageStatus } from '../models';

@Component({
  selector: 'f-assistant-chat-message',
  templateUrl: './assistant-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'assistantMessage'
})
export class FireflyAssistantChatMessageComponent {
  @Input() authorName!: string;
  @Input() timestamp!: string;
  @Input() messageStatus!: MessageStatus;

  MessageStatus = MessageStatus;
  LoadingDotsNumber = LoadingDotsNumber.One;
}
