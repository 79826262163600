import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { NumberFormattingService } from '../services/number-formatting.service';

@Pipe({
  name: 'caNumber',
  pure: false
})
export class NumberPipe extends BaseAsyncPipe<string, number | null | undefined, [number?]> implements PipeTransform {
  constructor(changeDetectorRef: ChangeDetectorRef, private numberFormattingService: NumberFormattingService) {
    super(changeDetectorRef);
  }

  getTransformer(value?: number | null, fraction?: number) {
    return this.numberFormattingService.formatNumber(value, fraction);
  }
}
