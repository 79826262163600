import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caAbs'
})
export class AbsoluteValuePipe implements PipeTransform {
  transform(value: number | null | undefined) {
    if (value === null || value === undefined) {
      return value;
    }

    return Math.abs(value);
  }
}
