import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { openContactSupportDrawer } from '@capital-access/common/actions';

@Component({
  selector: 'ca-search-error',
  templateUrl: './search-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchErrorComponent {
  constructor(private store: Store) {}

  openDrawer() {
    this.store.dispatch(openContactSupportDrawer());
  }
}
