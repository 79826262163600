import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({ name: 'fHighlight' })
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchString: string, highlightClass = '', skipPunctuationMarks = false): string {
    if (!value || !searchString) {
      return value;
    }

    const escapedSearchText = this.escapeSpecialCharacters(searchString);
    const pattern = this.getPattern(escapedSearchText);
    const regex = new RegExp(pattern, 'gi');

    if (skipPunctuationMarks) {
      return value.replace(regex, match => match.replace(/[^.,]+/g, `<mark class="${highlightClass}">$&</mark>`));
    }

    return value.replace(regex, match => `<mark class="${highlightClass}">${match}</mark>`);
  }

  protected adaptSearchTerm(term: string): string {
    return term;
  }

  // see https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  private escapeSpecialCharacters(value: string): string {
    return value.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
  }

  private getPattern(searchText: string): string {
    const searchTerms = searchText
      .split(' ')
      .filter(t => t.length > 0)
      .map(s => this.adaptSearchTerm(s))
      .sort((a, b) => b.length - a.length);

    return searchTerms.join('|');
  }
}
