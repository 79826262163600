export type ErrorPageType = 'notFound' | 'forbidden' | 'unknown' | 'custom' | 'sessionExpire';
export type ErrorPageScope = 'internal' | 'external';

export const errorPageTitle: Partial<Record<ErrorPageType, string>> = {
  forbidden: 'errorPageForbiddenTitle',
  notFound: 'errorPageNotFoundTitle',
  unknown: 'errorPageUnknownTitle',
  sessionExpire: 'errorPageSessionExpireTitle'
};

export const errorPageDescription: Partial<Record<ErrorPageType, string>> = {
  forbidden: 'errorPageForbiddenDescription',
  notFound: 'errorPageNotFoundDescription',
  unknown: 'errorPageUnknownDescription',
  sessionExpire: 'errorPageSessionExpireDescription'
};
