export enum ProfileCardId {
  Activities = 'profiles-activities-card',
  Coverage = 'profiles-coverage-card',
  EquityPortfolio = 'profiles-equity-portfolio-card',
  Esg = 'profiles-esg-card',
  Funds = 'profiles-funds-card',
  CustomFields = 'profiles-custom-fields-card',
  FundsManaged = 'profiles-funds-managed-card',
  FundEsgWAM = 'profiles-fund-esg-wam-card',
  FundManagers = 'profiles-fund-managers-card',
  Information = 'profiles-information-card',
  InvestmentApproach = 'profiles-investment-approach-card',
  Lists = 'profiles-lists-card',
  Notes = 'profiles-notes-card',
  Ownership = 'profiles-ownership-card',
  People = 'profiles-people-card',
  Targeting = 'profiles-targeting-card',
  UltimateParent = 'profiles-ultimate-parent-card',
  SustainabilityFit = 'profiles-sustainability-fit-card'
}
