<f-list class="border-bottom">
  <f-list-item
    *ngFor="let column of sortableColumns"
    [class.active]="isSelected(column.field)"
    [class.disabled]="context.disabled"
    (click)="changeSorting(column.field, sortingOrder)"
  >
    {{ column.title }}
  </f-list-item>
</f-list>
<f-list [attr.data-automation-id]="'f-list-sorting'">
  <f-list-item
    (click)="changeSorting(selectedField, sortAscending)"
    [class.disabled]="context.disabled"
    [class.active]="sortingOrder === sortAscending"
  >
    {{ ascending$ | async }}
  </f-list-item>
  <f-list-item
    (click)="changeSorting(selectedField, sortDescending)"
    [class.disabled]="context.disabled"
    [class.active]="sortingOrder === sortDescending"
  >
    {{ descending$ | async }}
  </f-list-item>
</f-list>
