export enum PredefinedPeriod {
  last12Month = 'last12Month',
  last3Years = 'last3Years',
  last5Years = 'last5Years'
}

export const predefinedPeriodsArr = Object.values(PredefinedPeriod);

export class CustomPeriod {
  start!: Date;
  end!: Date;

  constructor({ start, end }: { start: Date; end: Date }) {
    this.start = start;
    this.end = end;
  }
}
