<ng-container [caLocalizationScope]="localizationScope">
  <span
    *ngIf="selected && securities.length < 2"
    class="badge badge-neutral fw-bold"
    [ngbTooltip]="securityFullNameFn(selected)"
    [placement]="tooltipPlacement"
  >
    {{ securityShortNameFn(selected) }}
  </span>

  <f-dropdown
    *ngIf="securities.length >= 2 && securities.length <= 5"
    [text]="selected ? securityShortNameFn(selected) : ''"
    [tooltip]="selected ? securityFullNameFn(selected) : ('selectPinnedSecurities' | caLocalize)"
    [btnStyle]="'btn-secondary dropdown-toggle ca-ticker-selector-btn'"
    [mobileMenuTitle]="'searchForSecurityPlaceholder' | caLocalize"
    [menuCssClass]="'ca-security-dropdown-menu'"
    [tooltipPlacement]="tooltipPlacement"
    [placement]="'bottom-left'"
    class="f-drawer-outclick-element"
  >
    <f-list class="overflow-auto">
      <ng-container *ngFor="let security of securities">
        <f-list-item
          fDropdownItem
          *ngIf="security.id"
          [title]="securityFullNameFn(security)"
          [class.active]="security.id === selected?.id"
          (keyup.enter)="onSelect(security)"
          (click)="onSelect(security)"
          class="text-truncate"
        >
          {{ securityFullNameFn(security) }}
        </f-list-item>
      </ng-container>
    </f-list>
  </f-dropdown>

  <f-single-suggester-dropdown
    #suggester
    *ngIf="securities.length > 5"
    [icon]="''"
    [minLength]="1"
    [hideCaret]="false"
    [isOutlineInput]="false"
    [valuePath]="'longName'"
    [categoryPath]="'category'"
    [items]="suggesterSecurities"
    [buttonTooltipPlacement]="tooltipPlacement"
    [btnStyle]="'btn-secondary ca-ticker-selector-btn'"
    [buttonText]="selected ? securityShortNameFn(selected) : ''"
    [buttonTooltip]="selected ? securityFullNameFn(selected) : ''"
    [activeItemValue]="selected ? securityFullNameFn(selected) : ''"
    [placeholder]="'searchForSecurityPlaceholder' | caLocalize"
    [dropdownCssClass]="'suggester-dropdown-menu'"
    (select)="onSelect($any($event))"
    class="f-drawer-outclick-element"
  >
    <ng-template
      #fSuggesterItemTemplate
      let-item="item"
      let-inputValue="inputValue"
      let-isSingleListItem="isSingleListItem"
    >
      <div class="d-flex justify-content-between">
        <span
          *ngIf="item.id; else setSecurities"
          class="text-truncate pe-3"
          innerHTML="{{ item.longName | fHighlight : inputValue }}"
        ></span>
      </div>
      <ng-template #setSecurities>
        <span *ngIf="isSingleListItem" class="link link-primary" (click)="onSetSecurities()"
          >{{ 'setSecurities' | caLocalize }}
        </span>
      </ng-template>
    </ng-template>
  </f-single-suggester-dropdown>
</ng-container>
