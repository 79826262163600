import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySkeletonSmallCardLoaderModule } from '@capital-access/firefly/components';
import { ContactSupportComponent } from './contact-support.component';

@NgModule({
  imports: [CommonModule, FireflySkeletonSmallCardLoaderModule],
  declarations: [ContactSupportComponent],
  exports: [ContactSupportComponent]
})
export class CommonContactSupportModule {}
