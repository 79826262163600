<i
  [container]="'body'"
  fMobilePopover
  [mobilePopoverTitle]="mobilePopoverTitle"
  [ngbPopover]="pointerPopover"
  [triggers]="popoverTriggers"
  [placement]="popoverPlacement"
  [@.disabled]="!animation"
  [@moveArrow]="{
    value: arrowAnimationState,
    params: { left: boundedArrowPosition, duration: animationDuration, delay: animationDelay }
  }"
  data-automation-id="bar-arrow-pointer"
  class="f-i f-i-sm f-i-caret-down f-bar-arrow-pointer"
>
</i>

<f-bar
  [name]="name"
  [value]="value"
  [animation]="animation"
  [thickness]="thickness"
  [percentage]="percentage"
  [barClass]="barClass"
  [iconClass]="iconClass"
  [nameClass]="nameClass"
  [valueClass]="valueClass"
  [containerClass]="containerClass"
  [animationDelay]="animationDelay"
  [animationDuration]="animationDuration"
  [popoverTriggers]="popoverTriggers"
  [popoverPlacement]="popoverPlacement"
  [popover]="popover"
>
</f-bar>
