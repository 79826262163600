import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyListModule, FireflySuggesterModule } from '@capital-access/firefly/components';
import { MultipleMasterSuggesterComponent } from './multiple-master-suggester.component';

@NgModule({
  imports: [CommonModule, FireflySuggesterModule, FireflyListModule],
  declarations: [MultipleMasterSuggesterComponent],
  exports: [MultipleMasterSuggesterComponent]
})
export class MultipleMasterSuggesterModule {}
