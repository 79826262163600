import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { LOCALIZATION_KEY } from '../../constants';

@Component({
  selector: 'ca-modify-report-drawer-footer',
  templateUrl: './modify-report-drawer-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModifyReportDrawerFooterComponent {
  localizationScope = LOCALIZATION_KEY;
  @Input() disableSaving = false;
  @Input() createReportTitle!: string;
  @Output() closeDrawer = new EventEmitter();
  @Output() createReport = new EventEmitter();

  cancel() {
    this.closeDrawer.emit();
  }

  onCreateReport() {
    this.createReport.emit();
  }
}
