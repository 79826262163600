<div class="d-flex justify-content-center">
  <div class="border-top pt-5 ca-contact-support-container">
    <div class="row">
      <ng-container *ngIf="(loadCsAdvisorStatus$ | async) === loadStatus.Loading">
        <div class="col-12 col-sm-4 pe-4 mb-5 mb-sm-0">
          <f-skeleton-small-card-with-text></f-skeleton-small-card-with-text>
        </div>
        <div class="col">
          <f-skeleton-small-card-with-text></f-skeleton-small-card-with-text>
        </div>
      </ng-container>

      <ng-container *ngIf="csAdvisorData$ | async as csAdvisorData; else noCsAdvisorData">
        <div
          data-automation-id="client-service-info"
          class="col-12 col-sm-5 col-md-4 pe-4 mb-5 mb-sm-0 text-break"
          *ngIf="csAdvisorData.phoneNumber || csAdvisorData.email"
        >
          <h4 class="heading-20 mb-2 mb-sm-4">Client Engagement Advisor</h4>
          <div *ngIf="csAdvisorData.firstName || csAdvisorData.lastName" class="fw-bold mb-2">
            {{ csAdvisorData.firstName }} {{ csAdvisorData.lastName }}
          </div>
          <div *ngIf="csAdvisorData.phoneNumber" class="mb-2">
            <a class="link link-main" href="tel:{{ csAdvisorData.phoneNumber }}">{{ csAdvisorData.phoneNumber }}</a>
          </div>
          <div *ngIf="csAdvisorData.email">
            <a class="link link-primary" href="mailto:{{ csAdvisorData.email }}">{{ csAdvisorData.email }}</a>
          </div>
        </div>
      </ng-container>

      <ng-template #noCsAdvisorData>
        <div
          data-automation-id="client-service-error"
          class="col-12 col-sm-5 col-md-4 pe-4 mb-5 mb-sm-0 text-break"
          *ngIf="(loadCsAdvisorStatus$ | async) === loadStatus.Failed"
        >
          <h4 class="heading-20 mb-2 mb-sm-4">Client Engagement Advisor</h4>
          <div class="mb-2 fw-bold">Something went wrong</div>
          <div class="mb-2">Please reload the page</div>
          <span tabindex="0" class="link link-primary" (click)="reload()">Reload</span>
        </div>
      </ng-template>

      <div class="col" *ngIf="(loadCsAdvisorStatus$ | async) !== loadStatus.Loading">
        <h4 class="heading-20 mb-2 mb-sm-4" data-automation-id="contact-support-label">Client Support</h4>
        <div class="row">
          <div class="col-12 col-md-7">
            <div class="mb-2">
              <span class="text-muted me-2" data-automation-id="contact-support-americas-title">Americas:</span>
              <a
                class="link link-main"
                href="tel:{{ settings.phones.americas }}"
                data-automation-id="contact-support-americas-phone"
                >{{ settings.phones.americas }}</a
              >
            </div>
            <div class="mb-2">
              <span class="text-muted me-2" data-automation-id="contact-support-europe-title">Europe:</span>
              <a
                class="link link-main"
                href="tel:{{ settings.phones.europe }}"
                data-automation-id="contact-support-europe-phone"
                >{{ settings.phones.europe }}</a
              >
            </div>
          </div>
          <div class="col">
            <div class="mb-2">
              <span class="text-muted me-2" data-automation-id="contact-support-japan-title">Japan:</span>
              <a
                class="link link-main"
                href="tel:{{ settings.phones.japan }}"
                data-automation-id="contact-support-japan-phone"
                >{{ settings.phones.japan }}</a
              >
            </div>
            <div class="mb-2">
              <span class="text-muted me-2" data-automation-id="contact-support-asia-title">Asia Pacific:</span>
              <a
                class="link link-main"
                href="tel:{{ settings.phones.asiaPacific }}"
                data-automation-id="contact-support-asia-phone"
                >{{ settings.phones.asiaPacific }}</a
              >
            </div>
          </div>
        </div>
        <div class="col">
          <span class="text-muted me-2" data-automation-id="contact-support-email-title">Email:</span>
          <a
            class="link link-primary"
            href="mailTo:{{ settings.email }}"
            data-automation-id="contact-support-email-value"
          >
            {{ settings.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
