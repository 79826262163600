import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { syncCalendars } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { REDIRECT_PATH, SyncVendor } from '@capital-access/events/calendar-sync/common';
import {
  CALENDAR_SYNC_SETTINGS_MODULE_ID,
  LOAD_CALENDAR_SYNC_SETTINGS_MODULE
} from './calendar-sync-settings-module-id.model';

@Injectable()
export class CalendarSyncSettingsEffects {
  syncCalendars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncCalendars),
      switchMap(({ vendor }) =>
        this.load().pipe(map(moduleRef => moduleRef.instance.init(vendor && SyncVendor[vendor])))
      )
    )
  );

  redirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      filter(({ payload }) => payload.event.url === REDIRECT_PATH),
      switchMap(() => this.load()),
      map(moduleRef => moduleRef.instance.openSettings())
    )
  );

  private load() {
    return this.commonLazyLoadingService.loadModule(
      CALENDAR_SYNC_SETTINGS_MODULE_ID,
      LOAD_CALENDAR_SYNC_SETTINGS_MODULE
    );
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private commonLazyLoadingService: CommonLazyLoadingService
  ) {}
}
