import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { loadUserProfileSuccess } from '@capital-access/common/user';
import { PendoService } from './pendo.service';

@Injectable()
export class PendoEffects {
  onLoadUserProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserProfileSuccess),
        tap(action => this.pendoService.initialize(action.userProfile.user, action.userProfile.account))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private pendoService: PendoService) {}
}
