import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { getCurrencyRateResolved } from '..';

@Injectable({
  providedIn: 'root'
})
export class CommonCurrencyGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate() {
    return this.store.select(getCurrencyRateResolved).pipe(
      filter(resolved => !!resolved),
      map(() => true),
      take(1)
    );
  }
}
