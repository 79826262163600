export const barClasses = [
  'f-grouped-bar-1',
  'f-grouped-bar-2',
  'f-grouped-bar-3',
  'f-grouped-bar-4',
  'f-grouped-bar-5',
  'f-grouped-bar-6',
  'bg-primary-800',
  'bg-primary-700',
  'bg-primary-600',
  'bg-primary-500'
];

export const stackedBarClasses = [
  'bg-accent-3-700',
  'bg-accent-3-500',
  'bg-accent-5-300',
  'bg-primary-600',
  'bg-accent-6-300',
  'bg-accent-3-300',
  'bg-accent-6-600',
  'bg-accent-6-400',
  'bg-accent-2-400',
  'bg-accent-1-300'
];
