import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyStripesMaskComponent } from './stripes-mask.component';
import { FireflyStripesPatternComponent } from './stripes-pattern.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyStripesPatternComponent, FireflyStripesMaskComponent],
  exports: [FireflyStripesPatternComponent, FireflyStripesMaskComponent]
})
export class FireflyChartPatternsModule {}
