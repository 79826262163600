import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { userAuthenticated } from '@capital-access/common/actions';
import { UserRepository } from '../user.repository';
import { loadUserProfileFail, loadUserProfileSuccess } from './user.actions';

@Injectable()
export class CommonUserEffects {
  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userAuthenticated),
      switchMap(() =>
        this.repository.getUserProfile().pipe(
          map(userProfile => loadUserProfileSuccess({ userProfile })),
          catchError(error => of(loadUserProfileFail({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private repository: UserRepository) {}
}
