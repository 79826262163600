<f-inner-error
  data-automation-id="drawer-error"
  [caLocalizationScope]="localizationScope"
  [caption]="'somethingWentWrongTitle' | caLocalize"
  [info]="'somethingWentWrongMessage' | caLocalize"
  [rootCssClass]="rootCssClass"
>
  <span
    class="inner-error-link"
    data-automation-id="contact-support-link"
    fInnerErrorActions
    (click)="onContactSupportClick()"
    >{{ 'contactSupportTitle' | caLocalize }}</span
  >
</f-inner-error>
