<span
  *ngIf="isValidValue"
  class="ca-simple-change-badge"
  [ngClass]="badgeClass"
  data-automation-id="simple-value-change-badge"
  [title]="showTooltip ? formattedValue : ''"
>
  <span class="ca-simple-change-badge-text" [ngClass]="{ 'text-truncate': valueChange !== 0, 'fw-bold': isBold }">
    {{ formattedValue }}
  </span>
  <i class="f-i" [ngClass]="[iconClass, iconSizeClass]"></i>
</span>
