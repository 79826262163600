import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { of } from 'rxjs';
import { LoadStatus } from '@capital-access/common/utils';
import { SpinnerSize } from '../loading-indicators/models/spinner-size.enum';
import { FireflyNumberFormattingService } from '../utils/formatting/firefly-number-formatting.service';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';

@Component({
  selector: 'f-results-total-count',
  templateUrl: './results-total-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyResultsTotalCountComponent implements OnInit {
  constructor(
    @Optional() private localizationService: FireflyLocalizationService,
    @Optional() private numberFormattingService: FireflyNumberFormattingService
  ) {}
  SpinnerSize = SpinnerSize;
  LoadStatus = LoadStatus;
  totalResultsTitle$ = of('Results');
  filterResultsLoadFailedLabel$ = of('Please try your action again. If the problem persists, reach out to support.');
  tryAgainLabel$ = of('Try Again');

  get totalCount$() {
    if (!this.numberFormattingService) return of(this.totalCount.toLocaleString());
    return this.numberFormattingService.formatNumber(this.totalCount);
  }

  @Input() totalCount!: number;
  @Input() totalCountLoadStatus!: LoadStatus;

  @Output() tryAgainClick = new EventEmitter();

  ngOnInit() {
    if (!this.localizationService) return;
    this.tryAgainLabel$ = this.localizationService.localize('tryAgainLabel', {});
    this.totalResultsTitle$ = this.localizationService.localize('totalResultsTitle', {});
    this.filterResultsLoadFailedLabel$ = this.localizationService.localize('filterResultsLoadFailedLabel', {});
  }

  onTryAgainClick() {
    this.tryAgainClick.emit();
  }
}
