import { createAction, props } from '@ngrx/store';
import { Security } from '../models/security';

export const loadSecurities = createAction('[Securities] Load', props<{ ids: number[] }>());
export const loadSecuritiesSuccess = createAction(
  '[Securities] Load Securities Success',
  props<{ securities: Security[] }>()
);
export const loadSecuritiesFail = createAction(
  '[Securities] Load Fail',
  props<{ failedIds: number[]; error: unknown }>()
);
