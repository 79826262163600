import { LocalizedString } from '@capital-access/common/localization';
import { isString } from '@capital-access/common/utils';
import { ResolvedTitlePart, TitlePart, TitlePartObject, TitlePartString } from './title.models';

export function isTitlePartObject(arg: TitlePart): arg is TitlePartObject {
  const obj = arg as { value: unknown };

  return typeof obj?.value === 'object' || typeof obj?.value === 'string';
}

export function fromTitlePart(source: TitlePart): ResolvedTitlePart[] {
  if (isTitlePartObject(source)) {
    const resolvedTitleParts = fromTitlePartString(source.value);
    if (resolvedTitleParts.length > 0) {
      // Update only first title part in sequence to use override from source
      resolvedTitleParts[0].override = source.override;
    }

    return resolvedTitleParts;
  } else {
    return fromTitlePartString(source);
  }
}

export function fromTitlePartString(source: TitlePartString) {
  if (isString(source) || source instanceof LocalizedString) {
    return [{ value: source, override: false }];
  } else {
    return source.map(value => ({ value, override: false }));
  }
}

/**
 * Skip all title parts up until last part with `override === true`
 * @param source
 */
export function skipOverridenTitleParts(source: ResolvedTitlePart[]) {
  let lastOverrideIdx = -1;
  for (let i = source.length - 1; i >= 0; i--) {
    if (source[i].override) {
      lastOverrideIdx = i;
      break;
    }
  }

  if (lastOverrideIdx >= 0) {
    return source.slice(lastOverrideIdx);
  }

  return source;
}
