import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import type { ScaleLinear } from 'd3-scale';
import type { ChartDimensions } from '..';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-charts-y-axis]',
  template: `
    <svg:g class="f-axis" [attr.transform]="transform">
      <line *ngIf="showDomain" [attr.y2]="domainYOffset" class="f-axis-domain" y1="0" />
      <g
        f-charts-y-axis-ticks
        *ngIf="yScale"
        [ticks]="ticks"
        [scale]="yScale"
        [position]="axisPosition"
        [truncateTicks]="truncateTicks"
        [maxTickLength]="maxTickLength"
        [maxTicksCount]="maxTicksCount"
        [tickFormatting]="tickFormatting"
        [showGridLines]="showGridLines"
        [showTicksLabels]="showTicksLabels"
        [integerLabels]="integerTickLabels"
        [gridLineWidth]="dimensions.width"
        [condensedAxisOnMobile]="condensedAxisOnMobile"
        [condensedOnDesktop]="condensedOnDesktop"
        [height]="dimensions.height - labelYOffset"
        (dimensionsChanged)="emitYAxisDimensions($event)"
      />
      <text
        #label
        [ngClass]="{ 'd-none': !showLabel }"
        [attr.text-anchor]="labelAnchor"
        [attr.x]="labelXOffset"
        [attr.y]="-labelYOffset"
        class="f-charts-axis-label"
      >
        {{ labelText }}
      </text>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyYAxisComponent implements OnChanges {
  @Input() ticks!: number[];
  @Input() integerTickLabels = false;
  @Input() dimensions!: ChartDimensions;
  @Input() yScale!: ScaleLinear<number, number>;
  @Input() axisPosition: 'left' | 'right' = 'left';
  @Input() tickFormatting!: (d: unknown) => string;
  @Input() truncateTicks = true;
  @Input() maxTickLength = 16;
  @Input() maxTicksCount = 15;
  @Input() showGridLines = false;
  @Input() showTicksLabels = true;
  @Input() showDomain = false;
  @Input() showLabel = true;
  @Input() labelText!: string;
  @Input() labelYOffset = 30;
  @Input() condensedOnDesktop = false;
  @Input() condensedAxisOnMobile = true;

  @ViewChild('label', { read: ElementRef, static: true }) label!: ElementRef;

  @Output() dimensionsChanged = new EventEmitter();

  labelAnchor = 'start';
  domainYOffset = 0;
  labelXOffset = 0;
  transform = '';

  ngOnChanges() {
    this.update();
  }

  update() {
    this.labelAnchor = this.axisPosition === 'left' ? 'start' : 'end';
    this.domainYOffset = this.dimensions.height;

    if (this.axisPosition === 'right') {
      this.transform = `translate(${this.dimensions.width - 1} , 0)`;
    } else {
      this.transform = `translate(1, 0)`;
    }
  }

  emitYAxisDimensions({ width }: { width: number }) {
    const labelWidth = this.label.nativeElement.getBoundingClientRect().width || 0;
    const labelHeight = this.label.nativeElement.getBoundingClientRect().height || 0;
    this.labelXOffset = this.axisPosition === 'left' ? -width : width;
    this.dimensionsChanged.emit({ width, label: { width: labelWidth, height: labelHeight / 2 } });
  }
}
