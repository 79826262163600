import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'f-suggester-list-item',
  templateUrl: './suggester-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySuggesterListItemComponent {
  @Input() item!: Record<string, unknown>;
  @Input() valuePath!: string;
  @Input() linkPath?: string;
  @Input() inputValue!: string;
  @Input() isSingleListItem = false;
  @Input() suggesterItemTemplate?: TemplateRef<unknown>;

  @Output() itemClick = new EventEmitter<Record<string, unknown>>();

  @HostListener('click') onClick() {
    this.itemClick.emit(this.item);
  }
}
