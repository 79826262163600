import { SearchCategory } from './search-category.enum';

export interface ResultCategory {
  priority: number;
  name: SearchCategory;
  localizationKey: string;
}

export interface ResultCategoryMetadata extends ResultCategory {
  hasItems: boolean;
}

export const resultsConfig: ResultCategory[] = [
  { priority: 0, name: SearchCategory.Institutions, localizationKey: 'institutions' },
  { priority: 1, name: SearchCategory.Contacts, localizationKey: 'contacts' },
  { priority: 2, name: SearchCategory.Funds, localizationKey: 'funds' },
  { priority: 3, name: SearchCategory.Events, localizationKey: 'events' }
];
