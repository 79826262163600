import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { FireflyMobileCalendarComponent } from './mobile-calendar.component';

@NgModule({
  imports: [CommonModule, DateInputsModule],
  declarations: [FireflyMobileCalendarComponent],
  exports: [FireflyMobileCalendarComponent]
})
export class FireflyMobileCalendarModule {}
