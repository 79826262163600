import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  private static settings: unknown | null = null;

  static async load(): Promise<void> {
    const response = await fetch('./settings/settings.json');
    const settings = await (response.json() as Promise<unknown>);

    AppSettingsService.settings = settings;
  }

  /**
   * @description Get typed settings from setting.json
   * @example
   * class Settings {
   *   oidc: OidcSettings
   * }
   *
   * class OidcSettings {
   *   clientId: string;
   * }
   *
   * ...
   *
   * this.settings.getSettings<Settings>().oidc.clientId
   */
  getSettings<T>(): T {
    if (AppSettingsService.settings) {
      return AppSettingsService.settings as T;
    }

    throw new Error(`Settings are not initialized.`);
  }
}
