import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { HttpStatusCode } from '@capital-access/common/http';
import { FORBIDDEN_PAGE_ROUTE, INTERNAL_SERVER_ERROR_PAGE_ROUTE } from '@capital-access/common/utils';
import { getUserLoaded, getUserLoadError } from './+state/user.selectors';

@Injectable({ providedIn: 'root' })
export class UserGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store,
    private router: Router,
    @Inject(FORBIDDEN_PAGE_ROUTE) private forbiddenPageRoute: string,
    @Inject(INTERNAL_SERVER_ERROR_PAGE_ROUTE) private internalErrorPageRoute: string
  ) {}

  canActivate() {
    return combineLatest([this.store.select(getUserLoaded), this.store.select(getUserLoadError)]).pipe(
      map(([loaded, error]) => {
        if (error && error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.FORBIDDEN) {
            this.router.navigate([this.forbiddenPageRoute]);
          } else {
            this.router.navigate([this.internalErrorPageRoute]);
          }
        }

        return loaded;
      }),
      filter(x => x),
      take(1)
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
