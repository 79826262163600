<ng-container *ngIf="mobile; else desktop">
  <ng-container *ngFor="let item of allItems; let last = last">
    <span>{{ item }}</span>
    <span *ngIf="!last">, </span>
  </ng-container>
</ng-container>

<ng-template #desktop>
  <div class="d-flex">
    <ng-container *ngIf="firstItem">
      <span *ngIf="isNumber" class="text-desktop-truncate">
        {{ +firstItem | caCustomFieldNumber }}
      </span>
      <span *ngIf="!isNumber" class="text-desktop-truncate">
        {{ firstItem }}
      </span>
    </ng-container>

    <span
      triggers="click"
      [autoClose]="true"
      placement="bottom-right"
      popoverClass="popover-with-scroll"
      [ngbPopover]="popoverTemplate"
      class="link link-primary ms-1"
      data-automation-id="show-all"
      *ngIf="allItems.length > 1"
    >
      {{ showAllTitle$ | async }}
    </span>
  </div>
</ng-template>

<ng-template #popoverTemplate>
  <ul class="list-unstyled mb-0">
    <li class="list-group-item" *ngFor="let item of allItems">
      {{ item }}
    </li>
  </ul>
</ng-template>
