import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

// TODO: add (click) event to input
@Component({
  selector: 'f-list-item-checkbox',
  templateUrl: './list-item-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyListItemCheckboxComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() checkOnEnter = true;
  @Input() checkboxTitle: string | null = '';
  @Input() checkboxDisabled = false;
  @Input() indeterminate = false;
  @Input() hasSelectedStyles = false;
  @Input() itemCheckboxClass = '';
  @Input() hoverable = true;
  @Input() tabable = true;

  @Output() toggle = new EventEmitter();

  @HostBinding('class') class = 'f-list-item-checkbox';

  @HostListener('keyup.enter', ['$event'])
  onKeyUp($event: KeyboardEvent) {
    if (!this.checkOnEnter) return;
    $event.stopImmediatePropagation();
    this.flipCheckboxValue($event);
  }

  flipCheckboxValue($event?: Event) {
    if (this.disabled || this.checkboxDisabled) return;
    if ($event instanceof PointerEvent) (document.activeElement as HTMLElement)?.blur();
    if ($event?.defaultPrevented) return;
    this.checked = !this.checked;
    this.toggle.emit();
  }
}
