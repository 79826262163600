import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadStatus } from '@capital-access/common/utils';
import { State } from './reducer';
import { COMMON_CURRENCY_FEATURE_KEY } from './state';

const getCurrencyState = createFeatureSelector<State>(COMMON_CURRENCY_FEATURE_KEY);

export const getCurrencyRate = createSelector(getCurrencyState, x => x.rate);
export const getCurrencyLoadStatus = createSelector(getCurrencyState, x => x.loadStatus);

export const getCurrencyRateResolved = createSelector(
  getCurrencyLoadStatus,
  loadStatus => loadStatus == LoadStatus.Loaded || loadStatus == LoadStatus.Failed
);
