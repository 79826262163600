import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AutocompletePrediction } from '@capital-access/common/location-search';
import { Address } from '@capital-access/profiles/common';
import { CRM_COMMON_LOCALIZATION_SCOPE, CRM_FORM_MAX_LENGTH } from '../../model/constants';
import { SearchAddressService } from '../../services/search-address.service';

@Component({
  templateUrl: './crm-form-address-input.component.html',
  selector: 'ca-crm-form-address-input',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrmFormAddressInputComponent {
  localizationScope = CRM_COMMON_LOCALIZATION_SCOPE;

  @Input() address!: FormGroup;
  @Input() addressTitle!: string;
  @Input() disabled?: boolean;
  @Output() selectAddress = new EventEmitter<string>();

  maxLength = CRM_FORM_MAX_LENGTH.addressLine2;
  isAddressDetailsShowed = false;
  searchAddressItems$: Observable<AutocompletePrediction[]> = of([]);

  get inputAddress() {
    return this.address.get('inputAddress') as FormControl;
  }

  get addressLine2() {
    return this.address.get('addressLine2') as FormControl;
  }

  constructor(private searchAddressService: SearchAddressService, private cdr: ChangeDetectorRef) {}

  showAddressDetails() {
    this.isAddressDetailsShowed = true;
  }

  filterSearchAddressItems(searchText: string) {
    this.searchAddressItems$ = this.searchAddressService.searchAddress(searchText);
  }

  onSelectionAddressChange(event: unknown) {
    const placeId = (event as AutocompletePrediction).placeId;

    this.searchAddressService.getAddressDetails(placeId).subscribe(place => {
      const streetTypes = ['street_number', 'route'];

      const streetComponents = place.addressComponents.filter(component =>
        component.types.some(type => streetTypes.includes(type))
      );

      const inputAddress = place.formattedAddress.includes(place.name)
        ? place.formattedAddress
        : `${place.name}, ${place.formattedAddress}`;

      const addressCity =
        place.addressComponents.find(component => component.types.some(type => type == 'locality'))?.longName || '';

      const addressCountry =
        place.addressComponents.find(component => component.types.some(type => type == 'country'))?.longName || '';

      const stateInfo = place.addressComponents.find(component =>
        component.types.some(type => type == 'administrative_area_level_1')
      );

      const zipCode =
        place.addressComponents.find(component => component.types.some(type => type == 'postal_code'))?.longName || '';

      let addressLine1 = '';

      if (streetComponents.length) {
        const shouldSkipName = !!streetComponents.find(component => place.name?.includes(component.shortName));

        addressLine1 =
          (shouldSkipName ? '' : `${place.name}, `) + streetComponents.map(component => component.shortName).join(', ');
      } else if (!place.name.includes(addressCity)) {
        addressLine1 = place.name;
      }

      this.address.patchValue(
        {
          inputAddress,
          addressLine1,
          addressCity,
          addressCountry,
          state: stateInfo?.longName || '',
          stateCode: stateInfo?.shortName || '',
          zipCode
        },
        { emitEvent: false }
      );
      this.address.get('inputAddress')?.markAsPristine();
      this.selectAddress.emit(inputAddress);
    });
  }

  onOutClickAddress() {
    if (this.inputAddress.dirty) {
      this.resetAddress();
      this.selectAddress.emit('');
      this.cdr.detectChanges();
    }
  }

  resetAddress() {
    this.address.patchValue({
      inputAddress: '',
      addressLine1: '',
      place_id: '',
      addressCity: '',
      addressCountry: '',
      state: '',
      stateCode: '',
      zipCode: ''
    });
  }

  injectInputs(address?: Address, shouldPatchAddressLine2 = true) {
    if (!address) return;

    this.address.patchValue(
      {
        inputAddress: address.address,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressCity: address.addressCity,
        addressCountry: address.addressCountry,
        state: address.state,
        stateCode: address.stateCode,
        zipCode: address.zipCode
      },
      { emitEvent: false }
    );

    if (address.addressLine2 && shouldPatchAddressLine2) {
      this.showAddressDetails();
      this.cdr.markForCheck();
    }
  }
}
