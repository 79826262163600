import { Action, createReducer, on } from '@ngrx/store';
import {
  completeAuthorizeWithPopUp,
  failedAuthorizeWithPopUp,
  initAuthorizeWithPopUp
} from '../+store/auth-popup.actions';
import { userDataLoaded } from './auth.actions';
import { AuthorizeWithPopUpStatus } from './models/authorize-pop-up-status.model';
import { UserAuthData } from './models/user-data.model';

export const AUTH_FEATURE_KEY = 'common.auth';

export interface State {
  userData: UserAuthData | null;
  authorizeWithPopUpStatus: AuthorizeWithPopUpStatus;
}

export const initialState: State = {
  userData: null,
  authorizeWithPopUpStatus: AuthorizeWithPopUpStatus.Pristine
};

const authReducer = createReducer(
  initialState,
  on(userDataLoaded, (state, action) => ({ ...state, userData: action.userData })),
  on(initAuthorizeWithPopUp, (state, _) => ({
    ...state,
    authorizeWithPopUpStatus: AuthorizeWithPopUpStatus.Initiated
  })),
  on(completeAuthorizeWithPopUp, (state, _) => ({
    ...state,
    authorizeWithPopUpStatus: AuthorizeWithPopUpStatus.Completed
  })),
  on(failedAuthorizeWithPopUp, (state, action) => ({
    ...state,
    authorizeWithPopUpStatus: AuthorizeWithPopUpStatus.Failed,
    error: action.error
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
