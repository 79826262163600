<!-- ToDo: remove 'staticMobileTemplate' in case flexible navigation implementation for all applications -->
<ng-container *ngIf="flexibleSideNav; else staticMobileTemplate">
  <div
    *ngrxLet="panelExpanded$ as expanded"
    class="h-100"
    [ngClass]="{ 'd-block': expanded, 'd-none d-md-block': !expanded }"
  >
    <div class="h-100 ca-width" *ngIf="navigationMap">
      <aside
        class="ca-side-nav ca-side-nav-flexible"
        [class.ca-side-nav-expanded]="expanded"
        (clickOutside)="onClickOutside()"
        [clickOutsideEnabled]="expanded"
        [exclude]="'[data-menu-button]'"
        (click)="toggleExpand(!expanded)"
      >
        <div #sideNavContainer class="d-flex flex-column ca-side-nav-content">
          <div
            *ngIf="expanded"
            class="ca-logo-nav-item d-md-none border-bottom"
            @fadeSimpleItem
            (click)="logoItem.navigateHome()"
          >
            <ca-logo #logoItem [plainView]="true" [titleCssClass]="'fw-bold lh-1 align-self-end'"></ca-logo>
          </div>

          <div *ngIf="expanded" class="d-md-none" @fadeSimpleItem (click)="preventClose($event)">
            <ng-container *ngFor="let navItem of navigationMap?.mobileToggle; let i = index">
              <ca-toggle-nav-item class="w-100" [navItem]="navItem" [index]="i"></ca-toggle-nav-item>
            </ng-container>
          </div>

          <div [class.border-bottom]="isMobile" [class.pb-3]="isMobile">
            <ng-container *ngFor="let navItem of navigationMap?.top">
              <ca-link-nav-item
                *ngIf="navItem.enabled"
                [navItem]="navItem"
                [mini]="!expanded"
                [active]="navItem | caIsActiveRoute | async"
                (itemClick)="onItemClick($event)"
              ></ca-link-nav-item>
            </ng-container>
          </div>

          <div *ngIf="navigationMap?.mobileEntity?.length" class="d-sm-none border-bottom pb-3">
            <ng-container *ngFor="let navItem of navigationMap.mobileEntity">
              <ca-action-nav-item [navItem]="navItem" [sharedNavItem]="true" [mini]="!expanded"></ca-action-nav-item>
            </ng-container>
          </div>

          <div *ngIf="navigationMap?.mobileUser?.length" class="d-sm-none pb-3">
            <ng-container *ngFor="let navItem of navigationMap.mobileUser">
              <ca-action-nav-item
                *ngIf="navItem.action; else linkUserItem"
                [navItem]="navItem"
                [sharedNavItem]="true"
                [mini]="!expanded"
              ></ca-action-nav-item>
              <ng-template #linkUserItem>
                <ca-link-nav-item
                  *ngIf="navItem.link"
                  [sharedNavItem]="true"
                  [navItem]="navItem"
                  [mini]="!expanded"
                  [active]="navItem | caIsActiveRoute | async"
                  (itemClick)="onItemClick($event)"
                ></ca-link-nav-item>
              </ng-template>
            </ng-container>
          </div>

          <div class="mt-auto">
            <div [class.border-top]="isMobile">
              <ng-container *ngFor="let navItem of navigationMap?.bottom">
                <ca-action-nav-item
                  [navItem]="navItem"
                  [mini]="!expanded"
                  *ngIf="!navItem.featureToggleKey; else conditionalNavItem"
                ></ca-action-nav-item>
                <ng-template #conditionalNavItem>
                  <ca-action-nav-item
                    [navItem]="navItem"
                    [mini]="!expanded"
                    *caFeatureToggle="navItem.featureToggleKey!"
                  ></ca-action-nav-item>
                </ng-template>
              </ng-container>
            </div>
            <div [class.border-top]="isMobile">
              <ca-bottom-nav-item
                [mini]="!expanded"
                (click)="toggleExpand(!expanded, $event)"
                (keyup.enter)="toggleExpand(!expanded, $event)"
              ></ca-bottom-nav-item>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </div>
</ng-container>

<ng-template #staticMobileTemplate>
  <div
    *ngrxLet="panelExpanded$ as expanded"
    class="h-100"
    [ngClass]="{ 'd-block': expanded, 'd-none d-md-block': !expanded }"
  >
    <div class="h-100 ca-width" *ngIf="navigationMap">
      <aside
        class="ca-side-nav"
        [class.ca-side-nav-expanded]="expanded"
        [style.width.px]="expanded ? 240 : 48"
        (clickOutside)="onClickOutside()"
        [clickOutsideEnabled]="expanded"
        [exclude]="'[data-menu-button]'"
        (click)="toggleExpand(!expanded)"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <ng-container *ngFor="let navItem of navigationMap?.top">
              <ca-link-nav-item
                *ngIf="navItem.enabled"
                [navItem]="navItem"
                [mini]="!expanded"
                [active]="navItem | caIsActiveRoute | async"
                (itemClick)="onItemClick($event)"
              ></ca-link-nav-item>
            </ng-container>
          </div>
          <div>
            <ng-container *ngFor="let navItem of navigationMap?.bottom">
              <ca-action-nav-item
                [navItem]="navItem"
                [mini]="!expanded"
                *ngIf="!navItem.featureToggleKey; else conditionalNavItem"
              ></ca-action-nav-item>
              <ng-template #conditionalNavItem>
                <ca-action-nav-item
                  [navItem]="navItem"
                  [mini]="!expanded"
                  *caFeatureToggle="navItem.featureToggleKey!"
                ></ca-action-nav-item>
              </ng-template>
            </ng-container>
            <ca-bottom-nav-item
              [mini]="!expanded"
              (click)="toggleExpand(!expanded, $event)"
              (keyup.enter)="toggleExpand(!expanded, $event)"
            ></ca-bottom-nav-item>
          </div>
        </div>
      </aside>
    </div>
  </div>
</ng-template>
