import { createAction, props } from '@ngrx/store';
import { SearchCategory, SearchEntity, SearchResults, WithErrors } from './models';

export const criteriaChanged = createAction('[Search] Criteria Changed', props<{ criteria: string }>());
export const filterChanged = createAction('[Search] Filter Changed', props<{ filter: SearchCategory | null }>());
export const loadSearchResultsSuccess = createAction(
  '[Search] Load Search Results Success',
  props<{ results: SearchResults & WithErrors }>()
);
export const viewBucketsChanged = createAction('[Search] View Buckets Changed', props<{ buckets: number[] }>());
export const searchStarted = createAction('[Search] Started', props<{ criteria: string }>());
export const loadSearchResultsFailure = createAction(
  '[Search] Load Search Results Failure',
  props<{ error: unknown }>()
);
export const toggleFilterPanel = createAction('[Search] Toggle Filter panel');
export const selectEntity = createAction('[Search] Select entity', props<{ entity: SearchEntity }>());
export const unselectEntity = createAction('[Search] Unselect entity', props<{ entity: SearchEntity }>());
export const unselectAll = createAction('[Search] Unselect all entities');
export const close = createAction('[Search] Close');
export const reset = createAction('[Search] Reset');
