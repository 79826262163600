import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@progress/kendo-angular-utils';
import { FireflySpinnerModule } from '../loading-indicators/spinner/spinner.module';
import { FireflyDraggableListItemComponent } from './draggable/draggable-list-item/draggable-list-item.component';
import { FireflyDraggableNestedListComponent } from './draggable/draggable-nested-list/draggable-nested-list.component';
import { FireflyDraggableListComponent } from './draggable/firefly-draggable-list.component';
import { FireflyListDirective } from './list/list.directive';
import { FireflyListItemDirective } from './list-item/list-item.directive';
import { FireflyListItemCheckboxComponent } from './list-item-checkbox/list-item-checkbox.component';
import { FireflyListItemConcisedComponent } from './list-item-concised/list-item-concised.component';
import { FireflyToggleListItemComponent } from './toggle-list-item/toggle-list-item.component';

const components = [
  FireflyListDirective,
  FireflyListItemDirective,
  FireflyListItemCheckboxComponent,
  FireflyToggleListItemComponent,
  FireflyListItemConcisedComponent,
  FireflyDraggableListItemComponent,
  FireflyDraggableListComponent,
  FireflyDraggableNestedListComponent
];

@NgModule({
  imports: [CommonModule, FireflySpinnerModule, UtilsModule],
  declarations: [...components],
  exports: [...components]
})
export class FireflyListModule {}
