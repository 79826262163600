import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflySkeletonLoaderModule } from '../skeleton-loader.module';
import { FireflySkeletonSmallCardWithTextComponent } from './skeleton-small-card-with-text.component';
import { FireflySkeletonSmallCardWithTextAndBadgesComponent } from './skeleton-small-card-with-text-and-badges.component';

const components = [FireflySkeletonSmallCardWithTextAndBadgesComponent, FireflySkeletonSmallCardWithTextComponent];

@NgModule({
  imports: [CommonModule, FireflySkeletonLoaderModule],
  declarations: [...components],
  exports: [...components]
})
export class FireflySkeletonSmallCardLoaderModule {}
