import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonStandingDataModule } from '@capital-access/common/standing-data';
import { GlobalizationCurrencyEffects } from './+state/effects';
import { currencyReducer } from './+state/reducer';
import { GLOBALIZATION_CURRENCY_FEATURE_KEY } from './+state/state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(GLOBALIZATION_CURRENCY_FEATURE_KEY, currencyReducer),
    EffectsModule.forFeature([GlobalizationCurrencyEffects]),
    CommonStandingDataModule
  ]
})
export class GlobalizationCurrencyModule {}
