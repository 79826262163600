import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { SpinnerSize } from '../../loading-indicators';

@Component({
  selector: 'f-list-item-concised',
  templateUrl: './list-item-concised.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyListItemConcisedComponent {
  @Input() contentRootClass!: string | string[];
  @Input() disabled = false;
  @Input() loading = false;

  @Output() removeItem: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class') classes = 'list-group-item list-group-item-hover list-group-item-concised';
  @HostBinding('class.disabled') get disabledClass() {
    return this.disabled;
  }
  @HostBinding('attr.data-automation-id') get dataAutomationId() {
    return 'f-list-item';
  }

  spinnerSize = SpinnerSize;

  public onRemoveItem() {
    if (!this.disabled) {
      this.removeItem.emit();
    }
  }
}
