import { Component, inject, OnInit, QueryList } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';
import { FireflyMobileModalComponent, ModalContent } from '../../modal';
import { FireflyLocalizationService } from '../../utils';

@Component({
  templateUrl: './mobile-sorting-modal.component.html'
})
export class MobileSortingModalComponent implements OnInit, ModalContent {
  ascending$: Observable<string> = of('Ascending');
  descending$: Observable<string> = of('Descending');
  modalInstance!: NgbActiveModal;
  context!: {
    grid: GridComponent;
    sort: SortDescriptor[];
    disabled?: false;
  };
  sortableColumns: ColumnComponent[] = [];
  sortingOrder: SortDescriptor['dir'];
  sortAscending: SortDescriptor['dir'] = 'asc';
  sortDescending: SortDescriptor['dir'] = 'desc';
  selectedField = '';

  private localizationService = inject(FireflyLocalizationService, { optional: true });
  private modalContainer = inject(FireflyMobileModalComponent, { skipSelf: true });

  ngOnInit() {
    if (this.localizationService) {
      this.ascending$ = this.localizationService.localize('ascending', {});
      this.descending$ = this.localizationService.localize('descending', {});
    }
    this.selectedField = this.context.sort[0].field;
    this.sortingOrder = this.context.sort[0].dir;

    this.sortableColumns = (this.context.grid.columns as QueryList<ColumnComponent>).filter(
      col => col.sortable && !!col.field && !col.hidden
    );

    if (this.context.disabled) this.modalContainer.toggleAcceptBtnVisibility(false);
  }

  isSelected(sortedField: string): boolean {
    return this.selectedField === sortedField;
  }

  changeSorting(column: string, order: SortDescriptor['dir']): void {
    const selectedSort = this.context.sort.find(sort => (sort.field = column));
    if (selectedSort && !this.context.disabled) {
      selectedSort.dir = order;
      this.sortingOrder = order;
      this.selectedField = column;
    }
  }
}
