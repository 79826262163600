<kendo-fileselect
  #fileselect
  [title]="title"
  [multiple]="multiple"
  (select)="select($event)"
  (success)="success()"
  (remove)="remove($event)"
  (valueChange)="valueChange($event)"
>
  <kendo-upload-messages
    [dropFilesHere]="(uploaderDragDrop$ | async)!"
    [headerStatusUploaded]="(uploaderDragDrop$ | async)!"
    [headerStatusUploading]="(uploaderDragDrop$ | async)!"
    [select]="(uploaderBrowseFiles$ | async)!"
    [fileStatusFailed]="(uploaderStatusFailed$ | async)!"
  >
  </kendo-upload-messages>
</kendo-fileselect>
