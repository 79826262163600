import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getUserSettingsCurrencySymbol } from '@capital-access/common/globalization';
import { MILLIONS_SYMBOL } from '../common-formatting.constants';

@Directive({
  selector: '[caCurrencyMillionsSymbol]'
})
export class CurrencyMillionsSymbolDirective implements OnInit, OnDestroy {
  private subscription = Subscription.EMPTY;
  private currencySymbol: string = '';

  constructor(private element: ElementRef, private store: Store) {}

  ngOnInit(): void {
    this.subscription = this.store.select(getUserSettingsCurrencySymbol).subscribe(currencySymbol => {
      this.currencySymbol = currencySymbol || '';

      this.updateView();
    });
  }

  private updateView() {
    if (this.currencySymbol) {
      this.element.nativeElement.textContent = `${this.currencySymbol}, ${MILLIONS_SYMBOL}`;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
