import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { getSecurityName, SecurityFormattingType } from '@capital-access/common/security';
import { FireflySingleSuggesterDropdownComponent } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { SecuritySelectorItem, SelectorSecurity } from './models/security-selector.model';

@Component({
  selector: 'ca-security-selector',
  templateUrl: './security-selector.component.html',
  styleUrls: ['./security-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SecuritySelectorComponent implements OnChanges {
  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  @Input() selected: SelectorSecurity | null = null;
  @Input() securities: SelectorSecurity[] = [];
  @Input() securityShortNameFn = (security: SelectorSecurity) =>
    getSecurityName(security, SecurityFormattingType.Short);
  @Input() securityFullNameFn = (security: SelectorSecurity) => {
    if (!security.ticker) return security.name;
    return getSecurityName(security, SecurityFormattingType.Long);
  };

  @Output() securityIdSelected = new EventEmitter<{ id: number }>();
  @Output() setSecurities = new EventEmitter<void>();

  @ViewChild(FireflySingleSuggesterDropdownComponent) private suggester!: FireflySingleSuggesterDropdownComponent;

  suggesterSecurities: SecuritySelectorItem[] = [];
  tooltipPlacement = 'bottom-left';

  ngOnChanges(changes: SimpleChanges) {
    const securities = changes.securities?.currentValue as SelectorSecurity[];

    if (securities && securities.length > 5) {
      this.suggesterSecurities = securities.map(security => {
        const item: SecuritySelectorItem = {
          id: security.id,
          shortName: security.ticker,
          longName: this.securityFullNameFn(security)
        };
        if (security.category) item.category = security.category;
        return item;
      });
    }
  }

  onSelect({ id }: SelectorSecurity) {
    if (id) {
      this.securityIdSelected.emit({ id: id });
    }
  }

  onSetSecurities() {
    this.suggester.toggleDropdown(false);
    this.setSecurities.emit();
  }
}
