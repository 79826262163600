import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserNotification, UserNotificationsRepository } from '@capital-access/notifications/common';
import { NotificationsApi } from '../api/notifications.api';
import { getNotificationsQuery } from '../api/queries.graphql';
import { parseUserNotification } from '../helpers/parser.utils';

@Injectable()
export class UserNotificationsApiRepository implements UserNotificationsRepository {
  constructor(private api: NotificationsApi) {}

  public getNotifications(request: { limit?: number; beforeTimestamp?: string; lastRetrievedId?: string }) {
    return this.api.graphQl<{ notifications: UserNotification<string>[] }>(getNotificationsQuery, request).pipe(
      map(response => {
        if (response.errors && response.errors.length > 0)
          throw new Error(`Error getting notifications: ${response.errors}`);

        return (response.data?.notifications || []).map(parseUserNotification);
      })
    );
  }
}
