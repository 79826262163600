import { ErrorHandler, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Log } from './log';

@Injectable()
export class LogErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    Log.error(error);

    throwError(() => error);
  }
}
