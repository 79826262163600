export interface Security {
  id: number;
  name: string;
  ticker: string;
  stockExchange: string;
  macroIndustryId?: number;
  macroIndustryName?: string;
  reportType?: ReportType;
  snlCompanyId?: number;
  issuerId?: number;
  conversion?: SecurityConversion;
  type?: SecurityType;
}

export interface SecurityConversion {
  underlyingSecurityId: number;
  rate: number;
}

export interface SecurityType {
  code: string;
  name: string;
}

export enum ReportType {
  SRV = 'SRV',
  GSID = 'GSID'
}

export enum AssetType {
  Equity = 'EQUITY',
  Other = 'OTHER',
  FixedIncome = 'FIXED_INCOME',
  Index = 'INDEX',
  Fund = 'FUND',
  Cash = 'CASH',
  Commodities = 'COMMODITIES',
  Derivatives = 'DERIVATIVES'
}
