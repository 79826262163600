import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { LOCALIZATION_KEY, REPORT_TITLES } from '../constants';
import { ReportActionTitle, ReportType } from '../models/report-type.enum';

@Pipe({ name: 'caReportActionTitle', pure: false })
export class ReportActionTitlePipe
  extends BaseAsyncPipe<string, ReportType, [ReportActionTitle?]>
  implements PipeTransform
{
  constructor(private localizationService: LocalizationService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  getTransformer(reportType: ReportType, reportActionPrefix?: ReportActionTitle): Observable<string> {
    return this.localizationService.getLocalization(LOCALIZATION_KEY).pipe(
      take(1),
      map(localization => {
        const reportTitleLocalization = REPORT_TITLES[reportType];

        if (!reportTitleLocalization) {
          throw new Error(`No localization for ${reportType} report provided`);
        }

        const localizedTitle = localization[reportTitleLocalization];
        return reportActionPrefix ? `${localization[reportActionPrefix]} ${localizedTitle}` : localizedTitle;
      })
    );
  }
}
