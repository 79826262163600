import { ChangeDetectorRef, Directive, ElementRef, HostBinding, OnInit, Optional, Renderer2 } from '@angular/core';
import { TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { take } from 'rxjs/operators';
import { FireflyModalService } from '../../modal';
import { FireflyLocalizationService } from '../../utils';
import { FireflyBaseDateTimeDirective } from './base-date-time-directive';

@Directive({
  selector: '[fTimePicker]'
})
export class FireflyTimePickerDirective extends FireflyBaseDateTimeDirective implements OnInit {
  protected modalDialogTitle = 'Time';

  @HostBinding('class') class = 'f-time-picker';

  constructor(
    protected host: ElementRef,
    private timePicker: TimePickerComponent,
    protected renderer: Renderer2,
    protected cdr: ChangeDetectorRef,
    protected modalService: FireflyModalService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {
    super(host, timePicker, renderer, cdr, modalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.localizationService
      ?.localize('timePickerModalTitle', {})
      .pipe(take(1))
      .subscribe(value => (this.modalDialogTitle = value));
  }

  protected handleStateUpdate() {
    const acceptBtn = this.popupElement?.querySelector('.k-actions .k-time-accept') as HTMLElement;
    acceptBtn?.click();
  }
}
