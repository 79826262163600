import { type, value } from '@capital-access/common/user';

export class SecuritySettings {
  // TODO: decide how to handle missing (null) value
  @value(type.number) primary!: number | null;
  //TODO: uncomment decorator when secondary securities suggester added
  /*@value(type.array.ofNumber)*/ secondary: number[] = [];
}

export class PrimarySecuritySettings {
  @value(type.array.ofNumber, { path: 'securities:primary:ids' }) primary!: number[] | null;
}
