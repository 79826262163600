import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { displaySessionExpirePage, login, logout, userAuthenticated } from '@capital-access/common/actions';
import { SESSION_EXPIRE_PAGE_ROUTE } from '@capital-access/common/utils';
import { userDataLoaded } from './auth.actions';
import { getAuthorizeWithPopUpStatus } from './auth.selectors';
import { completeAuthorizeWithPopUp, failedAuthorizeWithPopUp } from './auth-popup.actions';
import { AuthorizeWithPopUpStatus } from './models/authorize-pop-up-status.model';
import { mapUserData } from './models/user-data-mapper.util';
@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(() => this.oidcSecurityService.authorize())
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        switchMap(() => this.oidcSecurityService.logoff())
      ),
    { dispatch: false }
  );

  loadUserData$ = createEffect(() =>
    this.oidcSecurityService.userData$.pipe(
      filter(({ userData }) => !!userData),
      map(({ userData }) => mapUserData(userData)),
      map(userData => userDataLoaded({ userData }))
    )
  );

  userAuthenticated$ = createEffect(() =>
    this.oidcSecurityService.isAuthenticated$.pipe(
      filter(result => !!result && result.isAuthenticated),
      take(1),
      map(() => userAuthenticated())
    )
  );

  authorizeWithPopUp$ = createEffect(() =>
    this.store.select(getAuthorizeWithPopUpStatus).pipe(
      //To avoid multiple pop-ups if the page sends more than one request
      distinctUntilChanged(),
      filter(status => status === AuthorizeWithPopUpStatus.Initiated),
      switchMap(() => {
        /**
         * Please note that popup might not work correctly when running app locally
         * if you have Redux Devtools browser extension installed
         * that uses the same mechanism for cross-origin communication via Window.postMessage()
         */
        return this.oidcSecurityService.authorizeWithPopUp().pipe(
          map(() => {
            //add refesh page after login with popup
            window.location.reload();
            return completeAuthorizeWithPopUp();
          }),
          catchError(error => of(failedAuthorizeWithPopUp({ error })))
        );
      })
    )
  );

  displaySessionExpirePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displaySessionExpirePage),
        tap(() => {
          this.router.navigate([this.sessionExpirePageRoute]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private oidcSecurityService: OidcSecurityService,
    private store: Store,
    private router: Router,
    @Inject(SESSION_EXPIRE_PAGE_ROUTE) private sessionExpirePageRoute: string
  ) {}
}
