import { createAction, props } from '@ngrx/store';
import { SyncEvent, SyncFilters } from './models';

export const setFilters = createAction('[Calendar Sync] Set Filter', props<{ filters: SyncFilters }>());

export const deleteActivity = createAction('[Calendar Sync] Delete Activity', props<{ activity: SyncEvent }>());

export const openTroubleshootDrawer = createAction('[Calendar Sync List] Troubleshoot', props<{ event: SyncEvent }>());

export const enableSync = createAction('[Calendar Sync] Enable Sync', props<{ activity: SyncEvent }>());
