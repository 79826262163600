import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { debounceTime, fromEvent, of, Subject, takeUntil } from 'rxjs';
import { Breakpoint, FireflyLocalizationService } from '../../utils';
import { FireflyCardRowService } from './card-row.service';

@Component({
  selector: 'f-card-row',
  templateUrl: './card-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardRowComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() containerCssClass: string = '';
  @Input() rowCssClass: string = '';
  @Input() firstColClass: string = '';
  @Input() secondColClass: string = '';
  @Input() thirdColClass: string = '';
  @Input() cardRowWithTabs = false;
  @Input() isTabsResponsiveOnLgScreen = false;
  @Input() tabsColClass = '';

  searchAnimationState: 'on' | 'off' = 'off';
  searchAnimationDuration = 250;
  mobileSearchIsActive = false;
  closeSearchBtnTitle$ = of('Close');
  showCloseLink = false;

  @ViewChild('searchContainer') searchContainer?: ElementRef;
  @ViewChild('tabContainer') tabContainer?: ElementRef;

  private destroyed$ = new Subject<void>();

  get mobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private cardRowService: FireflyCardRowService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {}

  ngAfterViewInit() {
    const tabs: HTMLElement = this.tabContainer?.nativeElement;
    if (tabs && this.isTabsResponsiveOnLgScreen) {
      this.changeTabsResponsiveBehavior(tabs);
    }

    fromEvent(this.searchContainer?.nativeElement, 'transitionend')
      .pipe(debounceTime(this.searchAnimationDuration), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.searchAnimationState = 'off';
        this.cdr.detectChanges();
      });
  }

  ngOnInit() {
    if (this.localizationService) {
      this.closeSearchBtnTitle$ = this.localizationService.localize('close', {});
    }

    this.cardRowService.searchOpenChange$.pipe(takeUntil(this.destroyed$)).subscribe(open => {
      open ? this.openMobileSearch() : this.closeMobileSearch();
      this.searchAnimationState = 'off';
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private openMobileSearch() {
    if (this.mobileSearchIsActive) return;

    window.requestAnimationFrame(() => {
      this.searchAnimationState = 'on';
      this.mobileSearchIsActive = true;
      this.cdr.detectChanges();
    });

    if (this.mobile) {
      this.showCloseLink = true;
    }
  }

  closeMobileSearch() {
    this.cardRowService.toggleSearchOpenState(false);
    this.searchAnimationState = 'on';
    this.mobileSearchIsActive = false;
    this.cdr.detectChanges();
    (document.activeElement as HTMLElement)?.blur();
  }

  private changeTabsResponsiveBehavior(tabs: HTMLElement) {
    const chips = tabs.querySelector('.d-none.d-lg-block');
    const dropdown = tabs.querySelector('.d-lg-none');
    if (!chips || !dropdown) return;

    chips.classList.remove('d-lg-block');
    chips.classList.add('d-xl-block');

    dropdown.classList.remove('d-lg-none');
    dropdown.classList.add('d-xl-none');
  }
}
