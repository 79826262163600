import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import { ActivityTag } from '@capital-access/activity/common';
import { toHttpParamsObject } from '@capital-access/common/utils';
import { CALENDAR_SYNC_API_BASE } from '../constants';
import {
  ActivitiesRequest,
  ActivitiesResponse,
  ProgressStats,
  SaveSubscriptionsRequest,
  SaveSubscriptionsResponse,
  SyncCalendar,
  SyncStatus,
  SyncSubscriptionResponse,
  SyncUser,
  SyncVendor,
  TroubleshootResponse
} from '../models';

@Injectable()
export class CalendarSyncRepository {
  public authorize() {
    return this.http.get<string>(`${CALENDAR_SYNC_API_BASE}/microsoft/authorize`);
  }

  public authorizeGoogle() {
    return this.http.get<string>(`${CALENDAR_SYNC_API_BASE}/google/authorize`);
  }

  public getSubscriptions(vendorType: SyncVendor): Observable<SyncSubscriptionResponse> {
    return this.http.get<SyncSubscriptionResponse>(`${CALENDAR_SYNC_API_BASE}/subscriptions`, {
      params: { vendorType }
    });
  }

  public saveSubscriptions(request: SaveSubscriptionsRequest): Observable<SaveSubscriptionsResponse> {
    return this.http.post<SaveSubscriptionsResponse>(`${CALENDAR_SYNC_API_BASE}/subscriptions`, request);
  }

  public getUser(): Observable<SyncUser> {
    return this.http.get<SyncUser>(`${CALENDAR_SYNC_API_BASE}/users`);
  }

  public deleteUser(vendorType: SyncVendor) {
    return this.http.delete(`${CALENDAR_SYNC_API_BASE}/users`, { params: { vendorType } });
  }

  public hardDeleteActivities(ids: string[]) {
    return this.http.delete(`${CALENDAR_SYNC_API_BASE}/activities`, { body: ids });
  }

  public deleteActivity(id: string) {
    const ids = [id];
    return this.deleteActivities(ids);
  }

  public deleteActivities(ids: string[]): Observable<string[]> {
    return this.http.delete<string[]>(`${CALENDAR_SYNC_API_BASE}/activities/delete`, { body: ids });
  }

  public deleteAudit(id: string, vendor: string) {
    return this.http.delete(`${CALENDAR_SYNC_API_BASE}/audits/delete`, { params: { eventId: id, vendorType: vendor } });
  }

  public disableSync(activityId: string) {
    return this.http.get<ActivityTag>(`${CALENDAR_SYNC_API_BASE}/activities/disable-sync`, { params: { activityId } });
  }

  public enableSync(activityId: string) {
    return this.http.get<ProgressStats>(`${CALENDAR_SYNC_API_BASE}/activities/enable-sync`, { params: { activityId } });
  }

  public getCalendars(vendorType = SyncVendor.Microsoft): Observable<SyncCalendar[]> {
    return this.http.get<SyncCalendar[]>(`${CALENDAR_SYNC_API_BASE}/calendars`, { params: { vendorType } });
  }

  public getFailedReasons(eventId: string): Observable<TroubleshootResponse> {
    return this.http.get<TroubleshootResponse>(`${CALENDAR_SYNC_API_BASE}/troubleshoot`, { params: { eventId } });
  }

  public setContactStatus(email: string, crmContactId: number) {
    return this.http.post(`${CALENDAR_SYNC_API_BASE}/troubleshoot/contact-adding`, { email, crmContactId });
  }

  public getEvents(params: ActivitiesRequest): Observable<ActivitiesResponse> {
    return this.http
      .get<ActivitiesResponse>(`${CALENDAR_SYNC_API_BASE}/events`, {
        params: toHttpParamsObject(params)
      })
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(d => ({
            ...d,
            startDate: this.toISOFormat(d.startDate),
            endDate: this.toISOFormat(d.endDate),
            isUpcoming: this.isUpcoming(d.startDate)
          }))
        }))
      );
  }

  public getEventStatus(vendorType: SyncVendor, calendarId: string, email: string, eventId: string) {
    return this.http.get<SyncStatus>(`${CALENDAR_SYNC_API_BASE}/events/status`, {
      params: { vendorType, calendarId, email, eventId }
    });
  }

  public getFailedEvents(
    skip: number,
    take: number,
    calendarIds: string[],
    vendorType: SyncVendor
  ): Observable<ActivitiesResponse> {
    return this.http
      .get<ActivitiesResponse>(`${CALENDAR_SYNC_API_BASE}/audits/failed`, {
        params: { skip, take, vendorType, calendarIds }
      })
      .pipe(
        map(response => ({
          ...response,
          data: response.data.map(d => ({
            ...d,
            startDate: this.toISOFormat(d.startDate),
            endDate: this.toISOFormat(d.endDate),
            isUpcoming: this.isUpcoming(d.startDate)
          }))
        }))
      );
  }

  public getTroubleshootCalendars(vendorType: SyncVendor): Observable<SyncCalendar[]> {
    return this.http.get<SyncCalendar[]>(`${CALENDAR_SYNC_API_BASE}/audits/calendars`, {
      params: { vendorType }
    });
  }

  private toISOFormat(date: string): string {
    return DateTime.fromISO(date, { zone: 'UTC' }).toISO();
  }

  private isUpcoming(date: string): boolean {
    return DateTime.fromISO(date, { zone: 'UTC' }) > DateTime.now();
  }

  constructor(private http: HttpClient) {}
}
