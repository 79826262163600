import { Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GridComponent } from '@progress/kendo-angular-grid';
import { fromEvent, of, Subscription } from 'rxjs';
import { debounceTime, filter, withLatestFrom } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { Breakpoint } from '../../utils/breakpoints';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'kendo-grid'
})
export class FireflyScrollableGridDirective implements OnInit, OnDestroy {
  private grid = inject(GridComponent, { self: true });
  private store = inject(Store, { optional: true });
  private scrollSub$ = Subscription.EMPTY;

  ngOnInit() {
    if (window.innerWidth > Breakpoint.Sm || !this.grid.scrollBottom.observed) return;

    requestAnimationFrame(() => {
      const scrollElement = document.querySelector('.ca-content-wrapper');
      this.scrollSub$ = fromEvent(scrollElement!, 'scroll', { capture: true })
        .pipe(
          debounceTime(100),
          withLatestFrom(this.store?.select(getFeatureToggle('mobile-scroll-behavior-CPD-2028')) || of(true)),
          filter(
            ([e, enabled]) => enabled && scrollElement === e.target && this.elementScrolledToBottom(e.target as Element)
          )
        )
        .subscribe(() => {
          this.grid.scrollBottom.emit();
        });
    });
  }

  ngOnDestroy() {
    this.scrollSub$.unsubscribe();
  }

  private elementScrolledToBottom(el: Element) {
    // extra space to emit event within 100 pixels from end of the page
    const scrollBottomPadding = 100;
    return el && el.scrollHeight - el.scrollTop < el.clientHeight + scrollBottomPadding;
  }
}
