// TODO: add a possibility to specify items type for chips component and remove this extension
export interface ListTypeOption extends Record<string, unknown> {
  text: string;
  type: ListType;
}

export enum ListType {
  Contact = 'Contact',
  Security = 'Security',
  Institution = 'Company'
}
