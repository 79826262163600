import { ReplaySubject } from 'rxjs';
import { LogLevel, LogObject } from './logger.model';

//@dynamic
export class Log {
  private static _logsSubject = new ReplaySubject<{ level: Exclude<LogLevel, LogLevel.Disable>; message: LogObject }>();

  static get logs$() {
    return Log._logsSubject.asObservable();
  }

  static error(message: LogObject) {
    Log.log(LogLevel.Error, message);
  }

  static warn(message: LogObject) {
    Log.log(LogLevel.Warning, message);
  }

  static info(message: LogObject) {
    Log.log(LogLevel.Information, message);
  }

  static debug(message: LogObject) {
    Log.log(LogLevel.Debug, message);
  }

  static trace(message: LogObject) {
    Log.log(LogLevel.Trace, message);
  }

  static log(logLevel: Exclude<LogLevel, LogLevel.Disable>, message: LogObject) {
    this._logsSubject.next({ level: logLevel, message });
  }
}
