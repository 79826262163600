import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CRM_FORM_MAX_LENGTH } from '../model/constants';

export function createAddressForm(): FormGroup {
  return new FormGroup({
    inputAddress: new FormControl(''),
    addressLine1: new FormControl(''),
    addressLine2: new FormControl('', Validators.maxLength(CRM_FORM_MAX_LENGTH.addressLine2)),
    place_id: new FormControl(''),
    addressCity: new FormControl(''),
    addressCountry: new FormControl(''),
    state: new FormControl(''),
    stateCode: new FormControl(''),
    zipCode: new FormControl('')
  });
}
