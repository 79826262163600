<f-list-item class="suggester-dropdown-info-wrapper" [withHover]="false" *ngIf="showError; else actionsRef">
  <ng-template *ngIf="errorTemplate; else defaultErrorTemplate" [ngTemplateOutlet]="errorTemplate"></ng-template>
</f-list-item>
<ng-template #actionsRef>
  <f-list-item class="suggester-dropdown-info-wrapper" [withHover]="false" *ngIf="showNoData">
    <ng-template *ngIf="noDataTemplate; else defaultNoDataTemplate" [ngTemplateOutlet]="noDataTemplate"></ng-template>
  </f-list-item>
  <f-list-item class="suggester-dropdown-info-wrapper" [withHover]="false" *ngIf="showNoResults">
    <ng-template
      *ngIf="noResultsTemplate; else defaultNoResultsTemplate"
      [ngTemplateOutlet]="noResultsTemplate"
    ></ng-template>
  </f-list-item>
  <f-list-item [withHover]="false" *ngIf="showViewMore && !showNoResults && !showError" (click)="onViewMoreClicked()">
    <ng-template
      *ngIf="viewMoreTemplate; else defaultViewMoreTemplate"
      [ngTemplateOutletContext]="{ $implicit: showLoader }"
      [ngTemplateOutlet]="viewMoreTemplate"
    ></ng-template>
  </f-list-item>
</ng-template>

<ng-template #defaultErrorTemplate>
  <span>
    {{ somethingWentWrong$ | async }}
    <a *ngIf="showContactSupportLink" class="link link-primary" (click)="contactSupport.emit()">{{
      contactSupport$ | async
    }}</a>
  </span>
</ng-template>

<ng-template #defaultNoResultsTemplate>
  <span class="text-muted no-results-template">{{ noResults$ | async }}</span>
</ng-template>

<ng-template #defaultViewMoreTemplate>
  <span
    class="link link-primary"
    [class.link-disabled]="showLoader"
    [title]="showLoader ? (isLoadingTitle$ | async) : ''"
    tabindex="0"
  >
    {{ viewMore$ | async }}
  </span>
</ng-template>

<ng-template #defaultNoDataTemplate>
  <span class="text-muted" tabindex="0">{{ noData$ | async }}</span>
</ng-template>
