<div #mobileCalendarContainer [class.d-none]="isHidden" class="d-flex flex-column d-sm-none align-items-center">
  <kendo-calendar
    [type]="'classic'"
    [value]="selectedDate"
    [disabledDates]="disabledDates"
    [activeView]="activeView"
    [bottomView]="activeView"
    [class.collapsed]="isCollapsed"
    (valueChange)="selectedDateChange.emit($event)"
    class="w-100 f-mobile-calendar"
  ></kendo-calendar>

  <button
    type="button"
    class="btn btn-simple-icon f-expand-mobile-calendar-btn btn-sm mb-1"
    (click)="toggleMobileCalendar()"
  >
    <i class="f-i f-i-collapse"></i>
  </button>
</div>
