<div
  [placement]="tooltipPlacement"
  [container]="$any(tooltipContainer)"
  [disableTooltip]="disableTooltip"
  [tooltipClass]="tooltipClass"
  [ngbTooltip]="tooltip"
>
  <div
    ngbDropdown
    display="dynamic"
    [container]="container"
    [placement]="placement"
    data-automation-id="f-dropdown-container"
    [autoClose]="autoClose"
    (openChange)="onDropdownOpenChange($event)"
  >
    <button
      ngbDropdownToggle
      fDropdownListFocus
      [tabbable]="tabbable"
      [focusableItemsContainer]="dropdownMenu"
      [disabled]="disabled || loading"
      [style.max-width.px]="btnMaxWidth"
      [ngClass]="[btnStyle, iconClass]"
      class="d-inline-flex justify-content-center align-items-center btn"
    >
      <i *ngIf="icon" class="f-i" [ngClass]="icon"></i>
      <span [ngClass]="{ 'text-truncate': btnMaxWidth }">{{ text }}</span>
      <i
        *ngIf="iconAfterTitle"
        class="f-i"
        [ngClass]="iconAfterTitle"
        [ngbPopover]="$any(iconPopoverContent)"
        [placement]="iconPopoverPlacement"
        [triggers]="iconPopoverTriggers"
        fMobilePopover
        container="body"
      ></i>
      <i *ngIf="!hideCaret" class="f-i f-i-xs f-i-caret-down form-caret"></i>

      <f-spinner *ngIf="loading" rootCssClass="spinner-center"></f-spinner>
    </button>
    <div #dropdownMenu ngbDropdownMenu data-automation-id="f-dropdown-menu" [ngClass]="menuCssClass">
      <ng-content></ng-content>
    </div>
  </div>
</div>
