import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StandingDataRepository } from '@capital-access/common/standing-data';
import { loadUserProfileSuccess } from '@capital-access/common/user';
import { loadSupportedCurrencies, loadSupportedCurrenciesFail, loadSupportedCurrenciesSuccess } from './actions';

@Injectable()
export class GlobalizationCurrencyEffects {
  triggerLoad$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadUserProfileSuccess),
      map(() => loadSupportedCurrencies())
    )
  );

  load$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadSupportedCurrencies),
      switchMap(() =>
        this.repository.getCurrencies().pipe(
          map(response => loadSupportedCurrenciesSuccess({ response })),
          catchError(error => of(loadSupportedCurrenciesFail({ error })))
        )
      )
    )
  );

  constructor(private actions: Actions, private store: Store, private repository: StandingDataRepository) {}
}
