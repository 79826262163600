import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { toHttpParamsObject } from '@capital-access/common/utils';
import {
  ContactFundIdsRequestDto,
  ContactFundResponseItem,
  ContactFundsExcelExportRequestDto,
  ContactFundsRequestDto,
  ContactFundsTopRequestDto
} from '../models/funds-managed/contact-funds.models';
import {
  InstitutionFundIdsRequestDto,
  InstitutionFundResponseItem,
  InstitutionFundsExcelExportRequestDto,
  InstitutionFundsRequestDto,
  InstitutionFundsTopRequestDto
} from '../models/funds-managed/institution-funds.models';

@Injectable({ providedIn: 'root' })
export class FundsManagedRepository {
  private readonly apiBase = '/api/funds-managed';

  constructor(private http: HttpClient) {}

  // ============== By Institution ========================
  getInstitutionFundIds(institutionId: number, request: InstitutionFundIdsRequestDto) {
    return this.http.get<number[]>(`${this.apiBase}/by-institution/${institutionId}/ids`, {
      params: toHttpParamsObject(request)
    });
  }

  getInstitutionFunds(institutionId: number, request: InstitutionFundsRequestDto) {
    return this.http.get<InstitutionFundResponseItem[]>(`${this.apiBase}/by-institution/${institutionId}/items`, {
      params: toHttpParamsObject(request)
    });
  }

  getTopInstitutionFunds(institutionId: number, request: InstitutionFundsTopRequestDto) {
    return this.http.get<InstitutionFundResponseItem[]>(`${this.apiBase}/by-institution/${institutionId}/top`, {
      params: toHttpParamsObject(request)
    });
  }

  exportInstitutionFunds(institutionId: number, request: InstitutionFundsExcelExportRequestDto) {
    return this.http
      .post(`${this.apiBase}/by-institution/${institutionId}/export`, request, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map(response => {
          if (response.body === null) {
            throw new Error('InstitutionFunds export returned empty response');
          }
          return response.body;
        })
      );
  }

  // ============== By Contact ============================
  getContactFundIds(contactId: number, request: ContactFundIdsRequestDto) {
    return this.http.get<number[]>(`${this.apiBase}/by-contact/${contactId}/ids`, {
      params: toHttpParamsObject(request)
    });
  }

  getContactFunds(contactId: number, request: ContactFundsRequestDto) {
    return this.http.get<ContactFundResponseItem[]>(`${this.apiBase}/by-contact/${contactId}/items`, {
      params: toHttpParamsObject(request)
    });
  }

  getTopContactFunds(contactId: number, request: ContactFundsTopRequestDto) {
    return this.http.get<ContactFundResponseItem[]>(`${this.apiBase}/by-contact/${contactId}/top`, {
      params: toHttpParamsObject(request)
    });
  }

  exportContactFunds(contactId: number, request: ContactFundsExcelExportRequestDto) {
    return this.http
      .post(`${this.apiBase}/by-contact/${contactId}/export`, request, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map(response => {
          if (response.body === null) {
            throw new Error('ContactFunds export returned empty response');
          }
          return response.body;
        })
      );
  }
}
