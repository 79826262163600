import { createSettingsSelectors } from '@capital-access/common/user';
import { APPLICATION_KEY } from '../../constants';
import { PrimarySecuritySettings, SecuritySettings } from '../../models/security-settings';
import { SurveillanceSettings } from '../../models/surveillance-settings';
import { APPLICATION_SECURITY_KEY, APPLICATION_SURVEILLANCE_KEY } from './preferences.keys';

export const { selectSettings: getSecuritySettings } = createSettingsSelectors(
  SecuritySettings,
  APPLICATION_SECURITY_KEY
);

export const { selectSettings: getPrimarySecuritySettings } = createSettingsSelectors(
  PrimarySecuritySettings,
  APPLICATION_KEY
);

export const { selectSettings: getSurveillanceSettings } = createSettingsSelectors(
  SurveillanceSettings,
  APPLICATION_SURVEILLANCE_KEY
);
