import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonRouterModule } from '@capital-access/common/router';
import { LogoComponent } from './logo.component';

@NgModule({
  imports: [CommonRouterModule, CommonModule, NgbModule, CommonLocalizationModule],
  declarations: [LogoComponent],
  exports: [LogoComponent]
})
export class LogoModule {}
