<div class="d-flex align-items-center" caLocalizationScope="search-nav">
  <ng-container [ngSwitch]="popupHeaderState$ | async">
    <div
      class="d-flex cursor-pointer ca-search-back-btn"
      *ngSwitchCase="popupHeaderState.Filter"
      data-automation-id="search-results-back-btn"
      (click)="onFilterChanged(null)"
    >
      <i class="f-i f-i-chevron-left"></i>
      <span class="link-50 ca-search-back-link">{{ 'back' | caLocalize }}</span>
    </div>

    <span class="body-50" *ngSwitchCase="popupHeaderState.EntitiesSelected" data-automation-id="selected-items-label">
      {{ 'selectedItems' | caLocalize }}
    </span>

    <span *ngSwitchCase="popupHeaderState.Default" class="body-50 text-muted" data-automation-id="search-results-label">
      {{ 'searchResults' | caLocalize }}
    </span>
  </ng-container>

  <f-editable-chip-group
    class="flex-grow-1 user-select-none ms-2"
    *ngIf="hasSelectedEntities$ | async"
    [items]="chipsItems$ | async"
    [collapsable]="true"
    (remove)="onUnselect($event)"
    (removeAll)="onUnselectAll()"
    (itemClick)="onChipClick($event)"
    data-automation-id="selected-items-chip-container"
  >
  </f-editable-chip-group>
</div>
