import { Inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Log } from '@capital-access/common/logging';
import {
  clearIdsMarkedForReset,
  fullSettingKey,
  markIdsForReset,
  saveSerializedSettings,
  saveSerializedSettingsSilentlySuccess,
  saveSettingsFailure,
  saveSettingsSuccess
} from '@capital-access/common/user';
import { ColumnChooserTableSettings, FireflyColumnChooserPersistenceService } from '@capital-access/firefly/components';
import { getSettingMarkedForReset, getTableSettings } from './+state/table-settings.selectors';
import { APP_TABLE_SETTINGS_PREFIX } from './table-settings.module';

@Injectable()
export class ColumnChooserPersistenceService implements FireflyColumnChooserPersistenceService {
  constructor(
    private store: Store,
    private actions: Actions,
    @Inject(APP_TABLE_SETTINGS_PREFIX) private appPrefix: string
  ) {}

  getTableSettings(tableId: string, columns: string[], stickyColumnsFieldOrderIndex: Record<string, number>) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);
    return this.store.select(getTableSettings(path)).pipe(
      switchMap(({ settings }) =>
        Object.values(settings).length
          ? this.handleSettingsIntegrity(settings, stickyColumnsFieldOrderIndex, path, columns.length)
          : of(null)
      ),
      map(settings => (settings ? this.mapEncodedTableSettings(columns, settings) : settings)),
      catchError(() => of(null))
    );
  }

  updateTableSettings(tableId: string, tableSettings: ColumnChooserTableSettings[]) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);
    const settings = tableSettings.map(col => ({
      id: `${path}:${this.encodeField(col.field)}`,
      value: JSON.stringify(col)
    }));

    this.store
      .select(getSettingMarkedForReset())
      .pipe(take(1))
      .subscribe(settingMarkedForReset =>
        this.store.dispatch(saveSerializedSettings({ data: [...settingMarkedForReset, ...settings] }))
      );

    return this.actions.pipe(
      ofType(saveSettingsSuccess, saveSettingsFailure),
      switchMap(res => {
        if (res.type === saveSettingsSuccess.type) {
          this.store.dispatch(clearIdsMarkedForReset());
          return of(res.settings);
        }
        return throwError(() => res.error);
      })
    );
  }

  resetTableSettings(tableId: string) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);
    return this.store.select(getTableSettings(path)).pipe(
      map(({ settings }) => {
        const nullSettings = [];
        for (const key in settings) {
          nullSettings.push({ id: `${path}:${key}`, value: null });
        }
        return nullSettings;
      }),
      tap(data => this.store.dispatch(saveSerializedSettings({ data }))),
      switchMap(() => {
        return this.actions.pipe(
          ofType(saveSettingsSuccess, saveSettingsFailure),
          switchMap(res => (res.type === saveSettingsSuccess.type ? of(res.settings) : throwError(res.error)))
        );
      })
    );
  }

  markUnusedSettings(tableId: string, columns: string[]) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);

    this.store
      .select(getTableSettings(path))
      .pipe(take(1))
      .subscribe(({ settings }) => {
        const unusedSettingKeys: string[] = [];

        Object.entries(settings).forEach(([key, { field }]) => {
          if (!columns.includes(field)) unusedSettingKeys.push(key);
        });

        if (unusedSettingKeys.length) {
          this.store.dispatch(
            markIdsForReset({
              markedForResetIds: unusedSettingKeys.map(key => fullSettingKey(path, key))
            })
          );
        }
      });
  }

  private mapEncodedTableSettings(columns: string[], settings: Record<string, ColumnChooserTableSettings>) {
    const res: ColumnChooserTableSettings[] = [];

    columns.forEach(col => {
      const encodedFieldMatch = settings[this.encodeField(col)];
      const regularFieldMatch = settings[col];

      if (encodedFieldMatch) {
        res.push(encodedFieldMatch);
      } else if (regularFieldMatch) {
        res.push(regularFieldMatch);
      }
    });

    return res;
  }

  private handleSettingsIntegrity(
    userSettings: Record<string, ColumnChooserTableSettings>,
    stickyColumnsFieldOrderIndex: Record<string, number>,
    path: string,
    columnNumber: number
  ): Observable<Record<string, ColumnChooserTableSettings>> {
    // Covers the case when `orderIndex` for a sticky column was changed: changes `orderIndex` to the default one
    const { updatedSettings: updatedSettingsSticky, settingsForUpdate: settingsForUpdateSticky } =
      this.handleWrongSettingsForStickyColumns(userSettings, stickyColumnsFieldOrderIndex);

    // Covers the case when several settings have the same `orderIndex`:
    // changes `orderIndex` value for the not sticky columns to the first available index after the current `orderIndex`;
    // In the case when there are no available `orderIndexes`, resets the setting for this column
    const {
      updatedSettings,
      settingsForUpdate: settingsForUpdateOrder,
      keysForReset
    } = this.handleSettingsWithSameOrderIndex(updatedSettingsSticky, stickyColumnsFieldOrderIndex, columnNumber);

    if (!settingsForUpdateSticky.length && !settingsForUpdateOrder.length && !keysForReset.length)
      return of(updatedSettings);

    this.saveFixedSettingsSilently([...settingsForUpdateSticky, ...settingsForUpdateOrder], keysForReset, path);

    return this.actions.pipe(
      ofType(saveSerializedSettingsSilentlySuccess),
      map(() => updatedSettings)
    );
  }

  private handleWrongSettingsForStickyColumns(
    userSettings: Record<string, ColumnChooserTableSettings>,
    stickyColumnsFieldOrderIndex: Record<string, number>
  ): {
    updatedSettings: Record<string, ColumnChooserTableSettings>;
    settingsForUpdate: ColumnChooserTableSettings[];
  } {
    const stickyColumns = Object.keys(stickyColumnsFieldOrderIndex);
    if (!stickyColumns.length) return { settingsForUpdate: [], updatedSettings: userSettings };

    return this.getStickySettingsStickyForUpdate(userSettings, stickyColumnsFieldOrderIndex);
  }

  private handleSettingsWithSameOrderIndex(
    userSettings: Record<string, ColumnChooserTableSettings>,
    stickyColumnsFieldOrderIndex: Record<string, number>,
    columnNumber: number
  ): {
    updatedSettings: Record<string, ColumnChooserTableSettings>;
    settingsForUpdate: ColumnChooserTableSettings[];
    keysForReset: string[];
  } {
    const keysByOrderIndex = this.getKeysByOrderIndex(userSettings, stickyColumnsFieldOrderIndex);
    const keysWithSameIndex: string[][] = Object.values(keysByOrderIndex).filter(keys => keys.length > 1);
    if (!keysWithSameIndex.length) return { settingsForUpdate: [], keysForReset: [], updatedSettings: userSettings };

    const wrongFields = keysWithSameIndex
      .map(keys => keys.map(key => userSettings[key]?.field || key).join(', '))
      .join('; ');
    Log.warn(`Same order indexes for several columns were detected: ${wrongFields}`);

    return this.getSettingsWithSameOrderIndexForUpdate(userSettings, keysByOrderIndex, columnNumber);
  }

  private saveFixedSettingsSilently(
    settingsForUpdate: ColumnChooserTableSettings[],
    keysForReset: string[],
    path: string
  ): void {
    const settingsForUpdateSerialized = settingsForUpdate.map(col => ({
      id: `${path}:${this.encodeField(col.field)}`,
      value: JSON.stringify(col)
    }));

    const settingsForReset = keysForReset.map(key => ({
      id: `${path}:${key}`,
      value: null
    }));

    this.store.dispatch(
      saveSerializedSettings({ data: [...settingsForUpdateSerialized, ...settingsForReset], silently: true })
    );
  }

  private getStickySettingsStickyForUpdate(
    userSettings: Record<string, ColumnChooserTableSettings>,
    stickyColumnsFieldOrderIndex: Record<string, number>
  ): {
    updatedSettings: Record<string, ColumnChooserTableSettings>;
    settingsForUpdate: ColumnChooserTableSettings[];
  } {
    const settingsForUpdate: ColumnChooserTableSettings[] = [];
    const updatedSettings: Record<string, ColumnChooserTableSettings> = {};

    Object.entries(userSettings).forEach(([key, setting]) => {
      const defaultOrderForStickyColumn = stickyColumnsFieldOrderIndex[setting.field];

      if (defaultOrderForStickyColumn === undefined) return;
      if (setting.orderIndex === defaultOrderForStickyColumn) return;

      const updatedSetting = {
        ...setting,
        orderIndex: defaultOrderForStickyColumn
      };
      settingsForUpdate.push(updatedSetting);
      updatedSettings[key] = updatedSetting;
    });

    if (settingsForUpdate.length) {
      const wrongFields = settingsForUpdate.map(({ field }) => field).join(', ');
      Log.warn(`Wrong order indexes for sticky columns were detected: ${wrongFields}`);
    }

    return {
      updatedSettings: settingsForUpdate.length ? { ...userSettings, ...updatedSettings } : userSettings,
      settingsForUpdate
    };
  }

  private getKeysByOrderIndex(
    userSettings: Record<string, ColumnChooserTableSettings>,
    stickyColumnsFieldOrderIndex: Record<string, number>
  ): Record<number, string[]> {
    const keysByOrderIndex: Record<number, string[]> = {};
    // Puts `field` value for all sticky columns at the beginning of the array to take them into account
    // to avoid changes of `orderIndex` for sticky column inside `getSettingsWithSameOrderIndexForUpdate`
    Object.keys(stickyColumnsFieldOrderIndex).forEach(stickyField => {
      keysByOrderIndex[stickyColumnsFieldOrderIndex[stickyField]] = [stickyField];
    });

    Object.entries(userSettings).forEach(([key, setting]) => {
      const { orderIndex } = setting;
      if (keysByOrderIndex[orderIndex]) {
        if (keysByOrderIndex[orderIndex][0] === setting.field) {
          // Covers the case if this setting is for a sticky column
          // and replaces its `field` value with its setting key at the beginning of the array
          keysByOrderIndex[orderIndex][0] = key;
        } else {
          keysByOrderIndex[orderIndex].push(key);
        }
      } else {
        keysByOrderIndex[orderIndex] = [key];
      }
    });
    return keysByOrderIndex;
  }

  private getSettingsWithSameOrderIndexForUpdate(
    userSettings: Record<string, ColumnChooserTableSettings>,
    keysByOrderIndex: Record<number, string[]>,
    columnNumber: number
  ): {
    updatedSettings: Record<string, ColumnChooserTableSettings>;
    settingsForUpdate: ColumnChooserTableSettings[];
    keysForReset: string[];
  } {
    const updatedSettings: Record<string, ColumnChooserTableSettings> = {
      ...userSettings
    };
    const settingsForUpdate: ColumnChooserTableSettings[] = [];
    const keysForReset: string[] = [];

    const usedOrderIndexes = Object.keys(keysByOrderIndex)
      .map(Number)
      .sort((a, b) => a - b);
    let unusedOrderIndexes = Array.from({ length: columnNumber }, (_, i) => i).filter(
      i => !usedOrderIndexes.includes(i)
    );

    Object.entries(keysByOrderIndex).forEach(([orderIndex, keys]) => {
      if (keys.length === 1) return;
      keys.forEach((key, i) => {
        // Makes changes only for the columns with key that is placed not in the beginning of array
        if (!i) return;

        const currentOrderIndex = Number(orderIndex);
        const arrayIndexInUnsetOrderIndexes = unusedOrderIndexes.findIndex(x => x > currentOrderIndex);
        if (arrayIndexInUnsetOrderIndexes < 0 || !unusedOrderIndexes.length) {
          delete updatedSettings[key];
          keysForReset.push(key);
        } else {
          const settingWithNewOrderIndex = {
            ...userSettings[key],
            orderIndex: unusedOrderIndexes[arrayIndexInUnsetOrderIndexes]
          };
          updatedSettings[key] = settingWithNewOrderIndex;
          settingsForUpdate.push(settingWithNewOrderIndex);
        }
        unusedOrderIndexes = unusedOrderIndexes.slice(arrayIndexInUnsetOrderIndexes + 1);
      });
    });

    return { updatedSettings, settingsForUpdate, keysForReset };
  }

  private getTableSettingsSection(appPrefix: string, tableId: string) {
    return `${appPrefix}:table-settings:${tableId}`;
  }

  private encodeField(field: string): string {
    return btoa(field).replace(/[=]/g, '');
  }
}
