<div class="d-flex mb-2">
  <div
    *ngIf="showSelectAllBtn"
    class="link link-primary me-3"
    [title]="selectAllBtnTitle$ | async"
    (click)="selectAll()"
    data-automation-id="select-all"
  >
    {{ selectAllBtnTitle$ | async }}
  </div>
  <div
    *ngIf="showClearAllBtn"
    class="link link-primary"
    [title]="clearAllBtnTitle$ | async"
    (click)="clearAll()"
    data-automation-id="clear-all"
  >
    {{ clearAllBtnTitle$ | async }}
  </div>
  <div
    class="link link-primary ms-auto"
    [title]="resetBtnTitle$ | async"
    (click)="resetSettings()"
    data-automation-id="reset-to-default"
  >
    {{ resetBtnTitle$ | async }}
  </div>
</div>

<div fDrawerScrollable [class]="dragVisibilityClasses">
  <f-draggable-list
    [items]="columns"
    [hoverable]="false"
    [preventDragFn]="preventDragFn"
    [listItemTemplate]="listItemTemplate"
    [rootCssClass]="listRootCssClass"
    (drop)="updateColumnsOrder()"
  >
    <ng-template #listItemTemplate let-column>
      <f-list-item-checkbox
        [hoverable]="false"
        [checked]="!column.hidden"
        [itemCheckboxClass]="'align-items-center'"
        [checkboxTitle]="getCheckboxTitle(column) | async"
        [disabled]="isColumnDisabled(column)"
        (toggle)="toggleColumn(column)"
      >
        <span>
          {{ column.title }}
          <span class="text-muted"> {{ getColumnDescription(column) | async }} </span>
        </span>
        <i
          *ngIf="column.optional"
          class="f-i f-i-xs f-i-close ms-auto"
          data-automation-id="remove-optional-column-btn"
          (click)="removeOptionalColumn($event, column)"
        ></i>
      </f-list-item-checkbox>
    </ng-template>
  </f-draggable-list>
</div>

<div *ngIf="optionalColumnsInitialState.length" class="sticky-bottom pt-4">
  <span
    class="link link-primary"
    [class.d-none]="!showAddColumnsLink"
    [class.link-disabled]="!optionalColumns.length || selectedOptionalColumnsLimitReached"
    [title]="addOptionalColumnLinkTitle | async"
    data-automation-id="add-additional-columns-link"
    (click)="showColumnsSuggester(suggester)"
  >
    + {{ addAdditionalColumnsTitle$ | async }}
  </span>

  <div class="d-flex" [class.d-none]="showAddColumnsLink">
    <f-multiple-suggester
      #suggester
      class="flex-grow-1"
      [idField]="'title'"
      [valuePath]="'title'"
      [isOutlineInput]="false"
      [showPredefinedSet]="true"
      [items]="optionalColumns"
      [placeholder]="$any(suggesterPlaceholder$ | async)"
      [maxSelectedItemsCount]="maxSelectedOptionalColumnsCount - selectedOptionalColumnsCount"
      [selectedItems]="$any(selectedOptionalColumns)"
      data-automation-id="optional-columns-suggester"
      (select)="onSelect($any($event))"
      (selectMany)="onSelectMany($any($event))"
      (removeMany)="onRemoveMany($any($event))"
      (remove)="onRemove($any($event))"
    >
    </f-multiple-suggester>
    <button
      type="button"
      class="btn btn-icon btn-secondary ms-1"
      [title]="addAdditionalColumnsTitle$ | async"
      data-automation-id="add-new-custom-columns-btn"
      (click)="addOptionalColumns()"
    >
      <i class="f-i f-i-plus-circle"></i>
    </button>
  </div>
</div>

<ng-template fDrawerFooterTemplate>
  <f-inline-alert *ngIf="showSelectAllBtn && !showClearAllBtn" [type]="alertType.Danger" class="d-block mb-3">
    {{ lastColumnTitle$ | async }}
  </f-inline-alert>

  <f-inline-alert *ngIf="showSubmitError" [type]="alertType.Danger" class="d-block mb-3">
    {{ submitErrorMessage$ | async }}
  </f-inline-alert>

  <div class="footer-with-buttons">
    <button
      type="button"
      class="btn btn-primary"
      data-automation-id="submit-button"
      [title]="showSelectAllBtn && !showClearAllBtn ? (lastColumnTitle$ | async) : (saveBtnTitle$ | async)"
      [disabled]="showSelectAllBtn && !showClearAllBtn"
      (click)="saveChanges()"
    >
      {{ saveBtnTitle$ | async }}
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      data-automation-id="cancel-button"
      [title]="cancelBtnTitle$ | async"
      (click)="cancelChanges()"
    >
      {{ cancelBtnTitle$ | async }}
    </button>
  </div>
</ng-template>
