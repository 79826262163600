import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { TimeFormatOptions } from '../models/date-formatting.models';
import { DateTimeFormattingService } from '../services/date-time-formatting.service';

@Pipe({
  name: 'caTime',
  pure: false
})
export class TimePipe extends BaseAsyncPipe<string, string, [TimeFormatOptions?]> implements PipeTransform {
  constructor(private dateTimeFormattingService: DateTimeFormattingService, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  getTransformer(value: string, formatOptions?: TimeFormatOptions): Observable<string> {
    return this.dateTimeFormattingService.formatTime(value, formatOptions);
  }
}
