import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { BreadcrumbItem } from '@capital-access/common/breadcrumbs';
import { LocalizationService, LocalizedString } from '@capital-access/common/localization';
import { BaseAsyncPipe } from '@capital-access/common/utils';

@Pipe({
  name: 'caLabel',
  pure: false
})
export class BreadcrumbLabelPipe extends BaseAsyncPipe<string, BreadcrumbItem> implements PipeTransform {
  constructor(private localization: LocalizationService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  override buildKeyName(item: BreadcrumbItem) {
    return item.url?.urlString;
  }

  getTransformer(item: BreadcrumbItem): Observable<string> {
    const label = item.label;
    if (isObservable(label)) {
      return label;
    }
    if (label instanceof LocalizedString) {
      return this.localization.localize(label);
    }
    return of(label);
  }
}
