import { Component, Input } from '@angular/core';

@Component({
  selector: 'f-badge-flag',
  templateUrl: './badge-flag.component.html'
})
export class FireflyBadgeFlagComponent {
  private _countryCode?: string;

  @Input() set countryCode(val: string) {
    if (val) this._countryCode = val;
  }
  get countryCode() {
    return `flags/${this._countryCode}.png`;
  }
}
