import { Directive, ElementRef, Host, HostBinding, Input, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { FireflyListItemCheckboxComponent } from '../../lists/list-item-checkbox/list-item-checkbox.component';
import { FireflyDropdownComponent } from '../dropdown/dropdown.component';

@Directive({
  selector: '[fDropdownItem]'
})
export class FireflyDropdownItemDirective implements OnInit, OnDestroy {
  @Input() disabled = false;
  private isCheckboxItem = false;
  private sub$ = Subscription.EMPTY;

  constructor(
    private host: ElementRef,
    @Optional() @SkipSelf() private fireflyDropdown: FireflyDropdownComponent,
    @Optional() @Host() hostCheckboxItemComponent: FireflyListItemCheckboxComponent
  ) {
    this.isCheckboxItem = !!hostCheckboxItemComponent;
  }

  @HostBinding('class.dropdown-item') dropdownClass = true;
  @HostBinding('class.dropdown-item-checkbox') get dropdownCheckboxClass() {
    return this.isCheckboxItem;
  }
  @HostBinding('class.disabled') get isListItemDisabled() {
    return this.disabled;
  }
  @HostBinding('attr.tabindex') get tabIndex() {
    return this.disabled ? -1 : 0;
  }

  ngOnInit() {
    this.sub$ = fromEvent(this.host.nativeElement, 'click', { capture: true }).subscribe($event => {
      if (!this.disabled) return;
      ($event as Event).stopImmediatePropagation();
      this.fireflyDropdown?.preventAutoClose();
    });
  }

  ngOnDestroy() {
    this.sub$.unsubscribe();
  }
}
