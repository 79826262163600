<ng-container *ngFor="let line of lines; let index = index; let isFirst = first">
  <svg:g
    f-line
    [data]="line"
    [xScale]="xScale"
    [yScale]="yScale"
    [curved]="curved"
    [lineWidth]="lineWidth"
    [circleRadius]="circleRadius"
    [lineClasses]="lineClasses[index] || []"
    [showPointerLine]="isFirst ? showPointerLine : false"
    [activeDataPointId]="activeDataPointId"
    [nearestXPointer]="nearestXPointer"
    [animationDuration]="animationDuration"
    [animationDelay]="animationDelay"
    [animation]="animation"
  />
</ng-container>
