import { Injectable } from '@angular/core';
import { IconSettingsService } from '@progress/kendo-angular-icons';
import {
  calendarIcon,
  cancelIcon,
  caretAltDownIcon,
  caretAltUpIcon,
  clockIcon,
  fileDataIcon,
  filePdfIcon,
  filePresentationIcon,
  fileTxtIcon,
  moreVerticalIcon,
  sortAscSmallIcon,
  sortDescSmallIcon,
  SVGIcon,
  xIcon
} from '@progress/kendo-svg-icons';
import * as icons from './kendo-icons.constants';

@Injectable()
export class KendoIconsService extends IconSettingsService {
  private svgDictionary: { [key: string]: SVGIcon } = {
    [caretAltUpIcon.name]: this.createIcon(icons.caretUpPath, 'k-caret-up'),
    [caretAltDownIcon.name]: this.createIcon(icons.caretDownPath, 'k-caret-down'),
    [sortAscSmallIcon.name]: this.createIcon(icons.caretUpPath, 'k-caret-up'),
    [sortDescSmallIcon.name]: this.createIcon(icons.caretDownPath, 'k-caret-down'),
    [moreVerticalIcon.name]: this.createIcon(icons.dotsYPath, 'k-dots-y'),
    [clockIcon.name]: this.createIcon(icons.clockPath, 'k-clock', '0 0 32 32'),
    [calendarIcon.name]: this.createIcon(icons.calendarPath, 'k-calendar', '0 0 32 32'),
    [xIcon.name]: this.createIcon(icons.closePath, 'k-close'),
    // this string key is used due to its absence in the list https://www.telerik.com/kendo-angular-ui/components/icons/svgicon/svgicon-list/
    // this approach was proposed for the issue https://github.com/telerik/kendo-angular/issues/4302
    'refresh-sm': this.createIcon(icons.retryPath, 'k-retry'),
    [filePdfIcon.name]: this.createIcon(icons.pdfPath, 'k-pdf'),
    [fileDataIcon.name]: this.createIcon(icons.xlsPath, 'k-xls'),
    [cancelIcon.name]: this.createIcon(icons.closePath, 'k-cancel'),
    [fileTxtIcon.name]: this.createIcon(icons.wordPath, 'k-word'),
    [filePresentationIcon.name]: this.createIcon(icons.pptPath, 'k-ppt')
  };

  getSvgIcon(svgIconName: string): SVGIcon {
    return this.svgDictionary[svgIconName] || super.getSvgIcon(svgIconName);
  }

  private createIcon(content: string, name: string, viewBox: string = '0 0 24 24'): SVGIcon {
    return { name, content, viewBox };
  }
}
