import { NgModule } from '@angular/core';
import { provideNavConfig } from '@capital-access/common/navigation';
import { CommonRouterModule } from '@capital-access/common/router';
import { EMAILS_PATH } from './constants';
import { EmailsNavConfigProvider } from './emails-nav-config.provider';
import { CanAccessEmails } from './emails-navigation-guard';

export const loadModule = () => import('@capital-access/emails/client').then(m => m.EmailsClientModule);

// @dynamic
@NgModule({
  imports: [
    CommonRouterModule.forFeature({
      authorized: [
        {
          path: EMAILS_PATH,
          canActivate: [CanAccessEmails],
          loadChildren: loadModule
        }
      ]
    })
  ],
  providers: [provideNavConfig(EmailsNavConfigProvider), CanAccessEmails]
})
export class EmailsClientLazyModule {}
