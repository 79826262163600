<div class="d-flex flex-column justify-content-between h-100">
  <div>
    <p class="mb-5">
      S&P Global Limited and its affiliates Ipreo LLC, Ipreo Limited and Ipreo Data Inc. and Markit North America,
      Inc.(collectively, “S&P”) is not registered as a broker or dealer with the U.S. Securities and Exchange Commission
      (SEC) nor is it a member of a self-regulatory organization such as the Financial Industry Regulatory Authority,
      Inc. (FINRA) or of the Securities Investor Protection Corporation (SIPC). Outside of the UK, S&P does not engage
      in any activities that will require it to register as a broker or dealer in the jurisdiction in which such
      services are performed. Ipreo Limited is authorized and regulated by the UK Financial Conduct Authority under
      reference number 775369 on the Financial Services Register at
      <a href="https://www.fca.org.uk/register/" target="_blank" class="link link-primary"
        >https://www.fca.org.uk/register/</a
      >.
    </p>

    <p class="mb-5">
      None of S&P, its affiliates or any of their third party providers is liable for any informational errors,
      incompleteness, or delays, or for any actions taken or decisions made in reliance on the data or any other
      information contained herein.
    </p>

    <p class="mb-5">
      The documents and information contained herein, including descriptions and illustrations, are the proprietary and
      confidential information of S&P and its affiliates or their third party providers.
    </p>

    <p class="mb-5">
      The information, opinions and recommendations presented by guest authors in any reports or newsletters do not
      necessarily reflect the views of S&P or any affiliates. Statements and options expressed in any articles, reviews
      and other materials herein are those of the article’s authors.
    </p>

    <p>
      For additional disclaimers and other important terms, see our
      <a [href]="settings.gcpTermsOfUseUrl" target="_blank" class="link link-primary">Terms of Use</a>
    </p>
  </div>

  <span class="text-muted body-50 mt-6" data-automation-id="watermark"
    >© {{ currentYear }} S&P Global. All Rights Reserved</span
  >
</div>
