import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonGlobalizationModule } from '@capital-access/common/globalization';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { CommonRouterModule } from '@capital-access/common/router';
import {
  FireflyBadgeFlagModule,
  FireflyInnerErrorModule,
  FireflySuggesterModule
} from '@capital-access/firefly/components';
import { CrmFormAddressInputComponent } from './components/crm-form-address-input/crm-form-address-input.component';
import { CrmFormPhoneInputComponent } from './components/crm-form-phone-input/crm-form-phone-input.component';
import { CrmModifiedToastComponent } from './components/custom-toast/crm-modified-toast.component';
import { CrmModifiedToastDirective } from './components/custom-toast/crm-modified-toast.directive';
import { CrmModifiedToastService } from './components/custom-toast/crm-modified-toast.service';
import { CrmModifiedToastStateService } from './components/custom-toast/crm-modified-toast-state.service';
import { CrmDrawerErrorComponent } from './components/drawer-error/crm-drawer-error.component';
import { CrmFormExistingAddressComponent } from './components/existing-address/crm-form-existing-address.component';
import { CrmFormExistingItemComponent } from './components/existing-item/crm-form-existing-item.component';
import { CrmFormExistingPhonesComponent } from './components/existing-phones/crm-form-existing-phones.component';
import { CRM_COMMON_LOCALIZATION_SCOPE } from './model/constants';
import { SearchAddressService } from './services/search-address.service';

@NgModule({
  imports: [
    CommonModule,
    CommonLocalizationModule.forFeature(CRM_COMMON_LOCALIZATION_SCOPE),
    FireflySuggesterModule,
    ReactiveFormsModule,
    CommonRouterModule,
    CommonGlobalizationModule,
    FireflyBadgeFlagModule,
    FireflyInnerErrorModule
  ],
  declarations: [
    CrmDrawerErrorComponent,
    CrmFormPhoneInputComponent,
    CrmFormAddressInputComponent,
    CrmFormExistingAddressComponent,
    CrmFormExistingItemComponent,
    CrmFormExistingPhonesComponent,
    CrmModifiedToastComponent,
    CrmModifiedToastDirective
  ],
  exports: [
    CrmDrawerErrorComponent,
    CrmFormPhoneInputComponent,
    CrmFormAddressInputComponent,
    CrmFormExistingAddressComponent,
    CrmFormExistingItemComponent,
    CrmFormExistingPhonesComponent,
    CrmModifiedToastDirective,
    CrmModifiedToastComponent
  ],
  providers: [SearchAddressService, CrmModifiedToastStateService, CrmModifiedToastService]
})
export class CrmCommonModule {}
