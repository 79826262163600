<f-dropdown
  caLocalizationScope="navigation"
  [icon]="icon"
  [btnStyle]="'btn-secondary'"
  [tooltip]="'newEntityTooltip' | caLocalize"
>
  <f-list>
    <f-list-item *ngFor="let item of list; let index = index" fDropdownItem (click)="itemClick.emit(index)">
      {{ item.localizationKey | caLocalize }}
    </f-list-item>
  </f-list>
</f-dropdown>
