import { createSelectorFactory, resultMemoize, Selector } from '@ngrx/store';

/**
 * Maps tuple into tuple of selectors
 * @example
 * Selectors<[string, number]> = [Selector<any, string>, Selector<any, number]
 **/
type Selectors<T extends unknown[]> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [TKey in keyof T]: Selector<any, T[TKey]>;
};

/**
 * Create selector which emits value only when it changed.
 *
 * To determine if value is changed `isResultEquals` function from config is used
 */
export function createDistinctResultSelector<T, Types extends unknown[]>(
  config: { isResultEquals: (x: T, y: T) => boolean },
  ...selectors: [...Selectors<Types>, (...input: Types) => T]
) {
  return createSelectorFactory<unknown, T>(projectFn => resultMemoize(projectFn, config.isResultEquals))(...selectors);
}
