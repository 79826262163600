import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyMobileDateRangeComponent } from './components/mobile-date-range.component';
import { FireflyMobileDateTimeComponent } from './components/mobile-date-time-picker.component';
import { FireflyDatePickerDirective } from './directives/date-picker.directive';
import { FireflyDateRangeDirective } from './directives/date-range.directive';
import { FireflySchedulerDateFormattingDirective } from './directives/scheduler-date-formatting.directive';
import { FireflyTimePickerDirective } from './directives/time-picker.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FireflyDatePickerDirective,
    FireflyTimePickerDirective,
    FireflyMobileDateTimeComponent,
    FireflyMobileDateRangeComponent,
    FireflyDateRangeDirective,
    FireflySchedulerDateFormattingDirective
  ],
  exports: [
    FireflyDatePickerDirective,
    FireflyTimePickerDirective,
    FireflyDateRangeDirective,
    FireflySchedulerDateFormattingDirective
  ]
})
export class FireflyDateModule {}
