import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { createCustomField } from '@capital-access/common/actions';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import { CUSTOM_FIELD_CREATE_MODULE_ID, LOAD_CUSTOM_FIELD_CREATE_MODULE } from '../creation-loader-module-id.model';

@Injectable()
export class CreationEffects {
  addCustomField$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCustomField),
        switchMap(({ entityType, addBackButton }) =>
          this.commonLazyLoadingService
            .loadModule(CUSTOM_FIELD_CREATE_MODULE_ID, LOAD_CUSTOM_FIELD_CREATE_MODULE)
            .pipe(tap(moduleRef => moduleRef.instance.showCustomFieldCreationForm(entityType, addBackButton || false)))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private commonLazyLoadingService: CommonLazyLoadingService) {}
}
