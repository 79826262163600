import { SortDescriptor } from '@progress/kendo-data-query';
import get from 'lodash/get';
import { SortOptions } from '../models/sort-options';
import { compare, createCollator } from './comparer';

function toComparer<T>(descriptor: SortOptions) {
  const collator = createCollator(descriptor.needNumericSorting);
  return (a: T, b: T) =>
    compare(get(a, descriptor.field), get(b, descriptor.field), collator, descriptor.dir === 'desc');
}

function compileComparer<T>(descriptors: SortOptions[]) {
  return descriptors
    .map(descriptor => toComparer<T>(descriptor))
    .reduce(
      (acc, curr) => (a: T, b: T) => acc(a, b) || curr(a, b),
      (_a: T, _b: T) => 0
    );
}

function containsNumericSymbolsOnly(value: string) {
  return /^[0-9]+$/.test(value);
}

export function orderByNullLast<T>(data: T[], descriptors: SortDescriptor[]): T[] {
  if (!data || !data.length || !descriptors || !descriptors.length) {
    return data;
  }

  const tempData = [...data];
  const sortOptions: SortOptions[] = [];

  descriptors.forEach(descriptor => {
    const containsStringValue = tempData
      .map(item => get(item, descriptor.field))
      .some(item => !containsNumericSymbolsOnly(item));

    sortOptions.push({ ...descriptor, needNumericSorting: !containsStringValue });
  });

  const comparer = compileComparer<T>(sortOptions);
  tempData.sort(comparer);

  return tempData;
}
