import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonBreadcrumbsModule } from '@capital-access/common/breadcrumbs';
import { CommonRouterModule } from '@capital-access/common/router';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { BreadcrumbCanNavigatePipe } from './pipes/breadcrumb-can-navigate.pipe';
import { BreadcrumbLabelPipe } from './pipes/breadcrumb-label.pipe';

@NgModule({
  imports: [CommonModule, CommonRouterModule, CommonBreadcrumbsModule],
  declarations: [BreadcrumbsComponent, BreadcrumbLabelPipe, BreadcrumbCanNavigatePipe],
  exports: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {}
