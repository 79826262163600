import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LocationSearchRepository, PlaceDetailsSearchType } from '@capital-access/common/location-search';

@Injectable()
export class SearchAddressService {
  constructor(private repository: LocationSearchRepository) {}

  searchAddress(text: string) {
    return this.repository.queryAutocomplete(text).pipe(map(v => v.predictions));
  }

  getAddressDetails(placeId: string) {
    const addressFields: PlaceDetailsSearchType[] = ['PlaceId', 'Name', 'FormattedAddress', 'AddressComponent'];
    return this.repository.getPlaceDetails(placeId, addressFields).pipe(map(r => r.result));
  }
}
