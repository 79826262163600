import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from './models/modal-options';

@Component({
  selector: 'f-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyModalComponent implements OnInit {
  context!: ModalOptions;

  @ViewChild('body', { static: true, read: ElementRef }) modalBody!: ElementRef;
  @ViewChild('dynamicContent', { static: true, read: ViewContainerRef })
  dynamicContent!: ViewContainerRef;

  constructor(public modal: NgbActiveModal, protected cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.loadComponent();
  }

  confirmClick() {
    this.modal.close();
  }

  loadComponent() {
    const component = this.dynamicContent.createComponent(this.context.component);
    component.instance.context = this.context.context;
    component.instance.modalInstance = this.modal;
  }

  get contentClass() {
    return this.context.hideDefaultFooter ? 'custom-modal-body' : 'modal-body';
  }
}
