import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'f-multiline-text-truncate',
  templateUrl: './multiline-text-truncate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultilineTextTruncateComponent implements OnInit, AfterViewInit {
  @Input() title: string = '';
  @Input() rootCssClass = '';

  @Input() dataAutomationId: string = '';
  @Input() textLinesNumber: number = 2;

  truncatedTitle: string = '';

  @ViewChild('titleContainer', { static: true })
  protected titleContainerRef!: ElementRef;

  @ContentChild('titleElement', { static: false })
  protected titleTemplateRef!: TemplateRef<unknown>;

  ngOnInit(): void {
    this.truncatedTitle = this.title ?? '';
  }

  ngAfterViewInit(): void {
    this.updateTitle();
  }

  updateTitle(): void {
    this.zone.runOutsideAngular(() => {
      this.setVisibleHeight();
      if (this.isTitleOverflow) {
        this.truncateTitle();
      }
    });
  }

  changeTitle(text: string): void {
    this.title = text;
    this.setVisibleTitle(text);
    this.updateTitle();
  }

  private setVisibleTitle(text: string): void {
    this.truncatedTitle = text;
    this.cd.detectChanges();
  }

  private setVisibleHeight(): void {
    const titleElements = this.titleContainerRef.nativeElement.children;
    if (!titleElements.length) return;
    const elementLineHeight = Array.from(titleElements).reduce<number>(
      (max, child) => Math.max(max, parseFloat(getComputedStyle(child as HTMLElement).lineHeight)),
      0
    );
    this.titleContainerRef.nativeElement.style.maxHeight = `${elementLineHeight * this.textLinesNumber}px`;
  }

  private get isTitleOverflow(): boolean {
    return this.titleContainerRef.nativeElement?.clientHeight < this.titleContainerRef.nativeElement.scrollHeight;
  }

  private truncateTitle(): void {
    const ellipsis = '…';

    let maxChunk = '';
    let chunk: string;

    let low = 0;
    let mid: number;
    let high = this.title.length;

    // Binary Search
    while (low <= high) {
      mid = low + ((high - low) >> 1); // Integer division

      chunk = this.getTrimmedTitleByLength(mid + 1, ellipsis);
      this.setVisibleTitle(chunk);

      if (this.isTitleOverflow) {
        // too big, reduce the chunk
        high = mid - 1;
      } else {
        // chunk valid, try to get a bigger chunk
        low = mid + 1;
        maxChunk = maxChunk.length > chunk.length ? maxChunk : chunk;
      }
    }

    maxChunk = this.getTrimmedTitleByLength(maxChunk.length - ellipsis.length - 1, ellipsis);
    this.setVisibleTitle(maxChunk);
  }

  private getTrimmedTitleByLength(substringIndex: number, ellipsis: string): string {
    return this.trimRight(this.title.substring(0, substringIndex)) + ellipsis;
  }

  private trimRight(text: string): string {
    return text.replace(/\s*$/, '');
  }

  constructor(protected cd: ChangeDetectorRef, protected zone: NgZone) {}
}
