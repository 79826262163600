import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FireflyBaseDraggableListComponent } from '../base-draggable-list/base-draggable-list.component';

@Component({
  selector: 'f-draggable-nested-list',
  templateUrl: './draggable-nested-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyDraggableNestedListComponent extends FireflyBaseDraggableListComponent {
  @Input() isParentDragging = false;
  @Input() actionBtnIcon?: string;
}
