import { CA_ADMIN_ROLE, CA_APPLICATION, CA_COMPASS_ROLE, CI_APPLICATION } from '@capital-access/common/user';
import { AppSwitcherItem, EntitlementType } from './models';

export const appSwitcherItems: Record<string, AppSwitcherItem> = {
  admin: {
    name: 'Capital Access Admin',
    entitlement: { sourceId: CA_ADMIN_ROLE, type: EntitlementType.Function },
    icon: 'f-i-group',
    order: 0
  },
  capitalAccess: {
    name: 'Capital Access',
    entitlement: { sourceId: CA_APPLICATION, type: EntitlementType.Application },
    icon: 'f-i-suitcase',
    order: 1
  },
  capitalInsight: {
    name: 'Capital Insight',
    entitlement: { sourceId: CI_APPLICATION, type: EntitlementType.Application },
    icon: 'f-i-insight',
    order: 2
  },
  capitalIqPro: {
    name: 'Capital IQ Pro',
    entitlement: { sourceId: CA_APPLICATION, type: EntitlementType.Application },
    icon: 'f-i-dashboard',
    order: 3
  },
  compass: {
    name: 'Compass',
    entitlement: { sourceId: CA_COMPASS_ROLE, type: EntitlementType.Function },
    icon: 'f-i-compass',
    order: 4
  }
};
