import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[fVirtualGridFixedHeight]'
})
export class VirtualGridFixedHeightDirective implements OnInit, OnDestroy {
  private anchorElement?: HTMLElement;

  constructor(private host: ElementRef) {}

  ngOnInit() {
    const virtualGrid = this.host.nativeElement.closest('.k-grid-virtual');
    if (!virtualGrid) return;
    this.anchorElement = this.host.nativeElement.closest('table.k-grid-table');
    this.anchorElement?.classList.add('transform-none');
  }

  ngOnDestroy() {
    this.anchorElement?.classList.remove('transform-none');
  }
}
