import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'g[f-bubble]',
  templateUrl: './bubble.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('bubbleAnimation', [
      transition(
        ':enter',
        [
          style({
            opacity: 0
          }),
          animate(
            '{{duration}}ms {{delay}}ms ease-out',
            style({
              opacity: 1
            })
          )
        ],
        { params: { duration: 0, delay: 0 } }
      )
    ])
  ]
})
export class FireflyBubbleComponent {
  @Input() animation = false;
  @Input() bubbleShowZero = false;
  @Input() animationDelay = 0;
  @Input() animationDuration = 120;
  @Input() value!: string | number;
  @Input() bubbleClasses: string[] | string = [];
  @Input() textClasses: string[] | string = [];
  @Input() bubbleRadius = 10;
  @Input() scaleFactor = 1;
  @Input() cx!: number;
  @Input() cy!: number;

  get radius(): number {
    return this.bubbleRadius * this.scaleFactor;
  }

  get isVisible(): boolean {
    if (typeof this.value === 'number') return !!this.value || this.bubbleShowZero;
    return !!this.value;
  }
}
