import isUndefined from 'lodash/isUndefined';
import { EmptyConstructor } from '@capital-access/common/utils';
import { SettingsConstructor } from './internal/settings-constructor';
import { fullSettingKey, KEY_DELIMITER, shortSettingKey } from './internal/user-settings.utils';
import { SerializedSettingsEntity } from './settings-entity';

export class UserSettingsSection {
  public static keyDelimiter = KEY_DELIMITER;

  private readonly entityMap: Record<string, string | null> = {};
  private readonly entities: SerializedSettingsEntity[];

  public get settingsKeys() {
    return Object.keys(this.entityMap);
  }

  constructor(private prefix: string, allSettings: SerializedSettingsEntity[]) {
    this.entities = allSettings.filter(entity => entity.id.startsWith(this.path));

    this.entities.forEach(entity => {
      const key = shortSettingKey(this.path, entity.id);
      this.entityMap[key] = entity.value;
    });
  }

  get path() {
    return this.prefix;
  }

  get count() {
    return this.entities.length;
  }

  getSection(section: string) {
    if (section === '') {
      return this;
    }

    return new UserSettingsSection(fullSettingKey(this.path, section), this.entities);
  }

  /**
   * Return serialized setting value or null if setting is missing.
   * @see [Wiki](https://dev.azure.com/bdc-ihsmarkit/capital-access/_wiki/wikis/capital-access.wiki/82/User-Settings-(Preferences)?anchor=working-with-a-single-setting) for usage examples
   * @param key setting path
   * @returns serialized setting value or null
   */
  getValue(key: string): string | null {
    return this.entityMap[key] || null;
  }

  /**
   * Returns T with properties set from user settings by the rules defined in T class with decorators.
   *
   * __!Note__ that only property with decorators will be set from user settings.
   *
   * @see [Wiki](https://dev.azure.com/bdc-ihsmarkit/capital-access/_wiki/wikis/capital-access.wiki/82/User-Settings-(Preferences)?anchor=settingsobject-api) for more.
   *
   * @example
   * ```ts
   * import { dictionary, value, nested, type } from '`@`capital-access/common/user';
   *
   * enum Size { Small = 1, Medium = 2, Big = 3 }
   *
   * class NestedSettings {
   *    // This property will be retrieved from 'text' setting.
   *    // If value is missing or it's not a string default '' will be used
   *    `@`value(type.string) text: = ''
   *
   *    //This property will be retrieved from 'size' setting.
   *    //If value is missing or it's not a valid enum value, default Size.Medium value will be used instead
   *    `@`value(type.enum(Size)) size = Size.Medium
   * }
   *
   * class MySettings {
   *    // This property will be retrieved from 'id' setting.
   *    // If value is missing or it's not an integer null will be set
   *    `@`value(type.number) id!: number | null
   *
   *    // This property will be retrieved from 'nested' sub section by the rules defined in NestedSettings class
   *    `@`nested(NestedSettings) nested!: NestedSettings
   *
   *    // Here dict will be set to dictionary with all keys prefixed with 'dict' present in user settings
   *    // Values of the dictionary will be set by the rules defined in NestedSettings class
   *    `@`dictionary(NestedSettings) dict!: Record<string, NestedSettings>;
   *
   *    // This property will NOT be set from user settings
   *    ignored: boolean = false;
   * }
   *
   * //------------Usage-------------
   *
   * settingsSection.toObject(MySettings);
   * ```
   * @param ctor ObjectClass
   */
  toObject<T>(ctor: EmptyConstructor<T>): T {
    const settingsCtor = SettingsConstructor.fromCtor(ctor);
    return settingsCtor.create(this);
  }

  equals(other: UserSettingsSection) {
    return (
      this.prefix === other.prefix &&
      this.entities.length === other.entities.length &&
      Object.keys(this.entityMap).every(k => this.entityMap[k] === other.entityMap[k])
    );
  }

  diff(data: SerializedSettingsEntity[]) {
    return data
      .map<SerializedSettingsEntity>(entity => ({
        id: entity.id,
        value: this.mapSettingsValue(entity.value)
      }))
      .filter(entity => {
        const currentValue = isUndefined(this.entityMap[entity.id]) ? null : this.entityMap[entity.id];
        return currentValue !== entity.value;
      });
  }

  private mapSettingsValue(value: string | null) {
    // empty string means setting is missing and so we should delete it.
    if (value === '') {
      return null;
    }
    return value;
  }
}
