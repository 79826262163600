import { Pipe, PipeTransform } from '@angular/core';
import { excelNumberFormat, ExcelOptions } from '../utils/excel-formats';

/**
 * returns excel number format
 * Supported formats:
 *  - decimal: decimal format. Fraction indicates fraction part availability.
 *  - millions: quantity format for millions. Fraction indicates fraction part availability.
 *  - thousands: quantity format for thousands. Fraction indicates fraction part availability.
 */
@Pipe({
  name: 'caExcelNumberFormat'
})
export class ExcelNumberFormatPipe implements PipeTransform {
  transform<TType extends keyof typeof excelNumberFormat>(value: TType, options?: ExcelOptions) {
    return excelNumberFormat[value](options?.colors, options?.fraction);
  }
}
