import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import {
  FireflyDropdownModule,
  FireflyFormattingModule,
  FireflyListModule,
  FireflySuggesterModule,
  FireflyTooltipModule
} from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { SecuritySelectorComponent } from './security-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FireflyListModule,
    FireflyTooltipModule,
    FireflyDropdownModule,
    FireflySuggesterModule,
    FireflyFormattingModule,
    CommonLocalizationModule.forFeature(COMMON_COMPONENTS_LOCALIZATION_SCOPE)
  ],
  declarations: [SecuritySelectorComponent],
  exports: [SecuritySelectorComponent]
})
export class CommonSecuritySelectorModule {}
