import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { FireflyKendoIconsModule } from '../kendo-icons/kendo-icons.module';
import { FireflyFileSelectComponent } from './file-select/file-select.component';
import { FireflyFileUploaderComponent } from './file-uploader/file-uploader.component';

@NgModule({
  imports: [CommonModule, UploadsModule, FireflyKendoIconsModule.forRoot()],
  declarations: [FireflyFileSelectComponent, FireflyFileUploaderComponent],
  exports: [FireflyFileSelectComponent, FireflyFileUploaderComponent]
})
export class FireflyUploadsModule {}
