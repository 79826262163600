<ng-container caLocalizationScope="navigation">
  <f-skeleton-drawer *ngIf="(loadCsAdvisorStatus$ | async) === loadStatus.Loading"></f-skeleton-drawer>

  <ng-container *ngIf="csAdvisorData$ | async as csAdvisorData; else noCsAdvisorData">
    <div
      *ngIf="csAdvisorData.phoneNumber || csAdvisorData.email"
      class="border-bottom text-break pb-4 mb-4"
      data-automation-id="client-service-info"
    >
      <h4 class="heading-20 mb-3">{{ 'clientEngagementAdvisor' | caLocalize }}</h4>
      <div *ngIf="csAdvisorData.firstName || csAdvisorData.lastName" class="fw-bold mb-1">
        {{ csAdvisorData.firstName }} {{ csAdvisorData.lastName }}
      </div>
      <div *ngIf="csAdvisorData.phoneNumber" class="mb-1">
        <a class="link link-main" href="tel:{{ csAdvisorData.phoneNumber }}">{{ csAdvisorData.phoneNumber }}</a>
      </div>
      <div *ngIf="csAdvisorData.email">
        <a class="link link-primary" href="mailto:{{ csAdvisorData.email }}">{{ csAdvisorData.email }}</a>
      </div>
    </div>
  </ng-container>

  <ng-template #noCsAdvisorData>
    <div
      *ngIf="(loadCsAdvisorStatus$ | async) === loadStatus.Failed"
      data-automation-id="client-service-error"
      class="border-bottom mb-4"
    >
      <h4 class="heading-20">{{ 'clientEngagementAdvisor' | caLocalize }}</h4>
      <f-inner-error
        class="d-block w-fit-content pb-4 pt-3"
        [info]="'tryAgain' | caLocalize"
        [isFlexiblePlacement]="true"
        [isHorizontal]="true"
      ></f-inner-error>
    </div>
  </ng-template>

  <ng-container *ngIf="(loadCsAdvisorStatus$ | async) !== loadStatus.Loading">
    <h4 class="heading-20 mb-3" data-automation-id="client-support-title">{{ 'clientSupport' | caLocalize }}</h4>
    <div class="border-bottom pb-4 mb-4" data-automation-id="contacts-info">
      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted">Americas:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.americas }}">{{
          settings.contactSupport.phones.americas
        }}</a>
      </div>

      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted">Europe:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.europe }}">{{
          settings.contactSupport.phones.europe
        }}</a>
      </div>

      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted">Asia Pacific:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.asiaPacific }}">{{
          settings.contactSupport.phones.asiaPacific
        }}</a>
      </div>

      <div class="mb-4">
        <span class="d-inline-block w-25 text-muted">Japan:</span>
        <a class="link link-main" href="tel:{{ settings.contactSupport.phones.japan }}">{{
          settings.contactSupport.phones.japan
        }}</a>
      </div>

      <div class="mb-2">
        <span class="d-inline-block w-25 text-muted" data-automation-id="contact-email"
          >{{ 'email' | caLocalize }}:</span
        >
        <a class="link link-primary" href="mailto:{{ settings.contactSupport.email }}">{{
          settings.contactSupport.email
        }}</a>
      </div>
    </div>

    <div>
      <h4 class="heading-20 mb-3" data-automation-id="helpful-links-title">{{ 'helpfulLinks' | caLocalize }}</h4>
      <p class="mb-3">
        <a class="link link-primary" [href]="settings.licenseAgreement.termsOfUseUrl" target="_blank">
          {{ 'thirdPartyData' | caLocalize }}
        </a>
      </p>
      <p class="mb-3">
        <a class="link link-primary" [href]="settings.licenseAgreement.gcpTermsOfUseUrl" target="_blank">{{
          'termsOfUse' | caLocalize
        }}</a>
      </p>
      <p class="mb-3">
        <a class="link link-primary" [href]="settings.licenseAgreement.privacyPolicyUrl" target="_blank">{{
          'privacyPolicy' | caLocalize
        }}</a>
      </p>
      <p class="mb-3">
        <a class="link link-primary" href="javascript:;" (click)="showDisclaimer()">{{ 'disclaimer' | caLocalize }}</a>
      </p>
      <p>
        <a class="link link-primary" href="javascript:;" (click)="exportContactsWithdrawList()">{{
          'withdrawList' | caLocalize
        }}</a>
      </p>
    </div>
  </ng-container>
</ng-container>

<span class="text-muted body-50 mb-6 ms-6 fixed-bottom" data-automation-id="watermark"
  >© {{ currentYear }} S&P Global. All Rights Reserved</span
>
