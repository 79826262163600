import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { CurrencyFormatOptions, CurrencyValueOptions } from '../models/currency-formatting.models';
import { CurrencyFormattingService } from '../services/currency-formatting.service';

@Pipe({
  name: 'caCurrency',
  pure: false
})
export class CurrencyPipe
  extends BaseAsyncPipe<
    string,
    number | null | undefined,
    [Partial<CurrencyFormatOptions>?, Partial<CurrencyValueOptions>?]
  >
  implements PipeTransform
{
  constructor(changeDetectorRef: ChangeDetectorRef, private currencyFormattingService: CurrencyFormattingService) {
    super(changeDetectorRef);
  }

  getTransformer(
    value: number | null | undefined,
    formatOptions?: Partial<CurrencyFormatOptions>,
    valueOptions?: Partial<CurrencyValueOptions>
  ): Observable<string> {
    return this.currencyFormattingService.formatCurrency(value, formatOptions, valueOptions);
  }
}
