import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FireflyBarSeriesComponent } from '@capital-access/firefly/components';
import { ConcentrationChartMobilePopoverComponent } from '../concentration-chart-mobile-popover/concentration-chart-mobile-popover.component';
import { DecilesIndexRange, FactorInfo } from '../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-bar-series]',
  templateUrl: 'concentration-bar-series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcentrationBarSeriesComponent extends FireflyBarSeriesComponent {
  @Input() factorInfo!: FactorInfo;
  @Input() highlightedIndexRange!: DecilesIndexRange;
  @Input() override bubblesAnimationDuration = 0;

  getPopoverOffset(value: number | string) {
    return value ? this.bubbleOffset + this.bubbleRadius : 0;
  }

  override mobileModalComponent = ConcentrationChartMobilePopoverComponent;
  override maxBubbleRadius = 30;
  override minBubbleRadius = 6;

  get selectedDecileIndex() {
    return this.factorInfo.securityDecileIndex;
  }

  override handleMobileModal(activeBarIndex: number) {
    if (this.popoverTemplate) {
      super.handleMobileModal(activeBarIndex);
    }
  }

  decileIsHighlighted(idx: number) {
    if (this.highlightedIndexRange && this.selectedDecileIndex! >= 0) {
      if (idx >= this.highlightedIndexRange.startIndex && idx <= this.highlightedIndexRange.endIndex) {
        return true;
      }
      return false;
    } else return;
  }

  override getModalContext(activeBarIndex: number, sliceStartIndex: number, sliceEndIndex: number) {
    const context = super.getModalContext(activeBarIndex, sliceStartIndex, sliceEndIndex);
    return {
      ...context,
      factorInfo: this.factorInfo
    };
  }
}
