import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  Output,
  TemplateRef
} from '@angular/core';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import { scaleBand, scaleLinear } from 'd3-scale';
import isNull from 'lodash/isNull';
import { Breakpoint } from '../../../utils';
import { getTicks } from '../../common/axis';
import type { ChartDataEntry, ChartDataSeries, ChartDimensions, ChartLegendOptions } from '../index';

@Component({
  selector: 'f-base-chart',
  template: ''
})
export class FireflyBaseChartComponent implements OnChanges {
  @Input() data!: ChartDataEntry[] | ChartDataSeries[];
  @Input() integerYAxis = false;
  @Input() fitContainer = false;
  @Input() view: ChartDimensions = {
    width: 300,
    height: 200
  };
  @Input() interactions: { hover: boolean; click: boolean; preventMobile?: boolean } = {
    hover: true,
    click: false,
    preventMobile: false
  };
  @Input() popoverTemplate!: TemplateRef<unknown> | null;
  @Input() popoverPlacement = 'top bottom';
  @Input() popoverOpenDelay = 200;
  @Input() popoverCssClass?: string;
  @Input() animationDuration = 750;
  @Input() animationDelay = 0;
  @Input() animation = true;
  @Input() labelsOffset = 21;
  @Input() showLabels = true;
  @Input() showLineAxisTicksLabels = true;
  @Input() showYAxisTicksLabels = true;
  @Input() showXAxisTicksLabels = true;
  @Input() leftAxisLabelText!: string;
  @Input() showXAxisGridlines = false;
  @Input() showYAxisGridlines = true;
  @Input() showYAxisDomain = false;
  @Input() showXAxisDomain = true;
  @Input() truncateXAxisTicks = true;
  @Input() truncateYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() maxYAxisTicksCount = 10;
  @Input() xAxisTickLabelOffset = 15;
  @Input() xAxisTickLabelClasses: string | string[] = [];
  @Input() xAxisTickFormatting!: (d: unknown) => string;
  @Input() yAxisTickFormatting!: (d: unknown) => string;
  @Input() legendOptions!: ChartLegendOptions;
  @Input() chartPatternsTemplate!: TemplateRef<unknown> | null;
  @Input() activeBarIndex!: number | undefined;
  @Input() showBarsOptionalData = false;
  @Input() infoBubbleShowZero = false;
  @Input() showBorders = false;
  @Input() showLegend = true;
  @Input() condensedOnDesktop = false;
  @Input() condensedAxisOnMobile = true;

  @Output() barClick = new EventEmitter();
  @Output() barHover: EventEmitter<number> = new EventEmitter();

  @HostBinding('class') cssCass = 'd-block w-100 h-100';

  yDomain!: number[];
  xDomain!: string[];
  xScale!: ScaleBand<string>;
  yScale!: ScaleLinear<number, number>;
  dimensions!: ChartDimensions;

  protected padding = 0.3;
  protected minBarWidth = 30;
  protected minTicksCount = 6;
  protected legendRootClass = '';
  protected domainPaddingFactor = 0.05;
  protected containerElement!: HTMLElement;

  constructor(protected element: ElementRef, protected cdr: ChangeDetectorRef, protected zone: NgZone) {
    this.containerElement = element.nativeElement;
  }

  get condensedView() {
    return !this.interactions?.preventMobile && (window.innerWidth < Breakpoint.Sm || this.condensedOnDesktop);
  }

  get maxTicksCount() {
    let count = this.maxYAxisTicksCount;
    if (this.condensedView) {
      const halfTheMaxCount = Math.round(count / 2);
      count = halfTheMaxCount < this.minTicksCount ? this.minTicksCount : halfTheMaxCount;
    }
    return count;
  }

  ngOnChanges() {
    this.update(this.dimensions || this.view);
  }

  getXScale(view: ChartDimensions): ScaleBand<string> {
    this.xDomain = this.getXDomain(this.data);
    const scale = scaleBand().range([0, view.width]).padding(this.padding).domain(this.xDomain);
    const adjustedPadding = this.condensedView && scale.bandwidth() < this.minBarWidth ? 0 : this.padding;

    return scale.padding(adjustedPadding);
  }

  getYScale(view: ChartDimensions): ScaleLinear<number, number> {
    this.yDomain = this.getYDomain(this.data);
    const [domainMinValue, domainMaxValue] = this.yDomain;
    let scale = scaleLinear().range([view.height, 0]).domain(this.yDomain).nice();
    let ticks = getTicks(scale, this.maxTicksCount);
    let ticksMaxValue = ticks.slice(-1)[0];
    let ticksMinValue = ticks[0];

    while (domainMaxValue > ticksMaxValue || domainMinValue < ticksMinValue) {
      const adjustedDomain = this.getDomainWithPaddings(scale.domain());
      scale = scaleLinear().range([view.height, 0]).domain(adjustedDomain).nice();
      ticks = getTicks(scale, this.maxTicksCount);
      ticksMaxValue = ticks.slice(-1)[0];
      ticksMinValue = ticks[0];
    }

    if (!this.showBarsOptionalData) {
      return scale.domain([ticksMinValue, ticksMaxValue]);
    }

    const step = this.getScaleStep(scale);
    const threshold = (ticksMaxValue / 100) * 80;

    if (this.shouldAdjustYScaleDomain(threshold)) {
      ticksMaxValue = getTicks(scale.domain([ticksMinValue, ticksMaxValue + step]), this.maxTicksCount).slice(-1)[0];
    }

    return scale.domain([ticksMinValue, ticksMaxValue]);
  }

  onDimensionsChanged(dims: ChartDimensions) {
    const oldDims = JSON.stringify(this.dimensions);
    const newDims = JSON.stringify(dims);

    if (newDims === oldDims) return;

    this.dimensions = dims;
    this.xScale = this.getXScale(dims);
    this.yScale = this.getYScale(dims);
    this.cdr.detectChanges();
  }

  onBarClick($event: { data: ChartDataEntry } | null) {
    this.barClick.emit($event);
  }

  onBarHover($event: { x: number; index: number }) {
    this.barHover.emit($event.index);
  }

  protected update(dims: ChartDimensions) {
    this.xScale = this.getXScale(dims);
    this.yScale = this.getYScale(dims);
  }

  protected getXDomain(data: ChartDataEntry[] | ChartDataSeries[]): string[] {
    const dataEntry = data as { name: string }[];
    return dataEntry.map(d => d.name);
  }

  protected getYDomain(data: ChartDataEntry[] | ChartDataSeries[], startFromZero = true): number[] {
    const dataEntry = data as { name: string; value?: number; series?: ChartDataEntry[] }[];

    const values = dataEntry
      .map(d => {
        if ('series' in d) {
          const entry = d as ChartDataSeries;
          return (entry.series as ChartDataEntry[])
            .map(i => i.value!)
            .filter(i => !isNull(i))
            .reduce((prev, current) => prev + current);
        }

        return d.value;
      })
      .filter(v => typeof v === 'number') as number[];

    const adjustedValues = startFromZero ? [0, ...values] : values;
    const min = Math.min(...adjustedValues);
    let max = Math.max(...adjustedValues);

    if (this.integerYAxis) max = Math.ceil(max);

    if (min === max && 'series' in dataEntry[0]) {
      //to avoid not clear charts for stacked bars when all values in series are 0
      max = min + this.minTicksCount - 1;
    }

    return startFromZero ? [min, max] : this.getDomainWithPaddings([min, max]);
  }

  protected getContainerDims() {
    const dims = this.containerElement?.getBoundingClientRect();
    return dims?.width && dims?.height ? { width: dims.width, height: dims.height } : this.view;
  }

  protected shouldAdjustYScaleDomain(threshold: number) {
    const optionalData = [...this.data].filter(d => !!d.optional);
    if (!optionalData.length) return;
    return optionalData.some(d => (d.value as number) > threshold);
  }

  protected getScaleStep(scale: ScaleLinear<number, number>) {
    const ticks = getTicks(scale, this.maxTicksCount, this.integerYAxis);
    const zeroTickIdx = ticks.findIndex(tick => tick === 0);
    return zeroTickIdx === ticks.length - 1 ? Math.abs(ticks[zeroTickIdx - 1]) : ticks[zeroTickIdx + 1];
  }

  protected getDomainWithPaddings([min, max]: number[]) {
    const r = max - min;
    const pad = r === 0 ? this.domainPaddingFactor : r * this.domainPaddingFactor;
    const adjustedMin = (min / pad - 1) * pad;
    const adjustedMax = (max / pad + 1) * pad;
    if (min < 0 || max < 0) {
      return [adjustedMin, adjustedMax];
    } else {
      return [adjustedMin > 0 ? adjustedMin : min, adjustedMax];
    }
  }
}
