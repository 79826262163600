import { createAction, props } from '@ngrx/store';
import { HistoricalOwnershipReportFilters } from '../models/historical-ownership-filters.model';

export const openHistoricalOwnershipReportDrawer = createAction(
  scoped`Open Historical Ownership Report Drawer`,
  props<{
    reportedQuarters: string[];
    filters?: HistoricalOwnershipReportFilters;
  }>()
);

export const createHistoricalOwnershipReportFinished = createAction(
  scoped`Create Historical Ownership report Finished`
);

function scoped(templateString: TemplateStringsArray) {
  return '[Reporting Common] ' + templateString[0];
}
