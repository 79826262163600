import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Breakpoint } from '@capital-access/firefly/components';
import { ActionConfig, SpecialActions } from '../../+state/models';

@Component({
  selector: 'ca-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionPanelComponent {
  @Input() specialActions!: ActionConfig[] | null;

  @Output() startSpecialAction = new EventEmitter<SpecialActions>();

  get mobileBreakpoint(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  onStartSpecialAction(config: ActionConfig, disabled?: boolean) {
    if (disabled) {
      return;
    }
    this.startSpecialAction.emit(config.action);
  }
}
