import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, OnChanges } from '@angular/core';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import { scaleBand, scaleLinear } from 'd3-scale';
import isEqual from 'lodash/isEqual';
import { Breakpoint } from '../../utils';
import { FireflyBarChartComponent } from '../bar-chart';
import type { ChartDataEntry, ChartDimensions } from '../common';

@Component({
  selector: 'f-bar-line-chart',
  templateUrl: './bar-line-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyBarLineChartComponent extends FireflyBarChartComponent implements OnChanges {
  @Input() lineCurved = false;
  @Input() lineAxisLabelText!: string;
  @Input() showLineAxisGridlines = false;
  @Input() lineData!: ChartDataEntry[];
  @Input() lineClasses: string[] = [];
  @Input() lineAxisTickFormatting!: (d: unknown) => string;
  @Input() infoBubbleClasses: string[] = ['f-bg-bubble'];
  @Input() infoBubbleTextClasses: string[] = ['f-text-bubble', 'fw-bold'];
  @Input() startLineAxisDomainWithZero = true;
  @Input() infoBubbleScaleFactor = 1;
  @Input() lineCircleRadius = 4;
  @Input() lineWidth = 2.5;

  @HostBinding('class') cssCass = 'd-block h-100 position-relative';

  lineXDomain!: string[];
  lineYDomain!: number[];
  lineXScale!: ScaleBand<string>;
  lineYScale!: ScaleLinear<number, number>;
  pointerXCoordinate!: number | undefined;
  activeDataPointId = -1;

  chartComponent = FireflyBarLineChartComponent;

  ngOnChanges() {
    super.ngOnChanges();
    this.data = this.data.map((entry, index) => {
      const lineData = this.lineData[index];
      return { ...entry, lineData };
    });
  }

  protected update(dims: ChartDimensions) {
    super.update(dims);
    this.lineYScale = this.getLineYScale(dims);
    this.lineXScale = this.getLineXScale(dims);
  }

  getLineXScale(view: ChartDimensions): ScaleBand<string> {
    this.lineXDomain = this.getXDomain(this.lineData);
    const scale = scaleBand().range([0, view.width]).padding(this.padding).domain(this.lineXDomain);
    const adjustedPadding = this.condensedView && scale.bandwidth() < this.minBarWidth ? 0 : this.padding;
    return scale.padding(adjustedPadding);
  }

  getLineYScale(view: ChartDimensions): ScaleLinear<number, number> {
    this.lineYDomain = this.getYDomain(this.lineData, this.startLineAxisDomainWithZero);
    return scaleLinear().range([view.height, 0]).domain(this.lineYDomain).nice();
  }

  onDimensionsChanged(dims: ChartDimensions) {
    if (isEqual(this.dimensions, dims)) return;

    this.dimensions = dims;
    this.xScale = this.getXScale(dims);
    this.yScale = this.getYScale(dims);
    this.lineXScale = this.getLineXScale(dims);
    this.lineYScale = this.getLineYScale(dims);
    this.cdr.detectChanges();
  }

  onBarHover({ x, index }: { x: number; index: number }) {
    this.pointerXCoordinate = x;
    if (index !== null) {
      this.activeDataPointId = index;
      this.cdr.detectChanges();
    }
    super.onBarHover({ x, index });
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (window.innerWidth < Breakpoint.Sm) return;
    this.pointerXCoordinate = undefined;
    this.activeDataPointId = -1;
    this.cdr.detectChanges();
  }
}
