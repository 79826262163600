import { createAction, props } from '@ngrx/store';
import { HistoricalOwnershipReportFilters } from '@capital-access/reporting/common';
import { ReportSection } from '../../core/models/report-sections';

export const createHistoricalOwnershipReport = createAction(
  '[Reporting] Create Historical Ownership Report',
  props<{
    sections: ReportSection[];
    includeTables: boolean;
    reportedQuarters: string[];
    filters?: HistoricalOwnershipReportFilters;
  }>()
);
