import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { startWith, takeUntil } from 'rxjs';
import { ExcelColumn, KendoExcelColumn } from '@capital-access/common/utils';
import { FireflySharedGridComponent } from '@capital-access/firefly/components';
import { ExportableColumnDirective } from './exportable-column.directive';

@Component({
  template: '',
  standalone: true
})
export abstract class ExportableGridComponent extends FireflySharedGridComponent implements OnInit {
  @ViewChildren(ExportableColumnDirective) columns!: QueryList<ExportableColumnDirective>;

  ngOnInit() {
    if (this.grid) {
      this.grid.columnReorder.pipe(startWith(), takeUntil(this.destroyed$)).subscribe(() => this.updateBindings());
    }
    this.columnChooserService.applyChangesObs.pipe(takeUntil(this.destroyed$)).subscribe(() => this.updateBindings());
  }

  get exportableColumns(): ExcelColumn[] {
    return this.getColumns().map(column => column.toExcelColumn());
  }

  get exportableKendoColumns(): KendoExcelColumn[] {
    if (!this.columns) {
      return [];
    }
    return this.getColumns()?.map(column => column.toKendoExcelColumn());
  }

  private getColumns() {
    return this.columns
      .toArray()
      .filter(col => !col.hidden)
      .sort((a, b) => a.orderIndex - b.orderIndex);
  }

  private updateBindings() {
    setTimeout(() => {
      this.columns.forEach(col => {
        col.updateBindings();
      });
    });
  }
}
