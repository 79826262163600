import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges
} from '@angular/core';
import { FireflyModalService } from '../../../modal';
import { Breakpoint } from '../../../utils';
import { NestedValuePipe } from '../../../utils/formatting/pipes/nested-value.pipe';
import { FireflyLocalizationService } from '../../../utils/localization/firefly-localization.service';
import { GroupedSingleData } from '../../models/grouped-data.model';
import { SuggesterType } from '../../models/suggester-type.enum';
import { SuggesterManager } from '../../suggester-manager/suggester-manager.class';
import { isDisabledItem } from '../../utils/list-items/list-items.utils';
import { FireflyBaseSuggesterComponent } from '../base-suggester/base-suggester.component';

@Component({
  selector: 'f-single-suggester',
  templateUrl: './single-suggester.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySingleSuggesterComponent extends FireflyBaseSuggesterComponent implements OnDestroy, OnChanges {
  protected selectedItem: Record<string, unknown> | null = null;
  protected initialSelectedItem: Record<string, unknown> | null = null;
  protected initialSelectedValue = '';

  @Input() set selectedValue(value: string) {
    this.initialSelectedValue = value;
    this.updateValueForInput(value);
  }

  get listData(): GroupedSingleData {
    return this.suggesterManager.listData as GroupedSingleData;
  }

  get categoryClass() {
    return this.categoryPath ? 'suggester-category-list' : '';
  }

  get requiredAndInvalid() {
    return super.requiredAndInvalid && (this.inputValue.dirty || this.inputValue.touched);
  }

  get footerTemplateClasses() {
    return {
      'single-suggester-no-data': !this.listData.totalLength && this.suggesterManager.inputControlValue.length,
      'single-suggester-has-scroll':
        this.listData.totalLength > this.maxResultsCount && this.suggesterManager.inputControlValue.length
    };
  }

  isActive(value: string) {
    const selectedItemValue = this.nestedValuePipe.transform(this.selectedItem, this.valuePath);
    return (this.inputValue.value && value === this.inputValue.value) || value === selectedItemValue;
  }

  constructor(
    private host: ElementRef,
    public nestedValuePipe: NestedValuePipe,
    public changeDetectorRef: ChangeDetectorRef,
    private modalService: FireflyModalService,
    @Optional() private localizationService: FireflyLocalizationService
  ) {
    super(host, localizationService);
    this.suggesterManager = new SuggesterManager(this, SuggesterType.Single);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.lazyLoadPredefinedSet &&
      this.showPredefinedSet &&
      changes['items']?.firstChange &&
      !changes['items']?.currentValue.length
    ) {
      this.predefinedSetIsLoading = true;
    } else if (changes['items']?.currentValue) {
      this.suggesterManager.groupData(this.items, this.categoryPath);
      if (this.lazyLoadPredefinedSet && this.showPredefinedSet) {
        this.predefinedSetIsLoading = false;
        if (this.suggesterIsInFocus) this.suggesterManager.toggleDropdown(true);
      }
    }
    this.suggesterManager.onPropertiesChanges(changes);
  }

  onEnter(item: unknown, $event?: Event) {
    $event?.preventDefault();
    this.onElementClick(item);
  }

  onElementClick(item: unknown, $event?: Event): void {
    $event?.preventDefault();
    if (!isDisabledItem(item, this.disabledPath, this.nestedValuePipe.transform)) {
      this.updateValueForInput(this.nestedValuePipe.transform(item, this.valuePath));
      this.selectedItem = item as Record<string, unknown>;
      if (!this.modal) {
        this.suggesterManager.toggleDropdown(false);
        this.select.emit(item);
      }
    }
  }

  openMobileModal() {
    if (window.innerWidth >= Breakpoint.Sm || this.modal) return;

    this.modal = this.modalService.open({
      modalDialogClass: this.modalDialogClass,
      component: this.modalComponent,
      title: this.placeholder,
      context: this,
      mobile: true
    });

    this.modal.result
      .then(
        () => this.handleStateUpdate(),
        () => this.resetState()
      )
      .finally(() => {
        setTimeout(() => {
          this.inputElement.nativeElement.removeAttribute('readonly');
          this.inputElement.nativeElement.blur();
        });
        this.inputElement.nativeElement.setAttribute('readonly', true);
        this.suggesterManager.toggleDropdown(false);
        this.suggesterManager.clearUnmatchedQuery();
        this.modal = null;
      });
  }

  clear() {
    this.selectedItem = null;
    this.suggesterManager.clearInput();
    if (!this.modal) {
      this.initialSelectedValue = '';
      this.initialSelectedItem = null;
    }
  }

  protected resetState() {
    this.updateValueForInput(this.initialSelectedValue);
    if (this.initialSelectedValue) this.selectedItem = { ...this.initialSelectedItem };
  }

  protected handleStateUpdate() {
    const selectedItemValue = this.nestedValuePipe.transform(this.selectedItem, this.valuePath);
    if (
      this.suggesterManager.inputLengthValid &&
      this.inputValue.value === selectedItemValue &&
      this.inputValue.pristine
    ) {
      this.initialSelectedValue = this.nestedValuePipe.transform(this.selectedItem, this.valuePath);
      this.initialSelectedItem = { ...(this.selectedItem as object) };
      this.select.emit(this.selectedItem);
    } else {
      this.initialSelectedValue = this.clearUnmatchedQueryOnBlur ? '' : this.inputValue.value ?? '';
      if (this.initialSelectedItem) {
        this.initialSelectedItem = null;
        this.selectedItem = null;
        this.reset.emit();
      }
    }
  }

  protected updateValueForInput(value: string) {
    this.inputValue.patchValue(value, { emitEvent: false });
    this.inputValue.markAsPristine();
  }
}
