import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonRouterModule } from '@capital-access/common/router';
import {
  FireflyDrawerModule,
  FireflyDropdownWithInitialsModule,
  FireflyTopNavModule
} from '@capital-access/firefly/components';
import { LogoModule } from '../logo/logo.module';
import { ContactSupportModule } from '../top-nav/contact-support/contact-support-module';
import { NavigationAnonymousComponent } from './navigation-anonymous.component';

@NgModule({
  imports: [
    CommonModule,
    CommonRouterModule,
    LogoModule,
    ContactSupportModule,
    FireflyTopNavModule,
    FireflyDropdownWithInitialsModule,
    FireflyDrawerModule
  ],
  declarations: [NavigationAnonymousComponent],
  exports: [NavigationAnonymousComponent]
})
export class NavigationAnonymousModule {}
