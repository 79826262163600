export enum ParticipantFirmType {
  ServiceProvider = 'ServiceProvider',
  ServiceConsumer = 'ServiceConsumer',
  Corporate = 'Corporate',
  ThirdParty = 'ThirdParty'
}

export enum ParticipantPermissionRole {
  Admin = 'Admin',
  Coordinator = 'Coordinator',
  Attendee = 'Attendee',
  Viewer = 'Viewer',
  Others = 'Others'
}

export enum EventParticipantRole {
  Participant = 'Participant',
  Coordinator = 'Coordinator',
  Company = 'Company'
}
