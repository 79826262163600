import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../../popovers/popover.module';
import { FireflyLineComponent } from './line.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule],
  declarations: [FireflyLineComponent],
  exports: [FireflyLineComponent]
})
export class FireflyLineModule {}
