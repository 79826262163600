import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { SearchCategory, SpecialActions } from '../../+state/models';
import { filterChanged } from '../../+state/search.actions';
import {
  getAvailableResultCategories,
  getAvailableSpecialActions,
  getFilter,
  showActions,
  showFilters
} from '../../+state/search.selectors';
import { startSpecialAction } from '../../+state/selected-entities.actions';

@Component({
  selector: 'ca-panel-container',
  templateUrl: './panel-container.component.html',
  styleUrls: ['./panel-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelContainerComponent {
  constructor(private store: Store) {}

  filters$ = this.store.select(getAvailableResultCategories);
  showActions$ = this.store.select(showActions);
  availableSpecialActions$ = combineLatest([
    this.store.select(getAvailableSpecialActions),
    this.store.select(getFeatureToggle('one-search-events-add-activity'))
  ]).pipe(
    map(([specialActions, isAddActivityEnabled]) =>
      specialActions.filter(action => action.action !== SpecialActions.AddActivity || isAddActivityEnabled)
    )
  );
  showFilters$ = this.store.select(showFilters);
  filter$ = this.store.select(getFilter);

  onFilterChanged(filter: SearchCategory | null) {
    this.store.dispatch(filterChanged({ filter }));
  }

  onStartSpecialAction(action: SpecialActions) {
    this.store.dispatch(startSpecialAction({ action }));
  }
}
