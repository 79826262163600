import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, of } from 'rxjs';
import { getUserSettingsCulture } from '@capital-access/common/globalization';
import { BaseAsyncPipe } from '@capital-access/common/utils';

@Pipe({
  name: 'caCustomFieldNumber',
  pure: false
})
export class CustomFieldNumberPipe
  extends BaseAsyncPipe<string, number | null | undefined, [number?]>
  implements PipeTransform
{
  constructor(changeDetectorRef: ChangeDetectorRef, private store: Store) {
    super(changeDetectorRef);
  }

  getTransformer(value?: number | null) {
    if (value === null || value === undefined || !isFinite(value)) {
      return of('');
    }

    return this.store.select(getUserSettingsCulture).pipe(
      map(culture =>
        new Intl.NumberFormat(culture, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 3
        }).format(value)
      )
    );
  }
}
