import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NAV_TRANSITION_DURATION_FUNC, NAV_TRANSITION_DURATION_FUNC_OPACITY } from '../../constants';

@Component({
  selector: 'ca-bottom-nav-item',
  templateUrl: './bottom-nav-item.component.html',
  styleUrls: ['./bottom-nav-item.component.scss'],
  animations: [
    trigger('fadeWidth', [
      state('void', style({ width: 0 })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC)])
    ]),
    trigger('fadeOpacity', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC_OPACITY)])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomNavItemComponent {
  @Input() mini!: boolean;

  get currentYear() {
    return new Date().getFullYear();
  }
}
