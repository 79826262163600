import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpinnerSize } from '../models/spinner-size.enum';

@Component({
  selector: 'f-spinner',
  templateUrl: './spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySpinnerComponent {
  @Input() spinnerSize: SpinnerSize = SpinnerSize.Default;
  @Input() rootCssClass!: string;
}
