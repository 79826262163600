import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponse, toHttpParamsObject } from '@capital-access/common/utils';
import {
  Country,
  CoverageItem,
  CoverageType,
  CurrencyRateResponse,
  CurrencyResponse,
  EsgApproach,
  FundType,
  Industry,
  InvestmentCenter,
  InvestorType,
  JobRole,
  Region,
  Style
} from './models';

@Injectable()
export class StandingDataRepository {
  private readonly baseUrl = '/api/standing-data';
  private readonly v2 = '?api-version=2';

  constructor(private httpClient: HttpClient) {}

  public getStyles(): Observable<DataResponse<Style>> {
    return this.httpClient.get<DataResponse<Style>>(`${this.baseUrl}/styles${this.v2}`);
  }

  public getCoverage(coverageType: CoverageType): Observable<DataResponse<CoverageItem>> {
    const resourceUri = `${this.baseUrl}/coverage/${coverageType}${this.v2}`;
    return this.httpClient.get<DataResponse<CoverageItem>>(resourceUri);
  }

  public getRegions(): Observable<DataResponse<Region>> {
    const resourceUri = `${this.baseUrl}/regions${this.v2}`;

    return this.httpClient.get<DataResponse<Region>>(resourceUri);
  }

  public getCountries(regionIds?: number[]): Observable<DataResponse<Country>> {
    const resourceUri = `${this.baseUrl}/countries${this.v2}`;
    if (regionIds) {
      return this.httpClient.get<DataResponse<Country>>(resourceUri, {
        params: toHttpParamsObject({ regionIds })
      });
    }
    return this.httpClient.get<DataResponse<Country>>(resourceUri);
  }

  public getIndustries(): Observable<DataResponse<Industry>> {
    const resourceUri = `${this.baseUrl}/industries${this.v2}`;
    return this.httpClient.get<DataResponse<Industry>>(resourceUri);
  }

  public getJobRoles(): Observable<DataResponse<JobRole>> {
    const resourceUri = `${this.baseUrl}/job-roles${this.v2}`;
    return this.httpClient.get<DataResponse<JobRole>>(resourceUri);
  }

  public getFundTypes(): Observable<DataResponse<FundType>> {
    const resourceUri = `${this.baseUrl}/fund-types${this.v2}`;
    return this.httpClient.get<DataResponse<FundType>>(resourceUri);
  }

  public getInvestmentCenters(): Observable<DataResponse<InvestmentCenter>> {
    const resourceUri = `${this.baseUrl}/investment-centers${this.v2}`;
    return this.httpClient.get<DataResponse<InvestmentCenter>>(resourceUri);
  }

  public getCurrencyRate(currencyCode: string): Observable<{ rate: number; rateDate: string }> {
    const resourceUri = `${this.baseUrl}/currencies/${currencyCode}/rate${this.v2}`;
    return this.httpClient.get<{ rate: number; rateDate: string }>(resourceUri);
  }

  public getCurrencyRates(currencyCode: string, dates: string[]): Observable<CurrencyRateResponse[]> {
    const resourceUri = `${this.baseUrl}/currencies/${currencyCode}/rates${this.v2}`;
    return this.httpClient.get<CurrencyRateResponse[]>(resourceUri, { params: { dates } });
  }

  public getCurrencies(): Observable<CurrencyResponse> {
    const resourceUri = `${this.baseUrl}/currencies${this.v2}`;
    return this.httpClient.get<CurrencyResponse>(resourceUri);
  }

  public getInvestorTypes(): Observable<DataResponse<InvestorType>> {
    const resourceUri = `${this.baseUrl}/investor-types${this.v2}`;
    return this.httpClient.get<DataResponse<InvestorType>>(resourceUri);
  }

  public getCompanyApproaches(): Observable<DataResponse<EsgApproach>> {
    const resourceUri = `${this.baseUrl}/esg/company-approaches${this.v2}`;
    return this.httpClient.get<DataResponse<EsgApproach>>(resourceUri);
  }

  public getFundApproaches(): Observable<DataResponse<EsgApproach>> {
    const resourceUri = `${this.baseUrl}/esg/fund-approaches${this.v2}`;
    return this.httpClient.get<DataResponse<EsgApproach>>(resourceUri);
  }

  public getContactApproaches(): Observable<DataResponse<EsgApproach>> {
    const resourceUri = `${this.baseUrl}/esg/contact-approaches${this.v2}`;
    return this.httpClient.get<DataResponse<EsgApproach>>(resourceUri);
  }
}
