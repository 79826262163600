import { ReportType } from './models/report-type.enum';

export const LOCALIZATION_KEY = 'reporting-common';
export const REPORTING_API_BASE_URL = '/api/reporting/';
export const POLLING_INTERVAL = 1000;
export const MAX_SMALL_REPORT_ITEMS_COUNT = 30;
export const MAX_REPORT_PROFILES_COUNT = 50;

export const REPORT_TITLES: Partial<Record<ReportType, string>> = {
  [ReportType.Contact]: 'contactProfileReport',
  [ReportType.Institution]: 'investorProfileReport',
  [ReportType.HistoricalOwnership]: 'historicalOwnershipReport',
  [ReportType.Itinerary]: 'itineraryReport'
};
