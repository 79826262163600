<ng-container *ngFor="let bucket of buckets">
  <div class="toast-wrapper" *ngIf="bucket.items.length > 0">
    <div @hideOnRemoval class="toast-grid" *ngFor="let toast of bucket.items">
      <ng-container *ngIf="toast.template; else messageRef">
        <ng-container *ngComponentOutlet="toast.template; injector: toast.templateInjector"></ng-container>
      </ng-container>

      <ng-template #messageRef>
        <f-toast-message
          [message]="toast.message || ''"
          [type]="toast.type"
          [headerText]="toast.headerText || ''"
          [onClose]="toast.onClose"
        ></f-toast-message>
      </ng-template>
    </div>
  </div>
</ng-container>
