import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'f-dropdown-with-initials',
  templateUrl: './dropdown-with-initials.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyDropdownWithInitialsComponent {
  @Input() user!: { firstName: string | undefined | null; lastName: string | undefined | null } | undefined | null;
  @Input() items!: { text: string }[] | null;
  @Output() itemClick = new EventEmitter<unknown>();

  get initials(): string {
    if (!this.user) return '-';
    const firstInitial = this.user.firstName && this.user.firstName?.trim()[0];
    const lastInitial = this.user.lastName && this.user.lastName?.trim()[0];
    if (firstInitial && lastInitial) {
      return firstInitial + lastInitial;
    } else if (!firstInitial && !lastInitial) {
      return '-';
    } else {
      return (firstInitial as string) || (lastInitial as string);
    }
  }
}
