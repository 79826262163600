import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyPopoverModule } from '../../../../popovers/popover.module';
import { FireflyBarComponent } from './bar.component';

@NgModule({
  imports: [CommonModule, FireflyPopoverModule],
  declarations: [FireflyBarComponent],
  exports: [FireflyBarComponent]
})
export class FireflyBarModule {}
