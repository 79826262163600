/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash/get';

@Pipe({ name: 'fNestedValue' })
export class NestedValuePipe implements PipeTransform {
  // type 'any' is used to prevent build errors in case if next pipe receives specific type of value
  transform(value: unknown, valuePath?: string, defaultValue: unknown = ''): any {
    return valuePath ? get(value, valuePath, defaultValue) : value;
  }
}
