import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Renderer2
} from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { drawerWithoutFooterClasses, drawerWithFooterClasses } from '../constants';

@Directive({
  selector: '[fDrawerRow]'
})
export class FireflyDrawerRowDirective {
  @HostBinding('class.f-drawer-row') fireflyDrawerRow = true;
}

@Directive({
  selector: '[fDrawerCol]'
})
export class FireflyDrawerColumnDirective {
  @HostBinding('class.f-drawer-col') fireflyDrawerCol = true;
}

@Directive({
  selector: '[fDrawerFooterTemplate]'
})
export class FireflyDrawerFooterTemplateDirective {}

@Directive({
  selector: '[fDrawerControlButtons]'
})
export class FireflyDrawerControlButtonsTemplateDirective {}

@Directive({
  selector: '[fDrawerActionButton]'
})
export class FireflyDrawerActionButtonDirective {}

@Directive({
  selector: '[fDrawerScrollable]'
})
export class FireflyDrawerScrollableDirective implements OnInit, AfterViewInit, OnDestroy {
  private _scrollbarInset = 0;
  private _scrollbarOffset = 2;
  private resize$ = new Subject<ResizeObserverEntry[]>();
  private resizeObserver: ResizeObserver = new ResizeObserver(entries => this.resize$.next(entries));
  private resizeSub$ = Subscription.EMPTY;
  private drawerPadding!: number;
  private nestedScroll = false;
  private parentScrollElement?: HTMLElement;

  @HostBinding('style.margin-right.px') get scrollbarOffset() {
    return this.nestedScroll ? null : -this._scrollbarOffset;
  }

  @HostBinding('style.padding-right.px') get scrollbarInset() {
    return this.nestedScroll ? null : this._scrollbarOffset - this._scrollbarInset;
  }

  @HostBinding('class.drawer-content-nested-scroll') get nestedClass() {
    return this.nestedScroll;
  }

  constructor(private el: ElementRef, private renderer: Renderer2, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    const drawer = document.querySelector('.drawer') as HTMLElement;
    this.parentScrollElement = this.el.nativeElement.parentElement.closest('[fDrawerScrollable]');
    this.nestedScroll = !!this.parentScrollElement;
    this.drawerPadding = parseInt(window.getComputedStyle(drawer).paddingRight);
    this.onScrollableResize();
  }

  ngAfterViewInit() {
    this.handleNestedScrollClasses();
  }

  ngOnDestroy() {
    this.resize$.complete();
    this.resizeSub$.unsubscribe();
    this.resizeObserver.disconnect();
  }

  private handleNestedScrollClasses() {
    if (!this.nestedScroll) return;

    const parentScrollContainer = this.parentScrollElement!.querySelector('div') as HTMLElement;
    parentScrollContainer.classList.add(...drawerWithFooterClasses);

    const hasElSibling = !!this.el.nativeElement.nextElementSibling;
    const hasDrawerFooter = !document.querySelector('.drawer-footer')?.classList.contains('d-none');
    if (hasDrawerFooter && !hasElSibling) {
      parentScrollContainer.classList.remove(...drawerWithoutFooterClasses);
      this.el.nativeElement.classList.add(...drawerWithoutFooterClasses);
    }
  }

  private onScrollableResize() {
    this.resizeObserver.observe(this.el.nativeElement);
    this.resizeSub$ = this.resize$
      .asObservable()
      .pipe(
        map(entries => entries[0].target as HTMLElement),
        map(el => el.scrollHeight > el.offsetHeight),
        distinctUntilChanged()
      )
      .subscribe(isScrollable => {
        if (isScrollable) {
          this._scrollbarOffset = this.drawerPadding;
          const { offsetWidth, clientWidth } = this.el.nativeElement;
          this._scrollbarInset = offsetWidth - clientWidth;
          this.renderer.removeClass(this.el.nativeElement, 'drawer-content-overflow');
        } else {
          this._scrollbarOffset = 2;
          this._scrollbarInset = 0;
          this.renderer.addClass(this.el.nativeElement, 'drawer-content-overflow');
        }
        this.cdRef.detectChanges();
      });
  }
}
