<div
  class="inner-error-wrapper"
  [ngClass]="[alignmentClass, flexiblePlacementClass, rootCssClass]"
  fVirtualGridFixedHeight
>
  <div class="inner-error-img" data-automation-id="inner-error-img"></div>
  <div class="inner-error-content">
    <div class="inner-error-caption" data-automation-id="inner-error-caption">
      {{ caption || (innerErrorTitle$ | async) }}
    </div>
    <div class="inner-error-info" data-automation-id="inner-error-info">
      {{ info }}
      <ng-content select="[fInnerErrorActions]"></ng-content>
    </div>
  </div>
</div>
