import { Injectable } from '@angular/core';
import { NgbAccordionConfig, NgbConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class FireflyAccordionConfig extends NgbAccordionConfig {
  constructor(ngbConfig: NgbConfig) {
    super();
    this.animation = ngbConfig.animation;
    this.animation = false;
  }
}
