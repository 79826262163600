import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { fallbackCulture, getUserSettingsCulture } from '@capital-access/common/globalization';
import { BaseAsyncPipe } from '@capital-access/common/utils';
import { timePatternOptions } from '../formatting-options/time-pattern.options';
import { TimeFormat } from '../models/date-formatting.models';

@Pipe({
  name: 'caTimeFormat',
  pure: false
})
export class TimeFormatPipe extends BaseAsyncPipe<string, TimeFormat> implements PipeTransform {
  private userCulture$ = this.store.select(getUserSettingsCulture);

  constructor(private store: Store, changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  getTransformer(format: TimeFormat): Observable<string> {
    return this.userCulture$.pipe(
      map(culture => {
        if (timePatternOptions[culture]) return timePatternOptions[culture][format];
        else return timePatternOptions[fallbackCulture][format];
      })
    );
  }
}
