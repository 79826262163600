import { inject, InjectionToken } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebsocketAuthProvider } from './appsync-websocket-connection';

export interface NotificationApiAuthProvider extends WebsocketAuthProvider {
  multiplex: boolean;
  providerName: string;
}

export const OIDC_NOTIFICATION_API_AUTH_PROVIDER = new InjectionToken<NotificationApiAuthProvider>(
  'oidc notification api auth provider',
  {
    factory: () => {
      const oidcService = inject(OidcSecurityService);

      const provider: WebsocketAuthProvider = (host: string, _: string) =>
        oidcService.getAccessToken().pipe(map(token => ({ host, Authorization: `Bearer ${token}` })));

      Object.assign(provider, {
        multiplex: true,
        providerName: 'oidc-auth'
      });

      return provider as NotificationApiAuthProvider;
    }
  }
);

export function createNotificationApiTempAuthProvider(tempToken: string) {
  const provider: WebsocketAuthProvider = (host: string, _: string) => of({ host, Authorization: `Temp ${tempToken}` });

  Object.assign(provider, {
    multiplex: false,
    providerName: 'temp-auth'
  });

  return provider as NotificationApiAuthProvider;
}
