import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreadcrumbItem } from '@capital-access/common/breadcrumbs';
import { getUrl } from '@capital-access/common/router';
import { BaseAsyncPipe } from '@capital-access/common/utils';

@Pipe({
  name: 'caCanNavigate',
  pure: false
})
export class BreadcrumbCanNavigatePipe extends BaseAsyncPipe<boolean, BreadcrumbItem> implements PipeTransform {
  constructor(private store: Store, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  override buildKeyName(item: BreadcrumbItem) {
    return item.url?.urlString;
  }

  getTransformer(item: BreadcrumbItem) {
    if (!item.url) return of(false);
    return this.store.pipe(
      select(getUrl),
      map(currentUrl => item.url!.urlString !== currentUrl)
    );
  }
}
