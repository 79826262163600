import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Renderer2,
  Signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, fromEvent, Observable, tap } from 'rxjs';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { NavigationItemsService, SideNavigationMap } from '@capital-access/common/navigation';
import {
  Breakpoint,
  FireflyDrawerDefaultContainerDirective,
  FireflyDrawerService
} from '@capital-access/firefly/components';

@Component({
  selector: 'ca-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements AfterViewInit {
  @ViewChild('scrollableElement') scrollableElement!: ElementRef;
  // should be removed together with mobile-scroll-behavior-CPD-2028
  @ViewChild('updatedScrollableElement') updatedScrollableElement!: ElementRef;
  @ViewChild('desktopDrawerContainer', { read: ViewContainerRef, static: true })
  desktopDrawerContainer!: ViewContainerRef;
  @ViewChild('mobileDrawerContainer', { read: ViewContainerRef, static: true })
  mobileDrawerContainer!: ViewContainerRef;
  changedScrollEnabled!: Signal<boolean | undefined>;

  flexibleMobileNav = true;

  sideNavigationMap$: Observable<SideNavigationMap>;
  lastY = 0;

  private destroyRef = inject(DestroyRef);

  constructor(
    navigationItemsService: NavigationItemsService,
    private store: Store,
    private router: Router,
    private renderer: Renderer2,
    private drawerService: FireflyDrawerService
  ) {
    this.sideNavigationMap$ = navigationItemsService.getSideNavigationMap();
    this.changedScrollEnabled = toSignal(this.store.select(getFeatureToggle('mobile-scroll-behavior-CPD-2028')));
  }

  get mobile() {
    return window.innerWidth < Breakpoint.Sm;
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() =>
          this.renderer.setProperty(
            (this.changedScrollEnabled() && this.mobile ? this.updatedScrollableElement : this.scrollableElement)
              .nativeElement,
            'scrollTop',
            0
          )
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.scrollHandler();

    const container = this.mobile ? this.mobileDrawerContainer : this.desktopDrawerContainer;
    if (container) new FireflyDrawerDefaultContainerDirective(container, this.drawerService, this.renderer);
  }

  private scrollHandler() {
    if (!this.changedScrollEnabled()) return;

    const targetEl = document.querySelector('.ca-content-wrapper') as HTMLElement;
    const richTextEditor = '.k-editor-content .ProseMirror';

    fromEvent(targetEl, 'touchstart')
      .pipe(
        filter(() => !document.querySelector(richTextEditor)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => (this.lastY = (event as TouchEvent).touches[0].clientY));

    fromEvent(targetEl, 'touchmove', {
      passive: false
    })
      .pipe(
        filter(() => !document.querySelector(richTextEditor)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(event => this.preventScrollOverflow(event as TouchEvent, targetEl));
  }

  private preventScrollOverflow(event: TouchEvent, targetEl: HTMLElement) {
    const top = event.touches[0].clientY;
    const scrollTop = targetEl.scrollTop;
    const maxScrollTop = targetEl.scrollHeight - targetEl.offsetHeight;
    const direction = this.lastY - top < 0 ? 'up' : 'down';
    if (
      event.cancelable &&
      ((scrollTop <= 0 && direction === 'up') || (scrollTop >= maxScrollTop && direction === 'down'))
    )
      event.preventDefault();

    this.lastY = top;
  }
}
