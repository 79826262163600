import { SECTIONS_SETTINGS_KEY } from '../../constants';
import { ConditionalReportSections, ReportSection, SectionSelectorListItem } from '../models/report-sections';

export const getSavedSections = (
  key: string,
  defaultSections: SectionSelectorListItem[],
  conditionalSections?: ConditionalReportSections
) => {
  const sectionsJson = localStorage.getItem(`${key.toLocaleUpperCase()}_${SECTIONS_SETTINGS_KEY}`);

  let sectionsList: SectionSelectorListItem[];

  if (sectionsJson) {
    const savedSections = JSON.parse(sectionsJson) as SectionSelectorListItem[];
    sectionsList = getSectionListsFromSaved(savedSections, defaultSections, conditionalSections);
  } else {
    sectionsList = getSectionListsFromDefault(defaultSections, conditionalSections);
  }

  return sectionsList;
};

function getSectionListsFromSaved(
  savedSections: SectionSelectorListItem[],
  defaultSections: SectionSelectorListItem[],
  conditionalSections?: ConditionalReportSections
) {
  if (!conditionalSections) return savedSections;
  const fullSectionList = includeUnsavedNewSections(savedSections, defaultSections, conditionalSections);
  return filterHiddenSections(fullSectionList, conditionalSections);
}

function getSectionListsFromDefault(
  defaultSections: SectionSelectorListItem[],
  conditionalSections?: ConditionalReportSections
) {
  if (!conditionalSections) return defaultSections;
  return filterHiddenSections(defaultSections, conditionalSections);
}

function includeUnsavedNewSections(
  savedSections: SectionSelectorListItem[],
  defaultSections: SectionSelectorListItem[],
  conditionalSections: ConditionalReportSections
) {
  for (const sectionKey in conditionalSections) {
    const isSectionInList = savedSections.some(section => section.id === sectionKey);

    if (!isSectionInList && !!conditionalSections[sectionKey as ReportSection]) {
      const sectionToAdd = defaultSections.find(section => section.id === sectionKey);
      savedSections.push({ ...sectionToAdd!, isSelected: !!sectionToAdd?.isCommonForAllSections });
    }
  }

  return savedSections;
}

function filterHiddenSections(
  savedSections: SectionSelectorListItem[],
  conditionalSections: ConditionalReportSections
) {
  const sectionsToFilter: ReportSection[] = [];

  for (const sectionKey in conditionalSections) {
    if (!conditionalSections[sectionKey as ReportSection]) {
      sectionsToFilter.push(sectionKey as ReportSection);
    }
  }

  if (sectionsToFilter.length) {
    savedSections = savedSections.filter(section => !sectionsToFilter.includes(section.id));
  }

  return savedSections;
}
