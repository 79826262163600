<div [caLocalizationScope]="localizationScope">
  <div>
    {{ options.message }}
  </div>

  <div *ngIf="options.profileLink">
    <a [href]="options.profileLink" class="link link-primary" data-automation-id="open-profile-link">{{
      'openProfileTitle' | caLocalize
    }}</a>
  </div>
</div>
