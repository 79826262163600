<div class="h-100">
  <div class="d-flex flex-md-row flex-column h-100">
    <div *ngIf="showSubNav" [@toggleAnimation]>
      <nav class="ca-sub-nav position-sticky">
        <a
          *ngFor="let item of items; trackBy: trackByFn"
          class="ca-sub-nav-item"
          [routerLinkActive]="item.disabled ? '' : 'ca-sub-nav-item-active'"
          [routerLink]="item.disabled ? [] : item?.link"
          [class.disabled]="item.disabled"
          [attr.data-automation-id]="item.dataAutomationId"
          triggers="hover focus"
          placement="bottom"
          [title]="item.disabled ? (item.disabledTooltipMessage! | caTryLocalize) : ''"
        >
          {{ item.name | caTryLocalize }}
        </a>
      </nav>
    </div>
    <div class="w-100 flex-fill overflow-hidden mt-n1 pt-1 ps-md-7 pb-0 position-relative">
      <div *ngIf="collapsible" class="ca-sub-nav-icon">
        <button class="btn btn-primary btn-icon btn-rounded" (click)="toggleSubNav()">
          <i
            class="f-i f-i-chevron-left"
            [ngClass]="{
              'f-i-chevron-left': showSubNav,
              'f-i-chevron-right': !showSubNav
            }"
          ></i>
        </button>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
