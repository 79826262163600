<ng-container [ngSwitch]="flow$ | async">
  <ca-navigation-anonymous *ngSwitchCase="'anonymous'">
    <router-outlet></router-outlet>
  </ca-navigation-anonymous>

  <ca-navigation *ngSwitchCase="'authorized'">
    <ca-one-search class="f-drawer-outclick-element" caTopNavSearch></ca-one-search>
    <router-outlet></router-outlet>
    <ca-ai-chat-button></ca-ai-chat-button>
  </ca-navigation>
  <div *ngSwitchDefault class="d-flex justify-content-center align-items-center h-100">
    <f-spinner rootCssClass="spinner-center spinner-theme-agnostic"></f-spinner>
  </div>
</ng-container>
