import { Pipe, PipeTransform } from '@angular/core';
import { EngageLinkProvider } from '../providers/event-link-provider';

@Pipe({
  name: 'caEventLink'
})
export class EventLinkPipe implements PipeTransform {
  constructor(private engageLinkProvider: EngageLinkProvider) {}

  transform(id: string): string {
    return this.engageLinkProvider.getEventLink(id);
  }
}
