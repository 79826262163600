import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Breakpoint } from '@capital-access/firefly/components';
import { D3DraggableLayout } from './draggable-layout.d3';
import { LayoutChangeEventData } from './draggable-layout.model';
import { DraggableLayoutItemPositionDirective } from './draggable-layout-item-position.directive';
import { DraggableLayoutService } from './draggable-layout-service';

@Component({
  selector: 'ca-draggable-layout',
  templateUrl: './draggable-layout.component.html',
  styleUrls: ['./draggable-layout.component.scss'],
  exportAs: 'draggable-layout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DraggableLayoutService]
})
export class DraggableLayoutComponent implements AfterContentInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private layout?: D3DraggableLayout;

  @ContentChildren(DraggableLayoutItemPositionDirective) positions!: QueryList<DraggableLayoutItemPositionDirective>;

  @Input()
  scrollParent: HTMLElement | Window | null = window;

  @Input()
  dragPreventSelector = 'a, a *, input, select, button, button *';

  @Input()
  dragSelector = '[caDraggableLayoutItemHeader]';

  @Input()
  dashboardParent: HTMLElement = this.element.nativeElement;

  @Output()
  layoutChange = new EventEmitter<LayoutChangeEventData>();

  constructor(
    private element: ElementRef,
    private zone: NgZone,
    private draggableLayoutService: DraggableLayoutService
  ) {}

  ngAfterContentInit() {
    if (window.innerWidth < Breakpoint.Sm) return;
    this.layout = new D3DraggableLayout(this.dashboardParent, {
      dragSelector: this.dragSelector,
      dragPreventSelector: this.dragPreventSelector,
      scrollParent: this.scrollParent,
      onPositionChange: eventData => this.layoutChange.next(eventData)
    });

    this.subscriptions = [
      this.positions.changes.subscribe(() => this.draggableLayoutService.scheduleRefresh()),
      this.draggableLayoutService.refresh.subscribe(() => this.refresh())
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  refresh() {
    this.zone.runOutsideAngular(() => this.layout?.refresh());
  }
}
