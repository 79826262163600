import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';
import { Security } from '../models/security';
import { mapToSecurity } from '../utils/security';
import { securitiesSelectors } from './reducer';
import { COMMON_SECURITY_FEATURE_KEY, SecuritiesState } from './state';
import { findSecurityById } from './utils';

export const getSecuritiesState = createFeatureSelector<SecuritiesState>(COMMON_SECURITY_FEATURE_KEY);

export const getSecuritiesEntities = createSelector(getSecuritiesState, securitiesSelectors.selectEntities);
export const getLoadedSecurities = createSelector(getSecuritiesState, securitiesSelectors.selectAll);

export const getLoadedSecurityIds = createSelector(
  getSecuritiesState,
  state => securitiesSelectors.selectIds(state) as number[]
);

export const getLoadingSecurityIds = createSelector(getSecuritiesState, state => state.loadingIds);
export const getSecuritiesLoading = createSelector(getLoadingSecurityIds, ids => !isEmpty(ids));

export const getSecuritiesLoaded = createSelector(
  getSecuritiesState,
  state => isEmpty(state.loadingIds) && (!isEmpty(state.ids) || !isEmpty(state.failedIds))
);

export const getFailedSecurityIds = createSelector(getSecuritiesState, state => state.failedIds);

export const getLoadedOrLoadingSecurityIds = createSelector(
  getLoadedSecurityIds,
  getLoadingSecurityIds,
  (loaded, loading) => [...loaded, ...loading]
);

export const getSecurities = createSelector(getLoadedSecurities, getFailedSecurityIds, (securities, failedIds) => [
  ...securities,
  ...mapToSecurity(failedIds)
]);

export const getSecurityById = (id: number) =>
  createSelector(getSecuritiesEntities, (securities: Dictionary<Security>) => findSecurityById(securities, id));

export const getSecuritiesByIds = (ids: number[]) =>
  createSelector(getSecuritiesEntities, (securities: Dictionary<Security>) =>
    ids.map(id => findSecurityById(securities, id))
  );
