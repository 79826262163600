import { Injectable } from '@angular/core';
import { CompositeId } from '../contracts/composite-id';
import { ProfileType } from '../contracts/profile-id.models';
import { HostProvider } from './host-provider';

@Injectable({ providedIn: 'root' })
export class ProfileLinkProvider {
  constructor(private hostProvider: HostProvider) {}

  getInstitutionProfileLink(ipreoCompanyId?: number | null, companyId?: number | null, includeHost: boolean = true) {
    return this.getProfileLink(ProfileType.Institution, ipreoCompanyId, companyId, includeHost);
  }

  getContactProfileLink(ipreoContactId?: number | null, contactId?: number | null, includeHost: boolean = true) {
    return this.getProfileLink(ProfileType.Contact, ipreoContactId, contactId, includeHost);
  }

  getFundProfileLink(ipreoFundId?: number | null, fundId?: number | null, includeHost: boolean = true) {
    return this.getProfileLink(ProfileType.Fund, ipreoFundId, fundId, includeHost);
  }

  getProfileLink(profileType: ProfileType, prId?: number | null, crmId?: number | null, includeHost: boolean = true) {
    const compositeId = new CompositeId(prId, crmId);
    let link = `profiles/${profileType}/${CompositeId.toString(compositeId)}`;
    if (includeHost) {
      link = `${this.hostProvider.baseUrl}${link}`;
    }
    return link;
  }
}
