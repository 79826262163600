import { testLang } from '@capital-access/common/globalization';

export function getLanguageDisplayName(langCode: string): string {
  if (langCode === testLang) return '•Test•';

  // Check if the browser supports Intl.DisplayNames
  if (!Intl.DisplayNames) return langCode;

  // Returns the language name in English, or a fallback if undefined
  const displayName = new Intl.DisplayNames(['en'], { type: 'language' }).of(langCode);

  return displayName ?? langCode; // If displayName is undefined, return the original langCode
}
