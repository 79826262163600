import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import head from 'lodash/head';
import { map } from 'rxjs/operators';
import { AppSettingsService } from '@capital-access/common/settings';
import { CompanyDetailsDto } from './models/company-details.model';
import { Settings } from './settings/settings.model';

@Injectable()
export class EventCompanyDetailsRepository {
  private eventHubBaseUrl: string;

  constructor(private httpClient: HttpClient, settingsService: AppSettingsService) {
    const settings = settingsService.getSettings<Settings>();
    this.eventHubBaseUrl = settings.eventHubUrl;
  }

  getCompanyDetails(eventId: string) {
    return this.httpClient
      .get<CompanyDetailsDto[]>(`${this.eventHubBaseUrl}/api/event/${eventId}/invitations`)
      .pipe(map(response => head(response)));
  }

  updateCompanyDetails(eventId: string, invitationId: number, dto: CompanyDetailsDto) {
    return this.httpClient.put<CompanyDetailsDto>(
      `${this.eventHubBaseUrl}/api/event/${eventId}/invitations/${invitationId}`,
      dto
    );
  }

  createCompanyDetails(eventId: string, dto: CompanyDetailsDto) {
    return this.httpClient.post<CompanyDetailsDto>(`${this.eventHubBaseUrl}/api/event/${eventId}/invitations`, dto);
  }
}
