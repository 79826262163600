import { Injectable } from '@angular/core';
import { createTitle } from '@capital-access/common/browser';
import { LocalizedString } from '@capital-access/common/localization';
import { LicenseAgreementComponent } from '../components/license-agreement.component';
import { LICENSE_AGREEMENT_LOCALIZATION_SCOPE } from '../models/constants';

@Injectable()
export class LicenseAgreementPageTitle {
  licenseAgreementTitle$ = createTitle(LicenseAgreementComponent, () => ({
    value: [
      new LocalizedString('applicationTitle'),
      new LocalizedString('pageTitle', { scope: LICENSE_AGREEMENT_LOCALIZATION_SCOPE })
    ],
    override: false
  }));
}
