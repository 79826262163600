import { ChangeDetectionStrategy, Component, DoCheck, Input } from '@angular/core';
import { SkeletonLoaderShade } from '../../models/skeleton-loader.enum';
import { SkeletonDrawerTemplate } from './skeleton-drawer-loader.enum';

@Component({
  selector: 'f-skeleton-drawer',
  template: `
    <f-skeleton-small-card-with-text
      *ngFor="let loader of loaders"
      [shortTemplate]="shortTemplate"
      [shade]="shade"
      [ngClass]="{ 'border-bottom pb-5': shortTemplate }"
      class="d-block mb-5"
    ></f-skeleton-small-card-with-text>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflySkeletonDrawerComponent implements DoCheck {
  @Input() shade: SkeletonLoaderShade = SkeletonLoaderShade.Light;
  @Input() shortTemplate: boolean = false;
  @Input() loadersCount!: number;

  loaders: null[] = [];

  ngDoCheck() {
    this.loaders = this.shortTemplate
      ? this.makeArray(this.loadersCount || SkeletonDrawerTemplate.Full)
      : this.makeArray(SkeletonDrawerTemplate.Short);
  }

  private makeArray(n: number) {
    return new Array(n).fill(null);
  }
}
