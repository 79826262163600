export type ExcelNumberColor = 'black' | 'white' | 'red' | 'green' | 'blue' | 'yellow' | 'magenta' | 'cyan';

export interface ExcelOptions {
  colors?: ExcelNumberColors;
  fraction?: boolean;
}

export interface ExcelNumberColors {
  positive?: ExcelNumberColor;
  negative?: ExcelNumberColor;
  zero?: ExcelNumberColor;
}

function resolveColors(format: string, colors?: ExcelNumberColors) {
  if (colors) {
    const positivePrefix = colors.positive ? `[${colors.positive}]` : '';
    const negativePrefix = colors.negative ? `[${colors.negative}]` : '';
    const zeroPrefix = colors.zero ? `[${colors.zero}]` : '';

    return `${positivePrefix}${format};${negativePrefix}-${format};${zeroPrefix}${format}`;
  }
  return format;
}

// Culture is not needed. These number formats is culture invariant. And the actual culture formatting would be done by Excel
function getExcelDecimalFormat(colors?: ExcelNumberColors, fraction?: boolean) {
  let format = '#,##0';
  if (fraction) format = format + '.00';
  return resolveColors(format, colors);
}

function getExcelThousandsFormat(colors?: ExcelNumberColors, fraction?: boolean) {
  let format = '#,##0,';
  if (fraction) format = format + '.00';
  return resolveColors(format, colors);
}

function getExcelMillionsFormat(colors?: ExcelNumberColors, fraction?: boolean) {
  let format = '#,##0,,';
  if (fraction) format = format + '.00';
  return resolveColors(format, colors);
}

export const excelNumberFormat = {
  /**
   * @param colors color config
   * @param fraction fraction part
   * @returns excel decimal format
   */
  decimal: getExcelDecimalFormat,
  /**
   * @param colors color config
   * @param fraction fraction part
   * @returns excel quantity format for millions
   */
  millions: getExcelMillionsFormat,
  /**
   * @param colors color config
   * @param fraction fraction part
   * @returns excel quantity format for thousands
   */
  thousands: getExcelThousandsFormat
};
