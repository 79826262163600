<div [formGroup]="formGroup" class="mb-4" [caLocalizationScope]="localizationScope">
  <ng-container [formArrayName]="formArrayName">
    <label class="form-label-top">{{ 'phoneNumberTitle' | caLocalize }}</label>
    <div *ngFor="let control of value.controls; index as i; let first = first" class="mb-1 d-flex">
      <div class="me-2 flex-grow-1">
        <input
          type="text"
          class="form-control form-control-outline"
          [placeholder]="'phoneNumberPlaceholder' | caLocalize"
          [ngClass]="{
            'is-invalid': control.invalid && control.touched
          }"
          [formControlName]="i"
          (focus)="onInputFocus(control)"
          autocomplete="new-password"
        />

        <div
          *ngIf="control?.hasError('pattern') && !control?.hasError('maxlength')"
          class="invalid-feedback"
          data-automation-id="phone-pattern-validation"
        >
          {{ 'phoneValidationMessage' | caLocalize }}
        </div>

        <div
          *ngIf="control?.hasError('maxlength')"
          class="invalid-feedback"
          data-automation-id="phone-limit-validation"
        >
          {{ 'phoneLengthLimit' | caLocalize : { maxLength: maxLength } }}
        </div>
      </div>
      <div class="me-0 flex-shrink-0">
        <button
          type="button"
          (click)="addInputField()"
          *ngIf="first"
          [disabled]="isAddButtonDisabled()"
          class="btn btn-secondary btn-icon"
          data-automation-id="add-input-field"
        >
          <i class="f-i f-i-plus-circle"></i>
        </button>

        <button
          type="button"
          class="btn btn-danger btn-icon"
          data-automation-id="remove-input-field"
          (click)="removeInputField(i)"
          *ngIf="!first"
        >
          <i class="f-i f-i-trash-bin"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
