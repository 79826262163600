import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { BROWSER_TITLE_FALLBACK_VALUE, TitlePartString } from '@capital-access/common/browser';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { AppKey, AppUrlService } from '@capital-access/common/settings';
import {
  CA_ADMIN_ROLE,
  CA_APPLICATION,
  CA_COMPASS_ROLE,
  CI_APPLICATION,
  Entitlement,
  getEntitlements,
  getUserLoaded
} from '@capital-access/common/user';
import { orderByNullLast, when } from '@capital-access/common/utils';
import { FireflyDropdownComponent } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { appSwitcherItems } from './constants';
import { EntitlementType, SwitcherConfigItem } from './models';

@Component({
  selector: 'ca-app-switcher',
  templateUrl: './app-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonAppSwitcherComponent implements OnInit {
  @Input() rootClass = 'd-none d-md-block';

  @ViewChild(FireflyDropdownComponent) private dropdown!: FireflyDropdownComponent;

  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;
  apps: SwitcherConfigItem[] = [];

  configs = Object.entries(appSwitcherItems).map(([key, value]) => ({ key, value }));

  constructor(
    @Optional() private store: Store,
    @Optional() private appUrlService: AppUrlService,
    @Optional() @Inject(BROWSER_TITLE_FALLBACK_VALUE) public title: TitlePartString
  ) {}

  ngOnInit() {
    if (!this.store) {
      this.apps = Object.values(appSwitcherItems).map(appConfig => appConfig);
      return;
    }

    combineLatest([
      this.store.select(getEntitlements),
      this.store.select(getFeatureToggle('ci-module-ISSYN-1393')),
      this.store.select(getFeatureToggle('app-switcher-add-cap-iq-CPD-1970'))
    ])
      .pipe(when(this.store.select(getUserLoaded)), take(1))
      .subscribe(([e, isCIFeatureToggleEnabled, isCapIQProFeatureToggleEnabled]) => {
        const userFunctions = e?.functions;
        const userApplications = e?.applications;

        if (!userFunctions?.length && !userApplications?.length) return;

        const functionEntitlements = [CA_ADMIN_ROLE, CA_COMPASS_ROLE];
        const applicationEntitlements = [CA_APPLICATION, CI_APPLICATION];

        const apps = [
          ...this.mapConfig(functionEntitlements, EntitlementType.Function, userFunctions),
          ...this.mapConfig(applicationEntitlements, EntitlementType.Application, userApplications)
        ]
          .map(config => {
            return {
              ...config!.value,
              url: this.appUrlService.getAppUrl(config!.key as AppKey)
            };
          })
          .filter(
            app =>
              (app.name !== appSwitcherItems.capitalInsight.name || isCIFeatureToggleEnabled) &&
              (app.name !== appSwitcherItems.capitalIqPro.name || isCapIQProFeatureToggleEnabled)
          );

        this.apps = orderByNullLast(apps, [{ field: 'order', dir: 'asc' }]);
      });
  }

  navigateTo(url: string | undefined) {
    if (url) window.open(url, '_blank');
    this.dropdown?.close();
  }

  private mapConfig(entitlements: string[], type: EntitlementType, userEntitlements?: Entitlement[]) {
    return entitlements
      .filter(ent => userEntitlements?.some(role => role.sourceId == ent))
      .map(ent =>
        this.configs.filter(({ value: { entitlement } }) => entitlement.type === type && entitlement.sourceId === ent)
      )
      .flat(1);
  }
}
