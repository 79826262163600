import { AfterViewInit, ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import type { ScaleBand, ScaleLinear } from 'd3-scale';
import { scaleBand, scaleLinear } from 'd3-scale';
import isEqual from 'lodash/isEqual';
import { Breakpoint } from '../../utils';
import type { ChartDataEntry, ChartDimensions } from '../common';
import { FireflyBaseChartComponent } from '../common/base-components/base-chart-component';

@Component({
  selector: 'f-area-chart',
  templateUrl: './area-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyAreaChartComponent extends FireflyBaseChartComponent implements AfterViewInit {
  @Input() data!: ChartDataEntry[];
  @Input() lineCurved = false;
  @Input() lineAxisLabelText!: string;
  @Input() showLineAxisGridlines = false;
  @Input() lineClasses: string[] = [];
  @Input() areaClasses: string[] = [];
  @Input() lineAxisTickFormatting!: (d: unknown) => string;
  @Input() lineCircleRadius = 4;
  @Input() lineWidth = 3;
  @Input() showArea = true;
  @Input() showPointerLine = true;

  @HostBinding('class') cssCass = 'd-block h-100 position-relative';

  lineXDomain!: string[];
  lineYDomain!: number[];
  lineXScale!: ScaleBand<string>;
  lineYScale!: ScaleLinear<number, number>;
  pointerXCoordinate!: number | undefined;
  activeDataPointId = -1;
  areaIsSelected = false;
  padding = 0;

  chartComponent = FireflyAreaChartComponent;

  get lineData(): ChartDataEntry[] {
    return this.data;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.areaIsSelected = this.activeBarIndex! >= 0 && this.interactions.click;
      this.cdr.detectChanges();
    }, this.animationDuration + 50);
  }

  protected update(dims: ChartDimensions) {
    super.update(dims);
    this.lineYScale = this.getLineYScale(dims);
    this.lineXScale = this.getLineXScale(dims);
    this.updateAreaChartScaleFactor(dims);
  }

  updateAreaChartScaleFactor(dims: ChartDimensions) {
    this.element.nativeElement.style.setProperty('--condensed-area-chart-scale-factor', `${this.scaleFactor(dims)}`);
  }

  scaleFactor(dims: ChartDimensions) {
    return dims.width / (dims.width - this.xScale.bandwidth());
  }

  getXScale(view: ChartDimensions): ScaleBand<string> {
    this.xDomain = this.getXDomain(this.data);
    return scaleBand().range([0, view.width]).padding(0).domain(this.xDomain);
  }

  getLineXScale(view: ChartDimensions): ScaleBand<string> {
    this.lineXDomain = this.getXDomain(this.lineData);
    return scaleBand().range([0, view.width]).padding(0).domain(this.lineXDomain);
  }

  getLineYScale(view: ChartDimensions): ScaleLinear<number, number> {
    this.lineYDomain = this.getYDomain(this.lineData);
    return scaleLinear().range([view.height, 0]).domain(this.lineYDomain).nice();
  }

  onDimensionsChanged(dims: ChartDimensions) {
    if (isEqual(this.dimensions, dims)) return;

    this.dimensions = dims;
    this.xScale = this.getXScale(dims);
    this.yScale = this.getYScale(dims);
    this.lineXScale = this.getLineXScale(dims);
    this.lineYScale = this.getLineYScale(dims);

    this.cdr.detectChanges();
  }

  onBarClick($event: { data: ChartDataEntry } | null) {
    super.onBarClick($event);
    this.areaIsSelected = false;
    window.requestAnimationFrame(() => {
      this.areaIsSelected = !!$event?.data;
      this.cdr.detectChanges();
    });
  }

  onBarHover({ x, index }: { x: number; index: number }) {
    this.pointerXCoordinate = x;
    if (index !== null) this.activeDataPointId = index;
    super.onBarHover({ x, index });
    this.cdr.detectChanges();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.element.nativeElement.classList.remove('hover');
    if (window.innerWidth < Breakpoint.Sm) return;
    this.pointerXCoordinate = undefined;
    this.activeDataPointId = -1;
    this.cdr.detectChanges();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.interactions.hover) return;
    this.element.nativeElement.classList.add('hover');
  }
}
