import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { FireflyLocalizationService } from '../../../utils/localization/firefly-localization.service';

@Component({
  selector: 'f-list-status-items',
  templateUrl: './list-status-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyListStatusItemsComponent implements OnInit {
  @Input() showError!: boolean;
  @Input() showLoader!: boolean;
  @Input() showNoData!: boolean;
  @Input() showViewMore!: boolean;
  @Input() showNoResults!: boolean;
  @Input() showContactSupportLink = true;
  @Input() noResultsTemplate?: TemplateRef<unknown>;
  @Input() loaderTemplate?: TemplateRef<unknown>;
  @Input() errorTemplate?: TemplateRef<unknown>;
  @Input() noDataTemplate?: TemplateRef<unknown>;
  @Input() viewMoreTemplate?: TemplateRef<unknown>;
  @Input() footerTemplate?: TemplateRef<unknown>;
  @Output() viewMore = new EventEmitter<void>();
  @Output() contactSupport = new EventEmitter<void>();

  noResults$: Observable<unknown> = of('No results match your criteria. Please try other criteria.');
  viewMore$: Observable<unknown> = of('View more');
  somethingWentWrong$: Observable<unknown> = of(
    'Something went wrong. Please try again in a few minutes. If the problem persists, reach out to support.'
  );
  contactSupport$: Observable<unknown> = of('Contact Support');
  noData$: Observable<unknown> = of('No data to display');
  isLoadingTitle$: Observable<unknown> = of('Loading...');

  constructor(@Optional() private localizationService: FireflyLocalizationService) {}

  ngOnInit() {
    if (!this.localizationService) return;
    this.noResults$ = this.localizationService.localize('suggesterNoResults', {});
    this.viewMore$ = this.localizationService.localize('suggesterViewMore', {});
    this.somethingWentWrong$ = this.localizationService.localize('suggesterSomethingWentWrong', {});
    this.contactSupport$ = this.localizationService.localize('suggesterContactSupport', {});
    this.isLoadingTitle$ = this.localizationService.localize('suggesterIsLoading', {});
    this.noData$ = this.localizationService.localize('noDataMessage', {});
  }

  onViewMoreClicked() {
    if (this.showLoader) return;
    this.viewMore.emit();
  }
}
